import React, { useState, useEffect } from "react";
import axios from "../../axios-interceptor";
import {
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
} from "reactstrap";
import Spinner from "../../components/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import * as projectActions from "../../redux/actions/currentProjectActions";
import { getMultiOptionswithCountryName } from "../../utils/codeLabels";
import {setOpportunityValue, setProductValue, setMethodologyValue, setMarketDropdown} from "../../redux/actions/currentProjectActions"
import { getMultiOptions } from "../../utils/codeLabels";
const SalesforceInput = ({ newContractNumber, setSF, addOpp, project, fieldInvalidation, syndicated,setGetNewData,
  isOpSearchDisable,
  setisOpSearchDisable,
  isOpSearchDisableErr,
  setisOpSearchDisableErr,inpopup }) => {
  const [opNum, setOpNum] = useState("");
  const [loadingOpp, setLoadingOpp] = useState(false);
  const [valid, setValid] = useState(false);
  const [warningMessage,setWarningMessage]=useState(null)
  //commit
  const costingProfile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  const currentproject = useSelector(
    ({ currentProject }) => currentProject.newProject
  );
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);

  const userRecord = useSelector(({ user }) => user.userRecord);
  const dispatch = useDispatch();

  useEffect(() => {
    validate();
  }, [opNum]);
  console.log("currentprojectinsalesforce",currentproject)
  const updateCostingProfile = (_costingProfile) => {
            dispatch({
              type: "UPDATE_NEW_COSTING",
              currentCostingProfile: _costingProfile,
            });
  }

  const getFieldingCountries = (FieldingCountry,fc) =>{
    let countries = FieldingCountry.split(';')

    

    let fielding=countries.map((country)=>{
      return getMultiOptionswithCountryName(
        codeLabels.FieldingCountriesOptions,
        country.trim()
      )
    })

    fielding=fielding.filter(item => item.length)
    let countryList=""
    countries.map((country,index)=>{
      let filteredarray=fielding.filter((fieldingCountry)=> fieldingCountry[0]?.label=== country.trim())
      if(!filteredarray.length)
      {
        countryList+=(country+",")
      }
    })

    if(countryList.length)
    {
      countryList = countryList.substring(0, countryList.length - 1);
      dispatch({
        type: projectActions.UPDATE_NEW_PROJECT,
        newProject: { ExcludedFieldingCountries: countryList },
      });
      // toastr.error(`The MSD fielding countries ${countryList} were excluded because they are not found in CINTRAL. Should you need help on this, please contact the CINTRAL Support team. Thank you.`)
    }
    
    let IsMulti=false
    fielding.map((cou)=>{
      console.log("cou",cou)
      if(cou[0].label!==fc[0]?.label){
        fc.push(...cou)
        IsMulti=true
      }
    })
    
    updateCostingProfile({
      FieldingCountries: fc,
      IsMultiCountry:IsMulti
    });
    }
  const handleProductMethodologyChange = (product) => {
    // console.log("product, methodology", product)
    let prodRemoveDupl = [...new Set(product)]
    //let methRemoveDupl = [...new Set(methodology)]
      let productCode = prodRemoveDupl.map(m => {
        return ({ label: m, value: m, isFixed: false })
      })
    
      // let MethCodes = methRemoveDupl.map(m => {
      //   let meth = codeLabels.MethodologyOptions.find(mo => mo.Label == m)
      //    return ({ label: m, value: meth?.Code, isFixed: false })
      // })
    // console.log("productCode,MethCodes",productCode)
    updateCostingProfile({
      ProductType: productCode,
      StudyType:productCode
    });
  };
  const updateProject = (newProject) => {
    dispatch({
      type: projectActions.UPDATE_NEW_PROJECT,
      newProject: newProject,
    });
  };
  const handleCommCountryChange = (value,SalesOrgName,POCRevenueCountry,OperatingCountry)=>
  {

      let fc = null;
      if (value)
          fc = getMultiOptions(
              codeLabels.FieldingCountriesOptions,
              value
          );
  updateProject({
    CommissioningCountry: value,
    CommissioningOffice: "",
    BusinessUnit: "CI",
    IndustryVertical: "",
    FieldingCountries: fc,
    VerticalId: null,
    SalesOrgName:SalesOrgName,
    POCRevenueCountry:POCRevenueCountry,
    MSDOperatingCountry:OperatingCountry
  });
};

  const handleCommCountryNotFound =(SalesOrgName)=>{
    updateProject({
      CommissioningOffice: "",
      BusinessUnit: "CI",
      IndustryVertical: "",
      VerticalId: null,
      SalesOrgName:SalesOrgName,
      MSDOperatingCountryMismatch:true,
      POCRevenueCountry:"N/A"
    });
  }



  const handleChange = (e) => {
    setOpNum(e.target.value.trim());
  };

  const getOpprNumfromAPI = () => {
    
    project?.ContractDetails?.map(

      (item) => {
        console.log("outside if")
        if(item.OpportunityNumber !== null){
          console.log("inside if")
        dispatch(setOpportunityValue(item.OpportunityNumber));
        }
      })
      return "";
  }
  const validate = () => {
    if (opNum.substring(0, 2) === "OP" && opNum.length === 12) {
      setValid(true);
    } else {
      setValid(false);
    }
    return valid;
  };

  const sfCall = (opportunityNumber) => {
    if(project?.ContractDetails?.find(i => i.OpportunityNumber === opportunityNumber)) {
      toastr.error("This opportunity number is already added.")
      return
    }
    if (!syndicated) {
      setLoadingOpp(true);
      const URL = "/salesforce/" + opportunityNumber;
      const config = {
        headers: { "auth-token": localStorage.getItem("auth-token") },
      };

      axios
        .get(URL, config)
        .then((response) => {
          setOpNum("");
          // const valid = response.data.opportunity.opportunityLineItemDetails.every( item => (item.MaterialID !== "InterCO Rev line" && item.MaterialID !== "INTERCO REV Share" && item.MaterialID !== null && item.CharacteristicValues !== null))
          // console.log("valid", valid);
          // if (!valid){
          //   toastr.error('Opportunity does not have valid Product/MSD Methodology')
          //   setLoadingOpp(false);
          //   return
          // }
          const isDuplicateProposal = response.data.isDuplicate;
          let materialIDs = []
          if(response.data.opportunity.CreatedDate >= "2024-07-01T00:00:00Z" ){
            response.data.opportunity.opportunityLineItemDetails.filter((item)=>{
              materialIDs.push(item?.MaterialID?.slice(0,4).toLowerCase())
              
            })
            console.log("maaterialIds",materialIDs);
          }
          let flagValues = []
          materialIDs.map((data)=>{
            if(data === "cmi-" || data?.includes("ci-")){
              flagValues.push("true")
              // toastr.error(`Invalid Material ID found, please correct ${opportunityNumber} in MSD and try again.`)
            }
          })
          if(flagValues.length &&  !response.data.opportunity.ContractType.includes("Existing Contract")){
            toastr.error(`Invalid Material ID found, please correct ${opportunityNumber} in MSD and try again.`)
            setLoadingOpp(false);
          }
          else{
          if((userRecord.CanCreateDuplicateProposal && isDuplicateProposal !== "") || !isDuplicateProposal) {
            setWarningMessage("")
            let SalesOrgcodes= response?.data?.opportunity?.SalesOrgcode
            let SalesOrgcodeFilterArray=codeLabels.SalesOrgcodes.filter((code)=>code.SalesOrgcode	===SalesOrgcodes)
            if( !SalesOrgcodeFilterArray.length)
            {
              dispatch(setMarketDropdown(true))
              handleCommCountryNotFound(response?.data?.opportunity?.SalesOrgName)
            }
            else if(SalesOrgcodeFilterArray.length)
            {
              handleCommCountryChange(SalesOrgcodeFilterArray[0].Ctr,response?.data?.opportunity?.SalesOrgName,SalesOrgcodeFilterArray[0].PocRevenueCountry,SalesOrgcodeFilterArray[0].OperatingCountry)
            }
            const filteredOppLineItems = response.data.opportunity.opportunityLineItemDetails.filter((item) => {
              if (item.MaterialID?.slice(0,16).toLowerCase().replace(' ','') === "expenserecharge") {
                item.CharacteristicValues = {Z_FIELDINGMETHOD:["EXPENSE RECHARGE"]};
                return true;
              } else if (
              (item?.MaterialID?.slice(0,4).toLowerCase() === "cmi-" || item.MaterialID?.slice(0,3).toLowerCase() === "cmi" || item?.MaterialID?.slice(0,3).toLowerCase() === "ci-" || item?.MaterialID?.slice(0,4).toLowerCase().replace(' ','') === "tbc") && (item.CharacteristicValues !== null)) {
                return true;
              } else {
                return false;
              }
            
            
            });
            response.data.opportunity.opportunityLineItemDetails = filteredOppLineItems;
            let contractDetails = {
              ...response.data.opportunity,
              AccountName: response.data.opportunity.accountDetails.AccountName,
              Industry: response.data.opportunity.accountDetails.Industry,
              FieldingCountries:response.data.opportunity.FieldingCountryCI,
              isSF: true,
              ContractNumber: newContractNumber,
              product: filteredOppLineItems.map(id => {
                return id.MaterialID
              }),
              opportunityNum: response.data.opportunity.OpportunityNumber,
              methodology: filteredOppLineItems.flatMap((item) => item.CharacteristicValues.Z_FIELDINGMETHOD),
              
            };
            dispatch(setProductValue(contractDetails.product));
            dispatch(setOpportunityValue(contractDetails.opportunityNum));
            dispatch(setMethodologyValue(contractDetails.methodology, contractDetails.product));
            dispatch(projectActions.setOpportunityData(response.data.opportunity.opportunityLineItemDetails));
            dispatch(projectActions.setOppourtnityLineItems(response.data.opportunity.opportunityLineItemDetails));
            handleProductMethodologyChange(contractDetails.product, contractDetails.methodology);
            if(contractDetails.Stage === "Closed Lost") {
              toastr.error('The provided opportunity is in invalid stage - "Closed Lost".')
              setLoadingOpp(false);
              return
            }
            if(contractDetails.FieldingCountries!==null){
              if(SalesOrgcodeFilterArray.length){
                getFieldingCountries(contractDetails.FieldingCountries,getMultiOptions(
                  codeLabels.FieldingCountriesOptions,
                  SalesOrgcodeFilterArray[0].Ctr
              ))
              }
            }
            // if(!contractDetails.methodology || (contractDetails.methodology).length === 0) {
            //   toastr.error('Methodology value is unavailable for given Opportunity number.')
            //   setLoadingOpp(false);
            //   return;
            // }
            if(!contractDetails.product || (contractDetails.product).length === 0) {
              toastr.error('Quote Product Characteristics is unavailable in MSD. Please check the MSD-OP-number guide.')
              setLoadingOpp(false);
              return;
            }
            addOpp(contractDetails);
            setLoadingOpp(false);
            

          if(inpopup)
          { setGetNewData({
              oppNo:response.data.opportunity.OpportunityNumber,
              oppName:response.data.opportunity.OpportunityName,
              Amount:response.data.opportunity.AmountUSD + " USD ",
              clientName:response.data.opportunity.accountDetails.AccountName
            })}
            return response.status;
              
          
          } else {
            toastr.error(`There is an existing project with the same opportunity/WBS numbers ${isDuplicateProposal}. You need to get a new Opportunity Number and WBS from MSD / Finance team and create a new CINTRAL Project with this opportunity.`
                        );

            setWarningMessage(`There is an existing project already existed with the same opportunity/WBS numbers ${isDuplicateProposal}. You need to get a new Opportunity Number and WBS from MSD / Finance team and create a new CINTRAL Project with this opportunity.​`)
            setLoadingOpp(false);
            return;
          }
        }          
      })
        .catch((err) => {
          toastr.error("Product is unavailable in MSD. Please check the MSD-OP-number guide.");
          console.log(err);
          setLoadingOpp(false);
          setisOpSearchDisable(true)

        });
    } else if (!isOpSearchDisable && syndicated) {
      setLoadingOpp(true);
      const URL = "/salesforce/" + opportunityNumber;

      const config = {
        headers: { "auth-token": localStorage.getItem("auth-token") },
      };

      axios
        .get(URL, config)
        .then((response) => {
          setOpNum("");
          let contractDetails = {
            ...response.data.opportunity,
            AccountName: response.data.opportunity.accountDetails.AccountName,
            Industry: response.data.opportunity.accountDetails.Industry,
            isSF: true,
            ContractNumber: newContractNumber,
          };

          if(contractDetails.Stage === "Closed Lost") {
            toastr.error('The provided opportunity is in invalid stage - "Closed Lost".')
            setLoadingOpp(false);
            return
          }

          addOpp(contractDetails);
          setLoadingOpp(false);
          setisOpSearchDisable(true)
          
          return response.status;
        })
        .catch((err) => {

          toastr.error("Getting opportunity from Microsoft Dynamics failed");
          console.log(err);
          setLoadingOpp(false);
          setisOpSearchDisable(true)

        });
    } else {
      setisOpSearchDisableErr(true)
    }
  };

  return (
    <React.Fragment>
      {syndicated &&
        <Row>
          <Col>
            <h5>Project Name</h5>
            <Input
            disabled = {project?.ProjectStatus == "7" || project?.ProjectStatus == "8"}
              placeholder="Enter Project Name..."
              value={project.ProjectName}
              onChange={(e) =>
                dispatch({
                  type: projectActions.UPDATE_NEW_PROJECT,
                  newProject: { ProjectName: e.target.value },
                })
              }
            />

            {fieldInvalidation.ProjectName ? (
              <span style={{ color: "#AD4400" }}>Please enter a project name</span>
            ) : null}
          </Col></Row>}


      <>
      { !syndicated &&
        <div>
          {!inpopup?<h6 className="mt-3">Add Microsoft Dynamics Opportunity</h6>:<span className="mt-3 modaltext">Add Microsoft Dynamics Opportunity</span>}
        <InputGroup  className={inpopup?"mb-3 popupsearch":"mb-3"}>
          <Input
                    disabled = {project?.ProjectStatus == "7" || (Object.keys(project.ContractDetails).length >= 1)}

            placeholder="Please enter an Opportunity Number (eg. OP-000000001)"
            value={opNum === "" ? getOpprNumfromAPI() : opNum}
            // value={opNum }
            onChange={(e) => handleChange(e)}
          />
          <InputGroupAddon addonType="append" color="primary">

            <Button
              disabled={!valid || loadingOpp|| project?.ProjectStatus == "7" || (Object.keys(project.ContractDetails).length >= 1)}
              className="search-btn-msd"
              onClick={(e) => sfCall(opNum)}
            >
              {loadingOpp ? <Spinner size="small" /> : "Search"}
            </Button>
          </InputGroupAddon>

        </InputGroup>
      <h5 style={{color:"red"}}>{warningMessage}</h5>
      </div>
      }
     
        {
           syndicated && isOpSearchDisableErr && <div style={{ color: "#AD4400" }}>Maximum number of client allowed is one</div>
        }
    {!inpopup && userRecord.CanBypassSalesForce ?
        <Button
          color="link"
          className="mb-3 pl-0"
          onClick={(e) => {
            setSF(false);
          }}
          disabled = {project?.ProjectStatus == "7"}
        >
          Continue without Opportunity Number
        </Button>:null
  }
   <br />
  {
    <Button
     color="link"
          className="mb-3 pl-0"
    >
    <a
      href="https://nielsenenterprise.sharepoint.com/:w:/s/CINTRALSupportRequest-Resolutionteam/EXc6UeIsZRRDscobqY-FzP0BT2_YHueA9___Ty5rzGXZ7Q?e=2MwNl4%3E&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIxNDE1LzIzMDcwMzA3MzQ2IiwiSGFzRmVkZXJhdGVkVXNlciI6ZmFsc2V9"
      target="_blank" rel="noreferrer"
    >
      MSD-OP-number guide
    </a>
  </Button>
  }
     </>
 </React.Fragment>
  );
};

export default SalesforceInput;
