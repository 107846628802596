import React, { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import Select from "react-select";
import { useSelector } from "react-redux";
import axios from "../../axios-interceptor";
import _ from "lodash";


function TimeCostUI({ LineItemId, project, profile, optionIds, setOptionsId, rateCards, setRateCards }) {
  const currencies = useSelector(({ currentCosting }) =>
		currentCosting.currentCostingProfile.ProfileSetting &&
			currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
			? currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
			: []
	);
  const [activitiesForOPS, setActivitiesForOPS] = useState([]);
  const [activitiesForCS, setActivitiesForCS] = useState([]);
  const [OPSband, setOPSBand] = useState([]);
  const [band, setBand] = useState([]);
  const [lineItemId, setLineItemId] = useState(LineItemId)
  const [ratesOps, setRatesOPs] = useState(null)
  const [ratesCS, setRatesCS] = useState(null)
  const [CScurrencyConversion,setCSCurrencyConversion]=useState(1)
  const [OPScurrencyConversion,setOPSCurrencyConversion]=useState(1)


  const [timeCostData, setTimeCostData] = useState(null)

  useEffect(() => {
    if (profile && profile.CountrySpecs && profile.CountrySpecs.length) {
      let ids = {}
      project.ContractDetails.map(cd => cd.opportunityLineItemDetails.map(ol => {
        let _optionsIds = []
        profile.CountrySpecs.map(cs => cs.MethodologySpecs.map(ms => ms.CostingOptions.map(co => {
          if (co.OpportunityLineItemID == ol.id) {
            _optionsIds.push(co.id)
          }
        }
        )
        )
        )
        ids[ol.id] = _optionsIds

      }))



      setOptionsId(ids)
      console.log(ids)
    }
    getCurrencyConversion()
  }, [])

  const getCurrencyConversion=()=>{
    if(profile.CSCurrencySelection)
    {
      let countryCode=profile.CSCurrencySelection
      let currencyArray=currencies.filter((item)=>item.Code===countryCode)
      setCSCurrencyConversion(currencyArray[0].ConversionRateToLocal)
    }

    if(profile.OPSCurrencySelection)
    {
      let countryCode=profile.OPSCurrencySelection
      let currencyArray=currencies.filter((item)=>item.Code===countryCode)
      setOPSCurrencyConversion(currencyArray[0].ConversionRateToLocal)
    }
  }

  const getCOSelectedCOs=(optionID)=>{
    let selected;
    profile.CountrySpecs.map((country)=>{
      country.MethodologySpecs.map((method)=>{
        method.CostingOptions.map((co,index)=>{
          if(co.id==optionID)
          {
            selected=co.CoSelectedForSummary
          }
        })
      })
    })
    return selected;
  }
  const updateActivityDetails = (activityDetails, bandName, BandActivity) => {
    // Ensure the bandName exists in activityDetails
    if (activityDetails.hasOwnProperty(bandName)) {
      activityDetails[bandName] = Object.keys(BandActivity).reduce((acc, key) => {
        acc[key] = (activityDetails[bandName][key] || 0) + BandActivity[key];
        return acc;
      }, { ...activityDetails[bandName] });
    } else {
      console.warn(`Band name ${bandName} does not exist in activityDetails.`);
    }
   
    return activityDetails;
  };
  const getActivityDetails = async () => {
    try {
      let activityDataForCS;
      let bandsDataForCS;
      let activityDataForOPS;
      let bandsDataForOPS;
  
      const res = await axios
        .get(`/timecost/defaults/${profile.id}`);
            activityDataForCS = res.data.activities.filter(
            (activity) => activity.function_id === 1
          );
            bandsDataForCS = res.data.bands.filter(
            (activity) => activity.function_id === 1
          );
            activityDataForOPS = res.data.activities.filter(
            (activity) => activity.function_id === 2
          );
            bandsDataForOPS = res.data.bands.filter(
            (activity) => activity.function_id === 2
          );
  
  
  
          setBand(bandsDataForCS);
          setActivitiesForCS(activityDataForCS);
          setOPSBand(bandsDataForOPS);
          setActivitiesForOPS(activityDataForOPS);
          console.log("activity names", bandsDataForCS, activityDataForOPS, activityDataForCS, bandsDataForOPS)
  
        
  
  
     const resp = await axios
        .get(`/timecost/${profile.id}`);
          if (resp.data.datas.length) {
              let _rateCS = []
              let _rateOP = []
            let activityData = resp.data.datas.map(ele => {
                let activityforCS = {}
                let bandHoursCS = {}
                let activityforOps = {}
                let bandHoursOps = {}
                
                /// CS1 Hours Code
                if(getCOSelectedCOs(ele.OptionId)!==false){
                  if (ele?.CSSchema?.length > 0) {
                    ele.CSSchema.map(t => {
                      Object.keys(t.Rateselection).filter(ele1 => Array.isArray(t.Rateselection[ele1])).map(activity => {
                        let bandsforActivity = {}
                        bandsforActivity.total = 0
                        t.Rateselection[activity].map(band => {
                          bandsforActivity[Object.keys(band)[0]] = Object.values(band)[0] == ""?0:Object.values(band)[0]
                          bandHoursCS[Object.keys(band)[0]] = (bandHoursCS[Object.keys(band)[0]] ? bandHoursCS[Object.keys(band)[0]] : 0) + (Object.values(band)[0] != ""&&Object.values(band)[0] ? Object.values(band)[0] : 0)
                          bandsforActivity.total = bandsforActivity.total + (Object.values(band)[0] != ""&&Object.values(band)[0] ? Object.values(band)[0] : 0)
                        })
                        // console.log("Details", activityforCS, activity, bandsforActivity, Object.keys(activityforCS).length, activityDataForCS);
                        if (Object.keys(activityforCS).length == 0) {
                          activityforCS[activity] = bandsforActivity;
                          // console.log("if", activityforCS, bandsforActivity)
                        } else if (Object.keys(activityforCS).length >= activityDataForCS?.length) {
                          // console.log("else if activityforCS[activity]", activityforCS , activity, bandsforActivity);
                          activityforCS = updateActivityDetails(activityforCS, activity, bandsforActivity);
                        } else {
                          activityforCS[activity] = bandsforActivity;
                          // console.log("activityforCS else", activityforCS)
                        }
                      })
                    })
                    console.log("activityforCSactivityforCS", activityforCS)
                  }
                  else {
                    activityforCS = null
                  }
                  
                  // Ops1 Hours Code
                  if (ele?.OPMSchema?.length > 0) {
                    ele.OPMSchema.map(t => {
                      console.log("console t", t)
                      Object.keys(t.Rateselection).filter(ele1 => Array.isArray(t.Rateselection[ele1])).map(activity => {
                        let bandsforActivity = {}
                        bandsforActivity.total = 0
                        t.Rateselection[activity].map(band => {
                          bandsforActivity[Object.keys(band)[0]] = Object.values(band)[0] == ""?0:Object.values(band)[0]
                          bandHoursOps[Object.keys(band)[0]] = (bandHoursOps[Object.keys(band)[0]] ? bandHoursOps[Object.keys(band)[0]] : 0) + (Object.values(band)[0] != ""&&Object.values(band)[0] ? Object.values(band)[0] : 0)
                          bandsforActivity.total = bandsforActivity.total + (Object.values(band)[0] != ""&&Object.values(band)[0] ? Object.values(band)[0] : 0)
                        })
                        // console.log("Detailss", activityforOps, activity, bandsforActivity, Object.keys(activityforOps).length, activityDataForOPS);
                        if (Object.keys(activityforOps).length == 0) {
                          activityforOps[activity] = bandsforActivity;
                          // console.log("iffff", activityforOps, bandsforActivity)
                        } else if (Object.keys(activityforOps).length >= activityDataForOPS?.length) {
                          // console.log("else if activityforCS[activity]", activityforOps , activity, bandsforActivity);
                          activityforOps = updateActivityDetails(activityforOps, activity, bandsforActivity);
                        } else {
                          activityforOps[activity] = bandsforActivity;
                          // console.log("activityforCS else", activityforOps)
                        }
                      })
                    })
                    console.log("activityforCSactivityforCS", activityforOps)
                  }
  
                  else {
                    activityforOps = null
                  }
                
                  return ({
                    ...ele,
                    activitiesForCS: activityforCS,
                    bandHoursCS: bandHoursCS,
                    activitiesForOPS: activityforOps,
                    bandHoursOps: bandHoursOps
  
                  })
                }
                else
                {
                  return ({
                    ...ele,
                    activitiesForCS: null,
                    bandHoursCS: {},
                    activitiesForOPS: null,
                    bandHoursOps: {}
  
                  })
                }
  
              
            })
  
            setTimeCostData(activityData)
            console.log("activityData",activityData)
  
  
          }
  
    }  catch (err) {
      console.log(err);
    }
    
  };
  useEffect(() => {
    getActivityDetails();

  }, [])
  const getCSbandWiseCost=(b)=>{
    // console.log("getCSbandWiseCost", b, timeCostData, optionIds, LineItemId);
    return LineItemId == 0 ?
    timeCostData 
    ? timeCostData.map(t => t.CSSchema.map(c => c.Rateselection[b.band_name])).flat(Infinity).reduce((acc,val) => (parseFloat(acc) || 0) + (parseFloat(val)|| 0), 0)
      
         :0
     : timeCostData ?
     timeCostData.filter(tc => tc.activitiesForCS &&_.includes(optionIds[LineItemId].toString(), tc.OptionId)).map(t => t.CSSchema.map(c => c.Rateselection[b.band_name])).flat(Infinity).reduce((acc,val) => (parseFloat(acc) || 0) + (parseFloat(val)|| 0), 0)
           
           :0
  }

  const getCSTotalCost=()=>
  {
    return LineItemId == 0 ?
    timeCostData ? band.map(q => timeCostData.map(t => t.CSSchema.map(c => c.Rateselection[q.band_name]))).flat(Infinity).reduce((acc,val) => (parseFloat(acc) || 0) + (parseFloat(val)|| 0), 0)
      : 0
    :
    timeCostData ? band.map(q => timeCostData.filter(tc => tc.activitiesForCS &&_.includes(optionIds[LineItemId].toString(), tc.OptionId)).map(t => t.CSSchema.map(c => c.Rateselection[q.band_name]))).flat(Infinity).reduce((acc,val) => (parseFloat(acc) || 0) + (parseFloat(val)|| 0), 0)

      : 0
  }

  const getOPSbandWiseCost=(b)=>{
    return LineItemId == 0 ?
    timeCostData  ?
    timeCostData.map(t => t.OPMSchema.map(c => c.Rateselection[b.band_name])).flat(Infinity).reduce((acc,val) => (parseFloat(acc) || 0) + (parseFloat(val)|| 0), 0)
      
          :0
          : timeCostData ?
          timeCostData.filter(tc => tc.activitiesForOPS &&_.includes(optionIds[LineItemId].toString(), tc.OptionId)).map(t => t.OPMSchema.map(c => c.Rateselection[b.band_name])).flat(Infinity).reduce((acc,val) => (parseFloat(acc) || 0) + (parseFloat(val)|| 0), 0)

:0
  }

  const getOPSTotalCost=()=>
  {
    return LineItemId == 0 ?
    timeCostData ?
    OPSband.map(q => timeCostData.map(t => t.OPMSchema.map(c => c.Rateselection[q.band_name]))).flat(Infinity).reduce((acc,val) => (parseFloat(acc) || 0) + (parseFloat(val)|| 0), 0)
    :0
    :
    timeCostData ? 
    OPSband.map(q => timeCostData.filter(tc => tc.activitiesForOPS &&_.includes(optionIds[LineItemId].toString(), tc.OptionId)).map(t => t.OPMSchema.map(c => c.Rateselection[q.band_name]))).flat(Infinity).reduce((acc,val) => (parseFloat(acc) || 0) + (parseFloat(val)|| 0), 0)

            
            :0
  }

  return (
    <div>
      <h4>Commercial Time Cost</h4>
  
     {optionIds && <table className="table table-bordered table-stripped mt-3">
        <tr>
          <th>Activities</th>
          {band.map(b =>
            <th>{b.band_name}</th>
          )}
          <th>Total</th>
        </tr>

        {activitiesForCS.map(acs => <tr>
          <th>{acs.activity_name}</th>
          {band.map(b =>
            LineItemId == 0 ? /// Over All Hours by activity and bands
              <td> {timeCostData ?
                timeCostData.filter(tc=>tc.activitiesForCS).reduce((res, tcd) => res + tcd.activitiesForCS[acs.activity_name][b.band_name], 0)
               
                :0} </td>
              :
              <td> {
                timeCostData ?
                timeCostData.filter(tc => //// Hours by Lineitem and activity Wise
                  _.includes(optionIds[LineItemId].toString(), tc.OptionId) && tc.activitiesForCS).reduce((res, tcd) =>
                    res + tcd.activitiesForCS[acs.activity_name][b.band_name], 0)
                 
                   
                   :0}</td>

          )}
          <td> {LineItemId == 0 ? 
          timeCostData ? 
          timeCostData.filter(tc=>tc.activitiesForCS).reduce((res, tcd) => res + tcd.activitiesForCS[acs.activity_name].total, 0)
 
    :0
          :
            timeCostData ? 
            timeCostData.filter(tc =>
              _.includes(optionIds[LineItemId].toString(), tc.OptionId) && tc.activitiesForCS).reduce((res, tcd) =>
                res + tcd.activitiesForCS[acs.activity_name].total, 0)

              
          :0}</td>
        </tr>)}
        <tr>
          <th>Total Hours</th>
          {band.map(b => <td>
            {LineItemId == 0 ?
             timeCostData ? 
            timeCostData.filter(tc=>tc.activitiesForCS).reduce((res, tcd) => res + tcd.bandHoursCS[b.band_name], 0) 
 :
              0

              : timeCostData ? 
              timeCostData.filter(tc =>
                _.includes(optionIds[LineItemId].toString(), tc.OptionId) && tc.activitiesForCS).reduce((res, tcd) =>
                  res + tcd.bandHoursCS[b.band_name], 0)

                     
                  :0}
          </td>)}
          <td>    {
           LineItemId == 0 
           ?
           timeCostData 
           ?
            timeCostData.reduce((res, tcd) =>
            res + Object.keys(tcd.bandHoursCS).filter(_band =>
              _.includes(band.map(b => b.band_name), _band)
            ).reduce((sum, b) => sum + tcd.bandHoursCS[b], 0), 0)
            
            
            :0
            :
            timeCostData ?
             timeCostData.filter(tc => _.includes(optionIds[LineItemId].toString(), tc.OptionId)&& tc.activitiesForCS).reduce((res, tcd) =>
              res + Object.keys(tcd.bandHoursCS).filter(_band =>
                _.includes(band.map(b => b.band_name), _band)
              ).reduce((sum, b) => sum + tcd.bandHoursCS[b], 0), 0)
              :0
          }
          </td>
        </tr>
        {/* CS1
        {JSON.stringify(ratesCS)}
        CS2
        {JSON.stringify(ratesCS2)} */}

        { (timeCostData && (!ratesCS || (ratesCS &&  timeCostData.filter(tc=>tc.activitiesForCS).length == ratesCS.length )) 
        )&& <tr>
          <th>Total Cost</th>
          {band.map(b => <td>
            { (getCSbandWiseCost(b)*CScurrencyConversion).toFixed(2)}
          </td>
          )}
          <td>  {(getCSTotalCost()*CScurrencyConversion).toFixed(2)}</td>
        </tr>}
      </table>}



      <h4>Operations Time Cost</h4>
      <table className="table table-bordered table-stripped mt-3">
        <tr>
          <th>Activities</th>
          {OPSband.map(b =>
            <th>{b.band_name}</th>
          )}
          <th>Total</th>
        </tr>

        {activitiesForOPS.map(acs => <tr>
          <th>{acs.activity_name}</th>
          {OPSband.map(b =>
            LineItemId == 0 ?
              <td> {timeCostData ? 
                timeCostData.filter(data => data.activitiesForOPS).reduce((res, tcd) =>
                res + tcd.activitiesForOPS[acs.activity_name][b.band_name], 0)
                
                :0
                } </td>
              :
              <td> {
                timeCostData ? 
                timeCostData.filter(tc =>
                  _.includes(optionIds[LineItemId].toString(), tc.OptionId) && tc.activitiesForOPS).reduce((res, tcd) =>
                    res + tcd.activitiesForOPS[acs.activity_name][b.band_name], 0)
                    :0}</td>

          )}
          <td> {LineItemId == 0 ? 
          timeCostData ? 
          timeCostData.filter(data => data.activitiesForOPS).reduce((res, tcd) =>
            res + tcd.activitiesForOPS[acs.activity_name].total, 0)

            :0
            :
            timeCostData ?
             timeCostData.filter(tc =>
              _.includes(optionIds[LineItemId].toString(), tc.OptionId) && tc.activitiesForOPS).reduce((res, tcd) =>
                res + tcd.activitiesForOPS[acs.activity_name].total, 0)

        :0
          }</td>
        </tr>)}
        {/* op1
        {JSON.stringify(ratesOps)}
        op2
        {JSON.stringify(ratesOps2)} */}
        <tr>
          <th>Total Hours</th>
          {OPSband.map(b => <td>
            {LineItemId == 0 ?
             timeCostData ? 
             timeCostData.filter(data => data.activitiesForOPS).reduce((res, tcd) =>
              res + tcd.bandHoursOps[b.band_name], 0) 

              :0
              :
              timeCostData ?
              timeCostData.filter(data =>
                _.includes(optionIds[LineItemId].toString(), data.OptionId) && data.activitiesForOPS).reduce((res, tcd) =>
                  res + tcd.bandHoursOps[b.band_name], 0)

                  :0
            }
          </td>)}
          <td>    {LineItemId == 0 ? 
          timeCostData ? 
          timeCostData.filter(data => data.activitiesForOPS).reduce((res, tcd) =>
            res + Object.keys(tcd.bandHoursOps).filter(_band =>
              _.includes(OPSband.map(b => b.band_name), _band)
            ).reduce((sum, b) => sum + tcd.bandHoursOps[b], 0), 0)
            
            :0
            :
            timeCostData?
             timeCostData.filter(data =>
              _.includes(optionIds[LineItemId].toString(), data.OptionId) && data.activitiesForOPS).reduce((res, tcd) =>
                res + Object.keys(tcd.bandHoursOps).filter(_band =>
                  _.includes(OPSband.map(b => b.band_name), _band)
                ).reduce((sum, b) => sum + tcd.bandHoursOps[b], 0), 0)

                :0
          }
          </td>
        </tr>

        {(timeCostData && (!ratesOps || (ratesOps  && timeCostData.filter(tc=>tc.activitiesForOPS).length == ratesOps.length)) 
        )&& <tr>
          <th>Total Cost</th>
          {OPSband.map(b => <td>
            {(getOPSbandWiseCost(b)*OPScurrencyConversion).toFixed(2)}
          </td>
          )}

          <td>
            {(getOPSTotalCost()*OPScurrencyConversion).toFixed(2)}
          </td>
        </tr>}

      </table>
    </div>

  )
}


export default TimeCostUI