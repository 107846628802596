import React, { useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AvForm, AvField } from "availity-reactstrap-validation";
import logo from "../../assets/img/avatars/modal-logo.png"; 
import {
  CustomInput,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Table,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Link} from "react-router-dom";
// import Edit from "../assets/icons/Connected-System-Icons/Tools/Edit-fill";

import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { useState } from "react";
import {
  faPencilAlt,
  faCopy,
  faPlusCircle,
  faTimesCircle,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { updateProfile } from "../../redux/actions/currentCostingActions";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import ReturnControls from "./ReturnControl";
import { valueChangedInRFQ } from "../../redux/actions/appActions";
import { ChangesTracker } from "./CountryMethodologyTabsFinal";
import {setindexCOforCopy} from "../../redux/actions/currentProjectActions"
import store from "../../redux/store/index";
export const LogoImgStyle = {
  width: "150px",
};

const Start = ({
  profile,
  countryIndex,
  methodology,
  methodologyIndex,
  updateProject,
  errorFieldsObj,
  vendor = true,
  enable = true,
  isEditable,
  disableFields,
})=> {
  const dispatch = useDispatch();
  const project = useSelector(
    ({ currentProject }) => currentProject.newProject
  );


  const styleModelBody = {
    minHeight: "200px",
  };
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);

  const [modelState, setModelState] = useState(false);
  //var [copyIndexCO, setCopyIndexCO] = useState([]);
  var copyIndexCO = [];

  const [modelStateVar, setModelStateVar] = useState(false);

  const [costingOptionIndex, setCostingOptionIndex] = useState(null);
  const [singleCost, setSingleCost] = useState(false);
  const [costOptCopy, setCostOptCopy] = useState(false);
  const [updateCostOpt, setUpdateCostOpt] = useState(false);
  const [modelMode, setModelMode] = useState("new");
  // const [copyVal, setCopyVal] = useState(2)
  const [newCostingOption, setNewCostingOption] = useState(null);

  const [addVariables, setAddVariables] = useState([]);

  const [searchValue, setSearchValue] = useState(null);

  const [copyToCostingOption, setCopyTo] = useState(null);
  const [isCODeleted, setCODeleted] = useState(0)
  const [fcValue, setfcValue]=useState("No")
  const isRfqValueChanged = useSelector(({ app }) => app.valueChanged);

  const {hasDataChanged, deleteId} = useContext(ChangesTracker);

  const oppValue = useSelector(

    ({ currentProject }) => currentProject.opportunityValue

  );
  const product = useSelector(

    ({ currentProject }) => currentProject.productValue

  );
  const currentCostingProfile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
);
const handleIndexCO = (indexCO) => {
  copyIndexCO = [indexCO];
  //console.log(copyIndexCO);
}
const array1 = profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].Code
  const array2=[]
  const array3 = array2.push(array1)
  //console.log("array2",array2)
  const productData =[]
  while(typeof profile.ProductTypeData === "string"){
    profile.ProductTypeData = JSON.parse(profile.ProductTypeData)
  }
  // if(typeof currentCostingProfile.ProductTypeData==="string")
  // {
  //   let producttypeData=JSON.parse(currentCostingProfile.ProductTypeData)
  //   producttypeData.map((data)=>{
  //     // const isLargeNumber = (element) => element === array2[0];
  //     productData.push(data.Code)
  // //     console.log("large",isLargeNumber)
  // // console.log(data?.Code?.findIndex(isLargeNumber));
  //   })
  // }
  // else
  // {
    console.log("profile?.ProductTypeData", profile?.ProductTypeData, store.getState().currentCosting.currentCostingProfile);
    profile?.ProductTypeData?.map((data)=>{
      // const isLargeNumber = (element) => element === array2[0];
      productData.push(data.Code)
  //     console.log("large",isLargeNumber)
  // console.log(data?.Code?.findIndex(isLargeNumber));
    })
  // }
  
 // console.log("product data",productData)
  const len = productData?.filter((element) => element === array2[0]).length;
  let subMeth = profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].Code
  let filterSMcode=[]
       while(typeof profile.ProductTypeData === "string"){
        profile.ProductTypeData = JSON.parse(profile.ProductTypeData)
             }
       filterSMcode = profile.ProductTypeData.filter ((item) => item.Code === subMeth);
       let productNamestoCheck = filterSMcode.map(obj => obj.Productname)
 // console.log("length1",len)
  // const temp = productData.map((data)=>{
  //   data === array[0]

  // })
  const intersection = array2.filter(element => productData.includes(element));
  console.log("intersection",intersection)
  

 



 

  const ClearSearch = () => {
    setSearchValue(null);
    let tempAddVariables = _.cloneDeep(addVariables);
    tempAddVariables.forEach((x) => {
      x.show = false;
      x.selected = false;
    });
    setAddVariables(tempAddVariables);
  };
  const updateProjectAndProfileWRTCostingOption = (profileTemp) => {
    const tempProfiles = project.CostingProfiles.map((cp) => {
      if (cp.id == profileTemp.id) return { ..._.cloneDeep(profileTemp) };
      return { ..._.cloneDeep(cp) };
    });
    dispatch(updateProfile(profileTemp));
    dispatch(updateProject({ CostingProfiles: tempProfiles }));
  };
  const saveCostingOption = (mode, oppr, index) => {
    if (mode == "new") {
      let profileTemp = _.cloneDeep(profile);
      let costingOptionsClone = _.cloneDeep(
        profileTemp.CountrySpecs[countryIndex].MethodologySpecs[
          methodologyIndex
        ].CostingOptions
      );
      profileTemp.CountrySpecs[countryIndex].MethodologySpecs[
        methodologyIndex
      ].CostingOptions = _.cloneDeep([
        ...costingOptionsClone,
        ...[
          {
            label: newCostingOption,
            Variables: _.cloneDeep(
              _.head(methodology.CostingOptions).Variables
            ),
          },
        ],
      ]);
      const tempProfiles = project.CostingProfiles.map((cp) => {
        if (cp.id == profileTemp.id) return { ..._.cloneDeep(profileTemp) };
        return { ..._.cloneDeep(cp) };
      });
      dispatch(updateProfile(profileTemp));
      dispatch(updateProject({ CostingProfiles: tempProfiles }));
    } else if (mode == "rename") {
      let profileTemp = _.cloneDeep(profile);
      let costingOptionsClone = _.cloneDeep(
        profileTemp.CountrySpecs[countryIndex].MethodologySpecs[
          methodologyIndex
        ].CostingOptions
      );
      costingOptionsClone[costingOptionIndex].label = newCostingOption;
      profileTemp.CountrySpecs[countryIndex].MethodologySpecs[
        methodologyIndex
      ].CostingOptions = costingOptionsClone;
      const tempProfiles = project.CostingProfiles.map((cp) => {
        if (cp.id == profileTemp.id) return { ..._.cloneDeep(profileTemp) };
        return { ..._.cloneDeep(cp) };
      });
      dispatch(updateProfile(profileTemp));
      dispatch(updateProject({ CostingProfiles: tempProfiles }));
    } else if (mode == "copy") {
      if (newCostingOption || oppr) {
        let profileTemp = _.cloneDeep(profile);
        let costingOptionsClone = _.cloneDeep(
          profileTemp.CountrySpecs[countryIndex].MethodologySpecs[
            methodologyIndex
          ].CostingOptions
        );
        profileTemp.CountrySpecs[countryIndex].MethodologySpecs[
          methodologyIndex
        ].CostingOptions = [
          ...costingOptionsClone,
          ...[
            {
              label: newCostingOption,
              Variables: oppr ? _.cloneDeep(
                methodology.CostingOptions[index+1]
              ).Variables :  _.cloneDeep(
                methodology.CostingOptions[costingOptionIndex]
              ).Variables,
            },
          ],
        ];
        const tempProfiles = project.CostingProfiles.map((cp) => {
          if (cp.id == profileTemp.id) return { ..._.cloneDeep(profileTemp) };
          return { ..._.cloneDeep(cp) };
        });
        dispatch(updateProfile(profileTemp));
        dispatch(updateProject({ CostingProfiles: tempProfiles }));
      } else if (copyToCostingOption) {
        const CostingOptions =
          profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex]
            .CostingOptions;
        const indexCopyToCostingOption = CostingOptions.findIndex(
          (x) => x.label == copyToCostingOption.label
        );
        const costingOptionClone = _.cloneDeep(
          CostingOptions[indexCopyToCostingOption]
        );
        costingOptionClone.Variables = _.cloneDeep(
          CostingOptions[costingOptionIndex].Variables
        );

        let profileTemp = _.cloneDeep(profile);
        let costingOptionsClone = _.cloneDeep(
          profileTemp.CountrySpecs[countryIndex].MethodologySpecs[
            methodologyIndex
          ].CostingOptions
        );
        costingOptionsClone[indexCopyToCostingOption] = costingOptionClone;
        profileTemp.CountrySpecs[countryIndex].MethodologySpecs[
          methodologyIndex
        ].CostingOptions = costingOptionsClone;
        const tempProfiles = project.CostingProfiles.map((cp) => {
          if (cp.id == profileTemp.id) return { ..._.cloneDeep(profileTemp) };
          return { ..._.cloneDeep(cp) };
        });
        dispatch(updateProfile(profileTemp));
        dispatch(updateProject({ CostingProfiles: tempProfiles }));
      }
    }
  };

// const Start = ({ profile, countryIndex, methodology, methodologyIndex, updateProject, errorFieldsObj, vendor = true, enable = true, isEditable, disableFields }) => {
//     const dispatch = useDispatch();
//     const project = useSelector(
//         ({ currentProject }) => currentProject.newProject
//     );
//     // useEffect(() => {
//     //     if(profile.CountrySpecs && profile.CountrySpecs[countryIndex]?.MethodologySpecs[methodologyIndex]?.CostingOptions && profile.CountrySpecs[countryIndex]?.MethodologySpecs[methodologyIndex]?.CostingOptions[0] && profile.CountrySpecs[countryIndex]?.MethodologySpecs[methodologyIndex]?.CostingOptions[0].Variables && profile.CountrySpecs[countryIndex]?.MethodologySpecs[methodologyIndex]?.CostingOptions[0].Variables[4] && profile.CountrySpecs[countryIndex]?.MethodologySpecs[methodologyIndex]?.CostingOptions[0].Variables[4].value)
//     //     {
//     //     let trackingValue = profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[0].Variables[4].value;
//     //     if (trackingValue)
//     //         InfoChange(0, 4, "Tracking Project", trackingValue);
//     //     }
//     // }, [project.ReloadForms]);

//     const styleModelBody = {
//         minHeight: "200px"
//     }
//     const codeLabels = useSelector(({ codeLabels }) => codeLabels);

//     const [modelState, setModelState] = useState(false);

//     const [modelStateVar, setModelStateVar] = useState(false);

//     const [costingOptionIndex, setCostingOptionIndex] = useState(null);

//     const [modelMode, setModelMode] = useState('new');

//     const [newCostingOption, setNewCostingOption] = useState(null);

//     const [addVariables, setAddVariables] = useState([]);

//     const [searchValue, setSearchValue] = useState(null);


//     const [copyToCostingOption, setCopyTo] = useState(null);

//     const ClearSearch = () => {
//         setSearchValue(null);
//         let tempAddVariables = _.cloneDeep(addVariables);
//         tempAddVariables.forEach(x => {
//             x.show = false;
//             x.selected = false;
//         });
//         setAddVariables(tempAddVariables);
//     }
//     const updateProjectAndProfileWRTCostingOption = (profileTemp) => {
//         const tempProfiles = project.CostingProfiles.map(cp => {
//             if (cp.id == profileTemp.id)

//                 return { ..._.cloneDeep(profileTemp) }
//             return { ..._.cloneDeep(cp) }
//         });
//         dispatch(updateProfile(profileTemp))
//         dispatch(updateProject({ CostingProfiles: tempProfiles }));
//     }
//     const saveCostingOption = (mode) => {
//         if (mode == "new") {
//             // let profileTemp = update(profile, {
//             //     CountrySpecs: {
//             //         [countryIndex]: {
//             //             MethodologySpecs:
//             //             {
//             //                 [methodologyIndex]:
//             //                 {
//             //                     CostingOptions:
//             //                     {
//             //                         $push: [
//             //                             { label: newCostingOption, Variables: _.cloneDeep(_.head(methodology.CostingOptions).Variables) },
//             //                         ]
//             //                     }
//             //                 }
//             //             }
//             //         }
//             //     }
//             // });
//             let profileTemp = _.cloneDeep(profile)
//             let costingOptionsClone = _.cloneDeep(profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions)
//             profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions = _.cloneDeep(
//                 [...costingOptionsClone, ...[
//                     { label: newCostingOption, Variables: _.cloneDeep(_.head(methodology.CostingOptions).Variables) },
//                 ]])
//             const tempProfiles = project.CostingProfiles.map(cp => {
//                 if (cp.id == profileTemp.id)
//                     return { ..._.cloneDeep(profileTemp) }
//                 return { ..._.cloneDeep(cp) }
//             });
//             dispatch(updateProfile(profileTemp))
//             dispatch(updateProject({ CostingProfiles: tempProfiles }));
//         }
//         else if (mode == "rename") {
//             // let profileTemp = update(profile, {
//             //     CountrySpecs: {
//             //         [countryIndex]: {
//             //             MethodologySpecs:
//             //             {
//             //                 [methodologyIndex]:
//             //                 {
//             //                     CostingOptions:
//             //                     {
//             //                         [costingOptionIndex]:
//             //                         {
//             //                             $merge: { label: newCostingOption }
//             //                         }
//             //                     }
//             //                 }
//             //             }
//             //         }
//             //     }
//             // });
//             let profileTemp = _.cloneDeep(profile)
//             let costingOptionsClone = _.cloneDeep(profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions)
//             costingOptionsClone[costingOptionIndex].label = newCostingOption
//             profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions = costingOptionsClone
//             const tempProfiles = project.CostingProfiles.map(cp => {
//                 if (cp.id == profileTemp.id)
//                     return { ..._.cloneDeep(profileTemp) }
//                 return { ..._.cloneDeep(cp) }
//             });
//             dispatch(updateProfile(profileTemp))
//             dispatch(updateProject({ CostingProfiles: tempProfiles }));
//         }
//         else if (mode == "copy") {
//             if (newCostingOption) {
//                 // let profileTemp = update(profile, {
//                 //     CountrySpecs: {
//                 //         [countryIndex]: {
//                 //             MethodologySpecs:
//                 //             {
//                 //                 [methodologyIndex]:
//                 //                 {
//                 //                     CostingOptions:
//                 //                     {
//                 //                         $push: [
//                 //                             { label: newCostingOption, Variables: _.cloneDeep(methodology.CostingOptions[costingOptionIndex]).Variables },
//                 //                         ]
//                 //                     }
//                 //                 }
//                 //             }
//                 //         }
//                 //     }
//                 // });
//                 let profileTemp = _.cloneDeep(profile)
//                 let costingOptionsClone = _.cloneDeep(profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions)
//                 profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions = [...costingOptionsClone, ...[
//                     { label: newCostingOption, Variables: _.cloneDeep(methodology.CostingOptions[costingOptionIndex]).Variables },
//                 ]]
//                 const tempProfiles = project.CostingProfiles.map(cp => {
//                     if (cp.id == profileTemp.id)
//                         return { ..._.cloneDeep(profileTemp) }
//                     return { ..._.cloneDeep(cp) }
//                 });
//                 dispatch(updateProfile(profileTemp))
//                 dispatch(updateProject({ CostingProfiles: tempProfiles }));
//             }
//             else if (copyToCostingOption) {
//                 const CostingOptions = profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions;
//                 const indexCopyToCostingOption = CostingOptions.findIndex(x => x.label == copyToCostingOption.label);
//                 const costingOptionClone = _.cloneDeep(CostingOptions[indexCopyToCostingOption]);
//                 costingOptionClone.Variables = _.cloneDeep(CostingOptions[costingOptionIndex].Variables);

//                 // let profileTemp = update(profile, {
//                 //     CountrySpecs: {
//                 //         [countryIndex]: {
//                 //             MethodologySpecs:
//                 //             {
//                 //                 [methodologyIndex]:
//                 //                 {
//                 //                     CostingOptions:
//                 //                     {
//                 //                         [indexCopyToCostingOption]: { $set: costingOptionClone }
//                 //                     }
//                 //                 }
//                 //             }
//                 //         }
//                 //     }
//                 // });
//                 let profileTemp = _.cloneDeep(profile)
//                 let costingOptionsClone = _.cloneDeep(profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions)
//                 costingOptionsClone[indexCopyToCostingOption] = costingOptionClone
//                 profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions = costingOptionsClone
//                 const tempProfiles = project.CostingProfiles.map(cp => {
//                     if (cp.id == profileTemp.id)
//                         return { ..._.cloneDeep(profileTemp) }
//                     return { ..._.cloneDeep(cp) }
//                 });
//                 dispatch(updateProfile(profileTemp))
//                 dispatch(updateProject({ CostingProfiles: tempProfiles }));
//             }
//         }
//     }

//     const errorStyles = {

//         control: (styles) => ({ ...styles, borderColor: "#AD4400" })


//     }
//     const CommissioningCountryCheck = (actualstatus) => {
//         let _updatedStatus = actualstatus
//         if (project.CommissioningCountry == profile.CountrySpecs[countryIndex].CountryCode) {
//             let fieldingCountries = Array.isArray(profile.FieldingCountries) ? profile.FieldingCountries.map(fc => fc.value) : profile.FieldingCountries?.split(",")
//             if (!_.includes(fieldingCountries, project.CommissioningCountry)) {
//                 return true
//             } else if (_.includes(fieldingCountries, project.CommissioningCountry)) {
//                 return false
//             }
//         }

//         return _updatedStatus
//     }
//     const ReturnControl = (indexCO, indexV, variable) => {
//         let aa = profile.CountrySpecs[countryIndex].value;
//         const v = methodology.CostingOptions[indexCO].Variables.filter(x => x.label == variable.label)[0] || methodology.CostingOptions[0].Variables.filter(x => x.label == variable.label)[0];
//         //if (v.disabled)
//         //    return null;
//         if (methodology.RFQSchema.properties && methodology.RFQSchema.properties[v.id] && !v.value) {
//             if (methodology.RFQSchema.properties[v.id].defaultValue) {
//                 v.value = (v.type === 'creatableMulti' && v.optionsLabel && v.optionsLabel === 'Cities') ?
//                     project.CommissioningCountry === profile.CountrySpecs[countryIndex].CountryCode ?
//                         (v.value && v.value == null) ? v.value : methodology.RFQSchema.properties[v.id].defaultValue : '' :
//                     (v.type === 'single' && v.optionsLabel && v.optionsLabel === 'LengthOfInterviewOptions') ?
//                         methodology.RFQSchema.properties[v.id].defaultValue.value :
//                         (v.type === 'integer' ||
//                             v.type === 'text' ||
//                             v.type === 'textarea') ? methodology.RFQSchema.properties[v.id].defaultValue :
//                             (v.type === 'single') ? (_.isObject(methodology.RFQSchema.properties[v.id].defaultValue)) ?
//                                 methodology.RFQSchema.properties[v.id].defaultValue.value : methodology.RFQSchema.properties[v.id].defaultValue :
//                                 (v.type === 'multi') ? methodology.RFQSchema.properties[v.id].defaultValue : v.value
//             }
//         }
//         v.disabled = v.id === "approxPercentageChange" && methodology.CostingOptions[indexCO].Variables.find(v=>v.id === "trackingProject").value!== 'Yes'?true:CommissioningCountryCheck(v.disabled)

//         var val1 = { required: { value: v.mandatory }, min: { value: 0, errorMessage: "Value should be greater than 0" } }
//         var val2 = { required: { value: v.mandatory }, min: { value: 1, errorMessage: 'Your value must be between 1 & 100 ' }, max: { value: 100, errorMessage: 'Your value must be between 1 & 100 ' } }

//         if (v.type == "integer")
//             return (
//                 <AvField        //chanege by mridul rishab
//                     name={v.label}
//                     value={v.value || ''}
//                     disabled={(profile.ProfileStatus == 99) || v.disabled || methodology.NotApplicable || disableFields}
//                     style={{ width: "100%" }}
//                     placeholder=""
//                     type="number"
//                     min={v.label == "Incidence Rate Main" ? 1 : 0}
//                     onChange={(e) => InfoChange(indexCO, indexV, e.target.name, parseInt(e.target.value) < 0 ? "1" : e.target.value)}
//                     required={v.disabled ? false : v.mandatory}
//                     invalid={v.label == "Incidence Rate Main" ? (parseInt(v.value) > 100) || (parseInt(v.value) < 1) : (parseInt(v.value) < 0)}
//                     validate={(v.mandatory && !methodology.NotApplicable && !v.disabled && (!_.includes(v.value)
//                     )) ? v.label == "Incidence Rate Main" ? val2 : val1 : {}}
//                 />
//             )
//         else if (v.type == "header")
//             return (
//                 <h5 className={'header'}>{v.label}</h5>
//             )
//         else if (v.type == "text")
//             return (
//                 <AvField
//                     name={v.label}
//                     placeholder=""
//                     type="text"
//                     style={{ width: "100%" }}
//                     disabled={(profile.ProfileStatus == 99) || v.disabled || methodology.NotApplicable || disableFields}
//                     onChange={(e) => InfoChange(indexCO, indexV, e.target.name, e.target.value)}
//                     value={v.value || ''}
//                 />
//             )
//         else if (v.type == "textarea") {

//             return (
//                 <AvField
//                     name={v.label}
//                     placeholder=""
//                     disabled={(profile.ProfileStatus == 99) || v.disabled || methodology.NotApplicable || disableFields}
//                     type="textarea"
//                     style={{ width: "100%" }}
//                     onChange={(e) => InfoChange(indexCO, indexV, e.target.name, e.target.value)}
//                     value={v.value || ''}
//                     validate={(v.mandatory && !methodology.NotApplicable && !v.disabled && (!_.includes(v.value)
//                     )) ? { required: { value: v.mandatory, errorMessage: 'field is required' } } : {}}
//                 />

//             )
//         }
//         else if (v.type == "single") {
//             let optionsTemp = v.optionsLabel ? codeLabels[v.optionsLabel] : v.options;
//             if (optionsTemp?.find(x => (x.Label && x.Label.toLowerCase() == 'other specify') || (x.label && x.label.toLowerCase() == 'other specify')))
//                 return (
//                     <>
//                         <CreatableSelect
//                             name={v.label}
//                             value={v.value}
//                             isDisabled={(profile.ProfileStatus == 99) || v.disabled || methodology.NotApplicable || disableFields}
//                             onChange={(e) => InfoChange(indexCO, indexV, v.label, e, v.id)}
//                             className="react-select-container"
//                             classNamePrefix="react-select"
//                             styles={errorFieldsObj && errorFieldsObj[profile.CountrySpecs[countryIndex].CountryName]
//                                 && errorFieldsObj[profile.CountrySpecs[countryIndex].CountryName]["CostingOptions"]
//                                 && errorFieldsObj[profile.CountrySpecs[countryIndex].CountryName]["CostingOptions"][profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].Label]
//                                 && _.includes(errorFieldsObj[profile.CountrySpecs[countryIndex].CountryName]["CostingOptions"][profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].Label], v.label, v.value)
//                                 ? errorStyles : ""}
//                             validate={(v.mandatory && !v.disabled && (!_.includes(v.value)
//                             )) ? { required: { value: v.mandatory } } : {}}
//                             // required={v.mandatory}
//                             options={
//                                 v.optionsLabel ?
//                                     codeLabels[v.optionsLabel].map(
//                                         (opt) => {
//                                             return {
//                                                 value: opt.Code,
//                                                 label: opt.Label,
//                                             }
//                                         })
//                                     :
//                                     v.options.map((option) => {
//                                         return {
//                                             value: option.value,
//                                             label: option.label,
//                                         }
//                                     })

//                             }
//                         />

//                         {!v.disabled && errorFieldsObj && errorFieldsObj[profile.CountrySpecs[countryIndex].CountryName]
//                             && errorFieldsObj[profile.CountrySpecs[countryIndex].CountryName]["CostingOptions"]
//                             && errorFieldsObj[profile.CountrySpecs[countryIndex].CountryName]["CostingOptions"][profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].Label]
//                             && _.includes(errorFieldsObj[profile.CountrySpecs[countryIndex].CountryName]["CostingOptions"][profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].Label], v.label, v.value)
//                             ? <p className="invalid-feedback d-flex">field is required</p> : null}
//                         {/* {v.mandatory && !v.value &&<p className="invalid-feedback d-flex">field is required</p>} */}
//                     </>
//                 )
//             else
//                 return (
//                     <SelectWrapper
//                         tag={CustomInput}
//                         name={v.label}
//                         value={v.value}
//                         disabled={(profile.ProfileStatus == 99) || v.disabled || methodology.NotApplicable || disableFields}
//                         type="select"
//                         onChange={(e) => InfoChange(indexCO, indexV, v.label, e.target.value)}
//                         validate={(v.mandatory && !methodology.NotApplicable && !v.disabled && (!_.includes(v.value)
//                         )) ? { required: { value: v.mandatory, errorMessage: 'field is required' } } : {}}
//                     >
//                         <option value="0"></option>
//                         {
//                             v.optionsLabel ?
//                                 codeLabels[v.optionsLabel]?.map(
//                                     (opt) => (
//                                         <option key={opt.Code} value={opt.Code}>{opt.Label}</option>
//                                     ))
//                                 :
//                                 v.options?.map((option) => (
//                                     <option key={option.value} value={option.value}>{option.label}</option>
//                                 ))
//                         }
//                     </SelectWrapper>
//                 )
//         }


//         else if (v.type == "multi" && v.optionsLabel && v.optionsLabel == 'Cities')
//             return (
//                 <div style={{ textAlign: 'center' }}>
//                     {
//                         codeLabels['CitiesOptions'].filter(x => x.Code == profile.CountrySpecs[countryIndex].CountryCode)[0] ?
//                             <Select
//                                 name={v.label}
//                                 value={v.value}
//                                 isDisabled={(profile.ProfileStatus == 99) || v.disabled || methodology.NotApplicable || disableFields}
//                                 onChange={(e) => InfoChange(indexCO, indexV, v.label, e)}
//                                 className="react-select-container"
//                                 classNamePrefix="react-select"
//                                 menuPortalTarget={document.querySelector('card-body')}
//                                 menuPosition={'fixed'}
//                                 options={
//                                     codeLabels['CitiesOptions'].filter(x => x.Code == profile.CountrySpecs[countryIndex].CountryCode)[0]['Cities'].map(
//                                         (opt) => {
//                                             return {
//                                                 value: opt.id,
//                                                 label: opt.Label,
//                                             }
//                                         })
//                                 }
//                                 isMulti
//                             /> : 'No City Found'
//                     }
//                 </div>
//             )
//         else if (v.type == "creatableMulti" && v.optionsLabel && v.optionsLabel == 'Cities')
//             return (
//                 <>
//                     {
//                         codeLabels['CitiesOptions'].filter(x => x.Code == profile.CountrySpecs[countryIndex].CountryCode)[0] ?
//                             <CreatableSelect
//                                 className="react-select-container"
//                                 classNamePrefix="react-select"
//                                 isMulti
//                                 isDisabled={(profile.ProfileStatus == 99) || v.disabled || methodology.NotApplicable || disableFields}
//                                 value={v.value}
//                                 onChange={(e) => InfoChange(indexCO, indexV, v.label, e)}
//                                 options={
//                                     codeLabels['CitiesOptions'].filter(x => x.Code == profile.CountrySpecs[countryIndex].CountryCode)[0]['Cities'].map(
//                                         (opt) => {
//                                             return {
//                                                 value: opt.id,
//                                                 label: opt.Label,
//                                             }
//                                         })
//                                 }
//                             /> : 'No City Found'
//                     }
//                 </>
//             )
//         else if (v.type == "multi")
//             return (
//                 <div>
//                     <Select
//                         name={v.label}
//                         value={v.value}
//                         isDisabled={(profile.ProfileStatus == 99) || v.disabled || methodology.NotApplicable || disableFields}
//                         onChange={(e) => InfoChange(indexCO, indexV, v.label, e)}
//                         className="react-select-container"
//                         classNamePrefix="react-select"
//                         options={
//                             v.optionsLabel ?
//                                 codeLabels[v.optionsLabel].map(
//                                     (opt) => {
//                                         return {
//                                             value: opt.Code,
//                                             label: opt.Label,
//                                         }
//                                     })
//                                 :
//                                 v.options.map((option) => {
//                                     return {
//                                         value: option.value,
//                                         label: option.label,
//                                     }
//                                 })

//                         }
//                         isMulti
//                     />
//                 </div>
//             )

//         else if (v.type == "check")
//             return (
//                 <CustomInput
//                     type="switch"
//                     name={v.label}
//                     id={v.label}
//                     defaultChecked={v.value ? v.value : true}
//                     className="h5"
//                     disabled={(profile.ProfileStatus == 99) || methodology.NotApplicable || disableFields}
//                     onChange={(e) => {

//                         InfoChange(indexCO, indexV, e.target.name, e.target.checked);
//                     }}
//                 />
//             )
//         else if (v.type == "date")
//             return (
//                 <FormGroup>
//                     <AvField
//                         // disabled={(_.includes(opsResourcesVariables, v.id)
//                         //     && !userRecord.CanEditOpsResources) || methodology.NotApplicable}
//                         name={"field_" + indexV}
//                         placeholder=""
//                         type="date"
//                         errorMessage="field is required"
//                         // validate={(v.mandatory && (!_.includes(opsResourcesVariables, v.id) || (_.includes(opsResourcesVariables, v.id)
//                         //     && userRecord.CanEditOpsResources))) ? { required: { value: v.mandatory } } : {}}
//                         onChange={(e) => InfoChange(indexCO, indexV, v.label, e.target.value)}
//                         value={v.value || ''}
//                     />
//                 </FormGroup>
//             )
//     };

//     const RenderVariables = (variables) => {
//         let tempTitle = "";
//         let tempIndex = null;
//         variables.filter(x => x.mandatory || x.label == "Boosters required").forEach((x, i) => {
//             if (x.label == "Boosters required") {
//                 x.firstField = true;
//                 x.sectionTitle = "Sample Specification";
//             }
//             else if (x.label == "Age") {
//                 x.firstField = true;
//                 x.sectionTitle = "Quota Specification";
//             }

//         });
//         return (
//             variables.map((variable, indexV) => {
//                 if (variable.mandatory || variable.label == "Boosters required") {
//                     if (!variable.Default && variable.firstField)
//                         return <>
//                             <Col md={'12'}><h5 id={"header_" + indexV} className={'header'}> {variable.sectionTitle}</h5></Col>
//                             <Col key={"col_" + indexV} style={{ paddingLeft: "3rem", paddingTop: "0.25rem" }} md={4}>
//                                 <Input type="checkbox" defaultChecked={variable.selected ? true : false}
//                                     onChange={(e) => { if (!methodology.NotApplicable) checkChangeVariable(indexV, e.target.checked) }} />
//                                 {variable.label}
//                             </Col>
//                         </>
//                     else if (!variable.Default)
//                         return <Col key={"col_" + indexV} style={{ paddingLeft: "3rem", paddingTop: "0.25rem" }} md={4}>
//                             <Input type="checkbox" defaultChecked={variable.selected ? true : false}
//                                 onChange={(e) => { if (!methodology.NotApplicable) checkChangeVariable(indexV, e.target.checked) }} />
//                             {variable.label}
//                         </Col>
//                 }
//             }));
//     };

//     const RenderVariablesNew = () => {

//         return (
//             addVariables.map((variable, indexV) => {
//                 if (!variable.Default && variable.show)
//                     return <Col key={"col_" + indexV} style={{ paddingLeft: "2.2rem", paddingTop: "1.8rem" }} md={4}>
//                         <Input type="checkbox" defaultChecked={variable.selected ? true : false} disabled={(profile.ProfileStatus == 99) || methodology.NotApplicable || disableFields}
//                             onChange={(e) => { if (!methodology.NotApplicable) checkChangeVariable(indexV, e.target.checked) }} />
//                         {variable.label}
//                     </Col>
//                 else
//                     return null;

//             }));
//     }

//     function removeItem(indexCO) {
//         const hasAnySelectedAfterRemove = profile.CountrySpecs[countryIndex]?.MethodologySpecs[methodologyIndex]?.CostingOptions.some((i, ind) => {
//             if (ind === indexCO) return false
//             return i.isSelected
//         })
//         if (!hasAnySelectedAfterRemove) {
//             toastr.info("Cannot delete this costing option as its is only selected.")
//             return
//         }
//         if (!methodology.NotApplicable)
//             if (!window.confirm('Are you sure to delete the costing option?')) return;

//         let deletableCostingOption = profile.CountrySpecs[countryIndex]?.MethodologySpecs[methodologyIndex]?.CostingOptions[indexCO]
//         if (deletableCostingOption)
//             deletableCostingOption.toRemove = true
//         // let profileTemp = update(profile, {
//         //     CountrySpecs: {
//         //         [countryIndex]: {
//         //             MethodologySpecs:
//         //             {
//         //                 [methodologyIndex]:
//         //                 {
//         //                     CostingOptions:
//         //                     {
//         //                         $splice: [[indexCO, 1]]
//         //                     }
//         //                 }
//         //             }
//         //         }
//         //     }
//         // });

//         let profileTemp = _.cloneDeep(profile)
//         let costingOptionsClone = _.cloneDeep(profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions)
//         costingOptionsClone.splice(indexCO, 1)
//         if (!profileTemp.deletedCostingOptions)
//             profileTemp.deletedCostingOptions = []

//         if (deletableCostingOption && deletableCostingOption.id)
//             profileTemp.deletedCostingOptions.push(deletableCostingOption)

//         profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions = costingOptionsClone
//         updateProjectAndProfileWRTCostingOption(profileTemp)
//     }

//     function InfoChange(indexCO, indexV, name, value, id = null) {

//         const variablesClone = _.cloneDeep(profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[indexCO].Variables);

//         const currentVariable = variablesClone[indexV];
//         if (value == null) {
//             value = (currentVariable.type === 'creatableMulti' ||
//                 currentVariable.type === 'multi') ? [] : value
//         }
//         currentVariable.value = value;

//         if (currentVariable.id == "respondentParticipation") {
//             variablesClone.filter(hidVar => hidVar.id == "fieldworkLength")[0].value = parseInt(value * 2.5);
//         }
//         if (name == "Sample size per scenario per option")
//             variablesClone.filter(hidVar => hidVar.id == "totalSamplePerCountryPerOPtion")[0].value =
//                 (parseInt(variablesClone.filter(hidVar => hidVar.id == "numberofTestScenario")[0].value) + parseInt(variablesClone.filter(hidVar => hidVar.id == "numberofControlScenario")[0].value)) * value;
//         if (name == "Type Of Venue") {//make it to id, not sure why id isn't working
//             let typeOfChannel = variablesClone.find(vc => vc.id == "typeOfChannel")
//             typeOfChannel.options = value == "Store" ? [
//                 { label: "Hypermarket", value: "Hypermarket" },
//                 { label: "Supermarket", value: "Supermarket" },
//                 { label: "Convenience", value: "Convenience" },
//                 { label: "Discounter", value: "Discounter" },
//                 { label: "Traditional Trade", value: "Traditional Trade" },
//                 { label: "On-trade", value: "On-trade" },
//                 { label: "Gas/Petrol Station", value: "Gas/Petrol Station" },
//                 { label: "Other", value: "Other" }
//             ] : value == "Restaurants/pubs" ? [
//                 { label: "Restaurant", value: "Restaurant" },
//                 { label: "Pub", value: "Pub" }
//             ] : value == "Leaflets/App/Web" ? [
//                 { label: "Leaflet", value: "Leaflet" }
//             ] : []
//         }
//         variablesClone.forEach((v, indexV) => {
//             if (v.label != currentVariable.label && v.dependentOn && v.dependentOn.fields.find(f => f.fieldName == currentVariable.label)) {

//                 let op = v.dependentOn.andor == "OR" ? "||" : "&&";
//                 let expression = "";
//                 v.dependentOn.fields.forEach((f, indexF) => {

//                     let fieldVal = f.fieldName != currentVariable.label ? _.head(variablesClone.filter(v => v.label == f.fieldName))?.value : currentVariable["value"];
//                     if (Array.isArray(fieldVal)) {
//                         let tempBool = variablesClone.filter(v => v.label == f.fieldName)[0]["value"].filter(x => x.label == f.fieldValue).length > 0;
//                         expression += tempBool + f.operator + "true" + (indexF != v.dependentOn.fields.length - 1 ? op : '');
//                     }
//                     else {
//                         expression += getChangedValue(fieldVal) + f.operator + getChangedValue(f.fieldValue) + (indexF != v.dependentOn.fields.length - 1 ? op : '');
//                     }
//                 });

//                 let enabled = eval(expression);
//                 if (enabled)
//                     v.disabled = false;
//                 else {
//                     v.disabled = true;
//                     v.value = null;
//                     variablesClone.forEach((v2, indexV2) => {
//                         if (v2.dependentOn && v2.dependentOn.fields.find(f => f.fieldName == v.label)) {
//                             v2.disabled = true;
//                             v2.value = null;
//                         }
//                     });
//                 }
//             }
//         });


//         let arr = [];
//         let rfqVariableClone = _.cloneDeep(profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].Variables);
//         rfqVariableClone.forEach((v, indexV) => {
//             if (v.label != currentVariable.label && v.dependentOn && v.dependentOn.fields.find(f => f.fieldName == currentVariable.label)) {
//                 arr.push({ label: v.label });
//             }
//         });
//         rfqVariableClone = OtherVariablesShowHide(indexCO, currentVariable, rfqVariableClone, arr);

//         arr = [];
//         let opResourcesVariablesClone = _.cloneDeep(profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].OpResourcesVariables);
//         opResourcesVariablesClone.forEach((v, indexV) => {
//             if (v.label != currentVariable.label && v.dependentOn && v.dependentOn.fields.find(f => f.fieldName == currentVariable.label)) {
//                 arr.push({ label: v.label });
//             }
//         });
//         opResourcesVariablesClone = OtherVariablesShowHide(indexCO, currentVariable, opResourcesVariablesClone, arr);
//         isEditable(true);


//         let profileTemp = _.cloneDeep(profile)
//         profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex] = {
//             ...profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex]
//             , Variables: rfqVariableClone
//             , OpResourcesVariables: opResourcesVariablesClone
//         }

//         if (profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[indexCO])
//             profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[indexCO].Variables = variablesClone

//         let costingOptionsClone = _.cloneDeep(profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions)
//         //not required, just for cloning, felt cute, will delete later
//         profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions = costingOptionsClone
//         updateProjectAndProfileWRTCostingOption(profileTemp)
//     }

//     function OtherVariablesShowHide(indexCO, currentVar, variablesClone, arr) {

//         const costingOptionsClone = _.cloneDeep(methodology.CostingOptions);
//         costingOptionsClone.forEach((co, index_co) => {
//             let currentVariable = index_co == indexCO ? currentVar : co.Variables.filter(x => x.label == currentVar.label)[0];
//             if (arr.filter(x => !x.done).length == 0)
//                 return;
//             variablesClone.forEach((v, indexV) => {
//                 if (v.label != currentVariable.label && v.dependentOn && v.dependentOn.fields.find(f => f.fieldName == currentVariable.label)) {
//                     if (arr.filter(x => x.label == v.label)[0].done)
//                         return;
//                     let op = v.dependentOn.andor == "OR" ? "||" : "&&";
//                     let expression = "";
//                     v.dependentOn.fields.forEach((f, indexF) => {
//                         let fieldVal = f.fieldName != currentVariable.label ? variablesClone.filter(v => v.label == f.fieldName)[0]?.value : currentVariable["value"];
//                         if (Array.isArray(fieldVal)) {
//                             let tempBool = false
//                             if (variablesClone.filter(v => v.label == f.fieldName)[0]["value"]) {
//                                 tempBool = variablesClone.filter(v => v.label == f.fieldName)[0]["value"].filter(x => x.label == f.fieldValue).length > 0;
//                             }
//                             else if (currentVariable["value"]) {
//                                 tempBool = currentVariable["value"].filter(x => x.label == f.fieldValue).length > 0;
//                             }
//                             expression += tempBool + f.operator + "true" + (indexF != v.dependentOn.fields.length - 1 ? op : '');
//                         }
//                         else {
//                             expression += getChangedValue(fieldVal) + f.operator + getChangedValue(f.fieldValue) + (indexF != v.dependentOn.fields.length - 1 ? op : '');
//                         }
//                     });
//                     let enabled = eval(expression);
//                     if (enabled) {
//                         //custom code starts
//                         if (v.label == 'Approx Percentage Change - Each Wave' && currentVariable.label == 'Tracking Project') {

//                             if (currentVariable["value"] == 'Yes') {
//                                 v.enabled = true;
//                                 v.disabled = false;
//                                 arr.filter(x => x.label == v.label)[0].done = true;
//                             }
//                             else {
//                                 v.enabled = false;
//                                 v.disabled = true;
//                                 v.value = null;
//                             }
//                         }
//                         //custom code starts
//                         else {
//                             v.disabled = false;
//                             arr.filter(x => x.label == v.label)[0].done = true;
//                         }
//                     }
//                     else {
//                         v.disabled = true;
//                         v.value = null;
//                         variablesClone.forEach((v2, indexV2) => {
//                             if (v2.dependentOn && v2.dependentOn.fields.find(f => f.fieldName == v.label)) {
//                                 v2.disabled = true;
//                                 v2.value = null;
//                             }
//                         });
//                     }
//                 }
//             });
//         });
//         return variablesClone;
//     }

//     function FoundDependent(currentVariable, variablesClone) {
//         let found = false;
//         variablesClone.forEach((v, indexV) => {
//             if (v.label != currentVariable.label && v.dependentOn && v.dependentOn.fields.find(f => f.fieldName == currentVariable.label)) {
//                 found = true;
//             }
//         });
//         return found;
//     }

//     function getChangedValue(value) {
//         let rValue = null;
//         if (isNaN(value))
//             rValue = "'" + value + "'";
//         else
//             rValue = value;
//         return rValue;
//     }

//     const checkChangeVariable = (indexV, value) => {

//         const addVariablesClone = _.cloneDeep(addVariables);

//         addVariablesClone.forEach((x, index) => {
//             if (index == indexV) {
//                 x["selected"] = value;
//                 x["value"] = null;
//             }
//             else if (x.dependentOn && x.dependentOn.fields.find(f => f.fieldName == addVariablesClone[indexV].label) && (x.AddToCostingOption || x.sectionTitle == addVariablesClone[indexV].sectionTitle)) {

//                 x["selected"] = value;
//                 if (value == true)
//                     x.disabled = true;
//             }
//         });
//         setAddVariables(addVariablesClone);
//     }

//     function SetAdditionalVariables() {
//         let RFQVariables = _.cloneDeep(methodology.Variables);
//         RFQVariables.forEach(v => {
//             if (!v.Default && _.head(methodology?.CostingOptions)?.Variables && _.includes(_.head(methodology.CostingOptions).Variables.map(cov => cov.id), v.id)) {
//                 v["selected"] = true
//             }
//             v.RFQ = true;
//             v.show = false;
//         });
//         let OPResourceVariables = _.cloneDeep(methodology.OpResourcesVariables);
//         OPResourceVariables.forEach(v => {
//             if (!v.Default && _.head(methodology?.CostingOptions)?.Variables && _.includes(_.head(methodology.CostingOptions).Variables.map(cov => cov.id), v.id)) {
//                 v["selected"] = true
//             }
//             v.OPR = true;
//             v.show = false;
//         });

//         let tempVariables = [...RFQVariables, ...OPResourceVariables];
//         setAddVariables(tempVariables);
//     }

//     function SearchValueChanged(searchValue) {
//         let tempAddVariables = _.cloneDeep(addVariables);
//         if (searchValue && searchValue.length >= 3) {
//             tempAddVariables.forEach(av => {
//                 const co = _.cloneDeep(profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[0]);
//                 const parentFound = av.dependentOn && av.dependentOn.fields.some(f => co.Variables.find(v => v.label == f.fieldName));
//                 const matchFound = av.label.replace(/\s+/g, '').toLowerCase().includes(searchValue.replace(/\s+/g, '').toLowerCase());
//                 if (av.selected)
//                     av.show = true;
//                 else if ((matchFound && !av.disabled) || (matchFound && parentFound))
//                     av.show = true;
//                 else
//                     av.show = false;
//             });
//         }
//         else {
//             tempAddVariables.forEach(av => {
//                 if (av.selected && !av.dependentOn)
//                     av.show = true;
//                 else
//                     av.show = false;
//             });
//         }
//         setAddVariables(tempAddVariables);
//     }

//     function AddRemoveOptionalVariables() {
//         const costingOptionsClone = _.cloneDeep(methodology.CostingOptions);
//         const addVariablesClone = _.cloneDeep(addVariables);

//         costingOptionsClone.forEach((co, indexCO) => {
//             const variablesClone = _.cloneDeep(co.Variables);
//             let tempVariables = [];
//             tempVariables = variablesClone.filter((vc, indexVC) => vc.Default);
//             addVariablesClone.filter(mv => mv.selected).forEach((mv, indexMV) => {
//                 const indexVar = variablesClone.findIndex(vc => vc.label == mv.label);
//                 if (indexVar > -1)
//                     tempVariables.push(_.cloneDeep(variablesClone[indexVar]));
//                 else {
//                     mv.value = null;
//                     tempVariables.push(_.cloneDeep(mv));
//                 }
//             });

//             co.Variables = tempVariables;
//         });

//         // let profileTemp = update(profile, {
//         //     CountrySpecs: {
//         //         [countryIndex]: {
//         //             MethodologySpecs:
//         //             {
//         //                 [methodologyIndex]:
//         //                 {
//         //                     CostingOptions: { $set: costingOptionsClone },
//         //                     Variables: { $set: addVariables }
//         //                 }
//         //             }
//         //         }
//         //     }
//         // });
//         let profileTemp = _.cloneDeep(profile)
//         profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex] = {
//             ...profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex],
//             CostingOptions: costingOptionsClone,
//             Variables: addVariables
//         }
//         // let costingOptionsClone = _.cloneDeep(profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions)
//         // profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions = costingOptionsClone
//         updateProjectAndProfileWRTCostingOption(profileTemp)

//     }

//     function AddRemoveOptionalVariablesNew() {
//         const costingOptionsClone = _.cloneDeep(methodology.CostingOptions);
//         const addVariablesClone = _.cloneDeep(addVariables);
//         const RFQVariables = _.cloneDeep(addVariables.filter(v => v.RFQ));
//         const OPResourceVariables = _.cloneDeep(addVariables.filter(v => v.OPR));
//         setModelStateVar(!modelStateVar);
//         const titleAddedVariables = [{ label: "RFQ Form", flag: false, costingheader: true }, { label: "Setup & Delivery", flag: false, costingheader: true }];
//         costingOptionsClone.forEach((co, indexCO) => {
//             const coVariablesClone = _.cloneDeep(co.Variables);
//             let tempVariables = [];
//             tempVariables = coVariablesClone.filter((vc, indexVC) => vc.Default);
//             addVariablesClone.filter(av => av.selected).forEach((av, indexAV) => {
//                 const indexVar = coVariablesClone.findIndex(vc => vc.label == av.label);
//                 if (indexVar > -1) {
//                     let temp = _.cloneDeep(coVariablesClone[indexVar]);
//                     tempVariables.push(temp);
//                 }
//                 else {

//                     av.parent = av.label == 'Data Processing Required' ? "Data Processing" : av.sectionTitle;
//                     av.value = null;
//                     if (RFQVariables.findIndex(x => x.id == av.id && x.label == av.label) > -1)
//                         av.form = "RFQ Form";

//                     if (OPResourceVariables.findIndex(x => x.id == av.id && x.label == av.label) > -1)
//                         av.form = "Setup & Delivery";

//                     tempVariables.push(_.cloneDeep(av));
//                 }
//             });
//             co.Variables = tempVariables;
//         });

//         let distinctParents = costingOptionsClone[0].Variables.filter(v => !v.Default).map(v => v.parent).filter((item, i, ar) => ar.indexOf(item) === i);

//         costingOptionsClone.forEach((co, indexCO) => {
//             const coVariablesClone = _.cloneDeep(co.Variables);
//             let tempVariables = [];
//             tempVariables = coVariablesClone.filter((vc, indexVC) => vc.Default);
//             distinctParents.forEach(p => {
//                 coVariablesClone.filter(v => !v.Default && v.parent == p).forEach(v => {
//                     if (v.form == "RFQ Form" && !titleAddedVariables[0].flag) {
//                         titleAddedVariables[0].flag = true;
//                         tempVariables.push(titleAddedVariables[0]);
//                     }
//                     if (v.form == "Setup & Delivery" && !titleAddedVariables[1].flag) {
//                         titleAddedVariables[1].flag = true;
//                         tempVariables.push(titleAddedVariables[1]);
//                     }

//                     tempVariables.push(_.cloneDeep(v));
//                 });
//             });
//             titleAddedVariables[0].flag = false;
//             titleAddedVariables[1].flag = false;
//             co.Variables = tempVariables;
//         });

//         // let profileTemp = update(profile, {
//         //     CountrySpecs: {
//         //         [countryIndex]: {
//         //             MethodologySpecs:
//         //             {
//         //                 [methodologyIndex]:
//         //                 {
//         //                     CostingOptions: { $set: costingOptionsClone },
//         //                     Variables: { $set: RFQVariables },
//         //                     OpResourcesVariables: { $set: OPResourceVariables }
//         //                 }
//         //             }
//         //         }
//         //     }
//         // });
//         let profileTemp = _.cloneDeep(profile)
//         profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex] = {
//             ...profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex]
//             , CostingOptions: costingOptionsClone
//             , Variables: RFQVariables
//             , OpResourcesVariables: OPResourceVariables
//         }
//         // let costingOptionsClone = _.cloneDeep(profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions)
//         // profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions = costingOptionsClone
//         updateProjectAndProfileWRTCostingOption(profileTemp)

//         ClearSearch();
//     }

//     function RemoveAdditionalVariable(v, indexV) {
//         const costingOptionsClone = _.cloneDeep(methodology.CostingOptions);
//         const addVariablesClone = _.cloneDeep(methodology.Variables);
//         const OpResourcesVariables = _.cloneDeep(methodology.OpResourcesVariables);

//         addVariablesClone.forEach((x, index) => {
//             if (x.label == v.label) {
//                 x.selected = false;
//                 x.value = null;
//             }
//             else if (x.dependentOn && x.dependentOn.fields.find(f => f.fieldName == v.label)) {
//                 x.selected = false;
//                 x.disabled = true;
//                 x.value = null;
//             }
//         });

//         OpResourcesVariables.forEach((x, index) => {
//             if (x.label == v.label) {
//                 x.selected = false;
//                 x.value = null;
//             }
//             else if (x.dependentOn && x.dependentOn.fields.find(f => f.fieldName == v.label)) {
//                 x.selected = false;
//                 x.disabled = true;
//                 x.value = null;
//             }
//         });

//         costingOptionsClone.forEach((co, indexCO) => {
//             let variablesClone = _.cloneDeep(co.Variables);
//             variablesClone = variablesClone.filter((x, index) => index != indexV && (!x.dependentOn || !x.dependentOn.fields.find(f => f.fieldName == v.label)));
//             const rfqvariables = variablesClone.filter(x => x.form == "RFQ Form").length;
//             variablesClone = variablesClone.filter(x => rfqvariables > 0 ? x == x : x.label != "RFQ Form");
//             const stpvariables = variablesClone.filter(x => x.form == "Setup & Delivery").length;
//             variablesClone = variablesClone.filter(x => stpvariables > 0 ? x == x : x.label != "Setup & Delivery");
//             co.Variables = variablesClone;
//         });

//         // let profileTemp = update(profile, {
//         //     CountrySpecs: {
//         //         [countryIndex]: {
//         //             MethodologySpecs:
//         //             {
//         //                 [methodologyIndex]:
//         //                 {
//         //                     CostingOptions: { $set: costingOptionsClone },
//         //                     Variables: { $set: addVariablesClone },
//         //                     OpResourcesVariables: { $set: OpResourcesVariables }
//         //                 }
//         //             }
//         //         }
//         //     }
//         // });
//         let profileTemp = _.cloneDeep(profile)
//         profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex] = {
//             ...profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex]
//             , CostingOptions: costingOptionsClone
//             , Variables: addVariablesClone
//             , OpResourcesVariables: OpResourcesVariables
//         }
//         updateProjectAndProfileWRTCostingOption(profileTemp)
//     }

//     function CopyCostingOption(indexCO) {
//         let costingOption = _.cloneDeep(methodology.CostingOptions[indexCO]);
//         delete costingOption["id"];
//         //to avoid copying costs data 
//         Object.keys(costingOption)?.filter(c => c.toLowerCase().indexOf("cost") > -1).map(costKey => {
//             costingOption[costKey] = null
//         })
//         costingOption.CostsData = null
//         costingOption.TimingsData = null
//         const costingOptionsClone = _.cloneDeep(methodology.CostingOptions);
//         costingOptionsClone.splice(indexCO + 1, 0, costingOption);
//         if (costingOption.label.includes("copy")) {
//             var regex = /[\d|,|.|e|E|\+]+/g;
//             const nameCostingOption = costingOption.label.split("-")[1];
//             const copyPart = costingOption.value.split("-")[0];
//             var copyNumber = copyPart.match(regex);
//             costingOption.value = "copy" + (copyNumber + 1) + "_" + costingOption.label;
//         }
//         else {
//             costingOption.label = "copy_" + costingOption.label;
//             costingOption.value = "copy1_" + costingOption.label;
//         }
//         // let profileTemp = update(profile, {
//         //     CountrySpecs: {
//         //         [countryIndex]: {
//         //             MethodologySpecs:
//         //             {
//         //                 [methodologyIndex]:
//         //                 {
//         //                     CostingOptions: { $set: costingOptionsClone }
//         //                 }
//         //             }
//         //         }
//         //     }
//         // });
//         let profileTemp = _.cloneDeep(profile)
//         profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions = costingOptionsClone
//         updateProjectAndProfileWRTCostingOption(profileTemp)
//     }

//     function RefreshCostingOption(indexCO) {

//         const costingOption = _.cloneDeep(methodology.CostingOptions[indexCO]);
//         costingOption.Variables.forEach(v => {
//             if (v.type == 'single')
//                 v.value = '';
//             else
//                 v.value = null;
//         });
//         // let profileTemp = update(profile, {
//         //     CountrySpecs: {
//         //         [countryIndex]: {
//         //             MethodologySpecs:
//         //             {
//         //                 [methodologyIndex]:
//         //                 {
//         //                     CostingOptions:
//         //                     {
//         //                         [indexCO]: { $set: costingOption }
//         //                     }
//         //                 }
//         //             }
//         //         }
//         //     }
//         // });
//         let profileTemp = _.cloneDeep(profile)
//         let costingOptionsClone = _.cloneDeep(profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions)
//         costingOptionsClone[indexCO] = costingOption
//         profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions = costingOptionsClone
//         updateProjectAndProfileWRTCostingOption(profileTemp)

//     }

//     function RenameClicked(indexCO) {
//         // let profileTemp = update(profile, {
//         //     CountrySpecs: {
//         //         [countryIndex]: {
//         //             MethodologySpecs:
//         //             {
//         //                 [methodologyIndex]:
//         //                 {
//         //                     CostingOptions:
//         //                     {
//         //                         [indexCO]: {
//         //                             $merge: { "rename": true }
//         //                         }
//         //                     }
//         //                 }
//         //             }
//         //         }
//         //     }
//         // });
//         let profileTemp = _.cloneDeep(profile)
//         let costingOptionsClone = _.cloneDeep(profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions)
//         costingOptionsClone[indexCO] = { ...costingOptionsClone[indexCO], "rename": true }
//         profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions = costingOptionsClone
//         updateProjectAndProfileWRTCostingOption(profileTemp)
//     }


//     function RenameCostingProfile(indexCO, value) {
//         // let profileTemp = update(profile, {
//         //     CountrySpecs: {
//         //         [countryIndex]: {
//         //             MethodologySpecs:
//         //             {
//         //                 [methodologyIndex]:
//         //                 {
//         //                     CostingOptions:
//         //                     {
//         //                         [indexCO]: {
//         //                             $merge: { "label": value }
//         //                         }
//         //                     }
//         //                 }
//         //             }
//         //         }
//         //     }
//         // });
//         let profileTemp = _.cloneDeep(profile)
//         let costingOptionsClone = _.cloneDeep(profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions)
//         costingOptionsClone[indexCO] = { ...costingOptionsClone[indexCO], "label": value }
//         profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions = costingOptionsClone
//         updateProjectAndProfileWRTCostingOption(profileTemp)
//     }
//     function selectCostingOption(indexCO, e) {

//         if (!e.target.checked) {
//             let _profileTemp = _.cloneDeep(profile)
//             let _costingOptionsClone = _.cloneDeep(_profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions)
//             let reaminingCostingOption = _costingOptionsClone.filter((co, Coindex) => Coindex !== indexCO)
//             if ((reaminingCostingOption.length && reaminingCostingOption.every(co => !co.isSelected))
//                 || _costingOptionsClone.length === 1
//             ) {
//                 toastr.info("One Costing Option from Every Methodology Must be Selected")
//                 return
//             }
//         }
//         // let profileTemp = update(profile, {
//         //     CountrySpecs: {
//         //         [countryIndex]: {
//         //             MethodologySpecs:
//         //             {
//         //                 [methodologyIndex]:
//         //                 {
//         //                     CostingOptions:
//         //                     {
//         //                         [indexCO]: {
//         //                             $merge: { "isSelected": e.target.checked ? true : false }
//         //                         }
//         //                     }
//         //                 }
//         //             }
//         //         }
//         //     }
//         // });
//         let profileTemp = _.cloneDeep(profile)
//         let costingOptionsClone = _.cloneDeep(profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions)
//         costingOptionsClone[indexCO] = { ...costingOptionsClone[indexCO], "isSelected": e.target.checked ? true : false }
//         profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions = costingOptionsClone
//         updateProjectAndProfileWRTCostingOption(profileTemp)
//     }
//     function RenameDone(indexCO) {
//         // let profileTemp = update(profile, {
//         //     CountrySpecs: {
//         //         [countryIndex]: {
//         //             MethodologySpecs:
//         //             {
//         //                 [methodologyIndex]:
//         //                 {
//         //                     CostingOptions:
//         //                     {
//         //                         [indexCO]: {
//         //                             $merge: { "rename": false }
//         //                         }
//         //                     }
//         //                 }
//         //             }
//         //         }
//         //     }
//         // });
//         let profileTemp = _.cloneDeep(profile)
//         let costingOptionsClone = _.cloneDeep(profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions)
//         costingOptionsClone[indexCO] = { ...costingOptionsClone[indexCO], "rename": false }
//         profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions = costingOptionsClone
//         updateProjectAndProfileWRTCostingOption(profileTemp)
//     }

//     return _updatedStatus;
//   };

    const errorStyles = {

        control: (styles) => ({ ...styles, borderColor: "#AD4400" })


    }
     var prodlength = product.length;
    //  let copyIndexCO;
    //  const setCopyIndexCO = (indexCO) => {
    //   debugger;
    //   copyIndexCO = indexCO
    //   console.log(copyIndexCO, indexCO)
    //   debugger;
    //  }
    const SingleCostOpt = () => {
       // pusing prod, opp line item id for single costoption
       let SMCODE = profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].Code
    let filterbySM=[]
        while(typeof profile.ProductTypeData === "string"){
              profile.ProductTypeData = JSON.parse(profile.ProductTypeData)
              }
        filterbySM = profile.ProductTypeData.filter ((item) => item.Code === SMCODE);
       const hasProductVar = profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[0]?.Variables.some(variable => {
          return variable.label === "Product";
        });
      
      if (!hasProductVar) {
        
        //var cntDefault = costingOptionsClone.Variables.filter(x => x.Default).length;
        let filterbySMcode=[]
        while(typeof profile.ProductTypeData === "string"){
          profile.ProductTypeData = JSON.parse(profile.ProductTypeData)
              }
        filterbySMcode = profile.ProductTypeData.filter ((item) => item.Code === SMCODE);
          if(!profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[0]?.Variables.some(variable => variable.label === "Product")) {
            profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[0]?.Variables.splice(0,0, {
                        label: "Product", 
                        id: "producttype", 
                        type: "single", 
                        Default: true,
                        "options": filterbySMcode
            });
          }


      }

      profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[0].Variables.find((c) => c.id === "producttype").options= filterbySM


       if (profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[0]?.Variables.some(element => {return element.label === "Product"})) {
                //product
                profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[0].Variables.find((c) => c.id === "producttype").value 
                = profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[0].Variables.find((c) => c.id === "producttype").options[0].Productname;
                // Opp line item id
                profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[0].OpportunityLineItemID 
                = profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[0].Variables.find((c) => c.id === "producttype"    
                      ).options[0].OpportunityLineItemID
                      profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[0].isSelected 
                = !(profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[0].Variables.find((c) => c.id === "producttype").options[0]?.isRed)
                profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[0].Variables.find((c) => c.id === "producttype").isRed 
                = (profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[0].Variables.find((c) => c.id === "producttype").options[0]?.isRed)
                setSingleCost(true);

       }
       updateProjectAndProfileWRTCostingOption(profile);
    }
    // singleCostTest
    const singleCostTest = () => {
      // pusing prod, opp line item id for single costoption
      let SMCODE = profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].Code
   let filterbySM=[]
       while(typeof profile.ProductTypeData === "string"){
             profile.ProductTypeData = JSON.parse(profile.ProductTypeData)
             }
       filterbySM = profile.ProductTypeData.filter ((item) => item.Code === SMCODE);
      const hasProductVar = profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[0]?.Variables.some(variable => {
         return variable.label === "Product";
       });
     
     if (!hasProductVar) {
       
       //var cntDefault = costingOptionsClone.Variables.filter(x => x.Default).length;
       let filterbySMcode=[]
       while(typeof profile.ProductTypeData === "string"){
         profile.ProductTypeData = JSON.parse(profile.ProductTypeData)
             }
       filterbySMcode = profile.ProductTypeData.filter ((item) => item.Code === SMCODE);
         if(!profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[0]?.Variables.some(variable => variable.label === "Product")) {
           profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[0]?.Variables.splice(0,0, {
                       label: "Product", 
                       id: "producttype", 
                       type: "single", 
                       Default: true,
                       "options": filterbySMcode
           });
         }


     }

     profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[0].Variables.find((c) => c.id === "producttype").options= filterbySM


      if (profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[0]?.Variables.some(element => {return element.label === "Product"})) {
                     if(profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[0].isSelected 
               == !(profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[0].Variables.find((c) => c.id === "producttype").options[0]?.isRed))
{
  return false
} else {
  return true
}
      }
      // updateProjectAndProfileWRTCostingOption(profile);

   }
    const CopywithOpp = (indexCO) => {
      
       // console.log("inside event", indexCO)
       // console.log("costingoptionsLength", currentCostingProfile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions.length)
       // console.log("costingoptionsValue", currentCostingProfile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions)
        if ( profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions.length < len && indexCO === 0) {
         // console.log("inside copyfunction")
          var costoplen = profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions.length -1
          CopyCostingOption(indexCO, costoplen)
          
        } 
    //     else if (currentCostingProfile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions.length === product.length && indexCO === 0) {
    //       var costing = currentCostingProfile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions;
    // var costfilter =   costing.filter((e) => 
    //     e.value !== "Default Option"
    //   )
    //   console.log("costfilter", costfilter);
    //   var costrev = costfilter.reverse();
    //   console.log("costrev", costrev);
    //   currentCostingProfile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions.push(costrev);
    //     }
      
    }
    const UpdateDelCostOpt = (indexCO) => {
      let SMCODE = profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].Code
      let filterbySMcode=[]
       while(typeof profile.ProductTypeData === "string"){
        profile.ProductTypeData = JSON.parse(profile.ProductTypeData)
             }
       filterbySMcode = profile.ProductTypeData.filter ((item) => item.Code === SMCODE);
      // console.log("productNamestoCheck",productNamestoCheck);
      let product = profile?.CountrySpecs[countryIndex]?.MethodologySpecs[methodologyIndex]?.CostingOptions[0]?.Variables?.find((c) => c.id === "producttype").value !== undefined ?  profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[0].Variables.find((c) => c.id === "producttype").value : profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[0].CostingOptionData.producttype
      let isNew = /^\d/.test(product);
      if (isNew) {
        let ProdNameCostOption = profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions.map((option,index) => {
          let p = option.Variables && option.Variables.find((c) => c.id === "producttype").value !== undefined ?  option.Variables.find((c) => c.id === "producttype").value : option.CostingOptionData.producttype
          // console.log(p);
          // console.log("productNamestoCheck check",productNamestoCheck.includes(p))
          return p;
        });
        // console.log("ProdNameCostOption", ProdNameCostOption);
        let test = productNamestoCheck.every(i => ProdNameCostOption.includes(i));
        // console.log("test1613", test);
        if (!test) {
          // let newCostOptionsList = productNamestoCheck.filter(i => !ProdNameCostOption.includes(i));
          let newCostOptionsList = productNamestoCheck.map((value, index) => ({index, value})).filter(({value}) => !ProdNameCostOption.includes(value))
          // console.log("newCostOptionsList", newCostOptionsList);
          newCostOptionsList.map((item,index) => {
            var costoplen = profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions.length -1
            // console.log("items", item.index);
            
            CopyCostingOption(indexCO, costoplen, item.index)
          })
        }
      } else {
        let LineItemCostOption = profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions.map((option,index) => {
          let p = option.OpportunityLineItemID
          // console.log(p);
          // console.log("productNamestoCheck check",productNamestoCheck.includes(p))
          return parseInt(p);
        });
        // console.log("ProdNameCostOption", ProdNameCostOption);
        let LineItemidstocheck = filterbySMcode.filter(j => typeof j.OpportunityLineItemID === 'number').map(i => i.OpportunityLineItemID);
        let test = LineItemidstocheck.every(i => LineItemCostOption.includes(i));
        // console.log("test1613", test);
        if (!test) {
          // let newCostOptionsList = productNamestoCheck.filter(i => !ProdNameCostOption.includes(i));
          let newCostOptionsList = filterbySMcode.map((value, index) => ({index, value})).filter(({value}) => (typeof value.OpportunityLineItemID === 'number') && (!LineItemCostOption.includes(value.OpportunityLineItemID)))
          // console.log("newCostOptionsList", newCostOptionsList);
          newCostOptionsList.map((item,index) => {
            var costoplen = profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions.length -1
            // console.log("items", item.index);
            
            CopyCostingOption(indexCO, costoplen, item.index)
          })
        }
      }
      

    }
    const UpdateCostOpt = (indexCO) => {
      //No Copied Cost Options

      let SMCODE = profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].Code

       let filterbySMcode=[]
       while(typeof profile.ProductTypeData === "string"){
        profile.ProductTypeData = JSON.parse(profile.ProductTypeData)
             }
       filterbySMcode = profile.ProductTypeData.filter ((item) => item.Code === SMCODE);
       filterbySMcode.map((c,i) => {
        profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[i].Variables =   
       profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[i]?.Variables.map(v => {
          if(v.label === "Product") {
            return {
              label: "Product", 
              id: "producttype", 
              type: "single", 
              Default: true,
              "options": filterbySMcode
            };
          } else {
            return v;
          }
        }) 

      if (profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[i]?.Variables.some(element => {return element.label === "Product"})) {
               //product
               profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[i].Variables.find((c) => c.id === "producttype").value 
               = profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[i].Variables.find((c) => c.id === "producttype").options[i].Productname;
               // Opp line item id
               profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[i].OpportunityLineItemID 
               = profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[i].Variables.find((c) => c.id === "producttype"    
                     ).options[i].OpportunityLineItemID
                     profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[i].isSelected 
              = !(profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[i].Variables.find((c) => c.id === "producttype").options[i]?.isRed)
              profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[i].Variables.find((c) => c.id === "producttype").isRed
              = profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[i].Variables.find((c) => c.id === "producttype").options[i]?.isRed

      }
       })
       
      setUpdateCostOpt(true);
      updateProjectAndProfileWRTCostingOption(profile);

   }
   const UpdateCostOptCopy = (indexCO) => {

    let SMCODE = profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].Code

       let filterbySMcode=[]
       while(typeof profile.ProductTypeData === "string"){
        profile.ProductTypeData = JSON.parse(profile.ProductTypeData)
             }
       filterbySMcode = profile.ProductTypeData.filter ((item) => item.Code === SMCODE);

      //  let Variables = currentCostingProfile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions.map((co,index) => {
      //   co.Variables
      //  } )
      let delFilSMCode = [];
      let uniqueValues = [];
      let duplicateValues = []; 
      let uniqueLineid = [];
      let duplicateLineId = [];
      let duplicateIndices = []; 
      let uniqueIncices = []; 
      // profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions.forEach((option, index) => {
      //   // const productVariables = option.Variables.filter(variable => variable.label === "Product");

      // });
      let currenProf = _.cloneDeep(profile);
      profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions.map( (option,index) => {

            //copied costoption
            // updating variables
            option.Variables =  option.Variables && 
            option.Variables.map(v => {
          if(v.label === "Product") {
            return {
              label: "Product", 
              id: "producttype", 
              type: "single", 
              Default: true,
              "options": filterbySMcode,
              value: v?.value
            };
          } else {
            return v;
          }
        })
        // console.log("option.Variables",option.Variables);
        // identifying producttypedata index for copied cost options

          let p = option.Variables.find((c) => c.id === "producttype").value !== undefined ?  option.Variables.find((c) => c.id === "producttype").value : option.CostingOptionData.producttype
          let q = /^\d/.test(p);
          let lineItemid = option.OpportunityLineItemID;
          let dupindex = q ? option.Variables.find((c) => c.id === "producttype").options.find((d) => d.Productname === p ) : option.Variables.find((c) => c.id === "producttype").options.find((d) => d.OpportunityLineItemID === parseInt(lineItemid) )
        // console.log("dupindex", dupindex);
        // updating variables.value
        if (dupindex === undefined && typeof parseInt(option.OpportunityLineItemID) === 'number' ) {
          delFilSMCode = filterbySMcode.filter(i => i?.isRed === true);
          let value = parseInt(option.OpportunityLineItemID)
          if (uniqueLineid.includes(value)) {
            duplicateValues.push(option);
            duplicateLineId.push(value);
            duplicateIndices.push(index);
          } else {
            uniqueValues.push(option);
            uniqueLineid.push(value);
            uniqueIncices.push(index);
          }

        }
      }

      );

      let i=0;
      profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions.map( (option,index) => {

        let p = option.Variables.find((c) => c.id === "producttype").value !== undefined ?  option.Variables.find((c) => c.id === "producttype").value : option.CostingOptionData.producttype
          let q = /^\d/.test(p);
          let lineItemid = parseInt(option.OpportunityLineItemID);
          let dupindex = q ? option.Variables.find((c) => c.id === "producttype").options.find((d) => d.Productname === p ) : option.Variables.find((c) => c.id === "producttype").options.find((d) => d.OpportunityLineItemID === parseInt(lineItemid) )
          
          if (dupindex === undefined && typeof parseInt(option.OpportunityLineItemID) === 'number' ) {

            if (duplicateIndices.includes(index)) {
              
          // updating variables.value
          if (option.Variables && option.Variables.some(element => {return element.label === "Product"})) {
            //product
            option.Variables.find((c) => c.id === "producttype").value 
            = delFilSMCode[i-1].Productname;
            // Opp line item id
            option.OpportunityLineItemID 
            = delFilSMCode[i-1].OpportunityLineItemID
            option.isSelected 
            = !(delFilSMCode[i-1]?.isRed)
            option.Variables.find((c) => c.id === "producttype").isRed 
            = (delFilSMCode[i-1]?.isRed)     
            }
  
          } else {

          // updating variables.value
          if (option.Variables && option.Variables.some(element => {return element.label === "Product"})) {
            //product
            option.Variables.find((c) => c.id === "producttype").value 
            = delFilSMCode[i].Productname;
            // Opp line item id
            option.OpportunityLineItemID 
            = delFilSMCode[i].OpportunityLineItemID
            option.isSelected 
            = !(delFilSMCode[i]?.isRed)
            option.Variables.find((c) => c.id === "producttype").isRed
            = (delFilSMCode[i]?.isRed)
            }
            i++;
        }

        } else if (option.Variables && option.Variables.some(element => {return element.label === "Product"})) {
        //product
        option.Variables.find((c) => c.id === "producttype").value 
        = dupindex.Productname;
        // Opp line item id
        option.OpportunityLineItemID 
        = dupindex.OpportunityLineItemID
        option.isSelected 
        = !(dupindex?.isRed)
        option.Variables.find((c) => c.id === "producttype").isRed 
        = (dupindex?.isRed)     
        }
      })
      // if (option.Variables && option.Variables.some(element => {return element.label === "Product"})) {
      //   //product
      //   option.Variables.find((c) => c.id === "producttype").value 
      //   = dupindex.Productname;
      //   // Opp line item id
      //   option.OpportunityLineItemID 
      //   = dupindex.OpportunityLineItemID
      //   option.isSelected 
      //   = !(dupindex?.isRed)
      //   option.Variables.find((c) => c.id === "producttype").isRed 
      //   = (dupindex?.isRed)     
      //   }

      
      // setCostOptCopy(true);
      dispatch(updateProfile(profile));
        dispatch(updateProject({ CostingProfiles: profile }));
   
    updateProjectAndProfileWRTCostingOption(profile);



   }
   //costOptCopyTest
   const costOptCopyTest = (indexCO) => {

    let SMCODE = profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].Code

       let filterbySMcode=[]
       while(typeof profile.ProductTypeData === "string"){
        profile.ProductTypeData = JSON.parse(profile.ProductTypeData)
             }
       filterbySMcode = profile.ProductTypeData.filter ((item) => item.Code === SMCODE);
      let delFilSMCode = [];
      let uniqueValues = [];
      let duplicateValues = []; 
      let uniqueLineid = [];
      let duplicateLineId = [];
      let duplicateIndices = []; 
      let uniqueIncices = []; 
      let currenProf = _.cloneDeep(profile);
      profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions.map( (option,index) => {

            //copied costoption
            // updating variables
            option.Variables =  option.Variables && 
            option.Variables.map(v => {
          if(v.label === "Product") {
            return {
              label: "Product", 
              id: "producttype", 
              type: "single", 
              Default: true,
              "options": filterbySMcode,
              value: v?.value
            };
          } else {
            return v;
          }
        })
        // console.log("option.Variables",option.Variables);
        // identifying producttypedata index for copied cost options

          let p = option.Variables.find((c) => c.id === "producttype").value !== undefined ?  option.Variables.find((c) => c.id === "producttype").value 
          : option.CostingOptionData.producttype
          let q = /^\d/.test(p);
          let lineItemid = option.OpportunityLineItemID;
          let dupindex = q ? option.Variables.find((c) => c.id === "producttype").options.find((d) => d.Productname === p ) 
          : option.Variables.find((c) => c.id === "producttype").options.find((d) => d.OpportunityLineItemID === parseInt(lineItemid) )
        // console.log("dupindex", dupindex);
        // updating variables.value
        if (dupindex === undefined && typeof parseInt(option.OpportunityLineItemID) === 'number' ) {
          delFilSMCode = filterbySMcode.filter(i => i?.isRed === true);
          let value = parseInt(option.OpportunityLineItemID)
          if (uniqueLineid.includes(value)) {
            duplicateValues.push(option);
            duplicateLineId.push(value);
            duplicateIndices.push(index);
          } else {
            uniqueValues.push(option);
            uniqueLineid.push(value);
            uniqueIncices.push(index);
          }

        }
      }

      );

      let i=0;
      let validation = profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions.map( (option,index) => {

        let p = option.Variables.find((c) => c.id === "producttype").value !== undefined ?  option.Variables.find((c) => c.id === "producttype").value 
        : option.CostingOptionData.producttype
          let q = /^\d/.test(p);
          let lineItemid = parseInt(option.OpportunityLineItemID);
          let dupindex = q ? option.Variables.find((c) => c.id === "producttype").options.find((d) => d.Productname === p ) 
          : option.Variables.find((c) => c.id === "producttype").options.find((d) => d.OpportunityLineItemID === parseInt(lineItemid) )
          
          if (dupindex === undefined && typeof parseInt(option.OpportunityLineItemID) === 'number' ) {

            if (duplicateIndices.includes(index)) {
              
          // updating variables.value
          if (option.Variables && option.Variables.some(element => {return element.label === "Product"})) {
            //product
           
           if( option.isSelected 
            == !(delFilSMCode[i-1]?.isRed)) 
              {
                return false
              } else {
                return true
              }
            
              
            }
  
          } else {

          // updating variables.value
          if (option.Variables && option.Variables.some(element => {return element.label === "Product"})) {
            //product

            if(option.isSelected 
            == !(delFilSMCode[i]?.isRed))
            {
              return false
            } else {
              return true
            }
            }
            i++;
        }

        } else if (option.Variables && option.Variables.some(element => {return element.label === "Product"})) {
        //product

        if(option.isSelected 
        == !(dupindex?.isRed))
        {
          return false
        } else {
          return true
        }
    
        }
      })
      console.log("validationvalidation", validation)
      return validation.some(val => val == true)
   }
  //   const SetCostOption = (indexCO) => {
  //     if (currentCostingProfile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions.length === product.length && indexCO === 0) {

      
  //     var costing = currentCostingProfile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions;
  //   var costfilter =   costing.filter((e) => 
  //       e.value !== "Default Option"
  //     )
  //     console.log("costfilter", costfilter);
  //     var costrev = costfilter.reverse();
  //     console.log("costrev", costrev);
  //     currentCostingProfile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions.splice(0, currentCostingProfile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions.length, ...costrev);
  //     prodlength = prodlength - 1;
  //   }
  // }

    
    const CommissioningCountryCheck = (actualstatus) => {
        let _updatedStatus = actualstatus
        if (project.CommissioningCountry == profile.CountrySpecs[countryIndex].CountryCode) {
            let fieldingCountries = Array.isArray(profile.FieldingCountries) ? profile.FieldingCountries.map(fc => fc.value) : profile.FieldingCountries?.split(",")
            if (!_.includes(fieldingCountries, project.CommissioningCountry)) {
                return true
            } else if (_.includes(fieldingCountries, project.CommissioningCountry)) {
                return false
            }
        }

        return _updatedStatus
    }

  const ReturnControl = (indexCO, indexV, variable) => {
    let aa=profile.CountrySpecs[countryIndex].CountryCode
    if(aa !== project.CommissioningCountry){
      if(variable.label === "Commercial Local Support" && !variable.value){
         variable.value=fcValue
      }
    }
    let v
    if (variable.label === "Product") {
      v =
      profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[indexCO].Variables.filter(
        (x) => x.label == variable.label
      )[0] ||
      profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[0].Variables.filter(
        (x) => x.label == variable.label
      )[0];
      if (v?.value === undefined && profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions.length === 1) {
        let prodVar = profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[0].Variables.filter(e => e.label === "Product")
        
        v.value = prodVar[0].options[0].Productname;
      }
    } else {
     v =
      methodology.CostingOptions[indexCO].Variables.filter(
        (x) => x.label == variable.label
      )[0] ||
      methodology.CostingOptions[0].Variables.filter(
        (x) => x.label == variable.label
      )[0];
    }
    return (
      <ReturnControls
      v= {v}
      methodology ={methodology}
      profile = {profile}
      project = {project}
      indexV ={indexV}
      indexCO = {indexCO}
      CommissioningCountryCheck  ={CommissioningCountryCheck}
      indxFC = {countryIndex}
      indxSM = {methodologyIndex}
      errorFieldsObj ={errorFieldsObj}
      component = "CO"
      country= {aa}
      InfoChange = {InfoChange}
      errorStyles = {errorStyles}
      disableFields = {disableFields}
      >
    
      </ReturnControls>)
  }
  // const ReturnControl = (indexCO, indexV, variable) => {
  //   let aa = profile.CountrySpecs[countryIndex].value;
  //   const v =
  //     methodology.CostingOptions[indexCO].Variables.filter(
  //       (x) => x.label == variable.label
  //     )[0] ||
  //     methodology.CostingOptions[0].Variables.filter(
  //       (x) => x.label == variable.label
  //     )[0];

  //   if (
  //     methodology.RFQSchema.properties &&
  //     methodology.RFQSchema.properties[v.id] &&
  //     !v.value
  //   ) {
  //     if (methodology.RFQSchema.properties[v.id].defaultValue) {
  //       v.value =
  //         v.type === "creatableMulti" &&
  //         v.optionsLabel &&
  //         v.optionsLabel === "Cities"
  //           ? project.CommissioningCountry ===
  //             profile.CountrySpecs[countryIndex].CountryCode
  //             ? v.value && v.value == null
  //               ? v.value
  //               : methodology.RFQSchema.properties[v.id].defaultValue
  //             : ""
  //           : v.type === "single" &&
  //             v.optionsLabel &&
  //             v.optionsLabel === "LengthOfInterviewOptions"
  //           ? methodology.RFQSchema.properties[v.id].defaultValue.value
  //           : v.type === "integer" || v.type === "text" || v.type === "textarea"
  //           ? methodology.RFQSchema.properties[v.id].defaultValue
  //           : v.type === "single"
  //           ? _.isObject(methodology.RFQSchema.properties[v.id].defaultValue)
  //             ? methodology.RFQSchema.properties[v.id].defaultValue.value
  //             : methodology.RFQSchema.properties[v.id].defaultValue
  //           : v.type === "multi"
  //           ? methodology.RFQSchema.properties[v.id].defaultValue
  //           : v.value;
  //     }
  //   }
  //   v.disabled = CommissioningCountryCheck(v.disabled);

  //   var val1 = {
  //     required: { value: v.mandatory },
  //     min: { value: 0, errorMessage: "Value should be greater than 0" },
  //   };
  //   var val2 = {
  //     required: { value: v.mandatory },
  //     min: { value: 1, errorMessage: "Your value must be between 1 & 100 " },
  //     max: { value: 100, errorMessage: "Your value must be between 1 & 100 " },
  //   };

  //   if (v.type == "integer")
  //     return (
  //       <AvField //chanege by mridul rishab
  //         name={v.label}
  //         value={v.value || ""}
  //         disabled={
  //           profile.ProfileStatus == 99 ||
  //           v.disabled ||
  //           methodology.NotApplicable ||
  //           disableFields
  //         }
  //         style={{ width: "100%" }}
  //         placeholder=""
  //         type="number"
  //         min={v.label == "Incidence Rate Main" ? 1 : 0}
  //         onChange={(e) =>
  //           InfoChange(
  //             indexCO,
  //             indexV,
  //             e.target.name,
  //             parseInt(e.target.value) < 0 ? "1" : e.target.value
  //           )
  //         }
  //         required={v.disabled ? false : v.mandatory}
  //         invalid={
  //           v.label == "Incidence Rate Main"
  //             ? parseInt(v.value) > 100 || parseInt(v.value) < 1
  //             : parseInt(v.value) < 0
  //         }
  //         validate={
  //           v.mandatory &&
  //           !methodology.NotApplicable &&
  //           !v.disabled &&
  //           !_.includes(v.value)
  //             ? v.label == "Incidence Rate Main"
  //               ? val2
  //               : val1
  //             : {}
  //         }
  //       />
  //     );
  //   else if (v.type == "header") return <h5 className={"header"}>{v.label}</h5>;
  //   else if (v.type == "text")
  //     return (
  //       <AvField
  //         name={v.label}
  //         placeholder=""
  //         type="text"
  //         style={{ width: "100%" }}
  //         disabled={
  //           profile.ProfileStatus == 99 ||
  //           v.disabled ||
  //           methodology.NotApplicable ||
  //           disableFields
  //         }
  //         onChange={(e) =>
  //           InfoChange(indexCO, indexV, e.target.name, e.target.value)
  //         }
  //         value={v.value || ""}
  //       />
  //     );
  //   else if (v.type == "textarea") {
  //     return (
  //       <AvField
  //         name={v.label}
  //         placeholder=""
  //         disabled={
  //           profile.ProfileStatus == 99 ||
  //           v.disabled ||
  //           methodology.NotApplicable ||
  //           disableFields
  //         }
  //         type="textarea"
  //         style={{ width: "100%" }}
  //         onChange={(e) =>
  //           InfoChange(indexCO, indexV, e.target.name, e.target.value)
  //         }
  //         value={v.value || ""}
  //         validate={
  //           v.mandatory &&
  //           !methodology.NotApplicable &&
  //           !v.disabled &&
  //           !_.includes(v.value)
  //             ? {
  //                 required: {
  //                   value: v.mandatory,
  //                   errorMessage: "field is required",
  //                 },
  //               }
  //             : {}
  //         }
  //       />
  //     );
  //   } else if (v.type == "single") {
  //     let optionsTemp = v.optionsLabel ? codeLabels[v.optionsLabel] : v.options;
  //     if (
  //       optionsTemp?.find(
  //         (x) =>
  //           (x.Label && x.Label.toLowerCase() == "other specify") ||
  //           (x.label && x.label.toLowerCase() == "other specify")
  //       )
  //     )
  //       return (
  //         <>
  //           <CreatableSelect
  //             name={v.label}
  //             value={v.value}
  //             isDisabled={
  //               profile.ProfileStatus == 99 ||
  //               v.disabled ||
  //               methodology.NotApplicable ||
  //               disableFields
  //             }
  //             onChange={(e) => InfoChange(indexCO, indexV, v.label, e, v.id)}
  //             className="react-select-container"
  //             classNamePrefix="react-select"
  //             styles={
  //               errorFieldsObj &&
  //               errorFieldsObj[
  //                 profile.CountrySpecs[countryIndex].CountryName
  //               ] &&
  //               errorFieldsObj[profile.CountrySpecs[countryIndex].CountryName][
  //                 "CostingOptions"
  //               ] &&
  //               errorFieldsObj[profile.CountrySpecs[countryIndex].CountryName][
  //                 "CostingOptions"
  //               ][
  //                 profile.CountrySpecs[countryIndex].MethodologySpecs[
  //                   methodologyIndex
  //                 ].Label
  //               ] &&
  //               _.includes(
  //                 errorFieldsObj[
  //                   profile.CountrySpecs[countryIndex].CountryName
  //                 ]["CostingOptions"][
  //                   profile.CountrySpecs[countryIndex].MethodologySpecs[
  //                     methodologyIndex
  //                   ].Label
  //                 ],
  //                 v.label,
  //                 v.value
  //               )
  //                 ? errorStyles
  //                 : ""
  //             }
  //             validate={
  //               v.mandatory && !v.disabled && !_.includes(v.value)
  //                 ? { required: { value: v.mandatory } }
  //                 : {}
  //             }
  //             options={
  //               v.optionsLabel
  //                 ? codeLabels[v.optionsLabel].map((opt) => {
  //                     return {
  //                       value: opt.Code,
  //                       label: opt.Label,
  //                     };
  //                   })
  //                 : v.options.map((option) => {
  //                     return {
  //                       value: option.value,
  //                       label: option.label,
  //                     };
  //                   })
  //             }
  //           />

  //           {!v.disabled &&
  //           errorFieldsObj &&
  //           errorFieldsObj[profile.CountrySpecs[countryIndex].CountryName] &&
  //           errorFieldsObj[profile.CountrySpecs[countryIndex].CountryName][
  //             "CostingOptions"
  //           ] &&
  //           errorFieldsObj[profile.CountrySpecs[countryIndex].CountryName][
  //             "CostingOptions"
  //           ][
  //             profile.CountrySpecs[countryIndex].MethodologySpecs[
  //               methodologyIndex
  //             ].Label
  //           ] &&
  //           _.includes(
  //             errorFieldsObj[profile.CountrySpecs[countryIndex].CountryName][
  //               "CostingOptions"
  //             ][
  //               profile.CountrySpecs[countryIndex].MethodologySpecs[
  //                 methodologyIndex
  //               ].Label
  //             ],
  //             v.label,
  //             v.value
  //           ) ? (
  //             <p className="invalid-feedback d-flex">field is required</p>
  //           ) : null}
  //           {/* {v.mandatory && !v.value &&<p className="invalid-feedback d-flex">field is required</p>} */}
  //         </>
  //       );
  //     else
  //       return (
  //         <AvField
  //           tag={CustomInput}
  //           name={v.label}
  //           value={v.value}
  //           disabled={
  //             profile.ProfileStatus == 99 ||
  //             v.disabled ||
  //             methodology.NotApplicable ||
  //             disableFields
  //           }
  //           type="select"
  //           onChange={(e) =>
  //             InfoChange(indexCO, indexV, v.label, e.target.value)
  //           }
  //           validate={
  //             v.mandatory &&
  //             !methodology.NotApplicable &&
  //             !v.disabled &&
  //             !_.includes(v.value)
  //               ? {
  //                   required: {
  //                     value: v.mandatory,
  //                     errorMessage: "field is required",
  //                   },
  //                 }
  //               : {}
  //           }
  //         >
  //           <option value="0"></option>
  //           {v.optionsLabel
  //             ? codeLabels[v.optionsLabel]?.map((opt) => (
  //                 <option key={opt.Code} value={opt.Code}>
  //                   {opt.Label}
  //                 </option>
  //               ))
  //             : v.options?.map((option) => (
  //                 <option key={option.value} value={option.value}>
  //                   {option.label}
  //                 </option>
  //               ))}
  //         </AvField>
  //       );
  //   } else if (
  //     v.type == "multi" &&
  //     v.optionsLabel &&
  //     v.optionsLabel == "Cities"
  //   )
  //     return (
  //       <div style={{ textAlign: "center" }}>
  //         {codeLabels["CitiesOptions"].filter(
  //           (x) => x.Code == profile.CountrySpecs[countryIndex].CountryCode
  //         )[0] ? (
  //           <Select
  //             name={v.label}
  //             value={v.value}
  //             isDisabled={
  //               profile.ProfileStatus == 99 ||
  //               v.disabled ||
  //               methodology.NotApplicable ||
  //               disableFields
  //             }
  //             onChange={(e) => InfoChange(indexCO, indexV, v.label, e)}
  //             className="react-select-container"
  //             classNamePrefix="react-select"
  //             menuPortalTarget={document.querySelector("card-body")}
  //             menuPosition={"fixed"}
  //             options={codeLabels["CitiesOptions"]
  //               .filter(
  //                 (x) =>
  //                   x.Code == profile.CountrySpecs[countryIndex].CountryCode
  //               )[0]
  //               ["Cities"].map((opt) => {
  //                 return {
  //                   value: opt.id,
  //                   label: opt.Label,
  //                 };
  //               })}
  //             isMulti
  //           />
  //         ) : (
  //           "No City Found"
  //         )}
  //       </div>
  //     );
  //   else if (
  //     v.type == "creatableMulti" &&
  //     v.optionsLabel &&
  //     v.optionsLabel == "Cities"
  //   )
  //     return (
  //       <>
  //         {codeLabels["CitiesOptions"].filter(
  //           (x) => x.Code == profile.CountrySpecs[countryIndex].CountryCode
  //         )[0] ? (
  //           <CreatableSelect
  //             className="react-select-container"
  //             classNamePrefix="react-select"
  //             isMulti
  //             isDisabled={
  //               profile.ProfileStatus == 99 ||
  //               v.disabled ||
  //               methodology.NotApplicable ||
  //               disableFields
  //             }
  //             value={v.value}
  //             onChange={(e) => InfoChange(indexCO, indexV, v.label, e)}
  //             options={codeLabels["CitiesOptions"]
  //               .filter(
  //                 (x) =>
  //                   x.Code == profile.CountrySpecs[countryIndex].CountryCode
  //               )[0]
  //               ["Cities"].map((opt) => {
  //                 return {
  //                   value: opt.id,
  //                   label: opt.Label,
  //                 };
  //               })}
  //           />
  //         ) : (
  //           "No City Found"
  //         )}
  //       </>
  //     );
  //   else if (v.type == "multi")
  //     return (
  //       <div>
  //         <Select
  //           name={v.label}
  //           value={v.value}
  //           isDisabled={
  //             profile.ProfileStatus == 99 ||
  //             v.disabled ||
  //             methodology.NotApplicable ||
  //             disableFields
  //           }
  //           onChange={(e) => InfoChange(indexCO, indexV, v.label, e)}
  //           className="react-select-container"
  //           classNamePrefix="react-select"
  //           options={
  //             v.optionsLabel
  //               ? codeLabels[v.optionsLabel].map((opt) => {
  //                   return {
  //                     value: opt.Code,
  //                     label: opt.Label,
  //                   };
  //                 })
  //               : v.options.map((option) => {
  //                   return {
  //                     value: option.value,
  //                     label: option.label,
  //                   };
  //                 })
  //           }
  //           isMulti
  //         />
  //       </div>
  //     );
  //   else if (v.type == "check")
  //     return (
  //       <CustomInput
  //         type="switch"
  //         name={v.label}
  //         id={v.label}
  //         defaultChecked={v.value ? v.value : true}
  //         className="h5"
  //         disabled={
  //           profile.ProfileStatus == 99 ||
  //           methodology.NotApplicable ||
  //           disableFields
  //         }
  //         onChange={(e) => {
  //           InfoChange(indexCO, indexV, e.target.name, e.target.checked);
  //         }}
  //       />
  //     );
  //   else if (v.type == "date")
  //     return (
  //       <FormGroup>
  //         <AvField
  //           name={"field_" + indexV}
  //           placeholder=""
  //           type="date"
  //           errorMessage="field is required"
  //           onChange={(e) =>
  //             InfoChange(indexCO, indexV, v.label, e.target.value)
  //           }
  //           value={v.value || ""}
  //         />
  //       </FormGroup>
  //     );
  // };

  const RenderVariablesNew = () => {
    return addVariables.map((variable, indexV) => {
      if (!variable.Default && variable.show)
        return (
          <Col
            key={"col_" + indexV}
            style={{ paddingLeft: "2.2rem", paddingTop: "1.8rem" }}
            md={4}
          >
            <Input
              type="checkbox"
              defaultChecked={variable.selected ? true : false}
              disabled={
                profile.ProfileStatus == 99 ||
                methodology.NotApplicable ||
                disableFields
              }
              onChange={(e) => {
                if (!methodology.NotApplicable)
                  checkChangeVariable(indexV, e.target.checked);
              }}
            />
            {variable.label}
          </Col>
        );
      else return null;
    });
  };
  function errorMsgCopy() {
    toastr.info("Cannot copy costing options for Non-syndicated projects")
      return
  }
  function errorMsgDelete() {
    toastr.info("Cannot delete costing options for Non-syndicated projects")
      return
  }
  function removeItem(indexCO) {
  //  console.log("costingoptionsValue", currentCostingProfile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions);
   // console.log("costingoptionsValue, indexCO", currentCostingProfile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions.length, indexCO);
   let p = profile?.CountrySpecs[countryIndex]?.MethodologySpecs[methodologyIndex]?.CostingOptions[indexCO]?.Variables?.filter(v => v?.label === "Product")[0]?.value;
   let q = profile?.CountrySpecs[countryIndex]?.MethodologySpecs[methodologyIndex]?.CostingOptions?.map(c => {
    let r = c?.Variables?.filter(v => v?.label === "Product")[0]?.value
     return r
    })
    let s = q?.filter(c => c===p)
   if (s?.length == 1) {
      toastr.info("Cannot delete costing options that are created by default")
      return
    }
      else {
    const hasAnySelectedAfterRemove = profile.CountrySpecs[countryIndex]?.MethodologySpecs[methodologyIndex]?.CostingOptions.some((i, ind) => {
      if (ind === indexCO) return false
      return i.isSelected
    })
    if (!hasAnySelectedAfterRemove) {
      toastr.info("Cannot delete this costing option as its is only selected.")
      return
    }
    if (!methodology.NotApplicable)
      if (!window.confirm('Are you sure to delete the costing option?')) return;

    let deletableCostingOption =
      profile.CountrySpecs[countryIndex]?.MethodologySpecs[methodologyIndex]
        ?.CostingOptions[indexCO];
    if (deletableCostingOption) deletableCostingOption.toRemove = true;

    let profileTemp = _.cloneDeep(profile);
    let costingOptionsClone = _.cloneDeep(
      profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex]
        .CostingOptions
    );
    costingOptionsClone.splice(indexCO, 1);
    if (!profileTemp.deletedCostingOptions)
      profileTemp.deletedCostingOptions = [];
    if (deletableCostingOption && deletableCostingOption.id)
      profileTemp.deletedCostingOptions.push(deletableCostingOption);

    profileTemp.CountrySpecs[countryIndex].MethodologySpecs[
      methodologyIndex
    ].CostingOptions = costingOptionsClone;
  if(deletableCostingOption?.Variables.find(i => i.id === "trackingProject")?.value === 'Yes' ) {
    setCODeleted(isCODeleted + 1)
  }
    updateProjectAndProfileWRTCostingOption(profileTemp);
}
  }
  useEffect(() => {
    dispatch(setindexCOforCopy([]));
    copyIndexCO = [];
  }, []);

  useEffect(() => {
    let {CostingOptions} = profile?.CountrySpecs[countryIndex]?.MethodologySpecs[methodologyIndex];
    CostingOptions?.map((co, indexCO) => {
      let indexV = co.Variables?.findIndex(i => i.id === "trackingProject");
      if(indexV >= 0) {  
        let v = co.Variables[indexV]
        if(v.value === 'Yes') {
          InfoChange(indexCO, indexV, v.label, v.value, null, isRfqValueChanged )
        }
      }
    })
  }, [countryIndex, methodologyIndex])

  useEffect(() => {
    if(isCODeleted){
    let _CostingOptions = _.cloneDeep(profile?.CountrySpecs[countryIndex]?.MethodologySpecs[methodologyIndex].CostingOptions)
    let indexV = -1
    let _indexCo = -1;
    _CostingOptions.forEach((co, indexCO) => {
      indexV = co?.Variables.findIndex(i => i.id === "trackingProject");
      if (indexV >= 0 && co?.Variables[indexV]?.value && co?.Variables[indexV]?.value === "Yes") {
        _indexCo = indexCO
      }
    })
    if(!(indexV >= 0 && _indexCo >=0)) {  
      let v1 = _CostingOptions[0]?.Variables[indexV]
      InfoChange(0, indexV, v1.label, v1.value?v1.value:"0")
    }
  }
  }, [isCODeleted])

  useEffect(() => {
    return () => {
      dispatch(valueChangedInRFQ(false));
    }
  }, [])
// useEffect(() => {
//   if (oppValue && product && product.length > 0) {
//     console.log(oppValue, product)
//     Array.from({length: product.length}).forEach((_, index) => {
//       if (!methodology.NotApplicable)
//           CopyCostingOption(index);
      
//       //saveCostingOption("copy", true, index)
      
      

//       console.log(oppValue, product);
//     })
    
//   }
// }, [product, oppValue]);
  function InfoChange(indexCO, indexV, name, value, id = null, edited = true) {
    let countryCheck=profile.CountrySpecs[countryIndex].CountryCode
    if(countryCheck!==project.CommissioningCountry){
      if(name ==="Commercial Local Support" && indexCO===0){
        setfcValue(value)
      }
    }
    const variablesClone = _.cloneDeep(
      profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex]
        .CostingOptions[indexCO].Variables
    );
    const CostingOptionData = _.cloneDeep(profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[indexCO].CostingOptionData)
    const currentVariable = variablesClone[indexV];
    if (value == null) {
      value =
        currentVariable.type === "creatableMulti" ||
        currentVariable.type === "multi"
          ? []
          : value;
    }
    currentVariable.value = value;

    if (currentVariable.id == "respondentParticipation") {
      variablesClone.filter(
        (hidVar) => hidVar.id == "fieldworkLength"
      )[0].value = parseInt(value * 2.5);
    }
    if (name == "Sample size per scenario per option")
      variablesClone.filter(
        (hidVar) => hidVar.id == "totalSamplePerCountryPerOPtion"
      )[0].value =
        (parseInt(
          variablesClone.filter(
            (hidVar) => hidVar.id == "numberofTestScenario"
          )[0].value
        ) +
          parseInt(
            variablesClone.filter(
              (hidVar) => hidVar.id == "numberofControlScenario"
            )[0].value
          )) *
        value;
    if (name == "Type Of Venue") {
      //make it to id, not sure why id isn't working
      let typeOfChannel = variablesClone.find((vc) => vc.id == "typeOfChannel");
      typeOfChannel.options =
        value == "Store"
          ? [
              { label: "Hypermarket", value: "Hypermarket" },
              { label: "Supermarket", value: "Supermarket" },
              { label: "Convenience", value: "Convenience" },
              { label: "Discounter", value: "Discounter" },
              { label: "Traditional Trade", value: "Traditional Trade" },
              { label: "On-trade", value: "On-trade" },
              { label: "Gas/Petrol Station", value: "Gas/Petrol Station" },
              { label: "Other", value: "Other" },
            ]
          : value == "Restaurants/pubs"
          ? [
              { label: "Restaurant", value: "Restaurant" },
              { label: "Pub", value: "Pub" },
            ]
          : value == "Leaflets/App/Web"
          ? [{ label: "Leaflet", value: "Leaflet" }]
          : [];
    }
    variablesClone.forEach((v, indexV) => {
      if (
        v.label != currentVariable.label &&
        v.dependentOn &&
        v.dependentOn.fields.find((f) => f.fieldName == currentVariable.label)
      ) {
        let op = v.dependentOn.andor == "OR" ? "||" : "&&";
        let expression = "";
        v.dependentOn.fields.forEach((f, indexF) => {
          let fieldVal =
            f.fieldName != currentVariable.label
              ? _.head(variablesClone.filter((v) => v.label == f.fieldName))
                  ?.value
              : currentVariable["value"];
          if (Array.isArray(fieldVal)) {
            let tempBool =
              variablesClone
                .filter((v) => v.label == f.fieldName)[0]
                ["value"].filter((x) => x.label == f.fieldValue).length > 0;
            expression +=
              tempBool +
              f.operator +
              "true" +
              (indexF != v.dependentOn.fields.length - 1 ? op : "");
          } else {
            expression +=
              getChangedValue(fieldVal) +
              f.operator +
              getChangedValue(f.fieldValue) +
              (indexF != v.dependentOn.fields.length - 1 ? op : "");
          }
        });

        let enabled = eval(expression);
        if (enabled) v.disabled = false;
        else {
          v.disabled = true;
          v.value = null;
          deleteId(v.id, countryIndex, methodologyIndex, indexCO)
          variablesClone.forEach((v2, indexV2) => {
            if (
              v2.dependentOn &&
              v2.dependentOn.fields.find((f) => f.fieldName == v.label)
            ) {
              v2.disabled = true;
              v2.value = null;
            }
          });
        }
      }
    });

    let arr = [];
    let rfqVariableClone = _.cloneDeep(
      profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex]
        .Variables
    );
    rfqVariableClone.forEach((v, indexV) => {
      if (
        v.label != currentVariable.label &&
        v.dependentOn &&
        v.dependentOn.fields.find((f) => f.fieldName == currentVariable.label)
      ) {
        arr.push({ label: v.label });
      }
    });
    rfqVariableClone = OtherVariablesShowHide(
      indexCO,
      currentVariable,
      rfqVariableClone,
      arr
    );

    arr = [];
    let opResourcesVariablesClone = _.cloneDeep(
      profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex]
        .OpResourcesVariables
    );
    opResourcesVariablesClone.forEach((v, indexV) => {
      if (
        v.label != currentVariable.label &&
        v.dependentOn &&
        v.dependentOn.fields.find((f) => f.fieldName == currentVariable.label)
      ) {
        arr.push({ label: v.label });
      }
    });
    opResourcesVariablesClone = OtherVariablesShowHide(
      indexCO,
      currentVariable,
      opResourcesVariablesClone,
      arr
    );

    let profileTemp = _.cloneDeep(profile);
    profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex] =
      {
        ...profileTemp.CountrySpecs[countryIndex].MethodologySpecs[
          methodologyIndex
        ],
        Variables: rfqVariableClone,
        OpResourcesVariables: opResourcesVariablesClone,
      };
      if (
        profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex]
        .CostingOptions[indexCO]
        ) {
          const hasChanged = hasDataChanged(CostingOptionData, variablesClone[indexV], countryIndex, methodologyIndex, indexCO)
          isEditable(edited ? hasChanged : false)
      profileTemp.CountrySpecs[countryIndex].MethodologySpecs[
        methodologyIndex
      ].CostingOptions[indexCO].Variables = variablesClone;
    }

    let costingOptionsClone = _.cloneDeep(
      profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex]
        .CostingOptions
    );
    //not required, just for cloning, felt cute, will delete later
    profileTemp.CountrySpecs[countryIndex].MethodologySpecs[
      methodologyIndex
    ].CostingOptions = costingOptionsClone;
    updateProjectAndProfileWRTCostingOption(profileTemp);
  }

  function OtherVariablesShowHide(indexCO, currentVar, variablesClone, arr) {
    const costingOptionsClone = _.cloneDeep(methodology.CostingOptions);
    costingOptionsClone.forEach((co, index_co) => {
      let currentVariable =
        index_co == indexCO
          ? currentVar
          : co.Variables.filter((x) => x.label == currentVar.label)[0];
      if (arr.filter((x) => !x.done).length == 0) return;
      variablesClone.forEach((v, indexV) => {
        if (
          v.label != currentVariable.label &&
          v.dependentOn &&
          v.dependentOn.fields.find((f) => f.fieldName == currentVariable.label)
        ) {
          if (arr.filter((x) => x.label == v.label)[0].done) return;
          let op = v.dependentOn.andor == "OR" ? "||" : "&&";
          let expression = "";
          v.dependentOn.fields.forEach((f, indexF) => {
            let fieldVal =
              f.fieldName != currentVariable.label
                ? variablesClone.filter((v) => v.label == f.fieldName)[0]?.value
                : currentVariable["value"];
            if (Array.isArray(fieldVal)) {
              let tempBool = false;
              if (
                variablesClone.filter((v) => v.label == f.fieldName)[0]["value"]
              ) {
                tempBool =
                  variablesClone
                    .filter((v) => v.label == f.fieldName)[0]
                    ["value"].filter((x) => x.label == f.fieldValue).length > 0;
              } else if (currentVariable["value"]) {
                tempBool =
                  currentVariable["value"].filter(
                    (x) => x.label == f.fieldValue
                  ).length > 0;
              }
              expression +=
                tempBool +
                f.operator +
                "true" +
                (indexF != v.dependentOn.fields.length - 1 ? op : "");
            } else {
              expression +=
                getChangedValue(fieldVal) +
                f.operator +
                getChangedValue(f.fieldValue) +
                (indexF != v.dependentOn.fields.length - 1 ? op : "");
            }
          });
          let enabled = eval(expression);
          if (enabled) {
            //custom code starts
            if (
              v.label == "Approx Percentage Change - Each Wave" &&
              currentVariable.label == "Tracking Project"
            ) {
              if (currentVariable["value"] == "Yes") {
                v.enabled = true;
                v.disabled = false;
                arr.filter((x) => x.label == v.label)[0].done = true;
              } else {
                v.enabled = false;
                v.disabled = true;
                v.value = null;
              }
            }
            //custom code starts
            else {
              v.disabled = false;
              arr.filter((x) => x.label == v.label)[0].done = true;
            }
          } else {
            v.disabled = true;
            v.value = null;
            variablesClone.forEach((v2, indexV2) => {
              if (
                v2.dependentOn &&
                v2.dependentOn.fields.find((f) => f.fieldName == v.label)
              ) {
                v2.disabled = true;
                v2.value = null;
              }
            });
          }
        }
      });
    });
    return variablesClone;
  }

  function getChangedValue(value) {
    let rValue = null;
    if (isNaN(value)) rValue = "'" + value + "'";
    else rValue = value;
    return rValue;
  }

  const checkChangeVariable = (indexV, value) => {
    const addVariablesClone = _.cloneDeep(addVariables);

    addVariablesClone.forEach((x, index) => {
      if (index == indexV) {
        x["selected"] = value;
        x["value"] = null;
      } else if (
        x.dependentOn &&
        x.dependentOn.fields.find(
          (f) => f.fieldName == addVariablesClone[indexV].label
        ) &&
        (x.AddToCostingOption ||
          x.sectionTitle == addVariablesClone[indexV].sectionTitle)
      ) {
        x["selected"] = value;
        if (value == true) x.disabled = true;
      }
    });
    setAddVariables(addVariablesClone);
  };

  function SetAdditionalVariables() {
    let RFQVariables = _.cloneDeep(methodology.Variables);
    RFQVariables.forEach((v) => {
      v.RFQ = true;
      v.show = false;
    });
    let OPResourceVariables = _.cloneDeep(methodology.OpResourcesVariables);
    OPResourceVariables.forEach((v) => {
      v.OPR = true;
      v.show = false;
    });
    let tempVariables = [...RFQVariables, ...OPResourceVariables];
    setAddVariables(tempVariables);
  }

  function SearchValueChanged(searchValue) {
    let tempAddVariables = _.cloneDeep(addVariables);
    if (searchValue && searchValue.length >= 3) {
      tempAddVariables.forEach((av) => {
        const co = _.cloneDeep(
          profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex]
            .CostingOptions[0]
        );
        const parentFound =
          av.dependentOn &&
          av.dependentOn.fields.some((f) =>
            co.Variables.find((v) => v.label == f.fieldName)
          );
        const matchFound = av.label
          .replace(/\s+/g, "")
          .toLowerCase()
          .includes(searchValue.replace(/\s+/g, "").toLowerCase());
        if (av.selected) av.show = true;
        else if ((matchFound && !av.disabled) || (matchFound && parentFound))
          av.show = true;
        else av.show = false;
      });
    } else {
      tempAddVariables.forEach((av) => {
        if (av.selected && !av.dependentOn) av.show = true;
        else av.show = false;
      });
    }
    setAddVariables(tempAddVariables);
  }

  function AddRemoveOptionalVariablesNew() {
    const costingOptionsClone = _.cloneDeep(methodology.CostingOptions);
    const addVariablesClone = _.cloneDeep(addVariables);
    const RFQVariables = _.cloneDeep(addVariables.filter((v) => v.RFQ));
    const OPResourceVariables = _.cloneDeep(addVariables.filter((v) => v.OPR));
    setModelStateVar(!modelStateVar);
    const titleAddedVariables = [
      { label: "RFQ Form", flag: false, costingheader: true },
      { label: "Setup & Delivery", flag: false, costingheader: true },
    ];
    costingOptionsClone.forEach((co, indexCO) => {
      const coVariablesClone = _.cloneDeep(co.Variables);
      let tempVariables = [];
      tempVariables = coVariablesClone.filter((vc, indexVC) => vc.Default);
      addVariablesClone
        .filter((av) => av.selected)
        .forEach((av, indexAV) => {
          const indexVar = coVariablesClone.findIndex(
            (vc) => vc.label == av.label
          );
          if (indexVar > -1) {
            let temp = _.cloneDeep(coVariablesClone[indexVar]);
            tempVariables.push(temp);
          } else {
            av.parent =
              av.label == "Data Processing Required"
                ? "Data Processing"
                : av.sectionTitle;
            av.value = null;
            if (
              RFQVariables.findIndex(
                (x) => x.id == av.id && x.label == av.label
              ) > -1
            )
              av.form = "RFQ Form";

            if (
              OPResourceVariables.findIndex(
                (x) => x.id == av.id && x.label == av.label
              ) > -1
            )
              av.form = "Setup & Delivery";

            tempVariables.push(_.cloneDeep(av));
          }
        });
      co.Variables = tempVariables;
    });

    let distinctParents = costingOptionsClone[0].Variables.filter(
      (v) => !v.Default
    )
      .map((v) => v.parent)
      .filter((item, i, ar) => ar.indexOf(item) === i);

    costingOptionsClone.forEach((co, indexCO) => {
      const coVariablesClone = _.cloneDeep(co.Variables);
      let tempVariables = [];
      tempVariables = coVariablesClone.filter((vc, indexVC) => vc.Default);
      distinctParents.forEach((p) => {
        coVariablesClone
          .filter((v) => !v.Default && v.parent == p)
          .forEach((v) => {
            if (v.form == "RFQ Form" && !titleAddedVariables[0].flag) {
              titleAddedVariables[0].flag = true;
              tempVariables.push(titleAddedVariables[0]);
            }
            if (v.form == "Setup & Delivery" && !titleAddedVariables[1].flag) {
              titleAddedVariables[1].flag = true;
              tempVariables.push(titleAddedVariables[1]);
            }

            tempVariables.push(_.cloneDeep(v));
          });
      });
      titleAddedVariables[0].flag = false;
      titleAddedVariables[1].flag = false;
      co.Variables = tempVariables;
    });

    let profileTemp = _.cloneDeep(profile);
    profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex] =
      {
        ...profileTemp.CountrySpecs[countryIndex].MethodologySpecs[
          methodologyIndex
        ],
        CostingOptions: costingOptionsClone,
        Variables: RFQVariables,
        OpResourcesVariables: OPResourceVariables,
      };

    updateProjectAndProfileWRTCostingOption(profileTemp);

    ClearSearch();
  }

  function RemoveAdditionalVariable(v, indexV) {
    const costingOptionsClone = _.cloneDeep(methodology.CostingOptions);
    const addVariablesClone = _.cloneDeep(methodology.Variables);
    const OpResourcesVariables = _.cloneDeep(methodology.OpResourcesVariables);

    addVariablesClone.forEach((x, index) => {
      if (x.label == v.label) {
        x.selected = false;
        x.value = null;
      } else if (
        x.dependentOn &&
        x.dependentOn.fields.find((f) => f.fieldName == v.label)
      ) {
        x.selected = false;
        x.disabled = true;
        x.value = null;
      }
    });

    OpResourcesVariables.forEach((x, index) => {
      if (x.label == v.label) {
        x.selected = false;
        x.value = null;
      } else if (
        x.dependentOn &&
        x.dependentOn.fields.find((f) => f.fieldName == v.label)
      ) {
        x.selected = false;
        x.disabled = true;
        x.value = null;
      }
    });

    costingOptionsClone.forEach((co, indexCO) => {
      let variablesClone = _.cloneDeep(co.Variables);
      variablesClone = variablesClone.filter(
        (x, index) =>
          index != indexV &&
          (!x.dependentOn ||
            !x.dependentOn.fields.find((f) => f.fieldName == v.label))
      );
      const rfqvariables = variablesClone.filter(
        (x) => x.form == "RFQ Form"
      ).length;
      variablesClone = variablesClone.filter((x) =>
        rfqvariables > 0 ? x == x : x.label != "RFQ Form"
      );
      const stpvariables = variablesClone.filter(
        (x) => x.form == "Setup & Delivery"
      ).length;
      variablesClone = variablesClone.filter((x) =>
        stpvariables > 0 ? x == x : x.label != "Setup & Delivery"
      );
      co.Variables = variablesClone;
    });

    let profileTemp = _.cloneDeep(profile);
    profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex] =
      {
        ...profileTemp.CountrySpecs[countryIndex].MethodologySpecs[
          methodologyIndex
        ],
        CostingOptions: costingOptionsClone,
        Variables: addVariablesClone,
        OpResourcesVariables: OpResourcesVariables,
      };
    updateProjectAndProfileWRTCostingOption(profileTemp);
  }

  // function CopyCostingOption(indexCO) {
  //  debugger;
  //   console.log("copyfunction", indexCO);
  //   console.log("methodology CostingOptions", methodology.CostingOptions);
  //   console.log("currentCostingProfile", currentCostingProfile)
  //   let costingOption = _.cloneDeep(methodology.CostingOptions[indexCO]);
  //   console.log(costingOption);
  //   delete costingOption["id"];
  //   //to avoid copying costs data
  //   Object.keys(costingOption)
  //     ?.filter((c) => c.toLowerCase().indexOf("cost") > -1)
  //     .map((costKey) => {
  //       costingOption[costKey] = null;
  //     });
  //   costingOption.CostsData = null;
  //   costingOption.TimingsData = null;
  //   costingOption.CommercialHours = null;
  //   const costingOptionsClone = _.cloneDeep(methodology.CostingOptions);
  //   costingOptionsClone.splice(indexCO + 1, 0, costingOption);
  //   if (costingOption.value.includes("copy")) {
  //     var regex = /[\d|,|.|e|E|\+]+/g;
  //     const nameCostingOption = costingOption.label.split("-")[1];
  //     const copyPart = costingOption.value.split("-")[0];
  //     var copyNumber = copyPart.match(regex);
  //     let copyValue=Number(costingOption.label[costingOption.label.length-1])
  //     if(isNaN(copyValue))
  //     {
  //       costingOption.label =   costingOption.label + " 1";
  //     }
  //     else
  //     {
  //       copyValue=copyValue+1
  //       costingOption.label =   costingOption.label.slice(0,costingOption.label.length-1) + " "+copyValue;
  //     }
      
      
  //     costingOption.value =
  //       "copy" + (copyNumber + 1) + "_" + costingOption.label;
  //   } else {
  //     let copyValue=Number(costingOption.label[costingOption.label.length-1])
  //     if(isNaN(copyValue))
  //     {
  //       costingOption.label =    costingOption.label+" 1";
  //     }
  //     else
  //     {
  //       copyValue=copyValue+1
  //       costingOption.label =    costingOption.label.slice(0,costingOption.label.length-1)+" "+copyValue;
  //     }
      
  //     costingOption.value = "copy_"+ costingOption.label;
      
  //   }
    
  //   let profileTemp = _.cloneDeep(profile);
  //   profileTemp.CountrySpecs[countryIndex].MethodologySpecs[
  //     methodologyIndex
  //   ].CostingOptions = costingOptionsClone;
  //   console.log(profileTemp);
  //   //methodology.CostingOptions.push(costingOption);
  //   updateProjectAndProfileWRTCostingOption(profileTemp);
  // }
  function CopyCostingOption(indexCO, costoplen, index) {
  //ExpenseCheck(indexCO)
  if (copyIndexCO.length > 0) {
    if (profile?.CountrySpecs[countryIndex]?.MethodologySpecs[methodologyIndex]?.CostingOptions[indexCO]?.Variables.filter(variable => {
      return variable?.label === "Product";
    })?.map(i => i?.value)[0]?.toLowerCase().includes('expense') ) {
      toastr.info(
        "Expense Recharge items cannot be copied"
      );
      return;
    }
    if (profile?.CountrySpecs[countryIndex]?.MethodologySpecs[methodologyIndex]?.CostingOptions[indexCO]?.Variables.find((c) => c.id === "producttype")?.isRed) {
      toastr.info(
        "This product Line is deleted. Cannot copy this cost Option"
      );
      return;
    }
  }
    //console.log("indexCO", indexCO);
   //console.log("profile.ProductTypeData, methodologyIndex", profile.ProductTypeData, methodologyIndex);
    let SMCODE = profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].Code;
    let filterbySM=[]
        while(typeof profile.ProductTypeData === "string"){
              profile.ProductTypeData = JSON.parse(profile.ProductTypeData)
              }
        filterbySM = profile.ProductTypeData.filter ((item) => item.Code === SMCODE);
    let oppTemp
    while(typeof profile.ProductTypeData === "string"){
      profile.ProductTypeData = JSON.parse(profile.ProductTypeData)
    }
    // if(typeof profile.ProductTypeData === "string")
    // {
    //   let productData=JSON.parse(profile.ProductTypeData)
    //   oppTemp = productData.filter ((item) => item.Code === SMCODE);
    // }
    // else
    // {
      oppTemp = profile.ProductTypeData.filter ((item) => item.Code === SMCODE);
    // }
    
      // console.log("copyfunction", indexCO);
      // console.log("methodology CostingOptions", methodology.CostingOptions);
      // console.log("currentCostingProfile", currentCostingProfile)

    let costingOption = _.cloneDeep(methodology.CostingOptions[indexCO]);
   // console.log(costingOption);
    delete costingOption["id"];

    //to avoid copying costs data

    Object.keys(costingOption)

      ?.filter((c) => c.toLowerCase().indexOf("cost") > -1)

      .map((costKey) => {

        costingOption[costKey] = null;

      });

    costingOption.CostsData = null;
    costingOption.isCopied=true

    costingOption.TimingsData = null;

    costingOption.CommercialHours = null;
    //console.log("indexCO , oppTemp.length, oppTemp", indexCO, oppTemp.length, oppTemp);

    // if (indexCO < (oppTemp.length)) {
    //   costingOption.OpportunityLineItemID = oppTemp[indexCO].OpportunityLineItemID;
    // } else {
    //   costingOption.OpportunityLineItemID = 
    //   profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[copyIndexCO].OpportunityLineItemID;
    // }
    

    //  const costingOptionsClone = _.cloneDeep(methodology.CostingOptions);
    var costingOptionsClone = _.cloneDeep(methodology.CostingOptions);
    if(profile.CountrySpecs[countryIndex].CountryCode !== project.CommissioningCountry && costoplen !== undefined){
      var temp = costingOptionsClone 
      if (costoplen < costingOptionsClone.length -1){
      var temp1 = costingOptionsClone[costoplen].Variables
      temp1.map((item)=>{
        if(item.label === "Commercial Local Support" && !item.value){
          item.value = fcValue
        }

      })
      temp[costoplen].Variables = temp1
      costingOptionsClone = temp
    }
      
    }

    (costoplen) ? costingOptionsClone.splice(costoplen + 1, 0, costingOption) : costingOptionsClone.splice(indexCO + 1, 0, costingOption)

    // setCopyVal(copyVal+1)

    if (copyIndexCO.length === 0 && !index ) {
      const hasProductVar = costingOptionsClone.every(element => {
        return element.Variables.some(variable => {
          return variable.label === "Product";
        });
      });
      if (!hasProductVar) {
        
        //var cntDefault = costingOptionsClone.Variables.filter(x => x.Default).length;
        let filterbySMcode=[]
        while(typeof profile.ProductTypeData === "string"){
              profile.ProductTypeData = JSON.parse(profile.ProductTypeData)
              }
        filterbySMcode = profile.ProductTypeData.filter ((item) => item.Code === SMCODE);
        costingOptionsClone.forEach(element => {
          if(!element.Variables.some(variable => variable.label === "Product")) {
            element.Variables.splice(0,0, {
                        label: "Product", 
                        id: "producttype", 
                        type: "single", 
                        Default: true,
                        "options": filterbySMcode
            });
          }
        });

      }
      costingOptionsClone.map(element =>{
            element.Variables.find((c) => c.id === "producttype").options= filterbySM
      })
      
//Adding product values and Opplineitemid values for newly automatically created costoptions
      let ind = 0;
      costingOptionsClone.map((co) => {
        co.Variables.find((c) => c.id === "producttype"    
        ).value = co.Variables.find((c) => c.id === "producttype"    
      ).options[ind].Productname
      co.OpportunityLineItemID = co.Variables.find((c) => c.id === "producttype"    
      ).options[ind].OpportunityLineItemID
      co.isSelected = !(co.Variables.find((c) => c.id === "producttype"    
      ).options[ind]?.isRed)
      co.Variables.find((c) => c.id === "producttype").isRed
      = (co.Variables.find((c) => c.id === "producttype"    
      ).options[ind]?.isRed)
      ind++
      // return product
      })
    }

    if (index) {
      // console.log("costingoptionsClone", costingOptionsClone);
      costingOptionsClone[costingOptionsClone.length-1].Variables.find((c) => c.id === "producttype").options= filterbySM;
      costingOptionsClone[costingOptionsClone.length-1].Variables.find((c) => c.id === "producttype").value = filterbySM[index].Productname;
      costingOptionsClone[costingOptionsClone.length-1].OpportunityLineItemID = filterbySM[index].OpportunityLineItemID;
      costingOptionsClone[costingOptionsClone.length-1].isSelected = !filterbySM[index]?.isRed;
      costingOptionsClone[costingOptionsClone.length-1].Variables.find((c) => c.id === "producttype").isRed = filterbySM[index]?.isRed;
    }
    //console.log("costingOptionsClone", costingOptionsClone);
    let count=0;

    let costingopt=costingOption.label.replace(/\d+|^\s+|\s+$/g,'').trim()
    let costingoptionslabel=[]

    for(let costingOption=0;costingOption<profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions.length;costingOption++){

      costingoptionslabel.push(profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex]

        .CostingOptions[costingOption].label)
    }
   // console.log("costingoptionslabel", costingoptionslabel);

    costingoptionslabel.map((label)=>{
        label=label.replace(/\d+|^\s+|\s+$/g,'').trim()

        if(label === costingopt)

        {
          count++;
        }

      })

    if (costingOption.value.includes("copy")) {

      var regex = /[\d|,|.|e|E|\+]+/g;

      const nameCostingOption = costingOption.label.split("-")[1];

      const copyPart = costingOption.value.split("-")[0];

      var copyNumber = copyPart.match(regex);

      costingOption.label =   costingOption.label.replace(/\d+|^\s+|\s+$/g,'').trim()+ " "+(parseInt(count)+1);
      costingOption.value =

        "copy" + (copyNumber + 1) + "_" + costingOption.label;

    } else {
        costingOption.label = costingOption.label.replace(/\d+|^\s+|\s+$/g,'').trim()+" "+(parseInt(count)+1);

      costingOption.value = "copy_"+ costingOption.label;
    }
    let profileTemp = _.cloneDeep(profile);

    profileTemp.CountrySpecs[countryIndex].MethodologySpecs[

      methodologyIndex

    ].CostingOptions = costingOptionsClone;
   // console.log(profileTemp);
   if (index) {
    profile = profileTemp;
    methodology = profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex];
    dispatch(updateProfile(profileTemp));
        dispatch(updateProject({ CostingProfiles: profileTemp }));
   }
    updateProjectAndProfileWRTCostingOption(profileTemp);

  }

  function RefreshCostingOption(indexCO) {
    const costingOption = _.cloneDeep(methodology.CostingOptions[indexCO]);
    costingOption.Variables.filter(v => !(v.label === "Product")).forEach((v) => {
      if (v.type == "single") v.value = "";
      else v.value = null;
    });

    let profileTemp = _.cloneDeep(profile);
    let costingOptionsClone = _.cloneDeep(
      profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex]
        .CostingOptions
    );
    costingOptionsClone[indexCO] = costingOption;
    profileTemp.CountrySpecs[countryIndex].MethodologySpecs[
      methodologyIndex
    ].CostingOptions = costingOptionsClone;
    updateProjectAndProfileWRTCostingOption(profileTemp);
  }

  function RenameClicked(indexCO) {
    let profileTemp = _.cloneDeep(profile);
    let costingOptionsClone = _.cloneDeep(
      profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex]
        .CostingOptions
    );
    costingOptionsClone[indexCO] = {
      ...costingOptionsClone[indexCO],
      rename: true,
    };
    profileTemp.CountrySpecs[countryIndex].MethodologySpecs[
      methodologyIndex
    ].CostingOptions = costingOptionsClone;
    updateProjectAndProfileWRTCostingOption(profileTemp);
  }

  function RenameCostingProfile(indexCO, value) {
    let profileTemp = _.cloneDeep(profile);
    let costingOptionsClone = _.cloneDeep(
      profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex]
        .CostingOptions
    );
    costingOptionsClone[indexCO] = {
      ...costingOptionsClone[indexCO],
      label: value,
    };
    profileTemp.CountrySpecs[countryIndex].MethodologySpecs[
      methodologyIndex
    ].CostingOptions = costingOptionsClone;
    updateProjectAndProfileWRTCostingOption(profileTemp);
  }
  function selectCostingOption(indexCO, e, co) {
    if (co.Variables.find((c) => c.id === "producttype")?.isRed) {
      toastr.info(
        "This product Line is deleted. Cannot select this cost Option"
      );
      return;
    } else {
      if (!e.target.checked) {
        let p = profile?.CountrySpecs[countryIndex]?.MethodologySpecs[methodologyIndex]?.CostingOptions[indexCO]?.Variables?.filter(v => v?.label === "Product")[0]?.value;
        let q = profile?.CountrySpecs[countryIndex]?.MethodologySpecs[methodologyIndex]?.CostingOptions?.map(c => {
         let r = c?.Variables?.filter(v => v?.label === "Product")[0]?.value
          return r
         })
         let s = q?.filter(c => c===p)
        if (s?.length == 1) {
           toastr.info("Cannot unselect costing options that are created by default")
           return
         }
        let _profileTemp = _.cloneDeep(profile);
        let _costingOptionsClone = _.cloneDeep(
          _profileTemp.CountrySpecs[countryIndex].MethodologySpecs[
            methodologyIndex
          ].CostingOptions
        );
        let reaminingCostingOption = _costingOptionsClone.filter(
          (co, Coindex) => Coindex !== indexCO
        );
        if (
          (reaminingCostingOption.length &&
            reaminingCostingOption.every((co) => !co.isSelected)) ||
          _costingOptionsClone.length === 1
        ) {
          toastr.info(
            "One Costing Option from Every Methodology Must be Selected"
          );
          return;
        }
      }
  
      let profileTemp = _.cloneDeep(profile);
      let costingOptionsClone = _.cloneDeep(
        profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex]
          .CostingOptions
      );
      costingOptionsClone[indexCO] = {
        ...costingOptionsClone[indexCO],
        isSelected: e.target.checked ? true : false,
      };
      profileTemp.CountrySpecs[countryIndex].MethodologySpecs[
        methodologyIndex
      ].CostingOptions = costingOptionsClone;
      updateProjectAndProfileWRTCostingOption(profileTemp);
    }
  }
  function RenameDone(indexCO) {
    let profileTemp = _.cloneDeep(profile);
    let costingOptionsClone = _.cloneDeep(
      profileTemp.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex]
        .CostingOptions
    );
    costingOptionsClone[indexCO] = {
      ...costingOptionsClone[indexCO],
      rename: false,
    };
    profileTemp.CountrySpecs[countryIndex].MethodologySpecs[
      methodologyIndex
    ].CostingOptions = costingOptionsClone;
    updateProjectAndProfileWRTCostingOption(profileTemp);
  }

  return (
    <React.Fragment>
      <Card className="ml-2 mr-2">
        <CardHeader>
          <Row>
            <Col>
              <CardTitle className="mb-0">
                <span
                  id={
                    "costing_options_card_" +
                    countryIndex +
                    "_" +
                    methodologyIndex
                  }
                >
                  Costing Options
                </span>
              </CardTitle>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>

            <Col md={12} className={"tableCopyProject"}>
              <AvForm encType="multipart/form-data" id="CostiongOptionsForm">
                <Table
                  size="sm"
                  bordered
                  style={{
                    backgroundColor: "white",
                    maxWidth: "100%",
                    width: "auto",
                  }}
                >
                  <thead>
                    <tr
                    >
                      <th
                        style={{
                          justifyContent: "space-between",
                          alignItems: "center",
                          minWidth: "250px",
                          maxWidth: "250px",
                          verticalAlign: "middle",
                        }}
                      >
                        <span>Costing Parameters</span>
                      </th>
                      {methodology.CostingOptions &&
                      
                        methodology.CostingOptions?.filter(
                          (co) => !co.toRemove
                        )?.map((co, indexCO) => (
                          
                          <th
                            className={co.isSelected ? "costing-options-th selected" : "costing-options-th"} 
                            key={
                              "c_" +
                              countryIndex +
                              "_m_" +
                              methodologyIndex +
                              "_co_" +
                              indexCO
                            }
                          >
                            
                            <div
                            
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                minWidth: "250px",
                                maxWidth: "250px",
                                paddingRight: ".3rem",
                              }}
                            >
                              {(!co.rename ) && (
                                <>
                                  
                                    <div  style={{ flexDirection: "column" }}>
                                    {
                                      <p
                                        title={co.label}
                                        style={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          maxWidth: "220px",
                                          color: co.isSelected ? '#fff' : '#000'
                                        }}
                                      >
                                        {co.label}
                                      </p>
                                    }
                                    {!disableFields ? (
                                      <div
                                        style={{
                                          justifyContent: "space-around",
                                          flexDirection: "row",
                                          maxWidth: "220px",
                                          display: "flex",
                                        }}
                                      >
                                        {vendor == true && (
                                          <FontAwesomeIcon
                                            title="Rename Costing Option"
                                            className="align-middle mr-2 addButton iconcolor"
                                            style={{
                                              cursor:
                                                methodology.NotApplicable ||
                                                CommissioningCountryCheck(false)
                                                  ? "no-drop"
                                                  : "pointer",
                                            }}
                                            icon={faPencilAlt}
                                            fixedWidth
                                            onClick={(e) => {
                                              if (
                                                !methodology.NotApplicable &&
                                                !CommissioningCountryCheck(
                                                  false
                                                )
                                              )
                                                RenameClicked(indexCO);
                                            }}
                                          />
                                        )}

                                        <div>
                                          <input
                                            type="checkbox"
                                            checked={co.isSelected}
                                            title="Select Option"
                                            onChange={(e) =>
                                              selectCostingOption(indexCO, e, co)
                                            }
                                          />
                                        </div>
                                        <div>
                                          {methodology.CostingOptions?.length >
                                            1 &&
                                            vendor == true && (
                                              <FontAwesomeIcon
                                                title="Delete Costing Option"
                                                className="align-middle mr-2 deleteButton"
                                                icon={faTimesCircle}
                                                fixedWidth
                                                style={{
                                                  cursor:
                                                    methodology.NotApplicable ||
                                                    CommissioningCountryCheck(
                                                      false
                                                    )
                                                      ? "no-drop"
                                                      : "pointer",
                                                }}
                                                onClick={() => {
                                                  // if (oppValue === "") {
                                                    //dispatch(setindexCOforCopy(indexCO));
                                                    handleIndexCO(indexCO);
                                                    removeItem(indexCO);
                                                  // } else {
                                                  //   errorMsgDelete()
                                                  // }
                                                   
                                                }}
                                              />
                                            )}
                                          {vendor == true && (
                                            <FontAwesomeIcon
                                              title="Copy costing options"
                                              className="align-middle mr-2 addButton"
                                              icon={faCopy}
                                              fixedWidth
                                              style={{
                                                cursor:
                                                  methodology.NotApplicable ||
                                                  CommissioningCountryCheck(
                                                    false
                                                  )                                                    ? "no-drop"
                                                    : "pointer",
                                              }}
                                              onClick={() => {
                                                // if (oppValue === "") {
                                                  if (!methodology.NotApplicable)
                                                  handleIndexCO(indexCO);
                                                  //console.log(copyIndexCO);
                                                  //dispatch(setindexCOforCopy(indexCO));
                                                  CopyCostingOption(indexCO);
                                                // } else {
                                                //   errorMsgCopy()
                                                // }
                                                
                                              }}
                                            />
                                          )}
                                          {vendor == true && (
                                            <FontAwesomeIcon
                                              title="Refresh Data"
                                              className="align-middle mr-2 addButton iconcolor"
                                              icon={faUndo}
                                              fixedWidth
                                              style={{
                                                cursor:
                                                  methodology.NotApplicable ||
                                                  CommissioningCountryCheck(
                                                    false
                                                  )
                                                    ? "no-drop"
                                                    : "pointer",
                                              }}
                                              onClick={() => {
                                                if (!methodology.NotApplicable)
                                                  RefreshCostingOption(indexCO);
                                              }}
                                            />
                                          )}
                                          
                                          {
                                            (oppValue|| (project.IsSyndicated && project.SyndicatedwithOpp !== true))&& 
                                               ( profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions.length < len) && (indexCO === 0) ?
                                              (CopywithOpp(indexCO)
                                              // SetCostOption(indexCO) 
                                            ) : (
                                              (
                                                (oppValue|| (!project.IsSyndicated || project.SyndicatedwithOpp == true)) && 
                                               (profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions.length === filterSMcode.length) &&
                                               (profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[indexCO]?.Variables) &&
                                               (profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[indexCO]?.Variables.some(variable => {
                                                return variable.label === "Product";
                                              })) && (indexCO === 0) && 
                                              // option.Variables.find((c) => c.id === "producttype").value !== undefined ?  option.Variables.find((c) => c.id === "producttype").value : option.CostingOptionData.producttype
                                              (profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions.some(i => i?.CostingOptionData?.producttype !== undefined)) &&
                                              (filterSMcode.some(i => i?.isRed === true)) &&
                                              UpdateDelCostOpt(indexCO)
                                              ) ||
                                              (
                                              (oppValue|| (project.IsSyndicated && project.SyndicatedwithOpp !== true)) && 
                                               (profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions.length === filterSMcode.length) &&
                                               (profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[indexCO]?.Variables) &&
                                               (profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[indexCO]?.Variables.some(variable => {
                                                return variable.label === "Product";
                                              })) &&
                                               ((
                                                !(profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[indexCO]?.Variables.filter(variable =>
                                                   variable.label === "Product"
                                                ).some(v => {
                                                  return v.options.some(opt => productNamestoCheck.includes(opt.Productname))
                                                }))
                                               ) || ((
                                                indexCO === 0
                                               ) && (!updateCostOpt))) &&
                                               UpdateCostOpt(indexCO)
                                              )
                                            ) 
                                          }
                                          {
                                             (
                                              (!project.IsSyndicated || project.SyndicatedwithOpp == true )&&(profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions.length > filterSMcode.length) && (indexCO === 0) &&  
                                              (profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[indexCO]?.Variables) &&
                                               (profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[indexCO]?.Variables.some(variable => {
                                                return variable.label === "Product";
                                              })) &&
                                              (profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions.some(i => i?.CostingOptionData?.producttype !== undefined)) &&
                                              (filterSMcode.some(i => i?.isRed === true)) &&
                                              UpdateDelCostOpt(indexCO)
                                            )
                                          }
                                          {
                                             (
                                              (profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[indexCO]?.Variables) &&
                                               (profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[indexCO]?.Variables.some(variable => {
                                                return variable?.label === "Product";
                                              })) &&  
                                              (profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions.length > filterSMcode.length) && (costOptCopyTest(profile)) && (indexCO === 0) && 
                                              UpdateCostOptCopy(indexCO)
                                            )
                                          }
                                          { // pusing prod, opp line item id for single costoption
                                                 (copyIndexCO.length === 0 && profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions.length === 1) && (profile.CountrySpecs[countryIndex].MethodologySpecs[methodologyIndex].CostingOptions[0]?.Variables) && (singleCostTest(profile)) && 
                                                  ( 
                                                  SingleCostOpt()  
                                                 )
                                                 
                                          }
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                  
                                </>
                              )}
                              {co.rename && (
                                <input
                                  type="text"
                                  value={co.label || ""}
                                  style={{ minWidth: "95%", maxWidth: "95%" }}
                                  onChange={(e) =>
                                    RenameCostingProfile(
                                      indexCO,
                                      e.target.value
                                    )
                                  }
                                  onBlur={() => {
                                    RenameDone(indexCO);
                                  }}
                                />
                              )}
                            </div>
                            
                          </th>
                        ))}
                    </tr>
                  </thead>
                  
                  <tbody>
                    {_.head(methodology.CostingOptions) &&
                      _.head(methodology.CostingOptions)
                        .Variables?.filter((hidVar) => !hidVar.ishidden)
                        .map((v, indexV) => (
                          <tr
                            key={
                              "c_" +
                              countryIndex +
                              "_m_" +
                              methodologyIndex +
                              "_v_" +
                              indexV
                            }
                          >
                            <td
                              style={{
                                fontWeight: v.costingheader ? "bold" : "",
                              }}
                              colSpan={
                                v.costingheader
                                  ? methodology.CostingOptions.length + 1
                                  : 1
                              }
                            >
                              {!v.Default &&
                                !v.costingheader &&
                                vendor == true && (
                                  <FontAwesomeIcon
                                    title="Delete Variable"
                                    className="align-middle mr-2 deleteButton"
                                    icon={faTimesCircle}
                                    fixedWidth
                                    style={{
                                      cursor: methodology.NotApplicable
                                        ? "no-drop"
                                        : "pointer",
                                    }}
                                    onClick={() => {
                                      if (!methodology.NotApplicable)
                                        if (
                                          window.confirm(
                                            "Are you sure to delete the variable?"
                                          )
                                        )
                                          RemoveAdditionalVariable(v, indexV);
                                    }}
                                  />
                                )}
                              {v.label + (v.mandatory ? "(*)" : "")}
                            </td>
                            {!v.costingheader &&
                              methodology.CostingOptions?.map((co, indexCO) => (
                                <td
                                  key={
                                    "c_" +
                                    countryIndex +
                                    "_m_" +
                                    methodologyIndex +
                                    "_co_" +
                                    indexCO
                                  }
                                  style={{ maxWidth: "300px" }}
                                  className="costingOptions"
                                >
                                  {ReturnControl(indexCO, indexV, v)}
                                </td>
                              ))}
                          </tr>
                        ))}
                    {!(
                      methodology.NotApplicable ||
                      CommissioningCountryCheck(false)
                    ) && (
                      <tr
                        key={"add_r_" + countryIndex + "_" + methodologyIndex}
                      >
                        <td style={{ textAlign: "center" }}>
                          {vendor == true && (
                            <FontAwesomeIcon
                              title="Additional Costing Parameters"
                              className="align-middle mr-2 addButton"
                              icon={faPlusCircle}
                              fixedWidth
                              style={{
                                cursor: methodology.NotApplicable
                                  ? "no-drop"
                                  : "pointer",
                                fontSize: "22px",
                              }}
                              onClick={() => {
                                if (!methodology.NotApplicable) {
                                  SetAdditionalVariables();
                                  setModelStateVar(true);
                                }
                              }}
                            />
                          )}
                        </td>
                        <td></td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </AvForm>
            </Col>

          </Row>
        </CardBody>
      </Card>
      <Modal
        isOpen={modelState}
        toggle={() => setModelState(!modelState)}
        centered
        size="md"
      >
        <ModalHeader toggle={() => setModelState(!modelState)}>
          {modelMode == "new"
            ? "New Costing Option"
            : modelMode == "copy"
            ? "Copy Costing Option"
            : "Rename Costing Option"}
        </ModalHeader>
        <ModalBody className="m-3" style={styleModelBody}>
          <Row>
            <Col
              md={
                modelMode == "copy" && methodology.CostingOptions?.length > 1
                  ? 6
                  : 12
              }
            >
              <FormGroup>
                <Label>Costing Option Name </Label>
                <input
                  type="text"
                  style={{ width: "100%" }}
                  value={newCostingOption}
                  onChange={(e) => {
                    setNewCostingOption(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
            {modelMode == "copy" &&
              methodology.CostingOptions?.length > 1 &&
              !newCostingOption && (
                <Col md={6}>
                  <FormGroup>
                    <Label>Copy Data To</Label>
                    <Select
                      name={"copyFrom"}
                      value={copyToCostingOption}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={methodology.CostingOptions.filter(
                        (x, i) => i != costingOptionIndex
                      )}
                      onChange={(val) => setCopyTo(val)}
                    />
                  </FormGroup>
                </Col>
              )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={() => {
              setModelState(!modelState);
              saveCostingOption(modelMode);
              setCostingOptionIndex(null);
              setNewCostingOption(null);
            }}
          >
            Save
          </Button>
          <Button color="danger" onClick={() => setModelState(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      {addVariables && addVariables.length > 0 && (
        <Modal
          isOpen={modelStateVar}
          toggle={() => setModelStateVar(!modelStateVar)}
          centered
          size="lg"
        >
          <ModalHeader
            toggle={() => {
              setModelStateVar(!modelStateVar);
              ClearSearch();
            }}
          >
            {/* <img src={logo} style={LogoImgStyle} className="modallogo"/> */}
          </ModalHeader>
          <ModalBody className="m-3" style={styleModelBody}>
            <div>
              <span className="modaltitle m-0">
                {'Adding Additional Costing Parameters'}
              </span>
            </div>
            <Row>
              <Col md={"2"}>
                <b>Type To Search</b>
              </Col>
              <Col md={"8"}>
                <input
                  type="text"
                  style={{ width: "100%" }}
                  value={searchValue || ""}
                  placeholder='Please type the field name that you want to add as "Costing Parameters"'
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    SearchValueChanged(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>{RenderVariablesNew()}</Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              onClick={() => {
                AddRemoveOptionalVariablesNew();
              }}
            >
              Save
            </Button>
            <Button
              color="danger"
              onClick={() => {
                setModelStateVar(false);
                ClearSearch();
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </React.Fragment>
  );
};
export default Start;