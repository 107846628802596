import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Button, FormGroup, Label, Row, Col, Form, Input, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Layout from "../../layouts/Project"
import './DataExport.css'
import axios from "../../axios-interceptor";
import * as XLSX from 'xlsx';
import { toastr } from 'react-redux-toastr';
import { ModalFooter } from 'react-bootstrap';
 
const ExportData = () => {
    const today = new Date();
    const startOfyear = new Date(today.getFullYear(), 0, 1);
    const codeLabels = useSelector(({ codeLabels }) => codeLabels);
    let baseCriteria =
    useSelector(({ codeLabels }) => codeLabels.ExportTemplateOptions.filter(b => b.Label.TeamplateName == 'BaseTemplate')) || [];
    let options = useSelector(({ codeLabels }) => codeLabels.ExportTemplateOptions.filter(b => !(b.Label.TeamplateName == 'BaseTemplate'))).map((item) => {
      return { value: item.Label.TeamplateName, label: item.Label.TeamplateName, 
        userInstruction: item.Label.userInstruction, ProjectStatus: item.Label.ProjectStatus, 
        CommissionedCountry: item.Label.CommissionedCountry, ExportTo: item.Label.ExportTo};
    }) || [];
  // State for Predefined Template
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [recentCriteria, setRecentCriteria] = useState(null);
  const [templateText, setTemplateText] = useState(''); // Text box for instructions based on template
  const [templateOptions, setTemplateOptions] = useState([]);
  // State for Commissioned Country (Multi-select)
  const [commissionedCountry, setCommissionedCountry] = useState([]);
  const [commissionedCountryOptions, setCommissionedCountryOptions] = useState([{ Label: "All Countries", Code: "All Countries"}, ...codeLabels.UserScopeOptions]);
  // State for Project Status (Multi-select)
  const [projectStatus, setProjectStatus] = useState([]);
  const [projectStatusOptions, setProjectStatusOptions] = useState([{ Label: "All Status", Code: "All Status"}, ...codeLabels.ProjectStatusOptions]);
  // State for Date Pickers
  const [creationDate, setCreationDate] = useState({ from: startOfyear, to: today });
  const [commissionedDate, setCommissionedDate] = useState({ from: null, to: null });
  const [projectLiveDate, setProjectLiveDate] = useState({ from: null, to: null });
  // State for options in both lists
  const [searchFieldFromOptions, setSearchFieldFromOptions] = useState(baseCriteria[0].Label.ExportFrom);
  const [selectedFieldOptions, setSelectedFieldOptions] = useState([]);
  // State for search inputs
  const [searchFieldFromText, setSearchFieldFromText] = useState('');
  const [selectedFieldText, setSelectedFieldText] = useState('');
  // State for selected items in each list
  const [selectedFieldFrom, setSelectedFieldFrom] = useState([]);
  const [selectedField, setSelectedField] = useState([]);
  const [loading, setLoading] = useState(false)
  const [isSaveModalOpen, setIsaveModalOpen] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const recordloading = useSelector(
    ({ app }) => app.recordloading
  );
  let userRecord = JSON.parse(localStorage.getItem('userRecord'));
  const resetRecentCriteria = async() => {

    if (recentCriteria.length > 0){
      console.log("dataresponseee", recentCriteria[0].CriteriaData);
      let selectedOption = recentCriteria[0].CriteriaData
      let payload1 = [selectedOption]
    let newTempopt = payload1.map((c) => {
      return {value: c.TeamplateName, label: c.TeamplateName, 
          userInstruction: c.userInstruction, ProjectStatus: c.ProjectStatus, 
          CommissionedCountry: c.CommissionedCountry, ExportTo: c.ExportTo}
  })
  // options.push(newTempopt[0])
  setTemplateOptions(options)
      selectedOption.value = selectedOption.TeamplateName 
      selectedOption.label = selectedOption.TeamplateName
      console.log("selectedOption", selectedOption)
    setSelectedTemplate(newTempopt[0]);
    if (selectedOption) {
      console.log("selectedOption", selectedOption)
      // Update the text box based on the template selected
      setTemplateText(` ${selectedOption.userInstruction}`);
      setSelectedFieldOptions(selectedOption.ExportTo);
      let projStatus = [];
      selectedOption.ProjectStatus.map(p => {
        projStatus.push(...projectStatusOptions.filter(c => c.Code == p).map((item) => {
          return { value: item.Label, label: item.Label, code: item.Code };
        }))
      })
      console.log("projStatusprojStatus", projStatus)
      setProjectStatus(projStatus);

      let commCountry = [];
      selectedOption.CommissionedCountry.map(p => {
        commCountry.push(...commissionedCountryOptions.filter(c => c.Code == p).map((item) => {
          return { value: item.Label, label: item.Label, code: item.Code };
        }))
      })
      setCommissionedCountry(commCountry);
      setSearchFieldFromOptions(baseCriteria[0].Label.ExportFrom.filter(item => !selectedOption.ExportTo.includes(item)));

    } else {
      setTemplateText(''); // Clear if no template is selected
    }
    } else {
      console.log("baseCriteria", baseCriteria)
      setTemplateOptions(options)
      let selectedOption = baseCriteria[0].Label
      let projStatus = [];
      selectedOption.ProjectStatus.map(p => {
        projStatus.push(...projectStatusOptions.filter(c => c.Code == p).map((item) => {
          return { value: item.Label, label: item.Label, code: item.Code };
        }))
      })
      console.log("projStatusprojStatus", projStatus)
      setProjectStatus(projStatus);

      let commCountry = [];
      selectedOption.CommissionedCountry.map(p => {
        commCountry.push(...commissionedCountryOptions.filter(c => c.Code == p).map((item) => {
          return { value: item.Label, label: item.Label, code: item.Code };
        }))
      })
      setCommissionedCountry(commCountry);

    }
    

    
  }
  const getDefault = async() => {
    let response =  await axios.get(`/Exports/getCriteria/${userRecord.id}`)
    setRecentCriteria(response.data.ExportCriteria);
    if (response.data.ExportCriteria.length > 0){
      console.log("dataresponseee", response.data.ExportCriteria[0].CriteriaData);
      let selectedOption = response.data.ExportCriteria[0].CriteriaData
      let payload1 = [selectedOption]
    let newTempopt = payload1.map((c) => {
      return {value: c.TeamplateName, label: c.TeamplateName, 
          userInstruction: c.userInstruction, ProjectStatus: c.ProjectStatus, 
          CommissionedCountry: c.CommissionedCountry, ExportTo: c.ExportTo}
  })
  // options.push(newTempopt[0])
  setTemplateOptions(options)
      selectedOption.value = selectedOption.TeamplateName 
      selectedOption.label = selectedOption.TeamplateName
      console.log("selectedOption", selectedOption)
    setSelectedTemplate(newTempopt[0]);
    if (selectedOption) {
      console.log("selectedOption", selectedOption)
      // Update the text box based on the template selected
      setTemplateText(` ${selectedOption.userInstruction}`);
      setSelectedFieldOptions(selectedOption.ExportTo);
      let projStatus = [];
      selectedOption.ProjectStatus.map(p => {
        projStatus.push(...projectStatusOptions.filter(c => c.Code == p).map((item) => {
          return { value: item.Label, label: item.Label, code: item.Code };
        }))
      })
      console.log("projStatusprojStatus", projStatus)
      setProjectStatus(projStatus);

      let commCountry = [];
      selectedOption.CommissionedCountry.map(p => {
        commCountry.push(...commissionedCountryOptions.filter(c => c.Code == p).map((item) => {
          return { value: item.Label, label: item.Label, code: item.Code };
        }))
      })
      setCommissionedCountry(commCountry);
      setSearchFieldFromOptions(baseCriteria[0].Label.ExportFrom.filter(item => !selectedOption.ExportTo.includes(item)));

    } else {
      setTemplateText(''); // Clear if no template is selected
    }
    } else {
      console.log("baseCriteria", baseCriteria)
      setTemplateOptions(options)
      let selectedOption = baseCriteria[0].Label
      let projStatus = [];
      selectedOption.ProjectStatus.map(p => {
        projStatus.push(...projectStatusOptions.filter(c => c.Code == p).map((item) => {
          return { value: item.Label, label: item.Label, code: item.Code };
        }))
      })
      console.log("projStatusprojStatus", projStatus)
      setProjectStatus(projStatus);

      let commCountry = [];
      selectedOption.CommissionedCountry.map(p => {
        commCountry.push(...commissionedCountryOptions.filter(c => c.Code == p).map((item) => {
          return { value: item.Label, label: item.Label, code: item.Code };
        }))
      })
      setCommissionedCountry(commCountry);

    }
    

    
  }
  useEffect(() => {
    console.log("userRecordddd", baseCriteria);
    getDefault();

}, []);

const setCreationFromDate = (date) => {
  if ((  (commissionedDate.from !== null && commissionedDate.from < date) || (date > commissionedDate.to && commissionedDate.to !== null)) && date !== null) {
    toastr.error("Commissioned dates should be in the range of commissionedDate dates")
  } else if (( (projectLiveDate.from !== null && projectLiveDate.from < date) || (date > projectLiveDate.to && projectLiveDate.to !== null)) && date !== null) {
    toastr.error("Commissioned dates should be in the range of Project Live dates")
  }
  
  else {
if (date <= creationDate.to) {
  setCreationDate({ ...creationDate, from: date})
} else if (creationDate.to == null) {
  setCreationDate({ ...creationDate, from: date})
}
else {
  toastr.error("From Date should not be after To date")
}
}
}

const setCreationToDate = (date) => {
  if (((commissionedDate.to !== null && date < commissionedDate.to) || (commissionedDate.from > date && commissionedDate.from !== null)) && date !== null) {
    toastr.error("Commissioned dates should be in the range of commissionedDate dates")
  } else if (( (projectLiveDate.to !== null && date < projectLiveDate.to) || (projectLiveDate.from > date && projectLiveDate.from !== null)) && date !== null) {
    toastr.error("Commissioned dates should be in the range of Project Live dates")
  } else {
  if (creationDate.from <= date) {
    setCreationDate({ ...creationDate, to: date})
  } else if (date <= null) {
    setCreationDate({ ...creationDate, to: date})
  }
   else {
    toastr.error("From Date should not be after To date")
  }    
}
}

const setCommissionedFromDate = (date) => {
  // if ((creationDate.from == null || creationDate.from > date || date > creationDate.to) && date !== null) {
    if (( (creationDate.from !== null && creationDate.from > date) || (date > creationDate.to && creationDate.to !== null)) && date !== null) {
    toastr.error("Commissioned dates should be in the range of Project creation dates")
  } else if (( (projectLiveDate.from !== null && projectLiveDate.from < date) || (date > projectLiveDate.to && projectLiveDate.to !== null)) && date !== null) {
    toastr.error("Commissioned dates should be in the range of Project Live dates")
  }
  
  else {
  if (date <= commissionedDate.to) {
    setCommissionedDate({ ...commissionedDate, from: date})
  } 
  else if (commissionedDate.to == null) {
    setCommissionedDate({ ...commissionedDate, from: date})
  }
  else {
    toastr.error("From Date should not be after To date")
  }
}
}

const setCommissionedToDate = (date) => {
  // if ((creationDate.to == null || date > creationDate.to || creationDate.from > date) && date !== null) {
    if (((creationDate.to !== null && date > creationDate.to) || (creationDate.from > date && creationDate.from !== null)) && date !== null) {
    toastr.error("Commissioned dates should be in the range of Project creation dates")
  } else if (( (projectLiveDate.to !== null && date < projectLiveDate.to) || (projectLiveDate.from > date && projectLiveDate.from !== null)) && date !== null) {
    toastr.error("Commissioned dates should be in the range of Project Live dates")
  } 
  else {
  if (commissionedDate.from <= date) {
    setCommissionedDate({ ...commissionedDate, to: date})
  } else if (commissionedDate.from == null) {
    setCommissionedDate({ ...commissionedDate, to: date})
  } else if (date == null) {
    setCommissionedDate({ ...commissionedDate, to: date})
  }
   else {
    toastr.error("From Date should not be after To date")
  }
}
}

const setLiveFromDate = (date) => {
  // if ((commissionedDate.from == null || creationDate.from == null || creationDate.from > date || commissionedDate.from > date || date > creationDate.to  || date > commissionedDate.to) && date !== null ) {
    if (( (creationDate.from !== null && creationDate.from > date) || (date > creationDate.to && creationDate.to !== null) ) && date !== null ) {
    toastr.error("Project Live dates should be in the range of ProjectCreation dates")
  }  else if (( (commissionedDate.from !== null && commissionedDate.from > date) || (date > commissionedDate.to && commissionedDate.to !== null)) && date !== null ) {
  toastr.error("Project Live dates should be in the range of Commissioned dates")
} 
  else {
  if (date <= projectLiveDate.to) {
    setProjectLiveDate({ ...projectLiveDate, from: date})
  } else if (projectLiveDate.to == null) {
    setProjectLiveDate({ ...projectLiveDate, from: date})
  }
   else {
    toastr.error("From Date should not be after To date")
  }
}
}

const setLiveToDate = (date) => {
  // if ((commissionedDate.to == null || creationDate.to == null || date > creationDate.to  || date > commissionedDate.to || creationDate.from > date || commissionedDate.from > date ) && date !== null  ) {
    if (( (creationDate.to !== null && date > creationDate.to) || (creationDate.from > date && creationDate.from !== null) ) && date !== null  ) {
    toastr.error("Project Live dates should be in the range of ProjectCreation dates")
  } else if (((date > commissionedDate.to && commissionedDate.to !== null )  || (commissionedDate.from > date && commissionedDate.from !== null) ) && date !== null  ) {
 toastr.error("Project Live dates should be in the range of Commissioned dates")
} 
  else {
  if (projectLiveDate.from <= date) {
    setProjectLiveDate({ ...projectLiveDate, to: date})
  }  else if (projectLiveDate.from == null) {
    setProjectLiveDate({ ...projectLiveDate, to: date})
  } else if (date == null) {
    setProjectLiveDate({ ...projectLiveDate, to: date})
  }
  else {
    toastr.error("From Date should not be after To date")
  }
}
}


// selectedTemplate
  // Handle template selection
  const handleTemplateSelect = (selectedOption) => {
    console.log("selectedOption", selectedOption)
    setSelectedTemplate(selectedOption);
    if (selectedOption) {
      console.log("selectedOption", selectedOption)
      // Update the text box based on the template selected
      setTemplateText(` ${selectedOption.userInstruction}`);
      setSelectedFieldOptions(selectedOption.ExportTo);
      let projStatus = [];
      selectedOption.ProjectStatus.map(p => {
        projStatus.push(...projectStatusOptions.filter(c => c.Code == p).map((item) => {
          return { value: item.Label, label: item.Label, code: item.Code };
        }))
      })
      setProjectStatus(projStatus);

      let commCountry = [];
      selectedOption.CommissionedCountry.map(p => {
        commCountry.push(...commissionedCountryOptions.filter(c => c.Code == p).map((item) => {
          return { value: item.Label, label: item.Label, code: item.Code };
        }))
      })
      setCommissionedCountry(commCountry);
      setSearchFieldFromOptions(baseCriteria[0].Label.ExportFrom.filter(item => !selectedOption.ExportTo.includes(item)));

    } else {
      setTemplateText(''); // Clear if no template is selected
      // resetRecentCriteria();
      // setTemplateOptions([]);
      setSearchFieldFromOptions(baseCriteria[0].Label.ExportFrom)
      setSelectedFieldOptions([])
    }
  };
  const handleCommissionedCountrySelect = (selectedOption) => {
    console.log("commissionedCountry", selectedOption)
    if (selectedOption !== null && selectedOption?.length > 0 && selectedOption.map(c => c.code).includes("All Countries")) {
      let status = [{ value: "All Countries", label: "All Countries", code: "All Countries" }]
      setCommissionedCountry(status)
    } else {
      setCommissionedCountry(selectedOption);
    }
  };
  const handleProjectStatusSelect = (selectedOption) => {
    console.log("projectStatus", selectedOption)
    if (selectedOption !== null && selectedOption?.length > 0 && selectedOption.map(c => c.code).includes("All Status")) {
      let status = [{ value: "All Status", label: "All Status", code: "All Status" }]
      setProjectStatus(status)
    } else {
      setProjectStatus(selectedOption);
    }
  };
  const selectedFieldsRef = useRef(null); // Add a ref to scroll the "Selected Fields" list
  // Handle adding fields from "Search Field From" to "Selected Fields"
  const handleAddField = () => {
    setSelectedFieldOptions([...selectedFieldOptions, ...selectedFieldFrom]);
    setSearchFieldFromOptions(searchFieldFromOptions.filter(item => !selectedFieldFrom.includes(item)));
   
    // Automatically select the moved items in the "Selected Fields"
    // setSelectedField(selectedFieldFrom);
   
    // Clear selections after moving
    setSelectedFieldFrom([]);
   
    // Scroll to the newly selected items in the "Selected Fields"
    setTimeout(() => {
      if (selectedFieldsRef.current) {
  const selectedItems = document.querySelectorAll(".list-group-item.active");
        if (selectedItems.length > 0) {
          selectedItems[selectedItems.length - 1].scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }
    }, 0);
  };
 
  const searchFieldsRef = useRef(null); // Add a ref to scroll the "Search Field From" list
 
  // Handle removing fields from "Selected Fields" to "Search Field From"
  const handleRemoveField = () => {
    setSearchFieldFromOptions([...searchFieldFromOptions, ...selectedField]);
    setSelectedFieldOptions(selectedFieldOptions.filter(item => !selectedField.includes(item)));
   
    // Automatically select the moved items in the "Search Field From"
    // setSelectedFieldFrom(selectedField);
   
    // Clear selections after moving
    setSelectedField([]);
   
    // Scroll to the newly selected items in the "Search Field From"
    setTimeout(() => {
      if (searchFieldsRef.current) {
  const selectedItems = document.querySelectorAll(".list-group-item.active");
        if (selectedItems.length > 0) {
          selectedItems[selectedItems.length - 1].scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }
    }, 0);
  };
 
  // Handle list item clicks for Search Field From (left side)
  const handleSelectFrom = (item) => {
    setSelectedField([]); // Clear selections from right side
    if (selectedFieldFrom.includes(item)) {
      setSelectedFieldFrom(selectedFieldFrom.filter(i => i !== item)); // Deselect if already selected
    } else {
      setSelectedFieldFrom([...selectedFieldFrom, item]); // Add to selection
    }
  };
 
  // Handle list item clicks for Selected Fields (right side)
  const handleSelectField = (item) => {
    setSelectedFieldFrom([]); // Clear selections from left side
    if (selectedField.includes(item)) {
      setSelectedField(selectedField.filter(i => i !== item)); // Deselect if already selected
    } else {
      setSelectedField([...selectedField, item]); // Add to selection
    }
  };

  // Function to move selected items upwards
const moveUp = () => {
  setSelectedFieldOptions(prevOptions => {
    const selectedIndices = selectedField.map(item => prevOptions.indexOf(item)).sort((a,b) => a-b);
    if (selectedIndices[0] > 0) {
      const newOptions = [...prevOptions];
      selectedIndices.forEach(index => {
        const item = newOptions.splice(index, 1)[0];
        newOptions.splice(index - 1, 0, item);
      });
      return newOptions;
    }
    return prevOptions;
  });
};
 
// Function to move selected items downwards
const moveDown = () => {
  setSelectedFieldOptions(prevOptions => {
    const selectedIndices = selectedField.map(item => prevOptions.indexOf(item)).sort((a, b) => b - a);
    if (selectedIndices[0] < prevOptions.length - 1) {
      const newOptions = [...prevOptions];
      selectedIndices.forEach(index => {
        const item = newOptions.splice(index, 1)[0];
        newOptions.splice(index + 1, 0, item);
      });
      return newOptions;
    }
    return prevOptions;
  });
};
 
 
  // Filter options based on search input
  const filterSearchFieldFromOptions = searchFieldFromOptions.filter(item =>
    item.toLowerCase().includes(searchFieldFromText.toLowerCase())
  );
  const filterSelectedFieldOptions = selectedFieldOptions.filter(item =>
    item.toLowerCase().includes(selectedFieldText.toLowerCase())
  );
  useEffect(() => {
    const findMatchingTemplate = () => {
        for (let template of templateOptions) {
            const isMatchingTemplate =
                JSON.stringify(template.CommissionedCountry.sort()) === JSON.stringify(commissionedCountry?.map(selected => selected.code).sort()) &&
                JSON.stringify(template.ProjectStatus.sort()) === JSON.stringify(projectStatus?.map(selected => selected.code).sort()) &&
                template.ExportTo.every(field => filterSelectedFieldOptions?.includes(field)) &&
                baseCriteria[0].Label.ExportFrom?.every(field => filterSearchFieldFromOptions?.includes(field) || template.ExportTo?.includes(field));
            
            if (isMatchingTemplate) {
                // Only update if the selected template is different
                if (!selectedTemplate || selectedTemplate.label !== template.label) {
                    setSelectedTemplate(template);
                    setTemplateText(template.userInstruction);
                }
                return;
            }
        }
 
        // If no match is found, clear the template only if it's not already null
        if (selectedTemplate) {
            setSelectedTemplate(null);
            setTemplateText('');
        }
    };
 
    // Call the matching function whenever relevant states change
    findMatchingTemplate();
}, [commissionedCountry, projectStatus, filterSearchFieldFromOptions, filterSelectedFieldOptions, options, baseCriteria]);
  const downloadData = async() => {
    if (creationDate.from !== null && creationDate.to == null) {
      toastr.error("Please set Project creation end date")
    } else if (creationDate.to !== null && creationDate.from == null) {
      toastr.error("Please set Project creation start date")
    } else if (commissionedDate.from !== null && commissionedDate.to == null) {
      toastr.error("Please set Project commission end date")
    } else if (commissionedDate.to !== null && commissionedDate.from == null) {
      toastr.error("Please set Project commission start date")
    } else if (projectLiveDate.from !== null && projectLiveDate.to == null) {
      toastr.error("Please set Project live end date")
    } else if (projectLiveDate.to !== null && projectLiveDate.from == null) {
      toastr.error("Please set Project live start date")
    }
    else {
    setLoading(true);
    try {
      console.log("payload", selectedTemplate?.label,
        selectedTemplate?.userInstruction,
        commissionedCountry.map(c => c.code),
        projectStatus.map(c => c.code),
        // creationDate.from.toLocaleString('en-US', {month: 'short', year: 'numeric'}).replace(' ', '-'),
        // creationDate.to.toLocaleString('en-US', {month: 'short', year: 'numeric'}).replace(' ', '-'),
        commissionedDate.from,
        commissionedDate.to,
        projectLiveDate.from,
        projectLiveDate.to, filterSelectedFieldOptions)
        // console.log("ExportTo", filterSelectedFieldOptions)
      let payload = {
        "TeamplateName": selectedTemplate?.label ? selectedTemplate?.label : [],
        "userInstruction": selectedTemplate?.userInstruction ? selectedTemplate?.userInstruction : null,
        "ExportTo": filterSelectedFieldOptions,
        "ExportFrom": searchFieldFromOptions,
        "ProjectStatus": projectStatus.map(c => c.code),
        "CommissionedCountry": commissionedCountry.map(c => c.code),
        "ProjectCreationStartDate": creationDate.from ? [creationDate.from.toLocaleString('en-US', {month: 'short', year: 'numeric'}).replace(' ', '-')]: null,
        "ProjectCreationEndDate": creationDate.to ? [creationDate.to.toLocaleString('en-US', {month: 'short', year: 'numeric'}).replace(' ', '-')] : null,
        "CommissionedStartDate": commissionedDate.from ? [commissionedDate.from.toLocaleString('en-US', {month: 'short', year: 'numeric'}).replace(' ', '-')] : null,
        "CommissionedEndDate": commissionedDate.to ? [commissionedDate.to.toLocaleString('en-US', {month: 'short', year: 'numeric'}).replace(' ', '-')] : null,
        "ProjectLiveStartDate": projectLiveDate.from ? [projectLiveDate.from.toLocaleString('en-US', {month: 'short', year: 'numeric'}).replace(' ', '-')] : null,
        "ProjectLiveEndDate": projectLiveDate.to ? [projectLiveDate.to.toLocaleString('en-US', {month: 'short', year: 'numeric'}).replace(' ', '-')] : null
    }
      let response =  await axios.post(`/Exports/${userRecord.id}`, payload)
      console.log("responseresponse", response);
      const worksheet = XLSX.utils.json_to_sheet(response.data.data);
      const workbook = XLSX.utils.book_new();
  
      XLSX.utils.book_append_sheet(workbook, worksheet, 'CINTRAL_RawData');
      // 1. Set column widths to 30 for all columns
 
worksheet['!cols'] = Array(filterSelectedFieldOptions?.length).fill({ wch: 20 }); // Set all columns to width 30
 
  const date = new Date();
  const formattedDate1 = date.toLocaleString('en-GB', {
    day: '2-digit',
    month: "short",
    year: "numeric"
  })
  const formattedDate = formattedDate1.replace(' ', '_');
  console.log("formattedDate formattedDate1", formattedDate, formattedDate1 );
      XLSX.writeFile(workbook, `CINTRAL_rawdata_${formattedDate}.xlsx`)
  
    } catch (error) {
      // toastr.error(error);
      setLoading(false);
        toastr.error("Something went wrong, Couldn't download data")
    } finally {
      setLoading(false);
    }
  }
  }

  const toggleSaveModal = () => {
    setIsaveModalOpen(!isSaveModalOpen);
  };

  const  handleSaveTemplate = async() => {
    if(templateName.trim() === "") {
      toastr.error("Please enter template name")
      return;
    }
    const newTemplateName = `My Template - ${templateName}`;
    console.log("newTemplateName", newTemplateName);

    setLoading(true);
    try {
      console.log("payload", [newTemplateName],
        selectedTemplate?.userInstruction,
        commissionedCountry.map(c => c.code),
        projectStatus.map(c => c.code),
        creationDate.from.toLocaleString('en-US', {month: 'short', year: 'numeric'}).replace(' ', '-'),
        creationDate.to.toLocaleString('en-US', {month: 'short', year: 'numeric'}).replace(' ', '-'),
        commissionedDate.from,
        commissionedDate.to,
        projectLiveDate.from,
        projectLiveDate.to, filterSelectedFieldOptions)
        // console.log("ExportTo", filterSelectedFieldOptions)
      let payload = {
        "TeamplateName": newTemplateName ? [newTemplateName] : [],
        "userInstruction": selectedTemplate?.userInstruction ? selectedTemplate?.userInstruction : null,
        "ExportTo": filterSelectedFieldOptions,
        "ExportFrom": searchFieldFromOptions,
        "ProjectStatus": projectStatus.map(c => c.code),
        "CommissionedCountry": commissionedCountry.map(c => c.code),
        "ProjectCreationStartDate": creationDate.from ? [creationDate.from.toLocaleString('en-US', {month: 'short', year: 'numeric'}).replace(' ', '-')]: null,
        "ProjectCreationEndDate": creationDate.to ? [creationDate.to.toLocaleString('en-US', {month: 'short', year: 'numeric'}).replace(' ', '-')] : null,
        "CommissionedStartDate": commissionedDate.from ? [commissionedDate.from.toLocaleString('en-US', {month: 'short', year: 'numeric'}).replace(' ', '-')] : null,
        "CommissionedEndDate": commissionedDate.to ? [commissionedDate.to.toLocaleString('en-US', {month: 'short', year: 'numeric'}).replace(' ', '-')] : null,
        "ProjectLiveStartDate": projectLiveDate.from ? [projectLiveDate.from.toLocaleString('en-US', {month: 'short', year: 'numeric'}).replace(' ', '-')] : null,
        "ProjectLiveEndDate": projectLiveDate.to ? [projectLiveDate.to.toLocaleString('en-US', {month: 'short', year: 'numeric'}).replace(' ', '-')] : null
    }
    let payload1 = [payload]
    let newTemp = payload1.map((c) => {
      return {value: c.TeamplateName, label: c.TeamplateName, 
          userInstruction: c.userInstruction, ProjectStatus: c.ProjectStatus, 
          CommissionedCountry: c.CommissionedCountry, ExportTo: c.ExportTo}
  })
      let response =  await axios.put(`/Exports/saveCriteria/${userRecord.id}`, payload)
      console.log("responseresponse", response);
      if (response.status === 201) {
        console.log("options, payload", options, payload)
        templateOptions.push(newTemp[0])
        toastr.success("Template saved")
      }
      // const worksheet = XLSX.utils.json_to_sheet(response.data.data);
      // const workbook = XLSX.utils.book_new();
  
      // XLSX.utils.book_append_sheet(workbook, worksheet, 'Project Data');
  
      // XLSX.writeFile(workbook, 'project_data.xlsx')
  
    } catch (error) {
      toastr.error(error);
    } finally {
      setIsaveModalOpen(false);
    setTemplateName('');
    setLoading(false);
    }
  }


  return (
    <div>
    <Layout
onlyHeader={true}
CardHeader="Download Data"  
>
  {loading ? (
              <div id="pageCoverSpin"></div>
            ) :
    <div className='data-export'>
      {/* <h2>Download Data</h2> */}
      <Form>
 
        {/* Predefined Template Dropdown */}
        <Row style= {{marginTop:'-5px'}} >
        <Col md={2} >
              <h4>Predefined Template</h4>
          </Col>
          <Col >
            <FormGroup>
              {/* <Label for="templateSelect">Predefined Template</Label> */}
              <Select
                options={templateOptions}
                value={selectedTemplate}
                onChange={handleTemplateSelect}
                placeholder="Select Template"
                isClearable
              />
            </FormGroup>
          </Col>
          <Col >
            {/* Text box beside predefined template for dynamic instruction */}
            {(templateText && templateText !== " null") && <FormGroup>
              {/* <Input
                type="text"
                className="form-control"
                value={templateText}
                readOnly
                disabled
                style={{ 'min-height': '35px', 'font-weight':'bold' }} 
              /> */}
              <h4>{templateText}</h4>
            </FormGroup>}
          </Col>
        </Row>
 
        {/* Commissioned Country Multi-Select */}
        <Row style= {{marginTop:'-5px'}}>

        <Col md={2} >
              <h4>Commissioned Country</h4>
          </Col>
          <Col md={5}>
          <FormGroup>
          <Select
            isMulti
            options={commissionedCountryOptions.map((item) => {
              return { value: item.Label, label: item.Label, code: item.Code };
            })}
            value={commissionedCountry}
            onChange={handleCommissionedCountrySelect}
            placeholder="Select countries"
          />
                  </FormGroup>
          </Col>
        </Row>
 
        {/* Project Status Multi-Select */}
        <Row style= {{marginTop:'-5px'}}>
        <Col md={2} >
              <h4>Project Status</h4>
          </Col>
          <Col md={5} >
        <FormGroup>
          <Select
            isMulti
            options={projectStatusOptions.map((item) => {
              return { value: item.Label, label: item.Label, code: item.Code };
            })}
            value={projectStatus}
            onChange={handleProjectStatusSelect}
            placeholder="Select statuses"
          />
        </FormGroup>
        </Col>
        </Row>
        {/* Date Pickers */}
        <Row form style= {{marginTop:'-5px'}}>
        <Col md={2} >
              <h4>Project Creation Date</h4>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label style= {{marginRight:'10px'}}>From:</Label>
              <DatePicker
                selected={creationDate.from}
                onChange={(date) => setCreationFromDate(date)}
                dateFormat="MMM-yyyy"
                placeholderText="Select month"
                showMonthYearPicker
                maxDate={today}
                monthClassName={() => 'data-export-react-datepicker__month-text'}
                isClearable
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label style= {{marginRight:'10px'}}>To:</Label>
              <DatePicker
selected={creationDate.to}
                onChange={(date) => setCreationToDate(date)}
                dateFormat="MMM-yyyy"
                placeholderText="Select month"
                showMonthYearPicker
                maxDate={today}
                monthClassName={() => 'data-export-react-datepicker__month-text'}
                isClearable
              />
            </FormGroup>
          </Col>
        </Row>
 
        <Row form style= {{marginTop:'-5px'}}>
        <Col md={2} >
              <h4>Commissioned Date</h4>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label style= {{marginRight:'10px'}}>From:</Label>
              <DatePicker
                selected={commissionedDate.from}
                onChange={(date) => setCommissionedFromDate(date)}
                dateFormat="MMM-yyyy"
                placeholderText="Select month"
                showMonthYearPicker
                maxDate={today}
                monthClassName={() => 'data-export-react-datepicker__month-text'}
                isClearable
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label style= {{marginRight:'10px'}}>To:</Label>
              <DatePicker
selected={commissionedDate.to}
                onChange={(date) => setCommissionedToDate(date)}
                dateFormat="MMM-yyyy"
                placeholderText="Select month"
                showMonthYearPicker
                maxDate={today}
                monthClassName={() => 'data-export-react-datepicker__month-text'}
                isClearable
              />
            </FormGroup>
          </Col>
        </Row>
 
        <Row form style= {{marginTop:'-5px'}}>
        <Col md={2} >
              <h4>Project Live Date</h4>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label style= {{marginRight:'10px'}}>From:</Label>
              <DatePicker
                selected={projectLiveDate.from}
                onChange={(date) => setLiveFromDate(date)}
                dateFormat="MMM-yyyy"
                placeholderText="Select month"
                showMonthYearPicker
                maxDate={today}
                monthClassName={() => 'data-export-react-datepicker__month-text'}
                isClearable
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label style= {{marginRight:'10px'}}>To:</Label>
              <DatePicker
selected={projectLiveDate.to}
                onChange={(date) => setLiveToDate(date)}
                dateFormat="MMM-yyyy"
                placeholderText="Select month"
                showMonthYearPicker
                maxDate={today}
                monthClassName={() => 'data-export-react-datepicker__month-text'}
                isClearable
              />
            </FormGroup>
          </Col>
        </Row>
        {/* Search Field From and Selected Fields with Search Bars */}
        <Row form style= {{marginTop:'-5px'}}>
        <Col md={2} >
              <h4>Export Column</h4>
              <div className="form-actions mt-4" style={{ paddingTop:'300px'}}>
          <Button color="primary" disabled = {filterSelectedFieldOptions.length <= 0 || commissionedCountry?.length <= 0 || commissionedCountry == null || projectStatus == null || projectStatus?.length <= 0 || !((creationDate["from"] !== null && creationDate["to"] !== null) || (commissionedDate["from"] !== null && commissionedDate["to"] !== null) || (projectLiveDate["from"] !== null && projectLiveDate["to"] !== null)) } onClick={downloadData} className="mr-2">
            Download Data
          </Button>
          {/* <Button color="primary"
          disabled = {selectedTemplate !== null}
           onClick={toggleSaveModal}>
            Save Template
          </Button> */}
        </div>
          </Col>
          <Col md={4}>
            <FormGroup>
            <h4 style={{marginLeft: '25%'}}>Available fields for export</h4>
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Search Field From"
                value={searchFieldFromText}
                onChange={(e) => setSearchFieldFromText(e.target.value)}
                style={{  border:'1px solid #b0b0b0', background:'white' }}
              />
              <ul className="list-group" style={{ height: '310px', overflowY: 'auto', border:'1px solid #b0b0b0', background:'white' }} ref={searchFieldsRef}>
                {filterSearchFieldFromOptions.map(item => (
                  <li
                    key={item}
                    className={`list-group-item ${selectedFieldFrom.includes(item) ? 'active' : ''}`}
                    onClick={() => handleSelectFrom(item)}
                    style={{ cursor: 'pointer', backgroundColor: selectedFieldFrom.includes(item) ? '#2D6DF6' : ''}}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </FormGroup>
          </Col>
 
          <Col md={1} className="d-flex flex-column align-items-center justify-content-center">
            <Button onClick={handleAddField} disabled={selectedFieldFrom.length === 0} style= {{'width':'95px'}}>
            Add &gt;&gt;
            </Button>
            <Button onClick={handleRemoveField} disabled={selectedField.length === 0} style= {{marginTop:'5px'}}>
              &lt;&lt; Remove
            </Button>
          </Col>
 
          <Col md={4}>
            <FormGroup>
            <h4 style={{marginLeft: '40%'}}>Export fields</h4>
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Search Download Field"
                value={selectedFieldText}
                onChange={(e) => setSelectedFieldText(e.target.value)}
                style={{  border:'1px solid #b0b0b0', background:'white' }}
              />
              <ul className="list-group" style={{ height: '310px', overflowY: 'auto', border:'1px solid #b0b0b0', background:'white' }} ref={selectedFieldsRef}>
                {filterSelectedFieldOptions.map(item => (
                  <li
                    key={item}
                    className={`list-group-item ${selectedField.includes(item) ? 'active' : ''}`}
                    onClick={() => handleSelectField(item)}
                    style={{ cursor: 'pointer', backgroundColor: selectedField.includes(item) ? '#2D6DF6' : ''}}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </FormGroup>
          </Col>
          <Col md={1} className="d-flex flex-column align-items-center justify-content-center">
          <Label><h5>Reorder</h5></Label>
  <Button onClick={moveUp} disabled={selectedField.length === 0}>
    &#x25B2;
  </Button>
  <Button onClick={moveDown} disabled={selectedField.length === 0} style= {{marginTop:'5px'}}>
    &#x25BC;
  </Button>
</Col>
        </Row>
 {/* Save modal template */}
 <Modal
 isOpen={isSaveModalOpen}
 toggle={toggleSaveModal}>
<ModalHeader
toggle={toggleSaveModal}>
  Save Template
</ModalHeader>
<ModalBody>
  <FormGroup>
    <Label
    for="templateName"
    readOnly
    >
      My Template -
    </Label>
    <Input
    type = "text"
    id="templateName"
    placeholder="Enter Template Name"
    value={templateName}
    onChange= {(e) => setTemplateName(e.target.value)}/>
  </FormGroup>
</ModalBody>
<ModalFooter>
  <Button
  color="primary"
  onClick={handleSaveTemplate}
  disabled={templateName.trim() === ''}>
    Save</Button>
    <Button
    color="secondary"
    onClick={toggleSaveModal}>
      Cancel
    </Button>
</ModalFooter>
 </Modal>
        {/* Buttons */}
        {/* <div className="form-actions mt-4" >
          <Button color="primary" onClick={downloadData} className="mr-2">
            Download Data
          </Button>
          <Button color="primary" onClick={() => alert('Template saved...')}>
            Save Template
          </Button>
        </div> */}
      </Form>
    </div>
}
    </Layout>
    </div>
  );
};
 
export default ExportData;