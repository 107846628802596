import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {

    AvField
} from "availity-reactstrap-validation"
import _ from 'lodash'
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { CustomInput, FormGroup, Label } from 'reactstrap'
import CreatableSelect from 'react-select/creatable';
import Select from "react-select";
import SelectWrapper from '../../components/SelectWrapper/SelectWrapper'


function ReturnControls({ v, indexV, indexCO, component, InfoChange, setModalStateTableDef, setTableDef, profile, errorFieldsObj, indxFC, indxSM, errorStyles,
    methodology, disableFields, CommissioningCountryCheck, project, onBlur, country, callFrom
}) {    
    const userRecord = useSelector(({ user }) => user.userRecord);
    const [opsResourcesVariables] = useState(["surveyProgrammingResource", "surveyProgrammingResourceVendor", "dataProcessingResource", "dataProcessingResourceVendor", "dataProcessingComplexity", "translationsResource", "translationsResourceVendor", "verbatimCodingResource", "verbatimCodingResourceVendor", "chartingResource", "chartingResourceVendor", "dashboardingResource", "dashboardingResourceVendor", "qualityControl", "qualityControlComments", "dataCollectionPlatform", "platformComments"])
    const codeLabels = useSelector(({ codeLabels }) => codeLabels);
    const mandatory = useRef(v.mandatory == true || v.mandatory == 'true')
    const copyIndexCO = useSelector(

        ({ currentProject }) => currentProject.indexcovalue
    
      );
      const erc = {
        control: (styles) => ({ ...styles, borderColor: "red" })
    }
    const _samplingTypeLookUp = [
        'externalSampleSourceSize',
        'internalSampleSourceSize',
        'clientSuppliedSampleSourceSize',
        'otherSampleSourceSize'
    ]

    const _samplingTypeHeaderLookUp = [
        'externalSampleProvider',
        'internalSamplingProvider',
        'clientSuppliedSamplingProvider',
        'otherSamplingProvider'
    ]

    let pacificData = _.head(codeLabels.PacificCountriesList).Label;
    let arr = pacificData.split(',');
    const [pacificMarkets] = useState(arr)


    let defTable = v.defTable ? <FontAwesomeIcon
        title={'click to open definition'}
        className="align-middle mr-2 addButton"
        icon={faInfoCircle}
        onClick={() => {
            setModalStateTableDef(true);
            setTableDef(v.defTable);
        }}
        fixedWidth
        style={{ cursor: "pointer" }}
    /> : null
    if (component === "CO") {
        if (methodology.RFQSchema.properties && methodology.RFQSchema.properties[v.id] && !v.value) {
            if (methodology.RFQSchema.properties[v.id]?.defaultValue) {
                v.value = (v.type === 'creatableMulti' && v.optionsLabel && v.optionsLabel === 'Cities') ?
                    project.CommissioningCountry === profile.CountrySpecs[indxFC].CountryCode ?
                        (v.value && v.value == null) ? v.value : methodology.RFQSchema.properties[v.id].defaultValue : '' :
                    (v.type === 'single' && v.optionsLabel && v.optionsLabel === 'LengthOfInterviewOptions') ?
                        methodology.RFQSchema.properties[v.id].defaultValue.value :
                        (v.type === 'integer' ||
                            v.type === 'text' ||
                            v.type === 'textarea') ? methodology.RFQSchema.properties[v.id].defaultValue :
                            (v.type === 'single') ? (_.isObject(methodology.RFQSchema.properties[v.id].defaultValue)) ?
                                methodology.RFQSchema.properties[v.id].defaultValue.value : methodology.RFQSchema.properties[v.id].defaultValue :
                                (v.type === 'multi') ? methodology.RFQSchema.properties[v.id].defaultValue : v.value
            }
        }
        v.disabled = CommissioningCountryCheck(v.disabled)
        if(!methodology.CostingOptions[indexCO].isSelected) {
            mandatory.current = false
        } else {
            mandatory.current = v.mandatory
        }
    }


    if (component === "SUD" && callFrom==="timecost" && methodology.OpsTimecostSchema?.properties[v.id]?.defaultValue && !v.value) {
        v.value = (v.type === 'integer' ||
            v.type === 'text' ||
            v.type === 'textarea' ||
            v.type === 'multi' ||
            v.type === 'date') ? methodology.OpsTimecostSchema.properties[v.id].defaultValue :
            (v.type === 'single') ? methodology.OpsTimecostSchema.properties[v.id].defaultValue.value : v.value
        if (v.value)
            InfoChange(indexV, v.value)

    }
    if (component === "SUD" && callFrom!=="timecost" && methodology.SetupSchema?.properties[v.id]?.defaultValue && !v.value) {
        v.value = (v.type === 'integer' ||
            v.type === 'text' ||
            v.type === 'textarea' ||
            v.type === 'multi' ||
            v.type === 'date') ? methodology.SetupSchema.properties[v.id].defaultValue :
            (v.type === 'single') ? methodology.SetupSchema.properties[v.id].defaultValue.value : v.value
        if (v.value)
            InfoChange(indexV, v.value)

    }
    if (component === "RFQ") {
        if (v.id == "sampleSizeMain" && methodology.Variables.some(v => _.includes(_samplingTypeHeaderLookUp, v.id))) {
            v.mandatory = false
        }
        if ((v.id === "numberOfWaves" || v.id === "trackingFrequency") && methodology.Variables && methodology.Variables.find(v => v.id == "trackingProject").value == "No") {
            return null
        }

        var val1 = { required: { value: v.mandatory && mandatory.current }, min: { value: 0, errorMessage: "Value should be greater than 0" } }
        var val2 = { required: { value: v.mandatory && mandatory.current }, min: { value: 1, errorMessage: "Value should be greater than 1" }, max: { value: 100, errorMessage: 'Your value must be between 1 & 100 ' } }
        if ((methodology?.RFQSchema?.properties[v.id]?.defaultValue && v.value != "") && !v?.value) {
            v.value =
                (v.type === 'creatableMulti' && v.optionsLabel && v.optionsLabel === 'Cities') ?
                    project.CommissioningCountry === profile.CountrySpecs[indxFC].CountryCode ?
                        methodology.RFQSchema.properties[v.id].defaultValue : '' :

                    (v.type === 'integer' ||
                        v.type === 'text' ||
                        v.type === 'textarea' ||
                        v.type === 'multi' ||
                        v.type === 'date') ? methodology?.RFQSchema?.properties[v.id]?.defaultValue :
                        (v.type === 'single') ? methodology?.RFQSchema?.properties[v.id]?.defaultValue?.value :

                            v.value
        }


        v.disabled = CommissioningCountryCheck(v.disabled)
    }

if ((profile?.CountrySpecs[indxFC]?.MethodologySpecs[indxSM]?.CostingOptions[indexCO]?.Variables.filter(variable => {
    return variable?.label === "Product";
  })?.map(i => i?.value)[0]) && profile?.CountrySpecs[indxFC]?.MethodologySpecs[indxSM]?.CostingOptions[indexCO]?.Variables.filter(variable => {
    return variable?.label === "Product";
  })?.map(i => i?.value)[0].slice(2,5).toLowerCase() === "exp" ) {
    if (v.label !=  "Product" && v.label !=  "Is this cost option a Booster?" && v.label !=  "Is this cost option a Client Chargeable?" && v.label !=  "Commercial Local Support" && v.label !=  "Tracking Project") {
        // v.value = "";
        delete v.value;
    }
    if (v.label ==  "Commercial Local Support" || v.label ==  "Tracking Project") {
        v.value = "No";
    }
    v.mandatory = false;
    v.disabled = true;
}
    if (v.type == "integer") {
        const isNumberOfWaves = v.id === "numberOfWaves";
        return (
            component === "RFQ" && v.id === "incidenceRate" && methodology && methodology.Variables && _.includes(methodology.Variables.map(v => v.id), "externalSampleProvider")
                && methodology.Variables.find(v => v.id === "externalSampleProvider").value !== "Applicable" ? null :
                <FormGroup>
                    {component !== "CO" && <Label>
                        {v.label + (v.mandatory ? '(*)' : '')}
                        {v.tooltext &&
                            <FontAwesomeIcon
                                title={v.tooltext}
                                className="align-middle mr-2 addButton"
                                icon={faInfoCircle}
                                fixedWidth
                                style={{ cursor: "pointer" }}
                            />
                        }
                        {defTable}
                    </Label>}
                    
                    <AvField
                        disabled={(((profile.ProfileStatus === 99) || _.includes(opsResourcesVariables, v.id))
                            && !userRecord.CanEditOpsResources) || v.disabled || disableFields || ((component === "RFQ" || component === "CO") && methodology.NotApplicable)
                            || (v.id === "sampleSizeMain" &&
                                (pacificMarkets.some(pm => pm === project.CommissioningCountry)  && project.CostingTemplateVersion != "v2.0") &&
                                methodology.Variables.some(v => _.includes(_samplingTypeHeaderLookUp, v.id)))
                        || (v.id === "sampleSizeMain" && methodology.CostingType == 3 && methodology.CostingOptions[indexCO].id)

                        }
                        name={component === "CO" ? v.label : "field_" + indexV}
                        placeholder=""
                        type="number"
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key) && component==="SUD") {
                              event.preventDefault();
                            }
                          }}
                          pattern="[0-9]"
                         
                        value={v.value || ''}
                        min={v.label === "Incidence Rate Main" ? 1 : 0}
                        errorMessage=" "
                        style = {{ borderColor : ( (errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
                            && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["CostingOptions"]
                            && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["CostingOptions"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
                            && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["CostingOptions"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label], v.label) && (v.value == undefined || v.value == "")
                ) || (errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
                    && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"]
                    && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
                    && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label], v.label) && (v.value == undefined || v.value == "")
        )) ? 'red' : ' ' }}
                        // validate={(v.mandatory && !methodology.NotApplicable && ((!_.includes(opsResourcesVariables, v.id) || (_.includes(opsResourcesVariables, v.id)
                        //     && userRecord.CanEditOpsResources)) || component === "RFQ" || (component === "CO" && !v.disabled))) ? { required: { value: v.mandatory && mandatory.current } } : {}}
                        // validate={(v.mandatory && !methodology.NotApplicable && (!_.includes(opsResourcesVariables, v.id) || (_.includes(opsResourcesVariables, v.id)
                        //     && userRecord.CanEditOpsResources))) ? { required: { value: v.mandatory && mandatory.current } } : {}}
                      
                        validate={(v.mandatory && !v.disabled && (!_.includes(v.value)
                        )) ? { required: { value: v.mandatory && mandatory.current } } : {}}
                        onChange={(e) => component !== "CO"
                            ? InfoChange(indexV, e.target.value)
                            : InfoChange(indexCO, indexV, e.target.name, e.target.value)
                        }
                        isdisabled={(profile.ProfileStatus === 99) || methodology.NotApplicable || disableFields}
                        {...onBlur && {
                            onBlur: (e) =>
                                isNumberOfWaves
                                    ? v.value > 1
                                        ? onBlur(indexV, e.target.value)
                                        : null
                                    : onBlur(indexV, e.target.value)

                        }}
                    />
                </FormGroup>
        )
    }
    else if (component === "CO" && v.label === "Product")  {  
       console.log("console log of v, indexCO",v, indexCO);
        return(      
                        <div>
                            <input type ="text" size="35" disabled value={v.value}/>
                        </div>
                        )
        
                    }
                    else if ((profile?.CountrySpecs[indxFC]?.MethodologySpecs[indxSM]?.CostingOptions[indexCO]?.Variables.filter(variable => {
                        return variable?.label === "Product";
                      })?.map(i => i?.value)[0]) && component === "CO" && v.label ===  "Is this cost option a Client Chargeable?")  {  
                        console.log("Client Chargeable? console log of v, indexCO",v, indexCO);
                        console.log("profile product", profile?.CountrySpecs[indxFC]?.MethodologySpecs[indxSM]?.CostingOptions[indexCO]?.Variables.filter(variable => {
                         return variable?.label === "Product";
                       })?.map(i => i?.value))
                       let prodVal = profile?.CountrySpecs[indxFC]?.MethodologySpecs[indxSM]?.CostingOptions[indexCO]?.Variables.filter(variable => {
                         return variable?.label === "Product";
                       })?.map(i => i?.value);
                       v.value = prodVal[0].slice(2,5).toLowerCase() === "exp" ? "Yes" : "No" 
                       if (v.value === "Yes") {
                         profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].CostingOptions[indexCO].label = "EXPENSE RECHARGE";
                         profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].CostingOptions[indexCO].value = "EXPENSE RECHARGE";
                         // profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].CostingOptions[indexCO].Name = "EXPENSE RECHARGE";
                       }
                         return(      
                                         // <div>
                                         //     <input type ="text" size="35" disabled value={v.value}/>
                                         // </div>
                                         <FormGroup>
                                             <SelectWrapper
                                     key={"field_" + indexV}
                                     disabled={true}
                                     tag={CustomInput}
                                     name={component === "CO" ? `${v.label}_${indexCO}` : "field_" + indexV}
                                     value={v.value || ""}
                                     type="select"
                                     placeholder='Select'
                                    //  errorMessage="Answer is required"
                                     onChange={(e) => {
                                         component !== "CO"
                                     ? InfoChange(indexV, e.target.value)
                                     : InfoChange(indexCO, indexV, v.label, e.target.value)
                                     }}
                                     
                                 >
                                     {/* <option value="0"></option> */}
                                     {
                                         v.optionsLabel ?
                                             codeLabels[v.optionsLabel].map(
                                                 (opt) => (
                                                     <option key={opt.Code} value={opt.Code}>{opt.Label}</option>
                                                 ))
                                             :
                                             v.options?.map((option) => (
                                                 <option key={option.value} value={option.value}>{option.label}</option>
                                             ))
                                     }
                                 </SelectWrapper>
                                         </FormGroup>
                                         )
                                          
                                     }
        
    else if (v.type == "multi" && v.optionsLabel && v.optionsLabel == 'Cities')
        return (
            <div style={{ textAlign: 'center' }}>
                {component !== "CO" && <Label>
                        {v.label + (v.mandatory ? '(*)' : '')}
                        {v.tooltext &&
                            <FontAwesomeIcon
                                title={v.tooltext}
                                className="align-middle mr-2 addButton"
                                icon={faInfoCircle}
                                fixedWidth
                                style={{ cursor: "pointer" }}
                            />
                        }
                        {defTable}
                    </Label>}
                {
                    codeLabels['CitiesOptions'].filter(x => x.Code == profile.CountrySpecs[indxFC].CountryCode)[0] ?
                        <Select
                            name={v.label}
                            value={v.value}
                            isDisabled={(profile.ProfileStatus == 99) || v.disabled || methodology.NotApplicable || disableFields}
                            onChange={(e) => InfoChange(indexCO, indexV, v.label, e)}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            styles={errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
                                && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["CostingOptions"]
                                && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["CostingOptions"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
                                && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["CostingOptions"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label], v.label) && v.value == undefined
                                ? erc : ""}
                            menuPortalTarget={document.querySelector('card-body')}
                            menuPosition={'fixed'}
                            options={
                                codeLabels['CitiesOptions'].filter(x => x.Code == profile.CountrySpecs[indxFC].CountryCode)[0]['Cities'].map(
                                    (opt) => {
                                        return {
                                            value: opt.id,
                                            label: opt.Label,
                                        }
                                    })
                            }
                            isMulti
                        /> : 'No City Found'
                }
            </div>
        )
    else if (v.type === "creatableMulti" && v.optionsLabel && v.optionsLabel == 'Cities')
        return (
            <>
                  {component !== "CO" && <Label>
                        {v.label + (v.mandatory ? '(*)' : '')}
                        {v.tooltext &&
                            <FontAwesomeIcon
                                title={v.tooltext}
                                className="align-middle mr-2 addButton"
                                icon={faInfoCircle}
                                fixedWidth
                                style={{ cursor: "pointer" }}
                            />
                        }
                        {defTable}
                    </Label>}
                {/* {
                    codeLabels['CitiesOptions'].filter(x => x.Code == profile.CountrySpecs[indxFC].CountryCode)[0] ?
                        <CreatableSelect
                            className="react-select-container"
                            classNamePrefix="react-select"
                            isMulti
                            isDisabled={(profile.ProfileStatus == 99) || v.disabled || methodology.NotApplicable || disableFields}
                            value={v.value}
                            errorMessage= "field is required"
                            onChange={(e) => component !== "CO"
                            ? InfoChange(indexV, e)
                            : InfoChange(indexCO, indexV, v.label, e)
                        }                    
                        validate={(v.mandatory && !v.disabled && (!_.includes(v.value)
                        )) ? { required: { value: v.mandatory && mandatory.current } } : {}}     
                           options={
                                codeLabels['CitiesOptions'].filter(x => x.Code == profile.CountrySpecs[indxFC].CountryCode)[0]['Cities'].map(
                                    (opt) => {
                                        return {
                                            value: opt.id,
                                            label: opt.Label,
                                        }
                                    })
                            }
                        /> : 'No City Found'
                } */}
                <CreatableSelect
                    name={v.label}
                    value={v.value}
                    isMulti
                    isDisabled={(profile.ProfileStatus == 99) || v.disabled || methodology.NotApplicable || disableFields}
                    onChange={(e) => {
                        component !== "CO"
                        ? InfoChange(indexV, e)
                        : InfoChange(indexCO, indexV, v.label, e)
                }}
               
                    className="react-select-container"
                    classNamePrefix="react-select"
                    //     errorMessage="Answer is required"
                    styles={v.value?.length !== 0 ? (errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
                        && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["CostingOptions"]
                        && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["CostingOptions"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
                        && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["CostingOptions"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label], v.label) && (v.value == undefined || v.value.length == 0) 
                        ? erc : "") : erc}
                    validate={(v.mandatory && !v.disabled && (!_.includes(v.value)
                    )) ? { required: { value: v.mandatory && mandatory.current } } : {}}
                    // required={v.mandatory}
                    options={
                        codeLabels['CitiesOptions'].filter(x => x.Code == profile.CountrySpecs[indxFC].CountryCode)[0]['Cities'].map(
                            (opt) => {
                                return {
                                    value: opt.id,
                                    label: opt.Label,
                                }
                            })
                    }
                />
                {!v.disabled && errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
                    && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["CostingOptions"]
                    && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["CostingOptions"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
                    && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["CostingOptions"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label], v.label) && v.value == undefined
                    ? null : null}
            </>

        )
        // else if (component === "CO" && v.label === "Product") {
            
        // }
    else if (v.type == "single"){
    let optionsTemp = v.optionsLabel ? codeLabels[v.optionsLabel] : v.options;
 
    if ( optionsTemp?.find(x => (x?.Label && x?.Label?.toString().toLowerCase() == 'other specify') || (x?.label && x?.label?.toString().toLowerCase() == 'other specify')))
        return (
            <>
              {component !== "CO" && <Label>
                        {v.label + (v.mandatory ? '(*)' : '')}
                        {v.tooltext &&
                            <FontAwesomeIcon
                                title={v.tooltext}
                                className="align-middle mr-2 addButton"
                                icon={faInfoCircle}
                                fixedWidth
                                style={{ cursor: "pointer" }}
                            />
                        }
                        {defTable}
                    </Label>}
                <CreatableSelect
                    name={v.label}
                    value={v.value}
                    isDisabled={(profile.ProfileStatus == 99) || v.disabled || methodology.NotApplicable || disableFields}
                    onChange={(e) => {
                        component !== "CO"
                        ? InfoChange(indexV, e)
                        : InfoChange(indexCO, indexV, v.label, e)
                }}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    // errorMessage="Answer is required"
                    styles={errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
                        && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["CostingOptions"]
                        && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["CostingOptions"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
                        && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["CostingOptions"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label], v.label) && v.value == undefined
                        ? erc : ""}

                    validate={(v.mandatory && !v.disabled && (!_.includes(v.value)
                    )) ? { required: { value: v.mandatory && mandatory.current } } : {}}
                    // required={v.mandatory}
                    options={
                        v.optionsLabel ?
                            codeLabels[v.optionsLabel].map(
                                (opt) => {
                                    return {
                                        value: opt.Code,
                                        label: opt.Label,
                                    }
                                })
                            :
                            v.options.map((option) => {
                                return {
                                    value: option.value,
                                    label: option.label,
                                }
                            })

                    }
                />

                {!v.disabled && errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
                    && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["CostingOptions"]
                    && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["CostingOptions"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
                    && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["CostingOptions"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label], v.label) && v.value == undefined
                    ? null : null}
                {/* {v.mandatory && !v.value &&<p className="invalid-feedback d-flex">field is required</p>} */}
            </>
        )
    else
     return (
            <FormGroup>
                {component !== "CO" && <Label>

                    {v.label + (v.mandatory ? '(*)' : '')}
                    {v.tooltext &&
                        <FontAwesomeIcon
                            title={v.tooltext}
                            className="align-middle mr-2 addButton"
                            icon={faInfoCircle}
                            fixedWidth
                            style={{ cursor: "pointer" }}
                        />
                    }
                    {defTable}
                </Label>}

                <SelectWrapper
                    key={"field_" + indexV}
                    disabled={(profile.ProfileStatus == 99) || (_.includes(opsResourcesVariables, v.id)
                        && !userRecord.CanEditOpsResources) || methodology.NotApplicable || disableFields || v.disabled ||
                         (v.label === "Commercial Local Support"||v.label === "Operations Local Support") 
                           && country === project.CommissioningCountry
                    }
                    tag={CustomInput}
                    name={component === "CO" ? `${v.label}_${indexCO}` : "field_" + indexV}
                    value={v.value || ""}
                    type="select"
                    placeholder='Select'
                    // errorMessage="Answer is required"
                    styles={component == "CO" ? (errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
                        && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["CostingOptions"]
                        && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["CostingOptions"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
                        && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["CostingOptions"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label], v.label)
                         && v.value == undefined ? erc : "") :
                    component == "RFQ" ? (errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
                        && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["RFQ"]
                        && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["RFQ"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
                        && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["RFQ"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label], v.label) 
                        && v.value == undefined ? erc : "") :
                        (errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
                            && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"]
                            && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
                            && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label], v.label)
                             && v.value == undefined ? erc : "") }
                    

                    validate={(v.mandatory && !v.disabled && !methodology.NotApplicable && (!_.includes(opsResourcesVariables, v.id) || (_.includes(opsResourcesVariables, v.id)
                        && userRecord.CanEditOpsResources))) ? { required: { value: v.mandatory && mandatory.current } } : {}}
                   // clearable
                    onChange={(e) => {
                        component !== "CO"
                    ? InfoChange(indexV, e.target.value)
                    : InfoChange(indexCO, indexV, v.label, e.target.value)
                    }}
                    
                >
                    {/* <option value="0"></option> */}
                    {
                        v.optionsLabel ?
                            codeLabels[v.optionsLabel].map(
                                (opt) => (
                                    <option key={opt.Code} value={opt.Code}>{opt.Label}</option>
                                ))
                            :
                            v.options?.map((option) => (
                                <option key={option.value} value={option.value}>{option.label}</option>
                            ))
                    }
                </SelectWrapper>
            </FormGroup>
        )}
    else if (v.type == "multi" && (codeLabels[v.optionsLabel] && codeLabels[v.optionsLabel].find(x => x?.Label?.toString().toLowerCase() == 'others'))) {
        return (
            <FormGroup>
                {component !== "CO" && <Label>
                    {v.label + (v.mandatory ? '(*)' : '')}
                    {v.tooltext &&
                        <FontAwesomeIcon
                            title={v.tooltext}
                            className="align-middle mr-2 addButton"
                            icon={faInfoCircle}
                            fixedWidth
                            style={{ cursor: "pointer" }}
                        />
                    }
                    {defTable}
                </Label>}
                <CreatableSelect
                    isDisabled={(profile.ProfileStatus == 99) || (_.includes(opsResourcesVariables, v.id)
                        && !userRecord.CanEditOpsResources) || methodology.NotApplicable || disableFields}
                    name={v.label}
                    value={v.value}
                    // errorMessage="Answer is required"
                    onChange={(e) => InfoChange(indexV, e)}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    styles={errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
                        && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"]
                        && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
                        && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label], v.label) && v.value == undefined
                        ? erc : ""}
                    // validate={(v.mandatory && !methodology.NotApplicable && (!_.includes(opsResourcesVariables, v.id) || (_.includes(opsResourcesVariables, v.id)
                    validate={(v.mandatory && !v.disabled && !methodology.NotApplicable && (!_.includes(opsResourcesVariables, v.id) || (_.includes(opsResourcesVariables, v.id)
                        && userRecord.CanEditOpsResources))) ? { required: { value: v.mandatory && mandatory.current } } : {}}
                    options={
                        v.optionsLabel ?
                            codeLabels[v.optionsLabel].map(
                                (opt) => {
                                    return {
                                        value: opt.Code,
                                        label: opt.Label,
                                    }
                                })
                            :
                            v.options?.map((option) => {
                                return {
                                    value: option.value,
                                    label: option.label,
                                }
                            })

                    }
                    isMulti
                />
                {errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
                    && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"]
                    && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
                    && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label], v.label) ? null : null}
            </FormGroup>
        )
    }
    else if (v.type == "multi")
        return (
            <FormGroup>
                {component !== "CO" && <Label>
                    {v.label + (v.mandatory ? '(*)' : '')}
                    {v.tooltext &&
                        <FontAwesomeIcon
                            title={v.tooltext}
                            className="align-middle mr-2 addButton"
                            icon={faInfoCircle}
                            fixedWidth
                            style={{ cursor: "pointer" }}
                        />
                    }
                    {defTable}
                </Label>}

                <Select
                    isDisabled={(profile.ProfileStatus == 99) || (_.includes(opsResourcesVariables, v.id)
                        && !userRecord.CanEditOpsResources) || methodology.NotApplicable || disableFields ||v.disabled}
                    name={v.label}
                    value={v.value}
                    // errorMessage="Answer is required"
                    
                        onChange={(e) => component !== "CO"
                        ? InfoChange(indexV, e)
                        : InfoChange(indexCO, indexV, e.target.name, e)
                    }
                                        className="react-select-container"
                    classNamePrefix="react-select"
                    styles={errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
                        && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"]
                        && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
                        && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label], v.label) && v.value == undefined
                        ? erc : ""}
                    validate={(v.mandatory && !v.disabled && !methodology.NotApplicable && (!_.includes(opsResourcesVariables, v.id) || (_.includes(opsResourcesVariables, v.id)
                        && userRecord.CanEditOpsResources))) ? { required: { value: v.mandatory && mandatory.current } } : {}}
                    options={
                        v.optionsLabel ?
                            codeLabels[v.optionsLabel].map(
                                (opt) => {
                                    return {
                                        value: opt.Code,
                                        label: opt.Label,
                                    }
                                })
                            :
                            v.options?.map((option) => {
                                return {
                                    value: option.value,
                                    label: option.label,
                                }
                            })

                    }
                    isMulti
                />
                {component === "SUD" && errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
                    && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"]
                    && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
                    && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label], v.label) ? null : null}
            </FormGroup>
        )
    else if (v.type == "text")
        return (
            <FormGroup>
                {component !== "CO" && <Label>
                    {v.label + (v.mandatory ? '(*)' : '')}
                    {v.tooltext &&
                        <FontAwesomeIcon
                            title={v.tooltext}
                            className="align-middle mr-2 addButton"
                            icon={faInfoCircle}
                            fixedWidth
                            style={{ cursor: "pointer" }}
                        />
                    }
                    {defTable}
                </Label>}
                <AvField
                    disabled={
                        (profile.ProfileStatus === 99) ||
                        (_.includes(opsResourcesVariables, v.id) && !userRecord.CanEditOpsResources) ||
                        methodology.NotApplicable ||
                        disableFields ||
                        v.disabled
                    }
                    name={component === "CO" ? v.label : "field_" + indexV}
                    placeholder=""
                    type="text"
                    errorMessage=" "
                    style = {{ borderColor :  ((errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
                        && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["CostingOptions"]
                        && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["CostingOptions"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
                        && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["CostingOptions"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label], v.label) && v.value == undefined
            ) || (errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
                && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"]
                && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
                && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label], v.label) && v.value == undefined
    )) ? 'red' : ' ' }}
                    validate={(v.mandatory && !v.disabled && !methodology.NotApplicable && ((!_.includes(opsResourcesVariables, v.id) || (_.includes(opsResourcesVariables, v.id)
                        && userRecord.CanEditOpsResources)) || component === "RFQ" || component === "CO")) ? { required: { value: v.mandatory && mandatory.current } } : {}}
                    onChange={(e) => component !== "CO"
                        ? InfoChange(indexV, e.target.value)
                        : InfoChange(indexCO, indexV, e.target.name, e.target.value)
                    }
                    value={v.value || ''}
                />
            </FormGroup>
        )

    else if (v.type === "textarea"){
        const isIncidenceRateNoteInVisible = v.id === "incidenceRateNote" && methodology && methodology.Variables && _.includes(methodology.Variables.map(v => v.id), "externalSampleProvider")
                    && methodology.Variables.find(v => v.id === "externalSampleProvider").value !== "Applicable"
        return (isIncidenceRateNoteInVisible && component !== "CO"  ? null :
            <FormGroup>
                {component !== "CO" && <Label>
                    {v.label + (v.mandatory ? '(*)' : '')}
                    {v.tooltext &&
                        <FontAwesomeIcon
                            title={v.tooltext}
                            className="align-middle mr-2 addButton"
                            icon={faInfoCircle}
                            fixedWidth
                            style={{ cursor: "pointer" }}
                        />
                    }
                    {defTable}
                </Label>}
                <AvField
                    disabled={(profile.ProfileStatus == 99) ||
                        (_.includes(opsResourcesVariables, v.id) && !userRecord.CanEditOpsResources) ||
                        methodology.NotApplicable ||
                        disableFields ||
                        v.disabled || (isIncidenceRateNoteInVisible && component === "CO")
                    }
                    name={component === "CO" ? v.label + "_" + indexCO : "field_" + indexV}
                    type="textarea"
                    errorMessage=" "
                    style = {{ borderColor :  ((errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
                        && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["CostingOptions"]
                        && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["CostingOptions"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
                        && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["CostingOptions"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label], v.label) && (v.value == undefined ||  v.value == "")
            ) || (errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
                && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"]
                && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
                && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label], v.label) && (v.value == undefined ||  v.value == "")
    ) )? 'red' : ' ' }}
                    validate={(v.mandatory && !v.disabled && !methodology.NotApplicable && ((!_.includes(opsResourcesVariables, v.id) || (_.includes(opsResourcesVariables, v.id)
                        && userRecord.CanEditOpsResources)) || component === "RFQ"|| (component==="CO" && v.disabled))) ? { required: { value: v.mandatory && mandatory.current } } : {}}
                    onChange={(e) => component !== "CO"
                        ? InfoChange(indexV, e.target.value)
                        : InfoChange(indexCO, indexV, e.target.name, e.target.value)
                    }
                    value={v.value || ''}
                />
            </FormGroup>
        )
    }  
    else if (v.type == "date")
        return (
            <FormGroup>
                {component !== "CO" && <Label>
                    {v.label + (v.mandatory ? '(*)' : '')}
                    {v.tooltext &&
                        <FontAwesomeIcon
                            title={v.tooltext}
                            className="align-middle mr-2 addButton"
                            icon={faInfoCircle}
                            fixedWidth
                            style={{ cursor: "pointer" }}
                        />
                    }
                    {defTable}
                </Label>}
                <AvField
                    disabled={(profile.ProfileStatus == 99) || (_.includes(opsResourcesVariables, v.id)
                        && !userRecord.CanEditOpsResources) || methodology.NotApplicable || disableFields}
                    name={component === "CO" ? v.label : "field_" + indexV}
                    placeholder=""
                    type="date"
                    onChange={(e) => component !== "CO" ? InfoChange(indexV, e.target.value) : InfoChange(indexCO, indexV, e.target.name, e.target.value)}
                    value={v.value || ''}
                    errorMessage=" "
                    // style = {{borderColor:"red"}}
                    style = {{ borderColor :  (errorFieldsObj && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]
                        && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"]
                        && errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label]
                        && _.includes(errorFieldsObj[profile.CountrySpecs[indxFC].CountryName]["SD"][profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].Label], v.label) && v.value == undefined
            ) ? 'red' : ' ' }}
                    
                    
                    validate={(v.mandatory && !v.disabled && !methodology.NotApplicable && (!_.includes(opsResourcesVariables, v.id) || (_.includes(opsResourcesVariables, v.id)
                        && userRecord.CanEditOpsResources))) ? { required: { value: v.mandatory && mandatory.current } } : {}}
                    
                />
            </FormGroup>
        )

    else if (v.type == "header")
        return (
            <>
                <h5 id={"header_" + indexV} className={'header'}>{v.label}</h5>
                {v.instructions && <h6 id={"header_i_" + indexV}>{v.instructions}</h6>}
            </>
        )

    else if (v.type == "check")
        return (
            <CustomInput
                type="switch"
                name={v.label}
                id={v.label}
                defaultChecked={v.value ? v.value : true}
                className="h5"
                disabled={(profile.ProfileStatus == 99) || methodology.NotApplicable || disableFields}
                onChange={(e) => {

                    InfoChange(indexCO, indexV, e.target.name, e.target.checked);
                }}
            />
        )
    else {
        return <input type="text"></input>
    }


}

export default React.memo(ReturnControls)