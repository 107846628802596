import axios from "../../axios-interceptor";
import { toastr } from "react-redux-toastr";

import * as projectsActions from "./projectsActions";
import * as codeLabelActions from "./codeLabelActions";
import * as appActions from "./appActions";
import * as reportStatusActions from "./reportStatusActions";
import { encryptOrDecryptString} from  '../../utils/azureConstant';

export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const TOGGLE_LOGIN = "TOGGLE_LOGIN";
export const UPDATE_AUTH = "UPDATE_AUTH";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const INIT_DATA_STATUS = "INIT_DATA_STATUS";
export const SET_TOKEN_AUTH_PAGE = "SET_TOKEN_AUTH_PAGE";

export const authStart = () => {
  return {
    type: AUTH_START,
  };
};

export const authSuccess = (authToken, userRecord) => {
  return (dispatch) => {
    dispatch({
      type: AUTH_SUCCESS,
      authToken: authToken,
      userRecord: userRecord,
    });
    dispatch({ type: appActions.SET_APP, apploaded: true });
    // dispatch(codeLabelActions.getCodeLabels());
    // dispatch(projectsActions.getProjects());
  };
};

export const authFail = (error, status, statusCode) => {
  return (dispatch) => {
    dispatch(logout(status));
    dispatch({ type: appActions.SET_APP, apploaded: true });
    dispatch({ type: AUTH_FAIL, error: error, statusCode: statusCode });
  };
};

export const logout = (status) => {

  // localstorage clear condition must be below this funcation
  if(!status ) {
    // browser tab close and logout logic 
    if(localStorage.getItem("auth-token")) { 
    // login with sso 
      sessionStorage.setItem("xyzData", 
      JSON.stringify({"logVal":  encryptOrDecryptString("true", 1)})
      )
    }
  }

  localStorage.clear()

  return (dispatch) => {
    dispatch({
      type: AUTH_LOGOUT,
    });
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem("auth-token");
    const userRecord = JSON.parse(localStorage.getItem("userRecord"));
    if (!token || token === "undefined") {
      dispatch(authFail());
    } else {
      dispatch(authSuccess(token, userRecord));
    }
  };
};

export const register = (data) => {
  return (dispatch) => {
    dispatch(appActions.pageLoadStart());
    axios
      // .post("/auth/register", data)
      .post("/auth/sso-register", data)
      .then((res) => {
        dispatch(appActions.pageLoadEnd(res.status));
        toastr.success("Account Requested", res.data.message);
      })
      .catch((err) => {
        dispatch(appActions.pageLoadEnd());
        toastr.error("Request Failed", err.data?.message);
      });
  };
};

export const submitQuestions = (data) => {
  return (dispatch) => {
    dispatch(appActions.pageLoadStart());
    axios
      // .post("/auth/register", data)
      .post("/auth/sso-submitQuestions", data)
      .then((res) => {
        dispatch(appActions.pageLoadEnd(res.status));
        toastr.success("Account Requested");
      })
      .catch((err) => {
        dispatch(appActions.pageLoadEnd());
        toastr.error("Request Failed", err.data?.message);
      });
  };
};

export const forgotPassword = (data) => {
  return (dispatch) => {
    ////console.log(data);
    dispatch(appActions.recordLoadStart());
    axios
      .post("/auth/forgot-password", data)
      .then((res) => {
        toastr.success("Password Successfully Reset", res.data.message);
        dispatch(appActions.recordLoadEnd());
      })
      .catch((err) => {
        dispatch(appActions.recordLoadEnd());
        toastr.error("Request Failed", err.data?.message);
      });
  };
};

export const resetPassword = (data) => {
  return (dispatch) => {
    dispatch(appActions.recordLoadStart());
    axios
      .post("/auth/reset-password", data)
      .then((res) => {
        dispatch(appActions.recordLoadEnd());
        toastr.success("Password Successfully Reset", res.data.message);
      })
      .catch((err) => {
        ////console.log(err);
        dispatch(appActions.recordLoadEnd());
        toastr.error("Request Failed", err.data?.message);
      });
  };
};

export const auth = (loginData) => {
  return async (dispatch) => {
    try {
      dispatch(authStart());
      const payload = {
        Email: loginData.email?.toLowerCase(),
        OfficeLocation: loginData.officeLocation,
      };

      let res = await axios.post("/auth/sso-login", payload)
      localStorage.setItem("auth-token", res.data.token);
      localStorage.setItem("userRecord", JSON.stringify(res.data.user));
      dispatch(authSuccess(res.data.token, res.data.user));
      dispatch({
        type: UPDATE_AUTH,
        field: "error",
        value: res.data.message,
        statusCode: res.status
      });
    } catch (err) {
      if (err?.response) {
        dispatch(authFail(err.response.data?.error, "loginError",  err.status ));
        // toastr.error("Login Failed", err.response.data?.error, { timeOut: 10000 });
      } else if (err?.data) {

        dispatch(authFail(err.data.message, "loginError",  err.status));
        // toastr.error("Login Failed", err.data?.error, { timeOut: 10000 });
      }
      throw err
    }
  };
};

export const setInitDataStatus = (status) => {
  return (dispatch) => {
    dispatch({ type: INIT_DATA_STATUS, initDataError: status });
  };
};
  
export const setTokenAuth = (auth) => {
  return (dispatch) => {
    dispatch(appActions.pageLoadEnd());
    dispatch({ type: SET_TOKEN_AUTH_PAGE, isValidToken:auth});
  };
};

