import React, { useEffect, useState } from "react";
import axios from "../../axios-interceptor";
import Wrapper from "../../components/Wrapper";
import Sidebar from "../../components/Sidebar";
import Main from "../../components/Main";
import ProjectNavbar from "../../components/ProjectNavbar";
import TimeCostModal from "../../components/TimeCostModal";
import * as currentCostingActions from "../../redux/actions/currentCostingActions";
import Select from "react-select";
import {
  Row,
  Button,
  Col,
  NavLink,
  NavItem,
  Nav,
  TabContent,
  TabPane,
  Table,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardSubtitle,
  Input,
} from "reactstrap";
import { Container } from "react-bootstrap";
import {
  faPencilAlt,
  faInfoCircle,
  faCopy,
  faPlusCircle,
  faSave
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import _ from "lodash";
import * as sideBarActions from "../../redux/actions/sidebarActions";
import { Prompt } from "react-router-dom";
import TimeCostTable from "./TimeCostTable";
import CostingMarketTable from "./CostingMarketTable";
import {
  setCommercialHours,
  setOperationsHours,
  setOperationsCost,
  saveHourspayload,
  saveTotalCost,
  saveTimecost
} from "../../redux/actions/timecostHoursActions";
import InfoModal from "../../components/TimeCostModals/InfoModal";
import CopyOptionsModal from "./CopyOptionsModal";
import { toastr } from "react-redux-toastr";
import TestPageOpResourceNew from "../proposalNew/TestOpResourceNew";
import { setCurrentProject, setFormErrorStatus, setOpportunityValue } from "../../redux/actions/currentProjectActions";
import { valueChangedInRFQ } from "../../redux/actions/appActions";
import { useLocation } from "react-router-dom";
import zIndex from "@material-ui/core/styles/zIndex";
import Axios from "../../axios-interceptor";
import { calcAll } from "../../utils/calculations";
import * as mapperFunctions from "../../utils/rfqMapper";
import { colors } from "@material-ui/core";

const SHOPPERS_METHODOLOGIS = ["SM000024", "SM000026", "SM000029"]

const OperationsTimecost = () => {
  var isvalid = true;
  const dispatch = useDispatch();
  const timecostView = useSelector(({ timeCost }) => timeCost.selectedRole);
  const pageloaded = useSelector(({ app }) => app.pageloaded);
  const profile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  const project = useSelector(
    ({ currentProject }) => currentProject.newProject
  );
  let opsCostData = useSelector(
    ({ timecostHours }) => timecostHours.operations
  );
  const rateCards = useSelector(({ rateCards }) => rateCards);
  let operationsCostData = useSelector(({ timecostHours }) => timecostHours.operationCost);
  const levelInput=timecostView==="commercial"?profile.CSInput:profile.OpsInput
  const currencies = useSelector(({ currentCosting }) =>
  currentCosting.currentCostingProfile.ProfileSetting &&
    currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
    ? currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
    : []
  );
  const [infoModal, setInfoModal] = useState(false);
  const [activeTabFC, setActiveTabFC] = useState(0);
  const [timecostViewModal, settimecostViewModal] = useState(false);
  const [activeTabSM, setActiveTabSM] = useState(0);
  const [activeTabCO, setActiveTabCO] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [OPSband, setOPSBand] = useState([]);
  const [activitiesForOPS, setActivitiesForOPS] = useState([]);
  const [alert, setAlert] = useState(false);
  const [activityMapping, setActivityMapping] = useState([]);
  const [currentActiveTab, setCurrentActiveTab] = useState("1");
  const [localSupportOPS, setLocalSupportOPS] = useState(true);
  const [localSupportModalOPS, setLocalSupportModalOPS] = useState(true);
  const [role, setRole] = useState("OPS");
  const [OPSTeamDataOptions, setOPSTeamDataOptions] = useState([]);
  const [OPSTeamDataOptionsTemp, setOPSTeamDataOptionsTemp] = useState([]);
  const [defaultRates, setDefaultRates] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isClear, setIsClear] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCostOptionID, setSelectedCostOptionID] = useState();
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedCountryForClear, setSelectedCountryForClear] = useState();
  const [selectedCountryData, setSelectedCountryData] = useState({});
  const [selectedMethodologyData1, setSelectedMethodologyData1] = useState({});
  const [modalMethodology, setModalMethodology] = useState(false);
  const [opsModal, setOpsModal] = useState(false);
  const [options, setoptions] = useState();
  const [makeScroll, setMakeScroll] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [costoptionsDetails,setCostoptionsDetails]=useState({});
  const [deletedTimeCostData,setDeletedTimeCostData]=useState({});
  const [deletedCostData,setDeletedCostData]=useState({})
  const [OPSCurrencyUnit,setOPSCurrencyUnit]=useState("")
  const [OPSCurrencyConversion,setOPSCurrencyConversion]=useState(1)
  const [OPScurrencyDropdown,setOPScurrencyDropdown]=useState({})
  const [OPSCurrencyCode,setOPSCurrencyCode]=useState("")
  const [errorObj, setErrorObj] = useState({});
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  let pacificData = _.head(codeLabels.PacificCountriesList).Label;
  const [pacificMarkets] = useState(pacificData?.split(","));
  const userRecord = useSelector(({ user }) => user.userRecord);
  const location = useLocation();  
  const [isModalOpen, handleOpen] = useState(false);
  const [validationHeader, setValidationHeader] = useState(
    "Please complete answering all mandatory fields before proceeding."
  );
  const [opsTimecostReason, setopsTimecostReason] = useState({});
  // const [productRow, setProductRow] = useState([]);
  const [isNewProject, setIsNewProject] = useState(false);
  const [showProductValidation, setShowProductValidation] = useState(true);

  useEffect(()=>{  
    let isNew = false;
    profile.CountrySpecs?.map(cs => {
      cs.MethodologySpecs?.map(ms => {
        if(ms.OpResourcesVariables?.length !== ms.OpResourcesVariablesTimecost?.length) {
          isNew = true;
        }
      })
    })
    if(isNew) {
      setIsNewProject(true);
    }
    if(profile.OpsTimecostReason==null) {
      setopsTimecostReason({})
    }
    else {
      setopsTimecostReason(profile.OpsTimecostReason)
    }
  },[profile])

  const teamData = async(countryArray,callBack) => {
    axios.get(`/timecost/country`).then((res) => {
      let opsTeamData = [];
      let opsTeamCountry = res.data.result;
      let opsTempData = [];

      opsTeamCountry.map((opsTeam) => {
          let optionObj = {
            value: opsTeam.Code,
            label: opsTeam.Label,
          };
          opsTeamData.push(optionObj);
      });
      if (countryArray && countryArray?.length > 0) {
        let cArray = [];
        countryArray?.map((c) => {
          if (profile.CountrySpecs[activeTabFC].MethodologySpecs[
            activeTabSM
          ]?.CostingOptions[activeTabCO]?.id == Object.keys(c)[0]
          ) {
            cArray.push(Object.values(c)[0])
          }
        });
        if (cArray && cArray?.length > 0) {
          opsTempData = opsTeamData.filter((data) =>
            !cArray.includes(data.label)
          );
        }
      }
      setOPSTeamDataOptions(opsTeamData);
      setOPSTeamDataOptionsTemp(opsTempData);
      callBack();
    });
  };
  const [opsResourcesVariables] = useState([
    "surveyProgrammingResource",
    "surveyProgrammingResourceVendor",
    "dataProcessingResource",
    "dataProcessingResourceVendor",
    // "dataProcessingComplexity",
    "translationsResource",
    "translationsResourceVendor",
    "verbatimCodingResource",
    "verbatimCodingResourceVendor",
    "chartingResource",
    "chartingResourceVendor",
    "dashboardingResource",
    "dashboardingResourceVendor",
    "qualityControl",
    "qualityControlComments",
    "dataCollectionPlatform",
    "platformComments",
  ]);

  const tableHeaders = [
    "Product Line",
    "WBS Number",
    "Methodology",
    "Function",
    "Internal Cost (USD)",
    `Internal Cost (${OPSCurrencyUnit})`,
    "Reason for zero time cost"
  ];

  const commercialFields = {
    "Associate Director": "CostIntCommAssociateDirector",
    "Data Science": "CostIntCommDataScience",
    Director: "CostIntCommDirector",
    "Executive Director": "CostIntCommExecDirector",
    Executive: "CostIntCommExecutive",
    Manager: "CostIntCommManager",
    "Senior Executive": "CostIntCommSeniorExecutive",
    "Senior Manager": "CostIntCommSeniorManager",
  };

  function objectKeys(obj) {
    var keys = [];
    if (!obj) return keys;
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
            keys.push(key);
        }
    }
    return keys;
}

  function formatSchemaByVariables(schemaJSON, variables) {
    if (schemaJSON && schemaJSON.properties != null) {
        var keys = objectKeys(schemaJSON.properties);
        var keysDependencies = schemaJSON.dependencies ? Object.keys(schemaJSON.dependencies) : [];

        for (var key of keys) {
            if (variables && variables.filter(x => x.id == key).length > 0) {
                var variable = variables.filter(x => x.id == key)[0]
                schemaJSON.properties[key].disabled = variable.disabled;
                schemaJSON.properties[key].selected = variable.selected;
                schemaJSON.properties[key].Default = variable.Default;
                // List of keys that can be the starting field for Operations resource in setup and Delivery Part of RFQ Form
                let opResorceHeaders = ["surveyProgrammingResource", "dataProcessingResource", "translationsResource", "verbatimCodingResource", "chartingResource", "dashboardingResource", "qualityControl"]
                // To

                if (_.includes(opResorceHeaders, key)) // To check if a methodology contains any of these fields in setup and delivery section or not
                    if (variable.isNewSection) { // to check if selected key is the first element or not
                        schemaJSON.properties[key].isNewSection = variable.isNewSection //Adding section title and isnew section in schema
                        schemaJSON.properties[key].sectionTitle = variable.sectionTitle
                    }
                    else { // Deleting is new section and section title if key is not the first element of section
                        if (schemaJSON.properties[key].isNewSection) {
                            delete schemaJSON.properties[key].isNewSection
                            delete schemaJSON.properties[key].sectionTitle
                        }
                    }
                // schemaJSON.properties[key].isNewSection = variables.isNewSection;

            }

            if (keysDependencies.indexOf(key) > -1) {
                var dependency = schemaJSON.dependencies[key].oneOf.filter(x => Object.keys(x.properties).length > 1)[0]
                var nestedKeysDependencies = Object.keys(dependency.properties);
                for (let keyNest of nestedKeysDependencies) {
                    if (variables?.filter(x => x.id == keyNest).length > 0) {
                        var variable = variables.filter(x => x.id == keyNest)[0]
                        dependency.properties[keyNest].disabled = variable.disabled;
                        dependency.properties[keyNest].selected = variable.selected;
                        dependency.properties[keyNest].Default = variable.Default;
                    }
                }
            }
        }
    }
    return schemaJSON;
}

  const validateForms = (_profile) => {
    let errorFieldsObj = {}; // object to store missing required fields - RFQ Form
    let costingProfile = _profile ? _profile : profile;
    let isValid = true;
    let countrySpecs = [...costingProfile.CountrySpecs];
    var allCostingOptionsUsed = [];
    let unavialable = [];
    let methodologyObj = {};
    let methodologyCountryObj = {};
    let isFieldsValid = true;
    let fieldingCountries = [];
    let isTrackingProjectNonPacific = {};

    if (Array.isArray(profile.FieldingCountries))
      fieldingCountries = profile.FieldingCountries.map((fc) => fc.value);
    else fieldingCountries = profile.FieldingCountries.split(",");

    countrySpecs.map((cs) => {
      if (cs.MethodologySpecs) {
        cs.MethodologySpecs?.filter((ms) => !ms.NotApplicable)?.map((ms) => {
          methodologyObj[ms.id] = ms.Label;
          methodologyCountryObj[ms.id] = cs.CountryName;
          ms.CostingOptions?.map((co) => {
            co.CostingOptionSchema?.map((cps) => {
              if (!_.includes(allCostingOptionsUsed, cps.id) && cps.id) {
                allCostingOptionsUsed.push(cps.id);
              }
            });
          });
          if (
            ms?.RFQSchema?.required &&
            _.includes(fieldingCountries, cs.CountryCode)
          ) {
            if (!ms.RFQData) {
              isValid = false;
              isFieldsValid = false;
              unavialable = ms.RFQSchema?.required;
            } else {
              let allRequiredFields = ms.RFQSchema?.required;
              if (
                (_.includes(pacificMarkets, project?.CommissioningCountry) && project.CostingTemplateVersion != "v2.0") &&
                _.includes(SHOPPERS_METHODOLOGIS, ms.Code) &&
                allRequiredFields?.length === 0
              ) {
                allRequiredFields = ms.Variables.filter(i => i.mandatory == true && !i.disabled).map(i => i.id)
              }
              if (
                ms.RFQData["externalSampleProvider"] &&
                ms.RFQData["externalSampleProvider"] != "Applicable"
              )
                allRequiredFields = allRequiredFields.filter(
                  (rf) => rf != "incidenceRate"
                );
              let existingProperties = Object.keys(
                ms.RFQSchema?.properties
              ).filter((f) => !ms.RFQSchema.properties[f].Default);

              let finalFields = allRequiredFields?.filter((arf) =>
                _.includes(existingProperties, arf)
              );
              unavialable = finalFields?.filter(
                (req) => req && (!ms.RFQData[req] || ms.RFQData[req] == "0")
              );

              if (
                (_.includes(pacificMarkets, project?.CommissioningCountry) && project.CostingTemplateVersion != "v2.0") &&
                ms.RFQData["trackingProject"] === "Yes" &&
                (!ms.RFQData["numberOfWaves"] ||
                  ms.RFQData["numberOfWaves"] < 2)
              ) {
                unavialable.push("numberOfWaves");
              } else if (
                ms.CostingOptions.length &&
                ms.CostingOptions.find(
                  (co) => co.CostingOptionData?.trackingProject === "Yes"
                )
              ) {
                isTrackingProjectNonPacific[ms.id] = true;
              }

              let haveInCostingOptions = ms.CostingOptions?.map(
                (co) => co?.CostingOptionData
              )?.filter(
                (co) =>
                  finalFields
                    ?.map((ff) => co && co[ff] && co[ff] != 0)
                    .filter((f) => f).length
              );
              if (unavialable.length && !haveInCostingOptions.length) {
                isValid = false;
                isFieldsValid = false;
              }
            }
          }
          if (!isFieldsValid && _.includes(fieldingCountries, cs.CountryCode)) {
            let fields = unavialable.map((obj) =>
              obj === "numberOfWaves"
                ? ms.SetupSchema?.properties[obj]?.title
                : ms.RFQSchema.properties[obj]?.title
            );
            if (!errorFieldsObj[cs.CountryName])
              errorFieldsObj[cs.CountryName] = {};

            if (!errorFieldsObj[cs.CountryName].RFQ)
              errorFieldsObj[cs.CountryName].RFQ = {};

            if (!errorFieldsObj[cs.CountryName].RFQ[ms.Label])
              errorFieldsObj[cs.CountryName].RFQ[ms.Label] = {};

            errorFieldsObj[cs.CountryName].RFQ[ms.Label] = fields;
          }
          isFieldsValid = true;
        });

        if (!_.includes(pacificMarkets, project?.CommissioningCountry)||
        (_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0")
        ) {
          cs.MethodologySpecs?.filter((ms) => !ms.NotApplicable).forEach(
            (mc) => {
              if (mc) {
                mc.CostingOptions.forEach((me) => {
                  const mandate = me.Variables.filter(
                    (mt) => mt.mandatory == true && !mt.disabled
                  ).map((i) => i.label);
                  const dataValues = me.Variables.filter(
                    (mt) => mt.mandatory == true && !mt.disabled
                  );
                  const labelData = dataValues.filter((dt) => dt.label);

                  if (!errorFieldsObj[cs.CountryName])
                    errorFieldsObj[cs.CountryName] = {};

                  if (!errorFieldsObj[cs.CountryName].CostingOptions)
                    errorFieldsObj[cs.CountryName].CostingOptions = {};

                  if (!errorFieldsObj[cs.CountryName].CostingOptions[mc.Label])
                    errorFieldsObj[cs.CountryName].CostingOptions[mc.Label] =
                      {};

                  if(me.Selected === 'true' || me.Selected){
                    if (
                      Object.keys(me.CostingOptionData).length === 0 &&
                      mandate.length > 0
                    ) {
                      isValid = false;
                      errorFieldsObj[cs.CountryName].CostingOptions[mc.Label] =
                        mandate;
                    } else {
                      const filterData = dataValues.filter((x) => x.value && x?.value?.length !== 0 );
                      const unfilterData = dataValues
                        .filter((xy) => !xy.value || xy?.value?.length === 0)
                        .map((t) => t.label);
  
                      if (filterData.length !== dataValues.length) {
                        isValid = false;
                        errorFieldsObj[cs.CountryName].CostingOptions[mc.Label] =
                          unfilterData;
                      }
                    }
                  }
                });
              }
            }
          );
        }
      }
    });

    if (costingProfile?.WaveSpecs?.length) {
      let validMethIds = [];
      costingProfile.CountrySpecs?.map((cs) => {
        cs.MethodologySpecs?.filter((ms) => !ms.NotApplicable)
          ?.map((ms) => ms.id)
          ?.map((ms) => {
            if (!_.includes(validMethIds, ms)) validMethIds.push(ms);
          });
      });
      let wavespecs = [
        ...costingProfile.WaveSpecs?.filter((ws) =>
          _.includes(validMethIds, ws.MethodologySpecId)
        ),
      ];
      if (wavespecs.length) {
        let OpsTimecostSchema = {};
        costingProfile.CountrySpecs?.map((cs) => {
          cs.MethodologySpecs?.map(ms => {
            wavespecs.map((ws) => {  
              console.log(ms.id, "ssss", ws.MethodologySpecId);
              if(ms.id === ws.MethodologySpecId){
                console.log(ms.OpsTimecostSchema, ms.OpResourcesVariablesTimecost, "12345")
                const opsVariable = formatSchemaByVariables(ms.OpsTimecostSchema, ms.OpResourcesVariablesTimecost);
                OpsTimecostSchema[ws.MethodologySpecId] = opsVariable;
              }
            })
          })
        })
        console.log(OpsTimecostSchema, "test")
        wavespecs.map((ws) => {  
          // ws = _.head(wavespecs)
          let finalPropsToValidate = [];
          ws.OpsTimecostSchema = OpsTimecostSchema[ws.MethodologySpecId];
          ws.OpsTimecostSchema?.required?.map((prop) => {
            let currentProp = ws.OpsTimecostSchema.properties[prop];
            console.log(currentProp, "validation");
            // if(currentProp.title === "Data Processing Complexity") {
            //   return;
            // }

            if (
              currentProp &&
              (!currentProp.dependentOn ||
              !currentProp.disabled) &&
              !currentProp.selected
            ) {
              if (
                prop === "numberofExcelFile" &&
                ws.OpsResourcesData &&
                ws.OpsResourcesData["chartingComplexity"] !==
                  prop
              )
                return;
              if (
                prop === "numberOfModules" &&
                ws.OpsResourcesData &&
                ws.OpsResourcesData["numberOfModules"] >= 0
              )
              return;
              if (
                prop === "numberOfOpenEnded" &&                 
                ws.OpsResourcesData &&
                ws.OpsResourcesData["numberOfSemiOpenEnded"] >= 0
              )
              return;
              if (
                prop === "numberOfSemiOpenEnded" &&               
                ws.OpsResourcesData &&
                ws.OpsResourcesData["numberOfSemiOpenEnded"] >= 0
              )
                return;
                if (

                                  prop === "L2breakouts" &&
                  
                                  ws.OpsResourcesData &&
                  
                                  ws.OpsResourcesData["L2breakouts"] >= 0
                  
                                )
                  
                                  return;
                  
                                if (
                  
                                  prop === "L3breakouts" &&
                  
                                  ws.OpsResourcesData &&
                  
                                  ws.OpsResourcesData["L3breakouts"] >= 0
                  
                                )
                  
                                  return;
                if (
                  prop === "numberOfOpenEndedToCode" &&
                  ws.OpsResourcesData &&
                  ws.OpsResourcesData["codingRequired"] === "No"
                )
                  return;
              if (
                (!_.includes(opsResourcesVariables, prop) &&
                  !_.includes(allCostingOptionsUsed, prop)) ||
                (_.includes(opsResourcesVariables, prop) &&
                  userRecord.CanEditOpsResources)
              )
                if (
                  prop === "surveyProgrammingResource" &&
                  ws.OpsResourcesData &&
                  ws.OpsResourcesData["surveyProgrammingRequired"] === "No"
                )
                  //todo: not sure why it is been done in this way in CountryMethodologyTabsFinal
                  //copied the same, but need to be fixed in a proper way
                  return;

              if (
                _.includes(opsResourcesVariables, prop) &&
                !userRecord.CanEditOpsResources
              )
                return;
                if(!finalPropsToValidate.includes(prop)){
                  finalPropsToValidate.push(prop);
                }
              
            } else {
              if (
                !(currentProp?.dependentOn?.fields?.length > 1) &&
                currentProp &&
                !currentProp.disabled &&
                !currentProp.selected
              ) {
                 
                currentProp?.dependentOn?.fields?.map((_dependentField) => {
                  let depProp = _dependentField;
                  if (depProp) {
                    let parentProp = {};
                    Object.keys(ws.OpsTimecostSchema.properties).map((pp) => {
                      if (
                        ws.OpsTimecostSchema.properties[pp].title ==
                        depProp.fieldName
                      ) {
                        parentProp = pp;
                      }
                    });
                    if (
                      prop === "approxPercentageChange" &&
                      wavespecs.length < 2
                    )
                      return;
                    

                    if (
                      parentProp &&
                      ws.OpsResourcesData &&
                      ws.OpsResourcesData[parentProp] &&
                      ws.OpsResourcesData[parentProp] != "No" &&
                      !_.includes(finalPropsToValidate, prop)
                    ) {
                      if (
                        (!_.includes(opsResourcesVariables, prop) &&
                          !_.includes(allCostingOptionsUsed, prop)) ||
                        (_.includes(opsResourcesVariables, prop) &&
                          userRecord.CanEditOpsResources)
                      ) {

                        if(!finalPropsToValidate.includes(prop)){
                          finalPropsToValidate.push(prop);
                        }
                      }
                    }
                  }
                });
              }
            }
          });

          let finalErrorFields = [];
          
          const numberOfWaves = ws.OpsResourcesData["numberOfWaves"]
            ? ws.OpsResourcesData["numberOfWaves"]
            : 0;

          if (
            isTrackingProjectNonPacific[ws.MethodologySpecId] &&
            ws.OpsTimecostSchema?.properties?.["numberOfWaves"] &&
            numberOfWaves < 2
          ) {
            isValid = false;
            isFieldsValid = false;
            if(finalErrorFields.includes(ws.OpsTimecostSchema?.properties?.["numberOfWaves"]?.title)){
              finalErrorFields.push(
                ws.OpsTimecostSchema?.properties?.["numberOfWaves"]?.title
              );
            }
            
          }
          const numberOfLicenses = ws.OpsResourcesData["NumberOfLicenses"]
          ?ws.OpsResourcesData["NumberOfLicenses"]:0;

          if(  
            ws.OpsTimecostSchema?.properties?.["NumberOfLicenses"] && 
            numberOfLicenses>1000
            ){
            isValid=false;
            isFieldsValid=false;
            finalErrorFields.push(ws.OpsTimecostSchema?.properties?.["NumberOfLicenses"]?.title)
          
          }
          
          const numberOfModules = ws.OpsResourcesData["numberOfModules"]
          ?ws.OpsResourcesData["numberOfModules"]:0;

          if(  
            ws.OpsTimecostSchema?.properties?.["numberOfModules"]  && numberOfModules>999
            ){
            isValid=false;
            isFieldsValid=false;
            finalErrorFields.push(ws.OpsTimecostSchema?.properties?.["numberOfModules"]?.title) && 
            finalErrorFields.push(" - Value should be between " +ws.OpsTimecostSchema?.properties?.["numberOfModules"].minimum +"-" +ws.OpsTimecostSchema?.properties?.["numberOfModules"].maximum)
          
          }
          const numberOfDashboard = ws.OpsResourcesData["numberOfDashboard"]
          ?ws.OpsResourcesData["numberOfDashboard"]:0;

          if(  
            ws.OpsTimecostSchema?.properties?.["numberOfDashboard"] && 
            numberOfDashboard>120
            ){
            isValid=false;
            isFieldsValid=false;
            finalErrorFields.push(ws.OpsTimecostSchema?.properties?.["numberOfDashboard"]?.title) &&  finalErrorFields.push(ws.OpsTimecostSchema?.properties?.["numberOfDashboard"]?.message)   
          }
          const L2Breakouts = ws.OpsResourcesData["L2breakouts"]

            ? ws.OpsResourcesData["L2breakouts"]

            : 0;




          if (

            ws.OpsTimecostSchema?.properties?.["L2breakouts"] &&

            L2Breakouts > 100

          ) {

            isValid = false;

            isFieldsValid = false;

            finalErrorFields.push(

              ws.OpsTimecostSchema?.properties?.["L2breakouts"]?.title

            );

          }

          const L3Breakouts = ws.OpsResourcesData["L3breakouts"]

            ? ws.OpsResourcesData["L3breakouts"]

            : 0;




          if (

            ws.OpsTimecostSchema?.properties?.["L3breakouts"] &&

            L3Breakouts > 100

          ) {

            isValid = false;

            isFieldsValid = false;

            finalErrorFields.push(

              ws.OpsTimecostSchema?.properties?.["L3breakouts"]?.title

            );

          }
          finalPropsToValidate.map((ftv) => {
            // const combinedObj = mergedObjects(ws.SetupSchema, ws.OpsTimecostSchema);
            console.log(ws.OpsResourcesData,ws.OpsTimecostSchema, "newdata", finalPropsToValidate)
            if (
              !ws.OpsResourcesData ||
              (ws.OpsResourcesData &&
                (!ws.OpsResourcesData[ftv] || ws.OpsResourcesData[ftv] == "0"))
            ) {
              let excludeError = [
               
              ];
              
              if (!excludeError.includes(ftv)) {
                console.log(ws.OpsTimecostSchema.properties[ftv].title, "aada")
                isValid = false;              
                isFieldsValid = false;              
                finalErrorFields.push(ws.OpsTimecostSchema.properties[ftv].title);
              }
            }
          });
          if (!isFieldsValid) {
            if (!errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]])
              errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]] = {};
			  
            if (!errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD)
              errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD =
                {};

            if (
              !errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                methodologyObj[ws.MethodologySpecId]
              ]
            )
              errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                methodologyObj[ws.MethodologySpecId]
              ] = {};

              if (_.includes(pacificMarkets, project.CommissioningCountry) && project.CostingTemplateVersion != "v2.0") {
                if (
                  !errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                    methodologyObj[ws.MethodologySpecId]
                  ].NO
                )
                  errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                    methodologyObj[ws.MethodologySpecId]
                  ].NO = {};
  
                if (
                  !errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                    methodologyObj[ws.MethodologySpecId]
                  ].NO[ws.WaveNumber]
                )
                  errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                    methodologyObj[ws.MethodologySpecId]
                  ].NO[ws.WaveNumber] = {};
  
                errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                  methodologyObj[ws.MethodologySpecId]
                ].NO[ws.WaveNumber] = finalErrorFields;
              } else {
                errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                  methodologyObj[ws.MethodologySpecId]
                ] = finalErrorFields;
              }
          }
          isFieldsValid = true;
        });
      }
    }
    
    if (!isValid) {
      if (
        document.getElementById("OpsResourcesFinalForm")
      ) {
        // document.getElementById("RFQFinalForm")?.requestSubmit();
        document.getElementById("OpsResourcesFinalForm")?.requestSubmit();
        // document.getElementById("CostiongOptionsForm")?.requestSubmit();
      }
      setErrorObj(errorFieldsObj);
      handleOpen(true);
    }
    return isValid;
  };

  function saveDraft(notAPI) {
    dispatch(setOpportunityValue(""));
    // validateCO();
    // validateRFQ();
    validateSUD();
    if (isvalid) {
      isvalid = true;
      let _profile = { ...profile }; //_.last(project.CostingProfiles) //currentCosting//_.last(project.CostingProfiles)

      // _profile = { ..._profile, ...currentCosting }
      _profile.AdditionalSheetId = profile.AdditionalSheetId
        ? profile.AdditionalSheetId
        : null;
      _profile.CostingType = profile.CostingType
        ? profile.CostingType
        : null;
      _profile.CostingsSheetId = profile.CostingsSheetId
        ? profile.CostingsSheetId
        : null;
        
      let _project = _.cloneDeep(project)
      if(_project.CostingProfiles){
        delete _project.CostingProfiles
      }
          _profile.Project = _project;
        
      if (!_.includes(pacificMarkets, project.CommissioningCountry) ||
       (_.includes(pacificMarkets, project.CommissioningCountry) && project.CostingTemplateVersion == "v2.0")) {
        _profile.CountrySpecs = _profile.CountrySpecs?.map((cs) => {
          let requiredcountry = {
            ...profile.CountrySpecs.find((CS) => CS.id == cs.id),
          };
          if (requiredcountry) {
            cs.MethodologySpecs = cs.MethodologySpecs.map((ms) => {
              let requiredMeth = {
                ...requiredcountry.MethodologySpecs.find(
                  (MS) => MS.id == ms.id
                ),
              };
              // delete requiredMeth.CostingOptions
              // delete requiredMeth.NotApplicable
              // delete requiredMeth.Responsibilities
              // delete requiredMeth.ResponsibilityData
              // delete requiredMeth.ResponsibilitySchema
              // delete requiredMeth.SetupData
              // delete requiredMeth.SetupSchema
              // delete requiredMeth.RFQData
              // delete requiredMeth.RFQSchema
              return { ...ms, ...requiredMeth };
            });
          }
          delete requiredcountry.MethodologySpecs;
          return { ...cs, ...requiredcountry };
        });
      }
      if (!_.includes(pacificMarkets, project.CommissioningCountry)  ||
       (_.includes(pacificMarkets, project.CommissioningCountry) && project.CostingTemplateVersion == "v2.0")) {
        _profile.WaveSpecs = _.cloneDeep(profile.WaveSpecs);
      }
      if (_profile.WaveSpecs)
        _profile.WaveSpecs = calcAll(
          project,
          profile,
          profile.CountrySpecs,
          _profile.WaveSpecs,
          rateCards,
          pacificMarkets
        )?.waveSpecs;

      var WaveSpecsAll = _profile?.WaveSpecs || [];
      // WaveSpecsAll = calcAll(project, currentCosting, currentCosting.CountrySpecs, WaveSpecsAll, rateCards, pacificMarkets)?.waveSpecs

      let temporaryWavesForValidation = [];
      let countIteration=0
      if(typeof profile.ProductTypeData !=="String")
      {
        var ProductTypeData=profile.ProductTypeData
      }
      else
      {
        while(typeof profile.ProductTypeData === "string"){
          profile.ProductTypeData = JSON.parse(profile.ProductTypeData)
        }
        // if(typeof JSON.parse( currentCosting.ProductTypeData) === "string"){
          
        //   var ProductTypeData=JSON.parse(JSON.parse( currentCosting.ProductTypeData))
        // }
        // else{
          var ProductTypeData=JSON.parse( profile.ProductTypeData)
        // }
      }
      _profile?.CountrySpecs?.forEach((cs,index) => {
        cs?.MethodologySpecs?.forEach((ms) => {
          ms.RFQSchema = mapperFunctions.formatSchemaByVariables(
            _.cloneDeep(ms?.RFQSchema),
            _.cloneDeep(ms?.Variables)
          );
          ms.RFQData = _.cloneDeep(ms?.Variables)
            ? mapperFunctions.getDataJSON(_.cloneDeep(ms?.Variables))
            : mapperFunctions.getDataJSON(_.cloneDeep([]));
          //let WaveSpecs=ms.WaveSpecs||[];
          var costingOptionWaves;
          if (ms?.CostingOptions.length)
            costingOptionWaves = ms?.CostingOptions[0]?.Variables.filter(
              (x) => x?.label == "Number of Waves"
            );
          else {
            costingOptionWaves = ms?.Variables
              ? ms.Variables.filter((x) => x?.id == "numberOfWaves")
              : [];
          }
          var WaveSpecs = WaveSpecsAll.filter(
            (x) => x.MethodologySpecId == ms.id
          );
          var waveSize =
            costingOptionWaves.length > 0
              ? costingOptionWaves[0].value
              : (ms?.OpResourcesVariables.filter(
                  (x) => x.label == "Number of Waves"
                ).length > 0
                  ? ms?.OpResourcesVariables.filter(
                      (x) => x.label == "Number of Waves"
                    )[0].value
                  : 1) || 1;
          let waves =
            WaveSpecs.length > 0 && WaveSpecs.length == waveSize
              ? WaveSpecs.length
              : waveSize;
          if (!waves) {
            waves = 1;
          }
          if (!_.includes(pacificMarkets, project.CommissioningCountry)  ||
           (_.includes(pacificMarkets, project.CommissioningCountry) && project.CostingTemplateVersion == "v2.0")) {
            var tempWaveSpecs = [];
            for (let i = 0; i < waves; i++) {
              var id =
                WaveSpecs.length > 0
                  ? WaveSpecs[i]
                    ? WaveSpecs[i].id
                    : null
                  : null;
              tempWaveSpecs.push({
                id: id,
                WaveNumber: i + 1,
                ResponsibilitySchema: _.cloneDeep(ms.Responsibilities),
                OpsResourcesSchema: mapperFunctions.formatSchemaByVariables(
                  _.cloneDeep(ms.SetupSchema),
                  _.cloneDeep(ms.OpResourcesVariables)
                ),
                OpsTimecostSchema: mapperFunctions.formatSchemaByVariables(
                  _.cloneDeep(ms.OpsTimecostSchema),
                  _.cloneDeep(ms.OpResourcesVariablesTimecost)
                ),
                OpsResourcesData: mapperFunctions.getDataJSON(
                  _.cloneDeep(ms.OpResourcesVariablesTimecost)
                ),
                MethodologySpecId: ms.id,
                CostingProfileId: _profile?.id,
              });

              ms["WaveSpecs"] = tempWaveSpecs;
              temporaryWavesForValidation = [
                ...temporaryWavesForValidation,
                ...tempWaveSpecs,
              ];
            }

            ///For Wave Specs Deletion
            if (WaveSpecs.length > waves) {
              for (let i = waves; i < WaveSpecs.length; i++) {
                var id = WaveSpecs[i].id;
                tempWaveSpecs.push({
                  id: id,
                  toRemove: true,
                  WaveNumber: i + 1,
                  ResponsibilitySchema: _.cloneDeep(ms.Responsibilities),
                  OpsResourcesSchema: mapperFunctions.formatSchemaByVariables(
                    _.cloneDeep(ms.SetupSchema),
                    _.cloneDeep(ms.OpResourcesVariables)
                  ),
                  OpsTimecostSchema: mapperFunctions.formatSchemaByVariables(
                    _.cloneDeep(ms.OpsTimecostSchema),
                    _.cloneDeep(ms.OpResourcesVariablesTimecost)
                  ),
                  OpsResourcesData: mapperFunctions.getDataJSON(
                    _.cloneDeep(ms.OpResourcesVariablesTimecost)
                  ),
                  MethodologySpecId: ms.id,
                  CostingProfileId: _profile?.id,
                });
                ms["WaveSpecs"] = tempWaveSpecs;
                temporaryWavesForValidation = [
                  ...temporaryWavesForValidation,
                  ...tempWaveSpecs,
                ];
              }
            }
          } else {
            //todo: wave specs deletion and updation
            //when _.groupBy(profile.WaveSpecs).length>tempWaveSpecs delete respective number of wavespecs
            //for that methodology accordingly, likewise _.groupBy(profile.WaveSpecs).length<tempWaveSpecs
            //create wavespecs, if you fill wavespecs and without IDs it'll create in backend
            var pacificTempWaves = [];
            let waveMethCount = profile.WaveSpecs.filter(
              (x) => x.MethodologySpecId === ms.id
            );
            // to add
            if (waveMethCount.length < waves) {
              for (let i = 0; i < waves; i++) {
                var id =
                  waveMethCount.length > 0
                    ? waveMethCount[i]
                      ? waveMethCount[i].id
                      : null
                    : null;
                pacificTempWaves.push({
                  id: id,
                  WaveNumber: i + 1,
                  ResponsibilitySchema: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].ResponsibilitySchema)
                    : _.cloneDeep(ms.Responsibilities),
                  CommercialHoursData: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].CommercialHoursData)
                    : _.cloneDeep(ms?.CommercialHoursData),
                  OpsResourcesSchema: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].OpsResourcesSchema)
                    : mapperFunctions.formatSchemaByVariables(
                        _.cloneDeep(ms.SetupSchema)
                      ),
                  OpsTimecostSchema: waveMethCount[i]
                  ? _.cloneDeep(waveMethCount[i].OpsTimecostSchema)
                  : mapperFunctions.formatSchemaByVariables(
                      _.cloneDeep(ms.OpsTimecostSchema)
                    ),
                  OpsResourcesData: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].OpsResourcesData)
                    : {},
                  MethodologySpecId: ms.id,
                  CostingProfileId: profile?.id,
                });
                pacificTempWaves = calcAll(
                  project,
                  profile,
                  profile.CountrySpecs,
                  pacificTempWaves,
                  rateCards,
                  pacificMarkets
                )?.waveSpecs;
                ms["WaveSpecs"] = pacificTempWaves;

                temporaryWavesForValidation = [
                  ...temporaryWavesForValidation,
                  ...pacificTempWaves,
                ];
              }
            }
            if (pacificTempWaves.length === 0) {
              profile.WaveSpecs = calcAll(
                project,
                profile,
                profile.CountrySpecs,
                profile.WaveSpecs,
                rateCards,
                pacificMarkets
              )?.waveSpecs;
              let data = profile.WaveSpecs.filter(
                (x) => x.MethodologySpecId === ms.id
              ).map((x) => {
                return { ...x };
              });
              ms["WaveSpecs"] = data;
              temporaryWavesForValidation = [
                ...temporaryWavesForValidation,
                ...data,
              ];
            }

            // to Remove
            if (waveMethCount.length > waves) {
              for (let i = waves; i < waveMethCount.length; i++) {
                var id = waveMethCount[i].id;
                pacificTempWaves.push({
                  id: id,
                  toRemove: true,
                  WaveNumber: i + 1,
                  ResponsibilitySchema: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].ResponsibilitySchema)
                    : _.cloneDeep(ms.Responsibilities),
                  CommercialHoursData: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].CommercialHoursData)
                    : _.cloneDeep(ms?.CommercialHoursData),
                  OpsResourcesSchema: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].OpsResourcesSchema)
                    : mapperFunctions.formatSchemaByVariables(
                        _.cloneDeep(ms.SetupSchema)
                      ),

                  OpsTimecostSchema: waveMethCount[i]
                  ? _.cloneDeep(waveMethCount[i].OpsTimecostSchema)
                  : mapperFunctions.formatSchemaByVariables(
                      _.cloneDeep(ms.OpsTimecostSchema)
                    ),
                  OpsResourcesData: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].OpsResourcesData)
                    : {},
                  MethodologySpecId: ms.id,
                  CostingProfileId: _profile?.id,
                });
                pacificTempWaves = calcAll(
                  project,
                  profile,
                  profile.CountrySpecs,
                  pacificTempWaves,
                  rateCards,
                  pacificMarkets
                )?.waveSpecs;
                ms["WaveSpecs"] = pacificTempWaves;
                temporaryWavesForValidation = [
                  ...temporaryWavesForValidation,
                  ...pacificTempWaves,
                ];
              }
            }
          }
          if (!_.includes(pacificMarkets, project.CommissioningCountry) ||
           (_.includes(pacificMarkets, project.CommissioningCountry) && project.CostingTemplateVersion == "v2.0"))
            ms.CostingOptions.forEach((co, indexCO) => {
              co["id"] = co.id;
              co["Name"] = co.label;
              co["Selected"] = co.isSelected?co.isSelected:false;
              co["CostingOptionSchema"] = _.cloneDeep(co.Variables);
              co["CostingOptionData"] = mapperFunctions.getDataJSON(
                _.cloneDeep(co.Variables)
              );

              if(!co.OpportunityLineItemID)
              {
                let oppID=co?.CostingOptionSchema[0]?.options[0]?.OpportunityLineItemID
                console.log("oppID",oppID)
                co["OpportunityLineItemID"]=oppID
              }
              if(!co?.CostingOptionData?.producttype)
              {
                co.CostingOptionData.producttype=co?.CostingOptionSchema[0]?.options[0]?.Productname
              } 
              if(!co?.CostingOptionData?.Booster)
              {
                co.CostingOptionData.Booster="No"
              } 
              if(!("CommericalLocalsu" in
                co["CostingOptionData"]) && (profile.createdAt >= "2023-08-05T10:43:12.000Z") ){
                  if(index === 0){
                    co["CostingOptionData"]={...co["CostingOptionData"],
                      "CommericalLocalsu" : "Yes",
                      "operationalpm" : "Yes"}
                    }
                    else{
                      co["CostingOptionData"]={
                        ...co["CostingOptionData"],
                        "CommericalLocalsu" : "No",
                        "operationalpm" : "Yes"}
                      }
                }
                  
                  
              co["MethodologySpecId"] = ms.id;
              
                if(!co.OpportunityLineItemID)
                {
                  co["OpportunityLineItemID"] = co.OpportunityLineItemID;
                }
                // ProductTypeData[countIteration].OpportunityLineItemID
                // countIteration++;

            });
        });
        // countIteration=0
      });
      //commercial costs recounting if there any and helps in case if any costing option got deleted
      Object.keys(commercialFields).map((cf) => {
        _profile[commercialFields[cf]] = 0;
      });
      _profile.CountrySpecs = _profile.CountrySpecs.map((cs) => {
        Object.keys(commercialFields).map((cf) => {
          cs[commercialFields[cf]] = 0;
        });
        cs.MethodologySpecs = cs.MethodologySpecs?.map((ms) => {
          ms.CostingOptions?.filter((co) => co.Selected)?.map((co) => {
            Object.keys(commercialFields).map((cf) => {
              cs[commercialFields[cf]] =
                (cs[commercialFields[cf]] ? cs[commercialFields[cf]] : 0) +
                (co[commercialFields[cf]] ? co[commercialFields[cf]] : 0);
            });
          });
          return { ...ms };
        });
        Object.keys(commercialFields).map((cf) => {
          _profile[commercialFields[cf]] =
            (_profile[commercialFields[cf]]
              ? _profile[commercialFields[cf]]
              : 0) + cs[commercialFields[cf]];
        });
        return { ...cs };
      });

      if (_profile.WaveSpecs.length > 0) {
        let WaveSpecs = _profile.WaveSpecs.filter(ws => ws.NotApplicable != true)
        const noWaves = Object.values(
          _.groupBy(WaveSpecs, "MethodologySpecId")
        ).map((O) => O.length);
        _profile.NumberOfWaves = _.max(noWaves);
      }
      if (project.ProjectName !== profile.Project.ProjectName) {
        profile.Project.ProjectName = project.ProjectName;
      }
      dispatch(valueChangedInRFQ(false));
      _profile.isRFQ=true

      if (!notAPI) {
        // dispatch(currentCostingActions.saveCostingProfileDraft(_profile));
        dispatch(currentCostingActions.saveCostingProfileTimeCost(_profile));
        Axios.put(`/projects/${project.ProjectId}/UpdateProjectParams`, {
          ClientChargeableApplicable: project.ClientChargeableApplicable ? 1 : 0,
        })
      } else {
        return { ..._profile, WaveSpecs: temporaryWavesForValidation };
      }
      // setprofileCopy(profile);
      // setShouldBlockNavigation(false);
    } else {
      toastr.error("You have Entered Invalid Values");
      isvalid = true;
    }
  }

  const getInternalCost = () => {
    let isValid = true;

    let productTypeArray=_.cloneDeep(profile.ProductTypeData)

    productTypeArray=productTypeArray.filter((item)=>!item.isRed)

    let combinedData={}

    productTypeArray.map((item)=>{
        const {OpportunityLineItemID,Productname,Label,WBSNumber}=item
        if(combinedData[OpportunityLineItemID]) {
          combinedData[OpportunityLineItemID].Label+=` ${Label}`;
        } else {
          combinedData[OpportunityLineItemID]={OpportunityLineItemID,WBSNumber,Productname,Label,csCost:0,opsCost:0};
        }
    })

    let productArray=Object.values(combinedData)
    profile.CountrySpecs.map((country)=>{
      country.MethodologySpecs.map((method)=>{
        method.CostingOptions.map((co)=>{
          let index=productArray.findIndex(obj=>obj.OpportunityLineItemID==co.OpportunityLineItemID)
          if(index!=-1 && co.CoSelectedForSummary)
          {
            if(co?.CostsData?.CostTotalInternalCommercial)
            {
              productArray[index].csCost+=co.CostsData.CostTotalInternalCommercial
            }
            if(co?.CostsData?.CostTotalInternalOperations)
            {
              productArray[index].opsCost+=co.CostsData.CostTotalInternalOperations
            }
          }
          
        })
      })
    })
    let valid=false
    if (profile?.CostingsSheetId) {
      if (
        profile?.CountrySpecs.every((cs) =>
          cs?.MethodologySpecs.every((ms) =>
            ms.CostingOptions.every((co) => co.CoSelectedForSummary != null)
          )
        )
      ) {
        
        productArray.map((item)=>{
          if(item.opsCost==0 && !opsTimecostReason[item.OpportunityLineItemID]) {
            isValid=false
            valid=true
          }
        })
        if(valid) {
          // setProductRow(productArray)
          return productArray;
        }
      }
    }
    return productArray;
  }  

  // const handleReasonSave=()=>{
  //   let tempProfile=_.cloneDeep(profile)
  //   tempProfile["OpsTimecostReason"]=opsTimecostReason
  //   dispatch(currentCostingActions.updateProfile(tempProfile));
  //   dispatch(currentCostingActions.saveCostingProfileDraft(tempProfile,()=>{
  //     toastr.success("Timecost Reason saved succesfully")
  //   },true));
  //   handleOpen(false)
  // }

  const gettimecost = async () => {
    setIsLoading(true);
    const res = await axios.get(`/timecost/defaults`);
    const response = await axios.get(`/timecost/${profile.id}`);
    const rates = res?.data?.rates?.filter((rate) => rate.function_id === 2);
    setDefaultRates(rates);
    const activityDataForCS = res.data.activities.filter(
      (activity) => activity.function_id === 2
    );
    const bandsDataForCS = res.data.bands.filter(
      (activity) => activity.function_id === 1
    );
    const activityDataForOPS = res.data.activities.filter(
      (activity) => activity.function_id === 2
    );
    const bandsDataForOPS = res.data.bands.filter(
      (activity) => activity.function_id === 2
    );

    let temparr = [];
    let csarr = res.data.Mapping.filter(
      (activity) => activity.funtction_id === 1
    );
    temparr.push(csarr);
    let opsarr = res.data.Mapping.filter(
      (activity) => activity.funtction_id === 2
    );
    temparr.push(opsarr);
    setActivityMapping(temparr);
    setOPSBand(bandsDataForOPS);
    setActivitiesForOPS(activityDataForOPS);
    let commercial = {};
    let operations = {};
    let operationCost = {};
    let countryArray = [];

    response.data.datas.map((co) => {
      console.log(co.OptionId);
      let id = co.OptionId;
      commercial[id] = [];
      operations[id] = [];
      operationCost[id] = [];

      co.OPMSchema && co.OPMSchema?.length > 0 && co.OPMSchema.map((schema) => {
        let pmtable = [];
        let costTable = [];
        for (
          let activitypm = 0;
          activitypm < activityDataForOPS.length;
          activitypm++
        ) {
          let temparr = [];
          let activityName = activityDataForOPS[activitypm].activity_name;
          if (!(activityName in schema.Rateselection)) {
            temparr = Array(bandsDataForOPS.length).fill("");
          } else {
            let activityarr = schema.Rateselection[activityName];

            for (let bandCS = 0; bandCS < bandsDataForOPS.length; bandCS++) {
              let bandname = bandsDataForOPS[bandCS].band_name;
              let flag = true;
              activityarr.forEach(function (obj) {
                if (bandname in obj) {
                  temparr.push(obj[bandname]);
                  flag = false;
                }
              });
              if (flag) {
                temparr.push("");
              }
            }
          }
          pmtable.push(temparr);
        }
        let totalCost = 0;
        for (let bandCS = 0; bandCS < bandsDataForOPS.length; bandCS++) {
          let bandname = bandsDataForOPS[bandCS].band_name;
          totalCost += Number(schema.Rateselection[bandname]);           
          costTable.push({ [bandname]: schema.Rateselection[bandname] });
        }
        if(costTable && costTable?.length > 0){
          costTable.push({["totalCost"]:(Math.round(totalCost * 100) / 100).toFixed(2)});
        }
        let countryName = schema.Rateselection.dropdownCountry.label;
        countryArray.push({[id]:countryName});
        let obj = {};
        let costObj = {};
        costObj[countryName] = costTable;
        obj[countryName] = pmtable;
        operations[id].push(obj);
        operationCost[id].push(costObj);
      });
    });
    if(countryArray && countryArray?.length > 0){
      await teamData(countryArray,()=>{
        const tableHours = [];
    const tableCosts = [];
    
    for (let i = 0; i < activityDataForOPS.length; i++) {
      tableHours.push(Array(bandsDataForOPS.length).fill(""));
    }

    for (let i = 0; i < bandsDataForOPS.length; i++) {
      tableCosts.push(Array(bandsDataForOPS.length).fill(""));
    }

    profile.CountrySpecs.map((country, index) => {
      const newRow = {
        [profile.CountrySpecs[index]?.CountryName]: tableHours,
      };
  
      const newCostRow = {
        [profile.CountrySpecs[index]?.CountryName]: tableCosts,
      };
  
      const newTable = [newRow];
      const newCost = [newCostRow];

      country.MethodologySpecs.map((method) => {
        method.CostingOptions.map((co,index) => {
          if(!(co.id in operations) || (operations[co.id] && !operations[co.id].length)) {
            operations[co.id] = newTable;
          }
          if(!(co.id in operationCost) || (operationCost[co.id] && !operationCost[co.id].length)) {
            operationCost[co.id] = newCost;
          }
        });
      });
    });

    dispatch(setCommercialHours(commercial));
    dispatch(setOperationsHours(operations));
    dispatch(setOperationsCost(operationCost));
    setIsLoading(false);
      });
    }else{
      let newArray = [];
      let id=profile.CountrySpecs[activeTabFC].MethodologySpecs[
        activeTabSM
      ]?.CostingOptions[activeTabCO]?.id;
      let name = profile.CountrySpecs[activeTabFC].CountryName;
      newArray.push({ [id]: name });      
      await teamData(newArray,()=>{
        const tableHours = [];
    const tableCosts = [];
    
    for (let i = 0; i < activityDataForOPS.length; i++) {
      tableHours.push(Array(bandsDataForOPS.length).fill(""));
    }

    for (let i = 0; i < bandsDataForOPS.length; i++) {
      tableCosts.push(Array(bandsDataForOPS.length).fill(""));
    }

    profile.CountrySpecs.map((country, index) => {
      const newRow = {
        [profile.CountrySpecs[index]?.CountryName]: tableHours,
      };
  
      const newCostRow = {
        [profile.CountrySpecs[index]?.CountryName]: tableCosts,
      };
  
      const newTable = [newRow];
      const newCost = [newCostRow];

      country.MethodologySpecs.map((method) => {
        method.CostingOptions.map((co,index) => {
          if(!(co.id in operations) || (operations[co.id] && !operations[co.id].length)) {
            operations[co.id] = newTable;
          }
          if(!(co.id in operationCost) || (operationCost[co.id] && !operationCost[co.id].length)) {
            operationCost[co.id] = newCost;
          }
        });
      });
    });

    dispatch(setCommercialHours(commercial));
    dispatch(setOperationsHours(operations));
    dispatch(setOperationsCost(operationCost));
    setIsLoading(false);
      });
    }  
  };
  const setoptionsData = () => {
    let options = {};
    for (let country = 0; country < profile.CountrySpecs.length; country++) {
      let method = [];
      for (
        let methodology = 0;
        methodology < profile.CountrySpecs[country].MethodologySpecs.length;
        methodology++
      ) {
        let cost = [];
        for (
          let costingOption = 0;
          costingOption <
          profile.CountrySpecs[country].MethodologySpecs[methodology]
            .CostingOptions.length;
          costingOption++
        ) {
          let label =
            profile.CountrySpecs[country].MethodologySpecs[methodology]
              .CostingOptions[costingOption].label;
          let ID =
            profile.CountrySpecs[country].MethodologySpecs[methodology]
              .CostingOptions[costingOption].id;
          let productName=profile.CountrySpecs[country].MethodologySpecs[methodology]
          .CostingOptions[costingOption].CostingOptionData.producttype
              if(!(productName.includes("EXPENSE RECHARGE")))
              {
                cost.push({ label: label, value: ID })
                
              }  
        }
        method.push(cost);
      }
      options[country] = method;
    }
    setoptions(options);
  };

  useEffect(() => {
    if (!isLoading && makeScroll) {
      window.scrollTo(0, scrollPosition);
      setMakeScroll(false);
    }
  }, [isLoading, makeScroll]);

  useEffect(() => {
    // getActivityDetails();
    setoptionsData();
    gettimecost();
    getCurrencyDetails();
    // getInternalCost();
  }, []);

  useEffect(()=>{
    let tempObj=costoptionsDetails
    profile.CountrySpecs.map((country)=>{
      country.MethodologySpecs.map((method)=>{
        method.CostingOptions.map((co,index)=>{
         tempObj[co.id]={countryName:country.CountryName,oppID:co.OpportunityLineItemID
         }
        })
      })
    })
    setCostoptionsDetails(tempObj)
    console.log(tempObj,"tempObj")

  },[])

  const getCurrencyDetails=()=>{
    if(timecostView==="operations")
    {
      let countryCode=profile.OPSCurrencySelection||project.CommissioningCountry
      let currencyArray=currencies.filter((item)=>item.Code===countryCode)
      setOPSCurrencyCode(countryCode)
      setOPSCurrencyUnit(currencyArray[0].CurrencyUnit)
      setOPSCurrencyConversion(currencyArray[0].ConversionRateToLocal)
      let finalCurrency = {
				value: `${currencyArray[0].Code}-${currencyArray[0].CurrencyUnit}`,
				label: `${currencyArray[0].Label} (1USD = ${currencyArray[0].ConversionRateToLocal}${currencyArray[0].CurrencyUnit})`,
			};
      setOPScurrencyDropdown(finalCurrency)
    }
  }

  const toggleSM = (tab) => {
    setActiveTabCO(0);
    const tableData = _.cloneDeep(opsCostData);
    let costTableData =
      tableData[
        profile.CountrySpecs[activeTabFC].MethodologySpecs[tab].CostingOptions[0].id
      ];
    let countryMap =  costTableData?.map((m)=>Object.keys(m)[0]);
    
    let dataOption=OPSTeamDataOptions;
    let res= dataOption.filter((data)=>
      !countryMap.includes(data.label)
    );
    setOPSTeamDataOptionsTemp(res);
        if (activeTabSM !== tab) {
      setActiveTabSM(tab);
    }
    let opsSupport =
      profile.CountrySpecs[activeTabFC].MethodologySpecs[tab].CostingOptions[0]
        .CostingOptionData.operationalpm;
    if (opsSupport === "Yes") {
      setLocalSupportOPS(true);
    } else {
      setLocalSupportOPS(false);
    }
    let modalSupport =
      profile.CountrySpecs[activeTabFC].MethodologySpecs[tab].CostingOptions[0]
        .CostingOptionData?.operationpm;
    if (modalSupport === "Yes") {
      setLocalSupportModalOPS(true);
    } else {
      setLocalSupportModalOPS(false);
    }
  };

  const toggleTabPane = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  const toggleCO = (tab) => {
    const tableData = _.cloneDeep(opsCostData);
    let costTableData =
      tableData[
        profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
        .CostingOptions[tab].id
      ];
    let countryMap =  costTableData?.map((m)=>Object.keys(m)[0]);
    
    let dataOption=OPSTeamDataOptions;
    let res= dataOption.filter((data)=>
      !countryMap.includes(data.label)
    );
    setOPSTeamDataOptionsTemp(res);
    if (activeTabCO !== tab) {
      setActiveTabCO(tab);
    }
    let opsSupport =
      profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
        .CostingOptions[tab].CostingOptionData.operationalpm;
    if (opsSupport === "Yes") {
      setLocalSupportOPS(true);
    } else {
      setLocalSupportOPS(false);
    }
    let modalSupport =
      profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
        .CostingOptions[tab].CostingOptionData?.operationpm;
    if (modalSupport === "Yes") {
      setLocalSupportModalOPS(true);
    } else {
      setLocalSupportModalOPS(false);
    }
  };

  const infoModalToggle = () => {
    setInfoModal(false);
  };
  const handleOPSCurrencyChange =(e)=>{
    let countryCode=e.value.split("-")[0]
    let currencyArray=currencies.filter((item)=>item.Code===countryCode)
    setOPSCurrencyCode(countryCode)
    setOPSCurrencyUnit(currencyArray[0].CurrencyUnit)
    setOPSCurrencyConversion(currencyArray[0].ConversionRateToLocal)
    let finalCurrency = {
      value: `${currencyArray[0].Code}-${currencyArray[0].CurrencyUnit}`,
      label: `${currencyArray[0].Label} (1USD = ${currencyArray[0].ConversionRateToLocal}${currencyArray[0].CurrencyUnit})`,
    };
    setOPScurrencyDropdown(finalCurrency)
  }
  function populateSideBarLinks(variables) {
    if (variables) {
      dispatch(
        sideBarActions.addCostingOptionsLink(
          "#costing_options_card_" + activeTabFC + "_" + activeTabSM
        )
      );
      dispatch(
        sideBarActions.addSudOptionsLink(
          "#sud_options_card_" + activeTabFC + "_" + activeTabSM
        )
      );

      if (profile.CountrySpecs.length > 1)
        dispatch(
          sideBarActions.resOptionsLink(
            "#res_options_card_" + activeTabFC + "_" + activeTabSM
          )
        );
      else dispatch(sideBarActions.resOptionsLink(""));

      let links = [];
      variables.forEach((x, index) => {
        if (x.isNewSection)
          links.push({ label: x.sectionTitle, value: "#header_" + index });
      });
      dispatch(sideBarActions.addSidebarLinks(links));
    }
  }

  function toggle(tab) {
    setActiveTabSM(0);
    setActiveTabCO(0);
    const tableData = _.cloneDeep(opsCostData);
    let costTableData =
      tableData[
        profile.CountrySpecs[tab].MethodologySpecs[0].CostingOptions[0].id
      ];
    let countryMap =  costTableData?.map((m)=>Object.keys(m)[0]);
    
    let dataOption=OPSTeamDataOptions;
    let res= dataOption.filter((data)=>
      !countryMap.includes(data.label)
    );
    setOPSTeamDataOptionsTemp(res);
    if (activeTabFC !== tab) {
      setActiveTabFC(tab);
      let allMeth = profile.CountrySpecs[tab].MethodologySpecs.map(
        (m, mIndex) => ({
          methodology: m,
          index: mIndex,
        })
      );
      let reqMeth = allMeth.find((x) => !x.methodology.NotApplicable);
      if (!reqMeth) reqMeth = _.head(allMeth);
    }

    if (
      !(
        "CostingOptionData" in
        profile.CountrySpecs[tab].MethodologySpecs[0].CostingOptions[0]
      )
    ) {
      populateSideBarLinks(
        profile.CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]
          ?.Variables
      );
    }
    let opsSupport =
      profile.CountrySpecs[tab].MethodologySpecs[0].CostingOptions[0]
        .CostingOptionData.operationalpm;
    if (opsSupport === "Yes") {
      setLocalSupportOPS(true);
    } else {
      setLocalSupportOPS(false);
    }
    let modalSupport =
      profile.CountrySpecs[tab].MethodologySpecs[0].CostingOptions[0]
        .CostingOptionData?.operationpm;
    if (modalSupport === "Yes") {
      setLocalSupportModalOPS(true);
    } else {
      setLocalSupportModalOPS(false);
    }
  }

  const updateModal = () => {
    settimecostViewModal(!timecostViewModal);
  };

  const getWBSnumber = () => {
    let oppID = profile?.CountrySpecs ? 
      profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
        .CostingOptions[activeTabCO].OpportunityLineItemID : "";
    let ProductTypeData = profile?.ProductTypeData !== undefined ? profile?.ProductTypeData : [];
    let filterarray = Array.isArray(ProductTypeData) ? ProductTypeData?.filter(
      (item) => item.OpportunityLineItemID == oppID
    ) : [];
    if (filterarray) {
      return filterarray[0]?.WBSNumber;
    }
    return null;
  };

  const clearOPSTable = (country) => {
    setShowModal(true);
    setSelectedCountryForClear(country);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleOk = () => {
    setIsClear(selectedCountryForClear);
    setShowModal(false);
  };

  const deleteOPSTable = (costOptionID, index) => {
    console.log("......", costOptionID, index);
    setSelectedCostOptionID(costOptionID);
    setSelectedIndex(index);
    setShowDeleteModal(true);
  };

  const handleCloseModal = () => {
    setShowDeleteModal(false);
  };

  const handleOKModal = () => {
    let csdata = _.cloneDeep(opsCostData);
    let costdata = _.cloneDeep(operationsCostData);

    const data = csdata[selectedCostOptionID]
      .map((c) => c)
      .filter((country, i) => i != selectedIndex);
    const cdata = costdata[selectedCostOptionID]
      .map((c) => c)
      .filter((country, i) => i != selectedIndex);
    const deletedData = csdata[selectedCostOptionID]
      .map((c) => c)
      .filter((country, i) => i === selectedIndex);
    const deletedCData = costdata[selectedCostOptionID]
      .map((c) => c)
      .filter((country, i) => i === selectedIndex);
      let teamData = OPSTeamDataOptions?.map((m)=>m.label);

    //opsdata  
    let clonedDeletedData = _.cloneDeep(deletedTimeCostData);
    if(teamData.includes(Object.keys(deletedData[0])[0])){
    if (clonedDeletedData && Object.keys(clonedDeletedData).length) {
      if (clonedDeletedData?.[selectedCostOptionID] === undefined) {
        let newObj = { [selectedCostOptionID]: deletedData };
        Object.assign(clonedDeletedData, newObj);
      }else{
        let newArray = clonedDeletedData[selectedCostOptionID];
        newArray.push(deletedData[0]);
        clonedDeletedData[selectedCostOptionID] = newArray;
      }
    }
    else {
      clonedDeletedData = { [selectedCostOptionID]: deletedData };
    }
  }
    //cost data
    let clonedCostData = _.cloneDeep(deletedCostData);
    if(teamData.includes(Object.keys(deletedCData[0])[0])){
    if (clonedCostData && Object.keys(clonedCostData).length) {
      if (clonedCostData?.[selectedCostOptionID] === undefined) {
        let newObj = { [selectedCostOptionID]: deletedCData };
        Object.assign(clonedCostData, newObj);
      }else{
        let newArray = clonedCostData[selectedCostOptionID];
        newArray.push(deletedCData[0]);
        clonedCostData[selectedCostOptionID] = newArray;
      }
    }
    else {
      clonedCostData = { [selectedCostOptionID]: deletedCData };
    }
  }
    if (clonedDeletedData && Object.keys(clonedDeletedData)?.length) {
      setDeletedTimeCostData(clonedDeletedData);
    }
    if (clonedCostData && Object.keys(clonedCostData)?.length) {
      setDeletedCostData(clonedCostData);
    }
    csdata[selectedCostOptionID] = data;
    costdata[selectedCostOptionID] = cdata;
     //excluding the already selected country from the list
     let costTableData = csdata[selectedCostOptionID];
     let countryMap =  costTableData?.map((m)=>Object.keys(m)[0]);
     
     let dataOption=OPSTeamDataOptions;
     let res= dataOption.filter((data)=>
       !countryMap.includes(data.label)
     );
    setOPSTeamDataOptionsTemp(res);
    dispatch(setOperationsHours(csdata));
    dispatch(setOperationsCost(costdata));
    setShowDeleteModal(false);
  };

  const handleSelectChange = (e, index) => {
    const tableData = _.cloneDeep(opsCostData);
    const tableCostData = _.cloneDeep(operationsCostData);

    let coData =
      tableData[
        profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
          .CostingOptions[activeTabCO].id
      ];
      let costData = tableCostData[
        profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
          .CostingOptions[activeTabCO].id
      ];
    let obj = {};
    let costObj = {};
    obj[e.label] = Object.values(coData[index])[0];
    coData[index] = obj;
    costObj[e.label] = Object.values(costData[index])[0];
    costData[index] = costObj;

    //excluding the already selected country from the list
    let costTableData =
    tableData[
      profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
        .CostingOptions[activeTabCO].id
    ];
    let countryMap =  costTableData?.map((m)=>Object.keys(m)[0]);
    let dataOption=OPSTeamDataOptions;
    let res= dataOption.filter((data)=>
    !countryMap.includes(data.label)
    );

    setOPSTeamDataOptionsTemp(res);
    dispatch(setOperationsHours(tableData));
    dispatch(setOperationsCost(tableCostData));
    console.log(tableData, "tableData");
  };

  const copyMethodologiesData = () => {
    var convertedMethodologies = [];

    if (profile.CountrySpecs[activeTabFC].MethodologySpecs[0].label) {
      convertedMethodologies = profile.CountrySpecs[
        activeTabFC
      ].MethodologySpecs.map((fc) => ({ label: fc.label, value: fc.label }));
    } else {
      convertedMethodologies = profile.CountrySpecs[
        activeTabFC
      ].MethodologySpecs.map((fc) => ({ label: fc.Label, value: fc.Label }));
    }
    setSelectedMethodologyData1(convertedMethodologies);
    setModalMethodology(true);
  };

  const generateRandomKey = () => {
    const randomKey = Math.random().toString(36).substring(2, 10);
    return randomKey;
  };

  const addNewCostingRow = () => {
    const costingOptionID =
      profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
        ?.CostingOptions[activeTabCO]?.id;
    const tableHours = [];
    for (var i = 0; i < activitiesForOPS.length; i++) {
      tableHours.push(Array(OPSband.length).fill(""));
    }
    const newRow = {
      [generateRandomKey()]: tableHours,
    };

    const tableCosts = [];
    for (var i = 0; i < OPSband.length; i++) {
      tableCosts.push(Array(OPSband.length).fill(""));
    }
    const newCostRow = {
      [generateRandomKey()]: tableCosts,
    };

    const tableData = _.cloneDeep(opsCostData);
    const tableCostData = _.cloneDeep(operationsCostData);

    //for hours
    if (tableData && Object.keys(tableData).length && tableData[costingOptionID]) {
      tableData[costingOptionID].push(newRow);
    } else {
      const optionIdAssignment = {
        [costingOptionID]: [newRow],
      };
      Object.assign(tableData, optionIdAssignment);
    }

    //for cost
    if (tableCostData && Object.keys(tableCostData).length && tableCostData[costingOptionID]) {
      tableCostData[costingOptionID].push(newCostRow);
    } else {
      const optionIdAssignment = {
        [costingOptionID]: [newCostRow],
      };
      Object.assign(tableCostData, optionIdAssignment);
    }
    dispatch(setOperationsHours(tableData));
    dispatch(setOperationsCost(tableCostData))
    toastr.success("New Costing Market Added");
  };

  const copyCountriesDataOps = () => {
    var convertedCountries = [];

    if (profile.CountrySpecs[0].label) {
      convertedCountries = profile.CountrySpecs.map((fc) => ({
        label: fc.label,
        value: fc.label,
      }));
    } else {
      convertedCountries = profile.CountrySpecs.map((fc) => ({
        label: fc.CountryName,
        value: fc.CountryName,
      }));
    }

    setSelectedCountryData(convertedCountries);
    copyMethodologiesData();

    setOpsModal(true);
  };

  const getCSOPSDetails=(coID)=>{
    let result=[];
    profile.CountrySpecs.map((country)=>{
      country.MethodologySpecs.map((method)=>{
        method.CostingOptions.map((co,index)=>{
          if(coID==co.id)
          {
            result.push(co.CostingOptionData.CommericalLocalsu,co.CostingOptionData.operationalpm,co.CostingOptionData.Commericalsupport,co.CostingOptionData.operationpm,method.Code,country.CountryCode)
          }
        })
      })
    })
    return result
  };

  const copyHoursWBSlevel=async()=>{
    const response =await axios.get(`/timecost/${profile.id}`)
    let costingOptionDetailsObj=costoptionsDetails
    let hoursPayload={
      records: [],
    };
    let totalCostPayload = {
      records: [],
    };
    for (const costingOptionID in opsCostData) {
      const [csSupport,opsSupport,csModal,opsModal]=getCSOPSDetails(costingOptionID)
      
        if(opsSupport==="No")
        {
          continue
        }
        if(opsModal==="No")
        {
          continue
        }
      
     
      let oppID=costingOptionDetailsObj[costingOptionID].oppID
      let countryName=costingOptionDetailsObj[costingOptionID].countryName
      let costArray=operationsCostData[costingOptionID]
      if(levelInput[oppID]==="wbslevel")
      {
        let tableArray=opsCostData[costingOptionID]
        let OPMSchema=[]
        tableArray.map((country,index)=>{
          let countryName;
          let hours;
          for (const [key, value] of Object.entries(country)) {
            countryName=key
            hours=value
          }
          let opsCostArray=costArray[index]
          let bandCostArray=opsCostArray[countryName]

          const isCountry = OPSTeamDataOptions?.some((obj) => obj.label === countryName);

          if(bandCostArray === undefined && !isCountry) {
            return null;
          }
  
          let pmData = {};
          OPSband.map((bandValue, index) => {
            let bandObj=bandCostArray[index]
            pmData[bandValue.band_name] = bandObj[bandValue.band_name];
          });
        let pmTable=hours
        pmData["dropdownCountry"]={label:countryName}
        for(let activity=0;activity<activitiesForOPS.length;activity++)
        {
          let temparr=[]
          let activityName=activitiesForOPS[activity].activity_name
        
          for(let bandCS=0;bandCS<OPSband.length;bandCS++)
          {
            let bandname=OPSband[bandCS].band_name
            let tempObj={}
            tempObj[bandname]=pmTable[activity][bandCS]
            temparr.push(tempObj)
          }
          pmData[activityName]=temparr
        }
        let finalObj={
          Rateselection: pmData,
        }
        response.data.datas.map((res)=>{
          if(res.OptionId===costingOptionID)
          {
            if(res.OPMSchema.length>index)
            {
              let schema=res.OPMSchema[index]
              if("CostId" in schema)
              {
                finalObj["CostId"]=schema.CostId
              }
            }
          }
        });
  
        OPMSchema.push(finalObj)
        })
          profile.CountrySpecs.map((country)=>{
          country.MethodologySpecs.map((method)=>{
            method.CostingOptions.map((co,index)=>{
              if(co.OpportunityLineItemID==oppID && country.CountryName==countryName && co.id!=costingOptionID&&co.CostingOptionData.CommericalLocalsu==="Yes")
              {
                let copyOPSschema=_.cloneDeep(OPMSchema)
                let costingOptionPayload={
                  ProfileId: profile.id,
                }
                costingOptionPayload["CountryId"]=country.CountryCode
                costingOptionPayload["MethodologyCode"]=method.Code
                costingOptionPayload["OptionId"]=co.id

                response.data.datas.map((res)=>{
                  if(res.OptionId==co.id)
                  {
                    copyOPSschema.map((schemaItem,index)=>{
                      if(res.OPMSchema.length>index)
                      {
                        let schema=res.OPMSchema[index]
                        if("CostId" in schema)
                        {
                          schemaItem["CostId"]=schema.CostId
                        }
                      }
                    })
                    
                  }
                });
                costingOptionPayload["OPMSchema"]=copyOPSschema


                response.data.datas.map((res)=>{
                  if(res.OptionId==co.id)
                  {
                    costingOptionPayload["id"]=res.id
                  }
                })
                hoursPayload.records.push(costingOptionPayload)
              }
              })
            })
          })

          const totalOperationsCost = operationsCostData[costingOptionID]?.map((csCost) => {
            return Object.values(csCost)[0];
          });
    
          const finalOpsCost = totalOperationsCost.reduce((outerAcc, innerArray) => {
            const innerSum = innerArray.reduce((innerArray, obj) => {
              const totalCostValue = parseFloat(obj.totalCost);
              if(!isNaN(totalCostValue)) {
                innerArray += totalCostValue;
              }
              return innerArray;
            }, 0);
            return outerAcc + innerSum;
          }, 0);

          
          profile.CountrySpecs.map((country)=>{
            country.MethodologySpecs.map((method)=>{
              method.CostingOptions.map((co,index)=>{
                if(co.OpportunityLineItemID==oppID && country.CountryName==countryName && co.id!=costingOptionID&&co.CostingOptionData.CommericalLocalsu==="Yes")
                {
                  let payload;
                  if(co.CostingOptionData.Booster==="No")
                  {
                    payload = {
                      id: co.id,
                      TotalOPSTimeCost: finalOpsCost
                    };
                    totalCostPayload.records.push(payload);
                  }
                  else
                  {
                    payload = {
                      id: co.id,
                      TotalOPSTimeCost: finalOpsCost*0.05
                    };
                    totalCostPayload.records.push(payload);
                  }
                }
              })
            })
          })

                      
      }
    }

    let resultpayload=[hoursPayload,totalCostPayload]
    console.log("copyhoursPayload",hoursPayload);
    return resultpayload
  }
    //*********************************************************** */
    const deleteHoursWBSlevel=async(response)=>{
      
    let costingOptionDetailsObj=costoptionsDetails
    let hoursPayload=[];
    for (const costingOptionID in deletedTimeCostData) {
      const [csSupport,opsSupport,csModal,opsModal]=getCSOPSDetails(costingOptionID)
      
        if(opsSupport==="No")
        {
          continue
        }
        if(opsModal==="No")
        {
          continue
        }
      
     
      let oppID=costingOptionDetailsObj[costingOptionID].oppID
      let countryName=costingOptionDetailsObj[costingOptionID].countryName
      let costArray=deletedCostData[costingOptionID]
      if(levelInput[oppID]==="wbslevel")
      {
        let tableArray=deletedTimeCostData[costingOptionID]
        let OPMSchema=[]
        tableArray.map((country,index)=>{
          let countryName;
          let hours;
          for (const [key, value] of Object.entries(country)) {
            countryName=key
            hours=value
          }
          let opsCostArray=costArray[index]
          let bandCostArray=opsCostArray[countryName]

          const isCountry = OPSTeamDataOptions?.some((obj) => obj.label === countryName);

          if(bandCostArray === undefined && !isCountry) {
            return null;
          }
  
          let pmData = {};
          OPSband.map((bandValue, index) => {
            let bandObj=bandCostArray[index]
            pmData[bandValue.band_name] = bandObj[bandValue.band_name];
          });
        let pmTable=hours
        pmData["dropdownCountry"]={label:countryName}
        for(let activity=0;activity<activitiesForOPS.length;activity++)
        {
          let temparr=[]
          let activityName=activitiesForOPS[activity].activity_name
        
          for(let bandCS=0;bandCS<OPSband.length;bandCS++)
          {
            let bandname=OPSband[bandCS].band_name
            let tempObj={}
            tempObj[bandname]=pmTable[activity][bandCS]
            temparr.push(tempObj)
          }
          pmData[activityName]=temparr
        }
        let finalObj={
          Rateselection: pmData,
          Isdeleted: true,
        }
        response.data.datas.map((res)=>{
          if(res.OptionId===costingOptionID)
          {
            if(res.OPMSchema.length>index)
            {
              let schema=res.OPMSchema[index]
              if("CostId" in schema)
              {
                finalObj["CostId"]=schema.CostId
              }
            }
          }
        });
  
        OPMSchema.push(finalObj)
        })
          profile.CountrySpecs.map((country)=>{
          country.MethodologySpecs.map((method)=>{
            method.CostingOptions.map((co,index)=>{
              if(co.OpportunityLineItemID==oppID && country.CountryName==countryName && co.id!=costingOptionID&&co.CostingOptionData.CommericalLocalsu==="Yes")
              {
                let copyOPSschema=_.cloneDeep(OPMSchema)
                let costingOptionPayload={
                  ProfileId: profile.id,
                }
                costingOptionPayload["CountryId"]=country.CountryCode
                costingOptionPayload["MethodologyCode"]=method.Code
                costingOptionPayload["OptionId"]=co.id

                response.data.datas.map((res)=>{
                  if(res.OptionId==co.id)
                  {
                    copyOPSschema.map((schemaItem,index)=>{
                      if(res.OPMSchema.length>index)
                      {
                        res.OPMSchema?.map((r)=>{
                          if (r.Rateselection.dropdownCountry.label == schemaItem.Rateselection.dropdownCountry.label) {
                            if ("CostId" in r) {
                              schemaItem["CostId"] = r.CostId
                            }
                          }
                         })
                      }
                    })
                    
                  }
                });
                costingOptionPayload["OPMSchema"]=copyOPSschema


                response.data.datas.map((res)=>{
                  if(res.OptionId==co.id)
                  {
                    costingOptionPayload["id"]=res.id
                  }
                })
                hoursPayload.push(costingOptionPayload)
              }
              })
            })
          })                    
      }
    }
    return hoursPayload;
    }
    //************************************************************ */
  const deleteTimeCostDatas = async (response) => {
    console.log("?????", deletedTimeCostData);
    let records=await deleteHoursWBSlevel(response);
    for(let costingOptionID in deletedTimeCostData)
    {
      const [csSupport,opsSupport,csModal,opsModal,methodCode,countryCode]=getCSOPSDetails(costingOptionID)
      if(opsSupport==="No")
      {
        continue
      }
      if(opsModal==="No")
      {
        continue
      }
      let tableArray=deletedTimeCostData[costingOptionID]
      let costArray=deletedCostData[costingOptionID]
      let costingOptionPayload={
        ProfileId: profile.id,
  
        CountryId: countryCode,

        MethodologyCode:methodCode,

        OptionId: costingOptionID,
        OPMSchema:[]
      }

      tableArray.map((country,index)=>{
        let countryName;
        let hours;
        for (const [key, value] of Object.entries(country)) {
          countryName=key
          hours=value
        }
        let opsCostArray=costArray[index]
        let bandCostArray=opsCostArray[countryName]

        let pmData = {};
        OPSband.map((bandValue, index) => {
          let bandObj=bandCostArray[index]
          pmData[bandValue.band_name] = bandObj[bandValue.band_name];
        });
      let pmTable=hours
      pmData["dropdownCountry"]={label:countryName}
      for(let activity=0;activity<activitiesForOPS.length;activity++)
      {
        let temparr=[]
        let activityName=activitiesForOPS[activity].activity_name
      
        for(let bandCS=0;bandCS<OPSband.length;bandCS++)
        {
          let bandname=OPSband[bandCS].band_name
          let tempObj={}
          tempObj[bandname]=pmTable[activity][bandCS]
          temparr.push(tempObj)
        }
        pmData[activityName]=temparr
      }
      let finalObj={
        Rateselection: pmData,
        Isdeleted: true,
      }
      response.data.datas.map((res)=>{
        if(res.OptionId==costingOptionID)
        {
          res.OPMSchema.map((r)=>{
            if (r.Rateselection.dropdownCountry.label === countryName) {
              if ("CostId" in r) {
                finalObj["CostId"] = r.CostId
              }
            } 
          })
        }
      });
      costingOptionPayload.OPMSchema.push(finalObj)
      })


      response.data.datas.map((res)=>{
        if(res.OptionId==costingOptionID)
        {
          costingOptionPayload["id"]=res.id
        }
      })

      records.push(costingOptionPayload)
    }
    
    await axios.put(`/timecost/Trash/${profile.id}`, records).then(
      res => {
        setDeletedTimeCostData({});
        setDeletedCostData({});
      }
    ).catch(err => {
      console.log(err.message);
    })
  }

  function validateSUD() {
    console.log(profile, "profileee")
    profile?.CountrySpecs.map((fc) =>
      fc?.MethodologySpecs.map((sm) =>
        sm?.OpResourcesVariablesTimecost.filter((v) => v?.type == "integer").map(
          (cd) => {
            if (parseInt(cd?.value) < 0) {
              isvalid = false;
            }
          }
        )
      )
    );
  }

  const saveTableData = async () => {
    dispatch(setOpportunityValue(""));    
    
    // if (validateForms(_profile)) {
    setIsLoading(true);
    setAlert(false);      
    let tempProfile = _.cloneDeep(profile);
    let _profile = saveDraft(true);
    if(isNewProject) {
      saveDraft();      
    // handleReasonSave();
    }
    tempProfile["OpsTimecostReason"] = opsTimecostReason
    // dispatch(currentCostingActions.updateProfile(tempProfile));
    // dispatch(currentCostingActions.saveCostingProfileDraft(tempProfile,()=>{
    //   toastr.success("Timecost Reason saved succesfully")
    // },true));
    handleOpen(false)
    let payload=await copyHoursWBSlevel()
    console.log("?????",deletedTimeCostData);
    const response = await axios.get(`/timecost/${profile.id}`);
    let hoursPayload=payload[0]

    let totalCostPayload = payload[1]


    if(deletedTimeCostData && Object.keys(deletedTimeCostData)?.length && response?.data?.datas?.length >0){
      deleteTimeCostDatas(response);  
    }
    
    for(let costingOptionID in opsCostData)
    {
      const [csSupport,opsSupport,csModal,opsModal,methodCode,countryCode]=getCSOPSDetails(costingOptionID)
      if(opsSupport==="No")
      {
        continue
      }
      if(opsModal==="No")
      {
        continue
      }
      let tableArray=opsCostData[costingOptionID]
      let costArray=operationsCostData[costingOptionID]
      let costingOptionPayload={
        ProfileId: profile.id,
  
        CountryId: countryCode,

        MethodologyCode:methodCode,

        OptionId: costingOptionID,
        OPMSchema:[]
      }

      tableArray.map((country,index)=>{
        let countryName;
        let hours;
        for (const [key, value] of Object.entries(country)) {
          countryName=key
          hours=value
        }
        let opsCostArray=costArray[index]
        let bandCostArray=opsCostArray[countryName]

        const isCountry = OPSTeamDataOptions?.some((obj) => obj.label === countryName);

        if(bandCostArray === undefined && !isCountry) {
          for(let country=0;country<profile.CountrySpecs.length;country++){
            for(let methodology=0;methodology<profile.CountrySpecs[country].MethodologySpecs.length;methodology++){
              for(let costingOption=0;costingOption<profile.CountrySpecs[country].MethodologySpecs[methodology].CostingOptions.length;costingOption++){
                if(profile.CountrySpecs[country].MethodologySpecs[methodology].CostingOptions[costingOption].id == costingOptionID) {
                  toastr.warning(`Please select a country for ${profile.CountrySpecs[country].CountryName} > ${profile.CountrySpecs[country].MethodologySpecs[methodology].label} > ${profile.CountrySpecs[country].MethodologySpecs[methodology].CostingOptions[costingOption].Name} > Team ${index+1}`, '', {timeOut: 5000});
                }
              }
            }
          }
          return null;
        }

        let pmData = {};
        OPSband.map((bandValue, index) => {
          let bandObj=bandCostArray[index]
          pmData[bandValue.band_name] = bandObj[bandValue.band_name];
        });
      let pmTable=hours
      pmData["dropdownCountry"]={label:countryName}
      for(let activity=0;activity<activitiesForOPS.length;activity++)
      {
        let temparr=[]
        let activityName=activitiesForOPS[activity].activity_name
      
        for(let bandCS=0;bandCS<OPSband.length;bandCS++)
        {
          let bandname=OPSband[bandCS].band_name
          let tempObj={}
          tempObj[bandname]=pmTable[activity][bandCS]
          temparr.push(tempObj)
        }
        pmData[activityName]=temparr
      }
      let finalObj={
        Rateselection: pmData,
      }
      response.data.datas.map((res)=>{
        if(res.OptionId==costingOptionID)
        {
          if(res.OPMSchema.length>index)
          {
            let schema=res.OPMSchema[index]
            if("CostId" in schema)
            {
              finalObj["CostId"]=schema.CostId
            }
          }
        }
      });

      costingOptionPayload.OPMSchema.push(finalObj)
      })

      

      response.data.datas.map((res) => {
        if(res.OptionId==costingOptionID)
        {
          costingOptionPayload["id"]=res.id
        }
      });

      const totalOperationsCost = operationsCostData[costingOptionID]?.map((csCost) => {
        return Object.values(csCost)[0];
      });

      const finalOpsCost = totalOperationsCost.reduce((outerAcc, innerArray) => {
        const innerSum = innerArray.reduce((innerArray, obj) => {
          const totalCostValue = parseFloat(obj.totalCost);
          if(!isNaN(totalCostValue)) {
            innerArray += totalCostValue;
          }
          return innerArray;
        }, 0);
        return outerAcc + innerSum;
      }, 0);


      let payload = {
        id: costingOptionID,
        TotalOPSTimeCost: finalOpsCost,
        OPSCurrencySelection:OPSCurrencyCode
      };

      hoursPayload.records.push(costingOptionPayload);
      totalCostPayload.records.push(payload);
    }
    console.log("hoursPayload",hoursPayload);
    console.log(totalCostPayload,"totalcostpayload");

    if(hoursPayload?.records[0]?.OPMSchema.length > 0) {
      // if(totalCostPayload.records.length > 0) {
      //   dispatch(saveTotalCost(totalCostPayload, profile.id));
      // }
      // if(hoursPayload.records.length > 0)
      // {
      //   dispatch(saveHourspayload(hoursPayload, profile.id));
      // }
      // let tempProfile=_.cloneDeep(profile)
      tempProfile["OPSCurrencySelection"]=OPSCurrencyCode
      dispatch(currentCostingActions.updateProfile(tempProfile));
      dispatch(saveTimecost(hoursPayload,totalCostPayload,profile.id));
      dispatch(currentCostingActions.saveCostingProfileDraft(tempProfile, () => {
          toastr.success("Timecost Reason saved succesfully")
        },true));
      if(isNewProject) {
        validateForms(_profile);          
      }
      setIsLoading(false);
    } else {
      toastr.warning("Data not saved");
      setIsLoading(false);
    }
    // }
  };

  const getTotalBandHours = (bandIndex, data) => {
    let totalHours = 0;
    let hours = Object.values(data)[0];
    for (let i = 0; i < activitiesForOPS.length; i++) {
      if (hours[i][bandIndex] !== "") totalHours += hours[i][bandIndex];
    }
    return totalHours;
  };

  const getTotalCost = (id, i, data) => {
    let bandRates = [];    
    let totalHours = getTotalBandHours(i, data);
    if (isNaN(totalHours)) {
      return 0;
    }
    const country = OPSTeamDataOptions?.filter((opsTeam =>opsTeam.label === Object.keys(data)[0] ));
    if(country && country?.length > 0){
      bandRates = defaultRates?.filter((rate) => rate.country_id === country[0]?.value);
    }
    let rate = bandRates?.filter((r)=>r.band_id === id);
    let finalRate = 0;
    if(rate && rate?.length > 0){
    finalRate = Number(
    Math.round(totalHours * rate[0].rate * 100) / 100
    ).toFixed(2);
    }
    return finalRate; 
  };

  const totalCost = (data) => {    
    let totalCost = 0;
    OPSband.forEach((b,index)=>{
      let tempCsValue = getTotalCost(b.band_id, index, data);
      if (!isNaN(tempCsValue)) {
        totalCost += Number(tempCsValue);
      }
    })
    return (Math.round(totalCost * 100) / 100).toFixed(2);
  };

  const getTotalOpsCost = (opportunityId) => {
    let totalSum = 0;
    for(const co in costoptionsDetails) {
      if(costoptionsDetails[co]?.oppID == opportunityId) {
        const totalOpsCost = opsCostData[co]?.map(data => totalCost(data))
                              ?.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0);
        totalSum += totalOpsCost;
      }
    }
    return totalSum;
  };

  
  const totalOpsCostArray = profile?.CountrySpecs !== undefined ? opsCostData[
    profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
      ?.CostingOptions[activeTabCO]?.id
    ]?.map(data => totalCost(data)) : [];
    
    const totalOpsCost = totalOpsCostArray?.length > 0 ? totalOpsCostArray?.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0) : 0;
    
    const getProductName=()=>{
      return profile?.CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]?.CostingOptions[activeTabCO]?.CostingOptionData.producttype
    }
    
    const showErrorMessages = (eve, errors, key) => {
      eve.preventDefault();
      dispatch(setFormErrorStatus({ [key]: errors }));
    };

  const ValueChangeCheckInChild = (val) => {
    dispatch(valueChangedInRFQ(val));
    // eve.preventDefault();
    // dispatch(setFormErrorStatus({ [key]: errors }))
  };

  const handleTimecostReasonOPS=(e,oppID)=>{
    let reasonObj={...opsTimecostReason}
    reasonObj[oppID]=e.target.value
    setopsTimecostReason(reasonObj)
  }

  return (
    <Wrapper>
      <Sidebar />
      <Main>
        <ProjectNavbar />
        {isLoading || !pageloaded? (
          <div id="pageCoverSpin"></div>
        ) : (
          <div className={"tab mt-12"}>
            <div
              style={{
                border: "2px solid grey",
                // width: "470px",
                fontSize: "14px",
                // float: "right",
                marginTop: "5px",
              }}
            >
              <p style={{ marginBottom: "0px" }}>
                Please refer to the Time Cost Planning Dashboard link below as a
                reference on how much hours to be budgeted.
              </p>
              <p>
                <a
                  href="https://bit.ly/NIQ-Time-Cost-Dashboard"
                  style={{ color: "blue" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  https://bit.ly/NIQ-Time-Cost-Dashboard
                </a>
              </p>
              <p style={{ marginBottom: "0px" }}>
                Access to be granted by Capacity Management Team (CMT).Please
                send an email request to
                m2m_capacity_management@nielsenenterprise.onmicrosoft.com. And
                indicate the user's email address (also include other team
                members if any) and market/s that want access to.
              </p>
            </div>
            <Row style={{ position: "sticky", zIndex: "1", top: "70px" }}>
              <Col
                md={12}
                className={"countryTabs tab-vertical"}
                style={{ height: "90px" }}
              >
                <div className={"tab mt-2"}>
                  <Nav tabs style={{ width: "100px", display: "inline-block" }}>
                    {profile.CountrySpecs?.map((fc, indxFC) => (
                      <NavItem key={fc.value}>
                        <NavLink
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          className={classnames({
                            active: activeTabFC === indxFC,
                          })}
                          onClick={() => {
                            toggle(indxFC);
                          }}
                        >
                          <b>{fc.label ? fc.label : fc.CountryName}</b>
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  <TabContent activeTab={activeTabFC}>
                    {profile.CountrySpecs?.map(
                      (fc, indxFC) =>
                        activeTabFC === indxFC && (
                          <>
                            <TabPane tabId={indxFC} key={fc.value}>
                              <Row>
                                <Col md={12} className={"methodologyTabs"}>
                                  <Nav tabs>
                                    {fc.MethodologySpecs?.map((sm, indxSM) => (
                                      <>
                                        <NavItem key={sm.value}>
                                          <NavLink
                                            className={classnames({
                                              active: activeTabSM === indxSM,
                                            })}
                                            onClick={() => {
                                              toggleSM(indxSM);
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                              }}
                                            >
                                              <div className="hoverEffect">
                                                {sm.NotApplicable}
                                                <b>
                                                  {sm.label
                                                    ? sm.label
                                                    : sm.Label}
                                                </b>
                                              </div>
                                            </div>
                                          </NavLink>
                                        </NavItem> 
                                                     
                                      </>
                                    ))}
                                  </Nav>
                                </Col>
                              </Row>
                            </TabPane>
                          </>
                        )
                    )}
                  </TabContent>  
                  </div>
                  </Col> 
              </Row>   
                  {isNewProject && profile.CountrySpecs?.map(
                      (fc, indxFC) =>
                        activeTabFC === indxFC && (
                          <Row>
                            <Col md={11}>
                            {fc.MethodologySpecs?.map((sm, indxSM) => (
                              activeTabSM === indxSM &&
                              <TestPageOpResourceNew
                                profile={profile}
                                indxFC={indxFC}
                                methodology={sm}
                                indxSM={indxSM}
                                updateProject={
                                  setCurrentProject
                                }
                                showErrorMessages={
                                  showErrorMessages
                                }
                                errorFieldsObj={errorObj}
                                isEditable={
                                  ValueChangeCheckInChild
                                }
                                disableFields={
                                  location.state
                                }
                                callFrom= "timecost"
                              />                          
                            ))}
                            </Col>
                          </Row>
                          
                          ))} 
            <Row style={{ position: "sticky", zIndex: "1", top: "70px" }}>
            <Col
                md={12}
                className={"countryTabs tab-vertical"}
                style={{ height: "90px" }}
              >
                <div className={"tab mt-2"}>
                  <Container style={{width:"100%", marginLeft: "80px"}}>
                    <TabContent activeTab={activeTabFC}>
                      {profile.CountrySpecs?.map(
                        (fc, indxFC) =>
                          activeTabFC === indxFC && (
                            <>
                              <TabPane tabId={indxFC} key={fc.value}>
                                <Row>
                                  <Col md={12} className={"methodologyTabs"}>
                                    <Nav tabs>
                                      {fc.MethodologySpecs?.[
                                        activeTabSM
                                      ].CostingOptions.map(
                                        (costingOption, indxSM) => {
                                          return (
                                            <NavItem key={indxSM.value}>
                                              <NavLink
                                                className={classnames({
                                                  active: activeTabCO === indxSM,
                                                })}
                                                disabled={
                                                  timecostView === "commercial"
                                                    ? costingOption
                                                        .CostingOptionData
                                                        .Commericalsupport ===
                                                      "No"
                                                    : costingOption
                                                        .CostingOptionData
                                                        .operationpm === "No"
                                                }
                                                onClick={() => {
                                                  toggleCO(indxSM);
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <div className="hoverEffect">
                                                    <b>
                                                      {costingOption.label
                                                        ? costingOption.label
                                                        : costingOption.Name}
                                                    </b>
                                                  </div>
                                                </div>
                                              </NavLink>
                                            </NavItem>
                                          );
                                        }
                                      )}
                                    </Nav>
                                  </Col>
                                </Row>
                              </TabPane>
                            </>
                          )
                      )}
                    </TabContent>
                  </Container>
                </div>
              </Col>
            </Row>                              
            <Row className="mt-2 mb-2">
              <Col md={6}>
                <h3 style = {{marginLeft: "160px"}}>Product: {profile?.CountrySpecs?profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[activeTabCO]
            .CostingOptionData.producttype:null}</h3>
              <h3 style = {{marginLeft: "160px"}}>WBS Number: {getWBSnumber()}</h3>
              </Col>
              <Col md={4}>
                  <h4>Currency Selection</h4>
                    <Select
													// isDisabled={profile.ProfileStatus > 1}
													placeholder="Select a Currency..."
													options={currencies?.map((c) => {
														return {
															value: `${c.Code}-${c.CurrencyUnit}`,
															label: `${c.Label} (1USD = ${c.ConversionRateToLocal}${c.CurrencyUnit})`,
														};
													})}
                          value={OPScurrencyDropdown?OPScurrencyDropdown:null}
                          onChange={handleOPSCurrencyChange}
										/>
              </Col>
            </Row>
            <Container
              style={{
                width: "50%",
                marginLeft: "160px",
                backgroundColor: "white",
              }}
            >
              <Table responsive hover striped bordered size="sm">
                <tbody>
                  <tr style={{ fontWeight: "bold", fontSize: "15px" }}>
                    <td>Summary</td>
                  </tr>
                  {
                    <tr style={{ fontWeight: "bold", fontSize: "15px" }}>
                      <td>Total Operations Time Cost ({OPSCurrencyUnit})</td>
                      <td>{(totalOpsCost*OPSCurrencyConversion)?.toFixed(2)}</td>
                    </tr>
                  }
                </tbody>
              </Table>
            </Container>
            <CostingMarketTable
              activities={activitiesForOPS}
              band={OPSband}
              role={role}
              activeTabSM={activeTabSM}
              activeTabCO={activeTabCO}
              activeTabFC={activeTabFC}
              teamDataOptions={OPSTeamDataOptions}
              tableData={_.cloneDeep(opsCostData)}
              defaultRates={defaultRates}
              costingOptionID={ profile.CountrySpecs !== undefined ?
                profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
                  ?.CostingOptions[activeTabCO]?.id : ""                
              }
              CurrencyConversion={OPSCurrencyConversion}
            />
            {opsCostData && profile.CountrySpecs &&
              opsCostData[
                profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
                  ?.CostingOptions[activeTabCO]?.id
              ]?.map((opsData, index) => (
                <>
                  <hr />
                  <Container style={{ marginLeft: "140px" }}>
                    <Row>
                      <Col
                        md={4}
                        style={{
                          marginLeft: "20px",
                          maxWidth: "287px !important",
                        }}
                      >
                        <h4>Select Operation Project Team {`${index + 1}`}</h4>
                      </Col>
                      <Col md={5} style={{ marginLeft: "-50px" }}>
                        <Select
                          options={OPSTeamDataOptionsTemp}
                          value={OPSTeamDataOptions.filter(function (option) {
                            return option.label === Object.keys(opsData)[0];
                          })}
                          onChange={(e) => {
                            handleSelectChange(e, index);
                          }}
                          isDisabled={
                            !localSupportModalOPS ||
                            !localSupportOPS ||
                            project.PriceChange == 3 ||
                            profile.ProfileStatus === "6" ||
                            profile.ProfileStatus === "99" ||
                            project.ProjectStatus == 3||
                            getProductName().includes("EXPENSE RECHARGE")
                          }
                        />
                      </Col>
                      <Col>
                        {index >= 2 && (
                          <Button
                            style={{
                              width: "80px",
                            }}
                            color="primary"
                            onClick={() => {
                              deleteOPSTable(
                                profile.CountrySpecs[activeTabFC]
                                  .MethodologySpecs[activeTabSM]
                                  ?.CostingOptions[activeTabCO]?.id,
                                index
                              );
                            }}
                            //  disabled={project.PriceChange == 3 || profile.ProfileStatus === "6" || profile.ProfileStatus === "99" || project.ProjectStatus == 3}
                          >
                            Delete
                          </Button>
                        )}
                      </Col>
                      <Col md={2}>
                        <Button
                          style={{
                            width: "80px",
                            marginLeft: "-43px",
                          }}
                          //  onClick={clearOPSTable}
                          color="primary"
                          onClick={() => {
                            clearOPSTable(Object.keys(opsData)[0]);
                          }}
                          //  disabled={project.PriceChange == 3 || profile.ProfileStatus === "6" || profile.ProfileStatus === "99" || project.ProjectStatus == 3}
                        >
                          Clear
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                  <TimeCostTable
                    reduxData = {_.cloneDeep(opsCostData)}
                    costingOptionID={
                      profile.CountrySpecs[activeTabFC].MethodologySpecs[
                        activeTabSM
                      ]?.CostingOptions[activeTabCO]?.id
                    }
                    activities={activitiesForOPS}
                    band={OPSband}
                    role="OPS"
                    inputCountry={Object.keys(opsData)[0]}
                    rowIndex={index}
                    tableData={Object.values(opsData)[0]}
                    localSupport={localSupportOPS}
                    localModalSupport={localSupportModalOPS}
                    defaultRates={defaultRates}
                    countryOptions={OPSTeamDataOptions}
                    isClearTable={isClear}
                    setIsClear={setIsClear}
                    costData = {_.cloneDeep(operationsCostData)}
                    setAlert={setAlert}
                    CurrencyUnit={OPSCurrencyUnit}
                    CurrencyConversion={OPSCurrencyConversion}
                    productName={getProductName()}
                  />
                </>
              ))}    

              {(isNewProject || showProductValidation) && <><hr /> <Container 
                style={{width: "100%",
                  marginLeft: "100px"
                }} 
              >
                <Row style={{ position: "sticky", zIndex: "1", top: "70px" }}>
                  <Col
                      md={12}
                      className={"countryTabs tab-vertical"}
                      style={{ height: "180px" }}
                    >
                  <div className={"tab mt-2"}>                
                    
                    {/* <Card className="ml-2 mr-2">
                      <CardHeader>                        
                        <CardTitle id="reason_timecost" className="mb-0" style={{ fontSize: '1.5rem', fontWeight: 'bold', margin: '0'}}> */}
                        <span style={{fontSize: "18px", fontWeight: "bold"}}>
                          Internal time cost input product level validation
                        </span>
                        <br/>
                        {/* </CardTitle>
                        <CardSubtitle style={{fontSize: '1.2rem', color: 'black'}}> */}
                        <span style={{fontSize: "16px"}}>
                          For each product line, associate the internal time cost or provide a reason if it is zero.
                        </span>
                        {/* </CardSubtitle>                          
                      </CardHeader> */}
                      {/* <CardBody> */}
                        <Table>
                          <thead>
                            {tableHeaders.map((tv) => (
                              <th>
                                {tv}
                              </th>
                            ))}
                          </thead>
                          <tbody>
                            {
                              getInternalCost()?.map(tv => (
                                <tr>
                                  <td>
                                    {tv?.Productname}
                                  </td>
                                  <td>
                                    {tv.WBSNumber}
                                  </td>
                                  <td>
                                    {tv.Label}
                                  </td>
                                  <td>
                                    Operations
                                  </td>
                                  <td>
                                    { getTotalOpsCost(tv.OpportunityLineItemID).toFixed(2)}
                                  </td>
                                  <td>
                                    {(getTotalOpsCost(tv.OpportunityLineItemID)*OPSCurrencyConversion).toFixed(2)} 
                                  </td>
                                  <td>
                                    <Input 
                                      type="textarea" 
                                      value={getTotalOpsCost(tv.OpportunityLineItemID) > 0 
                                        ? opsTimecostReason[tv.OpportunityLineItemID] = "" 
                                        : opsTimecostReason[tv.OpportunityLineItemID]} 
                                      disabled={getTotalOpsCost(tv.OpportunityLineItemID) > 0} 
                                      onChange={(e)=>handleTimecostReasonOPS(e,tv.OpportunityLineItemID)}
                                    />
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </Table>
                      {/* </CardBody>
                    </Card> */}
                  </div>
                  </Col>
                </Row>
              </Container></>}         
            <Prompt
              when={alert}
              message='Clicking on "OK" will navigate to next page without saving data '
            />
            <InfoModal
              infoModal={infoModal}
              infoModalToggle={infoModalToggle}
              activityMapping={activityMapping}
              currentActiveTab={currentActiveTab}
              toggleTabPane={toggleTabPane}
            />
            <Row>
              <Col className="justify-content-end">
              <Button style={{top:"75%", zIndex: "2"}}
                  className="validateandsave costing-profile-btn"
                  color="primary"
                  onClick={saveTableData}
                  disabled={project.PriceChange == 3 || profile.ProfileStatus === "6" || profile.ProfileStatus === "99" || project.ProjectStatus == 3}
                >
                  <FontAwesomeIcon
                    className="icon-costingprofile"
                    icon={faSave}
                    fixedWidth
                  />
                  {isNewProject ? `Validate & Save` : `Save`}
                </Button>
              </Col>
            </Row>

            <Row>
              <Col className="justify-content-end">
                <Button
                  style={{ top: "45%", zIndex: "2" }}
                  className="copy-to-all-market costing-profile-btn"
                  color="primary"
                  onClick={() => {
                    setInfoModal(true);
                  }}
                >
                  <FontAwesomeIcon
                    className="icon-costingprofile"
                    icon={faInfoCircle}
                    fixedWidth
                  />
                  Activities info
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="justify-content-end">
                <Button
                  style={{ top: "65%", zIndex: "2" }}
                  className="savedraft costing-profile-btn"
                  color="primary"
                  onClick={() => {
                    copyCountriesDataOps();
                  }}
                >
                  <FontAwesomeIcon
                    className="icon-costingprofile"
                    icon={faCopy}
                    fixedWidth
                  />
                  Copy Operations tables
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="justify-content-end">
                <Button
                  style={{ top: "35%", zIndex: "2" }}
                  className="copy-to-all-market costing-profile-btn"
                  color="primary"
                  onClick={() => {
                    settimecostViewModal(true);
                  }}
                >
                  <FontAwesomeIcon
                    className="icon-costingprofile"
                    icon={faPencilAlt}
                    fixedWidth
                  />
                  Edit Level of Input
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="justify-content-end">
                <Button
                  style={{ top: "55%", zIndex: "2" }}
                  className="copy-to-all-market costing-profile-btn"
                  color="primary"
                  onClick={addNewCostingRow}
                >
                  <FontAwesomeIcon
                    className="icon-costingprofile"
                    icon={faPlusCircle}
                    fixedWidth
                  />
                  Add Costing Market
                </Button>
              </Col>
            </Row>
            <TimeCostModal isOpen={timecostViewModal} isClose={updateModal} />
          </div>
        )}
        <Modal
          isOpen={showModal}
          toggle={handleClose}
          style={{ height: "30px" }}
        >
          <ModalHeader toggle={handleClose}>Clear Table Data</ModalHeader>
          <ModalBody>Are you sure to clear table data?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => handleOk()}>
              Ok
            </Button>
            <Button color="secondary" onClick={() => handleClose()}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={showDeleteModal}
          toggle={handleCloseModal}
          style={{ height: "30px" }}
        >
          <ModalHeader toggle={handleClose}>Delete Table</ModalHeader>
          <ModalBody>Are you sure to Delete table?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => handleOKModal()}>
              Ok
            </Button>
            <Button color="secondary" onClick={() => handleCloseModal()}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal className="modal-with-header" isOpen={isModalOpen} toggle={() => handleOpen()} size="lg">
        <ModalHeader
          // className="modal-header-error"
          toggle={() => handleOpen(!isModalOpen)}
        >
          {/* <img src={logo} style={imgStyle} className="modallogo" /> */}

        </ModalHeader>

        <ModalBody id="scrollModalBody" className="modal-body-error-header">
          <div>
            <span className="modaltitle modalvalidationheader " style = {{color:"red"}}>
              {validationHeader}
            </span>
          </div>
          {/* <p>
            <b>
              Key Parameters information is missing at cost option level for
              following Country and Methodologies
            </b>
          </p> */}
          <br></br>
          <br></br>
          {Object.keys(errorObj)?.length ? (
            <>
            <div className="col-md-12">
              <Table id="errorTable">
              <thead>
                              <tr>
                                <th>Country</th>
                                <th>Methodology</th>
                                <th>Mandatory fields requiring input</th>
                              </tr>
                            </thead>

              {Object.keys(errorObj).map(function (key) {
                return (
                  <>

                      {((errorObj[key]?.CostingOptions &&
                      Object.keys(errorObj[key]?.CostingOptions).length) || 
                      (errorObj[key]?.RFQ &&
                        Object.keys(errorObj[key]?.RFQ).length) || 
                        (errorObj[key]?.SD &&
                          Object.keys(errorObj[key]?.SD).length)) ? (
                        <>
                              <tbody>
                              {Object.keys(errorObj[key]).includes("CostingOptions") ? Object.keys(errorObj[key]?.CostingOptions).map(
                                  function (key1) {
                                    return (
                                      <tr>
                                        {((Object.keys(errorObj[key]).includes("CostingOptions") && Object.entries(
                                          errorObj[key]?.CostingOptions[key1]
                                        ).length > 0) || (Object.keys(errorObj[key]).includes("RFQ") && 
                                        (Object.entries(errorObj[key]?.RFQ).some((  [key,value]   ) => key == key1  ))
                                         && Object.entries(
                                          errorObj[key]?.RFQ[key1]
                                        ).length > 0) || (Object.keys(errorObj[key]).includes("SD") && (Object.entries(errorObj[key]?.SD).some((  [key,value]   ) => key == key1  )) &&Object.entries(
                                          errorObj[key]?.SD[key1]
                                        ).length > 0)) && (
                                          <>
                                            <td>{key}</td>
                                            <td>{key1} </td>
                                            <td style = {{color:"red"}}>
                                              {errorObj[key]?.CostingOptions &&
                                              Object.keys(errorObj[key]?.CostingOptions[key1]).length ?
                                               errorObj[key]?.CostingOptions[
                                                key1
                                              ].map((item,index)=>(
                                                <div key1 ={index}>{item}</div>
                                )) : null}

                                              {errorObj[key]?.RFQ &&
                                              (Object.entries(errorObj[key]?.RFQ).some((  [key,value]   ) => key == key1  )) &&
                                                Object.keys(errorObj[key].RFQ[key1]).length ? 
                                                errorObj[key].RFQ[key1].map((item,index)=>(
                                                  <div key1 ={index}>{item}</div>
                                  )) : null}

                                              {errorObj[key]?.SD &&
                                              (Object.entries(errorObj[key]?.SD).some((  [key,value]   ) => key == key1  )) &&
                                                Object.keys(errorObj[key].SD[key1]).length ?
                                                errorObj[key].SD[key1]
                                                .map((item,index)=>(
                                                  <div key1 ={index}>{item}</div>
                                  )): null}
                                            </td>
                                          </>
                                        )}
                                      </tr>
                                    );
                                  }
                                ) : Object.keys(errorObj[key]).includes("RFQ") ? 
                                Object.keys(errorObj[key]?.RFQ).map(
                                  function (key1) {
                                    return (
                                      <tr>
                                        {((Object.keys(errorObj[key]).includes("CostingOptions") && Object.entries(
                                          errorObj[key]?.CostingOptions[key1]
                                        ).length > 0) || (Object.keys(errorObj[key]).includes("RFQ") && 
                                        (Object.entries(errorObj[key]?.RFQ).some((  [key,value]   ) => key == key1  )) && Object.entries(
                                          errorObj[key]?.RFQ[key1]
                                        ).length > 0) || (Object.keys(errorObj[key]).includes("SD") &&
                                        (Object.entries(errorObj[key]?.SD).some((  [key,value]   ) => key == key1  )) && Object.entries(
                                          errorObj[key]?.SD[key1]
                                        ).length > 0)) && (
                                          <>
                                            <td>{key}</td>
                                            <td>{key1} </td>
                                            <td style = {{color:"red"}}>
                                              {errorObj[key]?.CostingOptions &&
                                              Object.keys(errorObj[key]?.CostingOptions[key1]).length ?
                                               errorObj[key]?.CostingOptions[
                                                key1
                                              ].map((item,index)=>(
                                                <div key1 ={index}>{item}</div>
                                )) : null}

                                              {errorObj[key]?.RFQ &&
                                              (Object.entries(errorObj[key]?.RFQ).some((  [key,value]   ) => key == key1  )) &&
                                                Object.keys(errorObj[key].RFQ[key1]).length ? 
                                                errorObj[key].RFQ[key1].map((item,index)=>(
                                                  <div key1 ={index}>{item}</div>
                                  )) : null}

                                              {errorObj[key]?.SD &&
                                              (Object.entries(errorObj[key]?.SD).some((  [key,value]   ) => key == key1  )) &&
                                                Object.keys(errorObj[key].SD[key1]).length ?
                                                errorObj[key].SD[key1]
                                                .map((item,index)=>(
                                                  <div key1 ={index}>{item}</div>
                                  )) : null}
                                            </td>
                                          </>
                                        )}
                                      </tr>
                                    );
                                  }
                                ): Object.keys(errorObj[key]).includes("SD") ? 
                                Object.keys(errorObj[key]?.SD).map(
                                  function (key1) {
                                    return (
                                      <tr>
                                        {((Object.keys(errorObj[key]).includes("CostingOptions") && Object.entries(
                                          errorObj[key]?.CostingOptions[key1]
                                        ).length > 0) || (Object.keys(errorObj[key]).includes("RFQ") && (Object.entries(errorObj[key]?.RFQ).some((  [key,value]   ) => key == key1  )) && Object.entries(
                                          errorObj[key]?.RFQ[key1]
                                        ).length > 0) || (Object.keys(errorObj[key]).includes("SD") &&
                                        (Object.entries(errorObj[key]?.SD).some((  [key,value]   ) => key == key1  )) && Object.entries(
                                          errorObj[key]?.SD[key1]
                                        ).length > 0)) && (
                                          <>
                                            <td>{key}</td>
                                            <td>{key1} </td>
                                            <td style = {{color:"red"}}>
                                              {errorObj[key]?.CostingOptions &&
                                              Object.keys(errorObj[key]?.CostingOptions[key1]).length ?
                                               errorObj[key]?.CostingOptions[
                                                key1
                                              ].map((item,index)=>(
                                                <div key1 ={index}>{item}</div>
                                )) : null}

                                              {errorObj[key]?.RFQ &&
                                              (Object.entries(errorObj[key]?.RFQ).some((  [key,value]   ) => key == key1  )) &&
                                                Object.keys(errorObj[key].RFQ[key1]).length ? 
                                                errorObj[key].RFQ[key1].map((item,index)=>(
                                                  <div key1 ={index}>{item}</div>
                                  )) : null}

                                              {errorObj[key]?.SD &&
                                              (Object.entries(errorObj[key]?.SD).some((  [key,value]   ) => key == key1  )) &&
                                                Object.keys(errorObj[key].SD[key1]).length ?
                                                errorObj[key].SD[key1]
                                                .map((item,index)=>(
                                                  <div key1 ={index}>{item}</div>
                                  )) : null}
                                            </td>
                                          </>
                                        )}
                                      </tr>
                                    );
                                  }
                                ) : null
                              }



                              </tbody>

                          {/* </Table> */}
                        </>
                      ) : null}

                    {/* </div>  */}
                  </>
                );
               })}  
               </Table>
               </div>
            </>
          ) : null}
          {/* {Object.keys(errorObj)?.length ? (
            <>
              {Object.keys(errorObj).map(function (key) {
                return (
                  <>
                    <div className="displayCountry col-md-12">
                      <h4>
                        <b>{key} :</b>
                      </h4>
                    </div>
                    <div className="col-md-12">
                      {errorObj[key]?.CostingOptions &&
                      Object.keys(errorObj[key]?.CostingOptions).length ? (
                        <>
                          <p>
                            <b>Costing Options Section:</b>
                          </p>
                          <Table id="errorTable">
                            <thead>
                              <tr>
                                <th>Methodologies</th>
                                <th>Fields</th>
                              </tr>
                            </thead>
                            {
                              <tbody>
                                {Object.keys(errorObj[key]?.CostingOptions).map(
                                  function (key1) {
                                    return (
                                      <tr>
                                        {Object.entries(
                                          errorObj[key]?.CostingOptions[key1]
                                        ).length > 0 && (
                                          <>
                                            <td>{key1} </td>
                                            <td>
                                              {errorObj[key]?.CostingOptions[
                                                key1
                                              ].join(", ")}
                                            </td>
                                          </>
                                        )}
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            }
                          </Table>
                        </>
                      ) : null}
                    </div>

                    <div className="col-md-12">
                      {errorObj[key]?.RFQ &&
                      Object.keys(errorObj[key].RFQ).length ? (
                        <>
                          <p>
                            <b>RFQ Form Section:</b>
                          </p>
                          <Table id="errorTable">
                            <thead>
                              <tr>
                                <th>Methodologies</th>
                                <th>Fields</th>
                              </tr>
                            </thead>
                            {
                              <tbody>
                                {Object.keys(errorObj[key].RFQ).map(function (
                                  key1
                                ) {
                                  return (
                                    <tr>
                                      <td>{key1} </td>
                                      <td>
                                        {errorObj[key].RFQ[key1].join(", ")}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            }
                          </Table>
                        </>
                      ) : null}
                    </div>
                    {(_.includes(
                      pacificMarkets,
                      project.CommissioningCountry
                    )  && project.CostingTemplateVersion != "v2.0")? (
                      <div className="col-md-12">
                        {errorObj[key]?.SD &&
                        Object.keys(errorObj[key].SD).length ? (
                          <>
                            <p>
                              <b>Setup and Delivery Section:</b>
                            </p>
                            <Table id="errorTable">
                              <thead>
                                <tr>
                                  <th>Methodologies</th>
                                  <th>Wave Number</th>
                                  <th>Fields</th>
                                </tr>
                              </thead>
                              {
                                <tbody>
                                  {Object.keys(errorObj[key].SD).map(function (
                                    key1
                                  ) {
                                    return Object.keys(
                                      errorObj[key].SD[key1].NO
                                    ).map(function (number) {
                                      return (
                                        <tr>
                                          <td>{key1}</td>
                                          <td>{number} </td>
                                          <td>
                                            {errorObj[key].SD[key1].NO[
                                              number
                                            ].join(", ")}
                                          </td>
                                        </tr>
                                      );
                                    });
                                  })}
                                </tbody>
                              }
                            </Table>
                          </>
                        ) : null}
                      </div>
                    ) : (
                      <div className="col-md-12">
                        {errorObj[key]?.SD &&
                        Object.keys(errorObj[key].SD).length ? (
                          <>
                            <p>
                              <b>Setup and Delivery Section:</b>
                            </p>
                            <Table id="errorTable">
                              <thead>
                                <tr>
                                  <th>Methodologies</th>
                                  <th>Fields</th>
                                </tr>
                              </thead>
                              {
                                <tbody>
                                  {Object.keys(errorObj[key].SD).map(function (
                                    key1
                                  ) {
                                    return (
                                      <tr>
                                        <td>{key1} </td>
                                        <td>
                                          {errorObj[key].SD[key1].join(", ")}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              }
                            </Table>
                          </>
                        ) : null}
                      </div>
                    )}
                  </>
                );
              })}
            </>
          ) : null} */}
        </ModalBody>
        <ModalFooter>
        {/* <Button  color="primary" style = {{marginLeft:"300px"}}  onClick = {()=>handleOpen(!isModalOpen)}>
            Ok
          </Button> */}
          <Button color="secondary" className="ml-auto"  onClick={() => handleOpen(!isModalOpen)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
        <CopyOptionsModal
          mode={"OPS"}
          modal={opsModal}
          activeTabFC={activeTabFC}
          activeTabCO={activeTabCO}
          activeTabSM={activeTabSM}
          setModal={setOpsModal}
          setIsLoading={setIsLoading}
          selectedMethodologyData1={selectedMethodologyData1}
          activities={activitiesForOPS}
          band={OPSband}
          options={options}
          selectedCountryData={selectedCountryData}
        />
      </Main>
    </Wrapper>
  );
};

export default OperationsTimecost;