import React, { useState, useEffect, Suspense } from "react";
import XlsxPopulate from 'xlsx-populate';
import { connect, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import ReactQuill from "react-quill";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import * as userActions from "../redux/actions/userActions";
import * as timeCostActions from "../redux/actions/timeCostActions"
import TimeCostModal from "./TimeCostModal"
import * as currentProjectActions from "../redux/actions/currentProjectActions";

import * as currentCostingActions from "../redux/actions/currentCostingActions";
import * as countryActions from "../redux/actions/countrySpecsActions";
import {
  Fetchwaves,
  setProfileRequests,
} from "../redux/actions/requestsActions";
import update from "immutability-helper";
import RequestCreate from "../components/RequestCreate/RequestCreate";
import RequestViewModal from "../components/RequestViewModal/RequestViewModal";
import axios from "../axios-interceptor";
import CostingTypeMapping from "./CostingTypeMapping";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import _ from "lodash";
import { Link } from "react-router-dom";
import ApprovalDelegate from "./ApprovalDelegate";
import {
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  InputGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Button,
  UncontrolledButtonDropdown,
  Badge,
  Label,
  NavItem,
  NavLink,
  Table,
} from "reactstrap";

import { ChevronsRight, Settings, User } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faPencilAlt } from "@fortawesome/free-solid-svg-icons";

import avatar1 from "../assets/img/avatars/nielsen-logo.png";
import { toastr } from "react-redux-toastr";
import ModalCommissioning from "../pages/summary/ModalCommissioning";
import ModalPostCommission from "../pages/summary/ModalPostCommission";
import {
  pageLoadEnd,
  pageLoadStart,
  recordLoadEnd,
  recordLoadStart,
  setCostingStatus,
  localPageLoadStart,
  localPageLoadEnd,
} from "../redux/actions/appActions";
import Spinner from "../components/Spinner";
import { getLabel } from "../utils/codeLabels";
import { selectWaveSpec } from "../redux/actions/currentWaveSpecActions";
import * as sheetCall from "../utils/msSheetAPiCall";
import { SET_VENDOR_FLAG } from "../redux/actions/vendor/ActionTypes";

import TotalOPSOOPModal from "./TotalOPSOOPModal";
import Project from "../layouts/Project";
import UploadedProjectModal from "./UploadedProjectModal";
import Edit from "../assets/icons/Connected-System-Icons/Tools/Edit-fill";
import Hamburger from "../assets/icons/Connected-System-Icons/Navigation/Hamburger";
import logo from "../../src/assets/img/avatars/modal-logo.png";

import useAnalyticsEventTracker from "../utils/useAnalyticsEventTracker";
import * as EventName from "../utils/GoogleAnalyticsEvents";
import SelectWrapper from "./SelectWrapper/SelectWrapper";
import { useRef } from "react";
import { valueChangedInRFQ as valueChangedInRFQAction } from "../redux/actions/appActions";
import CostingMarkets from "../pages/proposalNew/CostingMarkets";
import { setClosedStatus, setNewNotification } from "../redux/actions/notificationActions";
import { Badge as NewBadge, IconButton } from "@material-ui/core";

const ModalDecommissioning = React.lazy(() =>
  import("../pages/summary/ModalDecommissioning")
);
const alasql = window.alasql;
var imgStyle = {
  width: "150px",
};
const SHOPPERS_METHODOLOGIS = ["SM000024", "SM000026", "SM000029"]
const NavbarComponent = (props) => {
  const gaEventTracker = useAnalyticsEventTracker(EventName.Actions);
  const localPageload = useSelector(({ app }) => app.localPageload);
  const recordloading = useSelector(({ app }) => app.recordloading);
  
  const project = useSelector(
    ({ currentProject }) => currentProject.newProject
  );
  const currentCostingProfile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  const costingStatus = useSelector(({ app }) => app.costingStatus);
  const app = useSelector(({ app }) => app);
  const waveSpecs = useSelector(({ waveSpecs }) => waveSpecs);
  const currentCountrySpecs = useSelector(
    ({ currentCountrySpec }) => currentCountrySpec
  );
  const currentWaveSpec = useSelector(({ currentWaveSpec }) => currentWaveSpec);
  const timecostView=useSelector(({timeCost})=>timeCost.selectedRole)
  const costingoptions=useSelector(({timeCost})=>timeCost.costingoptions)

  const userRecord = useSelector(({ user }) => user.userRecord);
  const [timecostViewModal,settimecostViewModal]=useState(false);
  const [editableProjectName, setEditableProjectName] = useState(false);
  const [editableCostingName, setEditableCostingName] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isCommissionOpen, setCommissionOpen] = useState({});
  const [isCostSelection, setCostSelection] = useState(false);
  const [isDisableDirect1, setisDisableDirect1] = useState([]);
  const [isDisableSheet1, setisDisableSheet1] = useState([]);
  const [isDisableVendor1, setisDisableVendor1] = useState([]);
  const [cmlistselected1, setcmlistselected1] = useState([]);
  const [updateCost, setUpdateCost] = useState();
  const [notes, setNotes] = useState("");
  const [isOpenRequest, setIsOpenRequest] = useState(false);
  const [isOpenViewRequest, setIsOpenViewRequest] = useState(false);
  const valueChangedInRFQ = useSelector(({ app }) => app.valueChanged);
  const [costingmarketModal,setCostingmarketModal]=useState(false)

  const [showCostMethod, setShowCostMethod] = useState(false);
  const [salesForceModal, openSaleForceModal] = useState(false);
  //download summary- start
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const Vendorflag = useSelector(
    ({ vendorReducer }) => vendorReducer.vendorFlag
  );
  const [calledCurrencies, setCalledCurrencies] = useState(false);
  const [rfqData, setRfqData] = useState({});
  const [opsData, setOpsData] = useState({});
  const [costFields, setCostFields] = useState({});
  const [costFieldsByCostingOption, setCostFieldsByCostingOption] = useState(
    {}
  );
  const [downloadModal, openDownloadModal] = useState(false);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [bands, setBands] = useState([]);
  const [preValue, setPreValues] = useState([]);
  const [schema, setSchema] = useState({});
  const [haveCostingOptions, setHaveCostingOptions] = useState(false);

  const [openCommercialWarning, setOpenCOmmercialWarning] = useState(false);
  const [openOverrideAutoCosts, setOpenOverrideAutoCosts] = useState(false);

  const [openEditLevelPopUp, SetOpenEditLevelPopup] = useState(false);
  const [canOpenRequest, setCanOpenRequest] = useState(false);
  const [rfqWarningOpened, setRFQWarningOpen] = useState(false);
  const [opsWarningOpened, setOpsWarningOpen] = useState(false);
  const [isModalOpen, handleOpen] = useState(false);
  const [validationHeader, setValidationHeader] = useState("");
  const [shopperMethodologies] = useState(["SM000024", "SM000026", "SM000029"]);
  const [unsavedChangesAlert, setUnsavedChangesAlert] = useState(false);
  const [openCostingSummary, setOpenCostingSummary] = useState(false);
  const [overheadsPercentage, setOverheadsPercentage] = useState(0);
  var value;

     let methodologyMapping={
      'B2B-FTF': 'B2B FTF',
      'CLT IA With Venue (for CLT Interviewer-administered With Venue) / CLT With venue': 'CLT IA With Venue',
      'CLT No Venue - Exit Intercept': 'CLT No Venue Exit Intercept',
      'CLT No Venue - In-Store Intercept': 'CLT No Venue In Store',
      'CLT No Venue - SI/Places of Work/Leisure': 'CLT Street Places And Leisure',
      'CLT SA With Venue': 'CLT SA With Venue',
      'Desk Research': 'Desk Research',
      'Distribution Check': 'Distribution Check',
      'Door-to-Door': 'Door To Door',
      'In-Store Observation/Respondent (Pre & Post Interviews)': 'In Store And Respondent',
      'Mail (post)': 'Mail_post',
      'Mystery Shopping / Mystery Calling': 'Mystery Shopping Or Calling',
      'Observation (no interviews)': 'Observation',
      'Online CLT Self-Administrated': 'Online CLT Self Administered',
      'Pack Collection': 'Pack Collection',
      'Telephone / B2B-TEL': 'B2B Telephone',
      'Traffic Count': 'Traffic Count',
      'SMM (Online + Hardcopy Mailout)': 'Sequential Mixed Methodology',
      'Online Self Completion Survey': 'Online Survey',
      'Path Tracking': 'Path Tracking',
      'Eye Tracking': 'Eye Tracking',
      'Qual-Digital (Whats App etc.)': 'Qual Digital',
      'Qual-Digital (Whats App, Skype, etc.)': 'Qual Digital',
      'Qual-Ethnography': 'Qual Ethnography',
      'Qual-Focus Group': 'Qual Focus Group',
      'Qual-In-Depth Interview': 'Qual In Depth Interview',
      'Qual-In-Home Interview': 'Qual In Home Interview',
      'Qual-Accompanied Shopping': 'Qual Accompanied Shopping',
      'Qual-Mini Focus Groups': 'Qual Mini Focus Groups',
      'Qual-NeuroFocus': 'Qual NeuroFocus',
      'Qual-Online Focus Group': 'Qual Online Focus Group',
      'Qual-Online In-Depth Interview': 'Qual Online InDepth Interview',
      'Qual-Online Mini Focus Groups': 'Qual Online Mini Focus Groups',
      'Qual-Work Shop': 'Qual Work Shop',
      'Smart Store VR': 'Smart Store VR',
      'Consumption Moments': 'Consumption Moments',
      'Virtual Shopper': 'Virtual Shopper',
      'Social 360': 'Social 360',
      'Smart E-Store': 'Smart E-Store'
    }
  const user = useSelector(({ user }) => user);
  // oops opp pop up
  const [opsOpp, setOpsOpp] = useState(false);
  // ------
  // uploadedproject pop up
  const [uploadProject, setUploadProject] = useState(false);
  let pacificData = _.head(codeLabels.PacificCountriesList).Label;
  let arr = pacificData.split(",");
  const [pacificMarkets] = useState(arr);
  // const [pacificMarkets] = []
  const CostingTypesEnum = {
    INPUTDIRECTLY: 1,
    MSSHEETS: 2,
    VENDORBIDDING: 3,
  };
  const exportToExcel = async ([opts, data, sharepointExcel]) => {
    const workbook = await XlsxPopulate.fromBlankAsync();

    let methRowIndex = [];
    opts.forEach((sheetName, index) => {
      
        // set sheet name
        let worksheet;
        // let wscols = [];
  
        // if (index ===0) {
        //   worksheet = workbook.sheet(0).name(sheetName.sheetid)
        // } else {
          worksheet = workbook.addSheet(sheetName.sheetid)
        // }
        // const data = datas[index];
        const headers = Object.keys(data[index][0]);
        headers.forEach((header, colIndex) => {
          worksheet.cell(1, colIndex + 1).value(header);
          worksheet.column(colIndex + 1).width(30)
        })
        data[index].forEach((row, rowIndex) => {
            Object.keys(row).forEach((key, colIndex) => {
                worksheet.cell(rowIndex +2, colIndex + 1).value(row[key]);
                if (sheetName.sheetid == "Methodologies" && row[key] === "") {
                  methRowIndex.push(rowIndex+2);
                }
            });
        });
        // style header
        worksheet.range(`A1:${String.fromCharCode(64 + headers.length)}1`).style({ 
          fill: { type: 'solid', color: '000000' }, // Black background
          fontColor: 'FFFFFF', // White text
          bold: true });
  
        // style row
        worksheet.usedRange().forEach((row, rowIndex) => {
          if (rowIndex >0) {
            if (sheetName.sheetid == "Methodologies" && methRowIndex.includes(rowIndex+1)) {
              const backgroundColor = 'ffff00';
              row.style({ fill: {type: 'solid', color: backgroundColor}, fontColor: '000000'})
            } else {
              const backgroundColor = rowIndex % 2 !== 0 ? 'FFFFFFFF' : 'FFD3D3D3';
              row.style({ fill: {type: 'solid', color: backgroundColor}, fontColor: '000000'})
            }
          }
        })
  
        
    })

    if (sharepointExcel) {
      let sheetsData = sharepointExcel.data.result;
      let methodology=[]
      profile?.CountrySpecs[0]?.MethodologySpecs.map((method)=>{
        methodology.push(methodologyMapping[method.Label])
      })
      methodology.push("Summary")
       
      // Function to calculate total length of costing options

          // Iterate through countrySpecs if present
        

          sheetsData.forEach((sheetdata, index) => {
            if(sheetdata!==null)
            {
              const sheetName = methodology[index];
              const worksheet = workbook.addSheet(sheetName);
              let indices = [];
              let indicesSummary = [];
              let totalLength = 0;
              if (profile.CountrySpecs) {
                profile.CountrySpecs.forEach(countrySpec => {
                  // Iterate through methodologySpecs if present
                  if (countrySpec.MethodologySpecs) {
                    countrySpec.MethodologySpecs.forEach((methodologySpec, i) => {
                      // Check and add the length of costingOptions
                      if (methodologySpec.CostingOptions && i == index) {
                        totalLength += methodologySpec.CostingOptions.length;
                      }
                    });
                  }
                });
              }
        
          sheetdata.values.forEach((row, rowIndex) => {
            if (rowIndex <= 201) {
              row.forEach((cell, colIndex) => {
                  if(sheetName !== "Summary" && colIndex !== 1 && rowIndex !== 0) {
                    if (colIndex <= totalLength+1) {
                      colIndex !== 0 ? worksheet.cell(rowIndex , colIndex ).value(cell) : worksheet.cell(rowIndex , colIndex +1).value(cell)
                    colIndex == 0 ? worksheet.column(colIndex + 1).width(60) : worksheet.column(colIndex ).width(22)
                    if (rowIndex == 1 && colIndex !== 0) {
                        worksheet.cell(rowIndex , colIndex ).style({ fill: {type: 'solid', color: "0d0d0d"}, fontColor: 'ffffff'})
                    }
                    if (cell == "GRAND TOTAL OF SELECTED COST OPTIONS" || cell == "COSTING SUMMARY"){
                      indices.push(rowIndex-1)
                    }
                 if(colIndex > 0) {
                      worksheet.column(colIndex).style({horizontalAlignment:'right'})
                    }
                    }
                   } else if (sheetName == "Summary") {
                    worksheet.cell(rowIndex +1, colIndex +1).value(cell);
                    colIndex == 0 ? worksheet.column(colIndex + 1).width(60) : worksheet.column(colIndex + 1).width(20)
                    if (cell == "All Markets") {
                      indicesSummary.push(rowIndex)
                    }
                    // if(colIndex == 0) {
                    //   worksheet.column(colIndex + 1).style({bold:true})
                    // }
                  }
                // if(sheetName !== "Summary" && colIndex == 1) {
                //   worksheet.column(colIndex + 1).delete()
                // }
              // }
              })
            }
          })
          // if (workbook.sheet('Sheet1')) {
          //   workbook.deleteSheet('Sheet1');
          // }
                  // style row
                  worksheet.usedRange().forEach((row, rowIndex) => {
                      if (sheetName == "Summary" ) {
                        if (rowIndex == 0 && row._value.includes("USD")) {
                          const backgroundColor = 'ffff00';
                        row.style({ fill: {type: 'solid', color: backgroundColor}, fontColor: 'ff0000', bold:true})
                        } else if (rowIndex == 1) {
                          // row.style({ bold:true})
                          const backgroundColor = rowIndex % 2 == 0 ? 'FFFFFFFF' : 'FFD3D3D3';
                          row.style({ fill: {type: 'solid', color: backgroundColor}, fontColor: '000000'})
                        } else if (rowIndex !== 0 ) {
                          const backgroundColor = rowIndex % 2 == 0 ? 'FFFFFFFF' : 'FFD3D3D3';
                          row.style({ fill: {type: 'solid', color: backgroundColor}, fontColor: '000000'})
                        }
                        
                        if (indicesSummary.includes(rowIndex)) {
                          row.style({ fill: {type: 'solid', color: "0d0d0d"}, fontColor: 'ffffff'})
                        }
                        

                      } else {
                          // if (rowIndex == 0  && row._value.includes("Note")) {
                          //   const backgroundColor = 'ffff00';
                          // row.style({ fill: {type: 'solid', color: backgroundColor}, fontColor: 'ff0000', bold:true})
                          // } else
                           if (rowIndex !== 0 ) {
                            if (indices.includes(rowIndex)) {
                              row.style({ fill: {type: 'solid', color: "0d0d0d"}, fontColor: 'ffffff'})
                            } else {
                              const backgroundColor = rowIndex % 2 == 0 ? 'FFFFFFFF' : 'FFD3D3D3';
                              row.style({ fill: {type: 'solid', color: backgroundColor}, fontColor: '000000'})
                            }
                          } 
                      }

                  })

        }                
      })
    }
    if (workbook._sheets.length > 1 && workbook.sheet('Sheet1')) {
      workbook.deleteSheet('Sheet1');
    }
  
    //export
    let filename = `${currentCostingProfile.Project?.ProjectId}${
      currentCostingProfile.Project.ProjectName
        ? `_${currentCostingProfile.Project.ProjectName}`
        : ""
    }_${currentCostingProfile.ProfileNumber}${
      currentCostingProfile.ProfileName
        ? `_${currentCostingProfile.ProfileName}`
        : ""
    }_summary`;

    // console.log("workbook", workbook);
    const buffer = await workbook.outputAsync();
    const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
    const url = URL.createObjectURL(blob);
  
    const a = document.createElement('a');
    a.href = url;
    a.download = `${filename}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  const pendingProcessRef = useRef();

  const getLabelForCode = (key) => {
    let costLabel = key;
    codeLabels["CostLabelsOptions"].forEach((codeLabel) => {
      if (codeLabel.Code == key) {
        costLabel = codeLabel.Label;
      }
    });
    return costLabel;
  };
console.log(timecostViewModal,"timecost")


const validateProfile=()=>{
  let valid=true
  currentCostingProfile.CountrySpecs.map((country)=>{
    country.MethodologySpecs.map((method)=>{
      method.CostingOptions.map((co,index)=>{
        if(co.OpportunityLineItemID===null || !("OpportunityLineItemID" in co))
        { 
          valid=false
        }
       
      })
    })
  })
  return valid;
}


const updateCS=(role)=>{
    let validate=validateProfile()
    if(!validate)
    {
      toastr.error("Please click on every methodology/market tab and create the cost options and save it before moving to time cost input")
      return;
    }
    let methodologyspectable1=currentCostingProfile.CountrySpecs.map((country)=>{
      return (country.MethodologySpecs.map((ms)=>{
          return ms.CostingOptions.map((co)=>{
             return {
            methodology:ms.Label,
             Costingoptionlabel:co.label||co.Name,
             costingoptionid:co.id,
             OpportunityLineItemID:co.OpportunityLineItemID.toString(),
             Country:country.CountryName
           }
         })
       })).flat()
    })
      let costing=methodologyspectable1.map((cty)=>{
        let oopId=new Set()
        return cty.map((data)=>{
            if(!oopId.has(data.OpportunityLineItemID)){
                oopId.add(data.OpportunityLineItemID)
                return data.costingoptionid
            }
           
          })
         })
        let cos=costing.flat().flat().filter((data)=>{
          return data!=undefined
        })
        console.log(cos,"wbslevel")

        // setwbslevel(cos)
        let tempProfile=_.cloneDeep(currentCostingProfile)
        tempProfile?.CountrySpecs?.map((cs) => {
          return cs?.MethodologySpecs.map((ms) => {
             ms.CostingOptions.forEach((co, indexCO) => {
    
              if(currentCostingProfile.CSInput!==null){
                if(cos.includes(co.id)||currentCostingProfile.CSInput[co.OpportunityLineItemID]==='optionlevel'){
                   co["CostingOptionData"]={...co["CostingOptionData"],
                  "Commericalsupport" : "Yes"
                  }
                }
                else{
                    co["CostingOptionData"]={...co["CostingOptionData"],
                    "Commericalsupport" : "No"
                    }
                }
              }
              if(currentCostingProfile.OpsInput!==null){
                if(cos.includes(co.id)||currentCostingProfile.OpsInput[co.OpportunityLineItemID]==='optionlevel'){
                  co["CostingOptionData"]={...co["CostingOptionData"],
                  "operationpm" : "Yes",
                  "operationalpm" : "Yes"
                 }
               }
               else{
                 co["CostingOptionData"]={...co["CostingOptionData"],
                 "operationpm" : "No",
                          "operationalpm" : "Yes"
                 }
               }
              
              }
              })               
            });
    
          })
          dispatch(currentCostingActions.updateProfile(tempProfile));
          dispatch(currentCostingActions.saveCostingProfileDraft(tempProfile,()=>{
            if(role==="CS") history.push("/internalCostCommercial");
            else if(role==="OPS") history.push("/internalCostOperations");
          }));

}
  

  //For Time Cost Data Export
  
  let csvstring=''
  let csvstring1=''
  function convertToCSV(arr) {
    const array = [Object.keys(arr[0])].concat(arr)
    return array.map(it => {
      return Object.values(it).toString()
    }).join('\n')
  }
  function arrofarrays(arr){
    // for ()
    var array=[]
    array = [Object.keys(arr[0])].concat(array)
    for (let i of arr){
      array.push(Object.values(i))
  }
    return array;
  }
  const updateInputLevel=(role)=>{
    const unique=new Set();
    let Opportunitytable=currentCostingProfile.ProductTypeData.filter((obj)=>{
      if(!unique.has(obj.OpportunityLineItemID)){
          unique.add(obj.OpportunityLineItemID)
          return true
      }
      return false;
 
    })
    let tempProfile=_.cloneDeep(currentCostingProfile)
    tempProfile?.CountrySpecs?.map((cs) => {
      return cs?.MethodologySpecs.map((ms) => {
        ms.CostingOptions.forEach((co, indexCO) => {
          return co["CostingOptionData"]={...co["CostingOptionData"],
          "Commericalsupport" : "Yes",
          "operationpm" : "Yes",
          "operationalpm" : "Yes"
          }
      
        })                            
      });
    })
    let inputObj={}
    let inputdata=Opportunitytable.map((data)=>{
        inputObj[data.OpportunityLineItemID]="optionlevel"
        return{
          inputObj
        }
    })

    tempProfile={
      ...tempProfile,
      CSInput:inputObj,
      OpsInput:inputObj
    }
    dispatch(currentCostingActions.saveCostingProfileDraft(tempProfile,()=>{
      if(role==="CS") history.push("/internalCostCommercial");
      else if(role==="OPS") history.push("/internalCostOperations");
    }));
 
  }
  const TimeCostDataExport =()=>{

    toastr.info(`Downloading Time Cost Data Export `)
 
     axios.get(`/reports/timeCostExport/${props.project.ProjectId}`).then((res)=>{
         if (res.data[0].length) {
         
           if(res.data[1].length>=1){
                const workbook=XLSX.utils.book_new()
                csvstring=arrofarrays(res.data[0])
                const sheet1=XLSX.utils.aoa_to_sheet(csvstring)
                XLSX.utils.book_append_sheet(workbook,sheet1,`${props.project.ProjectId}-NewActivities`)
                csvstring1=arrofarrays(res.data[1])
                const sheet2=XLSX.utils.aoa_to_sheet(csvstring1)
                XLSX.utils.sheet_add_aoa(sheet2,[['Please note that the time costs on this tab are coming from the old time cost activities grouping input as a backup reference, any update input on the new time cost activities grouping will not be reflected on this tab']],{origin:-1})
                XLSX.utils.book_append_sheet(workbook,sheet2,`${props.project.ProjectId}-OldActivities`)
                const excelBlob =XLSX.write(workbook,{bookType:'xlsx',type: 'array'})
                const data = new Blob([excelBlob]);
                saveAs(data,`Time cost data export-${props.project.ProjectId}.xlsx`)
           }
           else{
              const workbook=XLSX.utils.book_new()
              csvstring=arrofarrays(res.data[0])

              const sheet1=XLSX.utils.aoa_to_sheet(csvstring)
              XLSX.utils.book_append_sheet(workbook,sheet1,`${props.project.ProjectId}`)
              const excelBlob =XLSX.write(workbook,{bookType:'xlsx',type: 'array'})
              const data = new Blob([excelBlob]);
              saveAs(data,`Time cost data export-${props.project.ProjectId}.xlsx`)


           }
          //  const url = window.URL.createObjectURL(new Blob([csvstring]));
          //  const link = document.createElement('a');
          //  link.href = url;
          //  link.setAttribute('download', `Time cost data export-${props.project.ProjectId}.csv`); //or any other extension
          //  document.body.appendChild(link); 
          //  link.click();
           toastr.success(`Download Successful
           Note:Go to downloads to get the CSV file`)
         }
       else{ 
        toastr.info(`Time Cost data doesn't exist. Please contact Support Team`)    
       }
 
     }
 
     )
 
    
 
   }
  /// For Vendor Module
  useEffect(() => {
    let temp = [];
    if (_.head(currentCostingProfile.CountrySpecs)?.MethodologySpecs) {
      currentCostingProfile?.CountrySpecs.forEach((country) => {
        let metharray = [];
        country.MethodologySpecs.forEach((meth) => {
          metharray.push({
            value: meth.Code,
            label: meth.Label,
            costType: meth.CostingType,
          });
        });
        temp.push({
          Code: country.CountryCode,
          Label: country.CountryName,
          Methodology: metharray,
        });
      });
    }
    setPreValues(temp);
  }, [currentCostingProfile]);

  useEffect(() => {
    setUpdateCost(currentCostingProfile.updateCost);
    setOverheadsPercentage(
      currentCostingProfile?.ProfileSetting?.PercentOverhead * 100
    );
  }, [currentCostingProfile]);

  useEffect(() => {
    if (currentCostingProfile?.CountrySpecs) {
      let _haveCostingOptions = false;
      currentCostingProfile.CountrySpecs?.map((cs) =>
        cs.MethodologySpecs?.map((ms) => {
          if (ms?.CostingOptions?.filter((co) => co.Selected)?.length) {
            _haveCostingOptions = true;
          }
        })
      );
      setHaveCostingOptions(_haveCostingOptions);
    }
  }, [currentCostingProfile]);
  const [methCountryCostSet, setMethCountryCostSet] = useState({});
  const [methCostFieldsSet, setMethCostFieldsSet] = useState({});
  const [, setMethLabels] = useState({});
  const [includeOnlyInMCP] = useState([
    "MCPLocalCountryExtra",
    "CostTotalMCPMargin",
  ]);
  
  useEffect(() => {
    let finalCostFields = {};
    let _methCountryCostSet = {};
    let _methCostFieldsSet = {};
    let _methLabels = {};
    if (
      !Object.keys(methCountryCostSet).length &&
      currentCostingProfile.CountrySpecs?.length &&
      !Object.keys(methCostFieldsSet).length &&
      currentCostingProfile.Project
    ) {
      _.head(currentCostingProfile.CountrySpecs)?.MethodologySpecs.map((ms) => {
        if (
          _.includes(shopperMethodologies, ms.Code) &&
          (!_.includes(
            pacificMarkets,
            currentCostingProfile.Project.CommissioningCountry
          )||(_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0"))
        ) {
          if (ms && ms.CostsData) {
            Object.keys(ms.CostsData).map((csch) => {
              _methLabels[ms.Code] = ms.Label;
              if (!_methCostFieldsSet[ms.Code])
                _methCostFieldsSet[ms.Code] = [];
              if (!_.includes(_methCostFieldsSet[ms.Code], csch))
                _methCostFieldsSet[ms.Code].push(csch);
            });
          }
        } else {
          Object.keys(ms.CalculationSchema).map((csch) => {
            if (!finalCostFields[csch]) {
              if (_.includes(includeOnlyInMCP, csch)) {
                if (currentCostingProfile.IsMultiCountry)
                  finalCostFields[csch] = [];
              } else {
                finalCostFields[csch] = [];
              }
            }
            Object.keys(ms.CalculationSchema[csch]).map((insch) => {
              if (
                finalCostFields[csch] &&
                !_.includes(finalCostFields[csch], insch)
              ) {
                finalCostFields[csch].push(insch);
              }
            });
            if (additionalLabels && additionalLabels[csch]) {
              Object.keys(additionalLabels[csch]).map((lab) => {
                if (!_.includes(finalCostFields[csch], lab)) {
                  finalCostFields[csch].push(lab);
                }
              });
            }
          });
        }
      });
      currentCostingProfile.CountrySpecs.map((cs) => {
        cs.MethodologySpecs.filter(
          (ms) =>
            (_.includes(shopperMethodologies, ms.Code) && project.CostingTemplateVersion != "v2.0")
        ).map((ms) => {
          if (ms) {
            if (!_methCountryCostSet[ms.Code])
              _methCountryCostSet[ms.Code] = {};
            _methCountryCostSet[ms.Code][ms.Code] = {};
            _methCountryCostSet[ms.Code][cs.CountryCode] = ms.CostsData;
            _methCountryCostSet[ms.Code][ms.Code] = ms.Label;
          }
        });
      });
      setMethCountryCostSet(_methCountryCostSet);
      setMethCostFieldsSet(_methCostFieldsSet);
      setMethLabels(_methLabels);
      setCostFields(finalCostFields);
    }
  }, [currentCostingProfile]);

  const [tempProfileSetting, setTempProfileSetting] = useState({
    CostIntOpsMultiplier: 0,
    CostIntCommMultiplier: 0,
  });
  useEffect(() => {
    if (
      !Object.keys(schema).length &&
      currentCostingProfile &&
      currentCostingProfile.ProfileSetting
    ) {
      if (currentCostingProfile.ProfileSetting.CommercialHoursSchema) {
        setSchema(currentCostingProfile.ProfileSetting.CommercialHoursSchema);
        setBands(
          currentCostingProfile.ProfileSetting.CommercialHoursSchema.bands
        );
      }
      if (
        currentCostingProfile.ProfileSetting.CostIntCommMultiplier ||
        currentCostingProfile.ProfileSetting.CostIntOpsMultiplier
      ) {
        setTempProfileSetting({
          CostIntCommMultiplier:
            currentCostingProfile.ProfileSetting.CostIntCommMultiplier,
          CostIntOpsMultiplier:
            currentCostingProfile.ProfileSetting.CostIntOpsMultiplier,
        });
      }

      setOverheadsPercentage(
        (currentCostingProfile.ProfileSetting.PercentOverhead ?? 0) * 100
      );
    }
  }, [currentCostingProfile]);

  //Total Cost breakdown by costingOptions
  
  useEffect(() => {
    let finalCostFields = {};
    currentCostingProfile?.CountrySpecs?.map((cs) => {
      cs?.MethodologySpecs.map((ms) => {
        finalCostFields[ms.id] = {};
        Object.keys(ms.CalculationSchema).map((csch) => {
          if (!finalCostFields[ms.id][csch]) {
            if (_.includes(includeOnlyInMCP, csch)) {
              if (currentCostingProfile.IsMultiCountry)
                finalCostFields[csch] = [];
            } else {
              finalCostFields[ms.id][csch] = [];
            }
          }
          Object.keys(ms.CalculationSchema[csch]).map((insch) => {
            if (
              finalCostFields[ms.id][csch] &&
              !_.includes(finalCostFields[ms.id][csch], insch)
            ) {
              finalCostFields[ms.id][csch].push(insch);
            }
          });
          if (additionalLabels && additionalLabels[csch]) {
            Object.keys(additionalLabels[csch]).map((lab) => {
              if (!_.includes(finalCostFields[ms.id][csch], lab)) {
                finalCostFields[ms.id][csch].push(lab);
              }
            });
          }
        });
      });
    });
    setCostFieldsByCostingOption(finalCostFields);
  }, [currentCostingProfile]);
  const [allDownloadProps, setAllDownloadProps] = useState([
    {
      value: "projectDetails",
      label: "Project Details",
    },
    {
      value: "methodology",
      label: "Methodologies",
    },
    {
      value: "costingOptionsBreakdown",
      label: "Costing Options",
    },
    {
      value: "opsresources",
      label: "Operation Resources",
    },
    {
      value: "costbreakdown",
      label: "Total Cost Breakup",
    },
    {
      value: "profitability",
      label: "Profitability Overview",
    },
    {
      value: "profitabilitychecks",
      label: "Profitability Checks",
    },
    {
      value: "commercialbreakdown",
      label: "Commercial Time Breakdown (Per Wave)",
    },
    {
      value: "shoppersbreakdown",
      label: "Shoppers Cost Breakdown (Per Country)",
    },
    {
      value: "costingSheet",
      label: "Costing Sheet",
    }
  ]);
  const [methCountrySet, setMethCountrySet] = useState({});
  const [allOpsResourcesFields, setOpsResourcesFields] = useState([]);
  const [allRFQOptions, setRFQOptions] = useState({});
  const [allCostingOptions, setAllCostingOptions] = useState([]);
  const [opsResourcesVariables] = useState([
    "surveyProgrammingResource",
    "surveyProgrammingResourceVendor",
    "dataProcessingResource",
    "dataProcessingResourceVendor",
    "dataProcessingComplexity",
    "translationsResource",
    "translationsResourceVendor",
    "verbatimCodingResource",
    "verbatimCodingResourceVendor",
    "chartingResource",
    "chartingResourceVendor",
    "dashboardingResource",
    "dashboardingResourceVendor",
    "qualityControl",
    "qualityControlComments",
    "dataCollectionPlatform",
    "platformComments",
  ]);
  const [shopperCostLabels] = useState({
    ETExtOps: {
      label: "External Operations Costs",
      properties: [
        "Eye-tracking glasses rental ",
        "Shipment of  ET glasses/Tablets  ",
        "Tablets",
        "Traveling, training",
        "Venue (if applicable)",
        "Translation (Questionnaire, Briefing note, Showcard, etc.)",
        "Application programming",
        "Questionnaire programming Costs (ConfirmIT, STG, Decipher) - (TCS only)",
        "Questionnaire programming Costs (ConfirmIT, STG, Decipher) - (Non-TCS External)",
        "Store plan drawing",
        "Taking photos of POS in stores",
        "Recruitment only",
        "Fieldwork - (Payment to Interviewer and Supervisors)",
        "Outsourced Vendor Data collection/QC (Non-TCS External)",
        "Incentives",
        "QC/ Assurance cost (Payment to part-timers)",
        "Technican operator",
        "GO costs (Coding Of Verbatim) - (TCS only)",
        "GO costs (Coding Of Verbatim)  - (Non-TCS External)",
        "All other external costs",
        "All Other External costs - Printing/Stationaries costs",
        "All Other General Expenses (eg. Refreshments/Respondent travel..etc)",
      ],
    },
    ETIntCS: {
      label: "Internal Commercial Costs",
      properties: [
        "Project Coordination/Project management ",
        "Questionnaire Review/Inputs",
        "Insight Generation/Topline or Full Report Writing ",
        "Other (specify)…",
      ],
    },
    SSExtOps: {
      label: "External Operations Costs",
      properties: [
        "Cost for the Hours to find images online for 2D ",
        "Cost for the Hours to model the 2D products in vendor software",
        "Cost to buy products to photograph for 3D, if applicable",
        "Cost of shipment products for photography",
        "Photography for 3D SKUs (6-10 images per SKU), if applicable",
        "Modelling for 3D SKUs, if applicable",
        "Cost for the Hours to procure custom wallpaper images, if applicable",
        "Other Set-Up materials costs - SPECIFY: ",
        "Other Set-Up materials costs - SPECIFY: ",
        "VR Envoirnment Build / Development Cost (Vendor)",
        "Implicit Analysis Cost (Vendor) if applicable",
        "Sampling costs",
        "Translation questionnaire (if applicable)",
        "Coding OE (please specify number of OE)",
        "Translation verbatims of OE (please specify number of OE)",
        "Other Fieldwork Cost - SPECIFY: ",
        "Other Fieldwork Cost - SPECIFY: ",
        "Webcam Eye-tracking with Sticky (if requested)",
        "Additional Incentives for respondents who agree to participate in Eye Tracking exercise",
        "Video Selfies Cost (assuming 10% of total sample) - based on Nielsen VoxPopMe rate",
        "Translation transcripts from Video Selfie Feedback",
        "Other Video Selfie and/or ET Cost - SPECIFY: ",
        "Programming of questionnaire (TCS or vendor)",
        "Tabulation (CRDC)",
        "Charting (CRDC)",
        "Decipher credits",
        "Other Programming&DP Cost - SPECIFY: ",
        "Other Programming&DP Cost - SPECIFY: ",
      ],
    },
    SSIntCS: {
      label: "Internal Commercial Costs",
      properties: [
        "Questionnaire Review/Inputs (Internal CS time cost)",
        "Insight Generation/Topline or Full Report Writing  (Internal CS time costs)",
        "Others CS Time Cost - SPECIFY: ",
      ],
    },
    SSIntOps: {
      label: "Internal Operations Costs",
      properties: [
        "Cost for the Hours to find images online for 2D ",
        "Cost for the Hours to model the 2D products in vendor software",
        "Cost for the Hours to procure custom wallpaper images, if applicable",
        "Project Management costs (Internal Ops time costs)",
        "Programming of questionnaire (internal)",
        "Tabulation (internal)",
        "Others Ops Time Cost - SPECIFY: ",
      ],
    },
  });
  const getTotalShopper = (meth, _prop) => {
    let _sum = 0;
    Object.keys(methCountryCostSet[meth]).map((cs) => {
      if (methCountryCostSet[meth][cs] && methCountryCostSet[meth][cs][_prop])
        _sum = _sum + parseFloat(methCountryCostSet[meth][cs][_prop]);
    });
    return getCurrentCurrency(_sum);
  };
  const updateModal=()=>{

    settimecostViewModal(!timecostViewModal)
  }
  const getTotalShopperFinal = (meth, scfl) => {
    let _sum = 0;

    Object.keys(methCountryCostSet[meth]).map((cs) => {
      _sum =
        _sum +
        _.sum(
          shopperCostLabels[scfl]?.properties.map((_prop) =>
            methCountryCostSet[meth] &&
            methCountryCostSet[meth][cs] &&
            methCountryCostSet[meth][cs][_prop]
              ? parseFloat(methCountryCostSet[meth][cs][_prop])
              : 0
          )
        );
    });
    return getCurrentCurrency(_sum);
  };
  useEffect(() => {
    let alldownloadprops = [...allDownloadProps];
    if (!(currentCostingProfile.ProfileStatus > 1)) {
      alldownloadprops = alldownloadprops.filter(
        (adp) => adp.value != "profitabilitychecks"
      );
    }
    if (
      currentCostingProfile.ProfileSetting &&
      currentCostingProfile.ProfileSetting.UsesOopOverrideIntCommCost
    ) {
      alldownloadprops = alldownloadprops.filter(
        (adp) => adp.value != "commercialbreakdown"
      );
    }

    if (
      (_.includes(
        pacificMarkets,
        currentCostingProfile?.Project?.CommissioningCountry
      )  && project.CostingTemplateVersion != "v2.0")
    ) {
      alldownloadprops = alldownloadprops.filter(
        (adp) => adp.value != "costingOptionsBreakdown"
      );
    }

    if (!canRenderShopperBreakdown()) {
      alldownloadprops = alldownloadprops.filter(
        (adp) => adp.value != "shoppersbreakdown"
      );
    }

    setAllDownloadProps(alldownloadprops);
    if (!allOpsResourcesFields.length && currentCostingProfile.WaveSpecs) {
      let responsibilities = _.head(
        currentCostingProfile.WaveSpecs
      )?.ResponsibilitySchema;
      let _allResponsibilities = [];
      if (responsibilities) {
        Object.keys(responsibilities).map((res) => {
          if (!_.includes(_allResponsibilities, res)) {
            _allResponsibilities.push(res);
          }
        });
      }

      let allOpsResourcesDataParams = [];
      currentCostingProfile.WaveSpecs.map((t) => {
        if (t.OpsResourcesSchema && t.OpsResourcesSchema.properties) {
          Object.keys(t.OpsResourcesSchema.properties).map((opd) => {
            if (
              !_.includes(allOpsResourcesDataParams, opd) &&
              !_.includes(_allResponsibilities, opd)
            )
              allOpsResourcesDataParams.push(opd);
          });
        }
      });
      setOpsResourcesFields(allOpsResourcesDataParams);
    }
    if (
      !Object.keys(allRFQOptions).length &&
      currentCostingProfile.CountrySpecs?.length
    ) {
      let allRFQDataParams = {};
      currentCostingProfile.CountrySpecs?.map((cs) => {
        if (cs.MethodologySpecs?.length) {
          cs.MethodologySpecs.map((ms) => {
            allRFQDataParams[ms.id] = [];
            let allCostingOptionKeys = [
              "targetGroupDefinition",
              "citiesCoverage",
              "sampleSize",
              "lengthOfInterview",
              "sampleSource",
              "samplingType",
            ];

            ms.CostingOptions?.map((co) => {
              if (co?.CostingOptionData) {
                Object.keys(co.CostingOptionData).map((cd) => {
                  allCostingOptionKeys.push(cd);
                });
              }
            });
            if (ms.RFQSchema && ms.RFQSchema.properties) {
              Object.keys(ms.RFQSchema.properties).map((rfqkey) => {
                if (
                  !_.includes(allRFQDataParams[ms.id], rfqkey) &&
                  !_.includes(allCostingOptionKeys, rfqkey)
                ) {
                  allRFQDataParams[ms.id].push(rfqkey);
                }
              });
            }
            if (ms.RFQSchema?.dependencies) {
              Object.keys(ms.RFQSchema.dependencies).map((dep) => {
                ms.RFQSchema.dependencies[dep].oneOf.map((oo) => {
                  Object.keys(oo.properties).map((rfqkey) => {
                    if (
                      !_.includes(allRFQDataParams[ms.id], rfqkey) &&
                      !_.includes(allCostingOptionKeys, rfqkey)
                    ) {
                      allRFQDataParams[ms.id].push(rfqkey);
                    }
                  });
                });
              });
            }
          });
        }
      });

      setRFQOptions(allRFQDataParams);
    }
    if (
      !Object.keys(methCountrySet).length &&
      currentCostingProfile.CountrySpecs?.length
    ) {
      let methBreak = {};
      currentCostingProfile.CountrySpecs.map((cs) => {
        cs.MethodologySpecs?.map((ms) => {
          if (!methBreak[ms.id]) {
            methBreak[ms.id] = { country: cs.CountryName, methlabel: ms.Label };
          }
        });
      });
      setMethCountrySet(methBreak);
    }
    if (
      !allCostingOptions.length &&
      currentCostingProfile.CountrySpecs?.length
    ) {
      let _allCostingOptions = [];
      currentCostingProfile.CountrySpecs.map((cs) => {
        if (cs.MethodologySpecs?.length) {
          cs.MethodologySpecs.map((ms) => {
            ms?.CostingOptions?.filter((co) => co.Selected).map((t) => {
              t?.CostingOptionSchema?.map((cso) => {
                if (t.CostingOptionData && t.CostingOptionData[cso.id]) {
                  if (
                    !_allCostingOptions.filter((asc) => asc.id == cso.id).length
                  ) {
                    _allCostingOptions.push(cso);
                  }
                }
              });
            });
          });
        }
      });
      setAllCostingOptions(_allCostingOptions);
    }
  }, [currentCostingProfile]);

  useEffect(() => {
    if (currentCostingProfile?.CountrySpecs) {
      let _haveCostingOptions = false;
      currentCostingProfile.CountrySpecs?.map((cs, csi) => {
        if (!isDisableVendor1[csi]) {
          isDisableVendor1.push(false);
        }
        if (!isDisableSheet1[csi]) {
          isDisableSheet1.push(false);
        }
        if (!isDisableDirect1[csi]) {
          isDisableDirect1.push(false);
        }

        cs.MethodologySpecs?.map((ms) => {
          if (ms.CostingOptions && ms.CostingOptions.length) {
            _haveCostingOptions = true;
          }
        });
      });
      setHaveCostingOptions(_haveCostingOptions);
    }
  }, [currentCostingProfile]);
  const [errorObj, setErrorObj] = useState({});
  const validateCostingOptions = () => {
    let _profile = currentCostingProfile;
    let canNavigate = true;
    if (_profile.CountrySpecs?.length) {
      _profile.CountrySpecs.map((cs) => {
        if (cs.MethodologySpecs?.length) {
          cs.MethodologySpecs.filter((ms) => !ms.NotApplicable).map((ms) => {
            if (ms) {
              if (!ms.CostingOptions.length) canNavigate = false;
              else {
                ms.CostingOptions?.map((co) => {
                  co.CostingOptionSchema.map((cos) => {
                    if (
                      !co.CostingOptionData ||
                      (cos.mandatory &&
                        !cos.disabled &&
                        co.CostingOptionData &&
                        !co.CostingOptionData[cos.id])
                    )
                      canNavigate = false;
                  });
                });
              }
            }
          });
        }
      });
    }
    return canNavigate;
  };
  const validateForms = () => {
    let errorFieldsObj = {}; // object to store missing required fields - RFQ Form

    let isValid = true;
    let countrySpecs = [...currentCostingProfile.CountrySpecs];
    var allCostingOptionsUsed = [];
    let unavialable = [];
    let methodologyObj = {};
    let methodologyCountryObj = {};
    let isFieldsValid = true;
    let isTrackingProjectNonPacific = {};

    let fieldingCountries = [];
    if (Array.isArray(currentCostingProfile.FieldingCountries))
      fieldingCountries = currentCostingProfile.FieldingCountries.map(
        (fc) => fc.value
      );
    else fieldingCountries = currentCostingProfile.FieldingCountries.split(",");

    countrySpecs.map((cs) => {
      if (cs.MethodologySpecs) {
        cs.MethodologySpecs?.filter((ms) => !ms.NotApplicable)?.map((ms) => {
          methodologyObj[ms.id] = ms.Label;
          methodologyCountryObj[ms.id] = cs.CountryName;
          ms.CostingOptions?.map((co) => {
            co.CostingOptionSchema?.map((cps) => {
              if (!_.includes(allCostingOptionsUsed, cps.id) && cps.id) {
                allCostingOptionsUsed.push(cps.id);
              }
            });
          });
          if (
            ms?.RFQSchema?.required &&
            _.includes(fieldingCountries, cs.CountryCode)
          ) {
            if (!ms.RFQData) {
              isValid = false;
              isFieldsValid = false;
              unavialable = ms.RFQSchema?.required;
            } else {
              let allRequiredFields = ms.RFQSchema?.required;
              if (
                (_.includes(pacificMarkets, project?.CommissioningCountry)&& project.CostingTemplateVersion != "v2.0") &&
                _.includes(SHOPPERS_METHODOLOGIS, ms.Code) &&
                allRequiredFields?.length === 0
              ) {
                allRequiredFields = ms.Variables.filter(i => i.mandatory == true && !i.disabled).map(i => i.id)
              }
              if (
                ms.RFQData["externalSampleProvider"] &&
                ms.RFQData["externalSampleProvider"] != "Applicable"
              )
                allRequiredFields = allRequiredFields.filter(
                  (rf) => rf != "incidenceRate"
                );
              let existingProperties = Object.keys(
                ms.RFQSchema?.properties
              ).filter((f) => !ms.RFQSchema.properties[f].Default);

              let finalFields = allRequiredFields?.filter((arf) =>
                _.includes(existingProperties, arf)
              );
              unavialable = finalFields?.filter(
                (req) => req && (!ms.RFQData[req] || ms.RFQData[req] == "0")
              );

              if (
                (_.includes(pacificMarkets, project?.CommissioningCountry)&& project.CostingTemplateVersion != "v2.0") &&
                ms.RFQData["trackingProject"] === "Yes" &&
                (!ms.RFQData["numberOfWaves"] ||
                  ms.RFQData["numberOfWaves"] < 2)
              ) {
                // unavialable.push("numberOfWaves");
              } else if (
                ms.CostingOptions.length &&
                ms.CostingOptions.find(
                  (co) => co.CostingOptionData?.trackingProject === "Yes"
                )
              ) {
                isTrackingProjectNonPacific[ms.id] = true;
              }

              let haveInCostingOptions = ms.CostingOptions?.map(
                (co) => co?.CostingOptionData
              )?.filter(
                (co) =>
                  finalFields
                    ?.map((ff) => co && co[ff] && co[ff] != 0)
                    .filter((f) => f).length
              );
              if (unavialable.length && !haveInCostingOptions.length) {
                isValid = false;
                isFieldsValid = false;
              }
            }
          }
          if (!isFieldsValid && _.includes(fieldingCountries, cs.CountryCode)) {
            let fields = unavialable.map((obj) =>
              obj === "numberOfWaves"
                ? ms.SetupSchema?.properties[obj]?.title
                : ms.RFQSchema.properties[obj]?.title
            );
            if (!errorFieldsObj[cs.CountryName])
              errorFieldsObj[cs.CountryName] = {};

            if (!errorFieldsObj[cs.CountryName].RFQ)
              errorFieldsObj[cs.CountryName].RFQ = {};

            if (!errorFieldsObj[cs.CountryName].RFQ[ms.Label])
              errorFieldsObj[cs.CountryName].RFQ[ms.Label] = {};

            errorFieldsObj[cs.CountryName].RFQ[ms.Label] = fields;
          }
          isFieldsValid = true;
        });

        if (!_.includes(pacificMarkets, project?.CommissioningCountry)
        ||(_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0")
        ) {
          cs.MethodologySpecs?.filter((ms) => !ms.NotApplicable).forEach(
            (mc) => {
              if (mc) {
                mc.CostingOptions.forEach((me) => {
                  const mandate = me.Variables.filter(
                    (mt) => mt.mandatory == true && !mt.disabled
                  ).map((i) => i.label);
                  const dataValues = me.Variables.filter(
                    (mt) => mt.mandatory == true && !mt.disabled
                  );
                  const labelData = dataValues.filter((dt) => dt.label);

                  if (!errorFieldsObj[cs.CountryName])
                    errorFieldsObj[cs.CountryName] = {};

                  if (!errorFieldsObj[cs.CountryName].CostingOptions)
                    errorFieldsObj[cs.CountryName].CostingOptions = {};

                  if (!errorFieldsObj[cs.CountryName].CostingOptions[mc.Label])
                    errorFieldsObj[cs.CountryName].CostingOptions[mc.Label] =
                      {};

                  if(me.Selected === 'true' || me.Selected){
                    if (
                      !(me.CostingOptionData)
                    ) {
                      isValid = false;
                      toastr.info("Please save RFQ details before Creating new request")
                    }
                    else if (
                      me.CostingOptionData && Object.keys(me.CostingOptionData).length === 0 &&
                      mandate.length > 0
                    ) {
                      isValid = false;
                      errorFieldsObj[cs.CountryName].CostingOptions[mc.Label] =
                        mandate;
                    } else {
                      const filterData = dataValues.filter((x) => x.value);
                      const unfilterData = dataValues
                        .filter((xy) => !xy.value)
                        .map((t) => t.label);
  
                      if (filterData.length !== dataValues.length) {
                        isValid = false;
                        errorFieldsObj[cs.CountryName].CostingOptions[mc.Label] =
                          unfilterData;
                      }
                    }
                  }
                });
              }
            }
          );
        }
      }
    });

    if (currentCostingProfile?.WaveSpecs?.length) {
      let validMethIds = [];
      currentCostingProfile.CountrySpecs?.map((cs) => {
        cs.MethodologySpecs?.filter((ms) => !ms.NotApplicable)
          ?.map((ms) => ms.id)
          ?.map((ms) => {
            if (!_.includes(validMethIds, ms)) validMethIds.push(ms);
          });
      });
      let wavespecs = [
        ...currentCostingProfile.WaveSpecs?.filter((ws) =>
          _.includes(validMethIds, ws.MethodologySpecId)
        ),
      ];
      if (wavespecs.length) {
        wavespecs.map((ws) => {
          // ws = _.head(wavespecs)
          let finalPropsToValidate = [];
          ws.OpsResourcesSchema?.required?.map((prop) => {
            let currentProp = ws.OpsResourcesSchema.properties[prop];
            if (
              currentProp &&
              (!currentProp.dependentOn ||
              !currentProp.disabled) &&
              !currentProp.selected
            ) {
              if (
                prop === "numberofExcelFile" &&
                ws.OpsResourcesData &&
                ws.OpsResourcesData["numberofExcelFile"] > 0
              )
                return;
              if (
                prop === "numberOfModules" &&
                ws.OpsResourcesData &&
                ws.OpsResourcesData["numberOfModules"] >= 0
              )
              return;
              if (
                prop === "numberOfOpenEnded" &&                 
                ws.OpsResourcesData &&
                ws.OpsResourcesData["numberOfSemiOpenEnded"] >= 0
              )
              return;
              if (
                prop === "numberOfSemiOpenEnded" &&               
                ws.OpsResourcesData &&
                ws.OpsResourcesData["numberOfSemiOpenEnded"] >= 0
              )
                return;
                if (

                                  prop === "L2breakouts" &&
                  
                                  ws.OpsResourcesData &&
                  
                                  ws.OpsResourcesData["L2breakouts"] >= 0
                  
                                )
                  
                                  return;
                  
                                if (
                  
                                  prop === "L3breakouts" &&
                  
                                  ws.OpsResourcesData &&
                  
                                  ws.OpsResourcesData["L3breakouts"] >= 0
                  
                                )
                  
                                  return;
                if (
                  prop === "numberOfOpenEndedToCode" &&
                  ws.OpsResourcesData &&
                  ws.OpsResourcesData["codingRequired"] === "No"
                )
                  return;
              if (
                (!_.includes(opsResourcesVariables, prop) &&
                  !_.includes(allCostingOptionsUsed, prop)) ||
                (_.includes(opsResourcesVariables, prop) &&
                  userRecord.CanEditOpsResources)
              )
                if (
                  prop === "surveyProgrammingResource" &&
                  ws.OpsResourcesData &&
                  ws.OpsResourcesData["surveyProgrammingRequired"] === "No"
                )
                  //todo: not sure why it is been done in this way in CountryMethodologyTabsFinal
                  //copied the same, but need to be fixed in a proper way
                  return;

              if (
                _.includes(opsResourcesVariables, prop) &&
                !userRecord.CanEditOpsResources
              )
                return;
                if(!finalPropsToValidate.includes(prop)){
                  finalPropsToValidate.push(prop);
                }
            } else {
              if (
                !(currentProp?.dependentOn?.fields?.length > 1) &&
                currentProp &&
                !currentProp.disabled &&
                !currentProp.selected
              ) {
                 
                currentProp?.dependentOn?.fields?.map((_dependentField) => {
                  let depProp = _dependentField;
                  if (depProp) {
                    let parentProp = {};
                    Object.keys(ws.OpsResourcesSchema.properties).map((pp) => {
                      if (
                        ws.OpsResourcesSchema.properties[pp].title ==
                        depProp.fieldName
                      ) {
                        parentProp = pp;
                      }
                    });
                    if (
                      prop === "approxPercentageChange" &&
                      wavespecs.length < 2
                    )
                      return;
                    

                    if (
                      parentProp &&
                      ws.OpsResourcesData &&
                      ws.OpsResourcesData[parentProp] &&
                      ws.OpsResourcesData[parentProp] != "No" &&
                      !_.includes(finalPropsToValidate, prop)
                    ) {
                      if (
                        (!_.includes(opsResourcesVariables, prop) &&
                          !_.includes(allCostingOptionsUsed, prop)) ||
                        (_.includes(opsResourcesVariables, prop) &&
                          userRecord.CanEditOpsResources)
                      ) {
                        if(!finalPropsToValidate.includes(prop)){
                          finalPropsToValidate.push(prop);
                        }
                      }
                    }
                  }
                });
              }
            }
          });

          let finalErrorFields = [];
          
          const numberOfWaves = ws.OpsResourcesData["numberOfWaves"]
            ? ws.OpsResourcesData["numberOfWaves"]
            : 0;

            if (
              isTrackingProjectNonPacific[ws.MethodologySpecId] &&
              ws.OpsResourcesSchema?.properties?.["numberOfWaves"] &&
              numberOfWaves == 0
            ) {
              isValid = false;
              isFieldsValid = false;
              if(!finalErrorFields.includes(ws.OpsResourcesSchema?.properties?.["numberOfWaves"]?.title) 
                && finalPropsToValidate.includes("numberOfWaves")){
                finalErrorFields.push(
                  "Number of waves should be greater than 1"
                );
              }
            }
            if (
              isTrackingProjectNonPacific[ws.MethodologySpecId] &&
              ws.OpsResourcesSchema?.properties?.["numberOfWaves"] &&
              numberOfWaves == 1
            ) {
              isValid = false;
              isFieldsValid = false;
              if(!finalErrorFields.includes(ws.OpsResourcesSchema?.properties?.["numberOfWaves"]?.title) 
                && finalPropsToValidate.includes("numberOfWaves")){
                finalErrorFields.push(
                  "Number of waves should be greater than 1"
                );
              }
              
            }
          const numberOfLicenses = ws.OpsResourcesData["NumberOfLicenses"]
          ?ws.OpsResourcesData["NumberOfLicenses"]:0;

          if(  
            ws.OpsResourcesSchema?.properties?.["NumberOfLicenses"] && 
            numberOfLicenses>1000
            ){
            isValid=false;
            isFieldsValid=false;
            finalErrorFields.push(ws.OpsResourcesSchema?.properties?.["NumberOfLicenses"]?.title)
          
          }
          const numberOfModules = ws.OpsResourcesData["numberOfModules"]
          ?ws.OpsResourcesData["numberOfModules"]:0;

          if(  
            ws.OpsResourcesSchema?.properties?.["numberOfModules"]  && numberOfModules>999
            ){
            isValid=false;
            isFieldsValid=false;
            finalErrorFields.push(ws.OpsResourcesSchema?.properties?.["numberOfModules"]?.title) && 
            finalErrorFields.push(" - Value should be between " +ws.OpsResourcesSchema?.properties?.["numberOfModules"].minimum +"-" +ws.OpsResourcesSchema?.properties?.["numberOfModules"].maximum)
          
          }

          const L2Breakouts = ws.OpsResourcesData["L2breakouts"]

            ? ws.OpsResourcesData["L2breakouts"]

            : 0;




          if (

            ws.OpsResourcesSchema?.properties?.["L2breakouts"] &&

            L2Breakouts > 100

          ) {

            isValid = false;

            isFieldsValid = false;

            finalErrorFields.push(

              ws.OpsResourcesSchema?.properties?.["L2breakouts"]?.title

            );

          }

          const L3Breakouts = ws.OpsResourcesData["L3breakouts"]

            ? ws.OpsResourcesData["L3breakouts"]

            : 0;




          if (

            ws.OpsResourcesSchema?.properties?.["L3breakouts"] &&

            L3Breakouts > 100

          ) {

            isValid = false;

            isFieldsValid = false;

            finalErrorFields.push(

              ws.OpsResourcesSchema?.properties?.["L3breakouts"]?.title

            );

          }
          finalPropsToValidate.map((ftv) => {
            if (
              !ws.OpsResourcesData ||
              (ws.OpsResourcesData &&
                (!ws.OpsResourcesData[ftv] || ws.OpsResourcesData[ftv] == "0"))
            ) {
              let excludeError = [
                // "numberOfOpenEnded",
                // "numberOfSemiOpenEnded",
                // "numberOfOpenEndedToCode",
                "numberOfWaves"
               
              ];
              
              if (!excludeError.includes(ftv)) {
                isValid = false;              
                isFieldsValid = false;              
                finalErrorFields.push(ws.OpsResourcesSchema.properties[ftv].title);
              }
            }
          });
          if (!isFieldsValid) {
            if (!errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]])
              errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]] = {};
			  
            if (!errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD)
              errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD =
                {};

            if (
              !errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                methodologyObj[ws.MethodologySpecId]
              ]
            )
              errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                methodologyObj[ws.MethodologySpecId]
              ] = {};

              if (_.includes(pacificMarkets, project.CommissioningCountry)&& project.CostingTemplateVersion == "v2.0") {
                if (
                  !errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                    methodologyObj[ws.MethodologySpecId]
                  ].NO
                )
                  errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                    methodologyObj[ws.MethodologySpecId]
                  ].NO = {};
  
                if (
                  !errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                    methodologyObj[ws.MethodologySpecId]
                  ].NO[ws.WaveNumber]
                )
                  errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                    methodologyObj[ws.MethodologySpecId]
                  ].NO[ws.WaveNumber] = {};
  
                errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                  methodologyObj[ws.MethodologySpecId]
                ].NO[ws.WaveNumber] = finalErrorFields;
              } else {
                errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                  methodologyObj[ws.MethodologySpecId]
                ] = finalErrorFields;
              }
          }
          isFieldsValid = true;
        });
      }
    }

    if (!isValid) {
      if (document.getElementById("RFQFinalForm")) {
        document.getElementById("RFQFinalForm").requestSubmit();
        document.getElementById("OpsResourcesFinalForm")?.requestSubmit();
      }
      setErrorObj(errorFieldsObj);
      handleOpen(true);
    }
    return isValid;
  };
  const getActualPrice = (actualvalue, withCurrency) => {
    if (
      currencies &&
      currencies.length &&
      currentCurrency &&
      currentCurrency.ConversionRateToLocal
    ) {
      if (actualvalue) {
        let finalVal = _.round(
          actualvalue * currentCurrency.ConversionRateToLocal,
          2
        );
        return withCurrency
          ? `${finalVal} ${currentCurrency.CurrencyUnit}`
          : finalVal;
      } else return null;
    }
  };
  const getOOPThresholdText = () => {
    if (currentCostingProfile.ApprovalLevelNeeded === -1) {
      return `${
        currentCostingProfile.ApprovalDetails[0]
          .ThresholdOutOfPocketPercentage * 100
      } % or less (Price To Client > ${getActualPrice(
        currentCostingProfile.ApprovalDetails[0].ThresholdRevenueAmount,
        true
      )})`;
    } else {
      let index = currentCostingProfile.ApprovalLevelNeeded || 0;
      return `${
        currentCostingProfile.ApprovalDetails[index]
          .ThresholdOutOfPocketPercentage * 100
      } % or less (P2C > ${getActualPrice(
        currentCostingProfile.ApprovalDetails[index].ThresholdRevenueAmount,
        true
      )})`;
    }
  };
  useEffect(() => {
    let finalCostFields = {};
    _.head(currentCostingProfile.CountrySpecs)?.MethodologySpecs.map((ms) => {
      Object.keys(ms.CalculationSchema).map((csch) => {
        if (!finalCostFields[csch]) {
          finalCostFields[csch] = [];
        }
        Object.keys(ms.CalculationSchema[csch]).map((insch) => {
          if (!_.includes(finalCostFields[csch], insch)) {
            finalCostFields[csch].push(insch);
          }
        });
        if (additionalLabels && additionalLabels[csch]) {
          Object.keys(additionalLabels[csch]).map((lab) => {
            if (!_.includes(finalCostFields[csch], lab)) {
              finalCostFields[csch].push(lab);
            }
          });
        }
      });
    });
    setCostFields(finalCostFields);
  }, [currentCostingProfile]);
  useEffect(() => {
    if (
      currentCostingProfile &&
      rfqData &&
      !Object.keys(rfqData).length &&
      currentCostingProfile.CountrySpecs
    ) {
      let finalData = {};
      currentCostingProfile.CountrySpecs.map((cs) => {
        cs.MethodologySpecs.filter((ms) => !ms.NotApplicable).map((ms) => {
          if (!finalData[cs.CountryCode]) {
            finalData[cs.CountryCode] = {};
          }
          if (!finalData[cs.CountryCode][ms.Code]) {
            finalData[cs.CountryCode][ms.Code] = {};
          }
          finalData[cs.CountryCode][ms.Code] = ms.RFQData;
        });
      });
      setRfqData(finalData);
      let opsFinal = {};
      currentCostingProfile.WaveSpecs?.map((ws) => {
        opsFinal[ws.WaveNumber] = ws.OpsResourcesData;
      });
      setOpsData(opsFinal);
    }
  }, [currentCostingProfile]);
  const [currentCurrency, setCurrentCurrency] = useState({});
  const currencies = useSelector(({ currentCosting }) =>
    currentCosting.currentCostingProfile.ProfileSetting &&
    currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
      ? currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
      : []
  );
  useEffect(() => {
    if (
      !currencies &&
      currentCostingProfile &&
      currentCostingProfile.ProfileSetting &&
      Object.keys(currentCostingProfile.ProfileSetting).length
    ) {
      if (
        !calledCurrencies &&
        !currentCostingProfile.ProfileSetting.CurrenciesData
      ) {
        setCalledCurrencies(true);
        dispatch(
          currentCostingActions.setCurrencies(() => setCalledCurrencies(false))
        );
      }
    }
  }, [currentCostingProfile]);
  useEffect(() => {
    if (currentCostingProfile && currentCostingProfile.CostInputCurrency) {
      let values = currentCostingProfile.CostInputCurrency.split("-");
      let currencyUnit = _.last(values);
      let countryCode = _.head(values);
      if (currencies) {
        setCurrentCurrency(
          _.head(
            currencies.filter(
              (cur) =>
                cur.Code == countryCode && cur.CurrencyUnit == currencyUnit
            )
          )
        );
      }
    }
  }, [currentCostingProfile, currencies]);
  const [downloadType, setDownloadType] = useState("SHEET");
  const updateProject = (profileTemp, makeNewVersion) => {
    const tempProfiles = project.CostingProfiles.map((cp) => {
      if (cp.id == profileTemp.id) return { ..._.cloneDeep(profileTemp) };
      return { ..._.cloneDeep(cp) };
    });
    let _tempProject = { CostingProfiles: tempProfiles };
    if (makeNewVersion)
      _tempProject = { ..._tempProject, CostingTemplateVersion: "v2.0" };
    dispatch(currentProjectActions.setCurrentProject({ ..._tempProject }));
  };
  const getProperty = (schema, prop) => {
    if (schema.properties[prop]) return schema.properties[prop];
    else {
      let reqVal = "";
      if (schema.dependencies) {
        Object.keys(schema.dependencies).map((dep) => {
          schema.dependencies[dep].oneOf.map((oo) => {
            if (oo.properties[prop] && oo.properties[prop].title) {
              reqVal = oo.properties[prop];
            }
          });
        });
      }
      return reqVal;
    }
  };
  const getOpsValue = (value, prop) => {
    if (value || value == false) {
      if (prop.toLowerCase().indexOf("complexity") != -1) {
        if (prop == "surveyProgrammingComplexity") {
          return _.head(
            codeLabels.QuestionnaireComplexityOptions.filter(
              (frq) => frq.Code == value
            )
          )?.Label;
        }
        if (prop == "dataProcessingComplexity") {
          return _.head(
            codeLabels.DataProcessingComplexityOptions.filter(
              (frq) => frq.Code == value
            )
          )?.Label;
        }
        if (prop == "chartingComplexity") {
          return _.head(
            codeLabels.ChartingComplexityOptions.filter(
              (frq) => frq.Code == value
            )
          )?.Label;
        }
      } else {
        if (Array.isArray(value)) {
          if (typeof _.head(value) == "string") return value.join();
          else return value.map((v) => v.label).join();
        } else if (typeof value == "boolean") {
          return value ? "Yes" : "No";
        } else return value;
      }
    } else {
      return "-";
    }
  };
  const getCurrentCurrency = (actualvalue) => {
    if (
      currencies &&
      currencies.length &&
      currentCurrency &&
      currentCurrency.ConversionRateToLocal
    ) {
      if (actualvalue) {
        let finalVal = _.round(
          actualvalue * currentCurrency.ConversionRateToLocal,
          2
        );
        return `${finalVal} ${currentCurrency.CurrencyUnit}`;
      } else return `0 ${currentCurrency.CurrencyUnit}`;
    }
  };

  const profitabilityTable = [
    {
      id: "CostTotalExternalOperations",
      label: "Total OOP (Ops OOP + CS OOP)",
      isPrice: true,
    },
    { id: "WHT", label: "WHT(With holding tax)", isPrice: true },
    { id: "VAT", label: "VAT(Value added tax)", isPrice: true },
    {
      id: "TotalExternalCosts",
      label: "Total OOP (Ops OOP + CS OOP+Tax)",
      isPrice: true,
    },
    {
      id: "CostTotalInternalOperations",
      label: "Total Time Cost Operations",
      isPrice: true,
    },
    {
      id: "CostTotalInternalCommercial",
      label: "Total Time Cost Commercial",
      isPrice: true,
    },
    { id: "CostTotalMCPMargin", label: "MCP Margin", isPrice: true },
    {
      id: "Overheads",
      label: `Overheads ${overheadsPercentage}%`,
      isPrice: true,
    },
    // {
    //   id: "RecommendedPrice",
    //   label: "Recommended Price To Client (Revenue)",
    //   isPrice: true,
    // },
    {
      id: "PriceToClient",
      label: "Final Price To Client (Revenue)",
      isPrice: true,
    },
    { id: "OutOfPocketCostPercent", label: "OOP %" },
    {
      id: "ProjectMarginAbsolute",
      label:
        "Project Margin Absolute (Price - Total Cost (Ext & Int) - Margin - Overheads)",
      isPrice: true,
    },
    { id: "MarginPercent", label: "Project Margin %" },
    { id: "ClientChargeable", label: "Client Chargeble", isPrice: true },
  ];

  const getCurrencyUnit = (currencyInput) => {
    if (!currencyInput) currencyInput = "US-USD";
    let values = currencyInput.split("-");
    let currencyUnit = _.last(values);
    return currencyUnit;
  };

  const getGrandTotalWithoutCurrency = (actualvalue) => {
    if (
      currencies &&
      currencies.length &&
      currentCurrency &&
      currentCurrency.ConversionRateToLocal
    ) {
      if (actualvalue) {
        let finalVal = _.round(
          actualvalue * currentCurrency.ConversionRateToLocal,
          2
        );
        return `${finalVal}`;
      } else return `0`;
    }
  };

  const hasNewNotification = useSelector((state) => state.notificationReducer.NewNotification);
  const message = useSelector((state) => state.notificationReducer.Message);

  const handleClick = () => {
    // localStorage.removeItem("isAlertClosed");
    // if(props.setIsVisible !== undefined)
    //   props.setIsVisible(true);
    dispatch(setClosedStatus(false));
    dispatch(setNewNotification(true));
  };

  const alternativeLabels = {
    CostTotalInternalCommercial: {
      UsesOopOverrideIntCommCost: "CostIntCommMultiplier",
      // Value: "CostTotalExternalOperations"
    },
    CostTotalInternalOperations: {
      UsesOopOverrideIntOpsCost: "CostIntOpsMultiplier",
      // Value: "CostTotalExternalOperations"
    },
  };
  const additionalLabels = {
    CostTotalExternalOperations: {
      CostExtOpsMCPSubContract: "MCP/Group Company Sub-Contracting",
      CostExtOpsOtherTaxVAT: "VAT and Other Tax Adjustment",
    },
  };
  const costlabels = {
    CostExtOpsMCPSubContract: "MCP/Group Company Sub-Contracting",
    CostExtOpsOtherTaxVAT: "VAT and Other Tax Adjustment",
    CostTotalExternalOperations: "Total External Operations Costs (OOP)",
    CostExtOpsInterviewers: "Interviewers - Temporaries & Subcontractors",
    CostExtOpsDCQCDPSP: "External DC/Coding/QC/DP/Programming/Scripting",
    CostExtOpsTE: "Travel, Lodging and Entertainment",
    CostExtOpsOthers: "External Others",
    CostExtOpsIncentives: "Incentives/Respondent Fees",
    CostExtOpsConsultantVendor: "External Consultant/Vendor",
    CostExtOpsPrintingStationery: "Printing/Stationery",
    CostExtOpsFreightShipping: "Freight/Shipping",
    CostExtOpsVenueHireRecruitment:
      "Venue/Hire/Recruitment and Other Misc. Externals",
    CostTotalInternalOperations: "Total Internal Operations Costs",
    CostIntOpsFieldPMQC: "Internal Time Field, Project Management & QC",
    CostIntOpsOthers: "Internal Other Ops",
    CostIntOpsProgramming: "Internal Time Programming",
    CostIntOpsDPCodingAnalysis: "Internal Time Data Processing/Coding/Analysis",
    CostTotalExternalCommercial: "Total External Commercial Costs",
    CostExtCommTE: "Travel, Lodging and Entertainment",
    CostExtCommConsultant: "External consultant/report writing",
    CostExtCommOthers: "CS Other Expenses",
    CostTotalInternalCommercial: "Total Internal Commercial Costs",
  };
  const costIntCommLabels = {
    CostIntCommExecDirector: "Executive Director",
    CostIntCommDirector: "Director",
    CostIntCommAssociateDirector: "Associate Director",
    CostIntCommSeniorManager: "Senior Manager",
    CostIntCommManager: "Manager",
    CostIntCommSeniorExecutive: "Senior Executive",
    CostIntCommExecutive: "Executive",
    CostIntCommDataScience: "Data Science",
  };
  const rateCardReferences = {
    "Executive Director": "ExecutiveDirector",
    Director: "Director",
    "Associate Director": "AssociateDirector",
    "Senior Manager": "SeniorManager",
    Manager: "Manager",
    "Senior Executive": "SeniorExecutive",
    Executive: "Executive",
    "Data Science": "DatascienceInternalComm",
  };

  const canRenderShopperBreakdown = () => {
    //normal breakdown applies for shopper
    if (
      (_.includes(pacificMarkets, project.CommissioningCountry)&& project.CostingTemplateVersion != "v2.0") ||
      project.CostingTemplateVersion == "v2.0"
    )
      return false;

    let Shopper = [];
    currentCostingProfile?.CountrySpecs?.map((cs) =>
      cs.MethodologySpecs.map((ms) => {
        if (
          _.includes(shopperMethodologies, ms.Code) &&
          ms.CostsData &&
          Object.keys(ms.CostsData).length
        )
          Shopper.push(ms);
      })
    );
    return Shopper.length ? true : false;
  };

  const [shopperCostFieldsLookup] = useState({
    SM000024: ["ETExtOps", "ETIntCS"],
    SM000029: ["ETExtOps", "ETIntCS"],
    SM000026: ["SSExtOps", "SSIntOps", "SSIntCS"],
  });
  const downloadInSheets = async() => {
    let sharepointExcel;
    try {
      var opts = [];
      var allTabs = [];
      dispatch(pageLoadStart());
      dispatch(recordLoadStart());
      if (_.includes(selectedProperties, "projectDetails")) {
        opts.push({ sheetid: "Project Details", header: true });
        let data = [];
        data.push({
          Detail: "Project Name",
          Value: currentCostingProfile?.Project?.ProjectName,
        });
        data.push({
          Detail: "Project Number",
          Value: currentCostingProfile?.Project?.ProjectId,
        });
        data.push({
          Detail: "Primary Nielsen Contact Email",
          Value: currentCostingProfile?.Project?.ProposalOwnerEmail.value,
        });
        data.push({
          Detail: "Other Project Team Contacts",
          Value:
            currentCostingProfile &&
            currentCostingProfile.Project &&
            currentCostingProfile.Project.OtherProjectTeamContacts &&
            currentCostingProfile.Project.OtherProjectTeamContacts.length
              ? currentCostingProfile.Project.OtherProjectTeamContacts.map(
                  (item) => {
                    return item.value;
                  }
                ).join()
              : "No Contacts Available",
        });
        data.push({
          Detail: "Syndicated Project",
          Value: currentCostingProfile?.Project?.IsSyndicated ? "Yes" : "No",
        });
        data.push({
          Detail: "Client Details",
          Value: currentCostingProfile?.Project?.ContractDetails?.map(
            (item) => {
              return `${item.AccountName} ${
                item.OpportunityNumber ? `- (${item.OpportunityNumber})` : ""
              }`;
            }
          ).join(),
        });
        data.push({
          Detail: "Commissioning Office",
          Value: getLabel(
            "OfficeOptions",
            currentCostingProfile?.Project?.CommissioningOffice
          ),
        });
        data.push({
          Detail: "Business Unit",
          Value: getLabel(
            "BusinessUnitOptions",
            currentCostingProfile?.Project?.BusinessUnit
          ),
        });
        data.push({
          Detail: "Industry Vertical",
          Value: getLabel(
            "VerticalOptions",
            currentCostingProfile?.Project?.IndustryVertical
          ),
        });
        data.push({
          Detail: "All Fielding Countries",
          Value:
            currentCostingProfile.FieldingCountries &&
            typeof currentCostingProfile.FieldingCountries == "string"
              ? currentCostingProfile.FieldingCountries.split(",")
                  .map((item) => {
                    return getLabel("FieldingCountriesOptions", item);
                  })
                  .join()
              : "-",
        });

        data.push({
          Detail: "Project Background",
          Value: currentCostingProfile?.Project?.ProjectBackground ?? "",
        });
        data.push({
          Detail: "Research Objectives",
          Value: currentCostingProfile?.Project?.ResearchObjectives ?? "",
        });
        data.push({
          Detail: "Product Type",
          Value:
            currentCostingProfile.ProductType &&
            typeof currentCostingProfile.ProductType == "string"
              ? currentCostingProfile.ProductType.split(",")
                  .map((item) => {
                    return getLabel("ProductTypeOptions", item);
                  })
                  .join()
              : "-",
        });

        data.push({
          Detail: "Methodologies",
          Value: currentCostingProfile?.Methodology?.split(",")
            .map((item) => {
              return getLabel("MethodologyOptions", item);
            })
            .join(),
        });
        data.push({
          Detail: "Sub-Methodologies",
          Value: currentCostingProfile?.SubMethodology?.split(",")
            .map((item) => {
              return getLabel("SubMethodologyOptions", item);
            })
            .join(),
        });
        data.push({
          Detail: "Tracker",
          Value: currentCostingProfile?.IsTracker ? "Yes" : "No",
        });
        data.push({
          Detail: "Tracking Frequency",
          Value:
            currentCostingProfile && currentCostingProfile.TrackingFrequency
              ? // getLabel(
                //     "TrackingFrequencyOptions",
                currentCostingProfile.TrackingFrequency
              : // )
                "Not Applicable",
        });
        if (!data.length)
          data.push({
            Component: "",
            Total: "",
          });
        allTabs.push(data);
      }
      if (_.includes(selectedProperties, "methodology")) {
        opts.push({ sheetid: "Methodologies", header: true });
        let data = [];
        _.head(currentCostingProfile.CountrySpecs)?.MethodologySpecs.map(
          (ms) => {
            let finalList = [];
            let methtitleObj = {
              "Methodology BreakDown": ms.Label,
            };
            currentCostingProfile.CountrySpecs.map((cs) => {
              methtitleObj[
                getLabel("FieldingCountriesOptions", cs.CountryCode)
              ] = "";
            });
            finalList.push(methtitleObj);

            allRFQOptions[ms.id].map((rfq) => {
              let finalObj = {};
              let property = getProperty(ms.RFQSchema, rfq);

              if (property) {
                finalObj["Methodology BreakDown"] = property.title;
                currentCostingProfile.CountrySpecs.map((cs) => {
                  finalObj[
                    getLabel("FieldingCountriesOptions", cs.CountryCode)
                  ] =
                    rfqData[cs.CountryCode] && rfqData[cs.CountryCode][ms.Code]
                      ? Array.isArray(rfqData[cs.CountryCode][ms.Code][rfq])
                        ? joinedRFQOptionsData(
                            rfqData[cs.CountryCode][ms.Code][rfq]
                          )
                        : typeof rfqData[cs.CountryCode][ms.Code][rfq] ==
                          "boolean"
                        ? rfqData[cs.CountryCode][ms.Code][rfq]
                          ? "Yes"
                          : "No"
                        : rfqData[cs.CountryCode][ms.Code][rfq]
                        ? rfqData[cs.CountryCode][ms.Code][rfq]
                        : "-"
                      : "-";
                });
                finalList.push(finalObj);
              }
            });
            data.push(finalList);
          }
        );
        let final = [];
        if (!data.length)
          data.push({
            Component: "",
            Total: "",
          });
        data?.map((t) => {
          final.push(...t);
        });
        allTabs.push(final);
      }
      if (_.includes(selectedProperties, "opsresources")) {
        opts.push({ sheetid: "Operation resources", header: true });
        var title = "";
        let data = [];
        if (allOpsResourcesFields?.length > 0) {
          allOpsResourcesFields.map((ors) => {
            let finalList = [];
            let schemaWave = currentCostingProfile.WaveSpecs.find(
              (ws) => Object.keys(ws.OpsResourcesSchema).length > 1
            );
            let property = schemaWave
              ? Object.keys(schemaWave.OpsResourcesSchema).length ? getProperty(schemaWave.OpsResourcesSchema, ors)
              : null : null;
  
            if (property) {
              let finalObj = {};
              finalObj["Operation Resources BreakDown"] = property.title;
              currentCostingProfile.WaveSpecs.map((cs) => {
                let key = cs.WaveName
                  ? `#${cs.WaveNumber} ${cs.WaveName} ${
                      methCountrySet[cs.MethodologySpecId]?.methlabel
                    },${methCountrySet[cs.MethodologySpecId]?.country}`
                  : `#${cs.WaveNumber} ${
                      methCountrySet[cs.MethodologySpecId]?.methlabel
                    },${methCountrySet[cs.MethodologySpecId]?.country}`;
                finalObj[key] = opsData[cs.WaveNumber]
                  ? getOpsValue(opsData[cs.WaveNumber][ors], ors)
                  : "-";
              });
              finalList.push(finalObj);
            }
            data.push(finalList);
          });
        }
        // })
        let final = [];
        if (!data.length) {
          final.push({
            Component: "NA",
            Total: "NA",
          });
        } else {
          data?.map((t) => {
            final.push(...t);
          });
        }
        allTabs.push(final);
      }
      if (_.includes(selectedProperties, "costingOptionsBreakdown")) {
        var title = "";
        let data = [];
        opts.push({ sheetid: "Costing Options", header: true });
        currentCostingProfile.CountrySpecs?.map((cs) => {
          let finalList = [];
          let finalObj = {};
          finalObj["Costing Options"] = cs.CountryName;

          cs.MethodologySpecs?.filter((ms) => !ms.NotApplicable)?.map((ms) => {
            ms.CostingOptions?.filter((co) => co.Selected)?.map((cso) => {
              finalObj[`${ms.Label}-${cso.Name}`] = "";
            });
          });
          finalList.push(finalObj);

          allCostingOptions?.map((aco) => {
            let finalObj = {};

            finalObj["Costing Options"] = aco.label;
            cs.MethodologySpecs?.filter((ms) => !ms.NotApplicable)?.map(
              (ms) => {
                ms.CostingOptions?.filter((co) => co.Selected)?.map((cso) => {
                  finalObj[`${ms.Label}-${cso.Name}`] = cso.CostingOptionData
                    ? Array.isArray(cso.CostingOptionData[aco.id])
                      ? cso.CostingOptionData[aco.id].map((v) => v.label).join()
                      : typeof cso.CostingOptionData[aco.id] == "object"
                      ? cso.CostingOptionData[aco.id].label
                      : cso.CostingOptionData[aco.id]
                    : "";
                });
              }
            );
            finalList.push(finalObj);
          });
          data.push(finalList);
        });
        // })
        if (!data.length)
          data.push({
            Component: "",
            Total: "",
          });
        let final = [];
        let loopedData = [];
        data.map((t) => {
          final.push(...t);
          loopedData.push(t[0]);
        });

        let uniqColumns = [];
        loopedData.map((t) => {
          Object.keys(t).map((key) => {
            if (!_.includes(uniqColumns, key)) uniqColumns.push(key);
          });
        });
        uniqColumns.map((uc) => {
          loopedData.map((t) => {
            if (t[uc] == undefined) t[uc] = "";
          });
        });
        final.shift();
        final.unshift(loopedData[0]);
        allTabs.push(final);
      }

      if (_.includes(selectedProperties, "costbreakdown")) {
        opts.push({ sheetid: "Total Cost Breakup", header: true });
        if (
          (_.includes(pacificMarkets, project.CommissioningCountry)&& project.CostingTemplateVersion != "v2.0") ||
          project.CostingTemplateVersion !== "v2.0"
        ) {
          let data = [];
          Object.keys(costFields).map((cf) => {
            if (
              alternativeLabels[cf] &&
              Object.keys(alternativeLabels[cf]).filter(
                (al) => currentCostingProfile.ProfileSetting[al]
              ).length
            ) {
              let finalObj = {};
              finalObj.Component =
                "Breakdown Not Available - Using OOP % Multiplier";
              finalObj.Total = `${
                currentCostingProfile.ProfileSetting[
                  _.head(Object.values(alternativeLabels[cf]))
                ] * 100
              }%`;
              currentCostingProfile.CountrySpecs.map((cs) => {
                finalObj[getLabel("FieldingCountriesOptions", cs.CountryCode)] =
                  "";
              });
              data.push(finalObj);
            } else if (costFields[cf].length) {
              costFields[cf].map((ccf) => {
                let finalObj = {};
                if (costlabels[ccf]) {
                  finalObj.Component = costlabels[ccf];
                  finalObj.Total = getCurrentCurrency(
                    currentCostingProfile[ccf],
                    currentCostingProfile.CostInputCurrency
                  );
                  currentCostingProfile.CountrySpecs.map((cs) => {
                    finalObj[
                      getLabel("FieldingCountriesOptions", cs.CountryCode)
                    ] = getCurrentCurrency(cs[ccf], cs.CostInputCurrency);
                  });
                  data.push(finalObj);
                }
              });
            } else {
              Object.keys(costIntCommLabels).map((cicl) => {
                let finalObj = {};
                if (costIntCommLabels[cicl]) {
                  finalObj.Component = costIntCommLabels[cicl];
                  finalObj.Total = getCurrentCurrency(
                    currentCostingProfile[cicl],
                    currentCostingProfile.CostInputCurrency
                  );
                  currentCostingProfile.CountrySpecs.map((cs) => {
                    finalObj[
                      getLabel("FieldingCountriesOptions", cs.CountryCode)
                    ] = "";
                  });
                  data.push(finalObj);
                }
              });
            }
            if (
              alternativeLabels[cf] &&
              Object.keys(alternativeLabels[cf]).filter(
                (al) => currentCostingProfile.ProfileSetting[al]
              ).length
            ) {
              let finalObj = {};
              finalObj.Component = costlabels[cf];
              finalObj.Total = getCurrentCurrency(
                currentCostingProfile.CostTotalExternalOperations *
                  currentCostingProfile.ProfileSetting[
                    _.head(Object.values(alternativeLabels[cf]))
                  ]
              );
              currentCostingProfile.CountrySpecs.map((cs) => {
                finalObj[getLabel("FieldingCountriesOptions", cs.CountryCode)] =
                  "";
              });
              data.push(finalObj);
            } else {
              let finalObj = {};
              if (costlabels[cf]) {
                finalObj.Component = costlabels[cf];
                finalObj.Total = getCurrentCurrency(
                  currentCostingProfile[cf],
                  currentCostingProfile.CostInputCurrency
                );
                currentCostingProfile.CountrySpecs.map((cs) => {
                  finalObj[
                    getLabel("FieldingCountriesOptions", cs.CountryCode)
                  ] = getCurrentCurrency(cs[cf], cs.CostInputCurrency);
                });
                data.push(finalObj);
              }
            }
          });

          if (!data.length)
            data.push({
              Component: "",
              Total: "",
            });

          allTabs.push(data);
        } else {
          const costData = prepareCostBreakup();
          allTabs.push(costData);
        }
      }
      if (_.includes(selectedProperties, "commercialbreakdown")) {
        opts.push({ sheetid: "Commercial Time Breakdown", header: true });
        let data = [];

        bands.map((band) => {
          let finalObj = {};
          finalObj.Band = band;
          finalObj["Hourly Chargeout Rate"] = currentCostingProfile
            .ProfileSetting.CSRateCardUsed
            ? getCurrentCurrency(
                currentCostingProfile.ProfileSetting.CSRateCardUsed[
                  rateCardReferences[band]
                ]
              )
            : getCurrentCurrency(0);

          if (
            _.includes(
              pacificMarkets,
              currentCostingProfile.Project.CommissioningCountry
            )  && project.CostingTemplateVersion != "v2.0"
          ) {
            waveSpecs.map((wave) => {
              finalObj[
                `#${wave.WaveNumber}${wave.WaveName ? ` ${wave.WaveName}` : ""}`
              ] = wave.CommercialHoursData
                ? wave.CommercialHoursData[band]
                  ? wave.CommercialHoursData[band]["Total"]
                  : "-"
                : "-";
            });
          }
          data.push(finalObj);
        });
        if (!data.length)
          data.push({
            Component: "",
            Total: "",
          });
        allTabs.push(data);
      }

      if (_.includes(selectedProperties, "profitability")) {
        let data = [];
        opts.push({ sheetid: "Profitability Overview", header: false });

        if (
          currentCostingProfile.Project.CostingTemplateVersion != "v2.0" ||
          (_.includes(
            pacificMarkets,
            currentCostingProfile.Project.CommissioningCountry
          )  && project.CostingTemplateVersion != "v2.0")
        ) {
          data.push({
            Component: "Total Internal Operations Cost",
            Cost: getCurrentCurrency(
              currentCostingProfile.CostTotalInternalOperations
            ),
          });
          data.push({
            Component: "Total External Operations / OOP / Third Party Cost",
            Cost: getCurrentCurrency(
              currentCostingProfile.CostTotalExternalOperations
            ),
          });
          data.push({
            Component: "Total Internal Commercial Cost",
            Cost: getCurrentCurrency(
              currentCostingProfile.CostTotalInternalCommercial
            ),
          });
          data.push({
            Component: "Total External Commercial Cost",
            Cost: getCurrentCurrency(
              currentCostingProfile.CostTotalExternalCommercial
            ),
          });
          data.push({
            Component: "MCP Local Margin cost",
            Cost: getCurrentCurrency(currentCostingProfile.CostTotalMCPMargin),
          });
          data.push({
            Component: [
              `Overheads ${
                currentCostingProfile.ProfileSetting?.PercentOverhead * 100
              }%`,
            ],
            Cost: getCurrentCurrency(currentCostingProfile.Overheads),
          });
          data.push({
            Component: [
              `Markup to get ${
                currentCostingProfile.ProfileSetting
                  ? currentCostingProfile.ProfileSetting.UsesOOPMarkUp
                    ? currentCostingProfile.ProfileSetting
                        .TargetPercentOOPMarkUp * 100
                    : currentCostingProfile.ProfileSetting
                        .TargetPercentContributionMargin * 100
                  : ""
              }% ${
                currentCostingProfile.ProfileSetting?.UsesOOPMarkUp
                  ? "OOP"
                  : "Contribution Margin"
              }`,
            ],
            Cost: getCurrentCurrency(currentCostingProfile.Markup),
          });
          data.push({
            Component: "%Margin",
            Cost: currentCostingProfile.PriceToClient
              ? `${_.round(
                  ((_.round(currentCostingProfile.TotalInternalCosts, 2) +
                    _.round(currentCostingProfile.TotalExternalCosts, 2)) /
                    currentCostingProfile.PriceToClient) *
                    100,
                  2
                )}%`
              : "0%",
          });
          data.push({
            Component: "Minimum Recommended Price to Client",
            Cost: getCurrentCurrency(currentCostingProfile.RecommendedPrice),
          });
          data.push({
            Component: "Actual Price Given To Client",
            Cost: getCurrentCurrency(currentCostingProfile.PriceToClient),
          });
          if (!data.length)
            data.push({
              Component: "",
              Cost: "",
            });
        } else {
          if (
            project.PriceChange === 2 ||
            (_.includes(pacificMarkets, project.CommissioningCountry)&& project.CostingTemplateVersion != "v2.0")
          ) {
            profitabilityTable.push({
              id: "RecommendedPrice",
              label: "Recommended Price To Client (Revenue)",
              isPrice: true,
            });
          }

          profitabilityTable.map((row) => {
            let finalObj = {};
            finalObj.Parameter = row.label;
            finalObj[
              `Grand Total(LCY - ${getCurrencyUnit(
                currentCostingProfile.CostInputCurrency
              )})`
            ] = row.isPrice
              ? getGrandTotalWithoutCurrency(currentCostingProfile[row.id])
              : `${_.round(currentCostingProfile[row.id] * 100, 2)} %`;

            if (currentCostingProfile.CostInputCurrency !== "US-USD") {
              finalObj["Grand Total(USD)"] = currentCostingProfile[row.id]
                ? row.isPrice
                  ? _.round(currentCostingProfile[row.id], 2)
                  : _.round(currentCostingProfile[row.id] * 100, 2)
                : 0;
              finalObj["Grand Total(USD)"] += row.isPrice ? "" : "%";
            }
            if (currentCostingProfile.IsMultiCountry) {
              currentCostingProfile.CountrySpecs.map((cs) => {
                finalObj[cs.label] = row.isPrice
                  ? getCurrentCurrency(cs[row.id])
                  : `${_.round(cs[row.id] * 100, 2)} %`;
              });
            }

            data.push(finalObj);
          });
        }

        allTabs.push(data);
      }
      if (_.includes(selectedProperties, "profitabilitychecks")) {
        opts.push({ sheetid: "Profitability Checks", header: false });
        let data = [];
        if (
          currentCostingProfile.ApprovalDetails &&
          currentCostingProfile.ApprovalDetails.length &&
          currentCostingProfile.ProfileSetting &&
          currentCostingProfile.ProfileSetting.NeedsOutOfPocketCostCheck
        ) {
          data.push({
            "Profitability Checks": "Project Margin %",
            Target: getOOPThresholdText(),
            Current: currentCostingProfile.OutOfPocketCostPercent
              ? `${_.round(
                  currentCostingProfile.OutOfPocketCostPercent * 100,
                  2
                )}%`
              : "",
          });
        }
        if (currentCostingProfile.ProfileSetting?.NeedsCommercialCostCheck) {
          data.push({
            "Profitability Checks": "Commercial Cost %",
            Target: `${
              currentCostingProfile.ProfileSetting.ThresholdPercentIntCommCost *
              100
            }% or more`,
            Current: currentCostingProfile.InternalCommercialCostPercent
              ? `${_.round(
                  currentCostingProfile.InternalCommercialCostPercent * 100,
                  2
                )}%`
              : "",
          });
        }
        if (currentCostingProfile.ProfileSetting?.NeedsNetRevenueCheck) {
          data.push({
            "Profitability Checks": "Net Revenue %",
            Target: `${
              currentCostingProfile.ProfileSetting.ThresholdPercentNetRevenue *
              100
            }% or more`,
            Current: currentCostingProfile.NetRevenuePercent
              ? `${_.round(currentCostingProfile.NetRevenuePercent * 100, 2)}%`
              : "",
          });
        }
        if (
          currentCostingProfile.ProfileSetting?.NeedsContributionMarginCheck
        ) {
          data.push({
            "Profitability Checks": "Minimum Contribution Margin %",
            Target: `${
              currentCostingProfile.ProfileSetting
                .TargetPercentContributionMargin * 100
            }% or more`,
            Current: currentCostingProfile.ContributionMarginPercent
              ? `${_.round(
                  currentCostingProfile.ContributionMarginPercent * 100,
                  2
                )}%`
              : "",
          });
        }
        if (
          currentCostingProfile.ProfileSetting?.NeedsMinimumProjectValueCheck
        ) {
          data.push({
            "Profitability Checks": "Minimum Project Value (Price to Client)",
            Target: getActualPrice(
              currentCostingProfile.ProfileSetting.ThresholdPriceToClient,
              true
            ),
            Current: getActualPrice(currentCostingProfile.PriceToClient, true),
          });
        }
        if (!data.length)
          data.push({
            "Profitability Checks": "",
            Target: "",
            Current: "",
          });
        allTabs.push(data);
      }

      if (_.includes(selectedProperties, "shoppersbreakdown")) {
        opts.push({
          sheetid: "Shoppers Cost (Per Country)",
          header: true,
        });
        let finalData = [];
        Object.keys(methCountryCostSet).map((meth) => {
          let methtitleObj = {};
          methtitleObj.Component = methCountryCostSet[meth][meth];
          methtitleObj.Total = "";
          Object.keys(methCountryCostSet[meth])?.map((cs) => {
            methtitleObj[cs] = "";
          });
          finalData.push(methtitleObj);
          shopperCostFieldsLookup[meth]?.map((scfl) => {
            let finalObjTotal = {};
            finalObjTotal.Component = shopperCostLabels[scfl].label;
            finalObjTotal.Total = getTotalShopperFinal(meth, scfl);
            Object.keys(methCountryCostSet[meth])?.map((cs) => {
              finalObjTotal[cs] = {};
              finalObjTotal[cs] = methCountryCostSet[meth][cs]
                ? getCurrentCurrency(
                    _.sum(
                      shopperCostLabels[scfl]?.properties.map((_prop) =>
                        methCountryCostSet[meth][cs][_prop]
                          ? parseFloat(methCountryCostSet[meth][cs][_prop])
                          : 0
                      )
                    )
                  )
                : "-";
            });
            finalData.push(finalObjTotal);
            shopperCostLabels[scfl]?.properties.map((_prop) => {
              let finalObj = {};
              finalObj.Component = _prop;
              finalObj.Total = getTotalShopper(meth, _prop);
              Object.keys(methCountryCostSet[meth])?.map((cs) => {
                finalObj[cs] = {};
                finalObj[cs] = methCountryCostSet[meth][cs]
                  ? getCurrentCurrency(methCountryCostSet[meth][cs][_prop])
                  : "0";
              });
              finalData.push(finalObj);
            });
          });
          // finalData.push(finalList);
        });
        allTabs.push(finalData);
      }
      if (_.includes(selectedProperties, "costingSheet")) {
         sharepointExcel = await sharepointcall();
      }
      // let filename = `${currentCostingProfile.Project?.ProjectId}${
      //   currentCostingProfile.Project.ProjectName
      //     ? `_${currentCostingProfile.Project.ProjectName}`
      //     : ""
      // }_${currentCostingProfile.ProfileNumber}${
      //   currentCostingProfile.ProfileName
      //     ? `_${currentCostingProfile.ProfileName}`
      //     : ""
      // }_summary`;
      // alasql(`SELECT INTO XLSX("${filename}.xlsx",?) FROM ?`, [
      //   opts,
      //   [...allTabs],
      // ]);
      exportToExcel([opts, [...allTabs], sharepointExcel])
      dispatch(pageLoadEnd());
      dispatch(recordLoadEnd());
    } catch (err) {
      toastr.error("Error message", err);
      console.log(err.message);
    }
  };
  const sharepointcall=async()=>{

 
    try {
          // let result;
    let payload={
      methodology:[]
    }
    profile?.CountrySpecs[0]?.MethodologySpecs.map((method)=>{
      payload["methodology"].push(methodologyMapping[method.Label])
    })
    payload["methodology"].push("Summary")
    const result = axios.post(`/utils/one-drive/get-costing-sheet/${profile.CostingsSheetId}`,payload)
    return result;
    } catch (err) {
      dispatch(localPageLoadEnd())
      toastr.error("Couldn't open the requested resource", err?.data?.message)
  };
  }


  const joinedRFQOptionsData = (rfqOptionsData) => {
    if (rfqOptionsData.length) {
      let joinedRFQDataArray = [];
      rfqOptionsData.map((data) => {
        joinedRFQDataArray.push(data.label);
      });

      return joinedRFQDataArray.join();
    } else {
      return rfqOptionsData.join();
    }
  };
  //download summary- end
  const getTotalByCostingOption = (costingOptions, ccf) => {
    let totalValue = 0;
    costingOptions
      ?.filter((selectedCO) => selectedCO.CoSelectedForSummary)
      ?.map((co) => (totalValue = totalValue + co[ccf]));
    return totalValue;
  };
  const prepareCostBreakup = () => {
    let data = [];
    currentCostingProfile.CountrySpecs.map((cs) => {
      cs?.MethodologySpecs.map((sm) => {
        costFieldsByCostingOption[sm.id] &&
          Object.keys(costFieldsByCostingOption[sm.id])?.map((cf) => {
            if (
              alternativeLabels[cf] &&
              Object.keys(alternativeLabels[cf]).filter(
                (al) => currentCostingProfile.ProfileSetting[al]
              ).length
            ) {
              let finalObj = {};
              finalObj.Component =
                "Breakdown Not Available - Using OOP % Multiplier";
              finalObj.Total = `${
                currentCostingProfile.ProfileSetting[
                  _.head(Object.values(alternativeLabels[cf]))
                ] * 100
              }%`;
              currentCostingProfile.CountrySpecs.map((cs) => {
                finalObj[getLabel("FieldingCountriesOptions", cs.CountryCode)] =
                  "";
              });
              data.push(finalObj);
            } else if (costFieldsByCostingOption[sm.id][cf].length) {
              let finalObjTotal = {};
              getCostComponent(
                finalObjTotal,
                getLabelForCode,
                cf,
                currentCostingProfile,
                getCurrentCurrency,
                getTotalByCostingOption,
                sm,
                cs,
                data
              );
              costFieldsByCostingOption[sm.id][cf]?.map((ccf) => {
                let finalObjComponent = {};
                getCostComponent(
                  finalObjComponent,
                  getLabelForCode,
                  ccf,
                  currentCostingProfile,
                  getCurrentCurrency,
                  getTotalByCostingOption,
                  sm,
                  cs,
                  data
                );
              });
            }
          });
      });
    });
    if (!data.length)
      data.push({
        Component: "",
        Total: "",
      });
    return data;
  };

  let location = useLocation();
  const [isApprovalModalOpen, handleApprovalModal] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleLogOut = () => {
    props.onLogOut();
    history.push("/auth/login");
  };

  const [methodologySelection, openMethodologySelection] = useState(false);
  const [selectedMethodologies, setSelectedMethodologies] = useState([]);
  const canRenderShopper = () => {
    //because pacific uses input costs page itself- no need for input shoppers
    if (_.includes(pacificMarkets, project.CommissioningCountry)&& project.CostingTemplateVersion != "v2.0") {
      return false;
    }
    if (currentCostingProfile?.CountrySpecs?.length) {
      if (
        _.head(currentCostingProfile.CountrySpecs)?.MethodologySpecs?.filter(
          (ms) => _.includes(shopperMethodologies, ms.Code)
        ).length
      )
        return true;
      return false;
    }
    return false;
  };
  const onlyShopper = () => {
    //because pacific uses input costs page itself
    if (_.includes(pacificMarkets, project.CommissioningCountry)&& project.CostingTemplateVersion != "v2.0") return false;
    if (project.CostingTemplateVersion == "v2.0") {
      return false;
    }
    if (currentCostingProfile?.CountrySpecs?.length) {
      let availableMeths = _.head(
        currentCostingProfile.CountrySpecs
      )?.MethodologySpecs;
      if (
        availableMeths?.filter((ms) =>
          _.includes(shopperMethodologies, ms.Code)
        ).length == availableMeths?.length
      )
        return true;
      return false;
    }
    return false;
  };

  const submitSelection = (costType) => {
    setCostSelection(false);
    if (costType == parseInt(codeLabels.CostingType[0].Code)) {
      history.push({
        pathname: `/costing-input/${currentCostingProfile.id}`,
        state: "DIRECTLY",
      });
    } else if (costType == parseInt(codeLabels.CostingType[1].Code)) {
      // history.push({
      //   pathname: `/costing-input/${currentCostingProfile.id}`,
      //   state: "SHEETS",
      // });
    } else if (costType == parseInt(codeLabels.CostingType[2].Code)) {
      let sampleSizeavailable = false;
      currentCostingProfile.CountrySpecs.forEach((c, i) => {
        c.MethodologySpecs.forEach((m, mi) => {
          if (
            preValue[i].Methodology.filter((meth) => meth.value == m.Code)[0]
              .costType == 3
          ) {
            m.CostingOptions.forEach((co) => {
              if (
                co.isSelected &&
                ((co?.CostingOptionData?.sampleSizeMain ||
                  co?.CostingOptionData?.sampleSize ||
                  co?.CostingOptionData?.numberOfGroup) <= 0 ||
                  isNaN(
                    co?.CostingOptionData?.sampleSizeMain ||
                      co?.CostingOptionData?.sampleSize ||
                      co?.CostingOptionData?.numberOfGroup
                  ))
              ) {
                sampleSizeavailable = true;
              }
            });
          }
        });
      });
      if (sampleSizeavailable) {
        toastr.error(
          "Please Add SampleSize/Number of Groups for the Methodologies which are Selected for Vendor Bidding"
        );
        return true;
      } else {
        history.push(
          "/vendordashboard/vendor-bidding/" +
            project.ProjectId +
            "/" +
            _.last(project.CostingProfiles).id
        );
      }
    }
    dispatch(recordLoadEnd());
  };
  const checkFunction = () => {
    var lengthMethodology = currentCostingProfile.CountrySpecs.map((cs) => {
      return cs.MethodologySpecs.length;
    }).reduce((result, sm) => result + sm);

    var flaglength = currentCostingProfile.CountrySpecs.map((cs) => {
      return cs.MethodologySpecs.filter((f) => !f.Flag).length;
    }).reduce((result, sm) => result + sm);

    if (lengthMethodology != flaglength) {
      setShowCostMethod(true);
    } else if (
      preValue &&
      preValue
        .map((sm) => {
          return sm?.Methodology?.filter((cm) => cm.costType > 0).length;
        })
        .reduce((result, sp) => result + sp) == lengthMethodology
    ) {
      if (
        lengthMethodology ==
        preValue
          .map((sm) => {
            return sm?.Methodology?.filter((cm) => cm.costType == 1).length;
          })
          .reduce((result, sp) => result + sp)
      )
        submitSelection(1);
      else if (
        lengthMethodology ==
        preValue
          .map((sm) => {
            return sm?.Methodology?.filter((cm) => cm.costType == 2).length;
          })
          .reduce((result, sp) => result + sp)
      )
        submitSelection(2);
      else if (
        lengthMethodology ==
        preValue
          .map((sm) => {
            return sm?.Methodology?.filter((cm) => cm.costType == 3).length;
          })
          .reduce((result, sp) => result + sp)
      )
        submitSelection(3);
      else {
        setCostSelection(true);
      }
    } else setShowCostMethod(true);
  };

  const chooseCostMethod = (method) => {
    if (!currentCostingProfile.CostingType && method != "VENDOR") {
      let _currentCostingProfile = _.cloneDeep(currentCostingProfile);
      _currentCostingProfile = {
        ..._currentCostingProfile,
        Project: {
          ..._currentCostingProfile.Project,
          CostingTemplateVersion: method == "SHEETS" ? "v2.0" : "",
        },
      };
      //old projects- if costing type hasnt selected yet
      dispatch(
        currentProjectActions.saveProject(
          {
            ...project,
            CostingTemplateVersion: method == "SHEETS" ? "v2.0" : "",
          },
          () => {
            dispatch(
              currentProjectActions.setCurrentProject({
                ..._.cloneDeep(project),
                CostingTemplateVersion: method == "SHEETS" ? "v2.0" : "",
              })
            );
            _currentCostingProfile.CountrySpecs =
              _currentCostingProfile.CountrySpecs.map((cs) => {
                cs.MethodologySpecs?.map((ms) => {
                  if (ms && !ms.NotApplicable)
                    ms.CostingType = CostingTypesEnum.MSSHEETS;
                });
                return { ...cs };
              });
            dispatch(
              currentCostingActions.saveCostingProfile(
                {
                  ..._currentCostingProfile,
                  Project: {
                    ..._currentCostingProfile.Project,
                    CostingTemplateVersion: method == "SHEETS" ? "v2.0" : "",
                  },
                  CostingType: method,
                },
                null,
                true
              )
            );
            dispatch(
              currentCostingActions.updateProfile({
                ..._currentCostingProfile,
                CostingType: method,
              })
            );
          }
        )
      );
      // dispatch(currentProjectActions.setCurrentProject({ ...project, CostingTemplateVersion: method == "SHEETS" ? "v2.0" : "" }))
    }

    let actualPath = _.head(location.pathname?.replace("/", "").split("/"));

    // dispatch({
    //   type: currentCostingActions.UPDATE_NEW_COSTING,
    //   currentCostingProfile: { CostingType: method },
    // });
    switch (method) {
      case "VENDOR":
        if (!currentCostingProfile.VendorBiddingSubmethodologies) {
          openMethodologySelection(true);
        } else {
          setTimeout(() => {
            dispatch(
              setCostingStatus(
                {
                  ...costingStatus,
                  showSheetsCosts: false,
                  // showManualCostEntry: true,
                  showCommercialCosts: false,
                  // showCostingInput: false
                },
                actualPath == "proposal-new" ? null : history
              )
            );
            setShowCostMethod(false);
          });
        }
        return;

      case "SHEETS":
        let currentcostingprofile = { ...currentCostingProfile };
        currentcostingprofile.CountrySpecs =
          currentcostingprofile.CountrySpecs.map((cs) => {
            cs.MethodologySpecs = cs.MethodologySpecs.map((ms) => {
              ms.TimingsSchema = ms.TimingsSchema
                ? ms.TimingsSchema
                : currentcostingprofile.ProfileSetting
                    .GlobalCostingSheetTimingsSchema;
              ms.CostsSchema = ms.CostsSchema
                ? ms.CostsSchema
                : currentcostingprofile.ProfileSetting
                    .GlobalCostingSheetCostsSchema;
              return { ...ms };
            });
            return { ...cs };
          });
        dispatch(currentCostingActions.updateProfile(currentcostingprofile));
        setTimeout(() => {
          dispatch(
            setCostingStatus(
              {
                ...costingStatus,
                showSheetsCosts: false,
                // showManualCostEntry: true,
                showCommercialCosts: false,
                // showCostingInput: false
              },
              // () => history.push(`/costing-input/${currentCostingProfile.id}`)
            )
          );

          setShowCostMethod(false);
        });
        return;

      case "DEFAULT":
        setTimeout(() => {
          dispatch(
            setCostingStatus(
              {
                ...costingStatus,
                showSheetsCosts: false,
                // showManualCostEntry: true,
                showCommercialCosts: false,
                showCostingInput: false,
              },
              () => history.push(`/costing-input/${currentCostingProfile.id}`)
            )
          );

          setShowCostMethod(false);
        });
        return;
    }
  };

  function getFinalCostMethodologyList() {
    let finalFields = [];
    _.head(currentCostingProfile.CountrySpecs)?.MethodologySpecs.map((ms) => {
      finalFields.push({
        value: ms.Code,
        label: `${ms.Label} (${getLabel(
          "MethodologyOptions",
          ms.ParentMethodologyCode
        )})`,
      });
    });
    //setcmlist(finalFields);
    return finalFields;
  }
  function commissionProject() {
    var vendor = false;
    var check = true;
    currentCostingProfile.CountrySpecs?.forEach((c) => {
      c.MethodologySpecs.forEach((m) => {
        if (m.CostingType == 3) {
          vendor = true;
        }
      });
    });
    if (vendor) {
      if (currentCostingProfile.VenodrBidDeadLineStatus != "close") {
        check = false;
        toastr.error("Bidding has not been closed in Vendor Module");
      }
    }
    if (check) {
      //todo: implement with validations with new changes
      if (
        haveCostingOptions ||
        (_.includes(pacificMarkets, project.CommissioningCountry)&& project.CostingTemplateVersion != "v2.0")
      ) {
        setValidationHeader("Cannot Proceed to Commission");
        if (
          project.IsSyndicated &&
          project.ParentProjectId &&
          !project.IsCopy
        ) {
          setCommisionModalStatus();
        } else if (validateForms()) {
          if (currentCostingProfile.OutOfPocketCostPercent)
            setCommisionModalStatus();
          else
            toastr.error(
              "Cannot Proceed to Commission",
              "Cannot proceed without Out Of Pocket Cost Percent"
            );
        }
      } else {
        toastr.error(
          "Cannot Proceed to Commission",
          "Cannot proceed without Costing Options. Please provide Costing options"
        );
      }
    }
  }
  function getFinalCostMethodologyList1() {
    let finalFields1 = [];
    let finalFields = [];
    if (
      currentCostingProfile &&
      currentCostingProfile.CountrySpecs &&
      currentCostingProfile.CountrySpecs.length > 0
    ) {
      currentCostingProfile.CountrySpecs.map((fc) => {
        finalFields = [];
        fc.MethodologySpecs.map((sm) =>
          finalFields.push({ value: sm.Code, label: sm.Label })
        );
        finalFields1.push(finalFields);
      });
    }

    return finalFields1;
  }

  const getFinalCostFields = () => {
    let finalFields = [];
    _.head(currentCostingProfile.CountrySpecs)?.MethodologySpecs.map((ms) => {
      finalFields.push({
        value: ms.id,
        label: `${ms.Label} (${getLabel(
          "MethodologyOptions",
          ms.ParentMethodologyCode
        )})`,
      });
      // if (ms.CostsSchema) {
      //   ms.CostsSchema.map(cs => {
      //     if (!_.includes(finalFields, cs.title))
      //       finalFields.push(cs.title)
      //   })
      // }
    });
    return finalFields;
  };
  const profile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  const isInputCostDisabled = () => {
    let checkValue = false;
    if (
      (profile.ProfileStatus == "5" && !profile.NeedsApproval) ||
      profile.ProfileStatus == "1"
    ) {
      checkValue = true;
    }
    return checkValue;
  };
  const showScheduleSummary = () => {
    // let reqProjectStatus = [1, 3, 4, 5, 98]
    // if (currentCostingProfile.Project
    //   && (_.includes(reqProjectStatus, parseInt(currentCostingProfile.Project.ProjectStatus)))) {
    //   return true
    // } else return false
    let path = location.pathname.split("/");
    const pages = [
      "costing",
      "summary",
      "schedule",
      "overridecosts",
      "countryoverridecosts",
      "proposal-new",
      "costing-input",
      "commercial-costing-input",
      "shopper-costing-input",
      "vendordashboard",
    ];
    return pages.indexOf(path[1]) !== -1;
  };
  const isSchedule = () => {
    let path = location.pathname.split("/");
    return path.length && path[1] == "schedule";
  };
  async function openCreateSheet() {
		if (!currentCostingProfile.CostingsSheetId) {
			toastr.info("Costing Sheet is being created...");

			dispatch(pageLoadStart());
			axios
				.post("/utils/sheets/" + currentCostingProfile.id + "/costing")
				.then((res) => {
					dispatch(
						currentCostingActions.updateProfile({
							...currentCostingProfile,
							CostingsSheetId: res.data.CostingsSheetId,
						})
					);
					updateProject({
						...currentCostingProfile,
						CostingsSheetId: res.data.CostingsSheetId,
					})

					dispatch(
						currentCostingActions.saveCostingProfile(
							{
								...currentCostingProfile,
								CostingsSheetId: res.data.CostingsSheetId,
							},
							null,
							true
						)
					);
					toastr.success("Sheet created Successfully", res.data.message);
					// window.open(`https://docs.google.com/spreadsheets/d/${res.data.CostingsSheetId}`, "_blank");

					// if (res.data.CostingsSheetId && project.CostingTemplateVersion != "v2.0") {
					if (res.data.CostingsSheetId && project.CostingTemplateVersion != "v2.0") {
						toastr.info("Sheet is being prepared", "Sheet Preparation might take sometime to setup your project configuration", { timeOut: 20000 });
						setTimeout(() => {
							toastr.info("It is about to complete", "Please do not refresh or close the page, sheet preparation is inprogress", { timeOut: 80000 });
						}, 20000);
						setTimeout(() => {
							toastr.info("Opening the sheet", null);
							dispatch(sheetCall.msSheetAPiCall(res.data.CostingsSheetId, true, true, () => dispatch(pageLoadEnd())));
							// dispatch(pageLoadEnd());

						}, 100000);

					}
					else if (res.data.CostingsSheetId && project.CostingTemplateVersion == "v2.0") {
						dispatch(sheetCall.msSheetAPiCall(res.data.CostingsSheetId, true, true, () => dispatch(pageLoadEnd())));

					}

				})
				.catch((err) => {
					dispatch(pageLoadEnd());
					// setSyncAbility(false)
					toastr.error("Creating Sheet Failed", err?.data.message);
				});
		}
		else {

			// window.open(`https://docs.google.com/spreadsheets/d/${currentprofile.CostingsSheetId}`, "_blank");
			//todo: handle in open cost sheet inside then block, avoid sending dispatch
			dispatch(sheetCall.msSheetAPiCall(currentCostingProfile.CostingsSheetId, true, true, () => dispatch(recordLoadEnd())))

		}
	}
  async function openSheet1() {
		dispatch(recordLoadStart());
		if (!project.CostingsFolderId && !project.ProjectResourcesFolderId) {
			axios
				.post("/utils/folders/" + project.id)
				.then((res) => {
					dispatch(currentProjectActions.setCurrentProject({
						CostingsFolderId: res.data.CostingsFolderId,
						ProjectResourcesFolderId: res.data.ProjectResourcesFolderId,
					}))
					openCreateSheet()
					toastr.success("One Drive folders are now ready for use");
				})
				.catch(() => {
					toastr.error("One Drive folder creation failed");
				})
		}
		else {
			openCreateSheet()
		}
	};

  async function openSheet() {
    dispatch(recordLoadStart());

    if (!currentCostingProfile.CostingsSheetId) {
      toastr.info("Costing Sheet is being created...");

      dispatch(pageLoadStart());
      axios
        .post("/utils/sheets/" + currentCostingProfile.id + "/costing")
        .then((res) => {
          let _currentCostingProfile = {
            ...currentCostingProfile,
            Project: {
              ...currentCostingProfile.Project,
              CostingTemplateVersion: "v2.0",
            },
          };
          dispatch(
            currentCostingActions.updateProfile({
              ..._currentCostingProfile,
              CostingsSheetId: res.data.CostingsSheetId,
            })
          );
          updateProject(
            {
              ..._currentCostingProfile,
              CostingsSheetId: res.data.CostingsSheetId,
            },
            true
          );

          dispatch(
            currentCostingActions.saveCostingProfile(
              {
                ..._currentCostingProfile,
                CostingsSheetId: res.data.CostingsSheetId,
              },
              null,
              true
            )
          );
          toastr.success("Sheet created Successfully", res.data.message);
          // window.open(`https://docs.google.com/spreadsheets/d/${res.data.CostingsSheetId}`, "_blank");

          // if (res.data.CostingsSheetId && project.CostingTemplateVersion != "v2.0") {
          if (
            res.data.CostingsSheetId &&
            project.CostingTemplateVersion != "v2.0"
          ) {
            toastr.info(
              "Sheet is being prepared",
              "Sheet Preparation might take sometime to setup your project configuration",
              { timeOut: 20000 }
            );
            setTimeout(() => {
              toastr.info(
                "It is about to complete",
                "Please do not refresh or close the page, sheet preparation is inprogress",
                { timeOut: 80000 }
              );
            }, 20000);
            setTimeout(() => {
              toastr.info("Opening the sheet", null);
              dispatch(
                sheetCall.msSheetAPiCall(
                  res.data.CostingsSheetId,
                  true,
                  true,
                  () => dispatch(pageLoadEnd())
                )
              );
              // dispatch(pageLoadEnd());
            }, 100000);
          } else if (
            res.data.CostingsSheetId &&
            project.CostingTemplateVersion == "v2.0"
          ) {
            dispatch(
              sheetCall.msSheetAPiCall(
                res.data.CostingsSheetId,
                true,
                true,
                () => dispatch(pageLoadEnd())
              )
            );
          }
        })
        .catch((err) => {
          dispatch(pageLoadEnd());
          // setSyncAbility(false)
          toastr.error("Creating Sheet Failed", err?.data.message);
        });
    } else {
      // window.open(`https://docs.google.com/spreadsheets/d/${currentCostingProfile.CostingsSheetId}`, "_blank");
      //todo: handle in open cost sheet inside then block, avoid sending dispatch
      dispatch(
        sheetCall.msSheetAPiCall(
          currentCostingProfile.CostingsSheetId,
          true,
          true,
          () => dispatch(recordLoadEnd())
        )
      );
    }
  }
  const setCommisionModalStatus = () => {
    if (
      currentCostingProfile &&
      currentCostingProfile.ProfileSetting &&
      currentCostingProfile.ProfileSetting.NeedsSFStatusCheck &&
      (!currentCostingProfile.Project.IsSyndicated || currentCostingProfile.Project.SyndicatedwithOpp == true)
    ) {
      let reqProjectClients = currentCostingProfile.Project?.ContractDetails;
      let sfClients = reqProjectClients.filter((rpc) => rpc.isSF);
      if (
        sfClients.filter(
          (sc) =>
            sc.Probability >=
            currentCostingProfile.ProfileSetting.MinimumSFProbability
        ).length ||
        !sfClients.length
      ) {
        setCommissionOpen({
          ...isCommissionOpen,
          ModalCommissioning: !isCommissionOpen.ModalCommissioning,
        });
      } else {
        openSaleForceModal(true);
      }
    } else {
      setCommissionOpen({
        ...isCommissionOpen,
        ModalCommissioning: !isCommissionOpen.ModalCommissioning,
      });
    }
  };

  const handlePendingProcess = () => {
    pendingProcessRef.current();
    setUnsavedChangesAlert(false);
    dispatch(valueChangedInRFQAction(false));
    // UpdateCostMethod()
    // submitSelection1()
  };

  const navigateToViewCostSummary = () => {
    dispatch(pageLoadStart());
    // dispatch(
    //   currentCostingActions.saveCostingProfileDraft(
    //     currentCostingProfile,
    //     () => {
          dispatch(
            currentCostingActions.getSummaryCosting(
              currentCostingProfile,
              () => history.push(`/summary/${currentCostingProfile.id}`),
              currentCostingProfile?.IsOPSOOPAdjusted ? false : true
            )
          );
          dispatch(pageLoadEnd());
    //     },
    //     true,
    //     true
    //   )
    // );
  };

  const navigateToInputCommercialCosts = () => {
    gaEventTracker(EventName.InputCommercialCosts);
    if (haveCostingOptions) {
      dispatch(
        setCostingStatus(
          {
            ...costingStatus,
            // showSheetsCosts: false,
            // showManualCostEntry: false,
            // showCommercialCosts: true
          },
          () =>
            history.push(
              `/commercial-costing-input/${currentCostingProfile.id}`
            )
        )
      );
    } else {
      setOpenCOmmercialWarning(true);
    }
  };

  const navigateInputShopperCosts = () => {
    dispatch(
      setCostingStatus(
        {
          ...costingStatus,
          showSheetsCosts: false,
          // showManualCostEntry: false,
          // showCommercialCosts: false,
          // showCostingInput: true,
        },
        () => history.push(`/shopper-costing-input/${currentCostingProfile.id}`)
      )
    );
  };

  const navigateToEditProjectDetails = () => {
    dispatch(currentProjectActions.clearCurrentProject());
    history.push("/proposal-new");
    dispatch(
      currentProjectActions.getProject(
        props.project.ProjectId
          ? props.project.ProjectId
          : currentCostingProfile.Project.ProjectId
      )
    );
    dispatch(currentProjectActions.initProject());
  };

  const navigateToViewProfileComparison = () => {
    dispatch(pageLoadStart());
    dispatch(
      currentProjectActions.getProject(
        currentCostingProfile.Project.ProjectId,
        () => history.push("/profile-Summary")
      )
    );
  };

var fieldingCountries = Array.isArray(currentCostingProfile?.FieldingCountries) ? currentCostingProfile?.FieldingCountries.map(fc => fc.value) : currentCostingProfile?.FieldingCountries?.split(",")
const submitSelection1 = (costType) => {

  var commissiongCountryerror = false;
  var commissiongCountry = false;
  if (!_.includes(fieldingCountries, project.CommissioningCountry)) {
      commissiongCountry = true
  }
  else if (_.includes(fieldingCountries, project.CommissioningCountry)) {
      commissiongCountry = false
  }

  setCostSelection(false);
  if (costType == parseInt(codeLabels.CostingType[0].Code)) {
      history.push(
          {
              pathname: `/costing-input/${currentCostingProfile.id}`,
              state: "DIRECTLY"
          })

  }
  else if (costType == parseInt(codeLabels.CostingType[1].Code)) {

      // history.push({
      //     pathname: `/costing-input/${currentCostingProfile.id}`,
      //     state: "SHEETS"
      // })

  }
  else if (costType == parseInt(codeLabels.CostingType[2].Code)) {
      let sampleSizeavailable = false;
      currentCostingProfile.CountrySpecs.forEach((c, i) => {
          c.MethodologySpecs.forEach((m, mi) => {
              if (value[0].Methodology.filter(meth => meth.value == m.Code)[0].costType == 3 && commissiongCountry) {
                  commissiongCountryerror = true
              }
              if (value[i].Methodology.filter(meth => meth.value == m.Code)[0].costType == 3) {

                  m.CostingOptions.forEach(co => {

                      if (co.isSelected && ((co?.CostingOptionData?.sampleSizeMain || co?.CostingOptionData?.sampleSize ||
                          co?.CostingOptionData?.numberOfGroup) <= 0 || isNaN(co?.CostingOptionData?.sampleSizeMain ||
                              co?.CostingOptionData?.sampleSize || co?.CostingOptionData?.numberOfGroup))) { sampleSizeavailable = true }

                  }
                  )
              }
          })
      })
      if (commissiongCountryerror) {
          toastr.error(`Please Select Costing type as MS Sheet for Commissiong Country as it is removed from Fielding Countries`)
          return true
      }
      if (sampleSizeavailable) {
          toastr.error("Please Add SampleSize/Number of Groups for the Methodologies which are Selected for Vendor Bidding")
          return true
      }

      else {
          dispatch({ type: SET_VENDOR_FLAG, Vendor: true })
          history.push('/vendordashboard/vendor-bidding/' + project.ProjectId +"/"+_.last(project.CostingProfiles).id)
      }
  }
  dispatch(recordLoadEnd());
}
  // To open cost sheet
  const UpdateCostMethod = (method) => {
   
    let list2 = []
    
    console.log("list2",list2);
    var commissiongCountryerror = false;
    var commissiongCountry = false
    let fieldingCountries = Array.isArray(currentCostingProfile.FieldingCountries) ? currentCostingProfile.FieldingCountries.map(fc => fc.value) : currentCostingProfile.FieldingCountries?.split(",")
    if (!_.includes(fieldingCountries, project.CommissioningCountry)) {
        commissiongCountry = true
    }
    else if (_.includes(fieldingCountries, project.CommissioningCountry)) {
        commissiongCountry = false
    }

    console.log("current costing profile",currentCostingProfile)
    let temporary = currentCostingProfile;
    
    project.CostingProfiles.map((item)=>{
      let csList = [];
      console.log("item",item);
      // if(item.CountrySpecs.length>1){
        item.CountrySpecs.map((data)=>{
          console.log("data",data);
          data.MethodologySpecs.map((meth)=>{
            console.log("meth",meth)
            list2.push({
              Code:data.CountryCode,
              Label:data.CountryName,
              Methodology: [
                {
                  value : meth.Code,
                  label:meth.Label,
                  costType:2,
                  shoppers:false,
                  selected:true
                }
              ]
            })
            // if(list2.length< 1 ){
            //   list2.push(csList)
            // }
            // else{
            //   csList.forEach((cs)=>
 
            //     list2[0].forEach((item)=>{
            //     if(cs.Code == item.Code){
            //     item.Methodology = {...item.Methodology,...cs.Methodology}
            //     }
            //     })
            //     )
              
            // }
          })
          
        })
      // }
      // else{
      //   item.CountrySpecs.map((cs,csi)=>{
      //     list2.push({
      //       Code:cs.CountryCode,
      //           Label:cs.CountryName,
      //     })
      //     cs.MethodologySpecs.map((ms,msi)=>{
      //       if(Object.keys(list2[csi].includes("Methodology"))){
      //         list2[csi].Methodology.push({
      //           value : ms.Code,
      //               label:ms.Label,
      //               costType:2,
      //               shoppers:false,
      //               selected:true
      //         })
      //       }
      //       list2[csi]["Methodology"] = {
      //         value : ms.Code,
      //               label:ms.Label,
      //               costType:2,
      //               shoppers:false,
      //               selected:true
      //       }
      //       list2[csi].push(
      //         {
      //           Methodology: [
      //             {
      //               value : ms.Code,
      //               label:ms.Label,
      //               costType:2,
      //               shoppers:false,
      //               selected:true
      //             }
      //           ]
      //         }
      //       )
      //     })
      //   })
      // }
    })
    console.log("list2",list2);
    const mergedArray = list2.reduce((acc, curr) => {
      const existingItem = acc.find(item => item.Code === curr.Code);
     
      if (existingItem) {
        existingItem.Methodology.push(...curr.Methodology);
      } else {
        acc.push(curr);
      }
     
      return acc;
    }, []);
     
    // console.log(mergedArray);
    console.log("finalList",mergedArray)

    console.log("project123",project)
    value = mergedArray;
    var Vendorflag = value?.map(cm => cm?.Methodology?.filter(cc => cc.costType == 3)?.length).reduce((r, v) => r + v) > 0 ? 3 : 0
    let _currentCostingProfile = _.cloneDeep(currentCostingProfile);
    var allCostingTypeSelection = false;
    var sampleSizeavailable = false;
    //setting MS sheets type

    _currentCostingProfile["updateCost"] = false
    _currentCostingProfile.CountrySpecs.forEach((c, i) => {
        c.MethodologySpecs.forEach((m, mi) => {
            if (value[0].Methodology.filter(meth => meth.value == m.Code)[0].costType == 3 && commissiongCountry) {
                commissiongCountryerror = true
            }
            if (value[i].Methodology.filter(meth => meth.value == m.Code)[0].costType == 3) {
                m.CostingOptions.forEach(co => {
                    if (co.isSelected && ((co?.CostingOptionData?.sampleSizeMain || co?.CostingOptionData?.sampleSize ||
                        co?.CostingOptionData?.numberOfGroup) <= 0 || isNaN(co?.CostingOptionData?.sampleSizeMain ||
                            co?.CostingOptionData?.sampleSize || co?.CostingOptionData?.numberOfGroup))) { sampleSizeavailable = true }
                }
                )


            }
            m.CostingType = value[i].Methodology.filter(meth => meth.value == m.Code)[0].costType
            m.CostingType= 2
        })
        if (value.map(sm => { return (sm?.Methodology?.filter(cm => !cm.costType).length) }).reduce((result, sp) => result + sp)) {
            allCostingTypeSelection = true
        }
        else {
            allCostingTypeSelection = false
        }
    })

    if (allCostingTypeSelection) {
        toastr.error("Please select costing type for all countries/methodologies")
        return true
    }
    if (commissiongCountryerror) {
        toastr.error(`Please Select Costing type as MS Sheet for Commissiong Country as it is removed from Fielding Countries`)
        return true
    }
    if (sampleSizeavailable) {
        toastr.error("Please Add SampleSize/Number of Groups for the Methodologies which are Selected for Vendor Bidding")
        return true
    }
    dispatch(pageLoadStart())
    const _project = update(project, {
        CostingProfiles: {
            [project.CostingProfiles?.length - 1]: {
                $set: _currentCostingProfile
            }
        }
    })
    // _currentCostingProfile.CountrySpecs[0].MethodologySpecs[0].CostingType = 2
    _currentCostingProfile.CountrySpecs.forEach((data)=>{
      data.MethodologySpecs.forEach((meth)=>{
        meth.CostingType = 2
      })
    })

    axios
        .put("/costingprofiles/" + _currentCostingProfile.id, _currentCostingProfile, {
            headers: { "auth-token": localStorage.getItem("auth-token") },
        }).then(res => {
            dispatch({
                type: "UPDATE_NEW_COSTING",
                currentCostingProfile: _currentCostingProfile,
            });
            dispatch(
                currentProjectActions.saveProject({ ..._project, CostingType: Vendorflag })
            );
            dispatch({
                type: currentProjectActions.UPDATE_NEW_PROJECT,
                newProject: { ..._project, CostingType: Vendorflag },
            });
            checkFunction()
            dispatch(pageLoadEnd())
            dispatch(countryActions.setCountrySpecs(_currentCostingProfile.CountrySpecs));

        })
    setShowCostMethod(false);
    setUpdateCost(false)
    let clone = _.cloneDeep(currentCostingProfile)
    clone.updateCost = false
    dispatch({
        type: "UPDATE_NEW_COSTING",
        currentCostingProfile: clone,
    });
    openSheet1()
};

const gotoRFQ = ()=>{
  let loc = window.location.hash
  if(loc == "#/costing"){
    handleOpen(!isModalOpen)
  }
  else{
    history.push("/costing")
  }
  console.log("location",window.location,window)
}
  const navigateToOverrideAutoCosts = () => {
    if (currentCostingProfile.WaveSpecs.length) {
      dispatch(pageLoadStart());
      // dispatch(
      //   currentCostingActions.saveCostingProfile(
      //     currentCostingProfile,
      //     () => {
      // if (!_.includes(pacificMarkets, project.CommissioningCountry)) {
      //   // history.push(`/overridecosts`);
      // }
      // else {
      history.push(`/countryoverridecosts`);
      // }
      dispatch(pageLoadEnd());
      // dispatch(currentCostingActions.generateSummary());
      // }
      // )
      // );
    } else {
      setOpenOverrideAutoCosts(true);
    }
  };

  const navigateToInputCost = () => {
    //todo: if not costing don't trigger below code

    // _.includes(pacificMarkets, project.CommissioningCountry)
    //   ? (
    //     chooseCostMethod("DEFAULT")

    //   ) : checkFunction();

    // setShowCostMethod(true);
    if (pacificMarkets.some((p) => p == project.CommissioningCountry) && project.CostingTemplateVersion != "v2.0") {
      chooseCostMethod("DEFAULT");
    } else if (
      !pacificMarkets.some((p) => p == project.CommissioningCountry)  && project.CostingTemplateVersion != "v2.0")
     {
      chooseCostMethod("SHEETS");
    } else {
      checkFunction();
      // if (!valueChangedInRFQ) {
      //   if (!currentCostingProfile.CostingType)
      //     setShowCostMethod(true);
      //   else chooseCostMethod(currentCostingProfile.CostingType);
      // }
      // else {

      //   setUnsavedChangesAlert(true);
      // }
    }
    // dispatch(
    //   setCostingStatus(
    //     {
    //       ...costingStatus,
    //       showSheetsCosts: false,
    //       showManualCostEntry: true,
    //       showCommercialCosts: false,
    //       showCostingInput: false,
    //     },
    //     history.push("/proposal-new")
    //   )
    // );
  };
  // const ReturnControl = (indexV, indexC, indexM, indexCO) => {
  //   const v =
  //     copyProfile.SelectedCountries[indexC].Methodologies[indexM]
  //       .CostingOptions[indexCO].Variables[indexV] || {};

  //   if (v.type == "integer")
  //     return (
  //       <input
  //         type="number"
  //         name={v.label}
  //         value={v.value}
  //         style={{ width: "100%" }}
  //         onChange={(e) =>
  //           InfoChange(indexC, indexM, indexCO, e.target.name, e.target.value)
  //         }
  //       />
  //     );
  //   else if (v.type == "text")
  //     return (
  //       <input
  //         type="text"
  //         name={v.label}
  //         value={v.value}
  //         style={{ width: "100%" }}
  //         onChange={(e) =>
  //           InfoChange(indexC, indexM, indexCO, e.target.name, e.target.value)
  //         }
  //       />
  //     );
  //   else if (v.type == "textarea")
  //     return (
  //       <input
  //         type="textarea"
  //         name={v.label}
  //         value={v.value}
  //         style={{ width: "100%" }}
  //         onChange={(e) =>
  //           InfoChange(indexC, indexM, indexCO, e.target.name, e.target.value)
  //         }
  //       />
  //     );
  //   else if (v.type == "single")
  //     return (
  //       <SelectWrapper
  //         tag={CustomInput}
  //         name={v.label}
  //         disabled={v.disabled || disableFields}
  //         value={
  //           v.value
  //             ? typeof v.value === "object"
  //               ? v.value.value
  //               : v.value
  //             : null
  //         }
  //         type="select"
  //         onChange={(e) =>
  //           InfoChange(indexC, indexM, indexCO, v.label, e.target.value)
  //         }
  //       >
  //         <option value="0"></option>
  //         {v.optionsLabel
  //           ? codeLabels[v.optionsLabel].map((opt) => (
  //               <option key={opt.Code} value={opt.Code}>
  //                 {opt.Label}
  //               </option>
  //             ))
  //           : v.options.map((option) => (
  //               <option key={option.value} value={option.value}>
  //                 {option.label}
  //               </option>
  //             ))}
  //       </SelectWrapper>
  //     );
  //   else if (v.type == "multi" && v.optionsLabel && v.optionsLabel == "Cities")
  //     return (
  //       <>
  //         {codeLabels["UserScopeOptions"].filter(
  //           (x) => x.Code == profile.CountrySpecs[indexC].CountryCode
  //         )[0] ? (
  //           <Select
  //             name={v.label}
  //             value={v.value}
  //             onChange={(e) => InfoChange(indexC, indexM, indexCO, v.label, e)}
  //             className="react-select-container"
  //             classNamePrefix="react-select"
  //             options={codeLabels["UserScopeOptions"]
  //               .filter(
  //                 (x) => x.Code == profile.CountrySpecs[indexC].CountryCode
  //               )[0]
  //               ["Cities"].map((opt) => {
  //                 return {
  //                   value: opt.id,
  //                   label: opt.Label,
  //                 };
  //               })}
  //             isMulti
  //           />
  //         ) : (
  //           "No City Found"
  //         )}
  //       </>
  //     );
  //   else if (
  //     v.type == "creatableMulti" &&
  //     v.optionsLabel &&
  //     v.optionsLabel == "Cities"
  //   )
  //     return (
  //       <>
  //         {codeLabels["UserScopeOptions"].filter(
  //           (x) => x.Code == profile.CountrySpecs[indexC].CountryCode
  //         )[0] ? (
  //           <CreatableSelect
  //             className="react-select-container"
  //             classNamePrefix="react-select"
  //             value={v.value}
  //             isMulti
  //             onChange={(e) => InfoChange(indexC, indexM, indexCO, v.label, e)}
  //             options={codeLabels["UserScopeOptions"]
  //               .filter(
  //                 (x) => x.Code == profile.CountrySpecs[indexC].CountryCode
  //               )[0]
  //               ["Cities"].map((opt) => {
  //                 return {
  //                   value: opt.id,
  //                   label: opt.Label,
  //                 };
  //               })}
  //           />
  //         ) : (
  //           "No City Found"
  //         )}
  //       </>
  //     );
  //   else if (v.type == "multi") {
  //     return (
  //       <div>
  //         <Select
  //           name={v.label}
  //           value={v.value}
  //           onChange={(e) => InfoChange(indexC, indexM, indexCO, v.label, e)}
  //           className="react-select-container"
  //           classNamePrefix="react-select"
  //           options={
  //             v.optionsLabel
  //               ? codeLabels[v.optionsLabel].map((opt) => {
  //                   return {
  //                     value: opt.Code,
  //                     label: opt.Label,
  //                   };
  //                 })
  //               : v.options.map((option) => {
  //                   return {
  //                     value: option.value,
  //                     label: option.label,
  //                   };
  //                 })
  //           }
  //           isMulti
  //         />
  //       </div>
  //     );
  //   }
  // };
  // function InfoChange(indexC, indexM, indexCO, name, value) {
  //   const VariablesClone = _.cloneDeep(
  //     copyProfile.SelectedCountries[indexC].Methodologies[indexM]
  //       .CostingOptions[indexCO].Variables
  //   );
  //   VariablesClone.filter((x) => x.label == name)[0].value = value;
  //   // let profileTemp = update(copyProfile, {
  //   //     SelectedCountries: {
  //   //         [indexC]: {
  //   //             Methodologies:
  //   //             {
  //   //                 [indexM]:
  //   //                 {
  //   //                     CostingOptions:
  //   //                     {
  //   //                         [indexCO]:
  //   //                         {
  //   //                             Variables:
  //   //                                 { $set: VariablesClone }
  //   //                         }
  //   //                     }
  //   //                 }
  //   //             }
  //   //         }
  //   //     }
  //   // });

  //   let profileTemp = _.cloneDeep(copyProfile);
  //   if (
  //     profile?.SelectedCountries[indexC]?.Methodologies[indexM]?.CostingOptions[
  //       indexCO
  //     ]
  //   )
  //     profile.SelectedCountries[indexC].Methodologies[indexM].CostingOptions[
  //       indexCO
  //     ].Variables = VariablesClone;
  //   setCopyProfile(profileTemp);
  // }
  const navigateToVendorBidding = () => {
    // dispatch(
    //   currentProjectActions.getProject(

    //     currentCostingProfile.Project.ProjectId
    //   ))

    history.push(
      "/vendordashboard/vendor-bidding/" +
        currentCostingProfile.Project.ProjectId +
        "/" +
        currentCostingProfile.id
    );
  };

  return (
    <>
      {isApprovalModalOpen && (
        <ApprovalDelegate
          isApprovalModal={isApprovalModalOpen}
          setApprovalModal={handleApprovalModal}
        />
      )}
      <Navbar color="black" light expand fixed="top" sticky="top">
        {localPageload || recordloading ? <div id="pageCoverSpin"></div> : null}
        <span
          className="sidebar-toggle d-flex mr-2"
          onClick={() => {
            props.onToggleSideBar();
          }}
        >
          <i className="hamburger align-self-center iconcolornavbar" />
          {/* <Hamburger/> */}
        </span>
        <Col xs="4" sm="4" md="4">
          {!props.onlyHeader && (
            <Row>
              <InputGroup className="align-items-center">
                {editableProjectName ? (
                  <Input
                    placeholder="Enter project name..."
                    value={props.project.ProjectName}
                    onChange={(e) =>
                      props.updateProject({ ProjectName: e.target.value })
                    }
                    onBlur={() => setEditableProjectName(false)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        props.updateProject({ ProjectName: e.target.value });
                        setEditableProjectName(false);
                      }
                    }}
                  />
                ) : (
                  <span
                    className={`h5-projectname text-overflow-hidden ${
                      !props.avoidEdit ? "width-90-perc" : ""
                    }`}
                    title={
                      props.project.ProjectName &&
                      props.project.ProjectName != "New Project..."
                        ? props.project.ProjectName.length > 0
                          ? props.project.ProjectName
                          : ""
                        : props.costingProfile &&
                          props.costingProfile.Project &&
                          props.costingProfile.Project.ProjectName
                        ? props.costingProfile.Project.ProjectName
                        : ""
                    }
                  >
                    {props.project.ProjectName &&
                    props.project.ProjectName != "New Project..."
                      ? props.project.ProjectName.length > 0
                        ? props.project.ProjectName
                        : "Enter project name..."
                      : props.costingProfile &&
                        props.costingProfile.Project &&
                        props.costingProfile.Project.ProjectName
                      ? props.costingProfile.Project.ProjectName
                      : null}
                  </span>
                )}
                {!props.avoidEdit ? (
                  <FontAwesomeIcon
                    title="Edit Project Name"
                    className="align-middle mr-2 iconcolornavbar"
                    icon={faPencilAlt}
                    fixedWidth
                    onClick={() => setEditableProjectName(!editableProjectName)}
                  />
                ) : null}
              </InputGroup>
            </Row>
          )}
          {props.CardHeader && (
            <span className={"h5-projectname"}>{props.CardHeader}</span>
          )}

          {!props.onlyHeader && (
            <Row>
              <span className="projectid text-white font-size-sm">
                {props.project.ProjectId
                  ? props.project.ProjectId
                  : props.costingProfile && props.costingProfile.Project
                  ? props.costingProfile.Project.ProjectId
                  : null}
              </span>
            </Row>
          )}
          {props.costingProfile.id && !props.hideProfileName ? (
            <Row>
              <InputGroup className="align-items-center">
                {editableCostingName ? (
                  <Input
                    placeholder="Enter profile name..."
                    value={props.costingProfile.ProfileName}
                    onChange={(e) =>
                      props.updateCosting({ ProfileName: e.target.value })
                    }
                    onBlur={() => setEditableCostingName(false)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        props.updateCosting({ ProfileName: e.target.value });
                        setEditableCostingName(false);
                      }
                    }}
                  />
                ) : (
                  <span
                    className={`h5-projectname text-overflow-hidden ${
                      !props.avoidEdit ? "width-90-perc" : ""
                    }`}
                    title={
                      props.costingProfile.ProfileName &&
                      props.costingProfile.ProfileName.length > 0
                        ? props.costingProfile.ProfileName
                        : ""
                    }
                  >
                    {props.costingProfile.ProfileName &&
                    props.costingProfile.ProfileName.length > 0
                      ? props.costingProfile.ProfileName
                      : "Enter profile name..."}
                  </span>
                )}
                {!props.avoidEdit ? (
                  <FontAwesomeIcon
                    title="Edit Costing Profile Name"
                    className="align-middle mr-2 iconcolornavbar"
                    icon={faPencilAlt}
                    fixedWidth
                    onClick={() => setEditableCostingName(!editableCostingName)}
                    style={{ cursor: "pointer" }}
                  />
                ) : // <Edit title="Edit Costing Profile Name" onClick={() => setEditableCostingName(!editableCostingName)}/>
                null}
              </InputGroup>
            </Row>
          ) : null}
        </Col>

        <Collapse navbar>
          <Nav className="ml-auto" navbar>
            <Col className="hidden-xs mt-2">
              {props.profileStatusToDisplay ? (
                <Col>
                  <Badge
                    className="m-0 h5"
                    color="primary"
                    title="Costing Profile Status"
                  >
                    Profile Status: {props.profileStatusToDisplay}
                  </Badge>
                </Col>
              ) : null}
              {props.projectStatusToDisplay ? (
                <Col className={!props.profileStatusToDisplay ? "mt-2" : ""}>
                  <Badge
                    className="m-0 h5"
                    color="primary"
                    title="Project Status"
                  >
                    Project Status: {props.projectStatusToDisplay}
                  </Badge>
                </Col>
              ) : null}
            </Col>
            {props.costingProfile.id && !props.hideActions ? (
              <div className="mr-1 mb-1 mt-2 btn-group">
                <Button
                  onClick={() => {
                    setIsOpen(!isOpen);
                    setNotes(props.costingProfile.CostingNotes ?? "");
                  }}
                  className="mr-2 btn-header"
                >
                  Notepad
                </Button>
              </div>
            ) : null}
            {props.costingProfile.id && !props.hideActions ? (
              <UncontrolledButtonDropdown
                key={"actions"}
                className="mr-1 mb-1 mt-2 btn-header"
              >
                <DropdownToggle
                  className="action-but-wrapper"
                  caret
                  color={"secondary"}
                >
                  <span className="btn-header action-but">Actions</span>
                </DropdownToggle>
                <DropdownMenu className="actions-dropdown-menu">
                  <DropdownItem
                    disabled={
                      project.ProjectStatus === "7" ||
                      project.PriceChange > 3 ||
                      (project.PriceChange == 3 && (project.ProjectStatus == "3" || project.ProjectStatus == "9")) || 
                      (project.PriceChange == 3) || (project.IsDeleted==1 && project.ProjectStatus =='8')||project.ProjectStatus =='3'||project.ProjectStatus =='9'||project.ProjectStatus =='11'||project.ProjectStatus =='2'||project.ProjectStatus =='4'||project.ProjectStatus =='98'
                    }
                    onClick={() => {
                      gaEventTracker(EventName.CreateNewRequest);
                      setValidationHeader("Please complete answering all mandatory fields before proceeding.");
                      //todo: implement with validations with new changes
                      if (project.IsUploaded) {
                        setUploadProject(true);
                      } else if (
                        (project.IsSyndicated && project.SyndicatedwithOpp !== true) &&
                        project.ParentProjectId &&
                        !project.IsCopy
                      ) {
                        setIsOpenRequest(true);
                      } else if (validateForms()) {
                        setIsOpenRequest(true);
                      }
                    }}
                  >
                    Create New RFQ
                  </DropdownItem>
                  {!(
                    (project.IsSyndicated && project.SyndicatedwithOpp !== true) &&
                    project.ParentProjectId &&
                    !project.IsCopy
                  ) ? (
                    <DropdownItem
                      disabled={
                        // currentCostingProfile.ProfileStatus == 6
                        // || currentCostingProfile.ProfileStatus == 99 ||
                        // !isInputCostDisabled()||currentCostingProfile.ProfileStatus > 1 || onlyShopper()
                        project.ProjectStatus === "7" ||
                        project.PriceChange == 4 ||
                        (project.PriceChange == 3 &&
                          (project.ProjectStatus == "3"|| project.ProjectStatus == "9")) || 
                          (project.PriceChange == 3) ||
                          (project.IsDeleted==1 && project.ProjectStatus =='8') ||
                        !isInputCostDisabled() ||
                        onlyShopper()
                      }
                      onClick={() => {

                        gaEventTracker(EventName.InputCosts);
                        axios
      .get("/requests/" + currentCostingProfile.id + "/all")
      .then((response) => {
       if(response.data.items.length){
        if (
          !valueChangedInRFQ &&
          !currentCostingProfile.ProfileChanged
        ) {
          // navigateToInputCost();
          UpdateCostMethod()
          submitSelection1()
          
        } else {
          setUnsavedChangesAlert(true);
          pendingProcessRef.current = UpdateCostMethod;
        }
       }
       else{
       toastr.info("CS needs to create cost request to proceed further.")
       }
      })
                       
                      }}
                      className="mr-2"
                    >
                      Cost Sheet/ RFQ added Info 
                    </DropdownItem>
                  ) : null}

                  <DropdownItem   
                  onClick={(e)=>{
                    if(currentCostingProfile.CountrySpecs[0].MethodologySpecs[0].CostingOptions[0].id !== undefined){
                      // createtable()
                      if(currentCostingProfile.CSInput !== null){
                        updateCS("CS")
                        // history.push("/internalCost")
 
                      }
                      else{
                        if(project.createdAt < "2024-01-13T00:00:00.000Z" && (!_.includes(pacificMarkets,project.CommissioningCountry)
                        ||(_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0")))
                        {
                          updateInputLevel("CS")
 
                        }
                        else{
                          let validate=validateProfile()
                          if(!validate)
                          {
                            toastr.error("Please click on every methodology/market tab and create the cost options and save it before moving to time cost input")
                          }
                          else
                          {
                            settimecostViewModal(true)
                          }
                        }
                        
                      }
                    }
                    else{
                      toastr.error("Please save RFQ details to proceed")
                    }
                    dispatch(timeCostActions.handleRoleSelection("commercial"))
                    
                  
                
                  }} 
                    >
                     Input Commercial Time Cost
                </DropdownItem>
                <DropdownItem   
                  onClick={(e)=>{
                    if(currentCostingProfile.CountrySpecs[0].MethodologySpecs[0].CostingOptions[0].id !== undefined){
                      if(currentCostingProfile.OpsInput !== null){

                        updateCS("OPS")
                      }
                      else{
                        if(project.createdAt < "2024-01-13T00:00:00.000Z" && (!_.includes(pacificMarkets,project.CommissioningCountry)
                        ||(_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0")))
                        {
                          updateInputLevel("OPS")
 
                        }
                        else{
                          let validate=validateProfile()
                          if(!validate)
                          {
                            toastr.error("Please click on every methodology/market tab and create the cost options and save it before moving to time cost input")
                          }
                          else
                          {
                            settimecostViewModal(true)
                          }
                        }
                      }
                    }
                    else{
                      toastr.error("Please save RFQ details to proceed")
                    }
                    dispatch(timeCostActions.handleRoleSelection('operations'))
                  }} 
                    >
                    Input Operations Time Cost
                </DropdownItem>
                {location.pathname.indexOf("costing") != 1 ? (
                    
                    <DropdownItem
                      disabled={project.PriceChange == 4}
                      onClick={(e) => {
                        gaEventTracker(EventName.ViewCostSummary);
                        if (
                          !valueChangedInRFQ &&
                          !currentCostingProfile.ProfileChanged
                        ) {
                          navigateToViewCostSummary();
                        } else {
                          setUnsavedChangesAlert(true);
                          pendingProcessRef.current =
                            navigateToViewCostSummary;
                        }
                        dispatch({ type: SET_VENDOR_FLAG, Vendor: false });
                      }}
                    >
                      Project Pricing / Commission
                    </DropdownItem>
                  ) : null}
                    {showScheduleSummary() ? (
                    <>
                     
                      {project.IsUploaded && uploadProject && (
                        <UploadedProjectModal
                          uploadProject={uploadProject}
                          setUploadProject={setUploadProject}
                        />
                      )}
                      {(_.includes(
                        pacificMarkets,
                        project.CommissioningCountry
                      ) && project.CostingTemplateVersion != "v2.0") ? (
                        <DropdownItem
                          disabled={
                            project.ProjectStatus === "7" ||
                            project.PriceChange == 4
                          }
                          onClick={() => {
                            if (
                              !valueChangedInRFQ &&
                              !currentCostingProfile.ProfileChanged
                            ) {
                              navigateToViewProfileComparison();
                            } else {
                              setUnsavedChangesAlert(true);
                              pendingProcessRef.current =
                                navigateToViewProfileComparison;
                            }
                          }}
                        >
                          View Profile Comparison
                        </DropdownItem>
                      ) : null}
                      {location.pathname.indexOf("summary") != 1 ? (
                        <DropdownItem
                          disabled={project.PriceChange == 4}
                          onClick={(e) => {
                            gaEventTracker(EventName.ViewCostSummary);
                            if (
                              !valueChangedInRFQ &&
                              !currentCostingProfile.ProfileChanged
                            ) {
                              navigateToViewCostSummary();
                            } else {
                              setUnsavedChangesAlert(true);
                              pendingProcessRef.current =
                                navigateToViewCostSummary;
                            }
                            dispatch({ type: SET_VENDOR_FLAG, Vendor: false });
                          }}
                        >
                          Project Pricing / Commission
                        </DropdownItem>
                      ) : null}

                      {/* <DropdownItem>Open Project Review</DropdownItem> */}
                    </>
                  ) : null}
                  
                  <DropdownItem divider className="dropdown-divider-actionMenu"/>
                  <DropdownItem
                    disabled={project.PriceChange == 4 ||  (project.IsDeleted==true && project.ProjectStatus =='8')}
                    onClick={() => {
                      gaEventTracker(EventName.EditProjectDetails);
                      if (
                        !valueChangedInRFQ &&
                        !currentCostingProfile.ProfileChanged
                      ) {
                        navigateToEditProjectDetails();
                      } else {
                        setUnsavedChangesAlert(true);
                        pendingProcessRef.current =
                          navigateToEditProjectDetails;
                      }
                      dispatch({ type: SET_VENDOR_FLAG, Vendor: false });
                      // currentCostingActions.getCosting(currentCostingProfile.id)
                    }}
                  >
                    Edit Project Details
                  </DropdownItem>

                  {["6", "99"].indexOf(
                        currentCostingProfile.ProfileStatus
                      ) !== -1 &&
                      !(
                        (project.IsSyndicated && project.SyndicatedwithOpp !== true) &&
                        project.ParentProjectId &&
                        !project.IsCopy
                      ) ? (
                        <DropdownItem
                          disabled={project.PriceChange == 4|| (project.IsDeleted==true && project.ProjectStatus =='8')}
                          onClick={() => {
                            if (project.IsUploaded) {
                              setUploadProject(true);
                            } else {
                              dispatch(
                                currentCostingActions.getCosting(
                                  currentCostingProfile.id,
                                  () =>
                                    history.push(
                                      `/schedule/${currentCostingProfile.id}`
                                    )
                                )
                              );
                              dispatch({
                                type: SET_VENDOR_FLAG,
                                Vendor: false,
                              });
                            }
                          }}
                        >
                          Edit Project Schedule
                        </DropdownItem>
                      ) : null}
                  
                  {currentCostingProfile.ProfileStatus > 1 ||
                  (currentCostingProfile.IsImportedProfile &&
                    currentCostingProfile.ProfileStatus != 6) || project.PriceChange == 3 ? null : !(
                      (project.IsSyndicated && project.SyndicatedwithOpp !== true) &&
                      project.ParentProjectId &&
                      !project.IsCopy
                    ) ? (
                    <DropdownItem
                      disabled={
                        project.ProjectStatus === "7" ||
                        project.PriceChange == 4 ||
                        (project.IsDeleted==true && project.ProjectStatus =='8') ||
                        (project.PriceChange == 3 &&
                          (project.ProjectStatus == "3" || project.ProjectStatus == "9"))
                      }
                      onClick={() => {
                        // dispatch(
                        //   setCostingStatus({
                        //     ...costingStatus,
                        //     showSheetsCosts: false,
                        //     showManualCostEntry: false,
                        //     showCommercialCosts: false,
                        //     showCostingInput: false
                        //   })
                        // );
                        //====================
                        // dispatch(
                        //   currentProjectActions.getProject(
                        //     props.project.ProjectId
                        //       ? props.project.ProjectId
                        //       : currentCostingProfile.Project.ProjectId,
                        //     () => {
                        //       history.push("/costing")
                        //       // history.push("/proposal-new")
                        //       //   if (_.includes(pacificMarkets, project.CommissioningCountry))
                        //       //     dispatch(currentCostingActions.getCosting(_.head(project.CostingProfiles).id, () => history.push("/costing")))
                        //       //   else
                        //       //     dispatch(currentCostingActions.getCosting(currentCostingProfile.id, () => history.push("/costing")))
                        //     }
                        //   )
                        // );
                        //===============Performing same dispatch which is present at edit proposal button.
                        gaEventTracker(EventName.EditCostingProfile);
                        dispatch(
                          currentCostingActions.getCosting(
                            currentCostingProfile?.id,
                            () => history.push("/costing")
                          )
                        );
                        dispatch({ type: SET_VENDOR_FLAG, Vendor: false });
                      }}
                    >
                      Edit RFQ Details
                    </DropdownItem>
                  ) : null}
                  {/* todo: avoid hardcoding status and label- move to a  reliable object - all action menu items*/}
                  {parseInt(currentCostingProfile.ProfileStatus) == 6 &&
                  !isSchedule() &&
                  !(
                    (project.IsSyndicated && project.SyndicatedwithOpp !== true) &&
                    project.ParentProjectId &&
                    !project.IsCopy
                  ) ? (
                    <>
                      <DropdownItem
                        disabled={project.PriceChange > 1  || (project.IsDeleted==true && project.ProjectStatus =='8')}
                        onClick={() => {
                          if (project.IsUploaded) {
                            setUploadProject(true);
                          } else if (
                            (!currentWaveSpec ||
                              (currentWaveSpec && !currentWaveSpec.id)) &&
                            currentCostingProfile?.WaveSpecs?.length
                          )
                            dispatch(
                              selectWaveSpec({
                                ..._.head(currentCostingProfile.WaveSpecs),
                              })
                            );
                          if (!project.IsUploaded) {
                            setCommissionOpen({
                              ...isCommissionOpen,
                              ModalDecommissioning:
                                isCommissionOpen.ModalDecommissioning
                                  ? !isCommissionOpen.ModalDecommissioning
                                  : true,
                            });
                          }
                        }}
                      >
                        Edit Commissioned Project
                      </DropdownItem>
                   
                    </>
                  ) : null}
                  <DropdownItem divider className="dropdown-divider-actionMenu"/>
                  <DropdownItem
                    disabled={
                      (project.ParentProjectId && !project.IsCopy) ||
                      project.PriceChange == 4
                    }
                    onClick={() => {
                      // window.open(
                      //   "https://drive.google.com/drive/folders/" +
                      //   project.CostingsFolderId
                      // );
                      if (project.IsUploaded) {
                        setUploadProject(true);
                      } else {
                        dispatch(
                          sheetCall.msSheetAPiCall(
                            project.CostingsFolderId,
                            false
                          )
                        );
                      }
                    }}
                  >
                    Open Costing Folder
                  </DropdownItem>
                  <DropdownItem
                    disabled={project.PriceChange == 4}
                    onClick={() => {
                      gaEventTracker(EventName.OpenProjectFolder);
                      if (project.IsUploaded) {
                        setUploadProject(true);
                      } else {
                        dispatch(
                          sheetCall.msSheetAPiCall(
                            project.ProjectResourcesFolderId,
                            false
                          )
                        );
                      }
                    }}
                  >
                    Open Project Folder
                  </DropdownItem>
                  <DropdownItem
                    disabled={
                      project.ProjectStatus === "7" || project.PriceChange == 4
                    }
                    onClick={() => {
                      gaEventTracker(EventName.ViewRequests);

                      if (project.IsUploaded) {
                        setUploadProject(true);
                      }
                      //todo: implement with validations with new changes
                      else if (
                        (project.IsSyndicated && project.SyndicatedwithOpp !== true) &&
                        project.ParentProjectId &&
                        !project.IsCopy
                      ) {
                        dispatch(setProfileRequests(currentCostingProfile.id));
                        setIsOpenViewRequest(true);
                      } else if (validateForms()) {
                        dispatch(setProfileRequests(currentCostingProfile.id));
                        setIsOpenViewRequest(true);
                      } else {
                        toastr.error(
                          "Cannot Proceed to view requests",
                          "Please fill all the mandatory fields in Methodologies and Operations Resources"
                        );
                      }
                    }}
                  >
                    View Requests
                  </DropdownItem>
                  <DropdownItem   
                  onClick={TimeCostDataExport} 
                    >
                     Download Time Cost Data
                </DropdownItem>
                {location.pathname.indexOf("summary") != -1 ? (
                    <>
                      <DropdownItem
                        disabled={project.ProjectStatus === "7"}
                        onClick={() => {
                          openDownloadModal(true);

                          // if (project.IsUploaded) {
                          //   setUploadProject(true);
                          // } else {
                          //   openDownloadModal(true);
                          // }
                        }}
                      >
                        Download Project Summary
                      </DropdownItem>
                    </>
                  ) : null}
                  <DropdownItem divider className="dropdown-divider-actionMenu"/>
                {/* <DropdownItem   
                  onClick={()=>setCostingmarketModal(true)} 
                    >
                     Costing Markets
                </DropdownItem>
                <CostingMarkets isOpen={costingmarketModal} closeModal={setCostingmarketModal}/>
                  {project.IsUploaded && uploadProject && (
                    <UploadedProjectModal
                      uploadProject={uploadProject}
                      setUploadProject={setUploadProject}
                    />
                  )} */}
                  {/* <DropdownItem divider />
                <DropdownItem
                  onClick={() => onWaveLevelEditClick()}
                >
                  Input Setup and Delivery
                </DropdownItem> */}

                  {/* {currentCostingProfile.ProfileStatus > 5 ? null : ( */}
                  {_.includes(
                    pacificMarkets,
                    project.CommissioningCountry
                  ) ? null : currentCostingProfile &&
                    currentCostingProfile.CountrySpecs &&
                    currentCostingProfile.ProfileSetting &&
                    !currentCostingProfile.ProfileSetting
                      .UsesOopOverrideIntCommCost &&
                    currentCostingProfile.ProfileSetting
                      .CommercialHoursSchema &&
                    !(
                      (project.IsSyndicated && project.SyndicatedwithOpp !== true) &&
                      project.ParentProjectId &&
                      !project.IsCopy
                    ) ? (
                    <DropdownItem
                      disabled={
                        currentCostingProfile.ProfileStatus > 1 ||
                        project.PriceChange >= 2 ||
                        project.ProjectStatus == "7"||  
                        (project.IsDeleted==1 && project.ProjectStatus =='8')
                      }
                      onClick={() => {
                        if (
                          !valueChangedInRFQ &&
                          !currentCostingProfile.ProfileChanged
                        ) {
                          navigateToInputCommercialCosts();
                        } else {
                          setUnsavedChangesAlert(true);
                          pendingProcessRef.current =
                            navigateToInputCommercialCosts;
                        }
                      }}
                      className="mr-2"
                    >
                      Input Commercial Costs
                    </DropdownItem>
                  ) : null}
                  {currentCostingProfile &&
                    canRenderShopper() &&
                    (project.CostingTemplateVersion != "v2.0" ||
                     ( _.includes(
                        pacificMarkets,
                        project.CommissioningCountry
                      ) && project.CostingTemplateVersion != "v2.0")) && (
                      <DropdownItem
                        disabled={
                          currentCostingProfile.ProfileStatus == 6 ||
                          currentCostingProfile.ProfileStatus == 99 ||
                          project.PriceChange > 2 ||
                          (project.PriceChange == 2 &&
                            (project.ProjectStatus == "3" || project.ProjectStatus == "9"))||
                            (project.IsDeleted==1 && project.ProjectStatus =='8')
                        }
                        onClick={() => {
                          //todo: if not costing don't trigger below code
                          //checkFunction();
                          // setShowCostMethod(true);
                          // if (currentCostingProfile.CostingType)
                          //   setShowCostMethod(true);
                          // else chooseCostMethod(currentCostingProfile.CostingType);
                          if (
                            !valueChangedInRFQ &&
                            !currentCostingProfile.ProfileChanged
                          ) {
                            if (validateCostingOptions()) {
                              navigateInputShopperCosts();
                            } else {
                              setUnsavedChangesAlert(true);
                              pendingProcessRef.current =
                                navigateInputShopperCosts;
                            }
                          }
                        }}
                        className="mr-2"
                      >
                        Input Shopper Costs
                      </DropdownItem>
                    )}
                  {!(
                    (project.IsSyndicated && project.SyndicatedwithOpp !== true) &&
                    project.ParentProjectId &&
                    !project.IsCopy
                  ) &&
                    !Vendorflag &&
                    (preValue?.map((sm) => {
                      return sm?.Methodology?.filter((cm) => cm.costType == 3)
                        .length;
                    }).length > 0
                      ? preValue
                          ?.map((sm) => {
                            return sm?.Methodology?.filter(
                              (cm) => cm.costType == 3
                            ).length;
                          })
                          .reduce((result, sp) => result + sp) > 0
                      : false) && (
                      <DropdownItem
                        disabled={
                          project.ProjectStatus === "7" ||
                          currentCostingProfile.ProfileStatus > 1 ||
                          project.PriceChange > 2 ||
                          (project.PriceChange == 2 &&
                           ( project.ProjectStatus == "3" || project.ProjectStatus == "9"))||
                            (project.IsDeleted==1 && project.ProjectStatus =='8')
                        }
                        // disabled={currentCostingProfile.ProfileStatus == 6
                        //   || currentCostingProfile.ProfileStatus == 99}

                        onClick={() => {
                          if (
                            !valueChangedInRFQ &&
                            !currentCostingProfile.ProfileChanged
                          ) {
                            navigateToVendorBidding();
                          } else {
                            setUnsavedChangesAlert(true);
                            pendingProcessRef.current = navigateToVendorBidding;
                          }
                        }}
                        className="mr-2"
                      >
                        Vendor Bidding
                      </DropdownItem>
                    )}

                  {(_.includes(pacificMarkets, project.CommissioningCountry) && project.CostingTemplateVersion != "v2.0")? (
                    currentCostingProfile.ProfileStatus > 5 ? null : props
                        .userRecord.IsOpsProjectManager ? (
                      <DropdownItem
                        disabled={
                          project.ProjectStatus === "7" ||
                          currentCostingProfile.ProfileStatus != 1 ||
                          ((project.IsSyndicated && project.SyndicatedwithOpp !== true) &&
                            project.ParentProjectId &&
                            !project.IsCopy) ||
                          project.PriceChange == 4 ||
                          (project.PriceChange == 3 &&
                            (project.ProjectStatus == "3" || project.ProjectStatus == "9"))|| 
                            (project.IsDeleted==1 && project.ProjectStatus =='8')
                        }
                        onClick={() => {
                          gaEventTracker(EventName.OverrideAutoCosts);
                          if (
                            !valueChangedInRFQ &&
                            !currentCostingProfile.ProfileChanged
                          ) {
                            navigateToOverrideAutoCosts();
                          } else {
                            setUnsavedChangesAlert(true);
                            pendingProcessRef.current =
                              navigateToOverrideAutoCosts;
                          }
                        }}
                      >
                        Override Auto-Costs
                      </DropdownItem>
                    ) : null
                  ) : null}
                   {/* {(_.includes(pacificMarkets, project.CommissioningCountry) && project.CostingTemplateVersion != "v2.0")? (
                    currentCostingProfile.ProfileStatus > 5 ? null : props
                        .userRecord.IsOpsProjectManager ? <DropdownItem divider />  : null
                      ) : null}
                  {project.CostingTemplateVersion == "v2.0" ? (
                    <DropdownItem
                      disabled={
                        project.ProjectStatus === "7" ||
                        project.PriceChange == 4 ||
                         (project.IsDeleted==1 && project.ProjectStatus =='8') ||
                        (project.PriceChange == 3 &&
                          (project.ProjectStatus == "3" || project.ProjectStatus == "9")) || 
                          (project.PriceChange == 3) ||
                        !(
                          parseInt(currentCostingProfile.ProfileStatus) < 5 &&
                          currentCountrySpecs?.MethodologySpecs?.every(
                            (ms) => ms.CostingOptions.length
                          )
                        )
                      }
                      onClick={() => {
                        if (project.IsUploaded) {
                          setUploadProject(true);
                        } else {
                          openSheet();
                        }
                      }}
                    >
                      Open Additional Sheet
                    </DropdownItem>
                  ) : (
                    <DropdownItem
                      disabled={
                        project.ProjectStatus === "7" ||
                        project.PriceChange == 4 ||
                        (project.IsDeleted==true && project.ProjectStatus =='8') ||
                        (project.PriceChange == 3 &&
                          (project.ProjectStatus == "3"|| project.ProjectStatus == "9"))
                      }
                      onClick={() => {
                        gaEventTracker(EventName.CreateAdditionalSheet);
                        if (project.IsUploaded) {
                          setUploadProject(true);
                        } else if (currentCostingProfile.AdditionalSheetId) {
                          // window.open(
                          //   "https://docs.google.com/spreadsheets/d/" +
                          //   currentCostingProfile.AdditionalSheetId
                          // );
                          dispatch(
                            sheetCall.msSheetAPiCall(
                              currentCostingProfile.AdditionalSheetId,
                              false
                            )
                          );
                        } else {
                          dispatch(localPageLoadStart());
                          axios
                            .post(
                              "/utils/sheets/" +
                                currentCostingProfile.id +
                                "/additional"
                            )
                            .then((res) => {
                              dispatch({
                                type: currentCostingActions.UPDATE_NEW_COSTING,
                                currentCostingProfile: {
                                  AdditionalSheetId: res.data.AdditionalSheetId,
                                },
                              });
                              // window.open(
                              //   "https://docs.google.com/spreadsheets/d/" +
                              //   res.data.AdditionalSheetId
                              // );
                              dispatch(
                                sheetCall.msSheetAPiCall(
                                  res.data.AdditionalSheetId,
                                  false
                                )
                              );
                              dispatch(localPageLoadEnd());
                              toastr.success(res.data.message);
                            })
                            .catch(() => {
                              dispatch(localPageLoadEnd());
                              toastr.error("Additional Sheet creation failed");
                            });
                        }
                      }}
                    >
                      {currentCostingProfile.AdditionalSheetId
                        ? "Open "
                        : "Create "}
                      Additional Sheet
                    </DropdownItem>
                  )} */}
                  {project.IsUploaded && uploadProject && (
                    <UploadedProjectModal
                      uploadProject={uploadProject}
                      setUploadProject={setUploadProject}
                    />
                  )}
                  {/* {currentCostingProfile.CostingsSheetId ? (
                  <DropdownItem
                    onClick={() => {
                      window.open(
                        "https://docs.google.com/spreadsheets/d/" +
                        currentCostingProfile.CostingsSheetId
                      );
                    }}
                  >
                    Open Costing Sheet
                  </DropdownItem>
                ) : null} */}
                  

                  

                 {/* <DropdownItem divider /> */}

                  
                
                  {/* {showScheduleSummary() ? (
                    <>
                     
                      {project.IsUploaded && uploadProject && (
                        <UploadedProjectModal
                          uploadProject={uploadProject}
                          setUploadProject={setUploadProject}
                        />
                      )}
                      {(_.includes(
                        pacificMarkets,
                        project.CommissioningCountry
                      ) && project.CostingTemplateVersion != "v2.0") ? (
                        <DropdownItem
                          disabled={
                            project.ProjectStatus === "7" ||
                            project.PriceChange == 4
                          }
                          onClick={() => {
                            if (
                              !valueChangedInRFQ &&
                              !currentCostingProfile.ProfileChanged
                            ) {
                              navigateToViewProfileComparison();
                            } else {
                              setUnsavedChangesAlert(true);
                              pendingProcessRef.current =
                                navigateToViewProfileComparison;
                            }
                          }}
                        >
                          View Profile Comparison
                        </DropdownItem>
                      ) : null}
                      {location.pathname.indexOf("summary") != 1 ? (
                        <DropdownItem
                          disabled={project.PriceChange == 4}
                          onClick={(e) => {
                            gaEventTracker(EventName.ViewCostSummary);
                            if (
                              !valueChangedInRFQ &&
                              !currentCostingProfile.ProfileChanged
                            ) {
                              navigateToViewCostSummary();
                            } else {
                              setUnsavedChangesAlert(true);
                              pendingProcessRef.current =
                                navigateToViewCostSummary;
                            }
                            dispatch({ type: SET_VENDOR_FLAG, Vendor: false });
                          }}
                        >
                          Project Pricing / Commission
                        </DropdownItem>
                      ) : null}

                      {/* <DropdownItem>Open Project Review</DropdownItem> */}
                    {/* </>
                  ) : null
                  }  */}

                  {/* {parseInt(currentCostingProfile.ProfileStatus) == 5 ||
                  (currentCostingProfile.ProfileStatus > 1 &&
                    currentCostingProfile.ProfileStatus < 6) ||
                  parseInt(currentCostingProfile.ProfileStatus) == 6 ? (
                    <DropdownItem divider />
                  ) : null} */}
                  {/* {parseInt(currentCostingProfile.ProfileStatus) == 5 &&
                  ["2", "3", "4", "5"].indexOf(project.ProjectStatus) == -1 ? (
                  <DropdownItem
                    onClick={() => {
                      commissionProject();
                    }}
                  >
                    Commission Costing
                  </DropdownItem>
                ) : null} */}

                  {/* {currentCostingProfile.ProfileStatus > 1 &&
                  currentCostingProfile.ProfileStatus < 6 ? (
                  location.pathname.indexOf("summary") != -1 ? (
                    <DropdownItem
                      onClick={() => {
                        dispatch(currentCostingActions.unlockPriceToClient());
                      }}
                    >
                      Unlock Price to Client
                    </DropdownItem>
                  ) : null
                ) : null} */}
                  
                  {project.IsUploaded && uploadProject && (
                    <UploadedProjectModal
                      uploadProject={uploadProject}
                      setUploadProject={setUploadProject}
                    />
                  )}
                  {/* edit ops oppchanges */}
                  {/* {(project.ProjectStatus == "3" || project.ProjectStatus == "9") &&
                    // project.DateFieldWorkStartActual === null &&
                    !(
                      (project.IsSyndicated && project.SyndicatedwithOpp !== true) &&
                      project.ParentProjectId &&
                      !project.IsCopy
                    ) && (
                      <>
                       <DropdownItem
                          disabled={project.PriceChange == 4 || (project.IsDeleted==true && project.ProjectStatus =='8')}
                          onClick={() => {
                            currentCostingActions.getCosting(
                              currentCostingProfile.id
                            );
                            setOpsOpp(true);
                          }}
                        >
                          Planned Cost Adjustments
                         </DropdownItem> 
                        {location.pathname.indexOf("summary") != -1 && (
                          <DropdownItem divider />
                        )}
                      </>
                    )} */}
                  {/*  */}
               
                  <>
                    {project.IsUploaded && uploadProject && (
                      <UploadedProjectModal
                        uploadProject={uploadProject}
                        setUploadProject={setUploadProject}
                      />
                    )}
                    {/* <DropdownItem divider /> */}
                    <DropdownItem
                      onClick={() =>
                        window.open(
                          process.env.REACT_APP_USER_GUIDE_URL,
                          "_blank"
                        )
                      }
                    >
                      Guide Documentation
                    </DropdownItem>
                  </>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            ) : null}

          <IconButton color="inherit" onClick={handleClick}>
            <NewBadge
              color="secondary"
              variant={hasNewNotification || message ? "dot" : "standard"}
              invisible={!message}
            >
              <FontAwesomeIcon
                className="ic-hover pointer"
                icon={faBell}
                color="white"
                title="Alert"
                size="lg"
              />
            </NewBadge>
          </IconButton>

            <UncontrolledDropdown nav inNavbar>
              <span className="d-inline-block d-sm-none">
                <DropdownToggle nav caret>
                  <Settings size={18} className="align-middle" />
                </DropdownToggle>
              </span>
              <span className="d-none d-sm-inline-block">
                <DropdownToggle nav caret>
                  {/* <img
                    src={avatar1}
                    className="avatar img-fluid rounded-circle mr-1"
                    alt="User Profile Picture"
                  /> */}
                  <span className="text-dark">
                    {props.userRecord.FirstName} {props.userRecord.LastName}
                  </span>
                </DropdownToggle>
                <a style={{marginLeft :"10px"}}
                  className="select-link text-white support"
                  onClick={() =>
                    window.open(process.env.REACT_APP_SUPPORT_FORM)
                  }
                >
                  Need Support?
                </a>
              </span>
              <DropdownMenu right>
                {userRecord.IsCostingApprover && (
                  <>
                    <DropdownItem
                      onClick={(e) => handleApprovalModal(!isApprovalModalOpen)}
                    >
                      User Profile
                    </DropdownItem>
                    <DropdownItem divider />{" "}
                  </>
                )}
                <DropdownItem
                  onClick={() => {
                    handleLogOut();
                  }}
                >
                  Sign out
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          <Row className="visible-xs mt-3">
            {props.profileStatusToDisplay ? (
              <Col>
                <Badge
                  className="m-0 h5"
                  color="primary"
                  title="Costing Profile Status"
                >
                  Profile Status: {props.profileStatusToDisplay}
                </Badge>
              </Col>
            ) : null}
            {props.projectStatusToDisplay ? (
              <Col className={!props.profileStatusToDisplay ? "mt-2" : ""}>
                <Badge
                  className="m-0 h5"
                  color="primary"
                  title="Project Status"
                >
                  Project Status: {props.projectStatusToDisplay}
                </Badge>
              </Col>
            ) : null}
          </Row>
        </Collapse>

        {props.costingProfile.id ? (
          <RequestCreate
            isOpen={isOpenRequest}
            toggle={() => setIsOpenRequest(!isOpenRequest)}
          />
        ) : null}
        {props.costingProfile.id ? (
          <RequestViewModal
            isOpen={isOpenViewRequest}
            toggle={() => setIsOpenViewRequest(!isOpenViewRequest)}
          />
        ) : null}

        <Modal
          isOpen={isOpen}
          toggle={() => setIsOpen(!isOpen)}
          centered={true}
          size="lg"
        >
          <ModalHeader toggle={() => setIsOpen(!isOpen)}>
            {/* <img src={logo} className="modallogo" /> */}
          </ModalHeader>
          <span className="modaltitle">Costing Notepad</span>
          <ModalBody>
            <Container>
              <ReactQuill
                placeholder="You can use this notepad to save any notes related to this costing currentCostingProfile. These notes can later be accessed directly from the dashboard and costing currentCostingProfile..."
                value={notes}
                onChange={(e) => {
                  setNotes(e);
                }}
              />
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                setIsOpen(!isOpen);
                props.updateCosting({ CostingNotes: notes });

                const updatedCosting = update(props.costingProfile, {
                  "CountrySpecs": { $set: props.countrySpecs },
                });
                const updatedCosting2 = update(updatedCosting, {
                  "Project": { $set: props.project },
                });
                const updatedCosting3 = update(updatedCosting2, {
                  "CostingNotes": { $set: notes },
                });
                props.saveCostingProfile(updatedCosting3);
              }}
              disabled={props.app.recordloading}
            >
              Save
            </Button>
            <Button
              color="secondary"
              disabled={props.app.recordloading}
              onClick={() => setIsOpen(!isOpen)}
            >
              Cancel
            </Button>{" "}
          </ModalFooter>
        </Modal>
        <ModalCommissioning
          setIsOpen={setCommissionOpen}
          isOpen={isCommissionOpen}
          toggle={() => {
            setCommissionOpen({
              ...isCommissionOpen,
              ModalCommissioning: !isCommissionOpen.ModalCommissioning,
            });
          }}
        />
        <ModalPostCommission
          isOpen={isCommissionOpen}
          toggle={() => {
            setCommissionOpen({
              ...isCommissionOpen,
              ModalPostCommission: !isCommissionOpen.ModalPostCommission,
            });
          }}
        />
        {isCommissionOpen && isCommissionOpen.ModalDecommissioning ? (
          <Suspense fallback={<></>}>
            <ModalDecommissioning
              isOpen={isCommissionOpen}
              toggle={() => {
                setCommissionOpen({
                  ...isCommissionOpen,
                  ModalDecommissioning: !isCommissionOpen.ModalDecommissioning,
                });
              }}
            />{" "}
          </Suspense>
        ) : null}

        <Modal
          isOpen={unsavedChangesAlert}
          toggle={() => setUnsavedChangesAlert(!unsavedChangesAlert)}
          centered={true}
          size="sm"
        >
          <ModalHeader
            toggle={() => setUnsavedChangesAlert(!unsavedChangesAlert)}
          >
            {/* <img src={logo} className="modallogo" /> */}
          </ModalHeader>
          <div>
            <span className="modaltitle">Alert !!!</span>
          </div>
          <ModalBody>
            RFQ fields are edited and not saved. Clicking on "Continue anyways"
            will navigate to next page without saving.
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handlePendingProcess}>
              Continue anyways
            </Button>
            <Button
              color="secondary"
              onClick={() => setUnsavedChangesAlert(!unsavedChangesAlert)}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={openEditLevelPopUp}
          toggle={() => SetOpenEditLevelPopup(!openEditLevelPopUp)}
          centered={true}
          size="sm"
        >
          <ModalHeader
            toggle={() => SetOpenEditLevelPopup(!openEditLevelPopUp)}
          >
            {/* <img src={logo} className="modallogo" /> */}
          </ModalHeader>
          <div>
            <span className="modaltitle">Please Save</span>
          </div>
          <ModalBody>Please Save it before opening this !!!!</ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => SetOpenEditLevelPopup(!openEditLevelPopUp)}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={openCommercialWarning}
          toggle={() => setOpenCOmmercialWarning(!openCommercialWarning)}
          centered={true}
          size="sm"
        >
          <ModalHeader
            toggle={() => setOpenCOmmercialWarning(!openCommercialWarning)}
          >
            {/* <img src={logo} className="modallogo" /> */}
          </ModalHeader>
          <span className="modaltitle">Add Costing Options</span>
          <ModalBody>
            Please add Costing Options to fill Commercial hour costs
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => setOpenCOmmercialWarning(!openCommercialWarning)}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={openOverrideAutoCosts}
          toggle={() => setOpenOverrideAutoCosts(!openOverrideAutoCosts)}
          centered={true}
          size="sm"
        >
          <ModalHeader
            toggle={() => setOpenOverrideAutoCosts(!openOverrideAutoCosts)}
          >
            {/* <img src={logo} className="modallogo" /> */}
          </ModalHeader>
          <span className="modaltitle">Add Waves</span>
          <ModalBody style={{ "margin-left": "10px" }}>
            Please add Waves/Save RFQ Data.
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => setOpenOverrideAutoCosts(!openOverrideAutoCosts)}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>

        {/* <CostingTypeMapping
          setUpdateCost={setUpdateCost}
          setCostSelection={setCostSelection}
          setShowCostMethod={setShowCostMethod}
          pacificData={pacificData}
          showCostMethod={showCostMethod}
          isCostSelection={isCostSelection}
          updateCost={updateCost}
        ></CostingTypeMapping> */}

        {/* {!currentCostingProfile.IsMultiCountry ? (
            <Button
              disabled={app.recordloading}
              onClick={() => chooseCostMethod("VENDOR")}
            >
              Trigger Vendor Bidding
              {app.recordloading &&
                currentCostingProfile.CostingType == "VENDOR" ? (
                <>
                  <Spinner size="small" color="#cccccc" />
                </>
              ) : null}
            </Button>
          ) : null}{" "}
          {(currentCostingProfile.ProfileSetting?.NeedsGlobalCostingSheet
            && !_.includes(pacificMarkets, project.CommissioningCountry)) ? (
            <Button
              disabled={app.recordloading}
              onClick={() => chooseCostMethod("SHEETS")}
            >
              Use MS Sheets
            </Button>
          ) : null}
          {" "}
          {!currentCostingProfile.IsMultiCountry ||
            (currentCostingProfile.ProfileSetting &&
              currentCostingProfile.ProfileSetting.AllowMCPManualCostEntry) ? (
            <Button
              disabled={app.recordloading}
              onClick={() => chooseCostMethod("DEFAULT")}
            >
              Input Cost Directly
            </Button>
          ) : null}{" "}
          {currentCostingProfile.IsMultiCountry ||
            (currentCostingProfile.ProfileSetting &&
              currentCostingProfile.ProfileSetting.AllowMCPManualCostEntry) ? (
            <Button
              disabled={app.recordloading}
              onClick={() => chooseCostMethod("VENDOR")}
            >
              Vendor Cost
            </Button>
          ) : null}{" "}
          {app.recordloading &&
            currentCostingProfile.CostingType !== "VENDOR" ? (
            <>
              <Spinner size="small" color="#cccccc" />
            </>
          ) : null} */}
        {/* </ModalFooter>
      </Modal> */}
        {/* changes */}
      {project.CommissioningCountry &&
        <TotalOPSOOPModal
          opsOpp={opsOpp}
          setOpsOpp={setOpsOpp}
          id="ProjectNavbar"
          project={project}
        />
      }
        <Modal
          isOpen={salesForceModal}
          toggle={() => openSaleForceModal(!salesForceModal)}
          centered={true}
        >
          <ModalHeader toggle={() => openSaleForceModal(!salesForceModal)}>
            {/* <img src={logo} className="modallogo" /> */}
          </ModalHeader>
          <span className="modaltitle">Update Client Details</span>
          <ModalBody>
            Please update client details to proceed
            {currentCostingProfile.Project &&
            currentCostingProfile.Project.ContractDetails
              ? currentCostingProfile.Project.ContractDetails.map(
                  (cd, index) => (
                    <>
                      <Row className="mb-2">
                        <Col>
                          <Label className="small font-weight-bold mb-0">
                            Opportunity Details #{index + 1}
                          </Label>
                        </Col>
                        {cd.isSF ? (
                          <Col>
                            <a
                              className="update-client-details"
                              onClick={() =>
                                dispatch(
                                  currentProjectActions.syncContactDetails(
                                    currentCostingProfile.Project.id,
                                    cd,
                                    true,
                                    () => {
                                      openSaleForceModal(false);
                                      setCommissionOpen({
                                        ...isCommissionOpen,
                                        ModalCommissioning:
                                          !isCommissionOpen.ModalCommissioning,
                                      });
                                    }
                                  )
                                )
                              }
                            >
                              Update Client Details
                            </a>
                          </Col>
                        ) : null}
                      </Row>
                      <Row className="mb-2">
                        <Col xs="4" className="pr-0">
                          Name
                        </Col>
                        <Col xs="8" className="pl-1">
                          {cd.OpportunityName}
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col xs="4" className="pr-0">
                          Account
                        </Col>
                        <Col xs="8" className="pl-1">
                          {cd.AccountName}
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col xs="4" className="pr-0">
                          Industry
                        </Col>
                        <Col xs="8" className="pl-1">
                          {cd.Industry}
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col xs="4" className="pr-0">
                          OP Number
                        </Col>
                        <Col xs="8" className="pl-1">
                          {cd.OpportunityNumber}
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col xs="4" className="pr-0">
                          Stage
                        </Col>
                        <Col xs="8" className="pl-1">
                          {cd.Stage} ({cd.Probability}
                          {"%"})
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col xs="4" className="pr-0">
                          Amount
                        </Col>
                        <Col xs="8" className="pl-1">
                          {cd.Amount} {cd.AmountCurrency}
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col xs="4" className="pr-0">
                          Start of Delivery
                        </Col>
                        <Col xs="8" className="pl-1">
                          {cd.StartofDelivery
                            ? cd.StartofDelivery.split("T")[0]
                            : "Not available"}
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col xs="4" className="pr-0">
                          End of Delivery
                        </Col>
                        <Col xs="8" className="pl-1">
                          {cd.EndofDelivery
                            ? cd.EndofDelivery.split("T")[0]
                            : "Not available"}
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col xs="4" className="pr-0">
                          Close Date
                        </Col>
                        <Col xs="8" className="pl-1">
                          {cd.CloseDate
                            ? cd.CloseDate?.split("T")[0]
                            : "Not available"}
                        </Col>
                      </Row>
                      <hr
                        className="mb-0 mt-0"
                        style={{ borderStyle: "dotted" }}
                      ></hr>
                    </>
                  )
                )
              : "No Contract Details Found"}
          </ModalBody>
        </Modal>
        <Modal
          toggle={() => {
            openDownloadModal(false);
            setSelectedProperties([]);
          }}
          isOpen={downloadModal}
          centered={true}
        >
          <ModalHeader
            toggle={() => {
              openDownloadModal(false);
              setSelectedProperties([]);
            }}
          >
            {/* <img src={logo} className="modallogo" /> */}
          </ModalHeader>
          <span className="downloadsummary">
            Select Sections to be Downloaded
          </span>
          <ModalBody>
            <Row className="m-0 justify-content-end">
              <a
                className="mr-1 select-link"
                onClick={() =>
                  {if (profile.CostingsSheetId===null) {
                    setSelectedProperties(
                      allDownloadProps.filter((adp) => adp.value !== "costingSheet").map((adp) => adp.value)
                    )
                  } else {
                    setSelectedProperties(
                      allDownloadProps.map((adp) => adp.value)
                    )
                  }
                }}
              >
                Select all
              </a>
              <a
                className="ml-1 select-link"
                onClick={() => setSelectedProperties([])}
              >
                Deselect all
              </a>
            </Row>
            <ul>
              {allDownloadProps.map((adp, index) => {
                return (
                  <li key={index}>
                    <span>
                      <input
                        type="checkbox"
                        checked={_.includes(selectedProperties, adp.value)}
                        id={adp.value}
                        value={adp.value}
                        disabled={adp.value==="costingSheet" && profile.CostingsSheetId===null}
                        onChange={(e) => {
                          if (e.target.checked) {
                            let selectedproperties = selectedProperties;
                            selectedproperties.push(e.target.value);
                            setSelectedProperties([...selectedproperties]);
                          } else {
                            let selectedproperties = selectedProperties.filter(
                              (val) => val != e.target.value
                            );
                            setSelectedProperties([...selectedproperties]);
                          }
                        }}
                      />
                      <label className="ml-2 pointer" htmlFor={adp.value}>
                        {adp.label } {adp.value==="costingSheet" && profile.CostingsSheetId===null&&<span style={{color:"red"}}>(Enable this option by first generating the Costing Sheet)</span>}
                      </label>
                      
                    </span>
                  </li>
                );
              })}
            </ul>
          </ModalBody>
          <ModalFooter>
            <Row>
              <div className="d-flex text-nowrap">
                {" "}
                <strong className="mt-1 mr-1">Format to download: </strong>
                <Select
                  className="width-150"
                  defaultValue={{
                    label:"EXCEL",
                    value:"SHEET"
                  }}
                  isDisabled={true}
                  onChange={(e) => setDownloadType(e.target.value)}
                  options={
                    [{
                      label:"EXCEL",
                      value:"SHEET"
                    }]
                  }
                >
                </Select>
              </div>
            </Row>
            <Button
              disabled={!selectedProperties.length}
              onClick={() => {
                if (selectedProperties.length) {
                  if (downloadType == "SHEET") downloadInSheets();
                  else if (downloadType == "PDF")
                    dispatch(
                      currentCostingActions.downloadInPdf(
                        selectedProperties,
                        currentCurrency
                      )
                    );
                }
              }}
            >
              Download{" "}
            </Button>
          </ModalFooter>
        </Modal>
        {/* Selecting Methodologies to be disabled in manual cost entry */}
        <Modal
          isOpen={methodologySelection}
          toggle={() => openMethodologySelection(!methodologySelection)}
        >
          <ModalHeader
            toggle={() => openMethodologySelection(!methodologySelection)}
            centered={true}
          >
            {/* <img src={logo} className="modallogo" /> */}
          </ModalHeader>
          <span className="modaltitle">Select Sub-Methodologies</span>
          <ModalBody>
            <Row className="m-0 justify-content-end">
              <a
                className="select-link mr-1"
                onClick={() => {
                  setSelectedMethodologies([
                    ...getFinalCostFields().map((gfc) => gfc.value),
                  ]);
                }}
              >
                Select all
              </a>
              <a
                className="select-link ml-1"
                onClick={() => {
                  setSelectedMethodologies([]);
                }}
              >
                Deselect all
              </a>
            </Row>
            <ul>
              {getFinalCostFields().map((cf, index) => (
                <li>
                  <input
                    type="checkbox"
                    id={`cf_${index}`}
                    checked={_.includes(selectedMethodologies, cf.value)}
                    onClick={(e) => {
                      if (e.target.checked) {
                        let selectedmethodologies = [...selectedMethodologies];
                        selectedmethodologies.push(cf.value);
                        setSelectedMethodologies([...selectedmethodologies]);
                      } else {
                        let selectedmethodologies = [
                          ...selectedMethodologies.filter(
                            (sm) => sm != cf.value
                          ),
                        ];
                        setSelectedMethodologies([...selectedmethodologies]);
                      }
                    }}
                  />
                  <label className="ml-2 pointer" for={`cf_${index}`}>
                    {cf.label}
                  </label>
                </li>
              ))}
            </ul>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Button
                color="secondary"
                size="small"
                onClick={() => {
                  openMethodologySelection(false);
                  setSelectedMethodologies([]);

                  dispatch(
                    currentCostingActions.updateProfile({
                      ...currentCostingProfile,
                      CostingType: null,
                    })
                  );
                  // dispatch(
                  //   currentCostingActions.saveCostingProfile(
                  //     { ...currentCostingProfile, CostingType: null },
                  //     () => {
                  //     },
                  //     true
                  //   )
                  // );
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                className="ml-2"
                size="small"
                disabled={!selectedMethodologies.length}
                onClick={() => {
                  let actualPath = _.head(
                    location.pathname?.replace("/", "").split("/")
                  );
                  dispatch(
                    currentCostingActions.saveCostingProfile(
                      {
                        ...currentCostingProfile,
                        CostingType: "VENDOR",
                        VendorBiddingSubmethodologies:
                          selectedMethodologies.join(),
                      },
                      () => {
                        setSelectedMethodologies([]);
                        openMethodologySelection(false);
                        window.open(
                          `https://vendormanagement.edashboard.in/#/pages/vendor-bidding/${currentCostingProfile.Project.ProjectId}/${currentCostingProfile.id}`,
                          "_blank"
                        );
                        dispatch(
                          setCostingStatus(
                            {
                              ...costingStatus,
                              // showManualCostEntry: true,
                              // showCommercialCosts: false
                            },
                            actualPath == "proposal-new" ? null : history
                          )
                        );
                        setShowCostMethod(false);
                      },
                      true
                    )
                  );
                }}
              >
                Confirm
              </Button>
            </Row>
          </ModalFooter>
        </Modal>

        <Modal
          className="modal-with-header"
          isOpen={isModalOpen}
          toggle={() => handleOpen()}
          size="lg"
          centered={true}
        >
          <ModalHeader toggle={() => handleOpen(!isModalOpen)}>
            {/* <img src={logo} className="modallogo" /> */}
          </ModalHeader>
          <div>
          <span className="modaltitle modalvalidationheader " style = {{color:"red"}}>
              {validationHeader}
            </span>
          </div>
          <ModalBody id="scrollModalBody" className="modal-body-error-header">
            {/* <p>
              <b>
                Key Parameters information is missing at cost option level for
                following Country and Methodologies
              </b>
            </p> */}

            {Object.keys(errorObj)?.length ? (
            <>
            <div className="col-md-12">
              <Table id="errorTable">
              <thead>
                              <tr>
                                <th>Country</th>
                                <th>Methodology</th>
                                <th>Mandatory fields requiring input</th>
                              </tr>
                            </thead>
                            {Object.keys(errorObj).map(function (key) {
                                            return (
                                              <>
                            
                                                  {((errorObj[key]?.CostingOptions &&
                                                  Object.keys(errorObj[key]?.CostingOptions).length) || 
                                                  (errorObj[key]?.RFQ &&
                                                    Object.keys(errorObj[key]?.RFQ).length) || 
                                                    (errorObj[key]?.SD &&
                                                      Object.keys(errorObj[key]?.SD).length)) ? (
                                                    <>
                                                          <tbody>
                                                          {Object.keys(errorObj[key]).includes("CostingOptions") ? Object.keys(errorObj[key]?.CostingOptions).map(
                                                              function (key1) {
                                                                return (
                                                                  <tr>
                                                                    {((Object.keys(errorObj[key]).includes("CostingOptions") && Object.entries(
                                                                      errorObj[key]?.CostingOptions[key1]
                                                                    ).length > 0) || (Object.keys(errorObj[key]).includes("RFQ") && 
                                                                    (Object.entries(errorObj[key]?.RFQ).some((  [key,value]   ) => key == key1  ))
                                                                     && Object.entries(
                                                                      errorObj[key]?.RFQ[key1]
                                                                    ).length > 0) || (Object.keys(errorObj[key]).includes("SD") && (Object.entries(errorObj[key]?.SD).some((  [key,value]   ) => key == key1  )) &&Object.entries(
                                                                      errorObj[key]?.SD[key1]
                                                                    ).length > 0)) && (
                                                                      <>
                                                                        <td>{key}</td>
                                                                        <td>{key1} </td>
                                                                        <td style = {{color:"red"}}>
                                                                        {errorObj[key]?.CostingOptions &&
                                                                          Object.keys(errorObj[key]?.CostingOptions[key1]).length ?
                                                                           errorObj[key]?.CostingOptions[
                                                                            key1
                                                                          ].map((item,index)=>(
                                                                            <div key1 ={index}>{item}</div>
                                                            )) : null}
                                                                         
                                                                         {errorObj[key]?.RFQ &&
                                                                          (Object.entries(errorObj[key]?.RFQ).some((  [key,value]   ) => key == key1  )) &&
                                                                            Object.keys(errorObj[key].RFQ[key1]).length ? 
                                                                            errorObj[key].RFQ[key1].map((item,index)=>(
                                                                              <div key1 ={index}>{item}</div>
                                                              )) : null}
                                                                          
                                                                          {errorObj[key]?.SD &&
                                                                          (Object.entries(errorObj[key]?.SD).some((  [key,value]   ) => key == key1  )) &&
                                                                            Object.keys(errorObj[key].SD[key1]).length ?
                                                                            errorObj[key].SD[key1]
                                                                            .map((item,index)=>(
                                                                              <div key1 ={index}>{item}</div>
                                                              )): null}
                                                                        </td>
                                                                      </>
                                                                    )}
                                                                  </tr>
                                                                );
                                                              }
                                                            ) : Object.keys(errorObj[key]).includes("RFQ") ? 
                                                            Object.keys(errorObj[key]?.RFQ).map(
                                                              function (key1) {
                                                                return (
                                                                  <tr>
                                                                    {((Object.keys(errorObj[key]).includes("CostingOptions") && Object.entries(
                                                                      errorObj[key]?.CostingOptions[key1]
                                                                    ).length > 0) || (Object.keys(errorObj[key]).includes("RFQ") && 
                                                                    (Object.entries(errorObj[key]?.RFQ).some((  [key,value]   ) => key == key1  )) && Object.entries(
                                                                      errorObj[key]?.RFQ[key1]
                                                                    ).length > 0) || (Object.keys(errorObj[key]).includes("SD") &&
                                                                    (Object.entries(errorObj[key]?.SD).some((  [key,value]   ) => key == key1  )) && Object.entries(
                                                                      errorObj[key]?.SD[key1]
                                                                    ).length > 0)) && (
                                                                      <>
                                                                        <td>{key}</td>
                                                                        <td>{key1} </td>
                                                                        <td style = {{color:"red"}}>
                                                                          {errorObj[key]?.CostingOptions &&
                                                                          Object.keys(errorObj[key]?.CostingOptions[key1]).length ?
                                                                           errorObj[key]?.CostingOptions[
                                                                            key1
                                                                          ].map((item,index)=>(
                                                                            <div key1 ={index}>{item}</div>
                                                            )) : null}
                                                                         
                                                                         {errorObj[key]?.RFQ &&
                                                                          (Object.entries(errorObj[key]?.RFQ).some((  [key,value]   ) => key == key1  )) &&
                                                                            Object.keys(errorObj[key].RFQ[key1]).length ? 
                                                                            errorObj[key].RFQ[key1].map((item,index)=>(
                                                                              <div key1 ={index}>{item}</div>
                                                              )) : null}
                                                                         
                                                                         {errorObj[key]?.SD &&
                                                                          (Object.entries(errorObj[key]?.SD).some((  [key,value]   ) => key == key1  )) &&
                                                                            Object.keys(errorObj[key].SD[key1]).length ?
                                                                            errorObj[key].SD[key1]
                                                                            .map((item,index)=>(
                                                                              <div key1 ={index}>{item}</div>
                                                              )) : null}
                                                                        </td>
                                                                      </>
                                                                    )}
                                                                  </tr>
                                                                );
                                                              }
                                                            ): Object.keys(errorObj[key]).includes("SD") ? 
                                                            Object.keys(errorObj[key]?.SD).map(
                                                              function (key1) {
                                                                return (
                                                                  <tr>
                                                                    {((Object.keys(errorObj[key]).includes("CostingOptions") && Object.entries(
                                                                      errorObj[key]?.CostingOptions[key1]
                                                                    ).length > 0) || (Object.keys(errorObj[key]).includes("RFQ") && (Object.entries(errorObj[key]?.RFQ).some((  [key,value]   ) => key == key1  )) && Object.entries(
                                                                      errorObj[key]?.RFQ[key1]
                                                                    ).length > 0) || (Object.keys(errorObj[key]).includes("SD") &&
                                                                    (Object.entries(errorObj[key]?.SD).some((  [key,value]   ) => key == key1  )) && Object.entries(
                                                                      errorObj[key]?.SD[key1]
                                                                    ).length > 0)) && (
                                                                      <>
                                                                        <td>{key}</td>
                                                                        <td>{key1} </td>
                                                                        <td style = {{color:"red"}}>
                                                                          {errorObj[key]?.CostingOptions &&
                                                                          Object.keys(errorObj[key]?.CostingOptions[key1]).length ?
                                                                           errorObj[key]?.CostingOptions[
                                                                            key1
                                                                          ].map((item,index)=>(
                                                                            <div key1 ={index}>{item}</div>
                                                            )) : null}
                                                                         
                                                                         {errorObj[key]?.RFQ &&
                                                                          (Object.entries(errorObj[key]?.RFQ).some((  [key,value]   ) => key == key1  )) &&
                                                                            Object.keys(errorObj[key].RFQ[key1]).length ? 
                                                                            errorObj[key].RFQ[key1].map((item,index)=>(
                                                                              <div key1 ={index}>{item}</div>
                                                              )) : null}
                                                                         
                                                                         {errorObj[key]?.SD &&
                                                                          (Object.entries(errorObj[key]?.SD).some((  [key,value]   ) => key == key1  )) &&
                                                                            Object.keys(errorObj[key].SD[key1]).length ?
                                                                            errorObj[key].SD[key1]
                                                                            .map((item,index)=>(
                                                                              <div key1 ={index}>{item}</div>
                                                              )) : null}
                                                                        </td>
                                                                      </>
                                                                    )}
                                                                  </tr>
                                                                );
                                                              }
                                                            ) : null
                                                          }
                                                            
                                                                   
                                                              
                                                          </tbody>
                            
                                                      {/* </Table> */}
                                                    </>
                                                  ) : null}
                            
                                                {/* </div>  */}
                                              </>
                                            );
                                           })}
              
               </Table>
               </div>
            </>
          ) : null}
            
          </ModalBody>
          <ModalFooter>
          <Button  color="primary" style = {{marginLeft:"300px"}} onClick = {()=>gotoRFQ()}>
            Go to RFQ
          </Button>
          <Button style = {{backgroundColor:"white"}} float="right" onClick={() => handleOpen(!isModalOpen)}>
            Cancel
          </Button>
          </ModalFooter>
        </Modal>
        <Modal
          zIndex={99999}
          isOpen={!user.isValidToken}
          size="sm"
          centered={true}
        >
          <ModalHeader>
            {/* <img src={logo} className="modallogo" /> */}
          </ModalHeader>
          <ModalBody>
            <div className="mb-2">
              Your session has been expired, so please wait while session is
              refreshed.
            </div>
            <div>
              <Spinner size="small" />
              <span className="ml-4">Login in...</span>
            </div>
          </ModalBody>
          <ModalFooter />
        </Modal>
        <TimeCostModal isOpen={timecostViewModal} isClose={updateModal}/>
      </Navbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  app: state.app,
  userRecord: state.user.userRecord,
  countrySpecs: state.countrySpecs,
  project: state.currentProject.newProject,
  costingProfile: state.currentCosting.currentCostingProfile,
  rateCards: state.rateCards,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onLogOut: () => {
      dispatch(userActions.logout());
    },
    onToggleSideBar: () => {
      dispatch(toggleSidebar());
    },
    updateProject: (newProject) =>
      dispatch({
        type: currentProjectActions.UPDATE_NEW_PROJECT,
        newProject: newProject,
      }),
    updateCosting: (newCosting) => {
      dispatch({
        type: currentCostingActions.UPDATE_NEW_COSTING,
        currentCostingProfile: newCosting,
      });
    },
    saveCostingProfile: (costingProfile) =>
      dispatch(currentCostingActions.saveCostingProfile(costingProfile)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarComponent);
function getCostComponent(
  finalObj,
  getLabelForCode,
  ccf,
  currentCostingProfile,
  getCurrentCurrency,
  getTotalByCostingOption,
  sm,
  cs,
  data
) {
  finalObj.Country = getLabel("FieldingCountriesOptions", cs.CountryCode);
  finalObj.Methodology = sm.Label;
  finalObj.Component = getLabelForCode(ccf);
  finalObj.Total = currentCostingProfile.IsMultiCountry
    ? getCurrentCurrency(
        getTotalByCostingOption(sm.CostingOptions, ccf),
        cs.CostInputCurrency
      )
    : getCurrentCurrency(
        getTotalByCostingOption(sm.CostingOptions, ccf),
        currentCostingProfile.CostInputCurrency
      );
  sm.CostingOptions?.map((co) => {
    finalObj[co.Name] = {};
    finalObj[co.Name] = currentCostingProfile.IsMultiCountry
      ? getCurrentCurrency(co[ccf], cs.CostInputCurrency)
      : getCurrentCurrency(co[ccf], currentCostingProfile.CostInputCurrency);
  });
  data.push(finalObj);
}
