import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../../components/Spinner";
import logo from "../../assets/img/avatars/modal-logo.png";
import * as currentProjectActions from "../../redux/actions/currentProjectActions";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  Row,
  Col,
  Label
} from "reactstrap";
import { toastr } from "react-redux-toastr";
import _ from "lodash";
import ModalCommissioning from "../../pages/summary/ModalCommissioning";
function CommissioningConfirmModal({
  confirmCommission,
  setConfirmCommission,
  reasonForApproval,
  setreasonForApproval,
}) {
  const userRecord = useSelector(({ user }) => user.userRecord);
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const dispatch = useDispatch();
  const [isModalOpen, handleOpen] = useState(false);
  let pacificData = _.head(codeLabels?.PacificCountriesList).Label;

  let arr = pacificData.split(",");

  const [pacificMarkets] = useState(arr);
  const [errorObj, setErrorObj] = useState({});

  const [isCommissionOpen, setCommissionOpen] = useState({});
  const [haveCostingOptions, setHaveCostingOptions] = useState(false);
  const [salesForceModal, openSaleForceModal] = useState(false);

  const [validationHeader, setValidationHeader] = useState("");
  const [opsResourcesVariables] = useState([
    "surveyProgrammingResource",
    "surveyProgrammingResourceVendor",
    "dataProcessingResource",
    "dataProcessingResourceVendor",
    "dataProcessingComplexity",
    "translationsResource",
    "translationsResourceVendor",
    "verbatimCodingResource",
    "verbatimCodingResourceVendor",
    "chartingResource",
    "chartingResourceVendor",
    "dashboardingResource",
    "dashboardingResourceVendor",
    "qualityControl",
    "qualityControlComments",
    "dataCollectionPlatform",
    "platformComments",
  ]);
  const project = useSelector(
    ({ currentProject }) => currentProject.newProject
  );
  const currentCostingProfile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  const profile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );

  useEffect(() => {
    if (currentCostingProfile?.CountrySpecs) {
      let _haveCostingOptions = false;
      currentCostingProfile.CountrySpecs?.map((cs) =>
        cs.MethodologySpecs?.map((ms) => {
          if (ms?.CostingOptions?.filter((co) => co.Selected)?.length) {
            _haveCostingOptions = true;
          }
        })
      );
      setHaveCostingOptions(_haveCostingOptions);
    }
  }, [currentCostingProfile]);

  const validateForms = () => {
    let errorFieldsObj = {}; // object to store missing required fields - RFQ Form
    let isValid = true;
    let countrySpecs = [...currentCostingProfile.CountrySpecs];
    var allCostingOptionsUsed = [];
    let unavialable = [];
    let methodologyObj = {};
    let methodologyCountryObj = {};
    let isFieldsValid = true;
    let isTrackingProjectNonPacific = {};

    let fieldingCountries = [];
    if (Array.isArray(currentCostingProfile.FieldingCountries))
      fieldingCountries = currentCostingProfile.FieldingCountries.map(
        (fc) => fc.value
      );
    else fieldingCountries = currentCostingProfile.FieldingCountries.split(",");

    countrySpecs.map((cs) => {
      if (cs.MethodologySpecs) {
        cs.MethodologySpecs?.filter((ms) => !ms.NotApplicable)?.map((ms) => {
          methodologyObj[ms.id] = ms.Label;
          methodologyCountryObj[ms.id] = cs.CountryName;
          ms.CostingOptions?.map((co) => {
            co.CostingOptionSchema?.map((cps) => {
              if (!_.includes(allCostingOptionsUsed, cps.id) && cps.id) {
                allCostingOptionsUsed.push(cps.id);
              }
            });
          });
          if (
            ms?.RFQSchema?.required &&
            _.includes(fieldingCountries, cs.CountryCode)
          ) {
            if (!ms.RFQData) {
              isValid = false;
              isFieldsValid = false;
              unavialable = ms.RFQSchema?.required;
            } else {
              let allRequiredFields = ms.RFQSchema?.required;
              let _allRequiredFields = allRequiredFields.filter(
                (arf) => ms.RFQSchema?.properties[arf]?.dependentOn
              );
              for (let field of _allRequiredFields) {
                let parent = _.head(
                  ms.RFQSchema?.properties[field].dependentOn.fields
                ).fieldName;
                parent = ms.Variables.find((v) => v.label === parent);
                if (!ms.RFQData[parent] || ms.RFQData[parent] !== "Yes") {
                  allRequiredFields = allRequiredFields.filter(
                    (rf) => rf !== field
                  );
                }
              }

              let existingProperties = Object.keys(ms.RFQSchema?.properties);
              let finalFields = allRequiredFields?.filter((arf) =>
                _.includes(existingProperties, arf)
              );
              unavialable = finalFields?.filter(
                (req) => req && (!ms.RFQData[req] || ms.RFQData[req] == "0")
              );

              if (
                (_.includes(pacificMarkets, project?.CommissioningCountry) && project.CostingTemplateVersion != "v2.0") &&
                ms.RFQData["trackingProject"] === "Yes" &&
                (!ms.RFQData["numberOfWaves"] ||
                  ms.RFQData["numberOfWaves"] < 2)
              ) {
                unavialable.push("numberOfWaves");
              } else if (
                ms.CostingOptions.length &&
                ms.CostingOptions.find(
                  (co) => co.CoSelectedForSummary && co.CostingOptionData?.trackingProject === "Yes"
                )
              ) {
                isTrackingProjectNonPacific[ms.id] = true;
              }
              let haveInCostingOptions = ms.CostingOptions?.map(
                (co) => co?.CostingOptionData
              )?.filter(
                (co) =>
                  finalFields
                    ?.map((ff) => co && co[ff] && co[ff] != 0)
                    .filter((f) => f).length
              );
              if (unavialable.length && !haveInCostingOptions.length) {
                isValid = false;
                isFieldsValid = false;
              }
            }
          }
          if (!isFieldsValid && _.includes(fieldingCountries, cs.CountryCode)) {
            let fields = unavialable.map((obj) =>
              obj === "numberOfWaves"
                ? ms.SetupSchema.properties["numberOfWaves"]?.title
                : ms.RFQSchema.properties[obj]?.title
            );
            if (!errorFieldsObj[cs.CountryName])
              errorFieldsObj[cs.CountryName] = {};

            if (!errorFieldsObj[cs.CountryName].RFQ)
              errorFieldsObj[cs.CountryName].RFQ = {};

            if (!errorFieldsObj[cs.CountryName].RFQ[ms.Label])
              errorFieldsObj[cs.CountryName].RFQ[ms.Label] = {};

            errorFieldsObj[cs.CountryName].RFQ[ms.Label] = fields;
          }
          isFieldsValid = true;
        });
      }
    });

    if (currentCostingProfile?.WaveSpecs?.length) {
      let validMethIds = [];
      currentCostingProfile.CountrySpecs?.map((cs) => {
        cs.MethodologySpecs?.filter((ms) => !ms.NotApplicable)
          ?.map((ms) => ms.id)
          ?.map((ms) => {
            if (!_.includes(validMethIds, ms)) validMethIds.push(ms);
          });
      });
      let wavespecs = [
        ...currentCostingProfile.WaveSpecs?.filter((ws) =>
          _.includes(validMethIds, ws.MethodologySpecId)
        ),
      ];
      if (wavespecs.length) {
        wavespecs.map((ws) => {
          // ws = _.head(wavespecs)
          let finalPropsToValidate = [];
          ws.OpsResourcesSchema?.required?.map((prop) => {
            let currentProp = ws.OpsResourcesSchema.properties[prop];

            if (
              currentProp &&
              !currentProp.dependentOn &&
              !currentProp.disabled &&
              !currentProp.selected
            ) {
              if (
                (!_.includes(opsResourcesVariables, prop) &&
                  !_.includes(allCostingOptionsUsed, prop)) ||
                (_.includes(opsResourcesVariables, prop) &&
                  userRecord.CanEditOpsResources)
              )
                if (
                  prop === "surveyProgrammingResource" &&
                  ws.OpsResourcesData &&
                  ws.OpsResourcesData["surveyProgrammingRequired"] === "No"
                )
                  //todo: not sure why it is been done in this way in CountryMethodologyTabsFinal
                  //copied the same, but need to be fixed in a proper way
                  return;

              if (
                _.includes(opsResourcesVariables, prop) &&
                !userRecord.CanEditOpsResources
              )
                return;
              finalPropsToValidate.push(prop);
            } else {
              if (
                !(currentProp?.dependentOn?.fields?.length > 1) &&
                currentProp &&
                !currentProp.disabled &&
                !currentProp.selected
              ) {
                let depProp = _.head(currentProp?.dependentOn?.fields);
                if (depProp) {
                  let parentProp = {};
                  Object.keys(ws.OpsResourcesSchema.properties).map((pp) => {
                    if (
                      ws.OpsResourcesSchema.properties[pp].title ==
                      depProp.fieldName
                    ) {
                      parentProp = pp;
                    }
                  });

                  if (
                    parentProp &&
                    ws.OpsResourcesData &&
                    ws.OpsResourcesData[parentProp] &&
                    parentProp === "chartingComplexity" &&
                    ws.OpsResourcesData[parentProp] == "EF" &&
                    _.includes(opsResourcesVariables, prop) &&
                    userRecord.CanEditOpsResources &&
                    ws.OpsResourcesData[parentProp] != "No" &&
                    !_.includes(finalPropsToValidate, prop)
                  ) {
                    if (
                      (!_.includes(opsResourcesVariables, prop) &&
                        !_.includes(allCostingOptionsUsed, prop)) ||
                      (_.includes(opsResourcesVariables, prop) &&
                        userRecord.CanEditOpsResources)
                    )
                      finalPropsToValidate.push(prop);
                  }
                }
              }
            }
          });
          let finalErrorFields = [];

          const numberOfWaves = ws?.OpsResourcesData?.["numberOfWaves"] ? ws.OpsResourcesData["numberOfWaves"] : 0;

          if (
            isTrackingProjectNonPacific[ws.MethodologySpecId] &&
            ws.OpsResourcesSchema?.properties?.["numberOfWaves"] &&
            numberOfWaves < 2
          ) {
            isValid = false;
            isFieldsValid = false;
            finalErrorFields.push(
              ws.OpsResourcesSchema?.properties?.["numberOfWaves"]?.title
            );
          }
          finalPropsToValidate.map((ftv) => {
            if (
              !ws.OpsResourcesData ||
              (ws.OpsResourcesData &&
                (!ws.OpsResourcesData[ftv] || ws.OpsResourcesData[ftv] == "0"))
            ) {
              isValid = false;
              isFieldsValid = false;
              finalErrorFields.push(
                ws.OpsResourcesSchema.properties[ftv].title
              );
            }
          });
          if (!isFieldsValid) {
            if (!errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]])
              errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]] = {};

            if (!errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD)
              errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD =
                {};

            if (
              !errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
              methodologyObj[ws.MethodologySpecId]
              ]
            )
              errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                methodologyObj[ws.MethodologySpecId]
              ] = {};

            errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
              methodologyObj[ws.MethodologySpecId]
            ] = finalErrorFields;
          }
          isFieldsValid = true;
        });
      }
    }

    if (!isValid) {
      if (document.getElementById("RFQFinalForm")) {
        document.getElementById("RFQFinalForm").requestSubmit();
        document.getElementById("OpsResourcesFinalForm")?.requestSubmit();
      }
      setErrorObj(errorFieldsObj);
      handleOpen(true);
    }
    return isValid;
  };
  function commissionProject() {
    if(profile.ExceptionCommision)
    {
      setCommisionModalStatus();
      return
    }
    var vendor = false;
    var check = true;
    currentCostingProfile.CountrySpecs?.forEach((c) => {
      c.MethodologySpecs.forEach((m) => {
        if (m.CostingType == 3 && (project?.ParentProjectId && project?.IsCopy)) {
          if (m.CostingOptions.every(co => co.SelectedVendorCost)) {
            m.CostingOptions.forEach(co => {
              let sample = co.CostingOptionData.sampleSizeMain
              let achievableSample = m?.BiddingDetail?.SelectedVendor.find(sv => sv.id == co.id)?.selected.filter(x => (!x.IsSUD)).reduce((result, sample) => result + parseInt(sample.AchivableSamples), 0)

              if (sample && achievableSample && sample == achievableSample) {
                check = true
                vendor = true
              }
              else {
                check = false
                vendor = false
              }
            })
          }
          else {
            check = false
            vendor = false
          }
          // vendor = true;
        }
      });
    });
    if (!vendor && !check) {
      //     if()
      //   if (currentCostingProfile.VenodrBidDeadLineStatus != "close") {

      //     check = false;
      toastr.error("Vendors achievable samples are suppose to be same as target sample size / number of groups and all rounds suppose to be closed");
      //   }
    }
    if (check) {
      //todo: implement with validations with new changes
      if (
        haveCostingOptions ||
       ( _.includes(pacificMarkets, project.CommissioningCountry) && project.CostingTemplateVersion != "v2.0")
      ) {
        
        setValidationHeader("Cannot Proceed to Commission");
        if ((project.IsSyndicated && project.SyndicatedwithOpp !== true) && project.ParentProjectId) {
          setCommisionModalStatus();
        } else if (validateForms()) {
          let products = []
          let TbProducts = ["CMI-TB BRAND",
                            "CMI-TB CONSUMER",
                            "CMI-TB CX",
                            "CMI-TB OTHER",
                            "CMI-TB SHPPRTREND",
                            "CMI-TB COMMS",
                            "CMI TB BRAND",
                            "CMI TB CONSUMER",
                            "CMI TB CX",
                            "CMI TB OTHER",
                            "CMI TB SHPPRTREND", 
                            "CMI TB COMMS"
                          ];
      
      while(typeof profile.ProductTableData === "string"){
        profile.ProductTableData = JSON.parse(profile.ProductTableData)
      }
      profile.ProductTableData.map((data)=>{
        if(!data.isRed)
        {
          products.push(data.MaterialID)
        } 
        else if (data.isRed && data.isRed !== true){
          products.push(data.MaterialID)
        }       
      })

      let TbProductsAva = products.some((item)=> TbProducts.includes(item)) 
          if (currentCostingProfile.OutOfPocketCostPercent || profile.IsZeroCost  || TbProductsAva == true || (products.includes("CMI-CNSMR EPS") || products.includes ("CMI CNSMR EPS")))
            setCommisionModalStatus();
          else
            toastr.error(
              "Cannot Proceed to Commission",
              "Cannot proceed without Out Of Pocket Cost Percent"
            );
        }
      } else {
        toastr.error(
          "Cannot Proceed to Commission",
          "Cannot proceed without Costing Options. Please provide Costing options"
        );
      }
    }
  }
  const setCommisionModalStatus = () => {
    if (
      currentCostingProfile &&
      currentCostingProfile.ProfileSetting &&
      currentCostingProfile.ProfileSetting.NeedsSFStatusCheck &&
      (!currentCostingProfile.Project.IsSyndicated  || currentCostingProfile.Project.SyndicatedwithOpp == true)
    ) {
      let reqProjectClients = currentCostingProfile.Project?.ContractDetails;
      let sfClients = reqProjectClients.filter((rpc) => rpc.isSF);
      if (
        sfClients.filter(
          (sc) =>
            sc.Probability >=
            currentCostingProfile.ProfileSetting.MinimumSFProbability
        ).length ||
        !sfClients.length
      ) {
        setCommissionOpen({
          ...isCommissionOpen,
          ModalCommissioning: !isCommissionOpen.ModalCommissioning,
        });
        console.log("profile", profile.ProfileStatus);

      } else {
        openSaleForceModal(true);
      }
    } else {
      setCommissionOpen({
        ...isCommissionOpen,
        ModalCommissioning: !isCommissionOpen.ModalCommissioning,
      });
    }
  };
  const closePopupforCommission = () => {
    setConfirmCommission(!confirmCommission);
  }

  return (
    <>
      <Modal
        isOpen={confirmCommission}
        toggle={() => setConfirmCommission(!confirmCommission)}
        centered={true}
        size="md"
        backdrop="static"
      >
        <ModalHeader toggle={() => setConfirmCommission(!confirmCommission)}>
          {/* <img src={logo} className="modallogo" /> */}

        </ModalHeader>
        <div>
          <span className="modaltitle modalops">Commission Costing</span>
        </div>
        <ModalBody>
          <span className="modaltext">Are you sure you want to Continue  Commissioning?</span>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={(e) => {
              commissionProject();
              closePopupforCommission();
            }}
          // disabled={app.recordloading}
          >
            Confirm
            {/* {loading ? <Spinner size="small" /> : null} */}
          </Button>
          <Button
            color="secondary"
            onClick={closePopupforCommission}
          // disabled={app.recordloading}
          >
            Cancel
          </Button>{" "}
        </ModalFooter>
      </Modal>
      <Modal className="modal-with-header" isOpen={isModalOpen} toggle={() => handleOpen()} size="lg" centered={true}>
        <ModalHeader
          toggle={() => handleOpen(!isModalOpen)} >
          {/* <img src={logo} className="modallogo" /> */}
        </ModalHeader>
        <div>
          <span className="modaltitle modalvalidate text-danger">{validationHeader}</span>
        </div>
        <ModalBody id="scrollModalBody" className="modal-body-error-header">
          <p>
            <b>
              Key Parameters information is missing at cost option level for
              following Country and Methodologies
            </b>
          </p>
          {Object.keys(errorObj)?.length ? (
            <>
              {Object.keys(errorObj).map(function (key) {
                return (
                  <>
                    <div className="displayCountry col-md-12">
                      <h4>
                        <b>{key} :</b>
                      </h4>
                    </div>
                    <div class="col-md-12">
                      {errorObj[key]?.RFQ &&
                        Object.keys(errorObj[key].RFQ).length ? (
                        <>
                          <p>
                            <b>RFQ Form Section:</b>
                          </p>
                          <Table id="errorTable">
                            <thead>
                              <tr>
                                <th>Methodologies</th>
                                <th>Fields</th>
                              </tr>
                            </thead>
                            {
                              <tbody>
                                {Object.keys(errorObj[key].RFQ).map(function (
                                  key1
                                ) {
                                  return (
                                    <tr>
                                      <td>{key1} </td>
                                      <td>
                                        {errorObj[key].RFQ[key1].join(", ")}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            }
                          </Table>
                        </>
                      ) : null}
                    </div>
                    <div class="col-md-12">
                      {errorObj[key]?.SD &&
                        Object.keys(errorObj[key].SD).length ? (
                        <>
                          <p>
                            <b>Setup and Delivery Section:</b>
                          </p>
                          <Table id="errorTable">
                            <thead>
                              <tr>
                                <th>Methodologies</th>
                                <th>Fields</th>
                              </tr>
                            </thead>
                            {
                              <tbody>
                                {Object.keys(errorObj[key].SD).map(function (
                                  key1
                                ) {
                                  return (
                                    <tr>
                                      <td>{key1} </td>
                                      <td>
                                        {errorObj[key].SD[key1].join(", ")}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            }
                          </Table>
                        </>
                      ) : null}
                    </div>
                  </>
                );
              })}
            </>
          ) : null}
          
        </ModalBody>
        <ModalFooter>
        
        <Button color="primary" onClick={() => handleOpen(!isModalOpen)}>
            Ok
          </Button>
         
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={salesForceModal}
        toggle={() => openSaleForceModal(!salesForceModal)}
        centered={true}
      >
        <ModalHeader toggle={() => openSaleForceModal(!salesForceModal)}>
          {/* <img src={logo} className="modallogo" /> */}
        </ModalHeader>
        <span className="modaltitle">
          Update Client Details
        </span>
        <ModalBody>
          Please update client details to proceed
          {currentCostingProfile.Project &&
            currentCostingProfile.Project.ContractDetails
            ? currentCostingProfile.Project.ContractDetails.map((cd, index) => (
              <>
                <Row className="mb-2">
                  <Col>
                    <Label className="small font-weight-bold mb-0">
                      Opportunity Details #{index + 1}
                    </Label>
                  </Col>
                  {cd.isSF ? (
                    <Col>
                      <a
                        className="update-client-details"
                        onClick={() =>
                          dispatch(
                            currentProjectActions.syncContactDetails(
                              currentCostingProfile.Project.id,
                              cd,
                              true,
                              () => {
                                openSaleForceModal(false);
                                setCommissionOpen({
                                  ...isCommissionOpen,
                                  ModalCommissioning:
                                    !isCommissionOpen.ModalCommissioning,
                                });
                              }
                            )
                          )
                        }
                      >
                        Update Client Details
                      </a>
                    </Col>
                  ) : null}
                </Row>
                <Row className="mb-2">
                  <Col xs="4" className="pr-0">
                    Name
                  </Col>
                  <Col xs="8" className="pl-1">
                    {cd.OpportunityName}
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs="4" className="pr-0">
                    Account
                  </Col>
                  <Col xs="8" className="pl-1">
                    {cd.AccountName}
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs="4" className="pr-0">
                    Industry
                  </Col>
                  <Col xs="8" className="pl-1">
                    {cd.Industry}
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs="4" className="pr-0">
                    OP Number
                  </Col>
                  <Col xs="8" className="pl-1">
                    {cd.OpportunityNumber}
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs="4" className="pr-0">
                    Stage
                  </Col>
                  <Col xs="8" className="pl-1">
                    {cd.Stage} ({cd.Probability}
                    {"%"})
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs="4" className="pr-0">
                    Amount
                  </Col>
                  <Col xs="8" className="pl-1">
                    {cd.Amount} {cd.AmountCurrency}
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs="4" className="pr-0">
                    Start of Delivery
                  </Col>
                  <Col xs="8" className="pl-1">
                    {cd.StartofDelivery
                      ? cd.StartofDelivery.split("T")[0]
                      : "Not available"}
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs="4" className="pr-0">
                    End of Delivery
                  </Col>
                  <Col xs="8" className="pl-1">
                    {cd.EndofDelivery
                      ? cd.EndofDelivery.split("T")[0]
                      : "Not available"}
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs="4" className="pr-0">
                    Close Date
                  </Col>
                  <Col xs="8" className="pl-1">
                    {cd.CloseDate
                      ? cd.CloseDate?.split("T")[0]
                      : "Not available"}
                  </Col>
                </Row>
                <hr
                  className="mb-0 mt-0"
                  style={{ borderStyle: "dotted" }}
                ></hr>
              </>
            ))
            : "No Contract Details Found"}
        </ModalBody>
      </Modal>
      <ModalCommissioning
        setIsOpen={setCommissionOpen}
        setConfirmCommission={setCommissionOpen}
        isOpen={isCommissionOpen}
        toggle={() => {
          setCommissionOpen({
            ...isCommissionOpen,
            ModalCommissioning: !isCommissionOpen.ModalCommissioning,
          });
        }}
      />
    </>
  );
}

export default CommissioningConfirmModal;
