import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { Badge, Collapse } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import nielsenTab from "../assets/img/avatars/nielsen-n-tab.png";
import routes from "../routes/index";
import * as currentProjectActions from "../redux/actions/currentProjectActions";
import * as currentCostingActions from "../redux/actions/currentCostingActions";
import AclGuard from "../utils/aclGuard";



const SidebarCategory = withRouter(
    ({
        name,
        badgeColor,
        badgeText,
        icon: Icon,
        isOpen,
        children,
        onClick,
        location,
        to,
    }) => {
        const getSidebarItemClass = (path) => {
            return location.pathname.indexOf(path) !== -1 ||
                (location.pathname === "/" && path === "/dashboard")
                ? "active"
                : "";
        };

        return (
            <li className={"sidebar-item " + getSidebarItemClass(to)}>
                <span
                    data-toggle="collapse"
                    className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
                    onClick={onClick}
                    aria-expanded={isOpen ? "true" : "false"}
                >
                    <Icon size={18} className="align-middle mr-3" />
                    <span className="align-middle">{name}</span>
                    {badgeColor && badgeText ? (
                        <Badge color={badgeColor} size={18} className="sidebar-badge">
                            {badgeText}
                        </Badge>
                    ) : null}
                </span>
                <Collapse isOpen={isOpen}>
                    <ul id="item" className={"sidebar-dropdown list-unstyled"}>
                        {children}
                    </ul>
                </Collapse>
            </li>
        );
    }
);

const SidebarItem = withRouter(
    ({ name, badgeColor, badgeText, icon: Icon, location, to, onRedirection, access }) => {
        const getSidebarItemClass = (path) => {
            return location.pathname === path ? "active" : "";
        };
        const userRecord = useSelector(({ user }) => user.userRecord)
        const userEmail = useSelector(({ user }) => user.userRecord.Email);
        const dispatch = useDispatch();

        return (
            (!access || (access && userRecord[access])) ?
                <li className={"sidebar-item " + getSidebarItemClass(to)} title={name}>
                    <NavLink to={to} className="sidebar-link" activeClassName="active" onClick={(e) => {
                        if (to == '/proposal-new') {
                            dispatch(currentProjectActions.clearCurrentProject());
                        }
                        if (onRedirection && location.pathname != to)
                            dispatch(onRedirection({agentEmail: userEmail}))
                        if (location.pathname == to)
                            e.preventDefault()
                    }}>
                        {/* {Icon ? <Icon size={18} className="align-middle mr-3" /> : null} */}
                        {name}
                        {badgeColor && badgeText ? (
                            <Badge color={badgeColor} size={18} className="sidebar-badge">
                                {badgeText}
                            </Badge>
                        ) : null}
                    </NavLink>
                </li>
                : null
        );
    }
);

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    toggle = (index) => {
        // Collapse all elements
        Object.keys(this.state).forEach(
            (item) =>
                this.state[index] ||
                this.setState(() => ({
                    [item]: false,
                }))
        );

        // Toggle selected element
        this.setState((state) => ({
            [index]: !state[index],
        }));
    };

    UNSAFE_componentWillMount() {
        /* Open collapse element that matches current url */
        const pathName = this.props.location.pathname;

        routes.forEach((route, index) => {
            const isActive = pathName.indexOf(route.path) === 0;
            const isOpen = route.open;
            const isHome = route.containsHome && pathName === "/" ? true : false;

            this.setState(() => ({
                [index]: isActive || isOpen || isHome,
            }));
        });
    }

    render() {
        const { sidebar, layout, userRecord } = this.props;

        return (
            <nav
                className={
                    "sidebar sidebar-sticky" + (!sidebar.isOpen ? " toggled" : "")
                    // +           (sidebar.isSticky ? " sidebar-sticky" : "") 
                }
            >
                <div className="sidebar-content">
                    <PerfectScrollbar>
                        <div className="sidebar-brand" href="/">
                            {/* <a href="/"> */}
                            {/* <Box className="align-middle text-primary" size={24} />{" "} */}
                            <img style = {{width:"42px",color:"white"}}
                                className="align-middle text-primary"
                                src={nielsenTab}
                                alt="logo"
                            />

                            <span className="align-middle" style={{ paddingLeft: "10px" }}>
                                CINTRAL
                            </span>
                        </div>

                        <ul className="sidebar-nav">
                            {routes.map((category, index) => {
                                return (
                                    // <AclGuard entity={category.path}>
                                    <React.Fragment key={index}>
                                        {category.header ? (
                                            <li className="sidebar-header">{category.header}</li>
                                        ) : null}

                                        {category.children ? (
                                            <SidebarCategory
                                                name={category.name}
                                                badgeColor={category.badgeColor}
                                                badgeText={category.badgeText}
                                                icon={category.icon}
                                                to={category.path}
                                                isOpen={this.state[index]}
                                                onClick={() => this.toggle(index)}
                                            >
                                                {category.children.map((route, index) => (
                                                    <SidebarItem
                                                        key={index}
                                                        name={route.name}
                                                        to={route.path}
                                                        badgeColor={route.badgeColor}
                                                        badgeText={route.badgeText}
                                                    />
                                                ))}
                                            </SidebarCategory>
                                        ) : category.hidden ? null : (
                                            <SidebarItem
                                                name={category.name}
                                                to={category.path}
                                                icon={category.icon}
                                                badgeColor={category.badgeColor}
                                                badgeText={category.badgeText}
                                                onRedirection={category.onRedirection}
                                                access={category.access}
                                            />
                                        )}
                                    </React.Fragment>
                                    // </AclGuard>
                                );
                            })}
							<li class="sidebar-item " title="PowerBI Project Dashboard">
								<a class="sidebar-link" target="_blank" href="https://nam02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fapp.powerbi.com%2Fgroups%2Fme%2Freports%2F31bcb364-9f46-44e8-8dfd-022132e6351a%2FReportSection3cdc951c11700073d059%3Fexperience%3Dpower-bi&amp;data=05%7C02%7CHimton.Hui%40nielseniq.com%7C83e265b1a4f64c3bce4a08dd4cd2f3a9%7C6ac7a1f45fb14153bb4f12d2020a1f7d%7C0%7C0%7C638751191085181580%7CUnknown%7CTWFpbGZsb3d8eyJFbXB0eU1hcGkiOnRydWUsIlYiOiIwLjAuMDAwMCIsIlAiOiJXaW4zMiIsIkFOIjoiTWFpbCIsIldUIjoyfQ%3D%3D%7C0%7C%7C%7C&amp;sdata=LBgxC2Vj9NmgOPV1jfns4UwHMwPf1s2eWlwabN63zJg%3D&amp;reserved=0">
								PowerBI Project Dashboard
								</a>
							</li>
                        </ul>

                        {/* {!layout.isBoxed && !sidebar.isSticky ? (
              <div className="sidebar-bottom d-none d-lg-block">
                <div className="media">
                  <img
                    className="rounded-circle mr-3"
                    src={avatar}
                    alt="Chris Wood"
                    width="40"
                    height="40"
                  />
                  <div className="media-body">
            <h5 className="mb-1">{userRecord.FirstName} {userRecord.LastName}</h5>
                    <div>
                      <FontAwesomeIcon
                        icon={faCircle}
                        className="text-success"
                      />{" "}
                      Online
                    </div>
                  </div>
                </div>
              </div>
            ) : null} */}
                    </PerfectScrollbar>
                </div>
            </nav>
        );
    }
}

export default withRouter(
    connect((store) => ({
        sidebar: store.sidebar,
        layout: store.layout,
        userRecord: store.user.userRecord,
    }))(Sidebar)
);