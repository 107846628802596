import React, { useState, useEffect } from "react";
import { ModalGeneric } from "./ModalGeneric";
import { useSelector, useDispatch } from "react-redux";
import { Button, Table } from "reactstrap";
import * as currentCostingActions from "../../redux/actions/currentCostingActions";
import * as currentProjectActions from "../../redux/actions/currentProjectActions";
import axios from "../../axios-interceptor";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import { getSubMethodologyAttribute } from "../../utils/codeLabels";
import Select from "react-select";
import * as offlineReasons from "./json/offlineReasons.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ModalPostCommission from "./ModalPostCommission";
import { faExclamationCircle, faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";


const MSDValidation = ({ isOpen, toggle, setIsOpen ,msdValid= {Price:true}}) => {
  const dispatch = useDispatch();
  const app = useSelector(({ app }) => app);
  const profile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  const project = useSelector(
    ({ currentProject }) => currentProject.newProject
  );
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  // const header = !("Stage" in msdValid) && !("WBS" in msdValid) ?  <span className="commissionmodal m-0">Approval is allowed only when these criteria are fulfilled</span> : <span className="commissionmodal m-0">Project Commissioning is allowed only when these criteria are fulfilled</span>;
  let header;
  if(project.IsSyndicated && project.SyndicatedwithOpp !== true){
    header =  <span className="commissionmodal m-0" style={{color:"red"}}>Please fulfill these criteria before proceeding.</span>;
  }
  else{
    header = !("Stage" in msdValid) && !("WBS" in msdValid)  ?  <span className="commissionmodal m-0" style={{color:"red"}}>Please fulfill these criteria before proceeding.</span> : <span className="commissionmodal m-0" style={{color:"red"}}>Please fulfill these criteria before proceeding.</span>;
  }
  let comment = React.createRef();
  const [selectedReasons, setSelectedReasons] = useState([]);
  // const price = msdValid?.Price && msdValid?.Price[0] && msdValid?.Price[1] ? msdValid.Price[0]>=msdValid.Price[1]*0.99 && msdValid.Price[0] <= msdValid.Price[1]*1.01 : msdValid.Price;
  const probability = msdValid?.Stage && msdValid?.Stage[0] !== undefined ? msdValid?.Stage[0].Probability : "";
  const opportunityNumber = msdValid?.Stage && msdValid?.Stage[0] !== undefined ? msdValid?.Stage[0].OpportunityNumber : "";
  const stage = msdValid?.Stage && msdValid?.Stage[0] !== undefined ? msdValid?.Stage[0].Stage : "";
  const costOptions = msdValid?.Costopt && msdValid?.Costopt !== undefined ? msdValid?.Costopt : [];
  const wbs = msdValid?.WBS && msdValid?.WBS[0] !== undefined ? msdValid?.WBS[0] : [];
  
  if(Array.isArray(wbs) && wbs.length){
    wbs.map((wb)=>{
      profile.ProductTableData.map((prod)=>{  
        if(wb.OpportunityLineItemID == prod.OpportunityLineItemID){
          wb.methodology = prod.methodology.length ?  prod.methodology[0].value : "Not Available"
        }
      })
    })
  }

  const meth = (methData)=>{
    let res = methData.map(item=>item.value).join(',')
    console.log("res meth",res);
    return res;
  }
  
  if(!project.IsSyndicated || project.SyndicatedwithOpp == true){
    if(Array.isArray(costOptions) && costOptions.length){
      costOptions.map((cost)=>{
        profile.ProductTableData.map((prod)=>{
        //  console.log(prod, "ProductTableData")
          if(cost.OpportunityLineItemID == prod.OpportunityLineItemID){
            cost.methodology = prod.methodology.length ? meth(prod.methodology) : "Not Available"
            //console.log(cost, 'Each')
          }
        })
      })
    }
  }
  else{
    if(Array.isArray(costOptions) && costOptions.length){
      profile.ProductTableData.map((prod)=>{
        costOptions.map((cost)=>{
          if(cost.WBSNumber== prod.WBSNumber){
            cost.methodology = prod.methodology.length ? meth(prod.methodology) : "Not Available"
            //console.log(cost, 'Each')
          }
        })
      })
    }}
  

  const [otherReason, setOtherReason] = useState("");
  const [shopperMethodologies] = useState(["SM000024", "SM000026", "SM000029"]);

  let profileSubMethodologiesDetails = [];
  let isNotOnline = false;
  if (
    profile &&
    profile.SubMethodology &&
    codeLabels &&
    codeLabels.SubMethodologyOptions
  ) {
    let stype = typeof profile.SubMethodology;
    let profileSubMethodologies = [];
    if (stype === "string") {
      profileSubMethodologies = profile.SubMethodology.split(",");
    } else {
      profileSubMethodologies = profile.SubMethodology.map((s) => s.value);
    }
    profileSubMethodologies.forEach((method) => {
      let i = codeLabels.SubMethodologyOptions.findIndex(
        (a) => a.Code === method
      );
      if (i >= 0) {
        profileSubMethodologiesDetails.push(
          codeLabels.SubMethodologyOptions[i]
        );
      }
    });
    profileSubMethodologiesDetails = profileSubMethodologiesDetails.filter(a => {
      if (a.FieldType !== "Online") {
        return profile.CountrySpecs?.some(cs => {
          let ms = cs.MethodologySpecs.find(i => i.Code === a.Code);
          return ms?.NotApplicable !== true
        })
      }
      return true
    })
    if (
      profileSubMethodologiesDetails.find((a) => a.FieldType !== "Online") &&
      profileSubMethodologiesDetails.find(
        (a) => !_.includes(shopperMethodologies, a.Code)
      )
    ) {
      isNotOnline = true;
    }
  }
  const doCommissioning = () => {

    if (isNotOnline) {
      if (selectedReasons.length > 0) {
        if (
          selectedReasons.find((s) => s.value === "Others") &&
          (otherReason === null || otherReason.trim() === "")
        )
          return false;
        dispatch(
          currentCostingActions.setReasonForOfflineProposal(
            selectedReasons,
            otherReason
          )
        );
      } else {
        return false;
      }
    }
    toggle();
    dispatch(
      currentCostingActions.commissionCosting(() => {
        //console.log("callback called");
        let waveSpecId = profile.WaveSpecs ? _.head(profile.WaveSpecs)?.id : 0;
        if (waveSpecId && profile?.Project?.BusinessUnit == "CI")
          axios.post(`/utils/mail/${waveSpecId}/rvn/all`).then((res) => {
            dispatch({
              type: currentProjectActions.UPDATE_NEW_PROJECT,
              newProject: { ...project, ProjectStatus: "3" },
            });
            debugger
            toastr.success(
              "Mail Sent Successfully",
              "Mail Sent to Revenue Controller"
            );
            // setCommissionOpen(pre => !pre)
          }).catch((error) => {
            toastr.error(
              "Mail Sending failed",
              "Mail Sending to Revenue Controller failed"
            );
          });
          setIsOpen({ ModalPostCommission: isOpen.ModalCommissioning });
        })
    );
  };

  useEffect(() => {
    if (profile) {
      setSelectedReasons(
        profile.ReasonForOfflineProposal ?
          typeof profile.ReasonForOfflineProposal == "string" ? JSON.parse(profile.ReasonForOfflineProposal) : profile.ReasonForOfflineProposal : []
      );
      setOtherReason(
        profile.ReasonForOfflineProposalOther
          ? profile.ReasonForOfflineProposalOther
          : null
      );
    }
  }, []);

  const body = (
    <>
   {"Stage" in msdValid ? <hr></hr> : ""}
 {/* {probability == 100 && opportunityNumber && stage ?<FontAwesomeIcon color = "green" icon={faThumbsUp}></FontAwesomeIcon>:<FontAwesomeIcon color = {"red"} icon={faThumbsDown}></FontAwesomeIcon>} <span style={{color:probability == 100 && opportunityNumber && stage?"green":"red"}} color="green"> {opportunityNumber} = {stage} ({probability}% Probability)</span> */}
 {"Stage" in msdValid ? (msdValid.Stage === true ?<FontAwesomeIcon color = "green" icon={faThumbsUp}></FontAwesomeIcon>:<FontAwesomeIcon color = {"red"} icon={faThumbsDown}></FontAwesomeIcon>):""} 
 <span style={"Stage" in msdValid ? {color:msdValid.Stage === true?"green":"red"} : {}} color="green">{"Stage" in msdValid ? "Change to Close Won Approved (100 % Profitability)" : "" }</span> 
 <span>{msdValid.Stage !== true && opportunityNumber && stage && probability && <> <br/><br/></>} {"Stage" in msdValid ? (msdValid.Stage !== true && opportunityNumber && stage && probability ?  `${opportunityNumber} = ${stage} (${probability}% Probability)`:""):""}</span>
<hr></hr>
{/* {price ?<FontAwesomeIcon color="green" icon={faThumbsUp}></FontAwesomeIcon> :<FontAwesomeIcon color = {"red"} icon={faThumbsDown}></FontAwesomeIcon>}<span style={{color:price?"green":"red"}} > MSD Price To Client = {msdValid.Price && msdValid.Price[0]!== undefined ? Math.round(msdValid.Price[0]) : 0} USD, CINTRAL Price To Client = {msdValid.Price && msdValid.Price[1]!== undefined ? Math.round(msdValid.Price[1]) : 0} USD </span> */}
{"Price" in msdValid ? (msdValid.Price === true ?<FontAwesomeIcon color="green" icon={faThumbsUp}></FontAwesomeIcon> :<FontAwesomeIcon color = {"red"} icon={faThumbsDown}></FontAwesomeIcon>) : ""}
{"Price" in msdValid ? (<span style={"Price" in msdValid ? {color:msdValid.Price === true?"green":"red"} : {}} >{"Price" in msdValid ? "Price to Client matched in MSD and CINTRAL" : ""} </span> ) : ""}
{"Price" in msdValid ? (<span>{msdValid.Price !== true && <><br/><br/></>} {"Price" in msdValid ? (msdValid.Price !== true ?  `MSD Price To Client = ${msdValid.Price && msdValid.Price[0]!== undefined ? Math.round(msdValid.Price[0]) : 0} USD, CINTRAL Price To Client = ${msdValid.Price && msdValid.Price[1]!== undefined ? Math.round(msdValid.Price[1]) : 0} USD` : ""):""} </span>) : ""}
{"WBS" in msdValid ? <hr></hr> : ""}
{/* {wbs ?<FontAwesomeIcon color="green" icon={faThumbsUp}></FontAwesomeIcon>:<FontAwesomeIcon color = {"red"} icon={faThumbsDown}></FontAwesomeIcon>} */}
{/* <span style={{color:wbs?"green":"red"}}>
   <Table responsive hover striped size="sm">
    <thead>
      <tr>
        <th>
          Material ID
        </th>
        <th>
          WBS Number
        </th>
      </tr>
    </thead>
    <tbody>
      {
        wbs?.map((wb) => {
          return (
            <tr>
              <td>
                {wb.MaterialID}
              </td>
              <td>
                {wb.WBSNumber}
              </td>
            </tr>
          );
        })
      }      
    </tbody>
  </Table>
</span> */}
{"WBS" in msdValid ? (msdValid.WBS === true ?<FontAwesomeIcon color="green" icon={faThumbsUp}></FontAwesomeIcon>:<FontAwesomeIcon color = {"red"} icon={faThumbsDown}></FontAwesomeIcon>) : ""}<span style={"WBS" in msdValid ? {color:msdValid.WBS === true?"green":"red"}: {}}> {"WBS" in msdValid ? "WBS Number is Available in MSD and CINTRAL":""} 
  {
    msdValid.WBS !== true && wbs && "WBS" in msdValid
      ?
      <>
      <br/>
      <br/> 
      <Table responsive hover striped size="sm">
      <thead>
        <tr>
          <th>
            ProductLine
          </th>
          <th>
            WBS Number
          </th>
          <th>
            Methodology
          </th>
        </tr>
      </thead>
      <tbody>
        {
          wbs?.map((wb,index) => {
            return (
              <tr>
                <td>
                {index+1 +"-" +wb?.MaterialID}
                </td>
                <td>
                  {wb?.WBSNumber}
                </td>
                <td> 
               {wb?.methodology}
                </td>
              </tr>
            );
          })
        }      
      </tbody>
    </Table>
    </> 
      : "" 
  } 
  
  </span>
   <hr></hr>
   {/* {costOptions ?<FontAwesomeIcon color="green" icon={faThumbsUp}></FontAwesomeIcon>:<FontAwesomeIcon color = {"red"} icon={faThumbsDown}></FontAwesomeIcon>}
   <span style={{color:costOptions?"green":"red"}}>
    <Table responsive hover striped size="sm">
      <thead>
        <tr>
          <th>
            Material ID
          </th>
          <th>
            WBS Number
          </th>
          <th>
            WBS level cost
          </th>
        </tr>
      </thead>
      <tbody>
        {
          costOptions?.map((cost) => {
            return (
              <tr>
                <td>
                  {cost.MaterialID}
                </td>
                <td>
                  {cost.WBSNumber}
                </td>
                <td>
                  {cost.TotalPriceUSD}
                </td>
              </tr>
            );
          })
        }      
      </tbody>
    </Table>
   </span> */}
   {msdValid.Costopt === true ?<FontAwesomeIcon color="green" icon={faThumbsUp}></FontAwesomeIcon>:<FontAwesomeIcon color = {"red"} icon={faThumbsDown}></FontAwesomeIcon>}
   <span style={{color:msdValid.Costopt === true?"green":"red"}}> Each WBS level has COST associated 
    {msdValid.Costopt !== true && costOptions 
    ? 
    <>
    <br/>
    <br/>
    <Table responsive hover striped size="sm">
      <thead>
        <tr>
          <th>
            ProductLine
          </th>
          <th>
            WBS Number
          </th>
          <th>
            WBS level cost (USD)
          </th>
          <th>
            Methodology
          </th>
          
        </tr>
      </thead>
      <tbody>
        {
          costOptions?.map((cost, index) => {
            return (
              <tr>
                <td>
                  {index+1 +"-" +cost?.MaterialID}
                </td>
                <td>
                  {cost?.WBSNumber}
                </td>
                <td>
                  {(cost?.Total).toFixed(2)}
                </td>
                <td> 
                {cost?.methodology}
                </td>
              </tr>
            );
          })
        }      
      </tbody>
    </Table> 
    </>
    : ""}
   </span>
   <hr></hr>
   {/* {msdValid.Time ?<FontAwesomeIcon color="green" icon={faThumbsUp}></FontAwesomeIcon>:<FontAwesomeIcon color = {"red"} icon={faThumbsDown}></FontAwesomeIcon>}<span style={{color:msdValid.Time?"green":"red"}}> Zero Time Cost with Justification Missing </span>
<hr></hr> */}
   {/* <FontAwesomeIcon color="red" icon={faExclamationCircle}/> <span style={{color:"red" , alignSelf:"center",fontWeight:"bold"}}>Fulfill all above Conditions to Commission</span> */}
    </>
  );
  const footer = (
    <>
  
      {/* <Button color="secondary" disabled={app.recordloading} onClick={toggle}>
        Cancel
      </Button> */}
    </>
  );

  return (
    <>

    <ModalGeneric
      isOpen={isOpen.MSDValidation}
      toggle={toggle}
      header={header}
      body={body}
      footer={footer}
    />
    </>
  );
};

export default MSDValidation;