import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// import * as currentProjectActions from "../../../redux/actions/currentProjectActions";

import { getLabel, getSubMethodologyAttribute} from "../../utils/codeLabels";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Row,
  Form,
  FormGroup,
  Label,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CreatableSelect from "react-select/creatable";
import { toastr } from "react-redux-toastr";

import { Link, useHistory } from "react-router-dom";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import * as sheetCall from "../../utils/msSheetAPiCall";

import { MinusCircle, PlusCircle } from "react-feather";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faSave,
  faBell,
  faCheck,
  faInfoCircle,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import _ from "lodash";
import * as deliveryActions from "../../redux/actions/deliveryActions";
import { getOnlyProject, getProject } from "../../redux/actions/currentProjectActions";
import { setCurrentWaveSpec } from "../../redux/actions/waveSpecsActions";
import {
  mailRequest,
  postComment,
  updateRequest,
  getFCSpoc,
  updateFcSpoc,
  setRequests,
  updateFcSpocEmail,
  expandrow,
  AccepectFcSpoc,
  RejectFcSpoc,
} from "../../redux/actions/requestsActions";
import {
  getSummaryCosting,
  saveCostingProfile,
} from "../../redux/actions/currentCostingActions";
import Select from "react-select";
import Info from "../../assets/icons/Connected-System-Icons/Navigation/Info";
import useAnalyticsEventTracker from "../../utils/useAnalyticsEventTracker";
import * as EventName from "../../utils/GoogleAnalyticsEvents";
import { SET_SAVE_BUTTON_DISABLED } from "../../redux/actions/appActions";
import { imgStyle } from "../marketDefault/DynamicRFQ/ModalRFQ";
import logo from "../../assets/img/avatars/nielsen-logo.png";
import * as currentCostingActions from "../../redux/actions/currentCostingActions";
import * as timeCostActions from "../../redux/actions/timeCostActions"
import TimeCostModal from "../../components/TimeCostModal";

const getNameFromMail = (mail) => {
  return _.head(mail.toLowerCase().split("@")).replaceAll(".", " ");
};

const ExpandableRowsTable = (props) => {
  const parentData = props.currentRequest.tableData;
  const currrentRowId = useSelector(({ requests }) => requests.currentRowId);
  const currentCostingProfile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  const project = useSelector(
    ({ currentProject }) => currentProject.newProject
  );
  //const [tableData, setTableData] = useState(props.currentRequest.tableData);
  const[filteredData, setFilteredData] = useState([]);
  const userEmail = useSelector(({ user }) => user.userRecord.Email);
  const [tableColumns, setTableColumns] = useState(
    props.currentRequest.tableColumns
  );
  const [objectData, setObjectData] = useState(props.currentFilter);
  const gaEventTracker = useAnalyticsEventTracker(EventName.RequestBoard);
  const dispatch = useDispatch();
  const history = useHistory();
  const app = useSelector(({ app }) => app);
  const allusers = useSelector(({ requests }) => requests.allusers);
  const [currentComment, updateComment] = useState("");
  const [isCloseRequestModal, setRequestClosingModal] = useState(false);
  const [timecostValidationModal, setTimecostValidationModal] = useState(false);
  const [isOpenRequestModal, setRequestOpeningModal] = useState(false);
  const [isAssignUserModal, setAssignUserModal] = useState(false);
  const [currentRequest, setCurrentRequest] = useState();
  const [fcSpocOpeningModal, setFcSpocOpeningModal] = useState(false);
  const [fcSpocEditEmail, setFcSpocEditEmail] = useState(false);
  const [fCSpocCountryList, setFCSpocCountryList] = useState([]);
  const [selctemailData, setSelectemailData] = useState();
  const [rowExpandable, setRowexpandble] = useState(false);
  const [fcList, setFclist] = useState([]);
  // const[expandid,setexpandid]=useState(()=>getID)
  const [selectAllChecked, setSelectAllChecked] = useState(true);
  const [checked, setChecked] = useState(true);
  const [currentValue, setCurrentValue] = useState([]);
  const FcSpocsList = useSelector(({ requests }) => requests.FcSpocsList);
  let rows = [];
  const [countryWiseUsers, setCountryWiseUsers] = useState([]);
  const [valid, setValid] = useState(true);
  const [updatedProjectId, setUpdatedProjectId] = useState();
  const [rejectResonModal,setRejectReasonModal]=useState(false)
  const [rejectValue, setRejectValue]=useState()
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const [disabled,setDisabled] = useState(false);
  const [priceChange,setPriceChanged] = useState(false);
  const [closedRequestDisabledCheck,setClosedRequestDisabledCheck] = useState(false);
  const [fieldingCountries , setFieldingCountries] = useState([]);
  let pacificData = _.head(codeLabels.PacificCountriesList).Label;
  let arr = pacificData.split(",");
  const [pacificMarkets] = useState(arr);
  const [timecostViewModal,settimecostViewModal]=useState(false);
  const navigate = useHistory();
  const [expandedRows, setExpandedRows] = useState({});
  useEffect(()=>{
    setFilteredData(parentData);
  },[parentData])
  useEffect(() =>{
    let req =filteredData?.find(x=>
      x.id == currrentRowId
    )
    checkDisabled(req);
    checkPriceChange(req)
    setFieldingCountryVal(req);
    setCurrentRequest(req);
  },[filteredData])
  useEffect(() => {
    let temp = [];
    if (FcSpocsList.length > 0 && allusers.length > 0) {
      FcSpocsList.map((obj) => {
        let intitalValue = obj.BusinessUnits?.map((v) => {if(v.FcSpocsList !== null){return v.FcSpocsList}else{return}}).filter( x => x).join();
        var flag = false;
        var dupFlag = false;
        var countryUsers = [];
        allusers.forEach((x) => {
          if (x.Countries) {
            if (x.Countries.search(obj.Code) >= 0) {
              if (countryUsers.length == 0) {
                countryUsers.push({ label: x.Email, value: x.Email });
              } else {
                countryUsers.forEach((y) => {
                  if (flag) {
                    return;
                  }
                  if (y == x.Email) {
                    flag = true;
                    return;
                  } else {
                    flag = false;
                  }
                });
                if (flag == false) {
                  countryUsers.push({ label: x.Email, value: x.Email });
                }
              }
            }
          }
        });
        if (temp.length == 0) {
          temp.push({
            FC: obj.Label,
            CountryCode: obj.Code,
            users: countryUsers,
            intialValue: intitalValue,
            checked: true,
            valid: true,
          });
        } else {
          temp.forEach((x) => {
            if (dupFlag) {
              return;
            }
            if (x.CountryCode == obj.Code) {
              dupFlag = true;
              return;
            } else {
              dupFlag = false;
            }
          });
          if (dupFlag == false) {
            temp.push({
              FC: obj.Label,
              CountryCode: obj.Code,
              users: countryUsers,
              intialValue: intitalValue,
              checked: true,
              valid: true,
            });
          }
        }
      });

      temp = temp.filter((item) => {
        let itempresent = false;
        fCSpocCountryList.forEach((x) => {
          if (itempresent) {
            return;
          }
          if (item.CountryCode == x.CountryCode) {
            itempresent = true;
          } else {
            itempresent = false;
          }
        });
        if (!itempresent) {
          return item;
        }
      });
      setCountryWiseUsers(temp);
    }
  }, [FcSpocsList]);


  const columns = [
    {
      dataField: "CountryLabel",
      text: "Fieding Country",
    },
    {
      dataField: "AgentEmail",
      text: "FC-Spoc",
    },
    {
      dataField: "Status",
      text: "Status",
    },
    {
      dataField: "createdAt",
      text: "Requested On",
    },
    {
      dataField: "DateClosed",
      text: "Completed On",
    },
    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <Row>
            <Col>
            <Link
            className={((currentRequest?.AcceptedBy !== null && currentRequest?.AcceptedBy.toLowerCase() == userEmail.toLowerCase()) && !row.IsClosed && row.AcceptedBy == null)? "pointer"
            : "no-actions"}
            onClick={() => {
                  // gaEventTracker(EventName.ReOpenRequest);
                  setSelectemailData(row);
                  setFcSpocEditEmail(true);
                }}>
              <FontAwesomeIcon
                icon={faPen}
              />
              </Link>
            </Col>
          </Row>
        );
      },
    },
  ];
  const changeHandler = (eve) => {
    let countrywiseusers = _.cloneDeep(countryWiseUsers);

    let flag = false;
    if (eve.target.type == "SpocList") {
      countrywiseusers.forEach((x) => {
        if (x.CountryCode == eve.target.id) {
          x.intialValue = eve.target.value;
        }
        if (x.checked) {
          if (x.intialValue) {
            if (
              x.intialValue.split(",").map((val) => {
                return val;
              }).length < 2
            ) {
              x.valid = false;
            } else {
              x.valid = true;
            }
          } else {
            x.valid = false;
          }
        } else {
          x.valid = true;
        }
      });
    } else if (eve.target.type == "CheckBox") {
      countrywiseusers.forEach((x) => {
        if (x.CountryCode == eve.target.id) {
          x.checked = eve.target.value;
        }
        if (x.checked) {
          if (x.intialValue) {
            if (
              x.intialValue.split(",").map((val) => {
                return val;
              }).length < 2
            ) {
              x.valid = false;
            } else {
              x.valid = true;
            }
          } else {
            x.valid = false;
          }
        } else {
          x.valid = true;
        }
      });
      countrywiseusers.forEach((x) => {
        if (flag) {
          return;
        }
        if (!x.checked) {
          flag = true;
          return;
        } else if (x.checked) {
          flag = false;
        }
      });
      setSelectAllChecked(!flag);
    }

    setCountryWiseUsers(countrywiseusers);
  };

  const validationCheck = (eve) => {
    let localValidationFlagChecked = false;
    let localValidationFlagValue = false;
    countryWiseUsers.forEach((x) => {
      let val = [];
      if (x.checked) {
        localValidationFlagChecked = true;
        val = x.intialValue?.split(",").map((val) => {
          return { label: val, value: val };
        });

        if (val?.length >= 2) {
          localValidationFlagValue = true;
        } else {
          x.valid = false;
          localValidationFlagValue = false;
        }
      }
    });
    return {
      atleast1checked: localValidationFlagChecked,
      valuesSelected: localValidationFlagValue,
    };
  };

  let comment = React.createRef();

  const userRecord = JSON.parse(localStorage.getItem("userRecord"));
  const getNameFromMailOptions = (mail) => {
    return (
      <span className="text-capitalize">
        {_.head(mail.toLowerCase().split("@")).replaceAll(".", " ")}
      </span>
    );
  };

  const postSelfComment = (reqId) => {
    if (currentComment)
      dispatch(
        postComment(
          {
            Comment: currentComment?.trim(),
            CommentOwnerEmail: userRecord.Email,
            RequestId: reqId?reqId:currentRequest.id,
          },
          () => updateComment("")
        )
      );
  };

  const initiateAssignUser = (req) => {
    let currenReq = { ...req };
    // if (req?.CostingProfile?.Project) {
    //   let exisitingAgents = currenReq.AgentEmail?.split(",")
    //   if (req?.CostingProfile?.Project?.OtherProjectTeamContacts) {
    //     let otherteamcontacts = req?.CostingProfile?.Project?.OtherProjectTeamContacts
    //     otherteamcontacts?.map(otc => {
    //       if (!_.includes(exisitingAgents, otc.value))
    //         exisitingAgents.push(otc.value)
    //     })
    //   }
    //   currenReq.AgentEmail = exisitingAgents?.join()
    //   // if (!currenReq.AgentEmail
    //   //   && req?.CostingProfile?.Project?.OtherProjectTeamContacts) {
    //   //   currenReq.AgentEmail = req?.CostingProfile?.Project?.OtherProjectTeamContacts?.map(m => m.value)?.join()
    //   // }
    // }
    //setCurrentRequest(currenReq);
    setCurrentRequest({
      ...currenReq,
      AssignedTo: userEmail
    })
    setAssignUserModal(true);
  };
  const getDefaultValue = (email) => {
    if (email) {
      // let reqVal = (allusers.filter((au) => _.includes(AgentEmail.split(","), au.Email)));
      let reqVal = email?.split(",");
      if (reqVal)
        return reqVal.map((rv) => {
          return {
            value: rv,
            label: rv,
            // label: `${rv.FirstName} ${rv.LastName}`,
          };
        });
    } else return "";
  };
  const getAllUserOptions = (req) => {
    let allUserOptions = [];
    if (allusers)
      allusers.map((c) => {
        allUserOptions.push({
          value: c.Email,
          label: c.Email,
          // label: getNameFromMailOptions(c.Email),
        });
      });
    if (req?.CostingProfile?.Project?.OtherProjectTeamContacts) {
      let otherteamcontacts =
        req?.CostingProfile?.Project?.OtherProjectTeamContacts;
      otherteamcontacts?.map((otc) => {
        if (_.includes(allUserOptions, otc.value))
          allUserOptions.push(otc.value);
      });
    }
    return allUserOptions;
  };

  const disablebtn = (row) => {
    if(row.AcceptedBy !== null && row.AcceptedBy.toLowerCase() == userEmail.toLowerCase()){
      if(row.ChildRequests.length){
        const testData = row.ChildRequests.map((item) => item.CountryCode);
        return fieldingCountries?.every((v) => {
          return testData.includes(v);
        });
      }else{
        return false;
      }
    }else{
      return true;
    }

};
  const closeRequestDisableBtn=(row) => {
    let val = false;
    let fieldingCountryList= row.CostingProfile.FieldingCountries.split(",").filter((item)=>
    item !== row.CostingProfile.Project.CommissioningCountry
    );
    if(row.ParentId == null){
      if(row.ChildRequests.length>0){
        if(fieldingCountryList.length == row.ChildRequests.length){
            val = row.ChildRequests.every(x => x.DateClosed)
        }else{
          val = false;
        }
        return !val;
      }else if(row.CostingProfile.isMultiCountry>0){
          return !val;
      }else{
        return val;
      }
    }
    else{
      return val;
    }
    
  }
  const updateFcstatus = (row) => {
    return row.map((item)=>{
      switch (item.IsClosed) {
        case null:
          item.Status = "Pending";
          break;
        case false:
          item.Status = "In-Progress";
          break;
        case true:
          item.Status = "Closed";
          break;
        }
        return{...item,createdAt:item.createdAt.split().map(x=>{
          let fulldate = new Date(x)
          let date = _.head(x.split("T"));
          let time = fulldate.toLocaleTimeString('en',
          { timeStyle: 'short', hour12: false });
          return(date +"  "+time)
        }),DateClosed:item.DateClosed? item.DateClosed.split().map(x=>{
          let fulldate = new Date(x)
          let date = _.head(x.split("T"));
          let time = fulldate.toLocaleTimeString('en',
          { timeStyle: 'short', hour12: false });
          return(date +"  "+time)
        }):null
        , CountryLabel:codeLabels.FieldingCountriesOptions?.map((v)=>{
          if(v.Code===item.CountryCode)
          {
            return v.Label
          }
      })
      }
    
    })
  }
  const getRelatedSubMeth=(methcode,submeth)=>{

    console.log("meth",methcode,submeth)
    let meth=[];
    submeth.map((sm)=>{
      meth.push(codeLabels.SubMethodologyOptions.find(smo => smo.Code == sm))
      console.log(meth);
    })
    let smethlabels= meth.filter((m)=>{
     return m.ParentMethodologyCode===methcode
  })
  
  let labels=smethlabels.map((m)=>m.Label)
  if(labels.length>0){
    return labels.join("\n")
  }
  else{
    return labels;
  }

}
  const projectDetails = (row) => {      
    const validateProfile=()=>{
      let valid=true
      currentCostingProfile?.CountrySpecs?.map((country)=>{
        country.MethodologySpecs.map((method)=>{
          method.CostingOptions.map((co,index)=>{
            if(co.OpportunityLineItemID===null || !("OpportunityLineItemID" in co))
            { 
              valid=false
            }
           
          })
        })
      })
      return valid;
    }

    const updateCS=(role)=>{
      let validate=validateProfile()
      if(!validate)
      {
        toastr.error("Please click on every methodology/market tab and create the cost options and save it before moving to time cost input")
        return;
      }
      let methodologyspectable1=currentCostingProfile?.CountrySpecs.map((country)=>{
        return (country.MethodologySpecs.map((ms)=>{
            return ms.CostingOptions.map((co)=>{
               return {
              methodology:ms.Label,
               Costingoptionlabel:co.label||co.Name,
               costingoptionid:co.id,
               OpportunityLineItemID:co.OpportunityLineItemID.toString(),
               Country:country.CountryName
             }
           })
         })).flat()
      })
        let costing=methodologyspectable1.map((cty)=>{
          let oopId=new Set()
          return cty.map((data)=>{
              if(!oopId.has(data.OpportunityLineItemID)){
                  oopId.add(data.OpportunityLineItemID)
                  return data.costingoptionid
              }
             
            })
           })
          let cos=costing.flat().flat().filter((data)=>{
            return data!=undefined
          })
          console.log(cos,"wbslevel")
  
          // setwbslevel(cos)
          let tempProfile=_.cloneDeep(currentCostingProfile)
          tempProfile?.CountrySpecs?.map((cs) => {
            return cs?.MethodologySpecs.map((ms) => {
               ms.CostingOptions.forEach((co, indexCO) => {
      
                if(currentCostingProfile?.CSInput!==null){
                  if(cos.includes(co.id)||currentCostingProfile?.CSInput[co.OpportunityLineItemID]==='optionlevel'){
                     co["CostingOptionData"]={...co["CostingOptionData"],
                    "Commericalsupport" : "Yes"
                    }
                  }
                  else{
                      co["CostingOptionData"]={...co["CostingOptionData"],
                      "Commericalsupport" : "No"
                      }
                  }
                }
                if(currentCostingProfile?.OpsInput!==null){
                  if(cos.includes(co.id)||currentCostingProfile?.OpsInput[co.OpportunityLineItemID]==='optionlevel'){
                    co["CostingOptionData"]={...co["CostingOptionData"],
                    "operationpm" : "Yes",
                    "operationalpm" : "Yes"
                   }
                 }
                 else{
                   co["CostingOptionData"]={...co["CostingOptionData"],
                   "operationpm" : "No",
                            "operationalpm" : "Yes"
                   }
                 }
                
                }
                })               
              });
      
            })
            dispatch(currentCostingActions.updateProfile(tempProfile));
            dispatch(currentCostingActions.saveCostingProfileDraft(tempProfile,()=>{
              if(role==="CS") history.push("/internalCostCommercial");
              else if(role==="OPS") history.push("/internalCostOperations");
            }));
  
    }

    const updateInputLevel=(role)=>{
      const unique=new Set();
      let Opportunitytable=currentCostingProfile?.ProductTypeData.filter((obj)=>{
        if(!unique.has(obj.OpportunityLineItemID)){
            unique.add(obj.OpportunityLineItemID)
            return true
        }
        return false;
   
      })
      let tempProfile=_.cloneDeep(currentCostingProfile)
      tempProfile?.CountrySpecs?.map((cs) => {
        return cs?.MethodologySpecs.map((ms) => {
          ms.CostingOptions.forEach((co, indexCO) => {
            return co["CostingOptionData"]={...co["CostingOptionData"],
            "Commericalsupport" : "Yes",
            "operationpm" : "Yes",
            "operationalpm" : "Yes"
            }
        
          })                            
        });
      })
      let inputObj={}
      let inputdata=Opportunitytable.map((data)=>{
          inputObj[data.OpportunityLineItemID]="optionlevel"
          return{
            inputObj
          }
      })
  
      tempProfile={
        ...tempProfile,
        CSInput:inputObj,
        OpsInput:inputObj
      }
      dispatch(currentCostingActions.saveCostingProfileDraft(tempProfile,()=>{
        if(role==="CS") history.push("/internalCostCommercial");
        else if(role==="OPS") history.push("/internalCostOperations");
      }));
   
    }

    const moveToOperations = () => {
        // navigate.push('/internalCostOperations');
        // dispatch(
        //   getOnlyProject(row.CostingProfile.Project?.ProjectId)
        // );
        // dispatch(
        //   currentCostingActions.getCosting(
        //     row.CostingProfile.id,            
        //   )
        // );
        // dispatch(
        //   currentCostingActions.getCosting(
        //     row.CostingProfile.id,            
        //   )
        // );
        if(currentCostingProfile?.CountrySpecs[0]?.MethodologySpecs[0]?.CostingOptions[0]?.id !== undefined){
          if(currentCostingProfile?.OpsInput !== null){

            updateCS("OPS")
          }
          else{
            if(project.createdAt < "2024-01-13T00:00:00.000Z" && (!_.includes(pacificMarkets,project.CommissioningCountry)
            ||(_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0")))
            {
              updateInputLevel("OPS")

            }
            else{
              let validate=validateProfile()
              if(!validate)
              {
                toastr.error("Please click on every methodology/market tab and create the cost options and save it before moving to time cost input")
              }
              else
              {
                settimecostViewModal(true)
              }
            }
          }
        }
        else{
          toastr.error("Please save RFQ details to proceed")
        }
        dispatch(timeCostActions.handleRoleSelection('operations'))
    };

    const keysToRemove = [
      "qualityControlComments",
      "platformComments",
      "overallOperationResourcesComments"
    ];

    const checkTimecostValidation = (row) => {
      if(row.ParentId!==null)
      {
        return true;
      }
      let validMethIds = [];
      let isNew = [];
      let isValid = false;    
      const summedCost = {};
      let allValid = [];

      currentCostingProfile.CountrySpecs?.map((cs) => {
        cs.MethodologySpecs?.filter((ms) => !ms.NotApplicable &&  ms?.SetupSchema?.properties &&  !Object.values(ms?.SetupSchema?.properties)?.some(
          (property) => property?.sectionTitle?.toLowerCase() === "operations resources"
        ))
          ?.map((ms) => ms.id)
          ?.map((ms) => {            
            if (!_.includes(validMethIds, ms)) validMethIds.push(ms);            
          });
      });

      currentCostingProfile.CountrySpecs?.map((cs) => {
        cs.MethodologySpecs?.filter((ms) => !ms.NotApplicable)
        ?.map((ms) => {
          cs.MethodologySpecs?.map(ms => {
            if (
              ms?.SetupSchema?.properties && !Object.values(ms?.SetupSchema?.properties)?.some(
               (property) => property?.sectionTitle?.toLowerCase() === "operations resources"
             )
           ) {
            if (!isNew.includes(ms.id))
             isNew.push(ms.id);
           }
         })
          ms.CostingOptions.forEach(({OpportunityLineItemID, TotalOPSTimeCost}) => {
            if(summedCost[OpportunityLineItemID]) {
              summedCost[OpportunityLineItemID] += TotalOPSTimeCost;
            } else {
              summedCost[OpportunityLineItemID] = TotalOPSTimeCost;
            }
          })
          // if(ms.SetupSchema?.properties && Object.keys(ms.SetupSchema?.properties).some(key => key.toLowerCase().includes('vendor'))) {          
          //   isNew = true;
          // }
          // if(new Date(currentCostingProfile?.createdAt) > new Date('2025-02-14')) {
          //   isNew = true;
          // }
        })
      });

      const validationResult = Object.keys(summedCost).map(id => {
        const totalCost = summedCost[id];

        const reasonExists = totalCost === 0 
          ? currentCostingProfile.OpsTimecostReason && 
            currentCostingProfile.OpsTimecostReason[id] !== '' &&
            currentCostingProfile.OpsTimecostReason[id] !== undefined &&
            currentCostingProfile.OpsTimecostReason[id] !== null 
          : true; 

        return {
          OpportunityLineItemID: id,
          TotalOPSTimeCost: totalCost,
          isValid: reasonExists
        }
      });

      if(isNew.length > 0) {
        let wavespecs = [
          ...currentCostingProfile.WaveSpecs?.filter((ws) =>
            _.includes(validMethIds, ws.MethodologySpecId)
          ),
        ];
        wavespecs?.map(ws => {
          if(ws.OpsTimecostSchema !== null) {
            const enabledFields = Object.entries(ws.OpsTimecostSchema?.properties)
            ?.filter(([key, item]) =>  item.disabled !== true && item.title !== "Data Processing Complexity")
            ?.map(([key]) => key);
            const uniqueKeys = _.uniq(enabledFields);
            const allKeysToCheck = uniqueKeys?.filter(ak => !keysToRemove.includes(ak));
            let allKeysExist;
            if (isNew.includes(ws.MethodologySpecId)) {
               allKeysExist = allKeysToCheck?.every(key => ws.OpsResourcesData?.hasOwnProperty(key));
            }
            isValid = allKeysExist && !(validationResult.some(item => !item.isValid));
            if(isValid && !allValid.includes(ws.MethodologySpecId)) {
              allValid.push(ws.MethodologySpecId);
            }
          }
        });
        isValid = validMethIds?.every(value => allValid.includes(value));
      } else {
        // let wavespecs = [
        //   ...currentCostingProfile.WaveSpecs?.filter((ws) =>
        //     _.includes(validMethIds, ws.MethodologySpecId)
        //   ),
        // ];
        // wavespecs?.map(ws => {
        //   if(ws.OpsTimecostSchema !== null) {
        //     const enabledFields = Object.entries(ws.OpsTimecostSchema?.properties)
        //     ?.filter(([key, item]) =>  item.disabled !== true && item.title !== "Data Processing Complexity")
        //     ?.map(([key]) => key);
        //     const allKeysToCheck = _.uniq(enabledFields);
        //     const allKeysExist = allKeysToCheck?.every(key => ws.OpsResourcesData?.hasOwnProperty(key));
        //     isValid = allKeysExist && !(validationResult.some(item => !item.isValid));
        //     if(isValid && !allValid.includes(ws.MethodologySpecId)) {
        //       allValid.push(ws.MethodologySpecId);
        //     }
        //   }
        // });
        isValid = !(validationResult.some(item => !item.isValid));
      }
      // } else {
      //   isValid = true;
      // }
      return isValid;
    };

    const updateModal=()=>{
      settimecostViewModal(!timecostViewModal)
    }
    
    return (
      <>
        <Col
          lg="8"
          md="8"
          sm="12"
          xs="12"
          style={{ borderRight: "1px solid #cccccc" }}
        >
          <Row className="req-left-section">
            <Col>
              <Row className="mt-2 mb-2">
                <Col>
                  <h5>Request Details</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="proj-detail">
                    <label className="font-weight-bold">
                      Commissioning Country
                    </label>
                    <span className="ml-3">
                      {getLabel(
                        "FieldingCountriesOptions",
                        row.CostingProfile.Project?.CommissioningCountry
                      )}
                    </span>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="proj-detail">
                    <label className="font-weight-bold">
                      Commissioning Office
                    </label>
                    <span className="ml-3">
                      {getLabel(
                        "OfficeOptions",
                        row.CostingProfile.Project?.CommissioningOffice
                      )}
                    </span>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="proj-detail">
                    <label className="font-weight-bold">Business Unit</label>
                    <span className="ml-3">
                      {getLabel(
                        "BusinessUnitOptions",
                        row.CostingProfile.Project?.BusinessUnit
                      )}
                    </span>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="proj-detail">
                    <label className="font-weight-bold">
                      Industry Vertical
                    </label>
                    <span className="ml-3">
                      {getLabel(
                        "VerticalOptions",
                        row.CostingProfile.Project?.IndustryVertical
                      )}
                    </span>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="proj-detail">
                    <label className="font-weight-bold">
                      Associated Methodology
                    </label>
                    <span className="ml-3">
                      <ul style={{ paddingInlineStart: "0" }}>
                      {row.Methodology
                          ? row.Methodology.split(",").map((method, index) => {
                              return (
                                <li key={index} style={{ listStyle: "none" ,whiteSpace: "pre-line" }}>
                                   {method.startsWith('FM')?
                                  getRelatedSubMeth(method,row.CostingProfile.SubMethodology.split(',')): getLabel("SubMethodologyOptions", method) }
                                </li>
                              );
                            })
                          : null}
                      </ul>
                    </span>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="proj-detail">
                    <label className="font-weight-bold">
                      Response Requested By
                    </label>
                    <span className="ml-3">
                      {row.DateDue
                        ? _.head(row.DateDue.split("T"))
                        : "Not Provided"}
                    </span>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="proj-detail">
                    <label className="font-weight-bold mr-3">
                      Primary CS Contact
                    </label>
                    <span className="capitalize">
                      {getNameFromMail(
                        row.CostingProfile.Project?.ProposalOwnerEmail.value
                      )}
                    </span>
                  </span>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row className="mt-2 mb-2">
                <Col>
                  <h5>Inital Request Notes</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <textarea
                    rows="9"
                    className="form-control"
                    disabled
                    value={row.InitialNotes}
                  ></textarea>
                </Col>
              </Row>
            </Col>
          </Row>
          {row?.ChildRequests.length !== 0 ? (
            <BootstrapTable
              classes="table-responsivemcp"
              keyField="id"
              bootstrap4
              hover
              data = {updateFcstatus(row.ChildRequests)}
              columns={columns}
            />
          ) : null}
          <hr
            className="mb-4 mt-0"
            style={{
              borderTop: "1px solid #cccccc",
            }}
          />
          <div style={{ display: "grid", gap: "10px" }}>
            <Row>
                <Col>
              <div className="d-flex justify-content-center">

                    <Button
                     
                     className="btn-secondary mr-2"
                     onClick={() => {                      
                      moveToOperations()         
                     }}
                    //  disabled={disabled?disabled:priceChange?priceChange:(row.IsClosed !== null)}
                    >
                     Operations Input
                    </Button>
                  
                    <Button
                     
                      className="btn-secondary mr-2"
                      onClick={() => {
                        gaEventTracker(EventName.AssignToUser);
                        initiateAssignUser(row);
          
                      }}
                      disabled={disabled?disabled:priceChange?priceChange:(row.IsClosed !== null)}
                    >
                      Accept & Assign
                    </Button>
                  
                    <Button
                      className="btn-secondary mr-2"
                      disabled={(app.recordloading || row.AssignedTo===null || disabled || priceChange|| closeRequestDisableBtn(row)) && (row.IsClosed === null || row.IsClosed) ? true : false}
                      onClick={() => {
                        gaEventTracker(EventName.CloseRequest);
                        setCurrentRequest(row);
                        if(checkTimecostValidation(row)) {
                          setRequestClosingModal(true);
                        } else {
                          setTimecostValidationModal(true);
                        }
                        
                      }}
                    >
                      Submit
                    </Button>
                  
                  </div>
                </Col>
              
            </Row>
            <Row>
              <Col>
                <div className="d-flex justify-content-center">
                  <Button
                    className="btn-secondary mr-2"
                    disabled={app.recordloading}
                    onClick={() => {
                      gaEventTracker(EventName.ViewCostingProfileSummary);
                      dispatch(
                        getProject(row.CostingProfile.Project?.ProjectId)
                      );
                      dispatch(
                        getSummaryCosting(row.CostingProfile, () =>
                          history.push(`/summary/${row.CostingProfile.id}`),
                          row?.CostingProfile?.IsOPSOOPAdjusted ? false : true
                        )
                      );
                    }}
                  >
                    View Costing Profile Summary
                  </Button>
                  <Button
                    className="btn-secondary mr-2"
                    disabled={app.recordloading || priceChange}
                    onClick={() => {
                      gaEventTracker(EventName.OpenCostingFolder);
                      // window.open(
                      //   "https://drive.google.com/drive/folders/" +
                      //   row.CostingProfile.Project.CostingsFolderId
                      // );
                      dispatch(sheetCall.msSheetAPiCall(
                        row.CostingProfile.Project.CostingsFolderId,
                        false
                      ));
                    }}
                  >
                    Open Costing Folder
                  </Button>
                  
                  {/* {row.RequestType !== "Approval" && row.IsClosed ? (
                    <Button
                      className="btn-secondary mr-2"
                      onClick={() => {
                        gaEventTracker(EventName.ReOpenRequest);
                        setCurrentRequest(row);
                        setRequestOpeningModal(true);
                      }}
                      disabled={app.recordloading}
                    >
                      Re-Open Request
                    </Button>
                  ) : null} */}
                  <Button
                    className="btn-secondary"
                    onClick={() => {
                      gaEventTracker(EventName.SendEmailNotification);
                      dispatch(mailRequest(row.id, "update"));
                    }}
                    disabled={
                      !row.AgentEmail || row.IsClosed || app.recordloading|| priceChange
                    }
                  >
                    Send Email Notification
                  </Button>
                  {row.CostingProfile.isMultiCountry > 0 &&
                  row.ParentId === null && row.RequestType !=="Approval" ? (
                    <Button
                      style={{ marginLeft: "10px" }}
                      className="btn-secondary"
                      onClick={() => {
                        setCurrentRequest(row);
                        dispatch(
                          getFCSpoc(fieldingCountries, () => {
                            setFCSpocCountryList(row.ChildRequests);
                            setFcSpocOpeningModal(true);
                            setSelectAllChecked(true);
                          })
                        );
                      }}
                      disabled={ disablebtn(row) || priceChange || row.IsClosed}
                    >
                      Assign FC Spoc
                    </Button>
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <TimeCostModal isOpen={timecostViewModal} isClose={updateModal}/>
      </>
    );
  };
  const chatBox = (row) => {
    return (
      <Col lg="4" md="4" sm="12" xs="12" className="d-flex flex-column pl-0">
        <div
          className="p-2 chat-messages"
          style={{
            overflowY: "auto",
            borderRight: "1px solid #cccccc",
          }}
        >
          {row.RequestLogs?.length ? (
            row.RequestLogs?.map((chat) => {
              return (
                <>
                  <div
                    className={`pb-4 ${
                      chat.CommentOwnerEmail == userRecord.Email
                        ? "chat-message-right"
                        : "chat-message-left"
                    } `}
                  >
                    <div
                      className={`flex-shrink-1 rounded py-2 px-3 mr-3 ${
                        chat.IsNotification ? "bg-light" : "bg-tertiary"
                      } `}
                    >
                      {!chat.IsNotification ? (
                        <div
                          className={`font-weight-bold mb-1 ${
                            chat.CommentOwnerEmail == userRecord.Email
                              ? "text-right"
                              : "text-left"
                          } `}
                        >
                          {chat.CommentOwnerEmail.toLowerCase()
                            .split("@")[0]
                            .split(".")
                            .map((word) => {
                              return word.replace(
                                word[0],
                                word[0].toUpperCase()
                              );
                            })
                            .join(" ")}
                        </div>
                      ) : (
                        <div
                          className={`font-weight-bold mb-1 ${
                            chat.CommentOwnerEmail == userRecord.Email
                              ? "text-right"
                              : "text-left"
                          } `}
                        >
                          <FontAwesomeIcon
                            className="not-allowed"
                            icon={faBell}
                          />
                          <FontAwesomeIcon
                            className="not-allowed"
                            icon={faCheck}
                          />
                        </div>
                      )}
                      <div>{chat.Comment}</div>
                      <div
                        className={`small text-nowrap mt-2 ${
                          chat.CommentOwnerEmail == userRecord.Email
                            ? "text-right"
                            : "text-left"
                        } `}
                      >
                        {chat.createdAt
                          ? moment(chat.createdAt).format("llll")
                          : "Just now"}
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <div className="text-center my-auto">
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="text-muted mb-1"
                size="lg"
              />

              <h5 className="text-muted">No Additional Notes Available</h5>
            </div>
          )}
        </div>
        <div
          className="new-chat d-flex"
          style={{
            overflowY: "auto",
            borderTop: "1px solid #cccccc",
          }}
        >
          <textarea
            className="border-0"
            disabled={row.IsClosed}
            placeholder={
              row.IsClosed
                ? "Adding new notes to closed requests is not permitted..."
                : "Enter any new request related notes here..."
            }
            onChange={(e) => updateComment(e.target.value)}
            ref={(ref) => (comment = ref)}
          ></textarea>
          <div className="send-btn-container">
            <Button
              disabled={row.IsClosed}
              onClick={() => {
                //todo: optimize
                postSelfComment(row.id);
                comment.value = "";
                setTimeout(() => {
                  updateComment("");
                });
              }}
              className={
                app.recordloading || !currentComment ? "no-actions" : "pointer"
              }
            >
              <FontAwesomeIcon className="pointer" icon={faSave} />
            </Button>
          </div>
        </div>
      </Col>
    );
  };

const checkPriceChange =(row)=>
{
  if(row?.CostingProfile?.Project?.PriceChange >2){
    setPriceChanged(true)
  }
}

  const checkDisabled = (row) =>{
    setDisabled(false);

    if(row){
      if(row.AgentEmail?.split(',').map(x=>x.toLowerCase()).includes(userEmail.toLowerCase()) || row.AssignedTo?.split(',').map(x=>x.toLowerCase()).includes(userEmail.toLowerCase())){
        setDisabled(false);
      }else{
        setDisabled(true);
      }
      
      if(row.RejectedBy?.split(',').map(x=>x?.toLowerCase()).includes(userEmail?.toLowerCase())){
        setDisabled(true);
      }
    
      if(row.AcceptedBy) {
        setDisabled(true)
      }
      
    }
  }


  const setFieldingCountryVal = (row)=>{
    let val = [];
    setFieldingCountries(val);
      val = row?.CostingProfile?.FieldingCountries?.split(',').filter((item)=>
      item !== row.CostingProfile.Project.CommissioningCountry
      )
      setFieldingCountries(val);
  }
  const expandRow = {
    renderer: (row) => (
      <div className="mb-0">
        <Row>
          {projectDetails({
            ...row
          })}

          {chatBox(row)}
        </Row>
      </div>
    ),

    expanded: [currrentRowId],
    showExpandColumn: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) =>
      isAnyExpands ? (
        <MinusCircle width={16} height={16} />
      ) : (
        <PlusCircle width={16} height={16} />
      ),
    expandColumnRenderer: ({ expanded }) =>
      expanded ? (
        <MinusCircle width={16} height={16} />
      ) : (
        <PlusCircle width={16} height={16} />
      ),

    onExpand: (row, isExpand) => {
      setCurrentRequest(row);
      checkDisabled(row);
      checkPriceChange(row)
      setFieldingCountryVal(row);
      
      setTimeout(() => {
        // isExpand ? dispatch(expandrow(row.id)) : dispatch(expandrow([]));
        if(isExpand && !expandedRows[row.id]) {
          dispatch(
            currentCostingActions.getCosting(
              row.CostingProfile.id,            
            )
          );
          dispatch(
            getProject(row.CostingProfile.Project?.ProjectId)
          );
          dispatch(expandrow(row.id))
          setExpandedRows({
            ...expandedRows,
            [row.id]: true,
          })        
        } else {
          dispatch(expandrow([]));
        }
      });
    },
  };

  return (
    filteredData?<Card  className="h-100">
      <CardBody className="p-4">
        <BootstrapTable
          classes="sticky-top-table"
          defaultSorted={[{ dataField: "createdAt", order: "desc" }]}
          bootstrap4
          hover
          striped
          bordered={false}
          keyField="id"
          data={filteredData}
          columns={tableColumns}
          expandRow={expandRow}
          noDataIndication={<b>No data available</b>}
        />
      </CardBody>
      <Modal
        isOpen={isCloseRequestModal}
        toggle={() => setRequestClosingModal(false)}
        size="sm"
        centered={true}
        backdrop={"static"}
      >
        <ModalHeader toggle={() => setRequestClosingModal(false)}>
          {/* <img src={logo} className="modallogo" /> */}
        </ModalHeader>
          <h4>{currentRequest?.CostingProfile.Project?.ProjectName}</h4>
          <h5>
            {"#"}
            {currentRequest?.CostingProfile?.ProfileNumber}{" "}
            {currentRequest?.CostingProfile?.ProfileName}
          </h5>
        <ModalBody>
          <p>
            This will notify the Request Creator that the request has
            been closed.
            <br />
            You will have to re-open this request to make any further changes.
          </p>
          <p>
            <h5>
              Are you sure you want to close Request #{currentRequest?.id}?
            </h5>
          </p>
        </ModalBody>
        <ModalFooter>
        <Button
            color="primary"
            disabled={app.recordloading}
            onClick={() =>
              dispatch(
                updateRequest(
                  {
                    ...currentRequest,
                    IsClosed: true,
                    DateClosed: new Date(),
                  },
                  () => {
                    dispatch(mailRequest(currentRequest.id, "close"));
                    dispatch({
                      type: "HANDLE_REQUESTS",
                      key: "FilterAllRequests",
                      value: true,
                  });
                    dispatch(setRequests(props.currentFilter));
                    setRequestClosingModal(false);
                  }
                )
              )
            }
          >
            Confirm
          </Button>
          <Button
            color="secondary"
            disabled={app.recordloading}
            onClick={() => setRequestClosingModal(false)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={timecostValidationModal}
        toggle={() => setTimecostValidationModal(false)}
        size="lg"
        centered={true}
        backdrop={"static"}
      >
        <ModalHeader toggle={() => setTimecostValidationModal(false)}>
          {/* <img src={logo} className="modallogo" /> */}
        </ModalHeader>          
        <ModalBody>
          <p>
            <h3>
              To Submit the cost, please ensure you have completed the below necessary input
            </h3>
          </p>
          <p>
            1. Fill Operations Resources
            <br />
          </p>
          <p>
          2. For each costing Option, associate the internal time cost or provide a reason if it is zero or less than cost calculated from predefined hours by {currentCostingProfile?.ThresholdTimecost * 100}%.
          </p>
        </ModalBody>
        <ModalFooter>        
          {/* <Button
            color="secondary"
            disabled={app.recordloading}
            onClick={() => setTimecostValidationModal(false)}
          >
            Cancel
          </Button> */}
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={isOpenRequestModal}
        toggle={() => setRequestOpeningModal(false)}
        size="sm"
        centered={true}
        backdrop={"static"}
      >
        <ModalHeader toggle={() => setRequestOpeningModal(false)}>
          <h4>{currentRequest?.CostingProfile.Project?.ProjectName}</h4>
          <h5>
            {"#"}
            {currentRequest?.CostingProfile?.ProfileNumber}{" "}
            {currentRequest?.CostingProfile?.ProfileName}
          </h5>
        </ModalHeader>
        <ModalBody>
          <p>
            This action <strong>will</strong> automatically notify the Request
            Assignee that the request has been re-opened.
          </p>
          {/* <p>
            Once the request is re-opened, you can add new notes/comments and
            then use "Send Email Notification" button to notify the Request
            Assignee.
          </p> */}
          <p>
            <h5>
              Are you sure you want to re-open the Request #{currentRequest?.id}
              ?
            </h5>
          </p>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex justify-content-between">
            <Button
              className="form-control"
              color="secondary"
              disabled={app.recordloading}
              onClick={() => setRequestOpeningModal(false)}
            >
              Cancel
            </Button>
            <Button
              className="form-control ml-2"
              color="primary"
              disabled={app.recordloading}
              onClick={() =>
                dispatch(
                  updateRequest(
                    {
                      ...currentRequest,
                      IsClosed: false,
                      DateClosed: null,
                    },
                    () => {
                      dispatch(mailRequest(currentRequest.id, "reopen"));
                      setRequestOpeningModal(false);
                    }
                  )
                )
              }
            >
              Confirm
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={fcSpocOpeningModal}
        toggle={() => setFcSpocOpeningModal(false)}
        size="lg"
        centered={true}
        backdrop={"static"}
      >
        <ModalHeader toggle={() => setFcSpocOpeningModal(false)}>
        {/* <img src={logo} className="modallogo" /> */}
        </ModalHeader>
        <h4>
            {"FC SPOC Assignment for project ("}
            {currentRequest?.CostingProfile.Project?.ProjectName}
            {")"}
          </h4>
          <h5>
            {"#"}
            {currentRequest?.CostingProfile?.ProfileNumber}{" "}
            {currentRequest?.CostingProfile?.ProfileName}
          </h5>
        <ModalBody>
          <Container>
            <p>
              Please confirm / add / remove FC's and their respective SPOCS to
              assign this request to
            </p>
            <Row className="rowBorder">
              <Col style={{ "flex-grow": "0.2" }}>
                <input
                  type="checkbox"
                  checked={selectAllChecked}
                  onChange={(val) => {
                    setSelectAllChecked(!selectAllChecked);
                    countryWiseUsers.map((x) => {
                      x.checked = !selectAllChecked;
                    });
                  }}
                />
              </Col>
              <Col>
                <h6>For Market</h6>
              </Col>

              <Col>
                <h6>FC Spoc</h6>
              </Col>
            </Row>
            <Row>
              <Col></Col>
            </Row>
            <Form>
              {countryWiseUsers.map((obj) => {
                rows.push(
                  <Row className="rowBorder" style={{ "margin-top": "10px" }}>
                    <Col>
                      <FormGroup>
                        <input
                          style={{ "margin-top": "10px" }}
                          type="checkbox"
                          checked={obj.checked}
                          onChange={(val) => {
                            changeHandler({
                              target: {
                                type: "CheckBox",
                                id: obj.CountryCode,
                                value: !obj.checked,
                              },
                            });
                          }}
                        />
                        <span>{"    "}</span>
                        <Label
                          style={{ "margin-left": "90px", "margin-top": "2px" }}
                        >
                          {" "}
                          {obj.FC}{" "}
                        </Label>
                      </FormGroup>
                    </Col>

                    <Col>
                      <CreatableSelect
                        // className={`mb-0 ${!obj.valid ? "fcspocerror" : ""}`}
                        isMulti
                        value={
                          obj.intialValue
                            ? obj.intialValue.split(",").map((val) => {
                                return { label: val, value: val };
                              })
                            : null
                        }
                        onChange={(val) => {
                          changeHandler({
                            target: {
                              type: "SpocList",
                              id: obj.CountryCode,
                              value: val
                                ? val.map((v) => v.value).join()
                                : null,
                            },
                          });
                        }}
                        options={obj.users}
                      />
                    </Col>
                  </Row>
                );
              })}
              {rows}
            </Form>
          </Container>
        </ModalBody>
        <ModalFooter>
            <Button
              color="primary"
              //disabled={app.recordloading}
              onClick={() => {
                let validation = validationCheck();
                if (validation.atleast1checked && validation.valuesSelected) {
                  let fcSpocList = [];
                  countryWiseUsers.forEach((x) => {
                    if (x.checked) {
                      fcSpocList.push({
                        CountryCode: x.CountryCode,
                        FcSpocs: x.intialValue,
                      });
                    }
                  });
                  const getJson = () => {
                    return {
                      ParentId: currentRequest.id,
                      FcSpocsList: fcSpocList,
                    };
                  };
                  dispatch(
                    updateFcSpoc(getJson(), () => {
                      dispatch(expandrow([currentRequest.id]));

                      dispatch(setRequests(props.currentFilter));
                      setFcSpocOpeningModal(false);
                    })
                  );
                } else {
                  setValid(false);
                  if (validation.atleast1checked) {
                    if (!validation.valuesSelected) {
                      toastr.error(
                        "",
                        "Please enter atleast 2 FC SPOC's for the selected countries"
                      );
                    }
                  } else {
                    toastr.error(
                      "",
                      "Please select atleast 1 fielding country"
                    );
                  }
                }
              }}
            >
              Create Request
            </Button>
            <Button
              color="secondary"
              disabled={app.recordloading}
              onClick={() => setFcSpocOpeningModal(false)}
            >
              Cancel
            </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={rejectResonModal}
        toggle={() => setRejectReasonModal(false)}
        size="sm"
        centered={true}
        backdrop={"static"}
      >
        <ModalHeader toggle={() => setRejectReasonModal(false)}>
        <h4>On-Hold Reason{"(*)"}</h4>
        </ModalHeader>
        <ModalBody>
        <Form>
            <FormGroup >
              <Input
                type="text"
                placeholder="Type Here for On-Hold Reason"
                maxlength="1000"
                autoFocus
                onChange={(event)=>setRejectValue(event.target.value)}
              />
              </FormGroup>
              </Form>

          
        </ModalBody>
        <ModalFooter>
          <div className="d-flex justify-content-between">
            <Button
              className="form-control"
              color="secondary"
              disabled={app.recordloading}
              onClick={() => setRejectReasonModal(false)}
            >
              Close
            </Button>
            <Button
              className="form-control ml-2"
              color="primary"
              style = {{width: "300px"}}
              disabled={!rejectValue}
              onClick={() => {
              const getjson = () => {
                          return {
                            id: currrentRowId,
                            rejectReason: rejectValue,
                            RejectedBy: currentRequest.RejectedBy?currentRequest.RejectedBy.concat(",",userEmail):userEmail,
                          };
                        };
                        dispatch(
                          RejectFcSpoc(getjson(), () => {
                            dispatch(setRequests(props.currentFilter));
                          })
                        );
                        setRejectReasonModal(false);
                        gaEventTracker(EventName.ReOpenRequest);
              }}
            >
              On-Hold
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={fcSpocEditEmail}
        toggle={() => setFcSpocEditEmail(false)}
        size="sm"
        centered={true}
        backdrop={"static"}
      >
        <ModalHeader toggle={() => setFcSpocEditEmail(false)}>
        {/* <img src={logo} className="modallogo" /> */}
        </ModalHeader>
        <h4>Edit FC-SPOC Email</h4>
        <ModalBody>
          <CreatableSelect
            // name="costingDirect"
            className="react-select-container"
            // classNamePrefix="react-select"
            isMulti
            options={
              getAllUserOptions(currentRequest)
            }
            defaultValue={getDefaultValue(selctemailData?.AgentEmail)}
            onChange={(select) =>{
             
              setSelectemailData({
                ...selctemailData,
                AgentEmail: select ? select.map((s) => s.value).join() : "",
              });
           
            }
            }
            // onChange={(val) => handleOnchange(val)}
          />
        </ModalBody>
        <ModalFooter>
          <div className="d-flex justify-content-between">
            <Button
              className="form-control"
              color="secondary"
              disabled={app.recordloading}
              onClick={() => setFcSpocEditEmail(false)}
            >
              Close
            </Button>
            <Button
              className="form-control ml-2"
              color="primary"
              disabled={app.recordloading}
              onClick={() => {
                if(selctemailData?.AgentEmail?.split(',').length<2){
                  toastr.error(
                    "",
                    "Please select atleast 2 FC Spoc's"
                  );
                }else{
                const json = () => {
                  return {
                    id: selctemailData.id,
                    FcSpocs:selctemailData.AgentEmail,
                    ParentId:selctemailData.ParentId
                  };
                };

                dispatch(
                  updateFcSpocEmail(json(), () => {
                    dispatch(setRequests(props.currentFilter));
                  })
                );
                setFcSpocEditEmail(false);
              }}}
            >
              Update
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={isAssignUserModal}
        toggle={() => setAssignUserModal(false)}
        size="sm"
        centered={true}
        backdrop={"static"}
      >
        <ModalHeader toggle={() => setAssignUserModal(false)}>
        {/* <img src={logo} className="modallogo" /> */}
        </ModalHeader>
        <h4>{currentRequest?.CostingProfile.Project?.ProjectName}</h4>
          <h5>
            {"#"}
            {currentRequest?.CostingProfile?.ProfileNumber}{" "}
            {currentRequest?.CostingProfile?.ProfileName}
          </h5>
        <ModalBody>
          <p>Please accept this request or select a user to assign this Request to:</p>
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            isMulti
            options={getAllUserOptions(currentRequest)}
            defaultValue={
              allusers && currentRequest?.AssignedTo? getDefaultValue(currentRequest.AssignedTo) : getDefaultValue(userEmail)
            }
            onChange={(select) =>
              setCurrentRequest({
                ...currentRequest,
                AssignedTo: select?.length? select.map((s) => s.value).join() : null,
              })
            }
          />
        </ModalBody>
        <ModalFooter>
            <Button
              color="primary"
              disabled={app.recordloading || currentRequest?.AssignedTo == null}
              onClick={() =>{
                const getjson = {
                  AcceptedBy: userEmail,
                  AssignedTo : currentRequest.AssignedTo,
                  IsClosed: false
                };
                dispatch(AccepectFcSpoc(currentRequest.id,getjson, () => {
                        dispatch({
                          type: "HANDLE_REQUESTS",
                          key: "FilterAllRequests",
                          value: true,
                        });
                        dispatch(setRequests(props.currentFilter));
                        setAssignUserModal(false)}));
                
              }
              }
            >
              Confirm
            </Button>
            <Button
              color="secondary"
              disabled={app.recordloading}
              onClick={() => setAssignUserModal(false)}
            >
              Cancel
            </Button>
        </ModalFooter>
      </Modal>
    </Card>:null
  );
};

const Tables = (props) => {
  const localPageload = useSelector(({ app }) => app.localPageload);
  const recordloading = useSelector(({ app }) => app.recordloading);
  return (
    <Container fluid className="p-0 requests-board-table h-100">
       {localPageload || recordloading ? (
      <div id="pageCoverSpin"></div>
    ) : null}
      <ExpandableRowsTable {...props} />
    </Container>
  );
};

export default Tables;