import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Row,
  Label,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import React from "react";
import "./UserDetailModal.css";
import MultiSelect from "../../../components/ReactstrapMultiSelect/MultiSelect";
import * as manageUserActions from "../../../redux/actions/manageUserActions";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import Select from "../../../components/Select/Select";
import { toastr } from "react-redux-toastr";
import logo from "../../../assets/img/avatars/modal-logo.png";
import SelectWrapper from "../../../components/SelectWrapper/SelectWrapper";
var imgStyle = {
  width: "150px",
};
const UserDetailModal = ({ show, toggle }) => {
  const dispatch = useDispatch();
  const users = useSelector(({ manageUsers }) => manageUsers.users);
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const selectedUser = useSelector(
    ({ manageUsers }) => manageUsers.selectedUser
  );

  // state for disabling editing
  const [userData, setUserData] = useState();
  const [editDisabled, setEditDisabled] = useState(false);

  const onChange = (value) => {
    setUserData({
      ...userData,
      ...value,
    });
    // dispatch(manageUserActions.updateSelectedUser(rowData.Email, value));
  };

  const onSubmit = () => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(String(userData.Email).toLowerCase())) {
      toggle();

      dispatch(manageUserActions.postUser(userData));
    } else {
      toastr.error(
        "User Creation Failed",
        "Email address not correctly formated!"
      );
    }
  };

  //Handelling

  return (
    <Modal
      isOpen={show}
      toggle={toggle}
      centered={true}
      size="lg"
      className="UserDetails"
    >
      <ModalHeader toggle={toggle}>
        {/* <img src={logo} className="modallogo" /> */}
      </ModalHeader>
      <div>
        <span className="modaltitle modaluser">User Details</span>
      </div>
      <ModalBody style={{ overflow: "auto" }}>
        <Container>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle>Email Address</CardTitle>
                  <Input
                    className="mb-2"
                    onChange={(e) =>
                      onChange({
                        Email: e.target.value,
                      })
                    }
                    placeholder="Enter Email Address..."
                    disabled={editDisabled}
                    // value={}
                  />
                  <Row>
                    <Col>
                      <CardTitle>First Name</CardTitle>
                      <Input
                        className="mb-2"
                        onChange={(e) =>
                          onChange({
                            FirstName: e.target.value,
                          })
                        }
                        disabled={editDisabled}
                        // value={}
                      />
                    </Col>
                    <Col>
                      <CardTitle>Last Name</CardTitle>
                      <Input
                        className="mb-2"
                        onChange={(e) =>
                          onChange({
                            LastName: e.target.value,
                          })
                        }
                        disabled={editDisabled}
                        // value={}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CardTitle>Default Language</CardTitle>
                      <SelectWrapper
                        type="select"
                        id="defaultLanguage"
                        name="defaultLanguage"
                        className="mb-2"
                        disabled={editDisabled}
                        onChange={(val) =>
                          onChange({
                            Language: val ? val.target.value : null,
                          })
                        }
                      >
                        {codeLabels.UserLanguageOptions.map((opt, index) => {
                          return (
                            <option key={index} id={opt.id} value={opt.Code}>
                              {opt.Label}
                            </option>
                          );
                        })}
                      </SelectWrapper>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="p-1">
                <CardHeader className="pb-0">
                  <CardTitle>Account Status</CardTitle>
                </CardHeader>
                <CardBody className="pt-0">
                  <Col>
                    <Row>
                      <Col>
                        <Label>
                          <Input
                            type="checkbox"
                            className="mb-2"
                            disabled={editDisabled}
                            onChange={(e) =>
                              onChange({
                                IsDisabled: e.target.checked,
                              })
                            }
                          />
                          Account Disabled
                        </Label>
                      </Col>
                    </Row>
                  </Col>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <Select
                  header="Countries"
                  options={codeLabels.CommissioningCountriesOptions}
                  isMulti
                  isSearchable
                  onChange={(val) =>
                    onChange({
                      Countries: val ? val.join(",") : null,
                    })
                  }
                />
              </Card>
              <Card>
                <MultiSelect
                  header={"Business Unit"}
                  options={codeLabels.BusinessUnitOptions}
                  disabled={editDisabled}
                  onChangeResult={(val) =>
                    onChange({
                      BusinessUnits: val ? val.join(",") : null,
                    })
                  }
                />
              </Card>
              <Card>
                <MultiSelect
                  header={"Verticals"}
                  options={codeLabels.VerticalOptions}
                  onChangeResult={(val) =>
                    onChange({
                      Verticals: val ? val.join(",") : null,
                    })
                  }
                  disabled={editDisabled}
                />
              </Card>
            </Col>
            <Col>
              <Card className="p-1">
                <CardHeader className="pb-0">
                  <CardTitle>User Roles</CardTitle>
                </CardHeader>
                <CardBody className="pt-0">
                  <Col>
                    <Row>
                      <Col>
                        <Label>
                          <Input
                            type="checkbox"
                            disabled={editDisabled}
                            checked={userData?.Sales}
                            onChange={(e) =>
                              onChange({
                                Sales: e.target.checked,
                              })
                            }
                          />
                          Sales
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>
                          <Input
                            type="checkbox"
                            disabled={editDisabled}
                            checked={userData?.ProjectOwnerCS}
                            onChange={(e) =>
                              onChange({
                                ProjectOwnerCS: e.target.checked,
                              })
                            }
                          />
                          Project owner (CS)
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>
                          <Input
                            type="checkbox"
                            disabled={editDisabled}
                            checked={userData?.PMInCharge}
                            onChange={(e) =>
                              onChange({
                                PMInCharge: e.target.checked,
                              })
                            }
                          />
                          PM in charge
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>
                          <Input
                            type="checkbox"
                            disabled={editDisabled}
                            checked={userData?.BiddingLocal}
                            onChange={(e) =>
                              onChange({
                                BiddingLocal: e.target.checked,
                              })
                            }
                          />
                          Bidding Local
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>
                          <Input
                            type="checkbox"
                            disabled={editDisabled}
                            checked={userData?.BiddingCOE}
                            onChange={(e) =>
                              onChange({
                                BiddingCOE: e.target.checked,
                              })
                            }
                          />
                          Bidding COE
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>
                          <Input
                            type="checkbox"
                            disabled={editDisabled}
                            checked={userData?.OpsDC}
                            onChange={(e) =>
                              onChange({
                                OpsDC: e.target.checked,
                              })
                            }
                          />
                          Ops DC
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>
                          <Input
                            type="checkbox"
                            disabled={editDisabled}
                            checked={userData?.OpsDM}
                            onChange={(e) =>
                              onChange({
                                OpsDM: e.target.checked,
                              })
                            }
                          />
                          Ops DM
                        </Label>
                      </Col>
                    </Row>
                  </Col>
                </CardBody>
              </Card>
              <Card className="p-1">
                <CardHeader className="pb-0">
                  <CardTitle>User Org</CardTitle>
                </CardHeader>
                <CardBody className="pt-0">
                  <Col>
                    <Row>
                      <Col>
                        <Label>
                          <Input
                            type="checkbox"
                            disabled={editDisabled}
                            checked={userData?.IsInternalUser}
                            onChange={(e) =>
                              onChange({
                                IsInternalUser: e.target.checked,
                              })
                            }
                          />
                          NIQ User
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>
                          <Input
                            type="checkbox"
                            disabled={editDisabled}
                            checked={userData?.IsTCSUser}
                            onChange={(e) =>
                              onChange({
                                IsTCSUser: e.target.checked,
                              })
                            }
                          />
                          TCS User
                        </Label>
                      </Col>
                    </Row>
                  </Col>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className="p-1">
                <CardHeader className="pb-0">
                  <CardTitle>Access Settings</CardTitle>
                </CardHeader>
                <CardBody className="pt-0">
                  <Col>
                    <Row>
                      <Col>
                        <Label>
                          <Input
                            type="checkbox"
                            disabled={editDisabled}
                            checked={userData?.InternalDashBoardAccess}
                            onChange={(e) =>
                              onChange({
                                InternalDashBoardAccess: e.target.checked,
                              })
                            }
                          />
                          Internal Dashboard
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>
                          <Input
                            type="checkbox"
                            disabled={editDisabled}
                            checked={userData?.RequestsBoardAccess}
                            onChange={(e) =>
                              onChange({
                                RequestsBoardAccess: e.target.checked,
                              })
                            }
                          />
                          Requests Board
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>
                          <Input
                            type="checkbox"
                            disabled={editDisabled}
                            checked={userData?.DeliveryDashboardAccess}
                            onChange={(e) =>
                              onChange({
                                DeliveryDashboardAccess: e.target.checked,
                              })
                            }
                          />
                          Setup &amp; Delivery
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>
                          <Input
                            type="checkbox"
                            disabled={editDisabled}
                            checked={userData?.FinanceAccess}
                            onChange={(e) =>
                              onChange({
                                FinanceAccess: e.target.checked,
                              })
                            }
                          />
                          Finance Reports
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>
                          <Input
                            type="checkbox"
                            disabled={editDisabled}
                            checked={userData?.ManageMarketAccess}
                            onChange={(e) =>
                              onChange({
                                ManageMarketAccess: e.target.checked,
                              })
                            }
                          />
                          Market Settings
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>
                          <Input
                            type="checkbox"
                            disabled={editDisabled}
                            checked={userData?.ManageUsersAccess}
                            onChange={(e) =>
                              onChange({
                                ManageUsersAccess: e.target.checked,
                              })
                            }
                          />
                          User Management
                        </Label>
                      </Col>
                    </Row>
                    {/* <Row>
											<Col>
												<Label>
													<Input
														type="checkbox"
														disabled={editDisabled}
														onChange={(e) =>
															onChange({
																ProjectReviewAccess: e.target.checked,
															})
														}
													/>
													Access Project Review
												</Label>
											</Col>
										</Row> */}
                    <Row>
                      <Col>
                        <Label>
                          <Input
                            type="checkbox"
                            disabled={editDisabled}
                            checked={userData?.AllActionsAccess}
                            onChange={(e) =>
                              onChange({
                                AllActionsAccess: e.target.checked,
                              })
                            }
                          />
                          Access All Other Actions/ View All Details in
                          Dashboard
                        </Label>
                      </Col>
                    </Row>
                    {/* For Vendor Module Access*/}
                    <Row>
                      <Col>
                        <Label>
                          <Input
                            type="checkbox"
                            disabled={editDisabled}
                            checked={userData?.VendorManagementAccess}
                            onChange={(e) =>
                              onChange({
                                VendorManagementAccess: e.target.checked,
                              })
                            }
                          />
                          Vendor Dashboard
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>
                          <Input
                            type="checkbox"
                            disabled={editDisabled}
                            checked={userData?.VendorOnboardingAccess}
                            onChange={(e) =>
                              onChange({
                                VendorOnboardingAccess: e.target.checked,
                              })
                            }
                          />
                          Allow Vendor Onboarding
                        </Label>
                      </Col>
                    </Row>
                  </Col>
                </CardBody>
              </Card>
              <Card className="p-1">
                <CardHeader className="pb-0">
                  <CardTitle>Special Permissions</CardTitle>
                </CardHeader>
                <CardBody className="pt-0">
                  <Col>
                    <Row>
                      <Col>
                        <Label>
                          <Input
                            type="checkbox"
                            disabled={editDisabled}
                            checked={userData?.IsMarketAdmin}
                            onChange={(e) =>
                              onChange({
                                IsMarketAdmin: e.target.checked,
                              })
                            }
                          />
                          Global Admin
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>
                          <Input
                            type="checkbox"
                            disabled={editDisabled}
                            checked={userData?.LocalAdmin}
                            onChange={(e) =>
                              onChange({
                                LocalAdmin: e.target.checked,
                              })
                            }
                          />
                          Local Admin
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>
                          <Input
                            type="checkbox"
                            disabled={editDisabled}
                            checked={userData?.SystemAdmin}
                            onChange={(e) =>
                              onChange({
                                SystemAdmin: e.target.checked,
                              })
                            }
                          />
                          System Admin
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>
                          <Input
                            type="checkbox"
                            disabled={editDisabled}
                            checked={userData?.IsProgrammer}
                            onChange={(e) =>
                              onChange({
                                IsProgrammer: e.target.checked,
                              })
                            }
                          />
                          Survey Programmer
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>
                          <Input
                            type="checkbox"
                            disabled={editDisabled}
                            checked={userData?.IsProgrammingTeamLeader}
                            onChange={(e) =>
                              onChange({
                                IsProgrammingTeamLeader: e.target.checked,
                              })
                            }
                          />
                          SP Team Lead
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>
                          <Input
                            type="checkbox"
                            disabled={editDisabled}
                            checked={userData?.CanCreateNewProposal}
                            onChange={(e) =>
                              onChange({
                                CanCreateNewProposal: e.target.checked,
                              })
                            }
                          />
                          Create Proposal
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>
                          <Input
                            type="checkbox"
                            disabled={editDisabled}
                            checked={userData?.CanBypassSalesForce}
                            onChange={(e) =>
                              onChange({
                                CanBypassSalesForce: e.target.checked,
                              })
                            }
                          />
                          Bypass SalesForce Input
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>
                          <Input
                            type="checkbox"
                            disabled={editDisabled}
                            checked={userData?.CanDeleteProposal}
                            onChange={(e) =>
                              onChange({
                                CanDeleteProposal: e.target.checked,
                              })
                            }
                          />
                          Can Delete OWN Proposal
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>
                          <Input
                            type="checkbox"
                            disabled={editDisabled}
                            checked={userData?.CanDeleteAnyProposal}
                            onChange={(e) =>
                              onChange({
                                CanDeleteAnyProposal: e.target.checked,
                              })
                            }
                          />
                          Can Delete ANY Proposal
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>
                          <Input
                            type="checkbox"
                            disabled={editDisabled}
                            checked={userData?.CanAddModules}
                            onChange={(e) =>
                              onChange({
                                CanAddModules: e.target.checked,
                              })
                            }
                          />
                          Add Modules
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>
                          <Input
                            type="checkbox"
                            disabled={editDisabled}
                            checked={userData?.CanCreateDuplicateProposal}
                            onChange={(e) =>
                              onChange({
                                CanCreateDuplicateProposal: e.target.checked,
                              })
                            }
                          />
                          Can Create Duplicate Proposal
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>
                          <Input
                            type="checkbox"
                            disabled={editDisabled}
                            checked={userData?.CanBackendCommission}
                            onChange={(e) =>
                              onChange({
                                CanBackendCommission: e.target.checked,
                              })
                            }
                          />
                          Can Exception Commission
                        </Label>
                      </Col>
                    </Row>
                    <Row>
											<Col>
												<Label>
													<Input
														type="checkbox"
														disabled={editDisabled}
                            checked={userData?.CanManageNotification}
														onChange={(e) =>
															onChange({
																CanManageNotification: e.target.checked,
															})
														}
													/>
													Can Manage Notifications
												</Label>
											</Col>
										</Row>
                    <Row>
											<Col>
												<Label>
													<Input
														type="checkbox"
														disabled={editDisabled}
                            checked={userData?.SUDExport}
														onChange={(e) =>
															onChange({
																SUDExport: e.target.checked,
															})
														}
													/>
													Can Export SUD data
												</Label>
											</Col>
										</Row>
                    <Row>
											<Col>
												<Label>
													<Input
														type="checkbox"
														disabled={editDisabled}
                            checked={userData?.CanExport}
														onChange={(e) =>
															onChange({
																CanExport: e.target.checked,
															})
														}
													/>
													Can Download Data
												</Label>
											</Col>
										</Row>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => onSubmit()}>
          Create
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UserDetailModal;
