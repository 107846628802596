import React, { useState, useEffect } from "react";

import SalesforceInput from "./SalesforceInput";
import ClientDetails from "./ClientDetails";
import ClientListTable from "./ClientListTable";
import * as projectActions from "../../redux/actions/currentProjectActions";
import * as currentCostingActions from "../../redux/actions/currentCostingActions";
import store from "../../redux/store";
import {getOnlyProject, saveProject} from "../../redux/actions/currentProjectActions";
import _ from "lodash";
import {
  CustomInput,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  CardTitle,
  Input,
  InputGroup,
  Row,
  Col,
  FormGroup,
} from "reactstrap";
import Spinner from "../../components/Spinner";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import axios from "../../axios-interceptor";
import { toastr } from "react-redux-toastr";
import { pageLoadEnd, pageLoadStart } from "../../redux/actions/appActions";
import * as currentProjectActions from "../../redux/actions/currentProjectActions";
import Select from "react-select";
import SyndicateModal from "./SyndicateModal";
// import SyndicatedModal from "./SyndicatedModal";
import UploadedProjectModal from "../../components/UploadedProjectModal";
import logo from "../../assets/img/avatars/modal-logo.png"; 
import { setOpportunityValue, setProductValue, setMethodologyValue } from "../../redux/actions/currentProjectActions";
const Salesforce = ({ fieldInvalidation, updateProject, user, project }) => {
  const [isOpen, setOpen] = useState(false);
  const [currentDelete, setCurrentDelete] = useState({});
  const [isOpSearchDisable, setisOpSearchDisable] = useState(false);
  const [isOpSearchDisableErr, setisOpSearchDisableErr] = useState(false);
  const [synPopUp, setSynPopUp] = useState(false);
  const [listPopUp, setlistPopUp] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [apiResult, setApiResult] = useState(null);
  const [loadOpp, setLoadOpp] = useState(false);
  const costingProfiles = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  const prod = useSelector(

    ({ currentProject }) => currentProject.productValue

  );
  let oppVal = useSelector(

    ({ currentProject }) => currentProject.opportunityValue

  );
  const meth = useSelector(

    ({ currentProject }) => currentProject.methodologyValue

  );
  let opdata = useSelector(

    ({currentProject}) => currentProject.opportunityData

  );
  const [Num, setNum] = useState("");
  const [valids, setValids] = useState(false);
  let OPPDatas;
  let ProductType;
  let ProductTableData;
  useEffect(() => {
    while(typeof costingProfiles.OPPDatas === "string"){
      costingProfiles.OPPDatas = JSON.parse(costingProfiles.OPPDatas)
    }
    while(typeof costingProfiles.ProductType === "string"){
      costingProfiles.ProductType = costingProfiles.ProductType.includes(',') ? costingProfiles.ProductType.split(',').map(item => item.trim()) : [costingProfiles.ProductType];
    }
    while(typeof costingProfiles.ProductTableData === "string"){
      costingProfiles.ProductTableData = JSON.parse(costingProfiles.ProductTableData)
    }
    
  }, []);

  const updateCostingProfiles = (_costingProfiles) => {
    dispatch({
      type: "UPDATE_NEW_COSTING",
      currentCostingProfile: _costingProfiles,
    });
  }
// updateprojectmodal
const[uploadProject,setUploadProject]=useState(false);
  // SOS Sai
  // refer to client details line 54
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const dispatch = useDispatch();
  const currentSFOPStatus = () => {
    let currentCC = _.head(
      codeLabels.UserScopeOptions?.filter(
        (uso) => uso.Code === project.CommissioningCountry
      )
    );
    let currentBU = _.head(
      currentCC?.BusinessUnits?.filter((bu) => bu.Code === project.BusinessUnit)
    );
    let currentVertical = _.head(
      currentBU?.Verticals?.filter(
        (ver) => ver.Code == project.IndustryVertical
      )
    );
    return currentVertical;
  };
  const [isSF, setSF] = useState(currentSFOPStatus()?.NeedsSFOpportunityNumber);
  // const [isSF, setSF] = useState(currentSFOPStatus().NeedsSFOpportunityNumber?currentSFOPStatus().NeedsSFOpportunityNumber:true);

  // const [isSF, setSF] = useState(true);

  // const [isSF, setSF] = useState(
  //   _.head(project.VerticalObj)?.NeedsSFOpportunityNumber
  // );
  useEffect(() => {
    //  setSF(currentSFOPStatus()?.NeedsSFOpportunityNumber);
    setSF(
      project.CostingProfiles.length > 0 &&
        ((project.IsSyndicated && project.ContractDetails.length) ||
          (!project.IsSyndicated && !project.ContractDetails.length))
        ? false
        : true
    );
  }, [project.VerticalId]);
  useEffect(() => {
    // If opportunities state changes, update project name with opportunity as default
    if (
      project.ProjectName === "" &&
      project.ContractDetails.length > 0
    ) {
      updateProject({
        ProjectName: project.ContractDetails[0].OpportunityName,
      });
    }
  }, [project.ContractDetails]);

  useEffect(() => {
    //console.log("CONTACT UPDATE IS HAPPENING");
    // populating project record with  Microsoft Dynamics contracts
    let primaryCS = "";

    // project status setting
    project.ContractDetails.forEach((obj) => {
      if (obj.Probability >= 90 && project.ProjectStatus === "1") {
        updateProject({ ProjectStatus: "6" });
      }
    });

    // contact emails setting
    if (!project.ProposalOwnerEmail?.value) {
      primaryCS = user.Email;
    } else {
      primaryCS = project.ProposalOwnerEmail.value;
    }
    if (!project.IsSFContactSyncPaused) {
      let opTeamMemberDetails = null;
      project.ContractDetails.forEach((obj) => {
        if (obj.isSF) {
          if (obj.opportunityTeamMemberDetails) {
            if (!opTeamMemberDetails) opTeamMemberDetails = [];
            opTeamMemberDetails = opTeamMemberDetails?.concat(
              obj.opportunityTeamMemberDetails
            );
          }
        }
      });
      opTeamMemberDetails = opTeamMemberDetails?.map((obj) => {
        return obj?.EmailAddresses;
      });
      opTeamMemberDetails = opTeamMemberDetails?.filter((email, index) => {
        return opTeamMemberDetails?.indexOf(email) === index;
      });
      if (opTeamMemberDetails?.indexOf(primaryCS) !== -1) {
        opTeamMemberDetails?.splice(opTeamMemberDetails.indexOf(primaryCS), 1);
      }
      opTeamMemberDetails = opTeamMemberDetails?.map((email) => {
        return { value: email, label: email };
      });
      if (
        !project.id &&
        (!project.OtherProjectTeamContacts ||
          (project.OtherProjectTeamContacts &&
            !project.OtherProjectTeamContacts.length)) &&
        opTeamMemberDetails
      ) {
        updateProject({
          OtherProjectTeamContacts: opTeamMemberDetails,
        });
      }
    }
  }, [project.ContractDetails]);

  const data = {
    tableColumns: [
      {
        dataField: "OpportunityNumber",
        text: "Opportunity #",
        formatter: (cell) => {
          if (cell) {
            return cell;
          } else {
            return "-";
          }
        },
      },
      {
        dataField: "OpportunityName",
        text: "Opportunity Name",
        formatter: (cell) => {
          if (cell) {
            return cell;
          } else {
            return "-";
          }
        },
      },
      { dataField: "AccountName", text: "Client Name" },
      {
        dataField: "Stage",
        text: "Stage",
        formatter: (cell) => {
          if (cell) {
            return cell;
          } else {
            return "-";
          }
        },
      },
      {
        dataField: "AmountUSD",
        text: "Amount",
        formatter: (cell) => {
          if (cell === 0 || cell == null) {
            return "-";
          } else {
            return cell + " USD";
          }
        },
      },
    ],
    tableData: project.ContractDetails,
  };
  useEffect(() => {
    validates();
  }, [Num]);
  const addOpp = (newOpp) => {
    updateProject({
      // ContractDetails: [...project.ContractDetails, newOpp],
      ContractDetails:project.Syndicated? [ newOpp]: [...project.ContractDetails, newOpp],
    });
  };
  const amendAddOpp = (newOpp) => {
    console.log(newOpp);
    updateProject({
      // ContractDetails: [...project.ContractDetails, newOpp],
      ContractDetails: [newOpp],
    });
  };

  const handleDelete = () => {  
    if(project.IsSyndicated && project.ParentProjectId && project.ContractDetails.length<=1){
       toastr.info("We require atleast one Opportunity for project Commission!")
       setOpen(!isOpen);
       dispatch(setOpportunityValue(""));
     }else if (!currentDelete.id) {
      let ProjectName = project.ProjectId ? project.ProjectName : "";
      let fc=[]
      costingProfiles.FieldingCountries.length>0&&fc.push(costingProfiles.FieldingCountries[0])
      if(project.ContractDetails.length > 1){       
        const filteredContractDetails = project.ContractDetails.filter(item => {
          return item.OpportunityNumber !== currentDelete.OpportunityNumber
        });
        ProjectName= filteredContractDetails[0].OpportunityName;
      }
       setOpen(false);
      //  dispatch(setOpportunityValue(""));
       updateProject({
         ContractDetails: [
           ...project.ContractDetails.filter((obj,ind) => {
             return ind !== currentDelete.index;
           }),
         ],
         OtherProjectTeamContacts: null,
         ProjectName: ProjectName
       });
       updateCostingProfiles(
        { FieldingCountries: fc}
        )
     }
      else {
       dispatch(pageLoadStart());
      //  dispatch(setOpportunityValue(""));
       axios
         .delete(`projects/contractdetails/${currentDelete.id}`)
         .then((res) => {
           dispatch(pageLoadEnd());
           setOpen(false);
           setCurrentDelete({});
           toastr.success(res.data.message);
           updateProject({
             ContractDetails: [
               ...project.ContractDetails.filter((obj) => {
                 return obj.id !== currentDelete.id;
               }),
             ],
           });
         })
         .catch((e) => {
           setOpen(false);
           setCurrentDelete({});
           dispatch(pageLoadEnd());
           toastr.error("Opportunity Delete Failed", e.data.message);
         });
     }
   };

  const delOpp = (OpportunityNumber, id,ind=null) => {
    if (project.ContractDetails.length > 0) {
      setOpen(true);
      setCurrentDelete({ OpportunityNumber: OpportunityNumber, id: id?id:null ,index:ind});
      setisOpSearchDisable(false);
      setisOpSearchDisableErr(false);
    }
  };
  // const handleList = () => {
  //   setSynPopUp(false)
  //   setlistPopUp(true)
  // }
  // const getList = () => {
  //   dispatch(pageLoadStart())
  //   axios.post("projects/syndicatedProjects/")
  //     .then(res => {
  //       dispatch(pageLoadEnd())
  //       toastr.success("Proposal Copied Successfully!")
  //     }).catch(error => {
  //       dispatch(pageLoadEnd())
  //       toastr.error("Coping Proposal Failed!")
  //     })
  // }
  let contractNum = 0;
  project.ContractDetails.forEach((obj) => {
    if (obj.ContractNumber > contractNum) {
      contractNum = obj.ContractNumber;
    }
  });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setNum("");
  };
  const handleProductMethodology = (product, subMeth, meth, multiCountry, updatedOpportunityData, updateTableData) => {
    // console.log("product, methodology", product)
    let prodRemoveDupl = [...new Set(product)]
      let productCode = prodRemoveDupl.map(m => {
        return ({ label: m, value: m, isFixed: false })
      });
      // let data = subMeth;
      let temp;
      let tempMeth;
      if (typeof subMeth === "string") {
        if(subMeth.includes(","))
        {
          const array = subMeth.split(",")
          temp = array.map((arr)=>{
            return ({label: arr, value: arr})
          })
        } else {
          temp =  [{label: subMeth, value: subMeth}]
        }
      }
      else{
        temp = subMeth.map(t => {
          return ({label: t?.label, value: t?.value});
        })
       
      }

      if (typeof meth === "string") {
      if(meth.includes(","))
      {
        const arr = meth.split(",")
        tempMeth = arr.map((arr)=>{
          return ({label: arr, value: arr})
        })
      } else {
        tempMeth =  [{label: meth, value: meth}]
      }
    }
      else{
        tempMeth = meth.map(t => {
          return ({label: t?.label, value: t?.value});
        })
       
      }
    
      // let MethCodes = methRemoveDupl.map(m => {
      //   let meth = codeLabels.MethodologyOptions.find(mo => mo.Label == m)
      //    return ({ label: m, value: meth?.Code, isFixed: false })
      // })
    // console.log("productCode,MethCodes",productCode)
    updateCostingProfiles({
      ProductType: productCode,
      StudyType:productCode,
      SubMethodology:temp,
      Methodology: tempMeth,
      IsMultiCountry: multiCountry,
      OPPDatas: updatedOpportunityData,
      ProductTableData: updateTableData
    });
  };
  const handleCommCountryChange = (SalesOrgName,POCRevenueCountry)=>
    {
    
      
    updateProject({
      SalesOrgName:SalesOrgName,
      POCRevenueCountry:POCRevenueCountry,
    });
    };
  let oppdeleted = 0;
  function deleteExistingOpp() {
    return new Promise((resolve, reject) => {

        let id = project.ContractDetails[0].id;
    let num = project.ContractDetails[0].OpportunityNumber;
    
      dispatch(pageLoadStart());
      // dispatch(setOpportunityValue(""));
      axios
        .delete(`projects/contractdetails/${id}`)
        .then((res) => {
          // dispatch(pageLoadEnd());
          // setOpen(false);
          // setCurrentDelete({});
          // toastr.success(res.data.message);
          updateProject({
            ContractDetails: [
              ...project.ContractDetails.filter((obj) => {
                return obj.id !== id;
              }),
            ],
          });
          oppdeleted =1;
          resolve(res);
        })
        .catch((e) => {
          // setOpen(false);
          // setCurrentDelete({});
          dispatch(pageLoadEnd());
          toastr.error(`${num} is deleted`, e.data.message);
          reject(e);
        });
      
      
    })
    
  }

  const handleSearch = () => {
    dispatch(Amend());
  }

  const Amend = () => {
    return async (dispatch, getState) => {
      try {
    let resp;
    let filteredOppLine;
    let contractDetails;
    let submeth = costingProfiles.SubMethodology;
    let meth = costingProfiles.Methodology ? costingProfiles.Methodology : project.CostingProfiles[0].Methodology;
    let prod = costingProfiles.ProductTableData.map(t => t.MaterialID);
    let multiCountry = costingProfiles.IsMultiCountry;
    // toastr.success("Api, will be called here");
    if(project?.ContractDetails?.find(i => i.OpportunityNumber === Num)) {
      setLoadOpp(false);
      toggleModal();
      toastr.error("This opportunity number is already added.");
      return
    }
    if (Object.keys(Num).length > 0) {


      setLoadOpp(true);
      const URL = "/salesforce/" + Num;
      const config = {
        headers: { "auth-token": localStorage.getItem("auth-token") },
      };

      axios
        .get(URL, config)
        .then((response) => {
          resp = response
          // const isAllWbsNumbersValid = costingProfiles.ProductTableData.every(item => {
          //   return item.hasOwnProperty("WBSNumber") && typeof item.WBSNumber === "string" && item.WBSNumber.trim() !== "";
          // });
          let materialIDs = []
          if(response.data.opportunity.CreatedDate >= "2024-07-01T00:00:00Z" ){
            response.data.opportunity.opportunityLineItemDetails.filter((item)=>{
              materialIDs.push(item?.MaterialID?.slice(0,4).toLowerCase())
              
            })
            console.log("maaterialIds",materialIDs);
          }
          let flagValues = []
          materialIDs.map((data)=>{
            if(data === "cmi-" || data?.includes("ci-")){
              flagValues.push("true")
              // toastr.error(`Invalid Material ID found, please correct ${opportunityNumber} in MSD and try again.`)
            }
          })
         
          if(flagValues.length &&  !resp.data.opportunity.ContractType.includes("Existing Contract")){
            toastr.error(`Invalid Material ID found, please correct ${response.data.opportunity.OpportunityNumber} in MSD and try again.`)
            setLoadOpp(false);
          }
          else{
           filteredOppLine = resp.data.opportunity.opportunityLineItemDetails.filter((item) => {
            if (item?.MaterialID?.slice(0,16).toLowerCase().replace(' ','') === "expenserecharge") {
              item.CharacteristicValues = {Z_FIELDINGMETHOD:["EXPENSE RECHARGE"]};
              return true;
            } else if (
            (item?.MaterialID?.slice(0,4).toLowerCase() === "cmi-" || item?.MaterialID?.slice(0,3).toLowerCase() === "cmi" || item?.MaterialID?.slice(0,3).toLowerCase() === "ci-" || item?.MaterialID?.slice(0,4).toLowerCase().replace(' ','') === "tbc") && (item.CharacteristicValues !== null)) {
              return true;
            } else {
              return false;
            }
          });
          if (project?.SalesOrgName !== null) {
            let SalesOrgcodes= resp?.data?.opportunity?.SalesOrgcode
            let SalesOrgcodeFilterArray=codeLabels.SalesOrgcodes.filter((code)=>code.SalesOrgcode	=== SalesOrgcodes)
            if( !SalesOrgcodeFilterArray.length) {
              SalesOrgcodeFilterArray = [{PocRevenueCountry: "N/A"}]
            }
            handleCommCountryChange(resp?.data?.opportunity?.SalesOrgName, SalesOrgcodeFilterArray[0].PocRevenueCountry)
          }
          resp.data.opportunity.opportunityLineItemDetails = filteredOppLine;
          // if (!isAllWbsNumbersValid) {
          //   setLoadOpp(false);
          //   toggleModal();
          //   toastr.error("Amendment cannot be performed for client chargeable items");
          //   return
          // }
          let i=0;
          resp.data.opportunity.opportunityLineItemDetails.reduce((acc, item) => {
            if (item.WBSNumber) {
            if(acc.has(item.WBSNumber)) {
              i++;
            } else {
              acc.add(item.WBSNumber);
            }
          }
            return acc;
          }, new Set());
          if (i>0) {
            setLoadOpp(false);
            toggleModal();
            toastr.error("WBSNumber in Line items should be unique");
            return
          }
          contractDetails = {
            ...resp.data.opportunity,
            AccountName: resp.data.opportunity.accountDetails.AccountName,
            Industry: resp.data.opportunity.accountDetails.Industry,
            FieldingCountries:response.data.opportunity.FieldingCountryCI,
            isSF: true,
            ContractNumber: 1,
            product: filteredOppLine.map(id => {
              return id.MaterialID
            }),
            opportunityNum: resp.data.opportunity.OpportunityNumber,
            methodology: filteredOppLine.flatMap((item) => item.CharacteristicValues.Z_FIELDINGMETHOD),
            
          };
          if(contractDetails.Stage === "Closed Lost") {
            toastr.error('The provided opportunity is in invalid stage - "Closed Lost".')
            setLoadOpp(false);
            return
          }
          if(!contractDetails.product || (contractDetails.product).length === 0) {
            toastr.error('Quote Product Characteristics is unavailable in MSD. Please check the MSD-OP-number guide.')
            setLoadOpp(false);
            return;
          }
          oppdeleted = 1;
          return;
       } }).then(() => {
            if (oppdeleted === 0) {
              // toastr.error('Opportunity is not valid');
            setLoadOpp(false);
            return;
            }
            setNum("");
            while (typeof costingProfiles.OPPDatas === "string") {
              costingProfiles.OPPDatas = JSON.parse(costingProfiles.OPPDatas);
            }
            while (typeof costingProfiles.ProductTableData === "string") {
              costingProfiles.ProductTableData = JSON.parse(costingProfiles.ProductTableData);
            }
            let filteredOppLineExp = filteredOppLine.filter((item) => !(item?.MaterialID?.slice(0,16).toLowerCase().replace(' ','') === "expenserecharge"));
          let oppDatasNew = filteredOppLineExp.filter(
            (lineId) => !costingProfiles.OPPDatas.filter((item) => !(item?.MaterialID?.slice(0,16).toLowerCase().replace(' ','') === "expenserecharge")).some((line) => line.WBSNumber == lineId.WBSNumber)
          );
          let tableNew = filteredOppLineExp.filter(
            (lineId) => !costingProfiles.ProductTableData.filter((item) => !(item?.MaterialID?.slice(0,16).toLowerCase().replace(' ','') === "expenserecharge")).some((line) => line.WBSNumber == lineId.WBSNumber)
          );
          console.log("filteredOppLineExp", filteredOppLineExp, costingProfiles.OPPDatas, costingProfiles.ProductTableData, oppDatasNew, tableNew)
          OPPDatas = costingProfiles.OPPDatas.concat(oppDatasNew);
          ProductType = prod.concat(contractDetails.product);
          let prodRemoveDupl = [...new Set(ProductType)]
          ProductTableData = costingProfiles.ProductTableData.concat(tableNew);
            let updopp = _.cloneDeep(OPPDatas);
            let updtab =  _.cloneDeep(ProductTableData);
              // Updating table data with new msd response
    const tableDataRef = updtab.map(temp => {
      const matchOppLineItem = filteredOppLine.filter(opp => opp.WBSNumber && temp.WBSNumber && opp.WBSNumber === temp.WBSNumber);

      if (matchOppLineItem.length > 0) {
        temp.OpportunityLineItemID = matchOppLineItem[0].OpportunityLineItemID;
        temp.MaterialID = matchOppLineItem[0].MaterialID;
        temp.ProductDescription = matchOppLineItem[0].ProductDescription;
        temp.StartDate = matchOppLineItem[0].StartDate;
        temp.EndDate = matchOppLineItem[0].EndDate;
        temp.CharacteristicValues.Z_FIELDINGMETHOD[0] = matchOppLineItem[0].CharacteristicValues.Z_FIELDINGMETHOD[0];
      }

      return temp;
    });
    // Updating OpportunityData with new msd response
    const updatedOppData = updopp.map(temp => {
      const matchOppLineItem = filteredOppLine.filter(opp => opp.WBSNumber && temp.WBSNumber && opp.WBSNumber === temp.WBSNumber);

      if (matchOppLineItem.length > 0) {
        temp.OpportunityLineItemID = matchOppLineItem[0].OpportunityLineItemID;
        temp.MaterialID = matchOppLineItem[0].MaterialID;
        temp.ProductDescription = matchOppLineItem[0].ProductDescription;
        temp.StartDate = matchOppLineItem[0].StartDate;
        temp.EndDate = matchOppLineItem[0].EndDate;
      }

      return temp;
    });
    // setting red colour object to identify deleted line items at MSD
    let updateTableDatatemp = tableDataRef.map((row) => ({
      ...row,
      isRed : !filteredOppLine.some(line => ((row?.MaterialID?.slice(0,16).toLowerCase().replace(' ','') == "expenserecharge") || line.WBSNumber === row.WBSNumber)),
    }));
    let updatedOpportunityDatatemp = updatedOppData.map((row) => ({
      ...row,
      isRed : !filteredOppLine.some(line => ((row?.MaterialID?.slice(0,16).toLowerCase().replace(' ','') == "expenserecharge") || line.WBSNumber === row.WBSNumber)),
    })); 
    ///////////////////////////////////////////////////////////////////////////
    // updating expense recharge items
    let filterExp = _.cloneDeep(filteredOppLine.filter((item) => (item?.MaterialID?.slice(0,16).toLowerCase().replace(' ','') === "expenserecharge")));
    let filterExpCount = filterExp?.length;
    let tabledataExpCount = updateTableDatatemp.filter((item) => (item?.MaterialID?.slice(0,16).toLowerCase().replace(' ','') === "expenserecharge")).length;
    let updatedTableExp;
    let updatedOpportunityDataExp;
    if (filterExpCount == tabledataExpCount) {
      let i = 0;
      let j = 0;
      updatedTableExp = updateTableDatatemp.map((temp) => {
        if (temp?.MaterialID?.slice(0,16).toLowerCase().replace(' ','') === "expenserecharge") {
          temp.OpportunityLineItemID = filterExp[i].OpportunityLineItemID;
          temp.MaterialID = filterExp[i].MaterialID;
          temp.ProductDescription = filterExp[i].ProductDescription;
          temp.StartDate = filterExp[i].StartDate;
          temp.EndDate = filterExp[i].EndDate;
          i++;
        }
        return temp;
      })
      updatedOpportunityDataExp = updatedOpportunityDatatemp.map((temp) => {
        if (temp?.MaterialID?.slice(0,16).toLowerCase().replace(' ','') === "expenserecharge") {
          temp.OpportunityLineItemID = filterExp[j].OpportunityLineItemID;
          temp.MaterialID = filterExp[j].MaterialID;
          temp.ProductDescription = filterExp[j].ProductDescription;
          temp.StartDate = filterExp[j].StartDate;
          temp.EndDate = filterExp[j].EndDate;
          j++;
        }
        return temp;
    })
    } else if (filterExpCount > tabledataExpCount) {
      if (tabledataExpCount == 0) {
        updatedTableExp = updateTableDatatemp.concat(filterExp);
        updatedOpportunityDataExp = updatedOpportunityDatatemp.concat(filterExp);
      } else {
        //5,2
        let i=0;
        let j=0;
        let updatedTableExp1 = updateTableDatatemp.map ((temp, index) => {
          if (temp?.MaterialID?.slice(0,16).toLowerCase().replace(' ','') === "expenserecharge") {
            if (i < tabledataExpCount) {
              temp.OpportunityLineItemID = filterExp[i].OpportunityLineItemID;
              temp.MaterialID = filterExp[i].MaterialID;
              temp.ProductDescription = filterExp[i].ProductDescription;
              temp.StartDate = filterExp[i].StartDate;
              temp.EndDate = filterExp[i].EndDate;
              i++;
            }
          }
          return temp;
        })
        let updatedOpportunityDataExp1 = updatedOpportunityDatatemp.map ((temp, index) => {
          if (temp?.MaterialID?.slice(0,16).toLowerCase().replace(' ','') === "expenserecharge") {
            if (j < tabledataExpCount) {
              temp.OpportunityLineItemID = filterExp[j].OpportunityLineItemID;
              temp.MaterialID = filterExp[j].MaterialID;
              temp.ProductDescription = filterExp[j].ProductDescription;
              temp.StartDate = filterExp[j].StartDate;
              temp.EndDate = filterExp[j].EndDate;
              j++;
            }
          }
          return temp;
        })
        // adding remaining items as new lines
        let newLines = filterExp.filter((item,index) => index>(i-1));
        updatedTableExp = updatedTableExp1.concat(newLines);
        updatedOpportunityDataExp = updatedOpportunityDataExp1.concat(newLines);
      }
    } else {
      if (filterExpCount == 0) {
         updatedTableExp = updateTableDatatemp.map((temp) => {
          if (temp?.MaterialID?.slice(0,16).toLowerCase().replace(' ','') === "expenserecharge") {
            temp.isRed = true;
          }
          return temp;
        })
        updatedOpportunityDataExp = updatedOpportunityDatatemp.map((temp) => {
          if (temp?.MaterialID?.slice(0,16).toLowerCase().replace(' ','') === "expenserecharge") {
            temp.isRed = true;
          }
          return temp;
        })
        // updateTableDatatemp
        // updatedOpportunityDatatemp
      } else {
        //2,5
        let i=0;
        let j=0;
        let updatedTableExp1 = updateTableDatatemp.map ((temp, index) => {
          if (temp?.MaterialID?.slice(0,16).toLowerCase().replace(' ','') === "expenserecharge") {
            if (i < filterExpCount) {
              temp.OpportunityLineItemID = filterExp[i].OpportunityLineItemID;
              temp.MaterialID = filterExp[i].MaterialID;
              temp.ProductDescription = filterExp[i].ProductDescription;
              temp.StartDate = filterExp[i].StartDate;
              temp.EndDate = filterExp[i].EndDate;
              i++;
            }
          }
          return temp;
        })
        let updatedOpportunityDataExp1 = updatedOpportunityDatatemp.map ((temp, index) => {
          if (temp?.MaterialID?.slice(0,16).toLowerCase().replace(' ','') === "expenserecharge") {
            if (j < filterExpCount) {
              temp.OpportunityLineItemID = filterExp[j].OpportunityLineItemID;
              temp.MaterialID = filterExp[j].MaterialID;
              temp.ProductDescription = filterExp[j].ProductDescription;
              temp.StartDate = filterExp[j].StartDate;
              temp.EndDate = filterExp[j].EndDate;
              j++;
            }
          }
          return temp;
        })
        // adding remaining items as new lines
        let newLines = filterExp.filter((item,index) => index>(i-1));
        updatedTableExp = updatedTableExp1.concat(newLines);
        updatedOpportunityDataExp = updatedOpportunityDataExp1.concat(newLines);
      }
    }
    // updateTableDatatemp;
    // updatedOpportunityDatatemp;
    let updateTableData = updatedTableExp.map((row) => ({
      ...row,
      isAmendedit : false,
    }));
    let updatedOpportunityData = updatedOpportunityDataExp.map((row) => ({
      ...row,
      isAmendedit : false,
    })); 
    // costingProfiles.OPPDatas = updatedOpportunityData;
    // costingProfiles.ProductTableData = updateTableData;

    console.log("contractDetails, prodRemoveDupl, updatedOpportunityData, updateTableData, submeth, meth", contractDetails, prodRemoveDupl, updatedOpportunityData, updateTableData, submeth, meth);
          dispatch(setProductValue(prodRemoveDupl));
          dispatch(setOpportunityValue(contractDetails.opportunityNum));
          dispatch(setMethodologyValue(contractDetails.methodology, contractDetails.product));
          dispatch(projectActions.setOpportunityData(updatedOpportunityData));
          dispatch(projectActions.setOppourtnityLineItems(updateTableData));
          handleProductMethodology(contractDetails.product, submeth, meth, multiCountry, updatedOpportunityData, updateTableData);
          
            deleteExistingOpp().then(() => {
              amendAddOpp(contractDetails);
             }).then(() => {
              project.ContractDetails = [contractDetails];
              costingProfiles.OPPDatas = updatedOpportunityData;
              costingProfiles.ProductTableData = updateTableData;
              project.CostingProfiles[0] = costingProfiles
              console.log("project, costingProfiles", project, costingProfiles);
           axios.put(`/projects/${getState().currentProject.newProject.ProjectId}`,project).then((result)=>{
            axios.get(`/projects/${getState().currentProject.newProject.ProjectId}`).then((res)=>{
              // console.log("res.data.project.ContractDetails[0]", res.data.project.ContractDetails[0]);
              getState().currentProject.newProject.ContractDetails[0].opportunityLineItemDetails= 
			res.data.project.ContractDetails[0].opportunityLineItemDetails;
              dispatch(
                // updateProject({
                //   // ContractDetails: [...project.ContractDetails, newOpp],
                //   ContractDetails: [res.data.project.ContractDetails[0]],
                // })
                
                saveProject(res.data.project,()=> {
                  axios.get(`/costingprofiles/${getState().currentCosting.currentCostingProfile.id}`).then((res)=> {
                    let prof = res.data.costingProfile;
                    prof.OPPDatas = updatedOpportunityData;
                    prof.ProductTableData = updateTableData;
                    console.log("prof", prof);
                 axios.put(`/costingprofiles/${getState().currentCosting.currentCostingProfile.id}`,prof).then((r) => {
                  getState().currentCosting.currentCostingProfile = prof;
                   dispatch({
                    type: "UPDATE_NEW_COSTING",
                    currentCostingProfile: prof,
                    });
                    // dispatch(currentCostingActions.updateProfile(prof));
                 })
               })
                }, null)
                
                )
                // })
            })
          })
        })
        .then(() => {
          setTimeout(()=>{
						dispatch(pageLoadEnd());
          toastr.success("The new opportunity has been amended successfully");
          setLoadOpp(false);
					},5000)
        })
            // deleteExistingOpp().then(() => {
            //   amendAddOpp(contractDetails)

        //  if(inpopup)
        //  { setGetNewData({
        //     oppNo:response.data.opportunity.OpportunityNumber,
        //     oppName:response.data.opportunity.OpportunityName,
        //     Amount:response.data.opportunity.AmountUSD + " USD ",
        //     clientName:response.data.opportunity.accountDetails.AccountName
        //   })}

          return resp.status;

        })
        .catch((err) => {
          toastr.error("Adding amendment opportunity failed. Please check the Opportunity in MSD with the MSD-OP-number guide.");
          console.log(err);
          setLoadOpp(false);
          // setisOpSearchDisable(true)

        });
      }
    } catch(error) {
      toastr.error("Adding amendment opportunity failed. Please check the Opportunity in MSD with the MSD-OP-number guide.");
          console.log(error);
          setLoadOpp(false);
    } 
    }
  }

  const handleChange = (e) => {
    setNum(e.target.value.trim());
    setSearchValue(Num);
  };
  const validates = () => {
    if (Num.substring(0, 2) === "OP" && Num.length === 12) {
      setValids(true);
    } else {
      setValids(false);
    }
    return valids;
  };
  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          setCurrentDelete({});
          setOpen(!isOpen);
        }}
      >
        <ModalHeader toggle={() => setOpen(!isOpen)}>
          {/* <img src={logo}  className="modallogo"/> */}
        </ModalHeader>
        <span className="modaltitle p-0">Are you sure you want to delete the opportunity number?</span>
        <ModalFooter>
          <Button
            color="primary"
            onClick={(e) => {
              handleDelete();
            }}
          >
            Yes
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => {
              setCurrentDelete({});
              setOpen(!isOpen);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {
        <CustomInput
        disabled = {project?.ProjectStatus == "7" || project.ProjectStatus=='8'}
          type="switch"
          id="syndicated"
          name="syndicated"
          className="h5 mb-3"
          label="This is a Syndicated Project"
          checked={project.IsSyndicated}
          onChange={(e) => {
            isSF ? setSF(false) : setSF(true);
            setisOpSearchDisable(false);
            setisOpSearchDisableErr(false);
            if(project.IsUploaded){
              setUploadProject(true)
            }else{
            if (
              (project.IsSyndicated && project.ProjectStatus === "3") ||  
              // project is syndicated and commissioned
              (project.IsSyndicated && project.ProjectStatus === "30") ||
              // project is syndicated and commissioned(recost)
              project.ParentProjectId != null
              // project is child project
            ) {
              toastr.error(
                "You are not allowed make project as Non-Syndicated"
              );
            } else {
              updateProject({ IsSyndicated: !project.IsSyndicated });
              if (!project.IsSyndicated) {
                setSynPopUp(true);
              } else {
                setSynPopUp(false);
              }
            }
          }}
        }
        />
      }
      { 
      (!project.IsSyndicated) && project.ProjectStatus === "30" &&
        <div>
        <Button colour="primary" onClick={toggleModal}>Amend Opportunity</Button>

        { isModalOpen &&
          <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader>Amend Opportunity</ModalHeader>
        <ModalBody>
          <p>Enter the Opportunity you want to replace {oppVal} with</p>
          <FormGroup>
            <Input
            type="text"
            placeholder="Enter Opportunity number"
            value={Num}
            onChange={(e) => handleChange(e)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSearch} disabled={!valids || loadOpp}>Search</Button>
          <Button color="secondary" onClick={toggleModal}>Close</Button>
          {loadOpp ? <Spinner size="small" /> : ""}
        </ModalFooter>
        </Modal>}
        </div>
      }
  {project.IsUploaded && uploadProject && <UploadedProjectModal uploadProject={uploadProject} setUploadProject={setUploadProject}/>}

{/*  */}
      {/* {synPopUp && (
        <SyndicateModal
          synPopUp={synPopUp}
          setSynPopUp={setSynPopUp}
          setisOpSearchDisable={setisOpSearchDisable}
          isOpSearchDisable={isOpSearchDisable}
          isOpSearchDisableErr={isOpSearchDisableErr}
          setisOpSearchDisableErr={setisOpSearchDisableErr}
          syndicated={project.IsSyndicated}
        />
      )} */}

      {isSF ? (
        <SalesforceInput
          project={project}
          newContractNumber={(contractNum += 1)}
          setSF={setSF}
          addOpp={addOpp}
          syndicated={project.IsSyndicated}
          fieldInvalidation={fieldInvalidation}
          isOpSearchDisable={isOpSearchDisable}
          setisOpSearchDisable={setisOpSearchDisable}
          isOpSearchDisableErr={isOpSearchDisableErr}
          setisOpSearchDisableErr={setisOpSearchDisableErr}
        />
      ) : (
        <ClientDetails
          newContractNumber={(contractNum += 1)}
          setSF={setSF}
          addOpp={addOpp}
          isOpSearchDisable={isOpSearchDisable}
          setisOpSearchDisable={setisOpSearchDisable}
          isOpSearchDisableErr={isOpSearchDisableErr}
          setisOpSearchDisableErr={setisOpSearchDisableErr}
          syndicated={project.IsSyndicated}
          fieldInvalidation={fieldInvalidation}
        />
      )}
      {fieldInvalidation.Contracts ? (
        <h5 style={{ color: "#AD4400" }}>please add a client</h5>
      ) : null}
      {project.ContractDetails.length !== 0 ? (
        <ClientListTable {...data} delOpp={delOpp} project = {project} />
      ) : null}
    </React.Fragment>
  );
};

export default Salesforce;