import React, { useEffect } from "react";
import { Col, Row, CardBody, FormFeedback, FormGroup } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash"
import { CustomInput, Input } from "reactstrap";
import * as codeLabelActions from "../../redux/actions/codeLabelActions";

import { getMultiOptions } from "../../utils/codeLabels";
import SelectWrapper from "../../components/SelectWrapper/SelectWrapper";

const Start = ({ project, updateProject, fieldInvalidation }) => {

  //commit
  console.log("market project",project)
  const marketDropdown = useSelector(
    ({ currentProject }) => currentProject.enableMarketDropdown
  );
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const existingProjectCheckArr=codeLabels.MSDFlagOptions.filter((item)=>item.Code==="MSDFlag")[0]?.Label
  const existingProjectCheck=new Date(existingProjectCheckArr)
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(codeLabelActions.getDashBoardResponse());
    let dropdownOptions = [];
    if (project.CommissioningCountry !== "") {
      dropdownOptions = codeLabels.AllCountries.filter((obj) => {
        if (obj.Code === project.CommissioningCountry) {
          return obj;
        }
      })[0]?.Offices;

      if (project.CommissioningOffice === "" && dropdownOptions?.length === 1) {
        updateProject({ CommissioningOffice: dropdownOptions[0]?.Code });
      }
    }
  }, [project.CommissioningCountry]);
  const ProjectStatus=()=>{
    if(project.ProjectStatus==="3" || project.ProjectStatus==="7" || project.PriceChange===3|| (project.ProjectStatus=='8')){
      
      return true
    }

  }

  useEffect(()=>{
    if(project.IsSyndicated && project.SyndicatedwithOpp !== true)
    {
      if(project.CommissioningCountry !== "")
      {
        let dropdown=codeLabels.SalesOrgcodes.filter((code)=>code.Ctr===project.CommissioningCountry)
        if(dropdown.length===1)
        {
          updateProject({
            SalesOrgName:dropdown[0].SalesOrgName,
            POCRevenueCountry:dropdown[0].PocRevenueCountry
          });
        }
      }
    }
  },[project.CommissioningCountry])


  // useEffect(()=>{
  //   if(project.MSDOperatingCountryMismatch)
  //   {
  //     if(project.CommissioningCountry !== "")
  //     {
  //       let dropdown=codeLabels.SalesOrgcodes.filter((code)=>code.Ctr===project.CommissioningCountry)
  //       if(dropdown.length)
  //       {
  //         updateProject({
  //           POCRevenueCountry:dropdown[0].PocRevenueCountry
  //         });
  //       }
  //     }
  //   }
  // },[project.CommissioningCountry])

  useEffect(() => {
    let dropdownOptions = [];
    if (project.CommissioningCountry !== "" && project.BusinessUnit !== "") {
      dropdownOptions = codeLabels.AllCountries.filter((obj) => {
        if (obj.Code === project.CommissioningCountry) {
          return obj;
        }
      })[0]?.BusinessUnits.filter((obj) => {
        if (obj.Code === project.BusinessUnit) {
          return obj;
        }
      })[0]?.Verticals;

      if (project.IndustryVertical === "" && dropdownOptions?.length === 1) {
        handleIndustryVerticalChange(dropdownOptions[0].Code);
      }
    }
  }, [project.BusinessUnit]);

  const commissioningCountryDisable=()=>{

    if(marketDropdown)
    {
      return false
    }
    if(!project.IsSyndicated || project.SyndicatedwithOpp == true)
    {
      return true
    }
  }
  const handleSalesorgChange=(value)=>{
    const array=codeLabels.SalesOrgcodes.filter((code)=>code.SalesOrgName	===value)

    updateProject({
      SalesOrgName:value,
      POCRevenueCountry:array[0].PocRevenueCountry
    });
  }

    const handleCommCountryChange = (value) =>
    {

        let fc = null;
        if (value)
            fc=getMultiOptions(
                codeLabels.FieldingCountriesOptions,
                value
            );
    updateProject({
      CommissioningCountry: value,
      CommissioningOffice: "",
      BusinessUnit: "CI",
      IndustryVertical: "",
      FieldingCountries: fc,
      VerticalId: null,
    });
  };

  const handleBusinessUnitChange = (e) => {
    updateProject({
      BusinessUnit: e.target.value,
      BusinessUnitId: Number(e.target.selectedOptions[0].id),
      IndustryVertical: "",
      VerticalId: null,
    });
  };
  const currentSFOPStatus = (vertCode) => {
    let currentCC = _.head(codeLabels.AllCountries.filter(uso => uso.Code === project.CommissioningCountry))
    let currentBU = _.head(currentCC.BusinessUnits.filter(bu => bu.Code === project.BusinessUnit))
    let currentVertical = _.head(currentBU.Verticals.filter(ver => ver.Code == vertCode))
    return currentVertical
  }
  const handleIndustryVerticalChange = (vertCode) => {
    //console.log("in handle industry vertical change");
    if (project.CommissioningCountry !== "" && project.BusinessUnit !== "") {
      let vert = currentSFOPStatus(vertCode)

      updateProject({
        IndustryVertical: vertCode,
        VerticalId: vert?.id,
      });
    }
  };
  return (
    <React.Fragment>
      {project.MSDOperatingCountryMismatch&&<h5 style={{color:"red"}}>Please select the Commission Country manually</h5>}
      <Row>
        <Col>
          <h5>Commission/Operating Country (MSD)</h5>
          <FormGroup>
            <SelectWrapper
              type="select"
              id="commissioningCountry"
              name="commissioningCountry"
              value={project.CommissioningCountry}
              invalid={fieldInvalidation.CommissioningCountry}
              onChange={(e) => {
                handleCommCountryChange(e.target.value);
              }}
              disabled={project.id?true:false || commissioningCountryDisable()}
            >
              <option value="">Please select an option</option>
              {(project.MSDOperatingCountryMismatch || (project.IsSyndicated && project.SyndicatedwithOpp !== true)) ? codeLabels.UserScopeOptions.sort((a, b) => a.Label.localeCompare(b.Label)).map((obj) => {
                return (
                  <option key={obj.Code} value={obj.Code}>
                    {obj.Label}
                  </option>
                );
              }) : codeLabels.AllCountries.sort((a, b) => a.Label.localeCompare(b.Label)).map((obj) => {
                return (
                  <option key={obj.Code} value={obj.Code}>
                    {obj.Label}
                  </option>
                );
              }) }
            </SelectWrapper>
            <FormFeedback>Please check country selection.</FormFeedback>
          </FormGroup>
        </Col>
        <Col>
          <h5>Commissioning Office</h5>
          <FormGroup>
            <SelectWrapper
              type="select"
              id="commissioningOffice"
              name="commissioningOffice"
              invalid={fieldInvalidation.CommissioningOffice}
              value={project.CommissioningOffice}
              onChange={(e) =>
                updateProject({ CommissioningOffice: e.target.value })
              }
              disabled={ProjectStatus()}
            >
              <option value="">Please select an option</option>
              {project.CommissioningCountry !== ""
                ?_.head(codeLabels.AllCountries.filter((obj) => {
                  if (obj.Code === project.CommissioningCountry) {
                    return obj;
                  }
                }))?.Offices.map((obj) => {
                  return (
                    <option key={obj.Code} value={obj.Code}>
                      {obj.Label}
                    </option>
                  );
                })
                : null}
            </SelectWrapper>
            <FormFeedback>Please check office selection.</FormFeedback>
          </FormGroup>
        </Col>
        {(project.createdAt===undefined ||new Date(project.createdAt)>= existingProjectCheck)&&<Col>
          <h5>Sales Org Name (MSD)</h5>
          <FormGroup>
            {(!project.IsSyndicated || project.SyndicatedwithOpp == true)?
            <Input type="text" disabled={true} value={project.SalesOrgName} />:
              <SelectWrapper
              type="select"
              id="SalesOrgName"
              name="SalesOrgName"
              value={project.SalesOrgName}
              invalid={fieldInvalidation.SalesOrgName}
              onChange={(e) => {
                handleSalesorgChange(e.target.value)
              }}
              disabled={project.id}
            >
              <option value="">Please select an option</option>
              {project.CommissioningCountry?codeLabels.SalesOrgcodes.filter((code)=>code.Ctr	===project.CommissioningCountry).map((obj) => {
                return (
                  <option key={obj.SalesOrgName} value={obj.SalesOrgName}>
                    {obj.SalesOrgName}
                  </option>
                );
              }):null}
            </SelectWrapper>
            }
          </FormGroup>
        </Col>}
        {(project.createdAt!=undefined &&new Date(project.createdAt)< existingProjectCheck)&&<Col>
          <h5>Business Unit</h5>
          <FormGroup>
            <SelectWrapper
              type="select"
              id="businessUnit"
              name="businessUnit"
              value={project.BusinessUnit}
              invalid={fieldInvalidation.BusinessUnit}
              onChange={(e) => {
                handleBusinessUnitChange(e);
              }}
              disabled={ProjectStatus()}
            >
              <option value="">Please select an option</option>
              {project.CommissioningCountry !== ""
                ? ( (project.IsSyndicated  && project.SyndicatedwithOpp !== true)?_.head(codeLabels.AllCountries.filter((obj) => {
                  if (obj.Code === project.CommissioningCountry) {
                    return obj;
                  }
                }))?.BusinessUnits.map((obj) => {
                  return (
                    <option key={obj.Code} value={obj.Code} id={obj.id}>
                      {obj.Label}
                    </option>
                  );
                }):_.head(codeLabels.AllCountries.filter((obj) => {
                  if (obj.Code === project.CommissioningCountry) {
                    return obj;
                  }
                }))?.BusinessUnits.map((obj) => {
                  return (
                    <option key={obj.Code} value={obj.Code} id={obj.id}>
                      {obj.Label}
                    </option>
                  );
                }))
                : null}
            </SelectWrapper>
            <FormFeedback>Please check business unit selection.</FormFeedback>
          </FormGroup>
        </Col>}
        <Col>
          <h5>Industry Vertical</h5>
          <FormGroup>
            <SelectWrapper
              type="select"
              id="industryVertical"
              name="industryVertical"
              value={project.IndustryVertical}
              invalid={fieldInvalidation.IndustryVertical}
              onChange={(e) => {
                handleIndustryVerticalChange(e.target.value);
              }}
              disabled={ProjectStatus()}
            >
              <option value="">Please select an option</option>
              {project.CommissioningCountry !== "" && project.BusinessUnit &&
                project.BusinessUnit !== ""
                ? _.head(codeLabels.AllCountries.filter((obj) => {
                    if (obj.Code === project.CommissioningCountry) {
                      return obj;
                    }
                  }))?.BusinessUnits.filter((obj) => {
                      if (obj.Code === project.BusinessUnit) {
                        return obj;
                      }
                    })[0]
                    ?.Verticals.map((obj) => {
                      return (
                        <option
                          key={obj.Code}
                          value={obj.Code}
                          id={obj.id}
                          vertical={obj}
                        >
                          {obj.Label}
                        </option>
                      );
                    })
                : null}
            </SelectWrapper>
            <FormFeedback>
              Please check industry vertical selection.
            </FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      {(project.createdAt===undefined ||new Date(project.createdAt)>= existingProjectCheck)&&<Row>
        <Col md={3}>
            <h5>POC (Revenue) Country</h5>
            <FormGroup>
                  <Input type="text" disabled={true} value={project.POCRevenueCountry} />
              <FormFeedback>Please check country selection.</FormFeedback>
            </FormGroup>
          </Col>
      </Row>}
    </React.Fragment>
  );
};

export default Start;
