import * as appActions from "../../redux/actions/appActions";
import axios from "../../axios-interceptor";
import { toastr } from "react-redux-toastr";
import { Prompt } from "react-router";
import jspreadsheet from "jspreadsheet-ce";
import "jspreadsheet-ce/dist/jspreadsheet.css";

import React, { useState, useEffect, Suspense, useRef } from "react";
import ManualCostEntry from "../costingProfile/ManualCostEntry";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvInput,
  AvRadio,
  AvSelectField,
} from "availity-reactstrap-validation";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  FormFeedback,
  FormGroup,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
  UncontrolledCollapse,
  Table,
  Container,
} from "reactstrap";
import { useSelector } from "react-redux";
import { CustomInput, Input, Label, Button } from "reactstrap";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import "./common.css";
import CostingOption from "./CostingOptionFinal";
import NewSpreadsheet from "./NewSpreadsheet";
import TestPageRFQ from "./TestPageRFQ";
import TestPageOpResource from "./TestOpResource";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faCopy,
  faPlus,
  faPlusCircle,
  faTimesCircle,
  faFileUpload,
  faSpinner,
  faCheck,
  faSave,
  faUserCheck,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
//import CostingOption from "./CostingOption";
import _, { filter, includes, isNull, uniq, uniqueId } from "lodash";
import Select from "react-select";
import update from "immutability-helper";
import * as sideBarActions from "../../redux/actions/sidebarActions";
import Settings from "../../components/Settings";
import TestResponsibilities from "./TestResponsibilities";
import TestResponsibilities_internal from "./TestResponsibilities_internal";
import * as mapperFunctions from "../../utils/rfqMapper";
import * as currentCostingActions from "../../redux/actions/currentCostingActions";
import { useHistory } from "react-router-dom";
import CommercialHours from "../costingProfile/CommercialHours";
import { setFormErrorStatus, setOpportunityValue, setNewCountryValue, setNewMethValue } from "../../redux/actions/currentProjectActions";
import CreatableSelect from "react-select/creatable";
import store from "../../redux/store/index"
import {
  pageLoadEnd,
  pageLoadStart,
  recordLoadEnd,
  recordLoadStart,
  setCostingStatus,
  valueChangedInRFQ,
} from "../../redux/actions/appActions";
import WaveLevelEdit from "../wavelevel/WaveLevelEdit_internal";
import CommercialHours_internal from "../costingProfile/CommercialHours_internal";
import { calcAll } from "../../utils/calculations";
import logo from "../../assets/img/avatars/modal-logo.png";
import SelectWrapper from "../../components/SelectWrapper/SelectWrapper";
import Axios from "../../axios-interceptor";
import { useContext } from "react";
var imgStyle = {
  width: "150px",
};
const SHOPPERS_METHODOLOGIS = ["SM000024", "SM000026", "SM000029"]

export const ChangesTracker = React.createContext({
  changes: {},
  hasDataChanged: function (schemaData, source, indxFC, indxSM, indexCO) {},
  deleteId: function(id, indxFC, indxSM, indexCO) {}
})


const CountryMethodologyTabs = ({
  profile,
  updateProject,
  setOpenNewProfile,
  project,
  disableFields,
}) => {
  const recordloading = useSelector(({ app }) => app.recordloading);
  const localPageload = useSelector(({ app }) => app.localPageload);

  const userRecord = useSelector(({ user }) => user.userRecord);

  let currentCosting = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );

  const newTabCountry = useSelector(
    ({ currentProject }) => currentProject.newCountry
  );

  const newTabMeth = useSelector(
    ({ currentProject }) => currentProject.newMeth
  );

  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const history = useHistory()
  const dispatch = useDispatch();
  const styleModelBody = {
    minHeight: "200px",
  };
  const [profileCopy, setprofileCopy] = useState(profile);
  var isvalid = true;
  const [activeTabFC, setActiveTabFC] = useState(newTabCountry);
  const [activeTabSM, setActiveTabSM] = useState(newTabMeth);
  const [activeCostSM, setActiveCostSM] = useState("");
  const [modelState, setModalState] = useState(false);
  const [copyProfile, setCopyProfile] = useState({});
  const [spreadSheetData, setSpreadSheetData] = useState(null);
  const [showSpreadSheet, setShowSpreadSheet] = useState(false);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const [sideBarOpened, setsideBarOpened] = useState(false);
  const costingStatus = useSelector(({ app }) => app.costingStatus);
  const [tempData, setTempData] = useState(null);
  const [isModalOpen, handleOpen] = useState(false);
  const [waveModal, setwaveModal] = useState(false);
  const [validationHeader, setValidationHeader] = useState(
    "Please complete answering all mandatory fields before proceeding"
  );
  const [checkToggleSM, setCheckToggleSM] = useState(false)
  const oppValue = useSelector(

    ({ currentProject }) => currentProject.opportunityValue

  );
  const product = useSelector(

    ({ currentProject }) => currentProject.productValue

  );
  const [opsResourcesVariables] = useState([
    "surveyProgrammingResource",
    "surveyProgrammingResourceVendor",
    "dataProcessingResource",
    "dataProcessingResourceVendor",
    "dataProcessingComplexity",
    "translationsResource",
    "translationsResourceVendor",
    "verbatimCodingResource",
    "verbatimCodingResourceVendor",
    "chartingResource",
    "chartingResourceVendor",
    "dashboardingResource",
    "dashboardingResourceVendor",
    "qualityControl",
    "qualityControlComments",
    "dataCollectionPlatform",
    "platformComments",
  ]);
  const [modalMethodologiesState, setModalMethodologiesState] = useState(false);
  const [advanceCopying, setAdvanceCopying] = useState({});
  const [modalConfirmMethodology, setModalConfirmMethodology] = useState(false);
  let pacificData = _.head(codeLabels.PacificCountriesList).Label;
  const [pacificMarkets] = useState(pacificData?.split(","));
  const rateCards = useSelector(({ rateCards }) => rateCards);
  const [filteredMethodology,  setfilteredMethodology] = useState([]);
  const currentProject = useSelector(({ currentProject }) => currentProject.newProject);


  const modalCategoriesSingleCountry  = [
    {
      value: "CostingOptions",
      label: "Costing Options",
    },
    {
      value: "RFQSchema",
      label: "RFQ Form",
    },
    {
      value: "OpResourcesVariables",
      label: "Setup & Delivery",
    }
  ];

  const COPYING_METHOD_VALUES = Object.freeze({
    APPEND: "APPEND",
    OVERWRITE: "OVERWRITE"
  })
  const modalCategories = [
    ...modalCategoriesSingleCountry,
    {
      value: "Responsibilities",
      label: "Responsibilities",
    },
  ];

  const modalPacificCategoriesSingleCountry = [
    {
      value: "RFQSchema",
      label: "RFQ Form",
    },
    {
      value: "OpResourcesVariables",
      label: "Setup & Delivery",
    },
    {
      value: "CommercialHoursData",
      label: "Commercial Hours",
    },
  ]

  const modalPacificCategories = [
    ...modalPacificCategoriesSingleCountry,
    {
      value: "Responsibilities",
      label: "Responsibilities",
    },
  ];
  const [errorObj, setErrorObj] = useState({});
  const oppTemp = profile.ProductTypeData;
  const countrySpecsLength =  profile.CountrySpecs?.length

  const singleCountryMethodologySpecs = countrySpecsLength === 1  && 
  profile.CountrySpecs ?
  profile.CountrySpecs[0]?.MethodologySpecs[activeTabSM]
  .Label : ""

  const {current : changes} = useRef({})

  const hasDataChanged = (schemaData, source, indexFC = "", indexSM = "", indexCO = "") => {
    const id = `${source.id}_${indexFC}${indexSM}${indexCO}`;
    if (changes.hasOwnProperty(id)) {
      if (_.isEqual(changes[id], source.value)) {
        delete changes[id]
      }
    } else {
      changes[id] = schemaData && schemaData[source.id]
    }
    if (_.isEmpty(source.value) &&  _.isEmpty(schemaData && schemaData[source.id])) {
      delete changes[id]
    }
    return Object.keys(changes).length > 0
  }

  const deleteId = (id, indexFC = "", indexSM = "", indexCO = "") => {
    delete changes[`${id}_${indexFC}${indexSM}${indexCO}`]
  }

  function flagAlertoff() {
    setsideBarOpened(!sideBarOpened);
  }

  const validateForms = (_profile) => {
    let errorFieldsObj = {}; // object to store missing required fields - RFQ Form
    let costingProfile = _profile ? _profile : profile;
    let isValid = true;
    let countrySpecs = [...costingProfile.CountrySpecs];
    var allCostingOptionsUsed = [];
    let unavialable = [];
    let methodologyObj = {};
    let methodologyCountryObj = {};
    let isFieldsValid = true;
    let fieldingCountries = [];
    let isTrackingProjectNonPacific = {};

    if (Array.isArray(profile.FieldingCountries))
      fieldingCountries = profile.FieldingCountries.map((fc) => fc.value);
    else fieldingCountries = profile.FieldingCountries.split(",");

    countrySpecs.map((cs) => {
      if (cs.MethodologySpecs) {
        cs.MethodologySpecs?.filter((ms) => !ms.NotApplicable)?.map((ms) => {
          methodologyObj[ms.id] = ms.Label;
          methodologyCountryObj[ms.id] = cs.CountryName;
          ms.CostingOptions?.map((co) => {
            co.CostingOptionSchema?.map((cps) => {
              if (!_.includes(allCostingOptionsUsed, cps.id) && cps.id) {
                allCostingOptionsUsed.push(cps.id);
              }
            });
          });
          if (
            ms?.RFQSchema?.required &&
            _.includes(fieldingCountries, cs.CountryCode)
          ) {
            if (!ms.RFQData) {
              isValid = false;
              isFieldsValid = false;
              unavialable = ms.RFQSchema?.required;
            } else {
              let allRequiredFields = ms.RFQSchema?.required;
              if (
                (_.includes(pacificMarkets, project?.CommissioningCountry) && project.CostingTemplateVersion != "v2.0") &&
                _.includes(SHOPPERS_METHODOLOGIS, ms.Code) &&
                allRequiredFields?.length === 0
              ) {
                allRequiredFields = ms.Variables.filter(i => i.mandatory == true && !i.disabled).map(i => i.id)
              }
              if (
                ms.RFQData["externalSampleProvider"] &&
                ms.RFQData["externalSampleProvider"] != "Applicable"
              )
                allRequiredFields = allRequiredFields.filter(
                  (rf) => rf != "incidenceRate"
                );
              let existingProperties = Object.keys(
                ms.RFQSchema?.properties
              ).filter((f) => !ms.RFQSchema.properties[f].Default);

              let finalFields = allRequiredFields?.filter((arf) =>
                _.includes(existingProperties, arf)
              );
              unavialable = finalFields?.filter(
                (req) => req && (!ms.RFQData[req] || ms.RFQData[req] == "0")
              );

              if (
                (_.includes(pacificMarkets, project?.CommissioningCountry) && project.CostingTemplateVersion != "v2.0") &&
                ms.RFQData["trackingProject"] === "Yes" &&
                (!ms.RFQData["numberOfWaves"] ||
                  ms.RFQData["numberOfWaves"] < 2)
              ) {
                // unavialable.push("numberOfWaves");
              } else if (
                ms.CostingOptions.length &&
                ms.CostingOptions.find(
                  (co) => co.CostingOptionData?.trackingProject === "Yes"
                )
              ) {
                isTrackingProjectNonPacific[ms.id] = true;
              }

              let haveInCostingOptions = ms.CostingOptions?.map(
                (co) => co?.CostingOptionData
              )?.filter(
                (co) =>
                  finalFields
                    ?.map((ff) => co && co[ff] && co[ff] != 0)
                    .filter((f) => f).length
              );
              if (unavialable.length && !haveInCostingOptions.length) {
                isValid = false;
                isFieldsValid = false;
              }
            }
          }
          if (!isFieldsValid && _.includes(fieldingCountries, cs.CountryCode)) {
            let fields = unavialable.map((obj) =>
              obj === "numberOfWaves"
                ? ms.SetupSchema?.properties[obj]?.title
                : ms.RFQSchema.properties[obj]?.title
            );
            if (!errorFieldsObj[cs.CountryName])
              errorFieldsObj[cs.CountryName] = {};

            if (!errorFieldsObj[cs.CountryName].RFQ)
              errorFieldsObj[cs.CountryName].RFQ = {};

            if (!errorFieldsObj[cs.CountryName].RFQ[ms.Label])
              errorFieldsObj[cs.CountryName].RFQ[ms.Label] = {};

            errorFieldsObj[cs.CountryName].RFQ[ms.Label] = fields;
          }
          isFieldsValid = true;
        });

        if (!_.includes(pacificMarkets, project?.CommissioningCountry)||
        (_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0")
        ) {
          cs.MethodologySpecs?.filter((ms) => !ms.NotApplicable).forEach(
            (mc) => {
              if (mc) {
                mc.CostingOptions.forEach((me) => {
                  const mandate = me.Variables.filter(
                    (mt) => mt.mandatory == true && !mt.disabled
                  ).map((i) => i.label);
                  const dataValues = me.Variables.filter(
                    (mt) => mt.mandatory == true && !mt.disabled
                  );
                  const labelData = dataValues.filter((dt) => dt.label);

                  if (!errorFieldsObj[cs.CountryName])
                    errorFieldsObj[cs.CountryName] = {};

                  if (!errorFieldsObj[cs.CountryName].CostingOptions)
                    errorFieldsObj[cs.CountryName].CostingOptions = {};

                  if (!errorFieldsObj[cs.CountryName].CostingOptions[mc.Label])
                    errorFieldsObj[cs.CountryName].CostingOptions[mc.Label] =
                      {};

                  if(me.Selected === 'true' || me.Selected){
                    if (
                      Object.keys(me.CostingOptionData).length === 0 &&
                      mandate.length > 0
                    ) {
                      isValid = false;
                      errorFieldsObj[cs.CountryName].CostingOptions[mc.Label] =
                        mandate;
                    } else {
                      const filterData = dataValues.filter((x) => x.value && x?.value?.length !== 0 );
                      const unfilterData = dataValues
                        .filter((xy) => !xy.value || xy?.value?.length === 0)
                        .map((t) => t.label);
  
                      if (filterData.length !== dataValues.length) {
                        isValid = false;
                        errorFieldsObj[cs.CountryName].CostingOptions[mc.Label] =
                          unfilterData;
                      }
                    }
                  }
                });
              }
            }
          );
        }
      }
    });

    if (costingProfile?.WaveSpecs?.length) {
      let validMethIds = [];
      costingProfile.CountrySpecs?.map((cs) => {
        cs.MethodologySpecs?.filter((ms) => !ms.NotApplicable)
          ?.map((ms) => ms.id)
          ?.map((ms) => {
            if (!_.includes(validMethIds, ms)) validMethIds.push(ms);
          });
      });
      let wavespecs = [
        ...costingProfile.WaveSpecs?.filter((ws) =>
          _.includes(validMethIds, ws.MethodologySpecId)
        ),
      ];
      if (wavespecs.length) {
        wavespecs.map((ws) => {
          // ws = _.head(wavespecs)
          let finalPropsToValidate = [];
          ws.OpsResourcesSchema?.required?.map((prop) => {
            let currentProp = ws.OpsResourcesSchema.properties[prop];
            if (
              currentProp &&
              (!currentProp.dependentOn ||
              !currentProp.disabled) &&
              !currentProp.selected
            ) {
              if (
                prop === "numberofExcelFile" &&
                ws.OpsResourcesData &&
                ws.OpsResourcesData["numberofExcelFile"] > 0
              )
                return;
              if (
                prop === "numberOfModules" &&
                ws.OpsResourcesData &&
                ws.OpsResourcesData["numberOfModules"] >= 0
              )
              return;
              if (
                prop === "numberOfOpenEnded" &&                 
                ws.OpsResourcesData &&
                ws.OpsResourcesData["numberOfSemiOpenEnded"] >= 0
              )
              return;
              if (
                prop === "numberOfSemiOpenEnded" &&               
                ws.OpsResourcesData &&
                ws.OpsResourcesData["numberOfSemiOpenEnded"] >= 0
              )
                return;
                if (

                                  prop === "L2breakouts" &&
                  
                                  ws.OpsResourcesData &&
                  
                                  ws.OpsResourcesData["L2breakouts"] >= 0
                  
                                )
                  
                                  return;
                  
                                if (
                  
                                  prop === "L3breakouts" &&
                  
                                  ws.OpsResourcesData &&
                  
                                  ws.OpsResourcesData["L3breakouts"] >= 0
                  
                                )
                  
                                  return;
                if (
                  prop === "numberOfOpenEndedToCode" &&
                  ws.OpsResourcesData &&
                  ws.OpsResourcesData["codingRequired"] === "No"
                )
                  return;
              if (
                (!_.includes(opsResourcesVariables, prop) &&
                  !_.includes(allCostingOptionsUsed, prop)) ||
                (_.includes(opsResourcesVariables, prop) &&
                  userRecord.CanEditOpsResources)
              )
                if (
                  prop === "surveyProgrammingResource" &&
                  ws.OpsResourcesData &&
                  ws.OpsResourcesData["surveyProgrammingRequired"] === "No"
                )
                  //todo: not sure why it is been done in this way in CountryMethodologyTabsFinal
                  //copied the same, but need to be fixed in a proper way
                  return;

              if (
                _.includes(opsResourcesVariables, prop) &&
                !userRecord.CanEditOpsResources
              )
                return;
                if(!finalPropsToValidate.includes(prop)){
                  finalPropsToValidate.push(prop);
                }
            } else {
              if (
                !(currentProp?.dependentOn?.fields?.length > 1) &&
                currentProp &&
                !currentProp.disabled &&
                !currentProp.selected
              ) {
                 
                currentProp?.dependentOn?.fields?.map((_dependentField) => {
                  let depProp = _dependentField;
                  if (depProp) {
                    let parentProp = {};
                    Object.keys(ws.OpsResourcesSchema.properties).map((pp) => {
                      if (
                        ws.OpsResourcesSchema.properties[pp].title ==
                        depProp.fieldName
                      ) {
                        parentProp = pp;
                      }
                    });
                    if (
                      prop === "approxPercentageChange" &&
                      wavespecs.length < 2
                    )
                      return;
                    

                    if (
                      parentProp &&
                      ws.OpsResourcesData &&
                      ws.OpsResourcesData[parentProp] &&
                      ws.OpsResourcesData[parentProp] != "No" &&
                      !_.includes(finalPropsToValidate, prop)
                    ) {
                      if (
                        (!_.includes(opsResourcesVariables, prop) &&
                          !_.includes(allCostingOptionsUsed, prop)) ||
                        (_.includes(opsResourcesVariables, prop) &&
                          userRecord.CanEditOpsResources)
                      ) {
                        if(!finalPropsToValidate.includes(prop)){
                          finalPropsToValidate.push(prop);
                        }
                      }
                    }
                  }
                });
              }
            }
          });

          let finalErrorFields = [];
          
          const numberOfWaves = ws.OpsResourcesData["numberOfWaves"]
            ? ws.OpsResourcesData["numberOfWaves"]
            : 0;

            if (
              isTrackingProjectNonPacific[ws.MethodologySpecId] &&
              ws.OpsResourcesSchema?.properties?.["numberOfWaves"] &&
              numberOfWaves == 0
            ) {
              isValid = false;
              isFieldsValid = false;
              if(!finalErrorFields.includes(ws.OpsResourcesSchema?.properties?.["numberOfWaves"]?.title) 
                && finalPropsToValidate.includes("numberOfWaves")){
                finalErrorFields.push(
                  "Number of waves should be greater than 1"
                );
              }
            }
            if (
              isTrackingProjectNonPacific[ws.MethodologySpecId] &&
              ws.OpsResourcesSchema?.properties?.["numberOfWaves"] &&
              numberOfWaves == 1
            ) {
              isValid = false;
              isFieldsValid = false;
              if(!finalErrorFields.includes(ws.OpsResourcesSchema?.properties?.["numberOfWaves"]?.title) 
                && finalPropsToValidate.includes("numberOfWaves")){
                finalErrorFields.push(
                  "Number of waves should be greater than 1"
                );
              }
              
            }
          const numberOfLicenses = ws.OpsResourcesData["NumberOfLicenses"]
          ?ws.OpsResourcesData["NumberOfLicenses"]:0;

          if(  
            ws.OpsResourcesSchema?.properties?.["NumberOfLicenses"] && 
            numberOfLicenses>1000
            ){
            isValid=false;
            isFieldsValid=false;
            finalErrorFields.push(ws.OpsResourcesSchema?.properties?.["NumberOfLicenses"]?.title)
          
          }
          
          const numberOfModules = ws.OpsResourcesData["numberOfModules"]
          ?ws.OpsResourcesData["numberOfModules"]:0;

          if(  
            ws.OpsResourcesSchema?.properties?.["numberOfModules"]  && numberOfModules>999
            ){
            isValid=false;
            isFieldsValid=false;
            finalErrorFields.push(ws.OpsResourcesSchema?.properties?.["numberOfModules"]?.title) && 
            finalErrorFields.push(" - Value should be between " +ws.OpsResourcesSchema?.properties?.["numberOfModules"].minimum +"-" +ws.OpsResourcesSchema?.properties?.["numberOfModules"].maximum)
          
          }
          const numberOfDashboard = ws.OpsResourcesData["numberOfDashboard"]
          ?ws.OpsResourcesData["numberOfDashboard"]:0;

          if(  
            ws.OpsResourcesSchema?.properties?.["numberOfDashboard"] && 
            numberOfDashboard>120
            ){
            isValid=false;
            isFieldsValid=false;
            finalErrorFields.push(ws.OpsResourcesSchema?.properties?.["numberOfDashboard"]?.title) &&  finalErrorFields.push(ws.OpsResourcesSchema?.properties?.["numberOfDashboard"]?.message)   
          }
          const L2Breakouts = ws.OpsResourcesData["L2breakouts"]

            ? ws.OpsResourcesData["L2breakouts"]

            : 0;




          if (

            ws.OpsResourcesSchema?.properties?.["L2breakouts"] &&

            L2Breakouts > 100

          ) {

            isValid = false;

            isFieldsValid = false;

            finalErrorFields.push(

              ws.OpsResourcesSchema?.properties?.["L2breakouts"]?.title

            );

          }

          const L3Breakouts = ws.OpsResourcesData["L3breakouts"]

            ? ws.OpsResourcesData["L3breakouts"]

            : 0;




          if (

            ws.OpsResourcesSchema?.properties?.["L3breakouts"] &&

            L3Breakouts > 100

          ) {

            isValid = false;

            isFieldsValid = false;

            finalErrorFields.push(

              ws.OpsResourcesSchema?.properties?.["L3breakouts"]?.title

            );

          }
          finalPropsToValidate.map((ftv) => {
            if (
              !ws.OpsResourcesData ||
              (ws.OpsResourcesData &&
                (!ws.OpsResourcesData[ftv] || ws.OpsResourcesData[ftv] == "0"))
            ) {
              let excludeError = [
                // "numberOfOpenEnded",
                // "numberOfSemiOpenEnded",
                // "numberOfOpenEndedToCode",
                "numberOfWaves"
              ];
              
              if (!excludeError.includes(ftv)) {
                isValid = false;              
                isFieldsValid = false;              
                finalErrorFields.push(ws.OpsResourcesSchema.properties[ftv].title);
              }
            }
          });
          if (!isFieldsValid) {
            if (!errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]])
              errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]] = {};
			  
            if (!errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD)
              errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD =
                {};

            if (
              !errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                methodologyObj[ws.MethodologySpecId]
              ]
            )
              errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                methodologyObj[ws.MethodologySpecId]
              ] = {};

              if (_.includes(pacificMarkets, project.CommissioningCountry) && project.CostingTemplateVersion != "v2.0") {
                if (
                  !errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                    methodologyObj[ws.MethodologySpecId]
                  ].NO
                )
                  errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                    methodologyObj[ws.MethodologySpecId]
                  ].NO = {};
  
                if (
                  !errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                    methodologyObj[ws.MethodologySpecId]
                  ].NO[ws.WaveNumber]
                )
                  errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                    methodologyObj[ws.MethodologySpecId]
                  ].NO[ws.WaveNumber] = {};
  
                errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                  methodologyObj[ws.MethodologySpecId]
                ].NO[ws.WaveNumber] = finalErrorFields;
              } else {
                errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                  methodologyObj[ws.MethodologySpecId]
                ] = finalErrorFields;
              }
          }
          isFieldsValid = true;
        });
      }
    }

    if (!isValid) {
      if (
        document.getElementById("RFQFinalForm") ||
        document.getElementById("CostiongOptionsForm")
      ) {
        document.getElementById("RFQFinalForm")?.requestSubmit();
        document.getElementById("OpsResourcesFinalForm")?.requestSubmit();
        document.getElementById("CostiongOptionsForm")?.requestSubmit();
      }
      setErrorObj(errorFieldsObj);
      handleOpen(true);
    }
    return isValid;
  };
  const MyComponent = () => (
    <>
      <Prompt
        when={Boolean(
          shouldBlockNavigation &&
            !sideBarOpened &&
            costingStatus.showCostingInput
        )}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      {/* Component JSX */}
    </>
  );
  useEffect(() => {
    currentCosting = store.getState().currentCosting.currentCostingProfile;
    console.log("updatedProfile", currentCosting);

  }, []);
  useEffect(() => {
    if (_.isEqual(profileCopy, profile)) {
      setShouldBlockNavigation(false);
    } else {
      setShouldBlockNavigation(true);
    }
    filteredSingleCountryMethodology(activeTabSM)

  }, [profile]);

  useEffect(() => {
    
    if (costingStatus.showCostingInput) dispatch(sideBarActions.hideSidebar());
  }, [costingStatus]);

  useEffect(() => {
    if (
      countrySpecsLength &&
      _.head(profile.CountrySpecs)?.MethodologySpecs?.length
    )
      populateSideBarLinks(
        _.head(_.head(profile.CountrySpecs).MethodologySpecs)?.Variables
      );
  }, [profile.CountrySpecs]);
  useEffect(() => {
    if (!tempData) return;
    let cloneSpreadsheetData = _.cloneDeep(spreadSheetData);
    if (tempData.type == "spreadsheet") {
      cloneSpreadsheetData[tempData.index].options.data[tempData.rowIndex][
        tempData.key
      ] = tempData.value;
      if (tempData.fKey)
        cloneSpreadsheetData[tempData.index].options.data[tempData.rowIndex][
          tempData.fKey
        ] = tempData.fValue;
    } else if (tempData.type == "mainstats") {
      cloneSpreadsheetData[tempData.index].options.mainStatsData[
        tempData.rowIndex
      ].data[tempData.colIndex - 1].value = tempData.value;
    }
    setSpreadSheetData(cloneSpreadsheetData);
  }, [tempData]);

  function populateSideBarLinks(variables) {
    if (variables) {
      dispatch(
        sideBarActions.addCostingOptionsLink(
          "#costing_options_card_" + activeTabFC + "_" + activeTabSM
        )
      );
      dispatch(
        sideBarActions.addSudOptionsLink(
          "#sud_options_card_" + activeTabFC + "_" + activeTabSM
        )
      );

      if (profile.CountrySpecs.length > 1)
        dispatch(
          sideBarActions.resOptionsLink(
            "#res_options_card_" + activeTabFC + "_" + activeTabSM
          )
        );
      else dispatch(sideBarActions.resOptionsLink(""));

      let links = [];
      variables.forEach((x, index) => {
        if (x.isNewSection)
          links.push({ label: x.sectionTitle, value: "#header_" + index });
      });
      dispatch(sideBarActions.addSidebarLinks(links));
    }
  }
  useEffect(() => {
    if (profile.CountrySpecs && profile.CountrySpecs.length > 0) {
      let allMeth = _.head(profile.CountrySpecs)?.MethodologySpecs?.map(
        (m, mIndex) => ({
          methodology: m,
          index: mIndex,
        })
      );
      let reqMeth = allMeth.find((x) => !x.methodology.NotApplicable);
      if (!reqMeth) reqMeth = _.head(allMeth);
      if(activeTabSM === 0){
        setActiveTabSM(reqMeth.index);
      }
    }
  }, []);

  const filteredSingleCountryMethodology = (tab) => {
    const  {CountrySpecs} = profile

    if(CountrySpecs?.length) {
      const methodologySpecsLength = CountrySpecs[0]?.MethodologySpecs.length > 1;

      const newDataSetup = []
      if(methodologySpecsLength) {
        CountrySpecs[0].MethodologySpecs.map((mData, mIndex) => {
          if(!mData.NotApplicable) {
            newDataSetup.push(fieldsFiltered(mData))
          }
        })
      }
      setfilteredMethodology(newDataSetup)
    }
  }
  const gotoRFQ = ()=>{
    let loc = window.location.hash
    if(loc == "#/costing"){
      handleOpen(!isModalOpen)
    }
    else{
      history.push("/costing")
    }
    console.log("location",window.location,window)
  }

  const fieldsFiltered = (arrData) => {

    const costingOption = (!pacificMarkets.includes(profile.CountrySpecs[0].CountryCode) ||
    (_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0")
    ) ? 
    arrData?.CostingOptions[0]?.Variables?.map((cItem, cIndex) => cItem.label) : []
    const rfqData = arrData?.Variables?.filter((cItem) => !cItem.Default).map((dataItem, cIndex) => dataItem.label)
    const setupAndDelivery = arrData?.OpResourcesVariables?.filter((cItem) => !cItem.disabled).map((dataItem, cIndex) => dataItem.label)
    return {
      costingOption : costingOption, 
      rfqData :rfqData , 
      setupAndDelivery: setupAndDelivery, 
      name:arrData.Label  
    }
  }

  function toggle(tab) {
    if (activeTabFC !== tab) {
      setActiveTabFC(tab);
      let allMeth = profile.CountrySpecs[tab].MethodologySpecs.map(
        (m, mIndex) => ({
          methodology: m,
          index: mIndex,
        })
      );
      let reqMeth = allMeth.find((x) => !x.methodology.NotApplicable);
      if (!reqMeth) reqMeth = _.head(allMeth);
      if(reqMeth.index === activeTabSM && !checkToggleSM){
        setActiveTabSM(reqMeth.index);
        setCheckToggleSM(false);
      } else if(reqMeth.index !== activeTabSM && checkToggleSM){
        setActiveTabSM(activeTabSM);
        // setCheckToggleSM(false);
      } else if(reqMeth.index !== activeTabSM && !checkToggleSM){
        setActiveTabSM(0);
      }
    }
    populateSideBarLinks(
      profile.CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]
        ?.Variables
    );
  }

  function toggleSM(tab) {
    if (activeTabSM !== tab) {
      setActiveTabSM(tab);
      filteredSingleCountryMethodology(tab)
    }
    populateSideBarLinks(
      profile.CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]
        ?.Variables
    );
  }
  function toggleCostingInput(tab) {
    if (activeCostSM !== tab) {
      setActiveCostSM(tab);
    }
  }

  function CopyClicked(indxFC, indxSM) {
    const Countries = _.cloneDeep(
      profile.CountrySpecs.map((fc) => ({
        label: fc.label,
        value: fc.id,
        ColSpan: null,
      }))
    );
    const CostingOptions = _.cloneDeep(
      profile.CountrySpecs[indxFC].MethodologySpecs[indxSM].CostingOptions
    );
    setCopyProfile({
      Countries: Countries,
      CostingOptions: CostingOptions,
      Mode: "Selection",
      indexFC: indxFC,
      indexSM: indxSM,
    });
    setModalState(true);
  }

  function CopyAsIsClicked() {
    const methodologyName =
      profile.CountrySpecs[copyProfile.indexFC].MethodologySpecs[
        copyProfile.indexSM
      ].label;
    const Countries = _.cloneDeep(
      profile.CountrySpecs.filter(
        (x, index) =>
          index != copyProfile.indexFC &&
          x.MethodologySpecs.find((y) => y.label == methodologyName)
      ).map((fc) => ({ label: fc.label, value: fc.id }))
    );

    setCopyProfile((prevState) => ({
      ...prevState,
      Mode: "CopyAsIs",
      SelectedCostingOptions: _.cloneDeep(copyProfile.CostingOptions),
      SelectedCountries: _.cloneDeep(Countries),
    }));
  }
  const updateProjectAndProfileWRTCostingOption = (
    profile,
    tempCountrySpecs,
    notReload
  ) => {
    let profileTemp = _.cloneDeep(profile);
    profileTemp.CountrySpecs = _.cloneDeep(tempCountrySpecs);

    const tempProfiles = project.CostingProfiles.map((cp) => {
      if (cp.id == profileTemp.id) return { ..._.cloneDeep(profileTemp) };
      return { ..._.cloneDeep(cp) };
    });

    dispatch(currentCostingActions.updateProfile(profileTemp));
    if (!notReload) {
      let tempReloadForms = project.ReloadForms == true ? false : true;
      dispatch(
        updateProject({
          CostingProfiles: tempProfiles,
          ReloadForms: tempReloadForms,
        })
      );
    } else {
      dispatch(updateProject({ CostingProfiles: tempProfiles }));
    }
  };
  function CopyProfileActionClicked(back = null) {
    if (back && (copyProfile.Mode == "First" || copyProfile.Mode == "CopyAsIs"))
      setCopyProfile((prevState) => ({
        ...prevState,
        Mode: "Selection",
      }));
    else if (back && copyProfile.Mode == "Second")
      setCopyProfile((prevState) => ({
        ...prevState,
        Mode: "First",
      }));
    else if (back && copyProfile.Mode == "Save")
      setCopyProfile((prevState) => ({
        ...prevState,
        Mode: "Second",
      }));
    else if (copyProfile.Mode == "First") {
      if (
        !copyProfile.SelectedCostingOptions ||
        !copyProfile.SelectedCountries
      ) {
        toastr.error("Error", "Please select required options");
        return;
      }
      let tempCountryWiseCostingOptions = [];
      copyProfile.SelectedCostingOptions.forEach((co, indexCO) => {
        copyProfile.SelectedCountries.forEach((country, indexCountry) => {
          const tempMethodologies = _.cloneDeep(
            profile.CountrySpecs.filter(
              (fc) => fc.id == country.value
            )[0].MethodologySpecs.map((m) => ({
              label: m.label,
              value: m.Code,
            }))
          );

          tempCountryWiseCostingOptions.push({
            CostingOption: co,
            Country: country,
            Methodologies: tempMethodologies,
          });
        });
      });
      setCopyProfile((prevState) => ({
        ...prevState,
        CountryWiseCostingOptions: tempCountryWiseCostingOptions,
        Mode: "Second",
      }));
    } else if (copyProfile.Mode == "Second") {
      let error2 = null;
      const label1 =
        profile.CountrySpecs[copyProfile.indexFC].MethodologySpecs[
          copyProfile.indexSM
        ].label;

      if (
        label1 != "Path Tracking" &&
        label1 != "Eye Tracking" &&
        label1 != "Smart Store VR"
      ) {
        copyProfile.CountryWiseCostingOptions.forEach((x) =>
          x.SelectedMethodologies.map((m) => {
            if (
              m.label == "Path Tracking" ||
              m.label == "Eye Tracking" ||
              m.label == "Smart Store VR"
            ) {
              error2 = true;
            }
          })
        );
      }

      if (error2) {
        toastr.error(
          "Error",
          "This Methodology Can not be Copied to Path Tracking,Eye Tracking,Smart Store VR"
        );
        return;
      }

      let error = null;
      copyProfile.CountryWiseCostingOptions.forEach((x) => {
        if (!x.SelectedMethodologies) error = true;
      });
      if (error) {
        toastr.error("Error", "Please select required options");
        return;
      }

      let srcCountry = profile.CountrySpecs[copyProfile.indexFC];
      let SelectedCountries = _.cloneDeep(copyProfile.SelectedCountries);
      //             copyProfile.CountryWiseCostingOptions.forEach((country, indexC) => {
      //                 let tempMethodologies = [];
      //                 let colSpanCountry = 0;
      //                 country.SelectedMethodologies.forEach((sm,indexM)=>
      //                 {
      //                     let objMethodology = _.cloneDeep(sm);
      //                     objMethodology["CostingOptions"] = [];
      //                     objMethodology["ColSpan"] = null;
      //                     let variables=[];

      //                     let destCostingOptions = _.cloneDeep(profile.CountrySpecs.filter(c=>c.id==country.Country.value)[0].MethodologySpecs.filter(m=> m.Code==sm.value)[0].CostingOptions[0]);
      //                     let cloneCostingOptions = _.cloneDeep(country.CostingOption);
      //                     cloneCostingOptions.Variables.forEach(sOption=>{
      //                         if(destCostingOptions.Variables.find(dOption=> dOption==sOption))
      //                         objMethodology["CostingOptions"].push(sOption);
      //                     } )
      //                     objMethodology["ColSpan"] = objMethodology["CostingOptions"].length;
      //                     tempMethodologies.push(objMethodology);
      //                     colSpanCountry += objMethodology["CostingOptions"].length;
      //                 }
      //                 )
      //                 country.Methodologies = tempMethodologies;
      //                 country["ColSpan"] = colSpanCountry;
      //             });

      SelectedCountries.forEach((country, indexC) => {
        let tempMethodologies = [];
        let colSpanCountry = 0;

        copyProfile.CountryWiseCostingOptions.filter(
          (cwco, indexCWCO) => cwco.Country.value == country.value
        ).forEach((fcwco, indexFCWCO) => {
          fcwco.SelectedMethodologies.forEach((methodology, indexM) => {
            //let destMethodology=_.cloneDeep(cwco);
            if (!tempMethodologies.find((m) => m.value == methodology.value)) {
              let objMethodology = _.cloneDeep(methodology);
              objMethodology["CostingOptions"] = [];
              objMethodology["ColSpan"] = null;
              copyProfile.CountryWiseCostingOptions.filter(
                (cwco_2, indexCWCO_2) =>
                  cwco_2.Country.value == country.value &&
                  cwco_2.SelectedMethodologies.find(
                    (m_2) => m_2.value == objMethodology.value
                  )
              ).forEach((fcwco_2, indexFCWCO_2) => {
                let destCostingOptions = _.cloneDeep(
                  profile.CountrySpecs.filter(
                    (c) => c.id == country.value
                  )[0].MethodologySpecs.filter(
                    (m) => m.Code == methodology.value
                  )[0].CostingOptions[0].Variables
                );
                let cloneCostingOptions = _.cloneDeep(fcwco_2.CostingOption);
                cloneCostingOptions.Variables.forEach((cVar) => {
                  if (
                    !destCostingOptions.find((dVar) => dVar.label == cVar.label)
                  ) {
                    // Display the CostingOptions
                    // cVar.disabled = true;
                    // cVar.ishidden = true;
                    // cVar.value = null;
                  } else if (
                    destCostingOptions.find(
                      (dVar) =>
                        dVar.label == cVar.label &&
                        JSON.stringify(cVar.options) !=
                          JSON.stringify(dVar.options)
                    )
                  ) {
                    // cVar.value = null;
                    cVar.options = destCostingOptions.filter(
                      (dVar) =>
                        dVar.label == cVar.label && cVar.options != dVar.options
                    )[0].options;
                  }
                });

                if (
                  country.label != srcCountry.label &&
                  cloneCostingOptions.Variables.find(
                    (cov) => cov.id == "citiesCoverage"
                  )
                ) {
                  cloneCostingOptions.Variables.find(
                    (cov) => cov.id == "citiesCoverage"
                  ).value = null;
                }

                objMethodology["CostingOptions"].push(cloneCostingOptions);
              });

              objMethodology["ColSpan"] =
                objMethodology["CostingOptions"].length;
              tempMethodologies.push(objMethodology);
              colSpanCountry += objMethodology["CostingOptions"].length;
            }
          });
        });
        country.Methodologies = tempMethodologies;
        country["ColSpan"] = colSpanCountry;
      });

      setCopyProfile((prevState) => ({
        ...prevState,
        SelectedCountries: SelectedCountries,
        Mode: "Save",
      }));
    } else if (copyProfile.Mode == "Save") {
      copyProfile.SelectedCountries.map((m) => {
        if (
          profile.CountrySpecs[copyProfile.indexFC].MethodologySpecs[
            copyProfile.indexSM
          ].label == "Path Tracking"
        ) {
          if (
            m.Methodologies.length == 1 &&
            m.Methodologies[0].label == "Path Tracking"
          ) {
            const tempCountrySpecs = _.cloneDeep(profile.CountrySpecs);
            copyProfile.SelectedCountries.forEach((sc, indexSC) => {
              sc.Methodologies.forEach((m, indexM) => {
                m.CostingOptions.forEach((co, indexCO) => {
                  const tempMethodology = tempCountrySpecs
                    .filter((fc) => fc.label == sc.label)[0]
                    .MethodologySpecs.filter((sm) => sm.label == m.label)[0];
                  const tempCostingOptions = tempCountrySpecs
                    .filter((fc) => fc.label == sc.label)[0]
                    .MethodologySpecs.filter(
                      (sm) => sm.label == m.label
                    )[0].CostingOptions;
                  const tempIndexCO = tempCostingOptions.findIndex(
                    (tempCO) => tempCO.label == co.label
                  );
                  co.Variables.forEach((v, indexV) => {
                    if (!v.Default) {
                      let found = false;
                      if (
                        tempMethodology.Variables.filter((mv) => mv.id == v.id)
                          .length > 0
                      ) {
                        found = true;
                        let objVar = tempMethodology.Variables.filter(
                          (mv) => mv.id == v.id
                        )[0];
                        objVar.selected = true;
                        objVar.value = null;
                      } else if (
                        tempMethodology.OpResourcesVariables.filter(
                          (mv) =>
                            mv.id == v.id ||
                            v.label == "RFQ Form" ||
                            v.label == "Setup & Delivery"
                        ).length > 0
                      ) {
                        found = true;
                        let objOpsVar =
                          tempMethodology.OpResourcesVariables.filter(
                            (mv) =>
                              mv.id == v.id ||
                              v.label == "RFQ Form" ||
                              v.label == "Setup & Delivery"
                          )[0];
                        objOpsVar.selected = true;
                        objOpsVar.value = null;
                      }
                      if (!found) v.NotFound = true;
                    }
                  });
                  co.Variables = co.Variables.filter((v) => v.NotFound != true);
                  if (tempIndexCO >= 0) {
                    const objCO = tempCostingOptions[tempIndexCO];
                    co.Variables.forEach((v, indexV) => {
                      const indexObjV = objCO.Variables.findIndex(
                        (objV) => objV.label == v.label
                      );
                      if (indexObjV >= 0)
                        objCO.Variables[indexObjV].value = v.value;
                      else if (v.label != "Sample Source") {
                        objCO.Variables.push(_.cloneDeep(v));
                      }
                    });
                  } else {
                    let objCO = _.cloneDeep(co);
                    delete objCO.id;
                    tempCostingOptions.push(objCO);
                  }
                  let deletedIndex = [];
                  tempCostingOptions.forEach((delco, decoindex) => {
                    let tempDelete = delco.Variables.filter(
                      (xo) => !xo.value
                    ).length;
                    if (tempDelete == delco.Variables.length)
                      deletedIndex.push(decoindex);
                    //delco.active=false;
                  });
                  let deletedIndexSort = deletedIndex.sort(function (a, b) {
                    return b - a;
                  });
                  deletedIndexSort.forEach((element) => {
                    tempCostingOptions.splice(element, 1);
                  });
                });
              });
            });
            // let profileTemp = update(profile, {
            //     CountrySpecs: { $set: tempCountrySpecs }
            // });
            updateProjectAndProfileWRTCostingOption(profile, tempCountrySpecs);
            setModalState(false);
            toastr.success("Success", "Data Copied Successfully");
          } else {
            toastr.error(
              "Error",
              "Can Not Copy Path Tracking to Other Methodology "
            );
            return;
          }
        } else if (
          profile.CountrySpecs[copyProfile.indexFC].MethodologySpecs[
            copyProfile.indexSM
          ].label == "Eye Tracking"
        ) {
          if (
            m.Methodologies.length == 1 &&
            m.Methodologies[0].label == "Eye Tracking"
          ) {
            const tempCountrySpecs = _.cloneDeep(profile.CountrySpecs);
            copyProfile.SelectedCountries.forEach((sc, indexSC) => {
              sc.Methodologies.forEach((m, indexM) => {
                m.CostingOptions.forEach((co, indexCO) => {
                  const tempMethodology = tempCountrySpecs
                    .filter((fc) => fc.label == sc.label)[0]
                    .MethodologySpecs.filter((sm) => sm.label == m.label)[0];
                  const tempCostingOptions = tempCountrySpecs
                    .filter((fc) => fc.label == sc.label)[0]
                    .MethodologySpecs.filter(
                      (sm) => sm.label == m.label
                    )[0].CostingOptions;
                  const tempIndexCO = tempCostingOptions.findIndex(
                    (tempCO) => tempCO.label == co.label
                  );
                  co.Variables.forEach((v, indexV) => {
                    if (!v.Default) {
                      let found = false;
                      if (
                        tempMethodology.Variables.filter((mv) => mv.id == v.id)
                          .length > 0
                      ) {
                        found = true;
                        let objVar = tempMethodology.Variables.filter(
                          (mv) => mv.id == v.id
                        )[0];
                        objVar.selected = true;
                        objVar.value = null;
                      } else if (
                        tempMethodology.OpResourcesVariables.filter(
                          (mv) =>
                            mv.id == v.id ||
                            v.label == "RFQ Form" ||
                            v.label == "Setup & Delivery"
                        ).length > 0
                      ) {
                        found = true;
                        let objOpsVar =
                          tempMethodology.OpResourcesVariables.filter(
                            (mv) =>
                              mv.id == v.id ||
                              v.label == "RFQ Form" ||
                              v.label == "Setup & Delivery"
                          )[0];
                        objOpsVar.selected = true;
                        objOpsVar.value = null;
                      }
                      if (!found) v.NotFound = true;
                    }
                  });
                  co.Variables = co.Variables.filter((v) => v.NotFound != true);
                  if (tempIndexCO >= 0) {
                    const objCO = tempCostingOptions[tempIndexCO];
                    co.Variables.forEach((v, indexV) => {
                      const indexObjV = objCO.Variables.findIndex(
                        (objV) => objV.label == v.label
                      );
                      if (indexObjV >= 0)
                        objCO.Variables[indexObjV].value = v.value;
                      else if (v.label != "Sample Source") {
                        objCO.Variables.push(_.cloneDeep(v));
                      }
                    });
                  } else {
                    let objCO = _.cloneDeep(co);
                    delete objCO.id;
                    tempCostingOptions.push(objCO);
                  }
                  let deletedIndex = [];
                  tempCostingOptions.forEach((delco, decoindex) => {
                    let tempDelete = delco.Variables.filter(
                      (xo) => !xo.value
                    ).length;
                    if (tempDelete == delco.Variables.length)
                      deletedIndex.push(decoindex);
                    //delco.active=false;
                  });
                  let deletedIndexSort = deletedIndex.sort(function (a, b) {
                    return b - a;
                  });
                  deletedIndexSort.forEach((element) => {
                    tempCostingOptions.splice(element, 1);
                  });
                });
              });
            });
            updateProjectAndProfileWRTCostingOption(profile, tempCountrySpecs);
            setModalState(false);
            toastr.success("Success", "Data Copied Successfully");
          } else {
            toastr.error(
              "Error",
              "Can Not Copy Eye Tracking to Other Methodology"
            );
            return;
          }
        } else if (
          profile.CountrySpecs[copyProfile.indexFC].MethodologySpecs[
            copyProfile.indexSM
          ].label == "Smart Store VR"
        ) {
          if (
            m.Methodologies.length == 1 &&
            m.Methodologies[0].label == "Smart Store VR"
          ) {
            const tempCountrySpecs = _.cloneDeep(profile.CountrySpecs);
            copyProfile.SelectedCountries.forEach((sc, indexSC) => {
              sc.Methodologies.forEach((m, indexM) => {
                m.CostingOptions.forEach((co, indexCO) => {
                  const tempMethodology = tempCountrySpecs
                    .filter((fc) => fc.label == sc.label)[0]
                    .MethodologySpecs.filter((sm) => sm.label == m.label)[0];
                  const tempCostingOptions = tempCountrySpecs
                    .filter((fc) => fc.label == sc.label)[0]
                    .MethodologySpecs.filter(
                      (sm) => sm.label == m.label
                    )[0].CostingOptions;
                  const tempIndexCO = tempCostingOptions.findIndex(
                    (tempCO) => tempCO.label == co.label
                  );
                  co.Variables.forEach((v, indexV) => {
                    if (!v.Default) {
                      let found = false;
                      if (
                        tempMethodology.Variables.filter((mv) => mv.id == v.id)
                          .length > 0
                      ) {
                        found = true;
                        let objVar = tempMethodology.Variables.filter(
                          (mv) => mv.id == v.id
                        )[0];
                        objVar.selected = true;
                        objVar.value = null;
                      } else if (
                        tempMethodology.OpResourcesVariables.filter(
                          (mv) =>
                            mv.id == v.id ||
                            v.label == "RFQ Form" ||
                            v.label == "Setup & Delivery"
                        ).length > 0
                      ) {
                        found = true;
                        let objOpsVar =
                          tempMethodology.OpResourcesVariables.filter(
                            (mv) =>
                              mv.id == v.id ||
                              v.label == "RFQ Form" ||
                              v.label == "Setup & Delivery"
                          )[0];
                        objOpsVar.selected = true;
                        objOpsVar.value = null;
                      }
                      if (!found) v.NotFound = true;
                    }
                  });
                  co.Variables = co.Variables.filter((v) => v.NotFound != true);
                  if (tempIndexCO >= 0) {
                    const objCO = tempCostingOptions[tempIndexCO];
                    co.Variables.forEach((v, indexV) => {
                      const indexObjV = objCO.Variables.findIndex(
                        (objV) => objV.label == v.label
                      );
                      if (indexObjV >= 0)
                        objCO.Variables[indexObjV].value = v.value;
                      else if (v.label != "Sample Source") {
                        objCO.Variables.push(_.cloneDeep(v));
                      }
                    });
                  } else {
                    let objCO = _.cloneDeep(co);
                    delete objCO.id;
                    tempCostingOptions.push(objCO);
                  }
                  let deletedIndex = [];
                  tempCostingOptions.forEach((delco, decoindex) => {
                    let tempDelete = delco.Variables.filter(
                      (xo) => !xo.value
                    ).length;
                    if (tempDelete == delco.Variables.length)
                      deletedIndex.push(decoindex);
                    //delco.active=false;
                  });
                  let deletedIndexSort = deletedIndex.sort(function (a, b) {
                    return b - a;
                  });
                  deletedIndexSort.forEach((element) => {
                    tempCostingOptions.splice(element, 1);
                  });
                });
              });
            });
            updateProjectAndProfileWRTCostingOption(profile, tempCountrySpecs);
            setModalState(false);
            toastr.success("Success", "Data Copied Successfully");
          } else {
            toastr.error(
              "Error",
              "Can Not Copy Smart Store to Other Methodology"
            );
            return;
          }
        } else {
          const tempCountrySpecs = _.cloneDeep(profile.CountrySpecs);
          let _fieldingCountries = profile.FieldingCountries;
          if (_fieldingCountries && Array.isArray(_fieldingCountries))
            _fieldingCountries = _fieldingCountries.map((fc) => fc.value);
          else if (_fieldingCountries)
            _fieldingCountries = _fieldingCountries.split(",");
          copyProfile.SelectedCountries.forEach((sc, indexSC) => {
            const cCode = profile.CountrySpecs.filter(
              (cs) => cs.id == sc.value
            )[0].CountryCode;
            if (_.includes(_fieldingCountries, cCode)) {
              sc.Methodologies.forEach((m, indexM) => {
                m.CostingOptions.forEach((co, indexCO) => {
                  const tempMethodology = tempCountrySpecs
                    .filter((fc) => fc.label == sc.label)[0]
                    .MethodologySpecs.filter((sm) => sm.label == m.label)[0];
                  const tempCostingOptions = tempCountrySpecs
                    .filter((fc) => fc.label == sc.label)[0]
                    .MethodologySpecs.filter(
                      (sm) => sm.label == m.label
                    )[0].CostingOptions;
                  const tempIndexCO = tempCostingOptions.findIndex(
                    (tempCO) => tempCO.label == co.label
                  );
                  co.Variables.forEach((v, indexV) => {
                    let found = false;
                    if (
                      tempMethodology.Variables.filter((mv) => mv.id == v.id)
                        .length > 0
                    ) {
                      found = true;
                      let objVar = tempMethodology.Variables.filter(
                        (mv) => mv.id == v.id
                      )[0];
                      objVar.selected = true;
                      objVar.value = null;
                    } else if (
                      tempMethodology.OpResourcesVariables.filter(
                        (mv) =>
                          mv.id == v.id ||
                          v.label == "RFQ Form" ||
                          v.label == "Setup & Delivery"
                      ).length > 0
                    ) {
                      found = true;
                      let objOpsVar =
                        tempMethodology.OpResourcesVariables.filter(
                          (mv) =>
                            mv.id == v.id ||
                            v.label == "RFQ Form" ||
                            v.label == "Setup & Delivery"
                        )[0];
                      objOpsVar.selected = true;
                      objOpsVar.value = null;
                    }
                    if (
                      !found &&
                      !_.includes(
                        tempMethodology.Variables.filter(
                          (vt) => vt.Default
                        ).map((vt) => vt.id),
                        v.id
                      )
                    )
                      v.NotFound = true;
                  });
                  co.Variables = co.Variables.filter((v) => v.NotFound != true);
                  tempMethodology.Variables.filter((v) => v.Default).map(
                    (coVar) => {
                      if (
                        !_.includes(
                          co.Variables.map((co) => co.id),
                          coVar.id
                        )
                      ) {
                        coVar.selected = true;
                        coVar.value = null;
                        co.Variables.push(coVar);
                      }
                    }
                  );
                  if (tempIndexCO >= 0) {
                    const objCO = tempCostingOptions[tempIndexCO];
                    co.Variables.forEach((v, indexV) => {
                      const indexObjV = objCO.Variables.findIndex(
                        (objV) => objV.label == v.label
                      );
                      if (indexObjV >= 0) {
                        if (profile.CountrySpecs[0].CountryCode === cCode) {
                          if (
                            v.id === "CommericalLocalsu" ||
                            v.id === "operationalpm"
                          )
                            objCO.Variables[indexObjV].value = "Yes";
                          else objCO.Variables[indexObjV].value = v.value;
                        } else {
                          objCO.Variables[indexObjV].value = v.value;
                        }
                      } else if (v.label != "Sample Source") {
                        objCO.Variables.push(_.cloneDeep(v));
                      }
                    });
                  } else {
                    let objCO = _.cloneDeep(co);
                    delete objCO.id;
                    tempCostingOptions.push(objCO);
                  }
                  let deletedIndex = [];
                  tempCostingOptions.forEach((delco, decoindex) => {
                    let tempDelete = delco.Variables.filter(
                      (xo) => !xo.value
                    ).length;
                    if (tempDelete == delco.Variables.length)
                      deletedIndex.push(decoindex);
                    //delco.active=false;
                  });
                  let deletedIndexSort = deletedIndex.sort(function (a, b) {
                    return b - a;
                  });
                  deletedIndexSort.forEach((element) => {
                    tempCostingOptions.splice(element, 1);
                  });
                });
              });
            }
          });
          updateProjectAndProfileWRTCostingOption(profile, tempCountrySpecs);
          setModalState(false);
          toastr.success("Success", "Data Copied Successfully");
        }
      });
    } else if (copyProfile.Mode == "CopyAsIs") {
      if (
        !copyProfile.SelectedCountries ||
        !copyProfile.SelectedCostingOptions
      ) {
        toastr.error("Error", "Please select required options");
        return;
      }
      const tempFC = _.cloneDeep(profile.CountrySpecs);
      const methodology =
        profile.CountrySpecs[copyProfile.indexFC].MethodologySpecs[
          copyProfile.indexSM
        ];

      let _fieldingCountries = profile.FieldingCountries;
      if (_fieldingCountries && Array.isArray(_fieldingCountries))
        _fieldingCountries = _fieldingCountries.map((fc) => fc.value);
      else if (_fieldingCountries)
        _fieldingCountries = _fieldingCountries.split(",");

      copyProfile.SelectedCountries.forEach((sc, indexSC) => {
        const cCode = profile.CountrySpecs.filter((cs) => cs.id == sc.value)[0]
          .CountryCode;
        profile.CountrySpecs.filter((x) => x.label == sc.label)[0]
          .MethodologySpecs.filter((x) => x.label == methodology.label)
          .forEach((m, indexM) => {
            if (_.includes(_fieldingCountries, cCode)) {
              methodology.CostingOptions.forEach((co, indexCO) => {
                const tempCostingOptions = tempFC
                  .filter((fc) => fc.label == sc.label)[0]
                  .MethodologySpecs.filter(
                    (sm) => sm.label == m.label
                  )[0].CostingOptions;
                if (
                  !copyProfile.SelectedCostingOptions.find(
                    (x) => x.label == co.label
                  )
                )
                  return;
                const tempIndexCO = tempCostingOptions.findIndex(
                  (tempCO) => tempCO.label == co.label
                );
                //new code
                const tempMethodology = tempFC
                  .filter((fc) => fc.label == sc.label)[0]
                  .MethodologySpecs.filter((sm) => sm.label == m.label)[0];
                co.Variables.forEach((v, indexV) => {
                  if (v.id == "citiesCoverage") {
                    delete v.value;
                  } else if (!v.Default) {
                    let found = false;
                    if (
                      tempMethodology.Variables.filter((mv) => mv.id == v.id)
                        .length > 0
                    ) {
                      found = true;
                      let objVar = tempMethodology.Variables.filter(
                        (mv) => mv.id == v.id
                      )[0];
                      objVar.selected = true;
                      objVar.value = null;
                    } else if (
                      tempMethodology.OpResourcesVariables.filter(
                        (mv) =>
                          mv.id == v.id ||
                          v.label == "RFQ Form" ||
                          v.label == "Setup & Delivery"
                      ).length > 0
                    ) {
                      found = true;
                      let objOpsVar =
                        tempMethodology.OpResourcesVariables.filter(
                          (mv) =>
                            mv.id == v.id ||
                            v.label == "RFQ Form" ||
                            v.label == "Setup & Delivery"
                        )[0];
                      objOpsVar.selected = true;
                      objOpsVar.value = null;
                    }
                    if (!found) v.NotFound = true;
                  }
                });
                co.Variables = co.Variables.filter((v) => v.NotFound != true);
                //new code ends

                if (tempIndexCO >= 0) {
                  const objCO = tempCostingOptions[tempIndexCO];
                  co.Variables.forEach((v, indexV) => {
                    const indexObjV = objCO.Variables.findIndex(
                      (objV) => objV.label == v.label
                    );
                    if (indexObjV >= 0) {
                      if (profile.CountrySpecs[0].CountryCode === cCode) {
                        if (
                          v.id === "CommericalLocalsu" ||
                          v.id === "operationalpm"
                        )
                          objCO.Variables[indexObjV].value = "Yes";
                        else objCO.Variables[indexObjV].value = v.value;
                      } else {
                        objCO.Variables[indexObjV].value = v.value;
                      }
                    } else objCO.Variables.push(_.cloneDeep(v));
                  });
                } else {
                  const objCO = _.cloneDeep(co);
                  delete objCO.id;
                  tempCostingOptions.push(objCO);
                }
                let deletedIndex = [];
                tempCostingOptions.forEach((delco, decoindex) => {
                  let tempDelete = delco.Variables.filter(
                    (xo) => !xo.value
                  ).length;
                  if (tempDelete == delco.Variables.length)
                    deletedIndex.push(decoindex);
                  //delco.active=false;
                });
                let deletedIndexSort = deletedIndex.sort(function (a, b) {
                  return b - a;
                });
                deletedIndexSort.forEach((element) => {
                  tempCostingOptions.splice(element, 1);
                });
              });
            }
          });
      });
      var temp = tempFC
      temp[0].MethodologySpecs.forEach((item)=>{
        item.CostingOptions.forEach((data)=>{
          data.Variables.forEach((res)=>{
            if(res.label === "Commercial Local Support" || res.label === "Operations Local Support"){
              res.value = "Yes"
            }
          })
        })
      })
      updateProjectAndProfileWRTCostingOption(profile, tempFC, true);

      setModalState(false);
      toastr.success("Success", "Data Copied Successfully");
    }
  }

  const ReturnControl = (indexV, indexC, indexM, indexCO) => {
    const v =
      copyProfile.SelectedCountries[indexC].Methodologies[indexM]
        .CostingOptions[indexCO].Variables[indexV] || {};

    if (v.type == "integer")
      return (
        <input
          type="number"
          name={v.label}
          value={v.value}
          style={{ width: "100%" }}
          onChange={(e) =>
            InfoChange(indexC, indexM, indexCO, e.target.name, e.target.value)
          }
        />
      );
    else if (v.type == "text")
      return (
        <input
          type="text"
          name={v.label}
          value={v.value}
          style={{ width: "100%" }}
          onChange={(e) =>
            InfoChange(indexC, indexM, indexCO, e.target.name, e.target.value)
          }
        />
      );
    else if (v.type == "textarea")
      return (
        <input
          type="textarea"
          name={v.label}
          value={v.value}
          style={{ width: "100%" }}
          onChange={(e) =>
            InfoChange(indexC, indexM, indexCO, e.target.name, e.target.value)
          }
        />
      );
    else if (v.type == "single")
      return (
        <SelectWrapper
          tag={CustomInput}
          name={v.label}
          disabled={v.disabled || disableFields}
          value={
            v.value
              ? typeof v.value === "object"
                ? v.value.value
                : v.value
              : null
          }
          type="select"
          onChange={(e) =>
            InfoChange(indexC, indexM, indexCO, v.label, e.target.value)
          }
        >
          <option value="0"></option>
          {v.optionsLabel
            ? codeLabels[v.optionsLabel].map((opt) => (
                <option key={opt.Code} value={opt.Code}>
                  {opt.Label}
                </option>
              ))
            : v.options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
        </SelectWrapper>
      );
    else if (v.type == "multi" && v.optionsLabel && v.optionsLabel == "Cities")
      return (
        <>
          {codeLabels["UserScopeOptions"].filter(
            (x) => x.Code == profile.CountrySpecs[indexC].CountryCode
          )[0] ? (
            <Select
              name={v.label}
              value={v.value}
              onChange={(e) => InfoChange(indexC, indexM, indexCO, v.label, e)}
              className="react-select-container"
              classNamePrefix="react-select"
              options={codeLabels["UserScopeOptions"]
                .filter(
                  (x) => x.Code == profile.CountrySpecs[indexC].CountryCode
                )[0]
                ["Cities"].map((opt) => {
                  return {
                    value: opt.id,
                    label: opt.Label,
                  };
                })}
              isMulti
            />
          ) : (
            "No City Found"
          )}
        </>
      );
    else if (
      v.type == "creatableMulti" &&
      v.optionsLabel &&
      v.optionsLabel == "Cities"
    )
      return (
        <>
          {codeLabels["UserScopeOptions"].filter(
            (x) => x.Code == profile.CountrySpecs[indexC].CountryCode
          )[0] ? (
            <CreatableSelect
              className="react-select-container"
              classNamePrefix="react-select"
              value={v.value}
              isMulti
              onChange={(e) => InfoChange(indexC, indexM, indexCO, v.label, e)}
              options={codeLabels["UserScopeOptions"]
                .filter(
                  (x) => x.Code == profile.CountrySpecs[indexC].CountryCode
                )[0]
                ["Cities"].map((opt) => {
                  return {
                    value: opt.id,
                    label: opt.Label,
                  };
                })}
            />
          ) : (
            "No City Found"
          )}
        </>
      );
    else if (v.type == "multi") {
      return (
        <div>
          <Select
            name={v.label}
            value={v.value}
            onChange={(e) => InfoChange(indexC, indexM, indexCO, v.label, e)}
            className="react-select-container"
            classNamePrefix="react-select"
            options={
              v.optionsLabel
                ? codeLabels[v.optionsLabel].map((opt) => {
                    return {
                      value: opt.Code,
                      label: opt.Label,
                    };
                  })
                : v.options.map((option) => {
                    return {
                      value: option.value,
                      label: option.label,
                    };
                  })
            }
            isMulti
          />
        </div>
      );
    }
  };

  function InfoChange(indexC, indexM, indexCO, name, value) {
    const VariablesClone = _.cloneDeep(
      copyProfile.SelectedCountries[indexC].Methodologies[indexM]
        .CostingOptions[indexCO].Variables
    );
    VariablesClone.filter((x) => x.label == name)[0].value = value;
    // let profileTemp = update(copyProfile, {
    //     SelectedCountries: {
    //         [indexC]: {
    //             Methodologies:
    //             {
    //                 [indexM]:
    //                 {
    //                     CostingOptions:
    //                     {
    //                         [indexCO]:
    //                         {
    //                             Variables:
    //                                 { $set: VariablesClone }
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // });

    let profileTemp = _.cloneDeep(copyProfile);
    if (
      profile?.SelectedCountries[indexC]?.Methodologies[indexM]?.CostingOptions[
        indexCO
      ]
    )
      profile.SelectedCountries[indexC].Methodologies[indexM].CostingOptions[
        indexCO
      ].Variables = VariablesClone;
    setCopyProfile(profileTemp);
  }

  function removeMethodology(indxFC, indxSM, applicableStatus) {
    //let profileTemp = update(profile, {
    //    CountrySpecs: {
    //        [indexFC]: {
    //            MethodologySpecs:
    //            {
    //                $splice: [[indexSM, 1]]
    //            }
    //        }
    //    }
    //});
    //const tempProfiles = [profileTemp];
    //updateProject({ CostingProfiles: tempProfiles });

    // let profileTemp = update(profile, {
    //     CountrySpecs: {
    //         [indxFC]: {
    //             MethodologySpecs:
    //             {
    //                 [indxSM]:
    //                 {
    //                     $merge: { "NotApplicable": !applicableStatus }
    //                 }
    //             }
    //         }
    //     }
    // });
    if (
      profile.CountrySpecs[indxFC].MethodologySpecs.length == 1 ||
      (applicableStatus == false &&
        profile.CountrySpecs[indxFC].MethodologySpecs.filter(
          (meth) => meth.NotApplicable
        ).length ==
          profile.CountrySpecs[indxFC].MethodologySpecs.length - 1)
    ) {
      window.confirm("You have to select at least one Methodology");
    } else {
      let profileTemp = _.cloneDeep(profile);
      profileTemp.CountrySpecs[indxFC].MethodologySpecs[indxSM].CostingType =
      profileTemp.CountrySpecs[indxFC].MethodologySpecs[indxSM].CostingType== "2"?
      profileTemp.CountrySpecs[indxFC].MethodologySpecs[indxSM].CostingType:2;
      profileTemp.CountrySpecs[indxFC].MethodologySpecs[indxSM] = {
        ..._.cloneDeep(
          profileTemp.CountrySpecs[indxFC].MethodologySpecs[indxSM]
        ),
        NotApplicable: !applicableStatus,
        Flag: !profileTemp?.CountrySpecs[indxFC]?.MethodologySpecs[indxSM]
          ?.Flag,
      };
      const tempProfiles = project.CostingProfiles.map((cp) => {
        if (cp.id == profileTemp.id) return { ..._.cloneDeep(profileTemp) };
        return { ..._.cloneDeep(cp) };
      });
      dispatch(currentCostingActions.updateProfile(profileTemp));
      dispatch(updateProject({ CostingProfiles: tempProfiles }));
      // avoid the tab shifting for the methodology applicable
      // if (indxSM != 0)
      //     setActiveTabSM(indxSM - 1);
      // else
      //     setActiveTabSM(1);
    }
  }

  const commercialFields = {
    "Associate Director": "CostIntCommAssociateDirector",
    "Data Science": "CostIntCommDataScience",
    Director: "CostIntCommDirector",
    "Executive Director": "CostIntCommExecDirector",
    Executive: "CostIntCommExecutive",
    Manager: "CostIntCommManager",
    "Senior Executive": "CostIntCommSeniorExecutive",
    "Senior Manager": "CostIntCommSeniorManager",
  };
  function validateCO() {
    //chanege by mridul rishab
    profile?.CountrySpecs.map((fc) =>
      fc?.MethodologySpecs.map((sm) =>
        sm?.CostingOptions.map((co) =>
          co?.Variables?.filter((v) => v?.type == "integer").map((cd) => {
            if (parseInt(cd?.value) > 100 && parseInt(cd?.value) < 1) {
              isvalid = false;
            } else if (parseInt(cd?.value) < 0) {
              isvalid = false;
            } else {
            }
          })
        )
      )
    );
  }

  function validateRFQ() {
    profile?.CountrySpecs.map((fc) =>
      fc?.MethodologySpecs.map((sm) =>
        sm?.Variables.filter((v) => v.type == "integer").map((cd) => {
          if (parseInt(cd?.value) < 0) {
            isvalid = false;
          }
          if (cd?.label == "Incidence Rate Main" && parseInt(cd?.value) > 100) {
            isvalid = false;
          }
        })
      )
    );
  }

  function validateSUD() {
    profile?.CountrySpecs.map((fc) =>
      fc?.MethodologySpecs.map((sm) =>
        sm?.OpResourcesVariables.filter((v) => v?.type == "integer").map(
          (cd) => {
            if (parseInt(cd?.value) < 0) {
              isvalid = false;
            }
          }
        )
      )
    );
  }


  function saveDraft(notAPI) {
    dispatch(setOpportunityValue(""));
    validateCO();
    validateRFQ();
    validateSUD();
    if (isvalid) {
      isvalid = true;
      let _profile = { ...currentCosting }; //_.last(project.CostingProfiles) //currentCosting//_.last(project.CostingProfiles)

      // _profile = { ..._profile, ...currentCosting }
      _profile.AdditionalSheetId = currentCosting.AdditionalSheetId
        ? currentCosting.AdditionalSheetId
        : null;
      _profile.CostingType = currentCosting.CostingType
        ? currentCosting.CostingType
        : null;
      _profile.CostingsSheetId = currentCosting.CostingsSheetId
        ? currentCosting.CostingsSheetId
        : null;
        
      let _project = _.cloneDeep(project)
      if(_project.CostingProfiles){
        delete _project.CostingProfiles
      }
          _profile.Project = _project;
        
      if (!_.includes(pacificMarkets, project.CommissioningCountry) ||
       (_.includes(pacificMarkets, project.CommissioningCountry) && project.CostingTemplateVersion == "v2.0")) {
        _profile.CountrySpecs = _profile.CountrySpecs?.map((cs) => {
          let requiredcountry = {
            ...currentCosting.CountrySpecs.find((CS) => CS.id == cs.id),
          };
          if (requiredcountry) {
            cs.MethodologySpecs = cs.MethodologySpecs.map((ms) => {
              let requiredMeth = {
                ...requiredcountry.MethodologySpecs.find(
                  (MS) => MS.id == ms.id
                ),
              };
              // delete requiredMeth.CostingOptions
              // delete requiredMeth.NotApplicable
              // delete requiredMeth.Responsibilities
              // delete requiredMeth.ResponsibilityData
              // delete requiredMeth.ResponsibilitySchema
              // delete requiredMeth.SetupData
              // delete requiredMeth.SetupSchema
              // delete requiredMeth.RFQData
              // delete requiredMeth.RFQSchema
              return { ...ms, ...requiredMeth };
            });
          }
          delete requiredcountry.MethodologySpecs;
          return { ...cs, ...requiredcountry };
        });
      }
      if (!_.includes(pacificMarkets, project.CommissioningCountry)  ||
       (_.includes(pacificMarkets, project.CommissioningCountry) && project.CostingTemplateVersion == "v2.0")) {
        _profile.WaveSpecs = _.cloneDeep(currentCosting.WaveSpecs);
      }
      if (_profile.WaveSpecs)
        _profile.WaveSpecs = calcAll(
          project,
          currentCosting,
          currentCosting.CountrySpecs,
          _profile.WaveSpecs,
          rateCards,
          pacificMarkets
        )?.waveSpecs;

      var WaveSpecsAll = _profile?.WaveSpecs || [];
      // WaveSpecsAll = calcAll(project, currentCosting, currentCosting.CountrySpecs, WaveSpecsAll, rateCards, pacificMarkets)?.waveSpecs

      let temporaryWavesForValidation = [];
      let countIteration=0
      if(typeof currentCosting.ProductTypeData !=="String")
      {
        var ProductTypeData=currentCosting.ProductTypeData
      }
      else
      {
        while(typeof currentCosting.ProductTypeData === "string"){
          currentCosting.ProductTypeData = JSON.parse(currentCosting.ProductTypeData)
        }
        // if(typeof JSON.parse( currentCosting.ProductTypeData) === "string"){
          
        //   var ProductTypeData=JSON.parse(JSON.parse( currentCosting.ProductTypeData))
        // }
        // else{
          var ProductTypeData=JSON.parse( currentCosting.ProductTypeData)
        // }
      }
      _profile?.CountrySpecs?.forEach((cs,index) => {
        cs?.MethodologySpecs?.forEach((ms) => {
          ms.RFQSchema = mapperFunctions.formatSchemaByVariables(
            _.cloneDeep(ms?.RFQSchema),
            _.cloneDeep(ms?.Variables)
          );
          ms.RFQData = _.cloneDeep(ms?.Variables)
            ? mapperFunctions.getDataJSON(_.cloneDeep(ms?.Variables))
            : mapperFunctions.getDataJSON(_.cloneDeep([]));
          //let WaveSpecs=ms.WaveSpecs||[];
          var costingOptionWaves;
          if (ms?.CostingOptions.length)
            costingOptionWaves = ms?.CostingOptions[0]?.Variables.filter(
              (x) => x?.label == "Number of Waves"
            );
          else {
            costingOptionWaves = ms?.Variables
              ? ms.Variables.filter((x) => x?.id == "numberOfWaves")
              : [];
          }
          var WaveSpecs = WaveSpecsAll.filter(
            (x) => x.MethodologySpecId == ms.id
          );
          var waveSize =
            costingOptionWaves.length > 0
              ? costingOptionWaves[0].value
              : (ms?.OpResourcesVariables.filter(
                  (x) => x.label == "Number of Waves"
                ).length > 0
                  ? ms?.OpResourcesVariables.filter(
                      (x) => x.label == "Number of Waves"
                    )[0].value
                  : 1) || 1;
          let waves =
            WaveSpecs.length > 0 && WaveSpecs.length == waveSize
              ? WaveSpecs.length
              : waveSize;
          if (!waves) {
            waves = 1;
          }
          if (!_.includes(pacificMarkets, project.CommissioningCountry)  ||
           (_.includes(pacificMarkets, project.CommissioningCountry) && project.CostingTemplateVersion == "v2.0")) {
            var tempWaveSpecs = [];
            for (let i = 0; i < waves; i++) {
              var id =
                WaveSpecs.length > 0
                  ? WaveSpecs[i]
                    ? WaveSpecs[i].id
                    : null
                  : null;
              tempWaveSpecs.push({
                id: id,
                WaveNumber: i + 1,
                ResponsibilitySchema: _.cloneDeep(ms.Responsibilities),
                OpsResourcesSchema: mapperFunctions.formatSchemaByVariables(
                  _.cloneDeep(ms.SetupSchema),
                  _.cloneDeep(ms.OpResourcesVariables)
                ),
                OpsResourcesData: mapperFunctions.getDataJSON(
                  _.cloneDeep(ms.OpResourcesVariables)
                ),
                MethodologySpecId: ms.id,
                CostingProfileId: _profile?.id,
              });

              ms["WaveSpecs"] = tempWaveSpecs;
              temporaryWavesForValidation = [
                ...temporaryWavesForValidation,
                ...tempWaveSpecs,
              ];
            }

            ///For Wave Specs Deletion
            if (WaveSpecs.length > waves) {
              for (let i = waves; i < WaveSpecs.length; i++) {
                var id = WaveSpecs[i].id;
                tempWaveSpecs.push({
                  id: id,
                  toRemove: true,
                  WaveNumber: i + 1,
                  ResponsibilitySchema: _.cloneDeep(ms.Responsibilities),
                  OpsResourcesSchema: mapperFunctions.formatSchemaByVariables(
                    _.cloneDeep(ms.SetupSchema),
                    _.cloneDeep(ms.OpResourcesVariables)
                  ),
                  OpsResourcesData: mapperFunctions.getDataJSON(
                    _.cloneDeep(ms.OpResourcesVariables)
                  ),
                  MethodologySpecId: ms.id,
                  CostingProfileId: _profile?.id,
                });
                ms["WaveSpecs"] = tempWaveSpecs;
                temporaryWavesForValidation = [
                  ...temporaryWavesForValidation,
                  ...tempWaveSpecs,
                ];
              }
            }
          } else {
            //todo: wave specs deletion and updation
            //when _.groupBy(profile.WaveSpecs).length>tempWaveSpecs delete respective number of wavespecs
            //for that methodology accordingly, likewise _.groupBy(profile.WaveSpecs).length<tempWaveSpecs
            //create wavespecs, if you fill wavespecs and without IDs it'll create in backend
            var pacificTempWaves = [];
            let waveMethCount = profile.WaveSpecs.filter(
              (x) => x.MethodologySpecId === ms.id
            );
            // to add
            if (waveMethCount.length < waves) {
              for (let i = 0; i < waves; i++) {
                var id =
                  waveMethCount.length > 0
                    ? waveMethCount[i]
                      ? waveMethCount[i].id
                      : null
                    : null;
                pacificTempWaves.push({
                  id: id,
                  WaveNumber: i + 1,
                  ResponsibilitySchema: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].ResponsibilitySchema)
                    : _.cloneDeep(ms.Responsibilities),
                  CommercialHoursData: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].CommercialHoursData)
                    : _.cloneDeep(ms?.CommercialHoursData),
                  OpsResourcesSchema: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].OpsResourcesSchema)
                    : mapperFunctions.formatSchemaByVariables(
                        _.cloneDeep(ms.SetupSchema)
                      ),
                  OpsResourcesData: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].OpsResourcesData)
                    : {},
                  MethodologySpecId: ms.id,
                  CostingProfileId: profile?.id,
                });
                pacificTempWaves = calcAll(
                  project,
                  currentCosting,
                  currentCosting.CountrySpecs,
                  pacificTempWaves,
                  rateCards,
                  pacificMarkets
                )?.waveSpecs;
                ms["WaveSpecs"] = pacificTempWaves;

                temporaryWavesForValidation = [
                  ...temporaryWavesForValidation,
                  ...pacificTempWaves,
                ];
              }
            }
            if (pacificTempWaves.length === 0) {
              profile.WaveSpecs = calcAll(
                project,
                currentCosting,
                currentCosting.CountrySpecs,
                profile.WaveSpecs,
                rateCards,
                pacificMarkets
              )?.waveSpecs;
              let data = profile.WaveSpecs.filter(
                (x) => x.MethodologySpecId === ms.id
              ).map((x) => {
                return { ...x };
              });
              ms["WaveSpecs"] = data;
              temporaryWavesForValidation = [
                ...temporaryWavesForValidation,
                ...data,
              ];
            }

            // to Remove
            if (waveMethCount.length > waves) {
              for (let i = waves; i < waveMethCount.length; i++) {
                var id = waveMethCount[i].id;
                pacificTempWaves.push({
                  id: id,
                  toRemove: true,
                  WaveNumber: i + 1,
                  ResponsibilitySchema: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].ResponsibilitySchema)
                    : _.cloneDeep(ms.Responsibilities),
                  CommercialHoursData: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].CommercialHoursData)
                    : _.cloneDeep(ms?.CommercialHoursData),
                  OpsResourcesSchema: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].OpsResourcesSchema)
                    : mapperFunctions.formatSchemaByVariables(
                        _.cloneDeep(ms.SetupSchema)
                      ),
                  OpsResourcesData: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].OpsResourcesData)
                    : {},
                  MethodologySpecId: ms.id,
                  CostingProfileId: _profile?.id,
                });
                pacificTempWaves = calcAll(
                  project,
                  currentCosting,
                  currentCosting.CountrySpecs,
                  pacificTempWaves,
                  rateCards,
                  pacificMarkets
                )?.waveSpecs;
                ms["WaveSpecs"] = pacificTempWaves;
                temporaryWavesForValidation = [
                  ...temporaryWavesForValidation,
                  ...pacificTempWaves,
                ];
              }
            }
          }
          if (!_.includes(pacificMarkets, project.CommissioningCountry) ||
           (_.includes(pacificMarkets, project.CommissioningCountry) && project.CostingTemplateVersion == "v2.0"))
            ms.CostingOptions.forEach((co, indexCO) => {
              co["id"] = co.id;
              co["Name"] = co.label;
              co["Selected"] = co.isSelected?co.isSelected:false;
              co["CostingOptionSchema"] = _.cloneDeep(co.Variables);
              co["CostingOptionData"] = mapperFunctions.getDataJSON(
                _.cloneDeep(co.Variables)
              );

              if(!co.OpportunityLineItemID)
              {
                let oppID=co?.CostingOptionSchema[0]?.options[0]?.OpportunityLineItemID
                console.log("oppID",oppID)
                co["OpportunityLineItemID"]=oppID
              }
              if(!co?.CostingOptionData?.producttype)
              {
                co.CostingOptionData.producttype=co?.CostingOptionSchema[0]?.options[0]?.Productname
              } 
              if(!co?.CostingOptionData?.Booster)
              {
                co.CostingOptionData.Booster="No"
              } 
              if(!("CommericalLocalsu" in
                co["CostingOptionData"]) && (profile.createdAt >= "2023-08-05T10:43:12.000Z") ){
                  if(index === 0){
                    co["CostingOptionData"]={...co["CostingOptionData"],
                      "CommericalLocalsu" : "Yes",
                      "operationalpm" : "Yes"}
                    }
                    else{
                      co["CostingOptionData"]={
                        ...co["CostingOptionData"],
                        "CommericalLocalsu" : "No",
                        "operationalpm" : "Yes"}
                      }
                }
                  
                  
              co["MethodologySpecId"] = ms.id;
              
                if(!co.OpportunityLineItemID)
                {
                  co["OpportunityLineItemID"] = co.OpportunityLineItemID;
                }
                // ProductTypeData[countIteration].OpportunityLineItemID
                // countIteration++;

            });
        });
        // countIteration=0
      });
      //commercial costs recounting if there any and helps in case if any costing option got deleted
      Object.keys(commercialFields).map((cf) => {
        _profile[commercialFields[cf]] = 0;
      });
      _profile.CountrySpecs = _profile.CountrySpecs.map((cs) => {
        Object.keys(commercialFields).map((cf) => {
          cs[commercialFields[cf]] = 0;
        });
        cs.MethodologySpecs = cs.MethodologySpecs?.map((ms) => {
          ms.CostingOptions?.filter((co) => co.Selected)?.map((co) => {
            Object.keys(commercialFields).map((cf) => {
              cs[commercialFields[cf]] =
                (cs[commercialFields[cf]] ? cs[commercialFields[cf]] : 0) +
                (co[commercialFields[cf]] ? co[commercialFields[cf]] : 0);
            });
          });
          return { ...ms };
        });
        Object.keys(commercialFields).map((cf) => {
          _profile[commercialFields[cf]] =
            (_profile[commercialFields[cf]]
              ? _profile[commercialFields[cf]]
              : 0) + cs[commercialFields[cf]];
        });
        return { ...cs };
      });

      if (_profile.WaveSpecs.length > 0) {
        let WaveSpecs = _profile.WaveSpecs.filter(ws => ws.NotApplicable != true)
        const noWaves = Object.values(
          _.groupBy(WaveSpecs, "MethodologySpecId")
        ).map((O) => O.length);
        _profile.NumberOfWaves = _.max(noWaves);
      }
      if (project.ProjectName !== profile.Project.ProjectName) {
        profile.Project.ProjectName = project.ProjectName;
      }
      dispatch(valueChangedInRFQ(false));
      _profile.isRFQ=true

      if (!notAPI) {
        dispatch(currentCostingActions.saveCostingProfileDraft(_profile));
        Axios.put(`/projects/${currentProject.ProjectId}/UpdateProjectParams`, {
          ClientChargeableApplicable: currentProject.ClientChargeableApplicable ? 1 : 0,
        })
      } else {
        return { ..._profile, WaveSpecs: temporaryWavesForValidation };
      }
      setprofileCopy(profile);
      setShouldBlockNavigation(false);
    } else {
      toastr.error("You have Entered Invalid Values");
      isvalid = true;
    }
  }
  const showErrorMessages = (eve, errors, key) => {
    eve.preventDefault();
    dispatch(setFormErrorStatus({ [key]: errors }));
  };
  const ValueChangeCheckInChild = (val) => {
    dispatch(valueChangedInRFQ(val));
    // eve.preventDefault();
    // dispatch(setFormErrorStatus({ [key]: errors }))
  };
  /**
   * copying the market level data
   * @param {index} tab
   */


   const selectedMethodologiesFunc = (val) => {

    if(val && advanceCopying.SelectedCategories.length !== val.length) {

      const methodologiesLabel = val.map(data => data.label)
      

      const filteredselectedMethodologies = filteredMethodology.filter(item => methodologiesLabel.includes(item.name))


      const intersection = handleIntersection([filteredMethodology[activeTabSM], ...filteredselectedMethodologies])

      const uniqData = handleMCategories(intersection)

      const selectConvertedCategories = modalCategoriesSingleCountry 
      
      const convertedCategories = selectConvertedCategories.filter((MC) => (
        uniqData.includes(MC.label) &&
        {
          label: MC.label,
          value: MC.value,
          ColSpan: null,
        }
      ));

      const nonRelatedCategories = advanceCopying.SelectedCategories.filter((MC) => (
        uniqData.includes(MC.label) &&
        {
          label: MC.label,
          value: MC.value,
          ColSpan: null,
        }
      ));

      setAdvanceCopying((prevState) => ({
        ...prevState,
        SelectedMethodologies: val ? val : [],
        SelectedCategories: nonRelatedCategories,
        Categories: convertedCategories,
      }));
    } else {
 
    setAdvanceCopying((prevState) => ({
      ...prevState,
      SelectedMethodologies: val ? val : [],
      SelectedCategories: !val ? [] : advanceCopying.SelectedCategories,
      Categories: !val ? [] : advanceCopying.Categories,
    }));
  }

   }

  // single country methodologies copy logic 
  const copyFormToMethodology = (tab) => {
    if (validateCopyModal()) {

      const primaryMethodology = profile.CountrySpecs[0].MethodologySpecs[activeTabSM]
      const methodologiesLabel = advanceCopying.SelectedMethodologies.map(data => data.label)

      let deletedCOs = []
      
        const copyToAllMethodology = profile.CountrySpecs[0].MethodologySpecs.map((C,index) => {

          if(index !== activeTabSM && (methodologiesLabel).includes(C.Label)) {
            advanceCopying.SelectedCategories.forEach((SC) => {
              switch (SC.value) {
                case "CostingOptions":

                  if (advanceCopying.SelectedCopyMethod === COPYING_METHOD_VALUES.APPEND) {
                    // Extract variables from source to tempCO

                    const targetCoHeadVariable = _.head(C.CostingOptions).Variables
                      .map((dataItem, cIndex) => dataItem.label)

                    var sourceLevel = _.cloneDeep(primaryMethodology.CostingOptions);
    
                    const tempCO = sourceLevel.map((MC) => {
                      
                      MC.Variables = MC.Variables.map((V) => {
                        if(targetCoHeadVariable.includes(V.label)) {

                          const  filterData =  _.cloneDeep(
                          _.head(C.CostingOptions)
                         .Variables.filter(item => item.label === V.label))
                        
                            return  {
                              ...filterData[0],
                              value: V?.value ? V?.value  : ""
                            }
                          
                        }
                      }).filter(item => item);

                      MC.CostsData = null
                      MC.TimingsData = null
                      return MC;
                    })

                    const matchingCoLabel = tempCO[0].Variables.map((dataItem, cIndex) => dataItem.label)

                    var remainingTargetCoVariables =  _.head(C.CostingOptions).Variables.filter(itemData => {
                      if(!matchingCoLabel.includes(itemData.label)) {
                        return itemData
                      }
                    })


                    const reOrderTempCO = tempCO.map((MCData) => {

                      const filterData = [...MCData.Variables, ...remainingTargetCoVariables]

                      filterData.map((item, index) => {
                        let indexValue = targetCoHeadVariable.indexOf(item.label)
                        let temp = filterData[indexValue]
                        filterData[indexValue] = item
                        filterData[index] =temp
                     })

                      MCData.Variables = filterData
                      return MCData;
                    })

                    // Append converted costing options to targeted MS costing options
                    
                    C.CostingOptions = C.CostingOptions
                    .concat(reOrderTempCO)
                  } 
                  else {
                    let costingOptBk = C.CostingOptions
                    C.CostingOptions = primaryMethodology.CostingOptions.map((MC, _ind) => {
                      let COs = primaryMethodology.CostingOptions[_ind];
                      if (COs) {
                        let _MC = _.cloneDeep(COs);

                        let targetCostingOption = C.CostingOptions.length-1 >= _ind ? 
                          C.CostingOptions[_ind] :  C.CostingOptions[0]

                        const coLabelFiltered = targetCostingOption.Variables.map((dataItem, cIndex) => dataItem.label)

                        _MC.Variables = _MC.Variables.map((V) => {
                          if(coLabelFiltered.includes(V.label)) {

                            let  filterData = 
                            targetCostingOption?.Variables.filter(item => item.label === V.label)
                            if(V?.value) {
                              return {
                                ...filterData[0],
                                value: V?.value
                              };
                            } else {
                              return {
                                ...filterData[0]
                              }
                            }
                          }
                        }).filter(item => item);

                      const matchedLabelFiltered = _MC.Variables.map((dataItem, cIndex) => dataItem.label)

                        const remainingTargetVariables =  targetCostingOption.Variables.filter((V) => {
                          if(!matchedLabelFiltered.includes(V.label)) {
                            return V;
                          }
                        })

                        if(remainingTargetVariables.length) {
                          _MC.Variables = _MC.Variables.concat(remainingTargetVariables)
                        }

                        const targetCoHeadVariable = _.head(costingOptBk).Variables
                        .map((dataItem, cIndex) => dataItem.label)
    
                        const filterData = _MC.Variables
    
                        filterData.map((item, index) => {
                          let indexValue = targetCoHeadVariable.indexOf(item.label)
                          let temp = filterData[indexValue]
                          filterData[indexValue] = item
                          filterData[index] =temp
                        })
  
                        _MC.Variables = filterData

                        _MC.id = C.CostingOptions[_ind]?.id;
                        _MC.MethodologySpecId = C.id;
                        return _MC;
                      } else {
                        return MC;
                      }
                    });
                    let temp = []
                    costingOptBk.forEach(i => {
                      if (C.CostingOptions.findIndex(c => c.id === i.id) === -1) {
                        temp.push({ ...i, toRemove: true })
                      }
                    })
                    deletedCOs = deletedCOs.concat(temp);
                  }

                  break;
                case  "RFQSchema":

                  if(C.Variables.length) {

                    const cloneRfqData = _.cloneDeep(C.Variables)
                    const newData = cloneRfqData.map((rfqVariable, index) => {
                     const matchData = primaryMethodology.Variables.filter(rfqVariabledastd => rfqVariabledastd.label  === rfqVariable.label)
                     if(matchData) {
                        if( matchData[0]?.options ) {
                          let valueData = matchData[0]?.value  ? matchData[0]?.value : "" 
                          let filterValue = valueData ? 
                           rfqVariable?.options.filter(item => 
                            item.value.toString().toLowerCase() ===  valueData.toString().toLowerCase()
                          ) : []

                          return {...rfqVariable, 
                            value: 
                            filterValue.length > 0 ? filterValue[0].value : 
                            valueData ?  valueData : 
                            rfqVariable?.value
                          }
                       } else {
                         return {...rfqVariable, 
                        value: matchData[0]?.value  ? matchData[0]?.value :
                        rfqVariable?.value ?  rfqVariable?.value :
                        "" }

                       }
                     } else {
                         return rfqVariable
                     }
                   })

                    C.Variables = [...newData]
                  }
                  
                break;
                case "OpResourcesVariables":

                if(C.OpResourcesVariables.length) {

                  const cloneSetupNdDelivery = _.cloneDeep(C.OpResourcesVariables)
                  const newData = cloneSetupNdDelivery.map((setupAndDVariable, index) => {
                   const matchData = primaryMethodology.OpResourcesVariables.filter(setupAndDVariabledastd => setupAndDVariabledastd.label  === setupAndDVariable.label)
                   if(matchData) {
                      if( matchData[0]?.options ) {
                        let valueData = matchData[0]?.value  ? matchData[0]?.value : undefined
                        
                        let filterValue = valueData ? setupAndDVariable?.options.filter(item => 
                          item.value.toString().toLowerCase() ===  valueData.toString().toLowerCase()
                        ) : []
                        
                        return {
                          ...setupAndDVariable, 
                          value: 
                          filterValue.length > 0 ? filterValue[0].value : 
                          valueData ?  valueData : 
                          setupAndDVariable?.value
                        }
                     } else {
                       return {...setupAndDVariable, 
                        value: matchData[0]?.value  ? matchData[0]?.value : 
                        setupAndDVariable?.value ?
                        setupAndDVariable?.value :
                        undefined 
                      }
                     }
                   } else {
                       return setupAndDVariable
                   }
                 })

                  C.OpResourcesVariables = [...newData]
                }
                break;
              }
            })
            return C;
          } else {
            return C;
          }
        })

        // updating the profile and project data
      let profileTemp = _.cloneDeep(profile);
      profileTemp.deletedCostingOptions = profileTemp?.deletedCostingOptions ? 
      profileTemp?.deletedCostingOptions.concat(deletedCOs) : deletedCOs
      
   
      const tempProfiles = project?.CostingProfiles.map((cp) => {
        if (cp?.id == profileTemp?.id) return { ..._.cloneDeep(profileTemp) };
        return { ..._.cloneDeep(cp) };
      });
      dispatch(updateProject({ CostingProfiles: tempProfiles }));
      dispatch(currentCostingActions.updateProfile(profileTemp));

      setModalConfirmMethodology(false);
      toastr.success(
        "Success",
        "Data copied successfully to the selected Methodology"
      );

    }
  }

  const copyFormToCountries = (tab) => {
    if (validateCopyModal()) {
      const tempProfile = { ...currentCosting };
      // selected market data
      const tempCountrySpecs = tempProfile.CountrySpecs[tab];
      // data conversion of the selected items in Modal
      const countryArray = advanceCopying.SelectedCountries.map(
        (SC) => SC.label
      );
      const methodologiesArray = advanceCopying.SelectedMethodologies.map(
        (MS) => MS.value
      );
      // filtering the countries other than selected market
      const filteredCountrySpecsByInd = tempProfile.CountrySpecs.filter(
        (_, ind) => ind !== tab
      );
      let filteredCountrySpecs = [];
      filteredCountrySpecsByInd.forEach((F) => {
        if (countryArray.includes(F.label)) {
          filteredCountrySpecs.push(F);
        }
      });
      let _fieldingCountries = profile.FieldingCountries;
      if (_fieldingCountries && Array.isArray(_fieldingCountries))
        _fieldingCountries = _fieldingCountries.map((fc) => fc.value);
      else if (_fieldingCountries)
        _fieldingCountries = _fieldingCountries.split(",");
      delete _fieldingCountries[tempCountrySpecs.value];
      // data copying for the selected methodologies and categories at market level
      let deletedCOs = []
      const convertedCountrySpecs = filteredCountrySpecs.map((C) => {
        C.MethodologySpecs = C.MethodologySpecs.map((M) => {
          if (methodologiesArray.includes(M.Code)) {
            const findMS = tempCountrySpecs.MethodologySpecs.find(
              (MS) => MS.Code === M.Code
            );
            if (findMS) {
              advanceCopying.SelectedCategories.forEach((SC) => {
                switch (SC.value) {
                  case "CostingOptions":
                    // if (_.includes(_fieldingCountries, C.CountryCode)) {
                      if (advanceCopying.SelectedCopyMethod === COPYING_METHOD_VALUES.APPEND) {
                        // Extract variables from source to tempCO
                        let tempCO = findMS.CostingOptions.map((MC) => {
                          let _MC = _.cloneDeep(MC);
                          _MC.Variables = _MC.Variables.map((V) => {
                            if(profile.CountrySpecs[0].CountryCode == C.CountryCode){
                              if(V.id == "CommericalLocalsu"){
                                V.value = "Yes"
                              }
                              if(V.id == "operationalpm"){
                                V.value = "Yes"
                              }
                            }
                            let _v = _.cloneDeep(V);
                            _v.disabled = false;
                            if (_v.id === "citiesCoverage") delete _v.value;
                            return _v;
                          });
                          delete _MC.id
                          _MC.MethodologySpecId = M.id;
                          return _MC;
                        })
                        /*
                          Check which (source, target) have more variables in costing option
                          Those who has more variable will be adding to alternate MS variable by replacing its value.
                        */
                        if (_.head(tempCO).Variables.length > _.head(M.CostingOptions).Variables.length) {
                          M.CostingOptions = M.CostingOptions.map(co => {
                            let tempVars = _.cloneDeep(_.head(tempCO).Variables)
                            let indx = 0;
                            co.Variables = tempVars.map((tv, ind) => {
                              if(tv.id === co.Variables[indx]?.id) {
                                let obj = {...tv, value: co.Variables[indx]?.value}
                                indx += 1
                                return obj
                              } else {
                                return {
                                  ...tv,
                                  value: undefined,
                                }
                              }
                            })
                            return co
                          })
                        } else {
                          tempCO = tempCO.map(co => {
                            let tempVars = _.cloneDeep(_.head(M.CostingOptions).Variables)
                            let indx = 0;
                            co.Variables = tempVars.map((tv, ind) => {
                              if(tv.id === co.Variables[indx]?.id) {
                                let obj = {...tv, value: co.Variables[indx]?.value}
                                indx += 1
                                return obj
                              } else {
                                return {
                                  ...tv,
                                  value: undefined
                                }
                              }
                            }).filter(i => !_.isEmpty(i))
                            return co
                          })
                        }
                        // Append converted costing options to targeted MS costing options
                        M.CostingOptions = M.CostingOptions.concat(tempCO)
                      } else {
                        // M.CostingOptions = findMS.CostingOptions.map(MC => {
                        // //    delete MC["id"]
                        //     let _MC = _.cloneDeep(MC)
                        //     _MC.Variables = _MC.Variables.map(V => {
                        //         let _v = _.cloneDeep(V)
                        //         if (_v.id === 'citiesCoverage') delete _v.value
                        //         return _v
                        //     });
                        //     return _MC;
                        // });
                        let costingOptBk = M.CostingOptions
                        M.CostingOptions = findMS.CostingOptions.map((MC, _ind) => {
                          let COs = findMS.CostingOptions[_ind];
                          if (COs) {
                            let _MC = _.cloneDeep(COs);
                            _MC.CommercialHours = M.CostingOptions[_ind]?.CommercialHours

                            _MC.Variables = _MC.Variables.map((V) => {
                              if(profile.CountrySpecs[0].CountryCode == C.CountryCode){
                                if(V.id == "CommericalLocalsu"){
                                  V.value = "Yes"
                                }
                                if(V.id == "operationalpm"){
                                  V.value = "Yes"
                                }
                              }
                              let _v = _.cloneDeep(V);
                              _v.disabled = false;
                              if (_v.id === "citiesCoverage") delete _v.value;
                              return _v;
                            });
                            _MC.id = M.CostingOptions[_ind]?.id;
                            _MC.MethodologySpecId = M.id;
                            return _MC;
                          } else {
                            return MC;
                          }
                        });
                        let temp = []
                        costingOptBk.forEach(i => {
                          if (M.CostingOptions.findIndex(c => c.id === i.id) === -1) {
                            temp.push({ ...i, toRemove: true })
                          }
                        })
                        deletedCOs = deletedCOs.concat(temp);
                      }
                    // }
                    break;
                  case "RFQSchema":
                    // let _fieldingCountries = profile.FieldingCountries
                    // if (_fieldingCountries && Array.isArray(_fieldingCountries))
                    //     _fieldingCountries = _fieldingCountries.map(fc => fc.value)
                    // else if (_fieldingCountries)
                    //     _fieldingCountries = _fieldingCountries.split(",")

                    if (_.includes(_fieldingCountries, C.CountryCode))
                      M.Variables = findMS.Variables;
                    break;
                  case "OpResourcesVariables":
                    M.OpResourcesVariables = findMS.OpResourcesVariables;
                    break;
                  case "Responsibilities":
                    M.Responsibilities = findMS.Responsibilities;
                    break;
                  default:
                    break;
                }
              });
              return M;
            }
            return M;
          }
          return M;
        });
        return C;
      });
      // updating the profile and project data
      let profileTemp = _.cloneDeep(currentCosting  );
      profileTemp.deletedCostingOptions = profileTemp.deletedCostingOptions ? profileTemp.deletedCostingOptions.concat(deletedCOs) : deletedCOs
      // commenting chethan's code as it is replacing the countries with the countries to be copied
      // profileTemp.CountrySpecs = _.cloneDeep(convertedCountrySpecs)
      // added by Ravi Punnam
      profileTemp.CountrySpecs = profileTemp.CountrySpecs.map(
        (obj) => convertedCountrySpecs.find((o) => o.id === obj.id) || obj
      );
      const tempProfiles = project.CostingProfiles.map((cp) => {
        if (cp.id == profileTemp.id) return { ..._.cloneDeep(profileTemp) };
        return { ..._.cloneDeep(cp) };
      });
      dispatch(updateProject({ CostingProfiles: tempProfiles }));
      dispatch(currentCostingActions.updateProfile(profileTemp));
      setModalConfirmMethodology(false);
      toastr.success(
        "Success",
        "Data copied successfully to the selected markets"
      );
    }
  };

  function CountryCheck(profile, Country) {
    let temp = Array.isArray(profile.FieldingCountries)
      ? _.includes(
          profile.FieldingCountries.map((f) => f.value),
          Country.CountryCode
        )
      : _.includes(profile.FieldingCountries.split(","), Country.CountryCode);

    return temp;
  }

  const copyFormToPacificCountries = (tab) => {
    if (validateCopyModal()) {
      const tempProfile = { ...profile };

      let methBreak = {};
      tempProfile.CountrySpecs.map((cs) => {
        cs.MethodologySpecs?.map((ms) => {
          if (!methBreak[ms.id]) {
            methBreak[ms.id] = { Code: ms.Code };
          }
        });
      });

      let selectedCountry = tempProfile.CountrySpecs[tab];
      let selectedCountryMethBreak = {};
      let slectedMethIds = selectedCountry.MethodologySpecs.map((ms) => ms.id);

      tempProfile.WaveSpecs.map((ws) => {
        if (slectedMethIds.indexOf(ws.MethodologySpecId) != -1) {
          if (!selectedCountryMethBreak[methBreak[ws.MethodologySpecId].Code]) {
            selectedCountryMethBreak[methBreak[ws.MethodologySpecId].Code] = {};
          }
          selectedCountryMethBreak[methBreak[ws.MethodologySpecId].Code][
            ws.WaveNumber
          ] = {
            OpsResourcesData: ws.OpsResourcesData,
            CommercialHoursData: ws.CommercialHoursData,
            ResponsibilitySchema: _.cloneDeep(ws.ResponsibilitySchema),
          };
        }
      });
      tempProfile.WaveSpecs = tempProfile.WaveSpecs.map((ws) => {
        if (slectedMethIds.indexOf(ws.MethodologySpecId) == -1) {
          ws.OpsResourcesData =
            selectedCountryMethBreak[methBreak[ws.MethodologySpecId].Code][
              ws.WaveNumber
            ]?.OpsResourcesData;
          ws.CommercialHoursData =
            selectedCountryMethBreak[methBreak[ws.MethodologySpecId].Code][
              ws.WaveNumber
            ]?.CommercialHoursData;
          ws.ResponsibilitySchema =
            selectedCountryMethBreak[methBreak[ws.MethodologySpecId].Code][
              ws.WaveNumber
            ]?.ResponsibilitySchema;
        }
        return { ...ws };
      });

      // selected market data
      const tempCountrySpecs = tempProfile.CountrySpecs[tab];
      // data conversion of the selected items in Modal
      const countryArray = advanceCopying.SelectedCountries.map(
        (SC) => SC.label
      );
      const methodologiesArray = advanceCopying.SelectedMethodologies.map(
        (MS) => MS.value
      );
      // filtering the countries other than selected market
      const filteredCountrySpecsByInd = tempProfile.CountrySpecs.filter(
        (_, ind) => ind !== tab
      );
      let filteredCountrySpecs = [];
      filteredCountrySpecsByInd.forEach((F) => {
        if (countryArray.includes(F.label)) {
          filteredCountrySpecs.push(F);
        }
      });
      // data copying for the selected methodologies and categories at market level
      const convertedCountrySpecs = filteredCountrySpecs.map((C) => {
        C.MethodologySpecs = C.MethodologySpecs.map((M) => {
          if (methodologiesArray.includes(M.Code)) {
            const findMS =  tempCountrySpecs.MethodologySpecs.find(
                  (MS) => MS.Code === M.Code
                )
             
            if (findMS) {
              advanceCopying.SelectedCategories.forEach((SC) => {
                switch (SC.value) {
                  case "RFQSchema":
                    M.Variables = M.Variables.map((x) => {
                      if(x.id === "citiesCoverage") {
                        return x
                      }
                      if (x.id != "numberOfWaves") {
                        // Not to copy number of Waves
                        return _.cloneDeep(
                          findMS.Variables.find((xy) => xy.id === x.id)
                        );
                      } else {
                        // copy the number of waves variable without value
                        let temp = _.cloneDeep(
                          findMS.Variables.find((xy) => xy.id === x.id)
                        );
                        delete temp.value;
                        return temp;
                      }
                    });
                    break;
                  default:
                    break;
                }
              });
              return M;
            }
            return M;
          }
          return M;
        });
        return C;
      });
      // updating the profile and project data
      let profileTemp = _.cloneDeep(profile);
      profileTemp.CountrySpecs = profileTemp.CountrySpecs.map(
        (obj) => convertedCountrySpecs.find((o) => o.id === obj.id) || obj
      );
      profileTemp.WaveSpecs = _.cloneDeep(tempProfile.WaveSpecs);
      const tempProfiles = project.CostingProfiles.map((cp) => {
        if (cp.id == profileTemp.id) return { ..._.cloneDeep(profileTemp) };
        return { ..._.cloneDeep(cp) };
      });
      dispatch(updateProject({ CostingProfiles: tempProfiles }));
      setModalConfirmMethodology(false);
      toastr.success(
        "Success",
        "Data copied successfully to the selected markets"
      );
    }
  };

  /**
   * data transformation for the modal
   * @param {index} tab
   */

  const handleIntersection = (filteredMethodologyArr) => {

    const costingOptionArray = []
    const rfqArray = []
    const setupAndDeliveryArray = []

    const intersection = filteredMethodologyArr.map((element) => {
      const filteredCostingOp = 
      !pacificMarkets.includes(profile.CountrySpecs[0].CountryCode)||
      (_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0") ? 
      element?.costingOption.filter(data => filteredMethodology[activeTabSM]?.costingOption.includes(data)) : [];
      const filteredSetupAndDelivery = element?.setupAndDelivery.filter(data => filteredMethodology[activeTabSM]?.setupAndDelivery.includes(data))
      const filteredRfq = element?.rfqData.filter(data => filteredMethodology[activeTabSM]?.rfqData.includes(data))
      

      const filteredArrayData = []
      if(filteredCostingOp.length) { 
        filteredArrayData.push("Costing Options"); 
        costingOptionArray.push(element.name)
      }
      if(filteredSetupAndDelivery.length) {
        filteredArrayData.push("Setup & Delivery"); 
        setupAndDeliveryArray.push(element.name) 
      }
      if(filteredRfq.length){ 
        filteredArrayData.push("RFQ Form"); 
        rfqArray.push(element.name)
      }
      
      return { ...element, filteredArrayData }
    });

    return intersection
  }

  const handleMCategories = (intersection) => {

    const filteredDataMethodology = intersection.filter((item, index) => index !== activeTabSM)

    const catData = []
    filteredDataMethodology.map((item, data) => 
      catData.push(...item.filteredArrayData)
    )

    const uniqueData =  uniq(catData)

    return uniqueData

  } 

  const handleModalData = (tab) => {
    profile = _.cloneDeep(currentCosting);
    const convertedCountries = profile.CountrySpecs
    .filter(
      (C, ind) => countrySpecsLength === 1 ? C : 
      ind!== tab
    )
    .map((fc) => ({ label: fc.label, value: fc.id, ColSpan: null }));

    
    const  methodologiesArray =   profile.CountrySpecs[
      tab
    ].MethodologySpecs

   
    const intersection = countrySpecsLength === 1 ? handleIntersection(filteredMethodology) : []

    const uniqData = countrySpecsLength === 1 ?  handleMCategories(intersection) : []


    const methodologiesArrayData = profile.CountrySpecs?.length === 1 
    ? 
      methodologiesArray.filter(data => data.label !== singleCountryMethodologySpecs && !data.NotApplicable) 
    : 
      methodologiesArray

    const convertedMethodologies = methodologiesArrayData.map((MS) => ({
      label: MS.label,
      value: MS.Code,
      ColSpan: null,
    }));
    const selectConvertedCategories = profile.CountrySpecs?.length === 1 ? modalCategoriesSingleCountry : 
    modalCategories
    const convertedCategories = selectConvertedCategories.filter((MC) => (
      countrySpecsLength === 1 ? 
      uniqData.includes(MC.label) &&
      {
        label: MC.label,
        value: MC.value,
        ColSpan: null,
      }
      : 
      {
        label: MC.label,
        value: MC.value,
        ColSpan: null,
      }
    ));

    const Countries = _.cloneDeep(convertedCountries);
    const MethodologiesSpec = _.cloneDeep(convertedMethodologies);
    const Categories = _.cloneDeep(convertedCategories);
    if (convertedCountries.length > 0) {
      setAdvanceCopying({
        Countries: Countries,
        Methodologies: MethodologiesSpec,
        Categories: Categories,
        Mode: "Selection",
        SelectedMarketInd: tab,
        SelectedCountries: Countries,
        SelectedMethodologies: MethodologiesSpec,
        SelectedCategories: Categories,
        intersectionCopying: intersection ? intersection : [],
        // SelectedCopyMethod: COPYING_METHOD_VALUES.APPEND

      });
    }
    setModalMethodologiesState(true);
  
  };
  const handlePacificModalData = (tab) => {

    const convertedCountries = profile.CountrySpecs
    .filter(
      (C, ind) => countrySpecsLength === 1 ? C  : 
      ind !== tab
    )
    .map((fc) => ({ label: fc.label, value: fc.id, ColSpan: null }));

    const  methodologiesArray =   profile.CountrySpecs[
      tab
    ].MethodologySpecs

    const intersection =  countrySpecsLength === 1 ?  handleIntersection(filteredMethodology) : []

    const uniqData = countrySpecsLength === 1 ?  handleMCategories(intersection) : []

    const methodologiesArrayData = profile.CountrySpecs?.length === 1 
    ? 
      methodologiesArray.filter(data => data.label !== singleCountryMethodologySpecs && !data.NotApplicable) 
    : 
      methodologiesArray

    const convertedMethodologies = methodologiesArrayData.map((MS) => ({
      label: MS.label,
      value: MS.Code,
      id: MS.id,
      ColSpan: null,
    }));
    const selectConvertedCategories = profile.CountrySpecs?.length === 1 ? modalPacificCategoriesSingleCountry : 
    modalPacificCategories
    const convertedCategories = selectConvertedCategories.filter((MC) => (
      countrySpecsLength === 1 ?
      uniqData.includes(MC.label) &&
      {
        label: MC.label,
        value: MC.value,
        ColSpan: null,
      } 
      : 
      {
        label: MC.label,
        value: MC.value,
        ColSpan: null,
      }
    ));
    const Countries = _.cloneDeep(convertedCountries);
    const MethodologiesSpec = _.cloneDeep(convertedMethodologies);
    const Categories = _.cloneDeep(convertedCategories);
    if (convertedCountries.length > 0) {
      setAdvanceCopying({
        Countries: Countries,
        Methodologies: MethodologiesSpec,
        Categories: Categories,
        Mode: "Selection",
        SelectedMarketInd: tab,
        SelectedCountries: Countries,
        SelectedMethodologies: MethodologiesSpec,
        SelectedCategories: Categories,
        intersectionCopying: intersection ? intersection : [],
      });
    }
    setModalMethodologiesState(true);
  };
  /**
   * To validate the copy modal mandatory fields
   * @returns boolean value
   */

  function copytoalldisable() {
    let value =
      Array.isArray(profile?.FieldingCountries) &&
      profile?.FieldingCountries.length
        ? !_.includes(
            profile?.FieldingCountries?.map((fc) => fc.value),
            profile.CountrySpecs[activeTabFC].CountryCode
          )
        : !_.includes(
            profile?.FieldingCountries?.split(","),
            profile.CountrySpecs[activeTabFC].CountryCode
          );


    let methodologiesTabUnChecked =  profile?.CountrySpecs[activeTabFC].MethodologySpecs
    [activeTabSM].NotApplicable

    let methodologiesTabUnCheckedArray = 
    profile?.CountrySpecs[activeTabFC].MethodologySpecs.filter(item => !item.NotApplicable)

    return value || methodologiesTabUnChecked || (methodologiesTabUnCheckedArray.length === 1 && profile?.FieldingCountries.length === 1)
  }
 
  const validateCopyModal = () => {
    if (
      advanceCopying.SelectedCountries &&
      advanceCopying.SelectedMethodologies &&
      advanceCopying.SelectedCategories
    ) {
      return true;
    }
    toastr.error("Error", "All fields are mandatory");
    return false;
  };

  const confirmModalCopying = () => {
    const { 
      SelectedCountries, 
      SelectedMethodologies, 
      SelectedCategories, 
      SelectedCopyMethod
    } = advanceCopying

    const isCopyMethodEmpty = () => SelectedCategories.some(i => i.value === "CostingOptions") && _.isEmpty(SelectedCopyMethod) 

    if(!SelectedCountries.length || !SelectedMethodologies.length || !SelectedCategories?.length || 
      isCopyMethodEmpty()
    ) {
      toastr.error("Error", "All fields are mandatory")
    } else {
      if (validateCopyModal()) {
        setModalMethodologiesState(false);
        setModalConfirmMethodology(true);
      }
    }
    
  };


  return (
    <ChangesTracker.Provider value={{changes, hasDataChanged, deleteId}}>
    <React.Fragment>
       {localPageload || recordloading? (
      <div id="pageCoverSpin"></div>
    ) : null}
      {disableFields}
      <MyComponent />
      <Modal
        isOpen={waveModal}
        // toggle={() => setwaveModal(false)}
        size="sm"
      >
        <ModalHeader>
          {/* <img src={logo} style={imgStyle} className="modallogo" /> */}
        </ModalHeader>
        <span className="modaltitle p-0">
          Update Waves
        </span>
        <ModalBody>
          Number of Waves has been updated, so please save waves, in order to
          update Setup and delivery form.
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              saveDraft();
              setwaveModal(false);
            }}
          >
            Save Waves
          </Button>
          {/* <Button onClick={() => setwaveModal(false)}>
                        Close
                    </Button> */}
        </ModalFooter>
      </Modal>
      <Modal className="modal-with-header" isOpen={isModalOpen} toggle={() => handleOpen()} size="lg">
        <ModalHeader
          // className="modal-header-error"
          toggle={() => handleOpen(!isModalOpen)}
        >
          {/* <img src={logo} style={imgStyle} className="modallogo" /> */}

        </ModalHeader>

        <ModalBody id="scrollModalBody" className="modal-body-error-header">
          <div>
          <span className="modaltitle modalvalidationheader " style = {{color:"red"}}>
              {validationHeader}
            </span>
          </div>
          {/* <p>
            <b>
              Key Parameters information is missing at cost option level for
              following Country and Methodologies
            </b>
          </p> */}
          <br></br>
          <br></br>
          {Object.keys(errorObj)?.length ? (
            <>
            <div className="col-md-12">
              <Table id="errorTable">
              <thead>
                              <tr>
                                <th>Country</th>
                                <th>Methodology</th>
                                <th>Mandatory fields requiring input</th>
                              </tr>
                            </thead>

              {Object.keys(errorObj).map(function (key) {
                return (
                  <>

                      {((errorObj[key]?.CostingOptions &&
                      Object.keys(errorObj[key]?.CostingOptions).length) || 
                      (errorObj[key]?.RFQ &&
                        Object.keys(errorObj[key]?.RFQ).length) || 
                        (errorObj[key]?.SD &&
                          Object.keys(errorObj[key]?.SD).length)) ? (
                        <>
                              <tbody>
                              {Object.keys(errorObj[key]).includes("CostingOptions") ? Object.keys(errorObj[key]?.CostingOptions).map(
                                  function (key1) {
                                    return (
                                      <tr>
                                        {((Object.keys(errorObj[key]).includes("CostingOptions") && Object.entries(
                                          errorObj[key]?.CostingOptions[key1]
                                        ).length > 0) || (Object.keys(errorObj[key]).includes("RFQ") && 
                                        (Object.entries(errorObj[key]?.RFQ).some((  [key,value]   ) => key == key1  ))
                                         && Object.entries(
                                          errorObj[key]?.RFQ[key1]
                                        ).length > 0) || (Object.keys(errorObj[key]).includes("SD") && (Object.entries(errorObj[key]?.SD).some((  [key,value]   ) => key == key1  )) &&Object.entries(
                                          errorObj[key]?.SD[key1]
                                        ).length > 0)) && (
                                          <>
                                            <td>{key}</td>
                                            <td>{key1} </td>
                                            <td style = {{color:"red"}}>
                                            {errorObj[key]?.CostingOptions &&
                                              Object.keys(errorObj[key]?.CostingOptions[key1]).length ?
                                               errorObj[key]?.CostingOptions[
                                                key1
                                              ].map((item,index)=>(
                                                <div key1 ={index}>{item}</div>
                                )) : null}
                                             
                                             {errorObj[key]?.RFQ &&
                                              (Object.entries(errorObj[key]?.RFQ).some((  [key,value]   ) => key == key1  )) &&
                                                Object.keys(errorObj[key].RFQ[key1]).length ? 
                                                errorObj[key].RFQ[key1].map((item,index)=>(
                                                  <div key1 ={index}>{item}</div>
                                  )) : null}
                                              
                                              {errorObj[key]?.SD &&
                                              (Object.entries(errorObj[key]?.SD).some((  [key,value]   ) => key == key1  )) &&
                                                Object.keys(errorObj[key].SD[key1]).length ?
                                                errorObj[key].SD[key1]
                                                .map((item,index)=>(
                                                  <div key1 ={index}>{item}</div>
                                  )): null}
                                            </td>
                                          </>
                                        )}
                                      </tr>
                                    );
                                  }
                                ) : Object.keys(errorObj[key]).includes("RFQ") ? 
                                Object.keys(errorObj[key]?.RFQ).map(
                                  function (key1) {
                                    return (
                                      <tr>
                                        {((Object.keys(errorObj[key]).includes("CostingOptions") && Object.entries(
                                          errorObj[key]?.CostingOptions[key1]
                                        ).length > 0) || (Object.keys(errorObj[key]).includes("RFQ") && 
                                        (Object.entries(errorObj[key]?.RFQ).some((  [key,value]   ) => key == key1  )) && Object.entries(
                                          errorObj[key]?.RFQ[key1]
                                        ).length > 0) || (Object.keys(errorObj[key]).includes("SD") &&
                                        (Object.entries(errorObj[key]?.SD).some((  [key,value]   ) => key == key1  )) && Object.entries(
                                          errorObj[key]?.SD[key1]
                                        ).length > 0)) && (
                                          <>
                                            <td>{key}</td>
                                            <td>{key1} </td>
                                            <td style = {{color:"red"}}>
                                              {errorObj[key]?.CostingOptions &&
                                              Object.keys(errorObj[key]?.CostingOptions[key1]).length ?
                                               errorObj[key]?.CostingOptions[
                                                key1
                                              ].map((item,index)=>(
                                                <div key1 ={index}>{item}</div>
                                )) : null}
                                             
                                             {errorObj[key]?.RFQ &&
                                              (Object.entries(errorObj[key]?.RFQ).some((  [key,value]   ) => key == key1  )) &&
                                                Object.keys(errorObj[key].RFQ[key1]).length ? 
                                                errorObj[key].RFQ[key1].map((item,index)=>(
                                                  <div key1 ={index}>{item}</div>
                                  )) : null}
                                             
                                             {errorObj[key]?.SD &&
                                              (Object.entries(errorObj[key]?.SD).some((  [key,value]   ) => key == key1  )) &&
                                                Object.keys(errorObj[key].SD[key1]).length ?
                                                errorObj[key].SD[key1]
                                                .map((item,index)=>(
                                                  <div key1 ={index}>{item}</div>
                                  )) : null}
                                            </td>
                                          </>
                                        )}
                                      </tr>
                                    );
                                  }
                                ): Object.keys(errorObj[key]).includes("SD") ? 
                                Object.keys(errorObj[key]?.SD).map(
                                  function (key1) {
                                    return (
                                      <tr>
                                        {((Object.keys(errorObj[key]).includes("CostingOptions") && Object.entries(
                                          errorObj[key]?.CostingOptions[key1]
                                        ).length > 0) || (Object.keys(errorObj[key]).includes("RFQ") && (Object.entries(errorObj[key]?.RFQ).some((  [key,value]   ) => key == key1  )) && Object.entries(
                                          errorObj[key]?.RFQ[key1]
                                        ).length > 0) || (Object.keys(errorObj[key]).includes("SD") &&
                                        (Object.entries(errorObj[key]?.SD).some((  [key,value]   ) => key == key1  )) && Object.entries(
                                          errorObj[key]?.SD[key1]
                                        ).length > 0)) && (
                                          <>
                                            <td>{key}</td>
                                            <td>{key1} </td>
                                            <td style = {{color:"red"}}>
                                              {errorObj[key]?.CostingOptions &&
                                              Object.keys(errorObj[key]?.CostingOptions[key1]).length ?
                                               errorObj[key]?.CostingOptions[
                                                key1
                                              ].map((item,index)=>(
                                                <div key1 ={index}>{item}</div>
                                )) : null}
                                             
                                             {errorObj[key]?.RFQ &&
                                              (Object.entries(errorObj[key]?.RFQ).some((  [key,value]   ) => key == key1  )) &&
                                                Object.keys(errorObj[key].RFQ[key1]).length ? 
                                                errorObj[key].RFQ[key1].map((item,index)=>(
                                                  <div key1 ={index}>{item}</div>
                                  )) : null}
                                             
                                             {errorObj[key]?.SD &&
                                              (Object.entries(errorObj[key]?.SD).some((  [key,value]   ) => key == key1  )) &&
                                                Object.keys(errorObj[key].SD[key1]).length ?
                                                errorObj[key].SD[key1]
                                                .map((item,index)=>(
                                                  <div key1 ={index}>{item}</div>
                                  )) : null}
                                            </td>
                                          </>
                                        )}
                                      </tr>
                                    );
                                  }
                                ) : null
                              }
                                
                                       
                                  
                              </tbody>

                          {/* </Table> */}
                        </>
                      ) : null}

                    {/* </div>  */}
                  </>
                );
               })}  
               </Table>
               </div>
            </>
          ) : null}
          {/* {Object.keys(errorObj)?.length ? (
            <>
              {Object.keys(errorObj).map(function (key) {
                return (
                  <>
                    <div className="displayCountry col-md-12">
                      <h4>
                        <b>{key} :</b>
                      </h4>
                    </div>
                    <div className="col-md-12">
                      {errorObj[key]?.CostingOptions &&
                      Object.keys(errorObj[key]?.CostingOptions).length ? (
                        <>
                          <p>
                            <b>Costing Options Section:</b>
                          </p>
                          <Table id="errorTable">
                            <thead>
                              <tr>
                                <th>Methodologies</th>
                                <th>Fields</th>
                              </tr>
                            </thead>
                            {
                              <tbody>
                                {Object.keys(errorObj[key]?.CostingOptions).map(
                                  function (key1) {
                                    return (
                                      <tr>
                                        {Object.entries(
                                          errorObj[key]?.CostingOptions[key1]
                                        ).length > 0 && (
                                          <>
                                            <td>{key1} </td>
                                            <td>
                                              {errorObj[key]?.CostingOptions[
                                                key1
                                              ].join(", ")}
                                            </td>
                                          </>
                                        )}
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            }
                          </Table>
                        </>
                      ) : null}
                    </div>

                    <div className="col-md-12">
                      {errorObj[key]?.RFQ &&
                      Object.keys(errorObj[key].RFQ).length ? (
                        <>
                          <p>
                            <b>RFQ Form Section:</b>
                          </p>
                          <Table id="errorTable">
                            <thead>
                              <tr>
                                <th>Methodologies</th>
                                <th>Fields</th>
                              </tr>
                            </thead>
                            {
                              <tbody>
                                {Object.keys(errorObj[key].RFQ).map(function (
                                  key1
                                ) {
                                  return (
                                    <tr>
                                      <td>{key1} </td>
                                      <td>
                                        {errorObj[key].RFQ[key1].join(", ")}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            }
                          </Table>
                        </>
                      ) : null}
                    </div>
                    {(_.includes(
                      pacificMarkets,
                      project.CommissioningCountry
                    )  && project.CostingTemplateVersion != "v2.0")? (
                      <div className="col-md-12">
                        {errorObj[key]?.SD &&
                        Object.keys(errorObj[key].SD).length ? (
                          <>
                            <p>
                              <b>Setup and Delivery Section:</b>
                            </p>
                            <Table id="errorTable">
                              <thead>
                                <tr>
                                  <th>Methodologies</th>
                                  <th>Wave Number</th>
                                  <th>Fields</th>
                                </tr>
                              </thead>
                              {
                                <tbody>
                                  {Object.keys(errorObj[key].SD).map(function (
                                    key1
                                  ) {
                                    return Object.keys(
                                      errorObj[key].SD[key1].NO
                                    ).map(function (number) {
                                      return (
                                        <tr>
                                          <td>{key1}</td>
                                          <td>{number} </td>
                                          <td>
                                            {errorObj[key].SD[key1].NO[
                                              number
                                            ].join(", ")}
                                          </td>
                                        </tr>
                                      );
                                    });
                                  })}
                                </tbody>
                              }
                            </Table>
                          </>
                        ) : null}
                      </div>
                    ) : (
                      <div className="col-md-12">
                        {errorObj[key]?.SD &&
                        Object.keys(errorObj[key].SD).length ? (
                          <>
                            <p>
                              <b>Setup and Delivery Section:</b>
                            </p>
                            <Table id="errorTable">
                              <thead>
                                <tr>
                                  <th>Methodologies</th>
                                  <th>Fields</th>
                                </tr>
                              </thead>
                              {
                                <tbody>
                                  {Object.keys(errorObj[key].SD).map(function (
                                    key1
                                  ) {
                                    return (
                                      <tr>
                                        <td>{key1} </td>
                                        <td>
                                          {errorObj[key].SD[key1].join(", ")}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              }
                            </Table>
                          </>
                        ) : null}
                      </div>
                    )}
                  </>
                );
              })}
            </>
          ) : null} */}
        </ModalBody>
        <ModalFooter>
        <Button  color="primary" style = {{marginLeft:"300px"}}  onClick = {()=>gotoRFQ()}>
            Go to RFQ
          </Button>
          <Button style = {{backgroundColor:"white"}}  onClick={() => handleOpen(!isModalOpen)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {/* {costingStatus.showCostingInput &&
                <NewSpreadsheet saveDraft={saveDraft} userRecord={userRecord} profile={profile} updateProject={updateProject} />
            } */}
      {/* {costingStatus.showManualCostEntry && !showSpreadSheet &&
                <ManualCostEntry />
            } */}
      {costingStatus.showCommercialCosts && !showSpreadSheet && (
        <CommercialHours editDisabled={false} />
      )}
      {!costingStatus.showManualCostEntry &&
        !costingStatus.showCostingInput &&
        !costingStatus.showCommercialCosts &&
        !showSpreadSheet && (
          <>
            <Row>
              <Col md={12} className={"countryTabs tab-vertical"}>
                <div className={"tab mt-4"}>
                  <Nav tabs>
                    {profile.CountrySpecs &&
                      profile.CountrySpecs.map((fc, indxFC) => (
                        <NavItem key={fc.value}>
                          <NavLink
                            className={classnames({
                              active: activeTabFC === indxFC,
                            })}
                            onClick={() => {
                              dispatch(setNewCountryValue(indxFC))
                              toggle(indxFC);
                            }}
                          >
                            <b>{fc.label ? fc.label : fc.CountryName}</b>
                          </NavLink>
                        </NavItem>
                      ))}
                  </Nav>
                  <TabContent activeTab={activeTabFC}>
                    {profile.CountrySpecs &&
                      profile.CountrySpecs.map(
                        (fc, indxFC) =>
                          activeTabFC === indxFC && (
                            <TabPane tabId={indxFC} key={fc.value}>
                              <Row>
                                <Col md={12} className={"methodologyTabs"}>
                                  <Nav tabs>
                                    {fc.MethodologySpecs &&
                                      fc.MethodologySpecs.map((sm, indxSM) => (
                                        <NavItem key={sm.value}>
                                          <NavLink
                                            className={classnames({
                                              active: activeTabSM === indxSM,
                                            })}
                                            onClick={() => {
                                              dispatch(setNewMethValue(indxSM));
                                              toggleSM(indxSM);
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                              }}
                                            >
                                              <div className="hoverEffect">
                                                {sm.NotApplicable}
                                                <b>
                                                  {sm.label
                                                    ? sm.label
                                                    : sm.Label}
                                                </b>
                                              </div>
                                              <div>
                                                {(!_.includes(
                                                  pacificMarkets,
                                                  project.CommissioningCountry
                                                ) ||(_.includes(pacificMarkets, project.CommissioningCountry) && project.CostingTemplateVersion == "v2.0")) &&
                                                  (profile.CountrySpecs.length >
                                                    1 ||
                                                    fc.MethodologySpecs.length >
                                                      1) &&
                                                  profile.CountrySpecs[
                                                    activeTabFC
                                                  ].MethodologySpecs[
                                                    activeTabSM
                                                  ].CostingOptions?.some((co) =>
                                                    co.Variables?.some(
                                                      (v) => v.value
                                                    )
                                                  ) && (
                                                    <FontAwesomeIcon
                                                      title="Copy data"
                                                      className="align-middle"
                                                      icon={faCopy}
                                                      style={{
                                                        cursor: "pointer",
                                                        marginLeft: "10px",
                                                      }}
                                                      onClick={() => {
                                                        CopyClicked(
                                                          indxFC,
                                                          indxSM
                                                        );
                                                      }}
                                                    />
                                                  )}
                                                {
                                                  // <FontAwesomeIcon
                                                  //     title="Delete Methodology"
                                                  //     className="align-middle mr-2 deleteButton"
                                                  //     icon={faTimesCircle}
                                                  //     fixedWidth
                                                  //     style={{ cursor: "pointer", marginLeft: "10px" }}
                                                  //     onClick={() => {
                                                  //         if (window.confirm('do you want to delete the methodology?'))
                                                  //             removeMethodology(indxFC, indxSM)
                                                  //     }}
                                                  // />

                                                  //todo: move all inline styles into corporate.scsss
                                                  <div className="ml-5">
                                                    <Input
                                                      type="checkbox"
                                                      style={{
                                                        cursor: "pointer",
                                                        marginTop:
                                                          profile.CountrySpecs
                                                            .length === 1 ||
                                                         ( _.includes(
                                                            pacificMarkets,
                                                            project.CommissioningCountry
                                                          )  && project.CostingTemplateVersion != "v2.0") 
                                                            ? "-0.5rem"
                                                            : "-0.80rem",
                                                        marginLeft:
                                                          profile.CountrySpecs
                                                            .length === 1
                                                            ? ""
                                                            : "-1rem",
                                                      }}
                                                      name={
                                                        fc.MethodologySpecs[
                                                          indxSM
                                                        ].Label
                                                      }
                                                      id={
                                                        fc.MethodologySpecs[
                                                          indxSM
                                                        ].Label
                                                      }
                                                      title="Not Applicable/ Not Fielding"
                                                      checked={
                                                        fc.MethodologySpecs[
                                                          indxSM
                                                        ].NotApplicable
                                                          ? false
                                                          : true
                                                      }
                                                      onClick={(e) => {
                                                        if (
                                                          window.confirm(
                                                            `${
                                                              e.target.checked
                                                                ? `Do you want to apply the methodology ?`
                                                                : `Do you don't want to apply the methodology ?`
                                                            }`
                                                          )
                                                        )
                                                          removeMethodology(
                                                            indxFC,
                                                            indxSM,
                                                            e.target.checked
                                                          );
                                                        else e.preventDefault();
                                                      }}
                                                    />
                                                  </div>
                                                }
                                              </div>
                                            </div>
                                          </NavLink>
                                        </NavItem>
                                      ))}
                                  </Nav>
                                  <TabContent
                                    activeTab={activeTabSM}
                                    style={{ padding: "0.25" }}
                                  >
                                    {fc.MethodologySpecs &&
                                      fc.MethodologySpecs.map(
                                        (sm, indxSM) =>
                                          activeTabSM === indxSM && (
                                            <TabPane
                                              tabId={indxSM}
                                              key={sm.value}
                                            >
                                              {_.includes(
                                                pacificMarkets,
                                                project.CommissioningCountry
                                              )&& project.CostingTemplateVersion != "v2.0"? null 
                                              : 
                                              // oppValue && product.map((item,index) => (
                                                
                                                  <CostingOption
                                                    profile={currentCosting}
                                                    countryIndex={indxFC}
                                                    methodology={sm}
                                                    methodologyIndex={indxSM}
                                                    updateProject={updateProject}
                                                    errorFieldsObj={errorObj}
                                                    isEditable={
                                                      ValueChangeCheckInChild
                                                    }
                                                    disableFields={disableFields}
                                                  />
                                                
                                              // ))
                                              }
                                              <TestPageRFQ
                                                profile={currentCosting}
                                                indxFC={indxFC}
                                                methodology={sm}
                                                indxSM={indxSM}
                                                updateProject={updateProject}
                                                errorFieldsObj={errorObj}
                                                showErrorMessages={
                                                  showErrorMessages
                                                }
                                                isEditable={
                                                  ValueChangeCheckInChild
                                                }
                                                disableFields={disableFields}
                                                setWaveModal={setwaveModal}
                                              />

                                              {_.includes(
                                                pacificMarkets,
                                                project.CommissioningCountry
                                              )&&project.CostingTemplateVersion != "v2.0" ? (
                                                <WaveLevelEdit
                                                  profile={profile}
                                                  indxFC={indxFC}
                                                  methodology={sm}
                                                  indxSM={indxSM}
                                                  updateProject={updateProject}
                                                  showErrorMessages={
                                                    showErrorMessages
                                                  }
                                                  errorFieldsObj={errorObj}
                                                  isEditable={
                                                    ValueChangeCheckInChild
                                                  }
                                                  disableFields={disableFields}
                                                />
                                              ) : (
                                                <>
                                                  <TestPageOpResource
                                                    profile={profile}
                                                    indxFC={indxFC}
                                                    methodology={sm}
                                                    indxSM={indxSM}
                                                    updateProject={
                                                      updateProject
                                                    }
                                                    showErrorMessages={
                                                      showErrorMessages
                                                    }
                                                    errorFieldsObj={errorObj}
                                                    isEditable={
                                                      ValueChangeCheckInChild
                                                    }
                                                    disableFields={
                                                      disableFields
                                                    }
                                                  />
                                                </>
                                              )}
                                              {_.includes(
                                                pacificMarkets,
                                                project.CommissioningCountry
                                              )&& project.CostingTemplateVersion != "v2.0"? (
                                                <CommercialHours_internal
                                                  profile={profile}
                                                  indxFC={indxFC}
                                                  methodology={sm}
                                                  indxSM={indxSM}
                                                  updateProject={updateProject}
                                                  showErrorMessages={
                                                    showErrorMessages
                                                  }
                                                  errorFieldsObj={errorObj}
                                                  editable={false}
                                                  disableFields={disableFields}
                                                />
                                              ) : null}

                                              {profile.IsMultiCountry ? (
                                                (_.includes(
                                                  pacificMarkets,
                                                  project.CommissioningCountry
                                                ) && project.CostingTemplateVersion != "v2.0" )? (
                                                  <TestResponsibilities_internal
                                                    profile={profile}
                                                    indxFC={indxFC}
                                                    methodology={sm}
                                                    indxSM={indxSM}
                                                    updateProject={
                                                      updateProject
                                                    }
                                                    disableFields={
                                                      disableFields
                                                    }
                                                  />
                                                ) : (
                                                  <TestResponsibilities
                                                    profile={profile}
                                                    indxFC={indxFC}
                                                    methodology={sm}
                                                    indxSM={indxSM}
                                                    updateProject={
                                                      updateProject
                                                    }
                                                    disableFields={
                                                      disableFields
                                                    }
                                                  />
                                                )
                                              ) : null}
                                            </TabPane>
                                          )
                                      )}
                                  </TabContent>
                                </Col>
                              </Row>
                            </TabPane>
                          )
                      )}
                  </TabContent>
                </div>
              </Col>
            </Row>
            {/* // )} */}
            <Col className="justify-content-end">
              {profile.ProfileStatus == 99 ? null : (
                <>
                  {(
                    countrySpecsLength === 1 ?  
                    profile.CountrySpecs[0]?.MethodologySpecs?.length > 1 : false) || 
                    countrySpecsLength > 1 ? (
                      countrySpecsLength > 1 && <Button
                      className= {countrySpecsLength === 1 
                        ? 
                          "copy-to-all-Methodology copy-to-all-market cprofile-Methodology-btn"
                        : 
                          "copy-to-all-market costing-profile-btn" 
                      }
                      disabled={recordloading || copytoalldisable()}
                      color="primary"
                      onClick={() => {
                        if (
                          (!_.includes(
                            pacificMarkets,
                            project.CommissioningCountry
                          )|| (_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0"))
                        ) {
                          handleModalData(activeTabFC);
                        } else {
                          handlePacificModalData(activeTabFC);
                        }
                      }}
                    >
                      <FontAwesomeIcon
                                className="icon-costingprofile" 
                                icon={faCopy}
                                fixedWidth
                              />
                      {countrySpecsLength === 1 ? "Copy to all methodologies"  :
                      "Copy to all markets" }
                    </Button>
                  ) : null}
                  <Button
                    className="savedraft costing-profile-btn"
                    disabled={recordloading}
                    color="primary"
                    onClick={() => {
                      saveDraft();
                    }}
                  >
                    {/* {recordloading ?
                      <FontAwesomeIcon
                        class="saving-btn icon-costingprofile"
                       icon={faSpinner}
                        fixedWidth
                      />: */}

                    <FontAwesomeIcon
                                className="icon-costingprofile" 
                                icon={faSave}
                                fixedWidth
                              />
                    Save As Draft
                    
                  </Button>

                  <Button
                    className="validateandsave costing-profile-btn"
                    disabled={recordloading}
                    color="primary"
                    onClick={() => {
                      setCheckToggleSM(true);
                      let _profile = saveDraft(true);
                      dispatch(setOpportunityValue(""));
                      setTimeout(() => {
                        if (validateForms(_profile)) {
                          saveDraft(false);
                        }
                      });
                    }}
                  >
                    {/* {recordloading ? 
                      <FontAwesomeIcon
                        class="saving-btn icon-costingprofile"
                        // icon={faSpinner}
                        fixedWidth
                      />
                      : */}

                    <FontAwesomeIcon
                      className="icon-costingprofile" 
                      icon={faCheck}
                      fixedWidth
                    />
                    Validate and Save
                    {/* {recordloading && (
                      <FontAwesomeIcon
                        className="saving-btn icon-costingprofile"
                        icon={faSpinner}
                        fixedWidth
                      />
                    )} */}
                  </Button>
                  {_.includes(pacificMarkets, project.CommissioningCountry) && project.CostingTemplateVersion != "v2.0" ? (
                    <Button
                      className="savenewprofile costing-profile-btn"
                      disabled={recordloading}
                      color="primary"
                      onClick={setOpenNewProfile}
                    >
                      {/* {recordloading ?
                        <FontAwesomeIcon
                          class="saving-btn icon-costingprofile"
                          icon={faSpinner}
                          fixedWidth
                        />
                        : */}

                      <FontAwesomeIcon
                                className="icon-costingprofile" 
                                icon={faUserCheck}
                                fixedWidth
                              />
                      {/* } */}
                      Save As New Profile
                      
                    </Button>
                  ) : null}
                </>
              )}
            </Col>
          </>
        )}
      {!costingStatus.showCostingInput && (
        <Settings flagAlertoff={flagAlertoff} />
      )}
      <AvForm encType="multipart/form-data">
        <Modal
          isOpen={modelState}
          toggle={() => setModalState(!modelState)}
          centered
          size={copyProfile && copyProfile.Mode == "Selection" ? "xm" : "xl"}
        >
          <ModalHeader toggle={() => setModalState(!modelState)}>
            {/* <img src={logo} className="modallogo" /> */}
          </ModalHeader>
          <span className="modaltitle p-0">{"Copying Costing Options To Methodologies & Markets"}</span>
          <ModalBody className="m-3" style={styleModelBody}>
            {copyProfile && copyProfile.Mode == "Selection" && (
              <>
                <Row>
                  <Col md={12} className="selection-buttons-co-copy-m">
                    <Button
                      color="primary"
                      disabled={profile.CountrySpecs.length < 2}
                      onClick={() => {
                        CopyAsIsClicked();
                      }}
                    >
                      Copy As Is
                    </Button>
                    <Button
                      // disabled={profile.CountrySpecs[copyProfile.indexFC].MethodologySpecs[copyProfile.indexSM].label == "Path Tracking" || profile.CountrySpecs[copyProfile.indexFC].MethodologySpecs[copyProfile.indexSM].label == "Eye Tracking" || profile.CountrySpecs[copyProfile.indexFC].MethodologySpecs[copyProfile.indexSM].label == "Smart Store"}
                      disabled={_.includes(
                        ["SM000024", "SM000026", "SM000029"],
                        profile.CountrySpecs[copyProfile.indexFC]
                          .MethodologySpecs[copyProfile.indexSM].Code
                      )}
                      color="primary"
                      onClick={() => {
                        setCopyProfile((prevState) => ({
                          ...prevState,
                          Mode: "First",
                          SelectedCostingOptions: null,
                          SelectedCountries: null,
                        }));
                      }}
                    >
                      Advance Copy
                    </Button>
                  </Col>
                </Row>
              </>
            )}
            {copyProfile &&
              (copyProfile.Mode == "First" ||
                copyProfile.Mode == "CopyAsIs") && (
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Select Costing Options</Label>
                      <Select
                        name={"copyCostingOptions"}
                        value={copyProfile.SelectedCostingOptions}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={copyProfile.CostingOptions}
                        isMulti
                        onChange={(val) => {
                          setCopyProfile((prevState) => ({
                            ...prevState,
                            SelectedCostingOptions: val,
                          }));
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Select Countries</Label>
                      <Select
                        name={"copyCountries"}
                        value={copyProfile.SelectedCountries}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        menuPortalTarget={document.querySelector("modal-body")}
                        menuPosition={"fixed"}
                        options={copyProfile.Countries}
                        isMulti
                        onChange={(val) => {
                          setCopyProfile((prevState) => ({
                            ...prevState,
                            SelectedCountries: val,
                          }));
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}

            {copyProfile && copyProfile.Mode == "Second" && (
              <Table
                size="sm"
                bordered
                style={{
                  backgroundColor: "white",
                  minWidth: "100%",
                  width: "auto",
                }}
              >
                <thead>
                  <tr>
                    <th className={"copyCostingOptionFirstTable"}>
                      <span> Costing Option</span>
                    </th>
                    <th className={"copyCostingOptionFirstTable"}>
                      <span> Country</span>
                    </th>
                    <th className={"copyCostingOptionFirstTable"}>
                      <span> Select Methodologies</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {copyProfile.CountryWiseCostingOptions.map(
                    (cwco, indexCWCO) => (
                      <tr>
                        <td>{cwco.CostingOption.label}</td>
                        <td>{cwco.Country.label}</td>
                        <td>
                          <div>
                            <Select
                              name={"methodologies"}
                              value={cwco.SelectedMethodologies}
                              menuPortalTarget={document.querySelector(
                                "modal-body"
                              )}
                              menuPosition={"fixed"}
                              options={cwco.Methodologies}
                              isMulti
                              onChange={(val) => {
                                const copyProfileTemp = update(copyProfile, {
                                  CountryWiseCostingOptions: {
                                    [indexCWCO]: {
                                      $merge: { SelectedMethodologies: val },
                                    },
                                  },
                                });
                                setCopyProfile(_.cloneDeep(copyProfileTemp));
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            )}

            {copyProfile && copyProfile.Mode == "Save" && (
              <Row>
                <Col md={12} className={"tableCopyProject costingOptions"}>
                  <Table
                    size="sm"
                    bordered
                    style={{
                      backgroundColor: "white",
                      maxWidth: "100%",
                      width: "auto",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{ minWidth: "250px", maxWidth: "250px" }}
                        ></th>
                        {copyProfile.SelectedCountries.map((c, indexC) => (
                          <th
                            colSpan={c.ColSpan}
                            key={indexC}
                            style={{ minWidth: "250px", maxWidth: "250px" }}
                          >
                            {c.label}
                          </th>
                        ))}
                      </tr>
                      <tr>
                        <th></th>
                        {copyProfile.SelectedCountries.map((c, indexC) =>
                          c.Methodologies.map((m, indexM) => (
                            <th colSpan={m.ColSpan} key={indexC + "_" + indexM}>
                              {m.label}
                            </th>
                          ))
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th></th>
                        {copyProfile.SelectedCountries.map((c, indexC) =>
                          c.Methodologies.map((m, indexM) =>
                            m.CostingOptions.map((co, indexCO) => (
                              <th key={indexC + "_" + indexM + "_" + indexCO}>
                                {co.label}
                              </th>
                            ))
                          )
                        )}
                      </tr>
                      {copyProfile.CostingOptions[0].Variables.map(
                        (v, indexV) => (
                          <tr key={indexV}>
                            <td>{v.label}</td>
                            {copyProfile.SelectedCountries.map((c, indexC) =>
                              c.Methodologies.map((m, indexM) =>
                                m.CostingOptions.map((co, indexCO) => (
                                  <td
                                    key={indexC + "_" + indexM + "_" + indexCO}
                                  >
                                    {ReturnControl(
                                      indexV,
                                      indexC,
                                      indexM,
                                      indexCO
                                    )}
                                  </td>
                                ))
                              )
                            )}
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            )}
          </ModalBody>
          <ModalFooter>
            {copyProfile && copyProfile.Mode != "Selection" && (
              <>
                {copyProfile.Mode != "Selection" && (
                  <Button
                    color="primary"
                    onClick={() => {
                      CopyProfileActionClicked();
                    }}
                  >
                    {(copyProfile.Mode == "First" ||
                      copyProfile.Mode == "Second") &&
                      "Next"}
                    {(copyProfile.Mode == "Save" ||
                      copyProfile.Mode == "CopyAsIs") &&
                      " Save"}
                  </Button>
                )}
                {copyProfile.Mode != "Selection" && (
                  <Button
                    color="secondary"
                    onClick={() => {
                      CopyProfileActionClicked(true);
                    }}
                  >
                    Back
                  </Button>
                )}
              </>
            )}
            <Button color="secondary" onClick={() => setModalState(false)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </AvForm>
      {/** Modal: Copying data for market level - Start here */}
      <Modal
        isOpen={modalMethodologiesState}
        toggle={() => setModalMethodologiesState(!modalMethodologiesState)}
        centered
        size={"xm"}
      >
        <ModalHeader
          toggle={() => setModalMethodologiesState(!modalMethodologiesState)}
        >
          {/* <img src={logo}  className="modallogo" /> */}
        </ModalHeader>
        <div>
          <span className="modaltitle modaltitleDisplay">
          { `Copying ${
              profile.CountrySpecs && profile.CountrySpecs[activeTabFC]?.label
            } ${
              singleCountryMethodologySpecs
            } 
            Methodologies data to other applicable 
            ${countrySpecsLength === 1 
              ? 
                "methodologies" 
              : 
              "markets"
            }`
          }
          </span>
        </div>
        <ModalBody style={{ minHeight: "60px" }}>
          {countrySpecsLength > 1 
            ?
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Select Countries(*)</Label>
                    <Select
                      name={"selectCountries"}
                      value={advanceCopying.SelectedCountries}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      menuPortalTarget={document.querySelector("modal-body")}
                      menuPosition={"fixed"}
                      options={advanceCopying.Countries}
                      isMulti
                      onChange={(val) => {
                        setAdvanceCopying((prevState) => ({
                          ...prevState,
                          SelectedCountries: val ? val : [],
                        }));
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
             : 
              "" 
          } 
          <Row>
            <Col>
              <FormGroup>
              
                <Label>Select Methodologies(*)</Label>
                <Select
                  name={"selectMethodologies"}
                  value={advanceCopying.SelectedMethodologies}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  menuPortalTarget={document.querySelector("modal-body")}
                  menuPosition={"fixed"}
                  options={advanceCopying.Methodologies}
                  isMulti
                  onChange={(val) => {
                    countrySpecsLength > 1 ?
                      setAdvanceCopying((prevState) => ({
                        ...prevState,
                        SelectedMethodologies: val ? val : [],
                      }))
                    :
                      selectedMethodologiesFunc(val)
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Select copy behaviour for costing options(*)</Label>
                  <Col className="ml-2">
                    <CustomInput
                      id={COPYING_METHOD_VALUES.APPEND + '-radio'}
                      label="Append"
                      type="radio"
                      name={"selectCopyMethod"}
                      disabled={!advanceCopying?.SelectedCategories?.some(i => i.value === "CostingOptions")}
                      checked={advanceCopying.SelectedCopyMethod === COPYING_METHOD_VALUES.APPEND}
                      onChange={(val) => {
                        setAdvanceCopying((prevState) => ({
                          ...prevState,
                          SelectedCopyMethod: COPYING_METHOD_VALUES.APPEND,
                        }));
                      }}
                    />
                  </Col>
                  <Col className="ml-2">
                    <CustomInput
                      id={COPYING_METHOD_VALUES.OVERWRITE + '-radio'}
                      label="Overwrite"
                      type="radio"
                      name={"selectCopyMethod"}
                      checked={advanceCopying.SelectedCopyMethod === COPYING_METHOD_VALUES.OVERWRITE}
                      disabled={!advanceCopying?.SelectedCategories?.some(i => i.value === "CostingOptions")}
                      onChange={(val) => {
                        setAdvanceCopying((prevState) => ({
                          ...prevState,
                          SelectedCopyMethod: COPYING_METHOD_VALUES.OVERWRITE,
                        }));
                      }}
                    />
                  </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Select Categories(*)</Label>
                <Select
                  name={"selectCategories"}
                  value={advanceCopying.SelectedCategories}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  menuPortalTarget={document.querySelector("modal-body")}
                  menuPosition={"fixed"}
                  options={advanceCopying.Categories}
                  isMulti
                  onChange={(val) => {
                      setAdvanceCopying((prevState) => ({
                        ...prevState,
                        SelectedCategories: val ? val : [],
                      }));
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label>
                {" "}
                <strong>Note:</strong> Copying the information may change the
                data in selected{" "}
                {countrySpecsLength === 1 ?  "methodologies" : "markets"}.
              </Label>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              confirmModalCopying();
            }}
          >
            Yes
          </Button>
          <Button
            color="secondary"
            onClick={() => setModalMethodologiesState(false)}
          >
            No
          </Button>
        </ModalFooter>
      </Modal>
      {/** Modal: Copying data for market level - End here */}
      {/** Modal: Confirm data copying - Start here */}
      <Modal isOpen={modalConfirmMethodology} size={"sm"} centered={true}>
        <ModalHeader
          toggle={() => setModalConfirmMethodology(!modalConfirmMethodology)}
        >
          {/* <img src={logo}  className="modallogo" /> */}
        </ModalHeader>
        <div>
          <span className="modaltitle modaltitleDisplay">
            {`Confirm Copying Data`}
          </span>
        </div>
        <ModalBody style={{ minHeight: "60px" }}>
          <Label>{`You are about to copy the ${
            profile.CountrySpecs && profile.CountrySpecs[activeTabFC]?.label
          }'s data to other selected ${countrySpecsLength === 1 ?  "methodology" : "markets"  }. This action is irreversible. Are you sure want to proceed with copying data ?`}</Label>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              if (!_.includes(pacificMarkets, project.CommissioningCountry)
              ||(_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0")
              ) {
                countrySpecsLength === 1 
                  ? 
                    copyFormToMethodology(activeTabFC)
                  :
                    copyFormToCountries(activeTabFC)

              } else {
                countrySpecsLength === 1 
                ? 
                  copyFormToMethodology(activeTabFC)
                :
                  copyFormToPacificCountries(activeTabFC)
              }
            }}
          >
            Yes
          </Button>
          <Button
            color="secondary"
            onClick={() => setModalConfirmMethodology(false)}
          >
            No
          </Button>
        </ModalFooter>
      </Modal>
      {/** Modal: Confirm data copying - End here */}
      {costingStatus.showCostingInput && (
        <Button
          color="primary"
          onClick={() => {
            dispatch(
              setCostingStatus({
                ...costingStatus,
                showSheetsCosts: false,
                showManualCostEntry: false,
                showCommercialCosts: false,
                showCostingInput: false,
              })
            );
          }}
        >
          Back To RFQ
        </Button>
      )}
    </React.Fragment>
    </ChangesTracker.Provider>
  );
};
export default CountryMethodologyTabs;
