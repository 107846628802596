import React, { useEffect, useState } from "react";
import { Label, CustomInput, Row, Col, Input } from "reactstrap";
import Select from "react-select";
import Axios from "../../axios-interceptor";
import { useDispatch } from "react-redux";
import * as currentProjectActions from "../../redux/actions/currentProjectActions";

const ProjectContacts = ({
  user,
  project,
  updateProject,
  primaryCSContacts,
  otherInternalContacts,
  SalesTeamContactError
}) => {
  const ErrorMsgStyle = {
    color: 'red',
    fontSize: '12px'
  };
  const [allUsers, setAllUsers] = useState([])
  const dispatch = useDispatch();
  const [filteredOtherInternalContacts, setFilteredOtherInternalContacts] = useState([]);
  useEffect(()=>{
    Axios.get("/users/all/contacts").then((response)=>{
      setAllUsers(response?.data?.items)
    })
    if ((project?.ContractDetails?.[0]?.OpportunityOwnerEmail && !project?.SalesTeamContact?.value)) {
     dispatch({
          type: currentProjectActions.UPDATE_NEW_PROJECT,
          newProject: {
            SalesTeamContact: {
              label: project?.ContractDetails?.[0]?.OpportunityOwnerEmail,
              value: project?.ContractDetails?.[0]?.OpportunityOwnerEmail,
            }
          },
        });
    }
  },[])
  useEffect(() => {
    if (!(project?.ProposalOwnerEmail?.value)) {
      project.ProposalOwnerEmail = updateProject({
        ProposalOwnerEmail: {
          value: user.EmailId,
          label: user.EmailId,
        },
      });
    }
    const userBusinessUnits = user.BusinessUnits.split(",");
    if (user && otherInternalContacts && otherInternalContacts.length > 0) {
      let filteredContacts = otherInternalContacts.filter(item => {
        // if (item.BusinessUnits?.indexOf(',') > -1) {
        //   // const contactBusinessUnits = item.BusinessUnits.split(',');
        //   return userBusinessUnits.some(ubu => item.BusinessUnits.split(',').includes(ubu))
        // } else {
        //   return userBusinessUnits.includes(item.BusinessUnits)
        // }
        return (item.BusinessUnits?.indexOf(',') > -1? 
        userBusinessUnits.some(ubu => item.BusinessUnits.split(',').includes(ubu)):
        userBusinessUnits.includes(item.BusinessUnits))
      })
      setFilteredOtherInternalContacts(filteredContacts);
    }
  }, [project, user, otherInternalContacts]);
 
  return (
    <React.Fragment>
      <Row>
      <Col >
          <Row>
            <Col>
              <Label className="h5">Sales Team Contact</Label>
            </Col>
          </Row>
          <Row>
            <Col>
            {
              (project?.IsSyndicated  && !project?.ContractDetails[0]?.OpportunityNumber) ?
                  <Select
                  className="react-select-container mb-3 lg-12 md-12"
                  classNamePrefix="react-select"
                  options={
                    allUsers.map((item) => {
                      return { value: item.Email, label: item.Email};
                    }) || []}
                    isSearchable
                    value={project.SalesTeamContact}
                      // || {value: "alfred.stanley@tcs.com", label: "alfred.stanley@tcs.com"}}
                    //isDisabled= {!project?.Syndicated}
                    onChange={(e) => {                      
                      updateProject({ SalesTeamContact: e });
                    }}
                    />
                :
                <>
                
                <Input
               
                type="text" className="form-control" 
                value={project?.SalesTeamContact?.value || project?.ContractDetails[0]?.OpportunityOwnerEmail}
                onChange={(e) => {
                  updateProject({ SalesTeamContact: e});
                }}
                disabled={true}
                /> 
                </>
            
            } 
            </Col>
          </Row>
          {SalesTeamContactError && <span style={ErrorMsgStyle}>Please check Sales team contact selection</span>}
        </Col>
        <Col>
        </Col>
      </Row>
      <br/>
      <Row>
      
        <Col>
          <Row>
            <Col>
              <Label className="h5">{"Proposal Owner (CS)"}</Label>
            </Col>
          </Row>
          <Row>
            <Col>
            
              <Select
                className="react-select-container mb-3 lg-12 md-12"
                classNamePrefix="react-select"
                options={primaryCSContacts.map((item) => {
                  return { value: item.EmailId, label: item.EmailId };
                })}
                isSearchable
                value={project.ProposalOwnerEmail}
                isDisabled= {project?.ProjectStatus == "7"}
                onChange={(e) => {
                  updateProject({ ProposalOwnerEmail: e });
                }}
                
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <CustomInput
                type="switch"
                id="isSFContactSyncPaused"
                name="customSwitch"
                label="Pause Microsoft Dynamics Contacts Sync"
                className="h5"
                disabled = {project?.ProjectStatus == "7"}
                // defaultChecked={project.IsSFContactSyncPaused}
                checked={project.IsSFContactSyncPaused}
                onChange={(e) => {
                  updateProject({
                    IsSFContactSyncPaused: !project.IsSFContactSyncPaused,
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <CustomInput
                type="switch"
                id="isRestrictedProject"
                name="customSwitch"
                label="Restrict Project Access"
                className="h5"
                disabled = { project?.ProjectStatus == "7"}
                // defaultChecked={project.IsRestrictedProject}
                checked={project.IsRestrictedProject}
                onChange={(e) => {
                  updateProject({
                    IsRestrictedProject: !project.IsRestrictedProject,
                  });
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Label className="h5">Other Project Team Contacts</Label>
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            options={filteredOtherInternalContacts.map((item) => {
              return { value: item.Email, label: item.Email };
            })}
            isMulti
            isDisabled= {project?.ProjectStatus == "7"}
            isSearchable
            value={project.OtherProjectTeamContacts}
            onChange={(e) => updateProject({ OtherProjectTeamContacts: e ?? []})}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ProjectContacts;
