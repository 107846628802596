import React, { useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import ReactPaginate from 'react-paginate';
import { lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "@alexcambose/react-infinite-scroll";
import axios from "../../../axios-interceptor";
import { Badge } from "reactstrap";
import Navbar from "../../../components/Navbar";
import { useHistory } from "react-router-dom";
import { getLabel } from "../../../utils/codeLabels";
import * as projectsActions from "../../../redux/actions/projectsActions";
import * as currentCostingActions from "../../../redux/actions/currentCostingActions";
import {
  clearCurrentProject,
  saveProject,
  updateWbsLevel
} from "../../../redux/actions/currentProjectActions";
import ExpandableRows from "./ExpandableRows";
import DashboardLayout from "../../../layouts/Dashboard";
import RecordsSpinner from "../../../components/RecordsSpinner";
import RecordsBadge from "../../../components/RecordsBadge";
import "./Dashboard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClone,
  faCheck,
  faLock,
  faExclamationTriangle,
  faCheckCircle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import Spinner from "../../../components/Spinner";
import * as currentProjectActions from "../../../redux/actions/currentProjectActions";
import { filterCriteria } from "../../../redux/actions/filterCriteria/filterCriteria";
import ProjectScheduleWBSLevel from "../../projectSchedule/ProjectScheduleWBSLevel";
import { recordLoadEnd, recordLoadStart } from "../../../redux/actions/appActions";
import _ from "lodash";


// const RecordsSpinner = lazy(() => import('../../../components/recordsSpinner'));
// const RecordsBadge = lazy(() => import('../../../components/RecordsBadge'));
// const ExpandableRows = lazy(() => import('./ExpandableRows'));
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  Input,
  Label,
  CardTitle,
} from "reactstrap";
import logo from "../../../assets/img/avatars/modal-logo.png";
import darkI from "../../../assets/img/pulseIcon/dark.png";

import useAnalyticsEventTracker from "../../../utils/useAnalyticsEventTracker";
import * as EventName from "../../../utils/GoogleAnalyticsEvents";
import SelectWrapper from "../../../components/SelectWrapper/SelectWrapper";
import AdvanceFilterModel from "./AdvanceFilterModel";
import { objectOf } from "prop-types";

const Dashboard = () => {
  const userRecord = JSON.parse(localStorage.getItem("userRecord"));
  const gaEventTracker = useAnalyticsEventTracker(EventName.Dashboard);
  const [infiniteLoad, setInfiniteLoad] = useState(false);
  const [isOpen, handleOpen] = useState(false);
  const [getCriteria, setGetCriteria] = useState([]);
  const [selectedCountries, setCountries] = useState([]);
  const [selectedVerticals, setVerticals] = useState([]);
  const [selectedBusinessUnits, setBusinessUnits] = useState([]);
  const [selectedStatus, setStatus] = useState([]);
  const [defaultStatus, setDefaultStatus] = useState(true);
  const [currentSelectedProject, setCurrentSelectedProject] = useState({});
  const [currentWBS, setCurrentWBS] = useState([]);
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [isSavedCriteria, setIsSavedCriteria] = useState(false);
  const [modal, setModal] = useState(false);
  const [commercialAriba, setCommercialAriba] = useState([]);
  const [commercial, setCommercial] = useState([]);
  const [operationsAriba, setOperationsAriba] = useState([]);
  const [operations, setOperations] = useState([]);
  const app = useSelector(({ app }) => app);
  const [wbsScheduleData, setWbsScheduleData] = useState([]);
  const projects = useSelector(({ projects }) => projects.items);
  const totalItems = useSelector(({ projects }) => projects.totalItems);
  const hasMore = useSelector(({ projects }) => projects.hasMore);
  const countries =
    useSelector(({ codeLabels }) => codeLabels.UserScopeOptions) || [];
    let verticals =
    useSelector(({ codeLabels }) => codeLabels.VerticalOptions) || [];
  verticals = verticals.filter(vertical =>
    userRecord.Verticals.split(",").includes(vertical.Code)
  );
  let businessUnits =
    useSelector(({ codeLabels }) => codeLabels.BusinessUnitOptions) || [];
  businessUnits = businessUnits.filter(businessUnit =>
    userRecord.BusinessUnits.split(",").includes(businessUnit.Code)
  );
  const projectStatus =
    useSelector(({ codeLabels }) => codeLabels.ProjectStatusOptions) || [];
  const searchCharactors =
    useSelector(({ navbar }) => navbar.SearchCharactors) || "";
  const CommissionCheck=useSelector(({ codeLabels }) => codeLabels.CommissionedFlagOptions);
  const [canclledModal, setCancelledModal]= useState(false);
  const searchBy = useSelector(({ navbar }) => navbar.SearchBy) || "";
  const [clear, isClear] = useState(false);
  const [editStatusModal, setEditStatusModal] = useState(false);
  const [selected, setSelected] = useState('');
  const [projectList, selectProjects] = useState("myProject");
  const [selectedProjectStatus, setProjectStatus] = useState(null);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [warningModal,setWarningModal]=useState(false)
  const [isScheduleDates, setScheduleDate] = useState(false);
  const [finalProfile,setFinalProfile]=useState({})
  const [WarningMessage,setWarningMessage]=useState(null)


  const handleStartDate = (startDate) => {
   startDate = new Date(startDate)
 //  console.log(startDate)
  // startDate = startDate.split("T")[0]
   console.log(startDate)
   setStartDate(startDate);
  
  };

  const handleEndDate = (endDate) => {
    if(endDate>startDate){
   endDate = new Date(endDate)
  // console.log(endDate)
   //endDate = endDate.split("T")[0]
   console.log(endDate)
    setEndDate(endDate);
    }else{
      toastr.error("From Date should be greater than To Date");
    }

  };

  const isAuthenticated = useSelector(
    ({ user }) =>
      user.authToken !== null &&
      user.authToken !== "undefined" &&
      user.authToken !== ""
  );
  const isInitDataLoaded = useSelector(({ user }) => user.initDataError !== '' ? user.initDataError : '');

  let defaultCriteria = userRecord?.UserDefaultCriteria?.length > 0 ? userRecord.UserDefaultCriteria.find(e => e.Source == "Dashboard" ) : null

  const [crName, setCrName] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isWelcome, setWelcome] = useState(false);
  const [projectManagersList, setProjectManagersList] = useState([]);
  const [projectListFilter,setProjectListFilter] = useState([]);
  const [selectedCriteriaName, setSelectedCriteriaName] = useState("");
  const [selectedJsonData, setSelectedJsonData] = useState(null);
  // const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const itemsPerPage = "100";

  useEffect(() => {
    // Fetch items from another resources.    
    // const endOffset = Number(itemOffset) + Number(itemsPerPage);
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setPageCount(Math.ceil(totalItems / itemsPerPage));
  }, [itemsPerPage, totalItems]);


  const dropdownCr = (value) => {
    setStatus([
      ...new Set(
        getCriteria.filter((i) => i.id == value).map((e) => e.CriteriaData.ProjectStatus).flat()),
    ]);
    setBusinessUnits([
      ...new Set(
        getCriteria.filter((i) => i.id == value).map((e) => e.CriteriaData.BussinessUnits).flat()),
    ]);
    setVerticals([
      ...new Set(
        getCriteria.filter((i) => i.id == value).map((e) => e.CriteriaData.Verticals).flat()),
    ]);
    setCountries([
      ...new Set(
        getCriteria.filter((i) => i.id == value).map((e) => e.CriteriaData.CommissioningCountry).flat()
      ),
    ]);    
    setStartDate(     
      getCriteria.filter((e)=> e.id == value)[0].CriteriaData.startDate ? new Date(getCriteria.filter((e)=> e.id == value)[0].CriteriaData.startDate) : ''

     );
    setEndDate(
      getCriteria.filter((e)=> e.id == value)[0].CriteriaData.endDate ? new Date(getCriteria.filter((e)=> e.id == value)[0].CriteriaData.endDate) : ''
        );
   // console.log(getCriteria, "getCriteria")
  };

  useEffect(() => {
    dispatch({ type: "SEARCH_TYPE", SearchingType: "ID" });
    dispatch({ type: currentCostingActions.CLEAR_NEW_COSTING });
    dispatch({ type: currentProjectActions.CLEAR_NEW_PROJECT });
    axios
      .get("/users/opspms/all")
      .then((res) => {
        axios.get("/users/all/contacts").then((response)=>{
          setProjectListFilter(response.data.items)
        })
        setProjectManagersList(res.data.users);
      })
      .catch((err) => {
        // dispatch(appActions.pageLoadEnd());
      });
  }, []);

    useEffect(() => {
        if(isInitDataLoaded === false) {
            dispatch(projectsActions.getProjects({ myProject: true }));
        }        
    }, [isInitDataLoaded]);

    useEffect(() => {
        dispatch({ type: "SEARCH_CHARACTORS", Charactors: "" });
        dispatch(currentProjectActions.clearCurrentProject());
    }, []);

  const handleCookies = () => {
    setCookies();
    setWelcome(false);
  };

  const fetchMoreData = () => {
    // let jsonBody = selectedJsonData ? selectedJsonData :  getJson();
    // if (searchCharactors.length > 2)
    //   jsonBody = {
    //     ...jsonBody,
    //     [getSearchBy()]: searchCharactors,
    //   };
    // if (projects.length >= totalItems) {
    //   dispatch(projectsActions.setProjects({ hasMore: false }));
    //   return;
    // }
    // setInfiniteLoad(true);
    // axios
    //   .post("/projects/filter?limit=20&offset=" + projects.length, jsonBody)
    //   .then((res) => {
    //     dispatch(projectsActions.appendProjects(res.data.items));
    //     setInfiniteLoad(false);
    //   })
    //   .catch((err) => {
    //     toastr.error("Loading Failed", err.data.message);
    //     setInfiniteLoad(false);
    //   });
  };

  const fetchScheduleDatesData = async (profileId) => {
    setInfiniteLoad(true);
    await axios.get(`/deliveries/getSchedule/${profileId}`).then((res) => {
      // console.log("resresres", res);
      if (res.data.data.length) {
        setWbsScheduleData(res.data.data);
      }
      // debugger;
      setInfiniteLoad(false);
    }).catch((err) => {
      toastr.error("Failed fetching schedule data", err.data.message);
      setInfiniteLoad(false);
    });
    setEditStatusModal(true)
  }

  const isGreenLightProject = (projectCode, ContractDetails) => {
    let ProjectStatus = getLabel("ProjectStatusOptions", projectCode);
    if (
      ProjectStatus.toUpperCase() === "PROPOSAL" ||
      ProjectStatus.toUpperCase() === "NEGOTIATING"
    ) {
      var probabilityCheck = ContractDetails.filter(
        (obj) => obj.Probability == 100
      );
      if (probabilityCheck.length) return true;
    }
    return false;
  };

  useEffect(() => {
    dispatch({ type: "SEARCH_CHARACTORS", Charactors: "" });
    dispatch(currentProjectActions.clearCurrentProject());
  }, []);

  useEffect(() => {
    if(defaultCriteria) {
        setTimeout(() => {
            handleSelectedFilter(true)
          }, 0)    
    }else{
        dispatch(projectsActions.getProjects({ myProject: true }));
    }
  }, [getCriteria]);

  useEffect(() => {
    dispatch(projectsActions.setProjects({ hasMore: true }));
  }, [projects]);

  useEffect(() => {
    history.replace("/");
  }, [isAuthenticated]);

  useEffect(() => {
    // if (editStatusModal == false) {
    console.log("editStatusModaleditStatusModal", editStatusModal);
      setCommercial([]);
      setCommercialAriba([]);
      setOperations([]);
      setOperationsAriba([]);

  }, [editStatusModal]);

  const getPriceLabel = (priceValue) => {
    return  priceValue ===  1 
      ?  
       {title: "", color: "success" }
      : 
        priceValue ===  2 
      ? 
      {title: "Pre-approved", color : "secondary" }

      : 
        priceValue ===  3 
      ?  
      {title: "Project locked, create a new project ", color : "danger" }

      : 
        {title: "Project locked and Copied", color : "danger" }


  }

  const data = {
    tableColumns: [
      {
        dataField: "ProjectId",
        text: "PROJECT ID",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "10%", color:"white" };
        },
        formatter: (cell, row) => {
          return (
            <span>
              {cell}{" "}
              {row.ContractDetails && row.ContractDetails.length > 0 ? (
                isGreenLightProject(row.ProjectStatus, row.ContractDetails) ? (
                  <FontAwesomeIcon
                    className="success pointer"
                    icon={faCheckCircle}
                    title="Project ready for commissiong"
                  />
                ) : null
              ) : null}
            </span>
          );
        },
      },
      {
        dataField: "ProjectName",
        text: "PROJECT NAME",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { color:"white" };
        },
        formatter: (cell, row) => {
          return (
            <span>
              {cell}{" "}
              {row.IsImportedProject ? (
                <FontAwesomeIcon
                  className="warning pointer"
                  icon={faExclamationTriangle}
                  title="Project Migrated from v1. Some features may not be available."
                />
              ) : null}
            </span>
          );
        },
      },
      {
        dataField: "ProjectStatus",
        text: "STATUS",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "10%" , color:"white" };
        },
        formatter: (cell, row) => {
          const label = getLabel("ProjectStatusOptions", cell);
          const priceValue = 
          (row.IsSyndicated && row.SyndicatedwithOpp !== true) && row.ParentProjectId ? null :
          row?.PriceChange ? getPriceLabel(row.PriceChange) : null

          return (
            <div>
              <Badge
                color="secondary"
                className="pointer mr-2"
                onClick={() => {
                  setProjectStatus(row?.ProjectStatus)
                  setCurrentSelectedProject(row);
                  setCurrentWBS([]);
                  setCommercial([]);
                  setCommercialAriba([]);
                  setOperations([]);
                  setOperationsAriba([]);
                  fetchScheduleDatesData(row?.CostingProfiles?.filter(p => p?.ProfileStatus !== "99")[0]?.id)
                }}
              >
                {label}
              </Badge>
              {row.ContractDetails && row.ContractDetails.length > 0 ? (
                isGreenLightProject(row.ProjectStatus, row.ContractDetails) ? (
                  <Badge color="success" className="pointer">
                    Project ready for commissiong
                  </Badge>
                ) : null
              ) : null}
              {
                (row.CommissioningCountry === "AU" || row.CommissioningCountry === "NZ") 
                  || priceValue === null
                ? 
                  null 
                : 
                  <Badge color={priceValue.color} className="pointer">
                    {priceValue.title}
                  </Badge>
              }
            </div>
          );
        },
      },
      
      {
        dataField: "ProposalOwnerEmail",
        text: "PROPOSAL OWNER",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "20%", color:"white"  };
        },
        formatter: (cell) => {
          if (cell.value) {
            return cell.value
              .toLowerCase()
              .split("@")[0]
              .split(".")
              .map((word) => {
                return word.replace(word[0], word[0].toUpperCase());
              })
              .join(" ");
          } else {
            return "";
          }
        },
      },
      {
        dataField: "CommissioningCountry",
        text: "COUNTRY",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "15%" , color:"white" };
        },
        formatter: (cell) => {
          return getLabel("FieldingCountriesOptions", cell);
        },
      },
      {
        dataField: "createdAt",
        text: "CREATED DATE",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "10%" , color:"white" };
        },
        formatter: (cell) => {
          return cell.split("T")[0];
        },
      },
      {
        dataField: "CostingProfiles",
        text: "COMMISSIONED DATE",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return {  color:"white" };
        },
        sortFunc:(a,b,order)=>{
          console.log(typeof a,"typeof a")
          a=a[a.length-1]?.CommissionedDate
          b=b[b.length-1]?.CommissionedDate

          if(a==null && b==null)
          {
            return 0
          }
          if(a==null && b!=null)
          {
            return 1
          }
          if(a!=null && b==null)
          {
            return -1
          }
          if(typeof a ==="string" && typeof b==="string")
          {
            const dateA=new Date(a)
            const dateB=new Date(b)
            return order ==="asc" ?dateA - dateB : dateB -dateA
          }
          return 0
          
        },
        headerStyle: (colum, colIndex) => {
          return { width: "10%" , color:"white" };
        },
        formatter: (cell,row) => {
          return row?.CostingProfiles[row.CostingProfiles.length-1]?.CommissionedDate?row.CostingProfiles[row.CostingProfiles.length-1].CommissionedDate.split("T")[0]:null
        },
      },

      {
        dataField: "ProjectLiveDate",
        text: "PROJECT LIVE DATE",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "10%", color:"white"  };
        },
        formatter: (cell) => {
          let projectLiveDate = null;
          if(cell) {
            let dateObj = new Date(cell);
            let year = dateObj.getFullYear();
            let month = (dateObj.getMonth()+1).toString().padStart().padStart(2, '0');
            let day = dateObj.getDate().toString().padStart(2, '0');
            projectLiveDate = `${year}-${month}-${day}`;
  
            // let todaysDate = new Date();
            // let todaysYear = todaysDate.getFullYear();
            // let todaysMonth = (todaysDate.getMonth()+1).toString().padStart().padStart(2, '0');
            // let todaysDay = todaysDate.getDate().toString().padStart(2, '0');
            // today = `${todaysYear}-${todaysMonth}-${todaysDay}`;
            // if(today >= projectLiveDate) {
            return projectLiveDate;
            // } else {
            //   return null;
            // }
          }
        },
      },
    ],
    tableData: projects,
  };

  const onSearchBarValueChange = (chr) => {
    dispatch({ type: "SEARCH_CHARACTORS", Charactors: chr });
  };
  const onSearchBarTypeChange = (typ) => {
    dispatch({ type: "SEARCH_TYPE", SearchingType: typ });
    dispatch({ type: "SEARCH_CHARACTORS", Charactors: "" });
  };

  const onModalChange = () => {
    handleOpen(!isOpen);
    if(defaultCriteria && clear) {
        handleSelectedFilter()
    } 
  };

  function handleSelectedFilter(isMounted) {
    let criData = defaultCriteria.CriteriaData
    let id = radioList.filter(e => criData?.hasOwnProperty(e.id))[0]?.id
    let obj ={
        verticals : criData.Verticals?.length > 0 ? criData.Verticals : undefined,
        businessUnits : criData.BussinessUnits?.length > 0 ? criData.BussinessUnits : undefined,
        countries : criData.CommissioningCountry?.length > 0 ? criData.CommissioningCountry : undefined,
        projectStatus : criData.ProjectStatus?.length > 0 ? criData.ProjectStatus : undefined,
        startDate: criData.startDate ? new Date(criData.startDate): undefined,
        endDate: criData.endDate ? new Date(criData.endDate): undefined,
        [id]: true
    }

    if(isSavedCriteria) {
      setVerticals([]);
    setStatus([]);
    setCountries([]);
    setBusinessUnits([]);
    setSelected('');
    setStartDate('');
    setEndDate('')
    }else{
      selectProjects(id)
      setVerticals(obj.verticals)
      setBusinessUnits(obj.businessUnits);
      setStatus(obj.projectStatus)
      setCountries(obj.countries)
      setSelected(criData.selected)
      setStartDate(obj.startDate)
      setEndDate(obj.endDate)
    }
   

    if(isMounted && !isSavedCriteria) {
        if(criData.BussinessUnits?.length == 0 && criData.CommissioningCountry?.length == 0 
            && criData.ProjectStatus?.length == 0 && criData.Verticals?.length ==0) {
                isClear(false)
            }else{
                isClear(true)
            }
        if(obj.hasOwnProperty('myDefaultCriteria') && getCriteria.length > 0) {
            let cr = getCriteria.find(e => e.id.toString() == criData.selected.toString())
            setSelectedCriteriaName(cr?.CriteriaName)
            let prefixId = radioList.find(e => e.name == cr?.CriteriaName.split("-")[0].trim())?.id 
            obj[prefixId] = true
        }
        dispatch(projectsActions.getProjects(obj));
    }
  }
  const hanldeCountries = (code) => {
    const arrayList = selectedCountries ? [...selectedCountries] : [];
    if (!selectedCountries?.includes(code)) arrayList.push(code);
    else {
      let i = arrayList.indexOf(code);
      arrayList.splice(i, 1);
    }
    setCountries([...arrayList]);
  };
  const hanldeVerticals = (code) => {
    const arrayList = selectedVerticals ? [...selectedVerticals] : [];
    if (!selectedVerticals?.includes(code)) arrayList.push(code);
    else {
      let i = arrayList.indexOf(code);
      arrayList.splice(i, 1);
    }
    setVerticals([...arrayList]);
  };
  const hanldeBusinessUnits = (code) => {
    const arrayList = selectedBusinessUnits ? [...selectedBusinessUnits] : [];
    if (!selectedBusinessUnits?.includes(code)) arrayList.push(code);
    else {
      let i = arrayList.indexOf(code);
      arrayList.splice(i, 1);
    }
    setBusinessUnits([...arrayList]);
  };
  const hanldeProjectStatus = (code) => {
    const arrayList = selectedStatus ? [...selectedStatus] : [];
    if (!selectedStatus?.includes(code)) arrayList.push(code);
    else {
      let i = arrayList.indexOf(code);
      arrayList.splice(i, 1);
    }
    setStatus([...arrayList]);
  };

  const getJson = (type) => {
    return {
      verticals: selectedVerticals?.length > 0 ? selectedVerticals : undefined,
      businessUnits:
        selectedBusinessUnits?.length > 0 ? selectedBusinessUnits : undefined,
      countries: selectedCountries?.length > 0 ? selectedCountries : undefined,
      projectStatus: selectedStatus?.length > 0 ? selectedStatus : undefined,
      startDate: startDate ? startDate :undefined,
      endDate: endDate ? endDate : undefined,
      [type ? "myDefaultCriteria" : projectList]: true,
    };
  };

  const handleAdvancedFilter = (e, type, selectedCriteria, allCriteria) => { 
    handleOpen(false);
    dispatch({ type: "SEARCH_CHARACTORS", Charactors: "" });
    isClear(true);

    if(getJson(type).hasOwnProperty('myDefaultCriteria')) {
      let value = selectedCriteria ? selectedCriteria : selected
      let criteria = allCriteria ? allCriteria : getCriteria
        let obj = criteria.find(e => e.id.toString() == value.toString() )
        setSelectedCriteriaName(obj?.CriteriaName)
        var prefixId = radioList.find(e => e.name == obj?.CriteriaName.split("-")[0].trim())?.id 

        let data = getJson(type)
        data[prefixId] = true
        var checkCriteria = checkSameCriteria(obj, data )
        setSelectedJsonData(data)
        dispatch(projectsActions.getProjects(data));
    }else{
        setSelectedCriteriaName("")
        dispatch(projectsActions.getProjects(getJson()));
    }
    
    

    const criteriaInfo = {
      Source: "Dashboard",
      CriteriaData: {
        CriteriaName: getCriteria.map((e) => e.CriteriaName),
        ProjectStatus: selectedStatus,
        Verticals: selectedVerticals,
        CommissioningCountry: selectedCountries,
        BussinessUnits: selectedBusinessUnits,
        startDate: startDate,
        endDate: endDate
      },
    };

    if(!checkCriteria && getJson(type).hasOwnProperty('myDefaultCriteria')) {
      criteriaInfo.CriteriaData["selected"] = ""
      criteriaInfo.CriteriaData[prefixId] = true
      setSelectedCriteriaName("")
    }else if(type) {
      criteriaInfo.CriteriaData["myDefaultCriteria"] = true
      criteriaInfo.CriteriaData["selected"]  = selectedCriteria
    }else{
      criteriaInfo.CriteriaData[projectList] = true
      criteriaInfo.CriteriaData["selected"]  = selected
    }
    
    setIsSavedCriteria(false)
    dispatch(filterCriteria(criteriaInfo))
  };

  function checkSameCriteria (PreviousCriteria, ModifiedCriteria) {
      let prvCriteria = PreviousCriteria.CriteriaData

      if(!isEqualArr(prvCriteria.CommissioningCountry, ModifiedCriteria.countries) || 
      !isEqualArr(prvCriteria.ProjectStatus, ModifiedCriteria.projectStatus) ||
       !isEqualArr(prvCriteria.BussinessUnits, ModifiedCriteria.businessUnits) ||
       !isEqualArr(prvCriteria.Verticals, ModifiedCriteria.verticals) || 
       !isEqualArr(prvCriteria.startDate, ModifiedCriteria.startDate) ||
       !isEqualArr(prvCriteria.endDate, ModifiedCriteria.endDate)){
        return false
      }

      return true
  }


  function isEqualArr (array1, array2) {
    let isEqual;
    if(array1?.length > 0 && array2?.length > 0) {
       isEqual = (array1?.length === array2?.length) && (array1.every(val => array2.includes(val)));
    }
    else if(array1.length == 0 && !array2  || array1.length == 0 && array2.length == 0 ) {
      isEqual = true
    }

    return isEqual
  }

  const getSearchBy = () => {
    let searchType = searchBy;
    switch (searchType) {
      case "ID":
        searchType = "projectId";
        break;
      case "NAME":
        searchType = "projectName";
        break;
      case "OP":
        searchType = "opNumber";
        break;
      case "PO":
        searchType = "proposalOwner";
        break;
      case "AN":
        searchType = "accountName";
        break;
      case "PM":
        searchType = "projectmanager";
        break;
      case "WBS":
        searchType = "wbsNumber";
        break;  
      default:
        searchType = "projectId";
        break;
    }
    return searchType;
  };
  const [timelimit, setTimeLimit] = useState(0);
  const handleSearch = () => {
    if (timelimit) clearTimeout(timelimit);
    let jsonBody = selectedJsonData ? selectedJsonData :  getJson();
    if (searchCharactors.length >= 3 || searchCharactors.length === 0) {
      setTimeLimit(
        setTimeout(() => {
          jsonBody = {
            ...jsonBody,
            [getSearchBy()]: searchCharactors,
          };
          dispatch(projectsActions.getProjects(jsonBody));
          // isClear(true);
          // handleClear('search')
        }, 500)
      );
    }
  };
  const handleClear = (type) => {
    isClear(false);
    setVerticals([]);
    setStatus([]);
    setCountries([]);
    setBusinessUnits([]);
    setStartDate('')
    setEndDate('')
    if(!type) {
      dispatch({ type: "SEARCH_CHARACTORS", Charactors: "" });
      dispatch(projectsActions.getProjects({ myProject: true }));
    }
    selectProjects("myProject");
    setSelected('')
    setSelectedCriteriaName('')

    const criteriaInfo = {
        Source: "Dashboard",
        CriteriaData: {
          CriteriaName: defaultCriteria?.CriteriaData?.CriteriaName || [],
          ProjectStatus: [],
          Verticals: [],
          CommissioningCountry: [],
          BussinessUnits: [],
          "myProject": true,
          selected : "",
        },
        isClear : true,
      };
  
      dispatch(filterCriteria(criteriaInfo))

      if(selectedBusinessUnits?.length > 0 || selectedCountries?.length > 0 
        || selectedStatus?.length > 0 || selectedStatus?.length > 0 ) {
          toastr.success("Successfully cleared filtered");
        }
  };
  const setCookies = () => {
    var now = new Date();
    now.setTime(now.getTime() + 1 * 3600 * 1000 * 24 * 7);
    let expirationDate = new Date(
      new Date().getTime() + 1 * 3600 * 1000 * 24 * 7
    );
    let newValue = {
      value: false,
      expirationDate: expirationDate.toISOString(),
    };
    localStorage.setItem("appMsg", JSON.stringify(newValue));
  };
  
  const handleRadio = (value) => {
    selectProjects(value);

    if(value == "myDefaultCriteria" && getCriteria.length>0) {
      let value = getCriteria[0].id 
        dropdownCr( value)
    }else{
        setBusinessUnits([]);
        setCountries([]);
        setStatus([]);
        setVerticals([]);
        setStartDate('')
        setEndDate('')
    }
  };

  const handleDropdownSelection = (value) => {
        setSelected(value)
        dropdownCr(value);
  }
  const radioList = [
    { name: "My Projects", id: "myProject" },
    { name: "Team Projects", id: "teamProject" },
    { name: "All Projects", id: "allProjects" },
    { name: "My Default Criteria", id: "myDefaultCriteria" },
  ];

  const cancelOpen = () => {
    handleOpen();
    setIsSavedCriteria(false)
    if (!clear) {
      setCountries([]);
      setVerticals([]);
      setBusinessUnits([]);
      setStatus([]);
      setStartDate('');
      setEndDate('')
      
    }
  };


  const saveStatus = () => {
    if(selectedProjectStatus == 7 && currentSelectedProject.ProjectStatus == "1") {
      dispatch(
        saveProject({
                ...currentSelectedProject,
                ProjectStatus: "1",
              },
          () => setEditStatusModal(!editStatusModal),
          true
        )
      );   
    }else{
      dispatch(
        saveProject(
          defaultStatus
            ? {
                ...currentSelectedProject,
                ProjectStatus: "4",
              }
            : currentSelectedProject,
          () => setEditStatusModal(!editStatusModal),
          true
        )
      );
    }

    
    setDefaultStatus(true);
    setModal(false)
  };
  const handleupdate=async()=>{
    
    let CommissionCheckArr=CommissionCheck.filter((item)=>item.Code==="CommissionedFlag")[0]?.Label
    console.log(CommissionCheckArr,"CommissionCheckArr")
    let checkDate=new Date(CommissionCheckArr)
    let finalProfile = _.head(
      currentSelectedProject.CostingProfiles.filter((cp) => cp.ProfileStatus == "6")
    );
    let commissionDate=new Date(finalProfile.CommissionedDate)

    if(commissionDate>=checkDate)
    {
      dispatch(recordLoadStart());
      const res =await axios.get(`/deliveries/getSchedule/${finalProfile.id}`)
      dispatch(recordLoadEnd());
      let projectManagerCheck=false;
      let endDateCheck=false
      res.data.data.map((item)=>{
        // if (item.ActualFWStartDate !== null && item.ActualFWStartDate !== "") {
          console.log("commercialAriba, commercial, operations, operationsAriba", commercialAriba, commercial, operations, operationsAriba, finalProfile.WBSLevelCosts);
          let wbslevelflags = finalProfile.WBSLevelCosts.filter(c => c.WBSNumber == item.WBSNumber)
  // Function to get the most recent entry for a given WBSNUMBER
  const getMostRecentEntry = (array, wbsNumber) => {
    const filteredEntries = array
      .map((entry, index) => ({ ...entry, index }))
      .filter(entry => entry.WBSNUMBER === wbsNumber);
    if (filteredEntries.length === 0) return null;
    return filteredEntries.reduce((recent, entry) => (entry.index > recent.index ? entry : recent));
  };
 
  //  flags with their respective checks
  const flags = [
    { isFlag: wbslevelflags[0].IsCommercial, array: commercial, teamFlag: 'IsCommercial' },
    { isFlag: wbslevelflags[0].IsCommercialAriba, array: commercialAriba, teamFlag: 'IsCommercialAriba' },
    { isFlag: wbslevelflags[0].IsOperational, array: operations, teamFlag: 'IsOperational' },
    { isFlag: wbslevelflags[0].IsOperationalAriba, array: operationsAriba, teamFlag: 'IsOperationalAriba' }
  ];
 
  //  if all flags satisfy their conditions
  const allFlagsChecked = flags.every(flag => {
    const mostRecentEntry = getMostRecentEntry(flag.array, item.WBSNumber);
    if (mostRecentEntry) {
      return mostRecentEntry[flag.teamFlag] || flag.isFlag;
    }
    return mostRecentEntry || flag.isFlag; // If no entry is found, check mostRecentEntry or  flag
  });
          if(item.OPSProjectManager===null || item.OPSProjectManager == "")
            {
              // commercialAriba, commercial, operations, operationsAriba
              // // projectManagerCheck=true;
              // let isStatusChange = currentWBS.filter(c => c.WBSNUMBER == item.WBSNumber)
              if (allFlagsChecked) {
                projectManagerCheck=true;
              }
              // for (const condition of conditions) {
              //   if (condition.array.length > 0) {
              //     let isStatusChange = condition.array.filter(condition.filter);
              //     if (isStatusChange.length > 0) {
              //       projectManagerCheck = true;
              //       break; // Exit the loop if any condition is met
              //     }
              //   }
              // }
            }
            if(item.NewFWEndDate=== null || item.NewFWEndDate=== "")
            {
              // let isStatusChange = currentWBS.filter(c => c.WBSNUMBER == item.WBSNumber)
              if (allFlagsChecked) {
                endDateCheck=true;
              }
              // for (const condition of conditions) {
              //   if (condition.array.length > 0) {
              //     let isStatusChange = condition.array.filter(condition.filter);
              //     if (isStatusChange.length > 0) {
              //       endDateCheck = true;
              //       break; // Exit the loop if any condition is met
              //     }
              //   }
              // }
            }
        // }
      })

      if(projectManagerCheck&&endDateCheck)
      {
        setWarningMessage(1)
        setWarningModal(true)
      }
      else if(projectManagerCheck)
      {
        setWarningMessage(2)
        setWarningModal(true)
      }
      else if(endDateCheck)
      {
        setWarningMessage(3)
        setWarningModal(true)
      }
      else
      {
        setIsConfirmation(true)
      }
    }
    else{
      setIsConfirmation(true)
    }

  }



  const setWbsLevelStatus = () => {
    console.log("currentSelectedProjectcurrentSelectedProjectcurrentSelectedProject", currentSelectedProject)
    const keepMostRecentEntries = (array) => {
      const uniqueEntries = {};
      array.forEach((entry, index) => {
        if (!uniqueEntries[entry.WBSNUMBER] || uniqueEntries[entry.WBSNUMBER].index < index) {
          uniqueEntries[entry.WBSNUMBER] = { ...entry, index };
        }
      });
      return Object.values(uniqueEntries);
    };
     
    // Updating arrays to keep the most recent entries
    const commercialFilt = keepMostRecentEntries(commercial);
    const commercialAribaFilt = keepMostRecentEntries(commercialAriba);
    const operationsFilt = keepMostRecentEntries(operations);
    const operationsAribaFilt = keepMostRecentEntries(operationsAriba);

    const commercialFinalArray = commercialFilt.length === 0 ? [...commercialAribaFilt] : commercialFilt.map((item) => {
      const matchingItem = commercialAribaFilt.find((newItem) => newItem.WBSNUMBER === item.WBSNUMBER);
      return matchingItem ? {...item, ...matchingItem} : item;
    });

    commercialAribaFilt.forEach((newItem) => {
      if(!commercialFilt.some((item) => item.WBSNUMBER === newItem.WBSNUMBER)) {
        commercialFinalArray.push(newItem)
      }
    });

    const operationsFinalArray = operationsFilt.length === 0 ? [...operationsAribaFilt] : operationsFilt.map((item) => {
      const matchingItem = operationsAribaFilt.find((newItem) => newItem.WBSNUMBER === item.WBSNUMBER);
      return matchingItem ? {...item, ...matchingItem} : item;
    });

    operationsAribaFilt.forEach((newItem) => {
      if(!operationsFilt.some((item) => item.WBSNUMBER === newItem.WBSNUMBER)) {
        operationsFinalArray.push(newItem)
      }
    });

    const finalArray = commercialFinalArray.length === 0 ? [...operationsFinalArray] : commercialFinalArray.map((item) => {
      const matchingItem = operationsFinalArray.find((newItem) => newItem.WBSNUMBER === item.WBSNUMBER);
      return matchingItem ? {...item, ...matchingItem} : item;
    });

    operationsFinalArray.forEach((newItem) => {
      if(!commercialFinalArray.some((item) => item.WBSNUMBER === newItem.WBSNUMBER)) {
        finalArray.push(newItem)
      }
    });

    if(currentWBS.length !== 0 || finalArray.length !== 0) {
      if(currentWBS.length !== 0 && finalArray.length !== 0) {
        const newArray = finalArray.map((item) => {
          const matchingItem = currentWBS.find((newItem) => newItem.WBSNUMBER === item.WBSNUMBER);
          return matchingItem ? {...item, ...matchingItem} : item;
        });

        currentWBS.forEach((newItem) => {
          if(!finalArray.some((item) => item.WBSNUMBER === newItem.WBSNUMBER)) {
            newArray.push(newItem)
          }
        });

        dispatch(updateWbsLevel(
          {
            ...currentSelectedProject
          },
          [
            ...newArray
          ],
          () => setEditStatusModal(!editStatusModal),
          true
        ));

      } else if(finalArray.length === 0) {
        dispatch(updateWbsLevel(
          {
            ...currentSelectedProject
          },
          [
            ...currentWBS
          ],
          () => setEditStatusModal(!editStatusModal),
          true
        ));
      } else if(currentWBS.length === 0){
        dispatch(updateWbsLevel(
          {
            ...currentSelectedProject
          },
          [
            ...finalArray
          ],
          () => setEditStatusModal(!editStatusModal),
          true
        ));
      }
    } else {
      toastr.error("Need to select at least one wbs to close")
    }
    setModal(false);
    setCancelledModal(false);
    setIsConfirmation(false);
  };

  const checkOwnProperty = (obj, propertyName) =>
    (obj && (typeof obj == "object" || typeof obj == "function") &&
    Object.prototype.hasOwnProperty.call( obj, propertyName))
    ? true : false;

  const handleJSONData = (data) => {
    let parsedData;
    
    if(typeof data === 'string') {
      try {
        parsedData = JSON.parse(data);
      } catch( err ) {
        console.error("Error parsing json", err);
      }
    } else if(typeof data === 'object') {
      parsedData = data;
    } else{
      console.error("Invalid data type", typeof data);
    }

    if(typeof parsedData === 'string') {
      try {
        parsedData = JSON.parse(parsedData);
      } catch( err ) {
        console.error("Error parsing json", err);
      }
    }
    return parsedData;
  };

  const defaultValue = (WBSData) => {
    // let currentSelectedProject
    console.log("currentSelectedProject.CostingProfiles.length", currentSelectedProject, currentSelectedProject.CostingProfiles.length)
    let isRecommission = false;
    if (currentSelectedProject.CostingProfiles.length > 1) {
      isRecommission = true;
    }
    let defaultValue = "";
    let status;
    // IsCancel
    defaultValue = checkOwnProperty(WBSData, "IsClosed") 
    ? (WBSData.IsClosed ? "4" : (checkOwnProperty(WBSData, "IsCancel") 
    ? (WBSData.IsCancel ? "2" : isRecommission? "9": currentSelectedProject.ProjectStatus == "9" ? "9" : "3") 
    : isRecommission? "9": currentSelectedProject.ProjectStatus == "9" ? "9" :"3")) 
    : (checkOwnProperty(WBSData, "IsCancel") 
    ? (WBSData.IsCancel ? "2" : isRecommission? "9": currentSelectedProject.ProjectStatus == "9" ? "9" :"3") 
    : (checkOwnProperty(WBSData, "IsFieldwork") ? (WBSData.IsFieldwork ?  "98" : isRecommission? "9": currentSelectedProject.ProjectStatus == "9" ? "9" :"3"): isRecommission? "9": currentSelectedProject.ProjectStatus == "9" ? "9" :"3"));
    // if(checkOwnProperty(WBSData, "IsClosed")) {
    //   if(WBSData.IsClosed)
    //     defaultValue = "4";
    //   else if(checkOwnProperty(WBSData, "IsFieldwork")) {
    //     if(WBSData.IsFieldwork) 
    //       defaultValue = "98"
    //     else 
    //       defaultValue = "";
    //   } else {
    //     defaultValue = "";
    //   }
    // } else if(checkOwnProperty(WBSData, "IsFieldwork")) {
    //   if(WBSData.IsFieldwork) {
    //     defaultValue= "98";
    //   } else {
    //     defaultValue = "";
    //   }
    // } else if(checkOwnProperty(WBSData, "IsCancel")) {
    //   if(WBSData.IsCancel) {
    //     defaultValue = "2";
    //   } else {
    //     defaultValue = "";
    //   }
    // }

    currentWBS.forEach((obj, idx) => {
      if(defaultValue == '' && obj.WBSNUMBER == WBSData.WBSNumber){
        status = obj.status;
      }
    });
    if(status && status == "Closed") {
      defaultValue = "4";
    } else if (status && status == "Cancel") {
      defaultValue = "2";
    } else if (status && status == "Fieldwork") {
      defaultValue = "98";
    }
    if (!currentWBS?.length && currentSelectedProject.ProjectStatus == "11" && !WBSData.IsClosed && !WBSData.IsFieldwork && !WBSData.IsCancel) {
      // await fetchScheduleDatesData(currentSelectedProject?.CostingProfiles?.filter(p => p?.ProfileStatus !== "99")[0]?.id);
      // console.log("currentWBS, WBSData, wbsScheduleData", currentWBS, WBSData, wbsScheduleData);
      // console.log("WBS", WBSData.WBSNumber, wbsScheduleData)
      let currentWbsScheduleData = wbsScheduleData.filter(p => p.WBSNumber == WBSData.WBSNumber);
      if(currentWbsScheduleData?.length && currentWbsScheduleData[0]?.ActualFWStartDate) {
        defaultValue = "11";
      }
      // console.log("WBS and status", WBSData.WBSNumber, defaultValue)
      // debugger;

    }
    // } else {
    //   defaultValue = "";
    // }
    return defaultValue;
  };

  const isAnyClosed = currentWBS.some(obj => obj.status === "Closed");

  const isWBSDuplicate = (WBSData) => {    
    let isDuplicateWbsNumber = currentWBS.some(item => {
      return (item.WBSNUMBER === WBSData.WBSNumber)
    });
    return isDuplicateWbsNumber;
  };

  const handleCommercialChange = (WBSData) => {
    setCurrentSelectedProject({
      ...currentSelectedProject,
      CostingProfiles: currentSelectedProject.CostingProfiles.map((cp)=>({
        ...cp,
        ProductTableData:handleJSONData(cp.ProductTableData).map((ptd) => ({
          ...ptd,
          IsCommercial: ptd.WBSNumber === WBSData.WBSNumber ? !WBSData.IsCommercial : ptd.IsCommercial
        }))
      }))
    });
  }

  
  const handleCommercialAribaChange = (WBSData) => {
    setCurrentSelectedProject({
      ...currentSelectedProject,
      CostingProfiles: currentSelectedProject.CostingProfiles.map((cp)=>({
        ...cp,
        ProductTableData:handleJSONData(cp.ProductTableData).map((ptd) => ({
          ...ptd,
          IsCommercialAriba: ptd.WBSNumber === WBSData.WBSNumber ? !WBSData.IsCommercialAriba : ptd.IsCommercialAriba
        }))
      }))
    });
  }
  
  const handleOperationalChange = (WBSData) => {
    setCurrentSelectedProject({
      ...currentSelectedProject,
      CostingProfiles: currentSelectedProject.CostingProfiles.map((cp)=>({
        ...cp,
        ProductTableData:handleJSONData(cp.ProductTableData).map((ptd) => ({
          ...ptd,
          IsOperational: ptd.WBSNumber === WBSData.WBSNumber ? !WBSData.IsOperational : ptd.IsOperational
        }))
      }))
    });
  }
  
  const handleOperationalAribaChange = (WBSData) => {
    setCurrentSelectedProject({
      ...currentSelectedProject,
      CostingProfiles: currentSelectedProject.CostingProfiles.map((cp)=>({
        ...cp,
        ProductTableData:handleJSONData(cp.ProductTableData).map((ptd) => ({
          ...ptd,
          IsOperationalAriba: ptd.WBSNumber === WBSData.WBSNumber ? !WBSData.IsOperationalAriba : ptd.IsOperationalAriba
        }))
      }))
    });
  };

  const openScheduleModule=async()=>{
    dispatch(recordLoadStart());
    const res=await axios.get(`/costingprofiles/${currentSelectedProject.id}/commissioned`)
    dispatch(recordLoadEnd());
    setFinalProfile(
      res.data.costingProfile
    )
    setScheduleDate(true)
    setWarningModal(false)
    setEditStatusModal(false)
    
  }

  const handlePageClick = (event) => {
    const newOffset = ((event.selected) * Number(itemsPerPage));
    if (timelimit) clearTimeout(timelimit);
    let jsonBody = selectedJsonData ? selectedJsonData :  getJson();
    if (searchCharactors.length >= 3 || searchCharactors.length === 0) {
      jsonBody = {
        ...jsonBody,
        [getSearchBy()]: searchCharactors,
      };
    }
    // console.log(`User requested page number ${event.selected+1}, which is offset ${newOffset}`);
    // setItemOffset(newOffset);
    // dispatch(projectsActions.getProjectsPagewise(jsonBody, newOffset));
    setInfiniteLoad(true);

    axios
      .post(`/projects/filter?limit=${itemsPerPage}&offset=` + newOffset, jsonBody)
      .then((res) => {
        dispatch(projectsActions.setProjects(res.data));
        setInfiniteLoad(false);
      })
      .catch((err) => {
        toastr.error("Loading Failed", err.data.message);
        setInfiniteLoad(false);
      });
  };

  let length = Math.max(
    countries.length,
    verticals.length,
    projectStatus.length + 1, // adding 1 for dynamic project status(ready for commission)
    businessUnits.length
  );

  let role = "";
  if(userRecord.IsClientService && !userRecord.IsOpsProjectManager)
    role = "Commercial";
  else if(!userRecord.IsClientService && userRecord.IsOpsProjectManager)
    role = "Operations";
  else if(userRecord.IsClientService && userRecord.IsOpsProjectManager)
    role = "Admin";

  // let closedBy = userRecord?.FirstName + " " + userRecord?.LastName;

  // let isWBSClosed = currentSelectedProject?.CostingProfiles
  //   ?.filter((cp) => ["6"].indexOf(cp.ProfileStatus) !== -1)
  //   ?.map((CostingProfile) => 
  //   CostingProfile?.ProductTableData != undefined ? handleJSONData(CostingProfile?.ProductTableData)?.map((WBSData, index) => {
  //     return WBSData.closedBy;
  //   }):null);

  // if(isWBSClosed?.length > 0) {
  //   isWBSClosed = isWBSClosed[0];
  // }
  // console.log(isWBSClosed)

  return (
    <div className="dashboard_main">
      <DashboardLayout
        navbar={
          <Navbar
            headerTitle="DASHBOARD"
            onSearchBarValueChange={onSearchBarValueChange}
            onSearchBarTypeChange={onSearchBarTypeChange}
            handleOpen={onModalChange}
            handleSearch={handleSearch}
            handleClear={handleClear}
            searchCharactors={searchCharactors}
            clear={clear}
          />
        }
        //button={
        //  <Button
        //    style={{
        //      position: "fixed",
        //      bottom: "1.5em",
        //      right: "1.5em",
        //      borderRadius: "50%",
        //      fontSize: "2em",
        //      width: "2em",
        //      height: "2em",
        //      paddingTop: "0px",
        //    }}
        //    onClick={(e) => {
        //      history.push("/proposal");
        //      dispatch(clearCurrentProject());
        //    }}
        //  >
        //    +
        //  </Button>
        //}

        button={
          <img
            onClick={(e) => {
              gaEventTracker(EventName.NewProposal);
              history.push("/proposal-new");
              dispatch(clearCurrentProject());
            }}
            className="newProposalBtn"
            src={darkI}
          />
        }
      >
        <React.Fragment>
          <div className="pagination-dashboard">

          <h4  style={{display: "table-cell", width:"25%"}}>
            Showing {projects.length} of {totalItems} Projects  
           
          </h4>

          <div style={{display: "table-cell", width:"50%"}}>
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="< Previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
         
          {selectedCriteriaName ? (
              <div style={{display: "table-cell", width:"25%"}}>

            <h5  style={{'word-wrap': 'break-word', "text-align":'right'}}>
            {selectedCriteriaName.length > 50 ? (
              <>
               Criteria Name :  {`${selectedCriteriaName.substring(0, 50)}...`} 
              </>
            ) : (
              <>
              Criteria Name :  {selectedCriteriaName}
              </>
            )}
            </h5> 

            </div> 
             ) : (
              <>
              <div>
               <h5  style={{'word-wrap': 'break-word', "text-align":'right'}}>
               Criteria Name : Custom
               </h5>
               </div>
              </>
             )} 
                </div>
          {projects ? (
            <>
              <InfiniteScroll
                loadMore={fetchMoreData}
                hasMore={hasMore}
                isLoading={infiniteLoad}
                loading={<div id="pageCoverSpin"></div>}
                noMore={<RecordsBadge back_top={true} recordTypeLabel="projects" />}
                initialLoad={false}
              >
                <ExpandableRows
                  {...data}
                  projectManagersList={projectManagersList}
                  projectListFilter = {projectListFilter}
                  currentfilter = {getJson()}
                ></ExpandableRows>
              </InfiniteScroll>
            </>
          ) : null}
        </React.Fragment>
      </DashboardLayout>
      <AdvanceFilterModel
        isOpen={isOpen}
        handleOpen={handleOpen}
        cancelOpen={cancelOpen}
        radioList={radioList}
        projectList={projectList}
        handleRadio={handleRadio}
        length={length}
        projectStatus={projectStatus}
        hanldeProjectStatus={hanldeProjectStatus}
        selectedStatus={selectedStatus}
        countries={countries}
        hanldeCountries={hanldeCountries}
        selectedCountries={selectedCountries}
        verticals={verticals}
        selectedVerticals={selectedVerticals}
        hanldeVerticals={hanldeVerticals}
        businessUnits={businessUnits}
        hanldeBusinessUnits={hanldeBusinessUnits}
        selectedBusinessUnits={selectedBusinessUnits}
        handleAdvancedFilter={handleAdvancedFilter}
        dropdownCr={dropdownCr}
        crName={crName}
        setCrName={setCrName}
        selected={selected}
        setSelected={setSelected}
        getCriteria={getCriteria}
        setGetCriteria={setGetCriteria}
        handleDropdownSelection={handleDropdownSelection}
        startDate={startDate}
        endDate={endDate}
        handleStartDate={handleStartDate}
        handleEndDate={handleEndDate}
        setIsSavedCriteria={setIsSavedCriteria}
      ></AdvanceFilterModel>

      <Modal isOpen={isWelcome} toggle={() => setWelcome(!isWelcome)} size="lg">
        <ModalHeader toggle={() => setWelcome(!isWelcome)}>
          <CardTitle>Welcome to CI Central v4.0</CardTitle>
        </ModalHeader>
        <ModalBody>
          <p>
            <strong className="text-uppercase">
              Important note for our users
            </strong>
          </p>
          <p>
            After last few months of tireless development efforts, we are
            excited to finally launch the next-generation CI workflow platfrom
            to our global CI markets.
          </p>
          <p>
            CI Central v1 was built with a collabrative effort from a very small
            team of internal developers by leveraging Appmaker - Google Gsuite's
            low-code appplication development environment for building custom
            business apps.
          </p>
          <p>
            Last year, when the pandemic had just started to disrupt the global
            order, we were met with another setback of our own. Google announced
            the Appmaker shut down within just over 3 years since it's launch on
            19 January, 2021.
          </p>
          <p>
            Having to migrate CI Central meant that everthing in v2 had to be
            built from the very ground up. Although this provided us an
            excellent opportunity to address the v1 limitations, it also meant
            having to work within a very tight, hard deadline.
          </p>
          <p>
            Ideally, we would have liked to involve users from all markets in
            extensive training sessions and UAT pre-launch. Unfortunately, to
            prevent any disruptions across our 40+ CI markets already on the
            platform we had no option but to launch v2.0 before 19 Jan, 2021.
          </p>
          <p>
            In the coming weeks, your local market administrators and the global
            team will be reaching out to understand the customizations required
            for your markets. For various countries who have not yet recieved
            the training, you can expect these sessions to be scheduled in next
            few days.
          </p>
          <p>
            Along with some performance optimizations, we also aim to roll out
            various suggested feature improvements in weekly minor releases.
            Meanwhile, if you encounter any issues or bugs, please reach out to
            your local contact or the global team.
          </p>
          <p>
            <strong>
              <em>
                For existing projects migrated from previous versions, please
                remember to first select the project's Business Unit and
                Industry Vertical and saving before creating new costing
                profiles.
              </em>
            </strong>
          </p>
          <p>
            <strong>We thank you for your support!</strong>
          </p>
          <p>
            <strong>Team CI Central</strong>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={(e) => {
              handleCookies();
            }}
          >
            Do not show this message again
          </Button>{" "}
          {/* <Button color="secondary" onClick={() => setWelcome(false)}>
						No
					</Button> */}
        </ModalFooter>
      </Modal>

      {/* Modal for Project closure confirmation */}
      <Modal isOpen={isConfirmation} toggle={() => {setIsConfirmation(!isConfirmation);}} size="sm">
        <ModalHeader toggle={() => {setIsConfirmation(!isConfirmation);}}>
          <CardTitle>Project Closure: {currentSelectedProject.ProjectId}</CardTitle>
        </ModalHeader>
        <ModalBody>
          <p>
            <strong>
              {isAnyClosed ? "Are you sure you want to Close these WBS?" : "Are you sure you want to continue?"}
            </strong>
          </p>  
          <p>
            {currentWBS.map((wbs) => 
              wbs.status === "Closed" ? 
              (currentWBS.length > 1 
                ? `${wbs.WBSNUMBER}, `  
                : `${wbs.WBSNUMBER}`) 
              : "")
            }
          </p>       
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={setWbsLevelStatus}
          >
            Ok
          </Button>{" "}
          <Button color="secondary" onClick={() => {setIsConfirmation(false);}}>
						Cancel
					</Button>
        </ModalFooter>
      </Modal>

      {/* Status Change Modal */}
      <Modal
                isOpen={editStatusModal}
                toggle={() => {setEditStatusModal(!editStatusModal);setDefaultStatus(true);setModal(!modal);}}
                size="xl"
                style={{maxWidth: '100%', width: '100%'}}
                centered={true}
            >
                <ModalHeader toggle={() => {setEditStatusModal(!editStatusModal);setDefaultStatus(true);setModal(false)}}>
                {/* <img src={logo}  className="modallogo"/> */}
                    
                </ModalHeader>
                <div>
                <span className="modaltitle modaltitleprojectstatus">
                Change Project Status
                </span>
                </div>
                {/* <ModalBody>
                    <p>
                        <strong>{currentSelectedProject.ProjectName}</strong>
                        <br />
                        <strong>{currentSelectedProject.ProjectId}</strong>
                    </p>
                    <p>
                        <em>Project status change guidelines here.</em>
                    </p>
                    <p>Change Project Status To:</p>
                    <SelectWrapper

                        onChange={(e) =>

                            {setDefaultStatus(false);
                              setCurrentSelectedProject({

                                ...currentSelectedProject,

                                ProjectStatus: e.target.value,

                            })
                              if(e.target.label === "Project Closed"){

 

                                setModal(true)

                                return

 

                              }

                              else {

                                setModal(false)

                              }

                            

                          }

                        }

                        defaultValue={currentSelectedProject.ProjectStatus}

                    >

                      {selectedProjectStatus == 7 ? (

                          projectStatus

                          .filter((ps) => ["1"].indexOf(ps.Code) !== -1)

                          .map((ps, index) => (

                              <option key ={index} value={ps.Code}>{ps.Label}</option>

                          ))

                      ): (

                        projectStatus

                            .filter((ps) => ["4", "5", "98"].indexOf(ps.Code) !== -1)

                            .map((ps, index) => (

                              ps.Label!=="Archived"?<option key ={index} value={ps.Code}>{ps.Label}</option>:null

                            ))

                      )}

 

                    </SelectWrapper>
                    <Label className="mb-2 mt-2">Project Status Notes:</Label>
                    <Input
                        type="textarea"
                        id="NotesFinance"
                        //className="form-control"
                        onChange={(e) =>
                            setCurrentSelectedProject({
                                ...currentSelectedProject,
                                NotesProjectStatus: e.target.value,
                            })
                        }
                        value={
                            currentSelectedProject.NotesProjectStatus
                                ? currentSelectedProject.NotesProjectStatus
                                : ""
                        }
                        rows="3"
                        placeholder="Please provide any relevant notes any project status related notes here..."
                    />
                </ModalBody> */}
                <ModalBody>
                    <p>
                        <strong>{currentSelectedProject.ProjectName}</strong>
                        <br />
                        <strong>{currentSelectedProject.ProjectId}</strong>
                    </p>
                    <p>
                      <em>Project status change guidelines here.</em>
                    </p>
                    {currentSelectedProject.ProjectStatus == 3 
                    || currentSelectedProject.ProjectStatus == 9
                    || currentSelectedProject.ProjectStatus == 4 
                    || currentSelectedProject.ProjectStatus == 98 
                    ||currentSelectedProject.ProjectStatus == 2
                    ||currentSelectedProject.ProjectStatus == 11
                    ? 
                    <Table responsive hover striped bordered size="lg" style={{marginBottom:"80px"}}>
                      <thead>
                        <tr>
                          <th>
                            WBS Number
                          </th>
                          <th>
                            Material Id
                          </th>
                          <th>
                            Product Description
                          </th>
                          {/* <th>
                          Fieldwork Start Date (MSD)
                          </th>
                          <th>
                          Client Delivery Date (EOD) (MSD)
                          </th> */}
                          <th>
                            Sub Project Type
                          </th>
                          <th>
                            Methodology From MSD
                          </th>
                          {/* <th>
                            Closed By
                          </th>
                          <th>
                            Role
                          </th> */}
                          {/* <th style={{width:"100%"}}> */}
                          <th className="col-6">
                            Status                            
                          </th>
                          <th className="col-3">
                            Commercial project closure actions (Filled by proposal owner)
                          </th>
                          <th  className="col-7">
                            Operations project closure actions (Filled by project manager)
                          </th>
                          <th>
                            Project Closed Date
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                          {currentSelectedProject?.CostingProfiles
                          ?.filter((cp) => ["6"].indexOf(cp.ProfileStatus) !== -1)
                          ?.map((CostingProfile) => 
                          CostingProfile?.ProductTableData != undefined ? handleJSONData(CostingProfile?.ProductTableData).filter(c => c.WBSNumber != null && !c.isRed)?.map((WBSData, index) => {
                              return (
                                <tr key={WBSData.WBSNumber}> 
                                  <td>
                                    {WBSData.WBSNumber}  
                                  </td>                     
                                  <td>
                                    {WBSData.MaterialID}
                                  </td>
                                  <td>
                                    {WBSData.ProductDescription}
                                  </td>
                                  {/* <td>
                                    {WBSData.StartDate.split("T")[0]}
                                  </td>
                                  <td>
                                    {WBSData.EndDate.split("T")[0]}
                                  </td> */}
                                  <td>
                                    {WBSData.methodologyType}
                                  </td>
                                  <td>
                                    {/* {WBSData.methodology} */}
                                    {WBSData.CharacteristicValues?.Z_FIELDINGMETHOD[0] ? WBSData.CharacteristicValues.Z_FIELDINGMETHOD[0] : WBSData.methodology[0].value}
                                  </td>
                                  {/* <td>
                                    {WBSData.closedBy ? WBSData.closedBy : ""}
                                  </td>
                                  <td>
                                    {WBSData.role ? WBSData.role : ""}
                                  </td> */}
                                  <td className="col-6">
                                    <SelectWrapper
                                      onChange={(e) =>
                                          {                                            
                                            setDefaultStatus(false);  
                                            if(!isWBSDuplicate(WBSData)) {
                                              setCurrentWBS(prevWbs => [
                                                ...prevWbs,
                                                {
                                                  status:e.target.value == 4 ? "Closed" : (e.target.value == 98 ? "Fieldwork": e.target.value == 2 ? "Cancel" : ""),  
                                                  WBSNUMBER:WBSData.WBSNumber,
                                                  
                                                }
                                              ]);
                                            } else {
                                              setCurrentWBS([{
                                                status:e.target.value == 4 ? "Closed" : (e.target.value == 98 ? "Fieldwork": e.target.value == 2 ? "Cancel" : ""),  
                                                WBSNUMBER:WBSData.WBSNumber,
                                                
                                              }]);
                                            }                         
                                            if(e.target.label === "Project Closed"){                                              
                                              setModal(true);              
                                            }              
                                            else {              
                                              setModal(false)              
                                            }

                                            // setWbsData(preWBSData => [...preWBSData,{WBSData:WBSData}])
                                            if(e.target.label === "Project Cancelled"){
                                              setCancelledModal(true);
                                            }
                                            else{
                                              setCancelledModal(false);
                                            }
                                          }
                                        }
                                      disabled={WBSData.IsClosed || WBSData.IsCancel || (currentSelectedProject.ProjectStatus == 4)}
                                      defaultValue={defaultValue(WBSData)}
                                    >
                                      {  
                                              projectStatus
                                              .filter((ps) => ["4", "98", "2", "3", "11", "9"].indexOf(ps.Code) !== -1)
                                              .map((ps, index) => (
                                                  <option key ={index} value={ps.Code} isdisabled={ps.Code==4?true:false}>{ps.Label==="Recommissioned"?"Project Recommissioned" : ps.Label=== "Commissioned"?"Project Commissioned" :ps.Label}</option>
                                              ))
                                      }
        
                                    </SelectWrapper>
                                  </td>
                                  <td className="col-3">
                                    <input 
                                      id="isInputCsChecked"
                                      type="checkbox"
                                      name="isInputCsChecked"
                                      checked={WBSData.IsCommercial}
                                      disabled={role==="Operations" || WBSData.IsClosed || WBSData.IsCancel || (currentSelectedProject.ProjectStatus == 4)}
                                      onChange={(e) => {
                                        if(!isWBSDuplicate(WBSData)) {
                                          e.persist()
                                          handleCommercialChange(WBSData);
                                          setCommercial(prevWbs => [
                                            ...prevWbs,
                                            {
                                              // status:e.target.value == 4 ? "Closed" : (e.target.value == 98 ? "Fieldwork": e.target.value == 2 ? "Cancelled" : ""),  
                                              WBSNUMBER:WBSData.WBSNumber,
                                              IsCommercial:e.target.checked
                                            }
                                          ]);
                                        } else {
                                          setCommercial([{
                                            // status:e.target.value == 4 ? "Closed" : (e.target.value == 98 ? "Fieldwork": e.target.value == 2 ? "Cancelled" : ""),  
                                            WBSNUMBER:WBSData.WBSNumber,
                                            IsCommercial:e.target.checked
                                          }]);
                                        }
                                        // setIsCommercialChecked(e.target.checked)
                                      }}
                                    /> 
                                    <label style={{display:"inline", marginLeft:"5px"}}>All CS time costs (actuals) booked in SAP</label> 
                                    <br></br>
                                    <input 
                                      id="isInputAribaCsChecked"
                                      type="checkbox"
                                      name="isInputAribaCsChecked"
                                      checked={WBSData.IsCommercialAriba}
                                      disabled={role==="Operations" || WBSData.IsClosed || WBSData.IsCancel || (currentSelectedProject.ProjectStatus == 4)}
                                      onChange={(e) => {
                                        if(!isWBSDuplicate(WBSData)) {
                                          e.persist()
                                          handleCommercialAribaChange(WBSData);
                                          setCommercialAriba(prevWbs => [
                                            ...prevWbs,
                                            {
                                              // status:e.target.value == 4 ? "Closed" : (e.target.value == 98 ? "Fieldwork": e.target.value == 2 ? "Cancelled" : ""),  
                                              WBSNUMBER:WBSData.WBSNumber,
                                              IsCommercialAriba:e.target.checked
                                            }
                                          ]);
                                        } else {
                                          setCommercialAriba([{
                                            // status:e.target.value == 4 ? "Closed" : (e.target.value == 98 ? "Fieldwork": e.target.value == 2 ? "Cancelled" : ""),  
                                            WBSNUMBER:WBSData.WBSNumber,
                                            IsCommercialAriba:e.target.checked
                                          }]);
                                        }
                                      }}
                                    /> 
                                    <label style={{display:"inline", marginLeft:"5px"}}>All CS project/contractor actual costs booked in SAP</label>                                   
                                  </td>
                                  <td  className="col-7">
                                    <input 
                                      id="isInputOpChecked"
                                      type="checkbox"
                                      name="isInputOpChecked"
                                      checked={WBSData.IsOperational}
                                      disabled={role==="Commercial" || WBSData.IsClosed || WBSData.IsCancel || (currentSelectedProject.ProjectStatus == 4)}
                                      onChange={(e) => {
                                        if(!isWBSDuplicate(WBSData)) {
                                          e.persist()
                                          handleOperationalChange(WBSData);
                                          setOperations(prevWbs => [
                                            ...prevWbs,
                                            {
                                              // status:e.target.value == 4 ? "Closed" : (e.target.value == 98 ? "Fieldwork": e.target.value == 2 ? "Cancelled" : ""),  
                                              WBSNUMBER:WBSData.WBSNumber,
                                              IsOperational:e.target.checked
                                            }
                                          ]);
                                        } else {
                                          setOperations([{
                                            // status:e.target.value == 4 ? "Closed" : (e.target.value == 98 ? "Fieldwork": e.target.value == 2 ? "Cancelled" : ""),  
                                            WBSNUMBER:WBSData.WBSNumber,
                                            IsOperational:e.target.checked
                                          }]);
                                        }
                                      }}
                                    />
                                    <label style={{display:"inline", marginLeft:"5px"}}>All Ops time costs (actuals) booked in SAP</label> 
                                    <br></br>
                                    <input 
                                      id="isInputAribaOpChecked"
                                      type="checkbox"
                                      name="isInputAribaOpChecked"
                                      checked={WBSData.IsOperationalAriba}
                                      disabled={role==="Commercial" || WBSData.IsClosed || WBSData.IsCancel || (currentSelectedProject.ProjectStatus == 4)}
                                      onChange={(e) => {
                                        if(!isWBSDuplicate(WBSData)) {                                          
                                          e.persist()
                                          handleOperationalAribaChange(WBSData);
                                          setOperationsAriba(prevWbs => [
                                            ...prevWbs,
                                            {
                                              // status:e.target.value == 4 ? "Closed" : (e.target.value == 98 ? "Fieldwork": e.target.value == 2 ? "Cancelled" : ""),  
                                              WBSNUMBER:WBSData.WBSNumber,
                                              IsOperationalAriba:e.target.checked
                                            }
                                          ]);
                                        } else {
                                          setOperationsAriba([{
                                            // status:e.target.value == 4 ? "Closed" : (e.target.value == 98 ? "Fieldwork": e.target.value == 2 ? "Cancelled" : ""),  
                                            WBSNUMBER:WBSData.WBSNumber,
                                            IsOperationalAriba:e.target.checked
                                          }]);
                                        }
                                      }}
                                    />
                                    <label style={{display:"inline", marginLeft:"5px"}}>All Ops external (vendor/freelancer) costs booked in SAP</label> 
                                  </td>
                                  <td>
                                    {WBSData.ProjectClosedDate?WBSData.ProjectClosedDate.split("T")[0]:""}
                                  </td>
                                </tr>
                              )                            
                            }) : null
                          )}                        
                      </tbody>
                    </Table> : "You can only close commissioned projects"}                   
                    {/* <p>Change Project Status To:</p> */}
                    
                    {/* <Label className="mb-2 mt-2">Project Status Notes:</Label>
                    <Input
                        type="textarea"
                        id="NotesFinance"
                        //className="form-control"
                        onChange={(e) =>
                            setCurrentSelectedProject({
                                ...currentSelectedProject,
                                NotesProjectStatus: e.target.value,
                            })
                        }
                        value={
                            currentSelectedProject.NotesProjectStatus
                                ? currentSelectedProject.NotesProjectStatus
                                : ""
                        }
                        rows="3"
                        placeholder="Please provide any relevant notes any project status related notes here..."
                    /> */}
                    <br/>
                      {((!modal || !canclledModal) 
                        && currentSelectedProject.ProjectStatus == 3 
                        || currentSelectedProject.ProjectStatus == 9
                        || currentSelectedProject.ProjectStatus == 4 
                        || currentSelectedProject.ProjectStatus == 98 
                        ||currentSelectedProject.ProjectStatus == 2
                        ||currentSelectedProject.ProjectStatus == 11) ? <p style={{color : "red"}}> Important: 
                        You cannot close the WBS without completing all the actions of commercial & operations. Kindly complete all the actions
                        and update it WBS will be closed automatically.
                      </p> : null }
                    {modal ?
                      <p style={{color : "red"}}> Important: 
                        Changing the status to closed is irreversible action and triggers WBS closure for this project, 
                        so costs will NOT be booked under this WBS anymore. 
                        You need to have also Ops confirmation that all the vendor payments are processed & 
                        all internal CS and Ops hours are clocked in Clockify. 
                      </p> 
                      : null
                    }
                    {canclledModal ?
                       <p style={{color : "red"}}> 
                       Important to note that project should be cancelled only if there is an agreement with the client to terminate the contract and cancel the project. 
                       Once project is cancelled, no resources will be added to this project. 
                       Please confirm that there is an official confirmation from the client to cancel the project
                     </p> 
                     : null
                    }
                </ModalBody>
                <ModalFooter>
                    {currentSelectedProject.ProjectStatus == 3 
                    || currentSelectedProject.ProjectStatus == 9
                    || currentSelectedProject.ProjectStatus == 4 
                    || currentSelectedProject.ProjectStatus == 98 
                    || currentSelectedProject.ProjectStatus == 2
                    ||currentSelectedProject.ProjectStatus == 11
                    ? 
                    <Button
                        color="primary"
                        disabled={app.recordLoading || (currentSelectedProject.ProjectStatus == 4) || currentSelectedProject.ProjectStatus == 2}
                        // onClick={ saveStatus}
                        onClick={handleupdate}
                    >
                        Update
                        {app.recordloading ? (
                            <Spinner size="small" color="#495057" />
                        ) : null}
                    </Button> : ""}
                    <Button
                        color="secondary"
                        disabled={app.recordLoading}
                        onClick={() =>{setEditStatusModal(!editStatusModal);setDefaultStatus(true);setModal(false);setCancelledModal(false)}}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal
              centered={true}
              isOpen={warningModal}
              toggle={() => setWarningModal(false)}
              size="sm"
            >
              <ModalBody>
                {WarningMessage===1&&<p>The <span style={{color:"red"}}>Project Manager</span> and the <span style={{color:"red"}}>New Planned FW End Date</span> details are missing.</p>}
                {WarningMessage===2&&<p>The <span style={{color:"red"}}>Project Manager</span> is not assigned.</p>}
                {WarningMessage===3&&<p>The <span style={{color:"red"}}>New Planned FW End Date</span> is missing.</p>}
                <p>Please go to the Date Adjustments page and update.</p>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={()=>openScheduleModule()}>
                    Go to Date Adjustments
                </Button>
                <Button color="secondary" onClick={() => setWarningModal(false)}>
                        Cancel
                </Button>
              </ModalFooter>
            </Modal>
            <Modal
              isOpen={isScheduleDates}
              toggle={() => setScheduleDate(false)}
              className="modal-xl"
              centered={true}
            >
              <ModalHeader>
                {/* <img alt="Nielsen Logo" src={logo} className="modallogo" /> */}
                <div>
                  <span className="modaltitle modalops">
                    {" "}
                    Edit Schedule Dates
                  </span>
                </div>
              </ModalHeader>
              <ModalBody>
                    <ProjectScheduleWBSLevel
                      profile={finalProfile}
                      project={currentSelectedProject}
                      setScheduleDate={setScheduleDate}
                      isScheduleDates={isScheduleDates}
                      getprojectmanagerlist={projectManagersList}
                    ></ProjectScheduleWBSLevel>
              </ModalBody>
              <ModalFooter></ModalFooter>
            </Modal>
            
    </div>
  );
};

export default Dashboard;
