import React, { useState, useEffect } from "react";
import { getLabel } from "../../utils/codeLabels";
import { useHistory, Link } from "react-router-dom";
import update from "immutability-helper";
import { useDispatch, useSelector } from "react-redux";
import * as currentProjectActions from "../../redux/actions/currentProjectActions";
import * as costingsActions from "../../redux/actions/costingsActions";
import * as currentCostingActions from "../../redux/actions/currentCostingActions";
import * as mapperFunctions from "../../utils/rfqMapper";
import { toastr } from "react-redux-toastr";
import _ from "lodash";
import Layout from "../../layouts/Project";
import MarketDetails from "./MarketDetails";
import Salesforce from "./Salesforce";
import ProjectContacts from "./ProjectContacts";
import ProjectDetails from "./ProjectDetails";
// import CountryMethodologyTabs from "./CountryMethodologyTabsFinal";
import ProjectOverview from "./ProjectOverview";
import TestResponsibilities from "./TestResponsibilities";
import CostingProfilesTable from "./CostingProfilesTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as appActions from "../../redux/actions/appActions";
import { HashLink } from "react-router-hash-link";
import store from "../../redux/store/index";
import {
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faExclamationTriangle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Collapse,
  CardTitle,
  CardHeader,
  Label,
  Badge,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import axios from "../../axios-interceptor";
import RecordsBadge from "../../components/RecordsBadge";
import useAnalyticsEventTracker from "../../utils/useAnalyticsEventTracker";
import * as EventName from "../../utils/GoogleAnalyticsEvents";
// uploadmodal
import UploadedProjectModal from "../../components/UploadedProjectModal";
import Up from "../../assets/icons/NIQ Icons SVG/Chevron/Up";
import Down from "../../assets/icons/NIQ Icons SVG/Chevron/Down";
import Right from "../../assets/icons/NIQ Icons SVG/Chevron/Right";
import logo from "../../assets/img/avatars/modal-logo.png";
const alasql = window.alasql;

var imgStyle = {
  width: "150px",
};

let initProject;

const Proposal = () => {
  const gaEventTracker = useAnalyticsEventTracker(EventName.NewProposal);
  // uploadmodal
  const [uploadProject, setUploadProject] = useState(false);
  const costingStatus = useSelector(({ app }) => app.costingStatus);
  const user = useSelector(({ user }) => user.userRecord);

  const links_sidebar = useSelector(({ sidebar }) => sidebar.links);

  const project = useSelector(
    ({ currentProject }) => currentProject.newProject
  );
  let oppData = useSelector(
    ({ currentProject }) => currentProject.opportunityData
  );
  let tableDataRedux = useSelector(
    ({ currentProject }) => currentProject.tabledata
  );
  const lineitems=useSelector(
    ({ currentProject }) => currentProject.lineitems
  )
  const costingProfile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  console.log("projectproject", project);
  //commit
  console.log("currentCostingProfile:......",store.getState().currentCosting.currentCostingProfile, lineitems, oppData);
  var showText;
  //console.log("oppdata",oppData)
  const [isProposalChanged, setIsProposalChanged] = useState(false);

  if (!initProject || (initProject && initProject.ProjectId == "")) {
    initProject = project;
  }

  let isProjectObjectSame = _.isEqual(project, initProject);
  const [costingMarketTable, setCostingMarketTable] = useState([]);

  useEffect(() => {
    setIsProposalChanged(!isProjectObjectSame);
  }, [isProjectObjectSame]);

  const primaryCSContacts = useSelector(
    ({ currentProject }) => currentProject.primaryCSContacts
  );
  const otherInternalContacts = useSelector(
    ({ currentProject }) => currentProject.otherInternalContacts
  );
  const costingProfiles = useSelector(
    ({ costings }) => costings.costingProfiles
  );
  const currentCostingProfile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  let tempp = _.cloneDeep(currentCostingProfile);
  let opp = tempp?.OPPDatas;
  let tab = tempp?.ProductTableData;
  while (typeof opp === "string") {
    opp = JSON.parse(opp);
  }
  while (typeof tab === "string") {
    tab = JSON.parse(tab);
  }
  if (opp?.length > 0) {
    if(opp?.length < oppData?.length) {
      currentCostingProfile.OPPDatas = oppData
    }
    if(tab?.length < lineitems?.length) {
      currentCostingProfile.ProductTableData = lineitems

    }
  }
  const oppValue = useSelector(
    ({ currentProject }) => currentProject.opportunityValue
  );
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  const dispatch = useDispatch();

  const history = useHistory();
  const [profileCreation, setProfileCreation] = useState(false);
  const [selectProfilesDownload, setSelectProfilesDownload] = useState(false);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [profileCreationWarning, setProfileCreationWarning] = useState(false);
  const [profileDetailsChange, setProfileDetailsChange] = useState(false);
  let pacificData = _.head(codeLabels.PacificCountriesList)?.Label;
  let arr = pacificData.split(",");
  const [pacificMarkets] = useState(arr);

  const [toggle, setToggle] = useState({
    start: true,
    salesforce: true,
    projectContacts: true,
    costingProfiles: true,
    overall: true,
    methodologyDTL: true,
    projectOverview: true,
  });

  const [fieldInvalidationProject, setFieldInvalidationProject] = useState({
    BusinessUnit: false,
    CommissioningCountry: false,
    IndustryVertical: false,
    SalesTeamContact: false,
    CommissioningOffice: false,
    Contracts: false,
    ProposalOwnerEmail: false,
    OtherProjectTeamContacts: false,
    ProjecName: false,
  });

  const [fieldInvalidationCosting, setFieldInvalidationCosting] = useState({
    // Methodology: false,
    Syndicated: false,
    IsTracker: false,
    TrackingFrequency: false,
    IsMultiCountry: false,
    FieldingCountries: false,
    // StudyType: false,
  });
  const [SalesTeamContactError,setSalesTeamContactError] = useState(false)
  
  const updateProject = (newProject) => {
    dispatch({
      type: currentProjectActions.UPDATE_NEW_PROJECT,
      newProject: newProject,
    });
  };

  const updateCostingProfile = (costingProfile) => {
    dispatch({
      type: "UPDATE_NEW_COSTING",
      currentCostingProfile: costingProfile,
    });
  };

  // useEffect(
  //     () => () => {
  //         ////console.log("on page unmount, clear costing");
  //         //
  //         dispatch({ type: currentCostingActions.CLEAR_NEW_COSTING });
  //         dispatch({ type: currentProjectActions.CLEAR_NEW_PROJECT });
  //     },
  //     []
  // );

  useEffect(() => {
    if (!currentCostingProfile || !currentCostingProfile.id) {
      dispatch(currentProjectActions.initProject());
    }
  }, []);

  const handleCostingProfileUpdate = () => {
    if (validateCosting()) {
      let cloneCP = _.cloneDeep(convertLabelfromCSV(currentCostingProfile));
      cloneCP.Project = project;
      let updateContMethProfile = cloneCP.id
        ? cloneCP
        : _.last(
            project.CostingProfiles.filter((cp) => cp.ProfileStatus != 99)
          );
      if (
        oppData === null ||
        oppData === undefined ||
        Object.keys(oppData).length === 0
      ) {
        oppData = cloneCP.OPPDatas;
      }
      while (typeof oppData === "string") {
        oppData = JSON.parse(oppData);
      }
      if (Object.keys(tableDataRedux).length === 0) {
        if (Object.keys(lineitems).length === 0) {
          tableDataRedux = cloneCP.ProductTableData;
        } else {
          tableDataRedux = lineitems;
        }
        
      }
      while (typeof tableDataRedux === "string") {
        tableDataRedux = JSON.parse(tableDataRedux);
      }

      if (
        oppData !== null &&
        oppData !== undefined &&
        Object.keys(oppData).length !== 0
      ) {
        var ProductTypeData = [];
        if (project.IsSyndicated && project.SyndicatedwithOpp !== true) {
          oppData.forEach((item, index) => {
            if (item?.Submethodology !== undefined) {
              item?.Submethodology?.map((val) => {
                let subMethValue = findSubMethodologyCode(
                  val.label,
                  codeLabels.SubMethodologyOptions
                );
                let subMethValueArr = subMethValue.split("_");
                ProductTypeData.push({
                  Code: subMethValueArr[0],
                  ParentMethodologyCode: subMethValueArr[1],
                  Label: val.label,
                  Productname: `${index + 1}-${item.MaterialID}`,
                  MSDMethodology: "",
                  WBSNumber: item.WBSNumber,
                  OpportunityLineItemID: "",
                  Wavenumber: item.WaveNumber,
                  ProductDescription: item.ProductDescription,
                  MethodologyType: item.methodologyType,
                  MaterialID: item.MaterialID,
                  OppItemId: index,
                });
              });
            } else {
              if (item?.methodology!== null && item?.methodology!== undefined) {
                let methodology = [];
                let methodologyStr = "";
                codeLabels.MethodologyOptions?.map((cl) => {
                  if (
                    cl.Label === item?.methodology
                  ) {
                    methodology.push(cl.Code);
                  }
                });
                if (methodology !== null) {
                  methodologyStr = methodology[0];
                  let subMethCodes = codeLabels?.SubMethodologyOptions?.filter(
                    (sub) => sub.ParentMethodologyCode === methodologyStr
                  );
                  let costSubMeth = costingProfile?.SubMethodology?.map(
                    (sm) => sm.label
                  );
                  let sub = subMethCodes?.filter((s) =>
                    costSubMeth?.includes(s.Label)
                  );
                  sub?.map((val) => {
                    ProductTypeData.push({
                      Code: val.Code,
                      ParentMethodologyCode: val.ParentMethodologyCode,
                      Label: val.Label,
                      Productname: `${index + 1}-${item.MaterialID}`,
                      MSDMethodology: "",
                      WBSNumber: item.WBSNumber,
                      OpportunityLineItemID: "",
                      Wavenumber: item.WaveNumber,
                      ProductDescription: item.ProductDescription,
                      MethodologyType: item.methodologyType,
                      MaterialID: item.MaterialID,
                      OppItemId: index,
                    });
                  });
                }
              }
            }
          });
        } else {
          oppData.forEach((item, index) => {
            if (item?.Submethodology !== undefined) {
              item?.Submethodology?.map((val) => {
                let subMethValue = findSubMethodologyCode(
                  val.label,
                  codeLabels.SubMethodologyOptions
                );
                let subMethValueArr = subMethValue.split("_");
                ProductTypeData.push({
                  Code: subMethValueArr[0],
                  ParentMethodologyCode: subMethValueArr[1],
                  Label: val.label,
                  Productname: `${index + 1}-${item.MaterialID}`,
                  MSDMethodology: item.MSDMethodology,
                  WBSNumber: item.WBSNumber,
                  OpportunityLineItemID: item.OpportunityLineItemID,
                  Wavenumber: item.WaveNumber,
                  ProductDescription: item.ProductDescription,
                  MethodologyType: item.methodologyType,
                  MaterialID: item.MaterialID,
                  isRed: item?.isRed,
                  OppItemId: index,
                });
              });
            } else {
              if (item?.methodology!==null && item?.methodology!==undefined) {
                let methodology = [];
                let methodologyStr = "";
                codeLabels.MethodologyOptions?.map((cl) => {
                  if (
                    cl.Label === item?.methodology
                  ) {
                    methodology.push(cl.Code);
                  }
                });
                if (methodology !== null) {
                  methodologyStr = methodology[0];
                  let subMethCodes = codeLabels?.SubMethodologyOptions?.filter(
                    (sub) => sub.ParentMethodologyCode === methodologyStr
                  );
                  let costSubMeth = costingProfile?.SubMethodology?.map(
                    (sm) => sm.label
                  );
                  let sub = subMethCodes?.filter((s) =>
                    costSubMeth?.includes(s.Label)
                  );
                  sub?.map((val) => {
                    ProductTypeData.push({
                      Code: val.Code,
                      ParentMethodologyCode: val.ParentMethodologyCode,
                      Label: val.Label,
                      Productname: `${index + 1}-${item.MaterialID}`,
                      MSDMethodology: item.MSDMethodology,
                      WBSNumber: item.WBSNumber,
                      OpportunityLineItemID: item.OpportunityLineItemID,
                      Wavenumber: item.WaveNumber,
                      ProductDescription: item.ProductDescription,
                      MethodologyType: item.methodologyType,
                      MaterialID: item.MaterialID,
                      isRed: item?.isRed,
                      OppItemId: index,
                    });
                  });
                }
              }
              else if (item?.CharacteristicValues?.Z_FIELDINGMETHOD[0] !== null) {
                let methodology = [];
                let methodologyStr = "";
                codeLabels.MethodologyOptions?.map((cl) => {
                  if (
                    cl.Label === item?.CharacteristicValues?.Z_FIELDINGMETHOD[0]
                  ) {
                    methodology.push(cl.Code);
                  }
                });
                if (methodology !== null) {
                  methodologyStr = methodology[0];
                  let subMethCodes = codeLabels?.SubMethodologyOptions?.filter(
                    (sub) => sub.ParentMethodologyCode === methodologyStr
                  );
                  let costSubMeth = costingProfile?.SubMethodology?.map(
                    (sm) => sm.label
                  );
                  let sub = subMethCodes?.filter((s) =>
                    costSubMeth?.includes(s.Label)
                  );
                  sub?.map((val) => {
                    ProductTypeData.push({
                      Code: val.Code,
                      ParentMethodologyCode: val.ParentMethodologyCode,
                      Label: val.Label,
                      Productname: `${index + 1}-${item.MaterialID}`,
                      MSDMethodology: item.MSDMethodology,
                      WBSNumber: item.WBSNumber,
                      OpportunityLineItemID: item.OpportunityLineItemID,
                      Wavenumber: item.WaveNumber,
                      ProductDescription: item.ProductDescription,
                      MethodologyType: item.methodologyType,
                      MaterialID: item.MaterialID,
                      isRed: item?.isRed,
                      OppItemId: index,
                    });
                  });
                }
              }
            }
          });
        }
        cloneCP["ProductTypeData"] = ProductTypeData;
        cloneCP["ProductTableData"] = tableDataRedux;
        cloneCP["OPPDatas"] = oppData;
      } else if (
        currentCostingProfile?.ProductTypeData &&
        currentCostingProfile?.ProductTypeData.length > 0
      ) {
        var payloadForProduct = [];
        while (typeof currentCostingProfile.ProductTableData === "string") {
          currentCostingProfile.ProductTableData = JSON.parse(
            currentCostingProfile.ProductTableData
          );
        }

        var payload = currentCostingProfile.ProductTableData;

        payload.forEach((item, index) => {
          if (item?.methodology !== null) {
            let methodology = [];
            let methodologyStr = "";
            codeLabels.MethodologyOptions?.map((cl) => {
              if (cl.Label === item?.methodology) {
                methodology.push(cl.Code);
              }
            });
            if (methodology !== null) {
              methodologyStr = methodology[0];
              let subMethCodes = codeLabels?.SubMethodologyOptions?.filter(
                (sub) => sub.ParentMethodologyCode === methodologyStr
              );
              let costSubMeth = costingProfile?.SubMethodology?.map(
                (sm) => sm.label
              );
              let sub = subMethCodes?.filter((s) =>
                costSubMeth?.includes(s.Label)
              );
              sub?.map((val) => {
                payloadForProduct.push({
                  Code: val.Code,
                  ParentMethodologyCode: val.ParentMethodologyCode,
                  Label: val.Label,
                  Productname: item.MaterialID,
                  //Methodology: {label: item.methodology},
                  MSDMethodology: item.MSDMethodology,
                  WBSNumber: item.WBSNumber,
                  OpportunityLineItemID: item.OpportunityLineItemID,
                  Wavenumber: item.WaveNumber,
                  ProductDescription: item.ProductDescription,
                  MethodologyType: item.methodologyType,
                  MaterialID: item.MaterialID,
                  isRed: item?.isRed,
                  OppItemId: index,
                });
              });
            }
          }
          // payloadForProduct.push({

          //   Productname: item.MaterialID,

          //   Methodology: {

          //     label: item.methodology

          //   },
          //   MSDMethodology:item.MSDMethodology,
          //   WBSNumber: item.WBSNumber,
          //   OpportunityLineItemID : item.OpportunityLineItemID,
          //   Wavenumber:item.WaveNumber,
          //   ProductDescription:item.ProductDescription,
          //   MethodologyType:item.methodologyType,
          //   MaterialID:item.MaterialID,
          //   isRed:item?.isRed
          // })
        });
        cloneCP["ProductTypeData"] = payloadForProduct;
        cloneCP["ProductTableData"] = currentCostingProfile.ProductTableData;
        cloneCP["OPPDatas"] = oppData;
      } else {
        cloneCP["ProductTypeData"] = [];
        cloneCP["ProductTableData"] = [];
        cloneCP["OPPDatas"] = [];
      }
      cloneCP["OpportunityNumber"]=project?.ContractDetails[0]?.OpportunityNumber
      var oppDatatemp = oppData;
      while (typeof oppDatatemp === "string") {
        oppDatatemp = JSON.parse(oppData);
      }
      if (oppDatatemp?.length > 0) {
        showText = oppDatatemp?.some((item) => item?.isRed);
      } else {
        while (typeof currentCostingProfile?.ProductTableData === "string") {
          currentCostingProfile.ProductTableData = JSON.parse(
            currentCostingProfile?.ProductTableData
          );
        }
        showText = currentCostingProfile.ProductTableData?.some(
          (item) => item?.isRed
        );
      }
      if (showText) {
        toastr.info(
          "The product lines highlighted in Red color were deleted and new product lines were added. Please re-map the newly added product lines on RFQ, Time Cost and Cost Sheet"
        );
      }
      while (typeof cloneCP.OPPDatas === "string") {
        cloneCP.OPPDatas = JSON.parse(cloneCP.OPPDatas);
      }
      while (typeof cloneCP.ProductTableData === "string") {
        cloneCP.ProductTableData = JSON.parse(cloneCP.ProductTableData);
      }
      let updatedOpportunityData = cloneCP.OPPDatas.map((row) => ({
        ...row,
        isAmendedit : true,
      }));
      cloneCP.OPPDatas = updatedOpportunityData;
      let updateTableData = cloneCP.ProductTableData.map((row) => ({
        ...row,
        isAmendedit : true,
      })); 
      cloneCP.ProductTableData = updateTableData;
      if (updateContMethProfile?.id) {
        cloneCP.id = updateContMethProfile.id;
        delete cloneCP.WaveSpecs;
        cloneCP.Project = {
          CommissioningCountry: cloneCP.Project.CommissioningCountry,
        };
        if (tableDataRedux.some((e) => e.hasOwnProperty("isRed"))) {
          setIsProposalChanged(true);
        }
        if (isProposalChanged) {
          dispatch(appActions.recordLoadStart());
          dispatch(appActions.pageLoadStart());
          axios
            .put(
              "/costingprofiles/AddUpdateNewMarketMethodology/" + cloneCP.id,
              cloneCP,
              {
                headers: {
                  "auth-token": localStorage.getItem("auth-token"),
                },
              }
            )
            .then((res) => {
              if (project?.ProjectId) {
                dispatch(currentProjectActions.getProject(project?.ProjectId));
              }
              dispatch(
                currentCostingActions.getCosting(updateContMethProfile.id, () =>
                  history.push("/costing")
                )
              );
              toastr.success("Costing Profile Saved", res.data.message);
              dispatch(appActions.pageLoadEnd());
              dispatch(appActions.recordLoadEnd());
            })
            .catch((err) => {
              toastr.error("Save Failed", err?.data?.message);
              dispatch(appActions.pageLoadEnd());
              dispatch(appActions.recordLoadEnd());
            });
        } else {
          dispatch(
            currentCostingActions.getCosting(updateContMethProfile.id, () =>
              history.push("/costing")
            )
          );
        }
      }
    }
  };
  const isTest = (users, newproject) => {
    let dev = users;
if (dev.includes(newproject.ProposalOwnerEmail.value) ) {
console.log("is a developer")
newproject.isTest = true;
} else {
	console.log("is not a developer")
  newproject.isTest = false;
}
return newproject
  }
  const handleProjectSave = (asNewProfile) => {
    setProfileDetailsChange(false);
    setProfileCreationWarning(false);
    if (validateProject() && validateMethodology()) {
      if (!project.id && !asNewProfile) {
        console.log("codeLabelscodeLabels", codeLabels.DevUsersOptions[0].Label.split(","));
        let devusers = codeLabels.DevUsersOptions[0].Label.split(",");
        let updproject = isTest(devusers, project);
        console.log("updprojectupdproject", updproject);
        dispatch(currentProjectActions.createProject(updproject));
      } else if (!asNewProfile) {
        dispatch(appActions.pageLoadStart());
        if (isProposalChanged) {
          handleProjectUpdate(() => {
            handleCostingProfileUpdate();
          });
        } else {
          handleCostingProfileUpdate();
        }
      } else if (asNewProfile) {
        handleCostingProfileCreate();
      }
    }
  };

  useEffect(() => {
    if (
      project.id &&
      (project.CostingProfiles.length === 0 || profileCreation) &&
      project.ProjectResourcesFolderId && //call only after project folder creates
      !project.Edit &&
      !project.IscopiedProject
    ) {
      handleCostingProfileCreate();
    }
    if (
      project.CostingProfiles &&
      project.CostingProfiles.length > 0 &&
      project.Edit == true
    ) {
      project.CostingProfiles.map((cp) => {
        //   if(oppValue === "") {
        //   if (!Array.isArray(cp.StudyType)) {
        //     cp.StudyType =
        //       cp.StudyType

        //   }
        // }
        // cp.Methodology = mapperFunctions.GetValueLablesFromCSV(
        //   codeLabels.MethodologyOptions,
        //   cp.Methodology
        // );
        cp.SubMethodology = mapperFunctions.GetValueLablesFromCSV(
          codeLabels.SubMethodologyOptions,
          cp.SubMethodology
        );
      });

      // if (_.last(project.CostingProfiles).StudyType && _.last(project.CostingProfiles).Methodology && _.last(project.CostingProfiles).SubMethodology) {
      let latestProfile = _.last(project.CostingProfiles);
      if (latestProfile) {
        updateCostingProfile({
          //StudyType: oppData === "" ? _.cloneDeep(latestProfile.StudyType) : null,
          Methodology: null,
          SubMethodology: _.cloneDeep(latestProfile.SubMethodology),
          IsMultiCountry: latestProfile.IsMultiCountry,
          FieldingCountries:
            typeof latestProfile.FieldingCountries == "string"
              ? _.cloneDeep(
                  mapperFunctions.GetValueLablesFromCSV(
                    codeLabels.FieldingCountriesOptions,
                    latestProfile.FieldingCountries
                  )
                )
              : latestProfile.FieldingCountries,
        });
      }
      // }
    }
  }, [project.id, project.ProjectResourcesFolderId]);

  useEffect(() => {
    //let cloneCostingProfile=_.cloneDeep(currentCostingProfile);
    // let cloneProfile = formatingDataForForms();
    if (!Array.isArray(currentCostingProfile.SubMethodology)) {
      let _costingProfiles = project.CostingProfiles.map((cloneProfile) => {
        cloneProfile = formatingDataForForms(cloneProfile);
        // if (!Array.isArray(cloneProfile.StudyType)) {
        let _updatedcloneProfile = {
          ...cloneProfile,
          // StudyType: cloneProfile.StudyType,
          // Methodology: !Array.isArray(cloneProfile.Methodology)
          //   ? _.cloneDeep(
          //       mapperFunctions.GetValueLablesFromCSV(
          //         codeLabels.MethodologyOptions,
          //         cloneProfile.Methodology
          //       )
          //     )
          //   : cloneProfile.Methodology,
          SubMethodology: !Array.isArray(cloneProfile.SubMethodology)
            ? _.cloneDeep(
                mapperFunctions.GetValueLablesFromCSV(
                  codeLabels.SubMethodologyOptions,
                  cloneProfile.SubMethodology
                )
              )
            : cloneProfile.SubMethodology,
          IsMultiCountry: !Array.isArray(cloneProfile.IsMultiCountry)
            ? cloneProfile.IsMultiCountry
            : cloneProfile.IsMultiCountry,
          FieldingCountries: !Array.isArray(cloneProfile.FieldingCountries)
            ? _.cloneDeep(
                mapperFunctions.GetValueLablesFromCSV(
                  codeLabels.FieldingCountriesOptions,
                  cloneProfile.FieldingCountries
                )
              )
            : cloneProfile.FieldingCountries,
        };
        return { ..._updatedcloneProfile };
        // } else {
        //     return { ...cloneProfile }
        // }
      });
      updateCostingProfile(
        _costingProfiles.find((cp) => cp.id == currentCostingProfile.id)
      );
      updateProject({
        CostingProfiles: _costingProfiles,
      });
    }
  }, [currentCostingProfile.ProfileLoaded]);

  // const saveOnlyProject = () => {
  //   if (validateProject()) {
  //     dispatch(appActions.pageLoadStart());
  //     axios
  //       .put("/projects/" + project.ProjectId, project, {
  //         headers: { "auth-token": localStorage.getItem("auth-token") },
  //       })
  //       .then((res) => {
  //         axios.get("/projects/" + project.ProjectId).then((res) => {
  //           let _project = res.data.project;
  //           delete _project.CostingProfiles;
  //           updateProject({ ...project, ..._project });
  //           toastr.success("Project Details Saved Successfully");
  //           dispatch(appActions.pageLoadEnd());
  //           dispatch(currentProjectActions.setOpportunityData(res.data.ContractDetails))
  //         });
  //       })
  //       .catch((error) => {});
  //   }
  // };

  //method to find the sub-methodology codes
  const findSubMethodologyCode = (label, subMethodologyOptions) => {
    let code = subMethodologyOptions.filter((sub) => sub.Label === label);
    return `${code[0].Code}_${code[0].ParentMethodologyCode}`;
  };

  const saveOnlyProject = () => {
    console.log("Entering into", costingMarketTable);
    axios
      .post(`/timecost/CostMarket/${costingProfile.id}`, costingMarketTable)
      .then((res) => {
        setCostingMarketTable(res.data.CostMarketCountries);
        toastr.success("Success", "Saved successfully");
      })
      .catch((error) => {
        console.log(error);
      });
    if (validateProject()) {
      dispatch(appActions.pageLoadStart());
      axios
        .put("/projects/" + project.ProjectId, project, {
          headers: { "auth-token": localStorage.getItem("auth-token") },
        })
        .then((res) => {
          axios.get("/projects/" + project.ProjectId).then((res) => {
            let _project = res.data.project;
            delete _project.CostingProfiles;
            updateProject({ ...project, ..._project });
            toastr.success("Project Details Saved Successfully");
            dispatch(appActions.pageLoadEnd());
            dispatch(
              currentProjectActions.setOpportunityData(res.data.ContractDetails)
            );
          });
        })
        .catch((error) => {});
    }
    if (validateCosting()) {
      let cloneCP = _.cloneDeep(convertLabelfromCSV(currentCostingProfile));
      cloneCP.Project = project;
      let updateContMethProfile = cloneCP.id
        ? cloneCP
        : _.last(
            project.CostingProfiles.filter((cp) => cp.ProfileStatus != 99)
          );
      if (
        oppData === null ||
        oppData === undefined ||
        Object.keys(oppData).length === 0
      ) {
        oppData = cloneCP.OPPDatas;
      }
      while (typeof oppData === "string") {
        oppData = JSON.parse(oppData);
      }
      if (Object.keys(tableDataRedux).length === 0) {
        if (Object.keys(lineitems).length === 0) {
          tableDataRedux = cloneCP.ProductTableData;
        } else {
          tableDataRedux = lineitems;
        }
        
      }
      while (typeof tableDataRedux === "string") {
        tableDataRedux = JSON.parse(tableDataRedux);
      }

      if (
        oppData !== null &&
        oppData !== undefined &&
        Object.keys(oppData).length !== 0
      ) {
        var ProductTypeData = [];
        if (project.IsSyndicated && project.SyndicatedwithOpp !== true) {
          oppData.forEach((item, index) => {
            if (item?.Submethodology !== undefined) {
              item?.Submethodology?.map((val) => {
                let subMethValue = findSubMethodologyCode(
                  val.label,
                  codeLabels.SubMethodologyOptions
                );
                let subMethValueArr = subMethValue.split("_");
                ProductTypeData.push({
                  Code: subMethValueArr[0],
                  ParentMethodologyCode: subMethValueArr[1],
                  Label: val.label,
                  Productname: `${index + 1}-${item.MaterialID}`,
                  MSDMethodology: "",
                  WBSNumber: item.WBSNumber,
                  OpportunityLineItemID: "",
                  Wavenumber: item.WaveNumber,
                  ProductDescription: item.ProductDescription,
                  MethodologyType: item.methodologyType,
                  MaterialID: item.MaterialID,
                  OppItemId: index,
                });
              });
            } else {
              if (item?.methodology !== null && item?.methodology !==undefined) {
                let methodology = [];
                let methodologyStr = "";
                codeLabels.MethodologyOptions?.map((cl) => {
                  if (
                    cl.Label === item?.methodology
                  ) {
                    methodology.push(cl.Code);
                  }
                });
                if (methodology !== null) {
                  methodologyStr = methodology[0];
                  let subMethCodes = codeLabels?.SubMethodologyOptions?.filter(
                    (sub) => sub.ParentMethodologyCode === methodologyStr
                  );
                  let costSubMeth = costingProfile?.SubMethodology?.map(
                    (sm) => sm.label
                  );
                  let sub = subMethCodes?.filter((s) =>
                    costSubMeth?.includes(s.Label)
                  );
                  sub?.map((val) => {
                    ProductTypeData.push({
                      Code: val.Code,
                      ParentMethodologyCode: val.ParentMethodologyCode,
                      Label: val.Label,
                      Productname: `${index + 1}-${item.MaterialID}`,
                      MSDMethodology: "",
                      WBSNumber: item.WBSNumber,
                      OpportunityLineItemID: "",
                      Wavenumber: item.WaveNumber,
                      ProductDescription: item.ProductDescription,
                      MethodologyType: item.methodologyType,
                      MaterialID: item.MaterialID,
                      OppItemId: index,
                    });
                  });
                }
              }
            }
          });
        } else {
          oppData.forEach((item, index) => {
            if (item?.Submethodology !== undefined) {
              item?.Submethodology?.map((val) => {
                let subMethValue = findSubMethodologyCode(
                  val.label,
                  codeLabels.SubMethodologyOptions
                );
                let subMethValueArr = subMethValue.split("_");
                ProductTypeData.push({
                  Code: subMethValueArr[0],
                  ParentMethodologyCode: subMethValueArr[1],
                  Label: val.label,
                  Productname: `${index + 1}-${item.MaterialID}`,
                  MSDMethodology: item.MSDMethodology,
                  WBSNumber: item.WBSNumber,
                  OpportunityLineItemID: item.OpportunityLineItemID,
                  Wavenumber: item.WaveNumber,
                  ProductDescription: item.ProductDescription,
                  MethodologyType: item.methodologyType,
                  MaterialID: item.MaterialID,
                  isRed: item?.isRed,
                  OppItemId: index,
                });
              });
            } else {
              if (item?.methodology !== null && item?.methodology !==undefined) {
                let methodology = [];
                let methodologyStr = "";
                codeLabels.MethodologyOptions?.map((cl) => {
                  if (
                    cl.Label === item?.methodology
                  ) {
                    methodology.push(cl.Code);
                  }
                });
                if (methodology !== null) {
                  methodologyStr = methodology[0];
                  let subMethCodes = codeLabels?.SubMethodologyOptions?.filter(
                    (sub) => sub.ParentMethodologyCode === methodologyStr
                  );
                  let costSubMeth = costingProfile?.SubMethodology?.map(
                    (sm) => sm.label
                  );
                  let sub = subMethCodes?.filter((s) =>
                    costSubMeth?.includes(s.Label)
                  );
                  sub?.map((val) => {
                    ProductTypeData.push({
                      Code: val.Code,
                      ParentMethodologyCode: val.ParentMethodologyCode,
                      Label: val.Label,
                      Productname: `${index + 1}-${item.MaterialID}`,
                      MSDMethodology: item.MSDMethodology,
                      WBSNumber: item.WBSNumber,
                      OpportunityLineItemID: item.OpportunityLineItemID,
                      Wavenumber: item.WaveNumber,
                      ProductDescription: item.ProductDescription,
                      MethodologyType: item.methodologyType,
                      MaterialID: item.MaterialID,
                      isRed: item?.isRed,
                      OppItemId: index,
                    });
                  });
                }
              }
              else if (item?.CharacteristicValues?.Z_FIELDINGMETHOD[0] !== null) {
                let methodology = [];
                let methodologyStr = "";
                codeLabels.MethodologyOptions?.map((cl) => {
                  if (
                    cl.Label === item?.CharacteristicValues?.Z_FIELDINGMETHOD[0]
                  ) {
                    methodology.push(cl.Code);
                  }
                });
                if (methodology !== null) {
                  methodologyStr = methodology[0];
                  let subMethCodes = codeLabels?.SubMethodologyOptions?.filter(
                    (sub) => sub.ParentMethodologyCode === methodologyStr
                  );
                  let costSubMeth = costingProfile?.SubMethodology?.map(
                    (sm) => sm.label
                  );
                  let sub = subMethCodes?.filter((s) =>
                    costSubMeth?.includes(s.Label)
                  );
                  sub?.map((val) => {
                    ProductTypeData.push({
                      Code: val.Code,
                      ParentMethodologyCode: val.ParentMethodologyCode,
                      Label: val.Label,
                      Productname: `${index + 1}-${item.MaterialID}`,
                      MSDMethodology: item.MSDMethodology,
                      WBSNumber: item.WBSNumber,
                      OpportunityLineItemID: item.OpportunityLineItemID,
                      Wavenumber: item.WaveNumber,
                      ProductDescription: item.ProductDescription,
                      MethodologyType: item.methodologyType,
                      MaterialID: item.MaterialID,
                      isRed: item?.isRed,
                      OppItemId: index,
                    });
                  });
                }
              }
            }
          });
        }
        cloneCP["ProductTypeData"] = ProductTypeData;
        cloneCP["ProductTableData"] = tableDataRedux;
        cloneCP["OPPDatas"] = oppData;
      } else if (
        currentCostingProfile?.ProductTypeData &&
        currentCostingProfile?.ProductTypeData.length > 0
      ) {
        var payloadForProduct = [];
        while (typeof currentCostingProfile.ProductTableData === "string") {
          currentCostingProfile.ProductTableData = JSON.parse(
            currentCostingProfile.ProductTableData
          );
        }

        var payload = currentCostingProfile.ProductTableData;

        payload.forEach((item, index) => {
          if (item?.methodology !== null) {
            let methodology = [];
            let methodologyStr = "";
            codeLabels.MethodologyOptions?.map((cl) => {
              if (cl.Label === item?.methodology) {
                methodology.push(cl.Code);
              }
            });
            if (methodology !== null) {
              methodologyStr = methodology[0];
              let subMethCodes = codeLabels?.SubMethodologyOptions?.filter(
                (sub) => sub.ParentMethodologyCode === methodologyStr
              );
              let costSubMeth = costingProfile?.SubMethodology?.map(
                (sm) => sm.label
              );
              let sub = subMethCodes?.filter((s) =>
                costSubMeth?.includes(s.Label)
              );
              sub?.map((val) => {
                payloadForProduct.push({
                  Code: val.Code,
                  ParentMethodologyCode: val.ParentMethodologyCode,
                  Label: val.Label,
                  Productname: item.MaterialID,
                  //Methodology: {label: item.methodology},
                  MSDMethodology: item.MSDMethodology,
                  WBSNumber: item.WBSNumber,
                  OpportunityLineItemID: item.OpportunityLineItemID,
                  Wavenumber: item.WaveNumber,
                  ProductDescription: item.ProductDescription,
                  MethodologyType: item.methodologyType,
                  MaterialID: item.MaterialID,
                  isRed: item?.isRed,
                  OppItemId: index,
                });
              });
            }
          }
          // payloadForProduct.push({

          //   Productname: item.MaterialID,

          //   Methodology: {

          //     label: item.methodology

          //   },
          //   MSDMethodology:item.MSDMethodology,
          //   WBSNumber: item.WBSNumber,
          //   OpportunityLineItemID : item.OpportunityLineItemID,
          //   Wavenumber:item.WaveNumber,
          //   ProductDescription:item.ProductDescription,
          //   MethodologyType:item.methodologyType,
          //   MaterialID:item.MaterialID,
          //   isRed:item?.isRed
          // })
        });
        cloneCP["ProductTypeData"] = payloadForProduct;
        cloneCP["ProductTableData"] = currentCostingProfile.ProductTableData;
        cloneCP["OPPDatas"] = oppData;
      } else {
        cloneCP["ProductTypeData"] = [];
        cloneCP["ProductTableData"] = [];
        cloneCP["OPPDatas"] = [];
      }
      var oppDatatemp = oppData;
      while (typeof oppDatatemp === "string") {
        oppDatatemp = JSON.parse(oppData);
      }
      if (oppDatatemp?.length > 0) {
        showText = oppDatatemp?.some((item) => item?.isRed);
      } else {
        while (typeof currentCostingProfile?.ProductTableData === "string") {
          currentCostingProfile.ProductTableData = JSON.parse(
            currentCostingProfile?.ProductTableData
          );
        }
        showText = currentCostingProfile.ProductTableData?.some(
          (item) => item?.isRed
        );
      }
      if (showText) {
        toastr.info(
          "The product lines highlighted in Red color were deleted and new product lines were added. Please re-map the newly added product lines on RFQ, Time Cost and Cost Sheet"
        );
      }
      if (updateContMethProfile?.id) {
        cloneCP.id = updateContMethProfile.id;
        delete cloneCP.WaveSpecs;
        cloneCP.Project = {
          CommissioningCountry: cloneCP.Project.CommissioningCountry,
        };
        if (isProposalChanged) {
          dispatch(appActions.recordLoadStart());
          dispatch(appActions.pageLoadStart());
          axios
            .put(
              "/costingprofiles/AddUpdateNewMarketMethodology/" + cloneCP.id,
              cloneCP,
              {
                headers: {
                  "auth-token": localStorage.getItem("auth-token"),
                },
              }
            )
            .then((res) => {
              // dispatch(
              //   currentCostingActions.getCosting(
              //     updateContMethProfile.id,
              //     () => history.push("/costing")
              //   )
              // );
              // toastr.success("Costing Profile Saved", res.data.message);
              if (project?.ProjectId) {
                dispatch(currentProjectActions.getProject(project?.ProjectId));
              }
              axios.get("/costingprofiles/" + cloneCP.id);
              dispatch(appActions.pageLoadEnd());
              dispatch(appActions.recordLoadEnd());
            })
            .catch((err) => {
              toastr.error("Save Failed", err?.data?.message);
              dispatch(appActions.pageLoadEnd());
              dispatch(appActions.recordLoadEnd());
            });
        } else {
          console.log("else part");
          // dispatch(
          //   currentCostingActions.getCosting(
          //     updateContMethProfile.id,
          //     () => history.push("/costing")
          //   )
          // );
        }
      }
    }
  };

  const handleCostingProfileCreate = () => {
    let cloneCP = _.cloneDeep(convertLabelfromCSV(currentCostingProfile));

    if (Object.keys(oppData).length !== 0) {
      var ProductTypeData = [];

      if (project.IsSyndicated && project.SyndicatedwithOpp !== true) {
        oppData.forEach((item, index) => {
          if (item?.Submethodology !== undefined) {
            item?.Submethodology?.map((val) => {
              let subMethValue = findSubMethodologyCode(
                val.label,
                codeLabels.SubMethodologyOptions
              );
              let subMethValueArr = subMethValue.split("_");
              ProductTypeData.push({
                Code: subMethValueArr[0],
                ParentMethodologyCode: subMethValueArr[1],
                Label: val.label,
                Productname: `${index + 1}-${item.MaterialID}`,
                MSDMethodology: "",
                WBSNumber: item.WBSNumber,
                OpportunityLineItemID: "",
                Wavenumber: item.WaveNumber,
                ProductDescription: item.ProductDescription,
                MethodologyType: item.methodologyType,
                MaterialID: item.MaterialID,
                OppItemId: index,
              });
            });
          } else {
            if (item?.CharacteristicValues?.Z_FIELDINGMETHOD[0] !== null) {
              let methodology = [];
              let methodologyStr = "";
              codeLabels.MethodologyOptions?.map((cl) => {
                if (
                  cl.Label === item?.CharacteristicValues?.Z_FIELDINGMETHOD[0]
                ) {
                  methodology.push(cl.Code);
                }
              });
              if (methodology !== null) {
                methodologyStr = methodology[0];
                let subMethCodes = codeLabels?.SubMethodologyOptions?.filter(
                  (sub) => sub.ParentMethodologyCode === methodologyStr
                );
                let costSubMeth = costingProfile?.SubMethodology?.map(
                  (sm) => sm.label
                );
                let sub = subMethCodes?.filter((s) =>
                  costSubMeth?.includes(s.Label)
                );
                sub?.map((val) => {
                  ProductTypeData.push({
                    Code: val.Code,
                    ParentMethodologyCode: val.ParentMethodologyCode,
                    Label: val.Label,
                    Productname: `${index + 1}-${item.MaterialID}`,
                    MSDMethodology: "",
                    WBSNumber: item.WBSNumber,
                    OpportunityLineItemID: "",
                    Wavenumber: item.WaveNumber,
                    ProductDescription: item.ProductDescription,
                    MethodologyType: item.methodologyType,
                    MaterialID: item.MaterialID,
                    OppItemId: index,
                  });
                });
              }
            }
          }
        });
      } else {
        oppData.forEach((item, index) => {
          if (item?.Submethodology !== undefined) {
            item?.Submethodology?.map((val) => {
              let subMethValue = findSubMethodologyCode(
                val.label,
                codeLabels.SubMethodologyOptions
              );
              let subMethValueArr = subMethValue.split("_");
              ProductTypeData.push({
                Code: subMethValueArr[0],
                ParentMethodologyCode: subMethValueArr[1],
                Label: val.label,
                Productname: `${index + 1}-${item.MaterialID}`,
                MSDMethodology: item.MSDMethodology,
                WBSNumber: item.WBSNumber,
                OpportunityLineItemID: item.OpportunityLineItemID,
                Wavenumber: item.WaveNumber,
                ProductDescription: item.ProductDescription,
                MethodologyType: item.methodologyType,
                MaterialID: item.MaterialID,
                OppItemId: index,
              });
            });
          } else {
            if (item?.CharacteristicValues?.Z_FIELDINGMETHOD[0] !== null) {
              let methodology = [];
              let methodologyStr = "";
              codeLabels.MethodologyOptions?.map((cl) => {
                if (
                  cl.Label === item?.CharacteristicValues?.Z_FIELDINGMETHOD[0]
                ) {
                  methodology.push(cl.Code);
                }
              });
              if (methodology !== null) {
                methodologyStr = methodology[0];
                let subMethCodes = codeLabels?.SubMethodologyOptions?.filter(
                  (sub) => sub.ParentMethodologyCode === methodologyStr
                );
                let costSubMeth = costingProfile?.SubMethodology?.map(
                  (sm) => sm.label
                );
                let sub = subMethCodes?.filter((s) =>
                  costSubMeth?.includes(s.Label)
                );
                sub?.map((val) => {
                  ProductTypeData.push({
                    Code: val.Code,
                    ParentMethodologyCode: val.ParentMethodologyCode,
                    Label: val.Label,
                    Productname: `${index + 1}-${item.MaterialID}`,
                    MSDMethodology: item.MSDMethodology,
                    WBSNumber: item.WBSNumber,
                    OpportunityLineItemID: item.OpportunityLineItemID,
                    Wavenumber: item.WaveNumber,
                    ProductDescription: item.ProductDescription,
                    MethodologyType: item.methodologyType,
                    MaterialID: item.MaterialID,
                    OppItemId: index,
                  });
                });
              }
            }
          }
        });
      }

      cloneCP["ProductTypeData"] = ProductTypeData;
      cloneCP["ProductTableData"] = tableDataRedux;
      cloneCP["OPPDatas"] = oppData;
    } else if (
      currentCostingProfile?.ProductTypeData &&
      currentCostingProfile?.ProductTypeData.length > 0
    ) {
      cloneCP["ProductTypeData"] = currentCostingProfile.ProductTypeData;
      cloneCP["ProductTableData"] = currentCostingProfile.ProductTableData;
      cloneCP["OPPDatas"] = oppData;
    } else {
      cloneCP["ProductTypeData"] = [];
      cloneCP["ProductTableData"] = [];
      cloneCP["OPPDatas"] = [];
    }
    while (typeof cloneCP.OPPDatas === "string") {
      cloneCP.OPPDatas = JSON.parse(cloneCP.OPPDatas);
    }
    while (typeof cloneCP.ProductTableData === "string") {
      cloneCP.ProductTableData = JSON.parse(cloneCP.ProductTableData);
    }
    let updatedOpportunityData = cloneCP.OPPDatas.map((row) => ({
      ...row,
      isAmendedit : true,
    }));
    cloneCP.OPPDatas = updatedOpportunityData;
    let updateTableData = cloneCP.ProductTableData.map((row) => ({
      ...row,
      isAmendedit : true,
    })); 
    cloneCP.ProductTableData = updateTableData;
    cloneCP["OpportunityNumber"]=project?.ContractDetails[0]?.OpportunityNumber
    const convertedSubMethodology = !Array.isArray(
      currentCostingProfile.SubMethodology
    )
      ? _.cloneDeep(
          mapperFunctions.GetValueLablesFromCSV(
            codeLabels.SubMethodologyOptions,
            currentCostingProfile.SubMethodology
          )
        )
      : currentCostingProfile.SubMethodology;

    // const convertedMethodology = !Array.isArray(currentCostingProfile.Methodology)
    // ? _.cloneDeep(
    //     mapperFunctions.GetValueLablesFromCSV(
    //       codeLabels.MethodologyOptions,
    //       currentCostingProfile.Methodology
    //     )
    //   )
    // : currentCostingProfile.Methodology

    // const convertedStudyType = currentCostingProfile.StudyType

    const convertedFieldingCountries = !Array.isArray(
      currentCostingProfile.FieldingCountries
    )
      ? _.cloneDeep(
          mapperFunctions.GetValueLablesFromCSV(
            codeLabels.FieldingCountriesOptions,
            currentCostingProfile.FieldingCountries
          )
        )
      : currentCostingProfile.FieldingCountries;
    let index = convertedFieldingCountries.findIndex(country => country.value ===project.CommissioningCountry);

    if(index !=0 && index != -1)
    {
      [convertedFieldingCountries[0], convertedFieldingCountries[index]] = [convertedFieldingCountries[index], convertedFieldingCountries[0]];
    }
    console.log("convertedFieldingCountries",convertedFieldingCountries)

    const updatedcurrentCostingProfile = {
      ...cloneCP,
      SubMethodology: convertedSubMethodology,
      FieldingCountries: convertedFieldingCountries,
    };

    if (validateCosting() && project.id) {
      dispatch(appActions.pageLoadStart());
      // _project = project.id ? project : _project
      updatedcurrentCostingProfile.ProfileName =
        _.includes(pacificMarkets, project.CommissioningCountry) &&
        project.CostingTemplateVersion != "v2.0"
          ? `Profile- ${
              project.CostingProfiles ? project.CostingProfiles.length + 1 : 1
            }`
          : `Master Costing Profile ${
              project.CostingProfiles.length
                ? project.CostingProfiles.length + 1
                : currentCostingProfile.ProfileNumber
            }`;
      // delete currentCostingProfile.id;
      delete updatedcurrentCostingProfile.id;
      dispatch(
        currentCostingActions.createCostingProfile(
          {
            // ...currentCostingProfile,
            ...updatedcurrentCostingProfile,
            ProjectId: project.id,
            VerticalId: project.VerticalId,
            ProfileNumber: project.CostingProfiles.length
              ? project.CostingProfiles.length + 1
              : currentCostingProfile.ProfileNumber,
          },
          () => {
            dispatch(
              currentProjectActions.getProject(project.ProjectId, () => {
                // if (callback)
                //     callback()
                dispatch(appActions.pageLoadEnd());
                history.push("/costing");
              })
            );
          }
        )
      );
    }
  };

  const handleProjectUpdate = (callback) => {
    if (validateProject()) {
      if (project.IsImportedProject && !project.IndustryVertical) {
        toastr.error(
          "Industry Vertical not found",
          "Please select an industry vertical and save the project before attempted to create a profile"
        );
      } else {
        dispatch(currentProjectActions.saveProject(project, callback, true));
      }
    }
  };
  //console.log("new proposal page")

  const handleProfileUpdate = () => {
    console.log("Entering into", costingMarketTable);
    dispatch(currentProjectActions.setNewCountryValue(0));
    dispatch(currentProjectActions.setNewMethValue(0));
    axios
      .post(`/timecost/CostMarket/${costingProfile.id}`, costingMarketTable)
      .then((res) => {
        toastr.success("Success", "Saved successfully");
      })
      .catch((error) => {
        console.log(error);
      });

    gaEventTracker(EventName.SaveandNext);
    if (
      !project.id ||
      (_.includes(pacificMarkets, project.CommissioningCountry) &&
        !profileDetailsChange) ||
      !_.includes(pacificMarkets, project.CommissioningCountry)
    ) {
      handleProjectSave();
    } else if (
      _.includes(pacificMarkets, project.CommissioningCountry) &&
      profileDetailsChange &&
      project.CostingTemplateVersion != "v2.0"
    ) {
      setProfileCreationWarning(true);
    } else {
      handleProjectSave(); //just a safe closing
    }
  };
  function formatingDataForForms(_profile) {
    let typeofquant = [
      "SM000005",
      "SM000006",
      "SM000007",
      "SM000008",
      "SM000009",
      "SM000010",
      "SM000011",
      "SM000021",
      "SM000023",
      "SM000031",
      "SM000032",
      "SM000033",
      "SM000034",
      "SM000035",
      "SM000015",
      "SM000022",
      "SM000001",
      "SM000025",
      "SM000026",
      "SM000027",
      "SM000028",
    ];
    let typeofqual = [
      "SM000012",
      "SM000013",
      "SM000014",
      "SM000016",
      "SM000017",
      "SM000018",
      "SM000019",
      "SM000020",
      "SM000002",
      "SM000003",
      "SM000004",
      "SM000036",
      "SM000037",
    ];
    let cloneProfile = _.cloneDeep(_profile);
    let commCountryCode = project.CommissioningCountry;
    cloneProfile.CountrySpecs?.forEach((cs, indexCS) => {
      cs["label"] = cs.CountryName;
      cs.MethodologySpecs?.forEach((ms, indexMS) => {
        if (typeofquant.find((tp) => tp == ms.Code)) ms["Quant"] = true;
        else if (typeofqual.find((tp) => tp == ms.Code)) ms["Qual"] = true;

        ms["label"] = ms.Label;
        let tempVariables = mapperFunctions.getSchemaWithDataJSON(
          ms.RFQSchema,
          ms.Code,
          ms.RFQData,
          true,
          commCountryCode,
          pacificMarkets,
          project.CostingTemplateVersion,
          currentCostingProfile,
          oppValue
        );
        if (!ms.RFQData) {
          tempVariables.forEach((x) => {
            if (x.dependentOn) x.disabled = true;
          });
        }

        let tempOpResourceVariables = mapperFunctions.getSchemaWithDataJSON(
          ms.SetupSchema,
          ms.Code,
          ms.SetupData
        );
        if (!ms.RFQData) {
          tempOpResourceVariables.forEach((x) => {
            if (
              x.dependentOn ||
              x.label == "Coding Required" ||
              x.label == "Verbatim Coding Required"
            ) {
              x.disabled = true;
            }
          });
        }
        // create a costing profile
        if (
          !_.includes(pacificMarkets, project.CommissioningCountry) ||
          (_.includes(pacificMarkets, project.CommissioningCountry) &&
            project.CostingTemplateVersion == "v2.0")
        ) {
          let tempCostingOption = [];
          if (ms.CostingOptions?.length == 0)
            tempCostingOption = [
              {
                label: "Default Option",
                value: "Default Option",
                isSelected: "true",
                Variables: _.cloneDeep(tempVariables.filter((x) => x.Default)),
              },
            ];
          else {
            ms.CostingOptions.forEach((co) => {
              let _tempCostingOption = {};
              Object.keys(co).map((optionKey) => {
                _tempCostingOption[optionKey] = co[optionKey];
              });
              _tempCostingOption.label = co.Name;
              _tempCostingOption.value = co.Name;
              _tempCostingOption.id = co.id;
              _tempCostingOption.isSelected = co.Selected;
              _tempCostingOption.Variables = _.cloneDeep(
                co.CostingOptionSchema
              );
              tempCostingOption.push(_tempCostingOption);
              // tempCostingOption.push({
              //     label: co.Name, value: co.Name, id: co.id, isSelected: co.Selected
              //     , MethodologySpecId: co.MethodologySpecId, Variables: _.cloneDeep(co.CostingOptionSchema)
              // })
            });
          }
          ms["CostingOptions"] = _.cloneDeep(tempCostingOption);
        }

        ms["Variables"] = _.cloneDeep(tempVariables);
        ms["OpResourcesVariables"] = tempOpResourceVariables;
        ms["Responsibilities"] = _.cloneDeep(ms.ResponsibilitySchema);
      });
    });
    return cloneProfile;
  }

  const validateProject = () => {
    let isValid = true;
    let updatedObj = {};

    // Market Details Fields
    let fieldsToValidate = project?.IsSyndicated ? [
      "BusinessUnit",
      "CommissioningCountry",
      "CommissioningOffice",
      "IndustryVertical",
      "SalesTeamContact"
    ] : [
      "BusinessUnit",
      "CommissioningCountry",
      "CommissioningOffice",
      "IndustryVertical",
    ]
    ;

    fieldsToValidate.forEach((field) => {
      if (!project[field] || project[field] === "") {
        //console.log(field);
        updatedObj[field] = true;
        isValid = false;
      } else {
        updatedObj[field] = false;
      }
    });

    // Contracts
    if (project.ContractDetails && project.ContractDetails.length > 0) {
      updatedObj["Contracts"] = false;
    } else {
      //console.log("Contracts");
      isValid = false;
      updatedObj["Contracts"] = true;
    }

    // Project Name
    if (!project.ProjectName || project.ProjectName.trim().length < 1) {
      isValid = false;
      updatedObj["ProjectName"] = true;
    } else {
      updatedObj["ProjectName"] = false;
    }
    // sales team contact
    if (!project.SalesTeamContact?.value && !project?.ContractDetails[0]?.OpportunityNumber && project?.IsSyndicated) {
      isValid = false;
      setSalesTeamContactError(true)
    } 
    else{
      setSalesTeamContactError(false)
    }
    setFieldInvalidationProject({ ...fieldInvalidationProject, ...updatedObj });
    if (!isValid) {
      toastr.error("Validation Errors", "Please check for missing Inputs");
    }
    return isValid;
  };

  const validateMethodology = () => {
    try {
      let updatedObj = {};
      let isValid = true;

      // Project Details Fields

      // if (
      //   currentCostingProfile.Methodology?.length < 1 ||
      //   currentCostingProfile.Methodology == undefined
      // ) {
      //   //console.log("MEthodology");
      //   isValid = false;
      //   updatedObj["Methodology"] = true;
      // } else {
      //   updatedObj["Methodology"] = false;
      // }

      if (
        currentCostingProfile.SubMethodology?.length < 1 ||
        currentCostingProfile.SubMethodology == undefined
      ) {
        //console.log("SubMethodology");
        isValid = false;
        updatedObj["SubMethodology"] = true;
      } 
      else {
        updatedObj["SubMethodology"] = false;
      }

      if ((project?.CostingProfiles[0]?.SubMethodology?.length >1) && (currentCostingProfile?.SubMethodology?.length < 1 ||
        currentCostingProfile?.SubMethodology == undefined)) {
        currentCostingProfile.SubMethodology = project.CostingProfiles[0].SubMethodology
        isValid = true;
        updatedObj["SubMethodology"] = false;
      }

      //   if (oppValue === "" && (!project.IsSyndicated || project.SyndicatedwithOpp == true)) {
      //   if (
      //    ( currentCostingProfile.StudyType?.length < 1 ||
      //     currentCostingProfile.StudyType == undefined)
      //   ) {
      //     //console.log("StudyType");
      //     isValid = false;
      //     updatedObj["StudyType"] = true;
      //   } else {
      //     updatedObj["StudyType"] = false;
      //   }
      // }

      if (currentCostingProfile.IsMultiCountry) {
        if (
          currentCostingProfile.FieldingCountries === null ||
          // currentCostingProfile.FieldingCountries.length < 2 ||
          currentCostingProfile.FieldingCountries.length === 0
        ) {
          //console.log("fielding ");
          isValid = false;
          updatedObj["FieldingCountries"] = true;
        } else {
          updatedObj["FieldingCountries"] = false;
        }
      } else {
        if (currentCostingProfile.FieldingCountries.length === 0) {
          updatedObj["FieldingCountries"] = true;
          isValid = false;
          updatedObj["FieldingCountries"] = true;
        } else {
          updatedObj["FieldingCountries"] = false;
        }
      }
      // let _updatedcurrentCostingProfile = convertLabelfromCSV(
      //   currentCostingProfile
      // );

      // let Methodologynotselected = codeLabels.MethodologyOptions.filter((x) =>
      //   _updatedcurrentCostingProfile.Methodology?.some(
      //     (y) => y.value == x.Code
      //   )
      // )
      //   .map((obj) => ({
      //     M: obj.Label,
      //     A: obj.SubMethodologies.filter((x) =>
      //       _updatedcurrentCostingProfile.SubMethodology?.some(
      //         (o2) => x.Code === o2.value
      //       )
      //     ),
      //   }))
      //   .filter((x) => x.A.length == 0);
      // if (Methodologynotselected.length > 0) {
      //   isValid = false;
      //   toastr.error(
      //     "Please review your input, no Submethodology is selected for " +
      //       Methodologynotselected[0].M.toLowerCase()
      //   );
      // } else
      if(!oppData?.length){
        oppData = costingProfile.OPPDatas
      }
      while (typeof oppData === "string") {
        oppData = JSON.parse(oppData);
      }
      
      if (!isValid) {
        toastr.error("Please fill all the fields!!!");
      }
      if (
        oppData !== undefined &&
        oppData !== null &&
        Object.keys(oppData).length != 0 &&
        (!project.IsSyndicated || project.SyndicatedwithOpp == true)
      ) {
        oppData.map((lineItem, index) => {
          if (lineItem?.MSDMethodology === undefined) {
            isValid = false;
            toastr.error("Please input Methodology for all line items");
            return;
          }
        });
      }

      //console.log("UPDATED OBJ", updatedObj);
      setFieldInvalidationCosting({
        ...fieldInvalidationCosting,
        ...updatedObj,
      });
      // setFieldInvalidationCosting(
      //     update(fieldInvalidationCosting, { $merge: updatedObj }));

      return isValid;
    } catch (err) {
      console.log(err);
    }
  };

  function convertLabelfromCSV(ccProfile) {
    //QuickFix: Need to find proper Solution

    let _updatedcurrentCostingProfile = {
      ...ccProfile,
      // StudyType: oppData === "" ? (!Array.isArray(ccProfile.StudyType)
      //   ? _.cloneDeep(
      //       mapperFunctions.GetValueLablesFromCSV(
      //         codeLabels.StudyTypeOptions,
      //         ccProfile.StudyType
      //       )
      //     )
      //   : ccProfile.StudyType) : null,
      Methodology: !Array.isArray(ccProfile.Methodology)
        ? _.cloneDeep(
            mapperFunctions.GetValueLablesFromCSV(
              codeLabels.MethodologyOptions,
              ccProfile.Methodology
            )
          )
        : ccProfile.Methodology,
      SubMethodology: !Array.isArray(ccProfile.SubMethodology)
        ? _.cloneDeep(
            mapperFunctions.GetValueLablesFromCSV(
              codeLabels.SubMethodologyOptions,
              ccProfile.SubMethodology
            )
          )
        : ccProfile.SubMethodology,
      IsMultiCountry: ccProfile.IsMultiCountry,
      FieldingCountries: !Array.isArray(ccProfile.FieldingCountries)
        ? _.cloneDeep(
            mapperFunctions.GetValueLablesFromCSV(
              codeLabels.FieldingCountriesOptions,
              ccProfile.FieldingCountries
            )
          )
        : ccProfile.FieldingCountries,
    };
    return _updatedcurrentCostingProfile;
  }

  const validateCosting = () => {
    let isValid = true;
    let updatedObj = {};

    // Project Details Fields
    //Removing existing logic of profilecretion as nowhere it is been used
    //By Ravi
    // if (currentCostingProfile.Methodology?.length < 1) {
    //   //console.log("MEthodology");
    //   isValid = false;
    //   updatedObj["Methodology"] = true;
    // } else {
    //   updatedObj["Methodology"] = false;
    // }

    if (currentCostingProfile.SubMethodology?.length < 1) {
      //console.log("SubMethodology");
      isValid = false;
      updatedObj["SubMethodology"] = true;
    } else {
      updatedObj["SubMethodology"] = false;
    }

    //   if (oppData === "") {
    //   if (currentCostingProfile.StudyType?.length < 1) {
    //     //console.log("StudyType");
    //     isValid = false;
    //     updatedObj["StudyType"] = true;
    //   } else {
    //     updatedObj["StudyType"] = false;
    //   }
    // }

    // if (currentCostingProfile.IsTracker) {
    //     if (currentCostingProfile.NumberOfWaves <= 1) {
    //         //console.log("numberof waves");
    //         isValid = false;
    //         updatedObj["NumberOfWaves"] = true;
    //     } else {
    //         updatedObj["NumberOfWaves"] = false;
    //     }

    //     if (currentCostingProfile.TrackingFrequency === "") {
    //         //console.log("tracking");
    //         isValid = false;
    //         updatedObj["TrackingFrequency"] = true;
    //     } else {
    //         updatedObj["TrackingFrequency"] = false;
    //     }
    // }

    if (currentCostingProfile.IsMultiCountry) {
      if (
        currentCostingProfile.FieldingCountries === null ||
        // currentCostingProfile.FieldingCountries.length < 2 ||
        currentCostingProfile.FieldingCountries.length === 0
      ) {
        //console.log("fielding ");
        isValid = false;
        updatedObj["FieldingCountries"] = true;
      } else {
        updatedObj["FieldingCountries"] = false;
      }
    } else {
      if (currentCostingProfile.FieldingCountries.length === 0) {
        updatedObj["FieldingCountries"] = true;
        isValid = false;
        updatedObj["FieldingCountries"] = true;
      } else {
        updatedObj["FieldingCountries"] = false;
      }
    }

    //console.log("UPDATED OBJ", updatedObj);
    // setFieldInvalidationCosting(
    //     update(fieldInvalidationCosting, { $merge: updatedObj })
    // );
    setFieldInvalidationCosting({ ...fieldInvalidationCosting, ...updatedObj });

    if (!isValid) {
      toastr.error("Form is missing fields");
    }

    return isValid;
  };

  const getProperty = (schema, prop) => {
    if (schema.properties[prop]) return schema.properties[prop];
    else {
      let reqVal = "";
      if (schema.dependencies) {
        Object.keys(schema.dependencies).map((dep) => {
          schema.dependencies[dep].oneOf.map((oo) => {
            if (oo.properties[prop] && oo.properties[prop].title) {
              reqVal = oo.properties[prop];
            }
          });
        });
      }
      return reqVal;
    }
  };

  const dowloadMultipleProfileRFQ = (profile, rfqData) => {
    let allRFQDataParams = {};
    profile.CountrySpecs?.map((cs) => {
      if (cs.MethodologySpecs?.length) {
        cs.MethodologySpecs.map((ms) => {
          allRFQDataParams[ms.id] = [];
          let allCostingOptionKeys = [
            "targetGroupDefinition",
            "citiesCoverage",
            "sampleSize",
            "lengthOfInterview",
            "sampleSource",
            "samplingType",
          ];

          ms.CostingOptions?.map((co) => {
            if (co?.CostingOptionData) {
              Object.keys(co.CostingOptionData).map((cd) => {
                allCostingOptionKeys.push(cd);
              });
            }
          });
          if (ms.RFQSchema && ms.RFQSchema.properties) {
            Object.keys(ms.RFQSchema.properties).map((rfqkey) => {
              if (
                !_.includes(allRFQDataParams[ms.id], rfqkey) &&
                !_.includes(allCostingOptionKeys, rfqkey)
              ) {
                allRFQDataParams[ms.id].push(rfqkey);
              }
            });
          }
          if (ms.RFQSchema?.dependencies) {
            Object.keys(ms.RFQSchema.dependencies).map((dep) => {
              ms.RFQSchema.dependencies[dep].oneOf.map((oo) => {
                Object.keys(oo.properties).map((rfqkey) => {
                  if (
                    !_.includes(allRFQDataParams[ms.id], rfqkey) &&
                    !_.includes(allCostingOptionKeys, rfqkey)
                  ) {
                    allRFQDataParams[ms.id].push(rfqkey);
                  }
                });
              });
            });
          }
        });
      }
    });

    let data = [];
    _.head(profile.CountrySpecs)?.MethodologySpecs.map((ms) => {
      let finalList = [];
      let methtitleObj = {
        "Methodology BreakDown": ms.Label,
      };
      profile.CountrySpecs.map((cs) => {
        methtitleObj[getLabel("FieldingCountriesOptions", cs.CountryCode)] = "";
      });
      finalList.push(methtitleObj);

      allRFQDataParams[ms.id].map((rfq) => {
        let finalObj = {};
        let property = getProperty(ms.RFQSchema, rfq);
        // if (property && property.isNewSection) {
        //     let titleObj = {};
        //     titleObj["Methodology BreakDown"] = property.sectionTitle;
        //     profile.CountrySpecs.map((cs) => {
        //         titleObj[getLabel("FieldingCountriesOptions", cs.CountryCode)] = "";
        //     });
        //     finalList.push(titleObj);
        // }
        if (property) {
          finalObj["Methodology BreakDown"] = property.title;
          profile.CountrySpecs.map((cs) => {
            finalObj[getLabel("FieldingCountriesOptions", cs.CountryCode)] =
              rfqData[cs.CountryCode] && rfqData[cs.CountryCode][ms.Code]
                ? Array.isArray(rfqData[cs.CountryCode][ms.Code][rfq])
                  ? rfqData[cs.CountryCode][ms.Code][rfq].join()
                  : typeof rfqData[cs.CountryCode][ms.Code][rfq] == "boolean"
                  ? rfqData[cs.CountryCode][ms.Code][rfq]
                    ? "Yes"
                    : "No"
                  : rfqData[cs.CountryCode][ms.Code][rfq]
                  ? rfqData[cs.CountryCode][ms.Code][rfq]
                  : "-"
                : "-";
          });
          finalList.push(finalObj);
        }
      });
      data.push(finalList);
    });
    return data;
    // let final = []
    // data.map(t => {
    //   final.push(...t)
    // })
    // allTabs.push(final)
    // }
  };

  const getOpsValue = (value, prop) => {
    if (value || value == false) {
      if (prop.toLowerCase().indexOf("complexity") != -1) {
        if (prop == "surveyProgrammingComplexity") {
          return _.head(
            codeLabels.QuestionnaireComplexityOptions.filter(
              (frq) => frq.Code == value
            )
          )?.Label;
        }
        if (prop == "dataProcessingComplexity") {
          return _.head(
            codeLabels.DataProcessingComplexityOptions.filter(
              (frq) => frq.Code == value
            )
          )?.Label;
        }
        if (prop == "chartingComplexity") {
          return _.head(
            codeLabels.ChartingComplexityOptions.filter(
              (frq) => frq.Code == value
            )
          )?.Label;
        }
      } else {
        if (Array.isArray(value)) {
          if (typeof _.head(value) == "string") return value.join();
          else return value.map((v) => v.label).join();
        } else if (typeof value == "boolean") {
          return value ? "Yes" : "No";
        } else return value;
      }
    } else {
      return "-";
    }
  };

  const downloadMultipleOps = (profile, opsData) => {
    let methBreak = {};
    profile?.CountrySpecs?.map((cs) => {
      cs.MethodologySpecs?.map((ms) => {
        if (!methBreak[ms.id]) {
          methBreak[ms.id] = { country: cs.CountryName, methlabel: ms.Label };
        }
      });
    });
    let responsibilities = _.head(profile.WaveSpecs)?.ResponsibilitySchema;
    let _allResponsibilities = [];
    if (responsibilities) {
      Object.keys(responsibilities)?.map((res) => {
        if (!_.includes(_allResponsibilities, res)) {
          _allResponsibilities.push(res);
        }
      });
    }

    let allOpsResourcesDataParams = [];
    profile?.WaveSpecs?.map((t) => {
      if (t.OpsResourcesSchema && t.OpsResourcesSchema.properties) {
        Object.keys(t.OpsResourcesSchema.properties)?.map((opd) => {
          if (
            !_.includes(allOpsResourcesDataParams, opd) &&
            !_.includes(_allResponsibilities, opd)
          )
            allOpsResourcesDataParams.push(opd);
        });
      }
    });
    let data = [];
    allOpsResourcesDataParams?.map((ors) => {
      let finalList = [];
      let property = getProperty(
        _.head(profile?.WaveSpecs)?.OpsResourcesSchema,
        ors
      );
      // if (property && property.isNewSection) {
      //     let titleObj = {};
      //     titleObj["Operation Resources BreakDown"] = property.sectionTitle;
      //     profile.WaveSpecs.map((cs) => {
      //         let key = cs.WaveName
      //             ? `#${cs.WaveNumber} ${cs.WaveName}`
      //             : `#${cs.WaveNumber}`;
      //         titleObj[key] = "";
      //     });
      //     finalList.push(titleObj);
      // }
      if (property) {
        let finalObj = {};
        finalObj["Operation Resources BreakDown"] = property.title;
        profile?.WaveSpecs?.map((cs) => {
          let key = cs.WaveName
            ? `#${cs.WaveNumber} ${cs.WaveName} ${
                methBreak[cs.MethodologySpecId]?.methlabel
              },${methBreak[cs.MethodologySpecId]?.country}`
            : `#${cs.WaveNumber} ${
                methBreak[cs.MethodologySpecId]?.methlabel
              },${methBreak[cs.MethodologySpecId]?.country}`;
          finalObj[key] = opsData[cs.WaveNumber]
            ? getOpsValue(opsData[cs.WaveNumber][ors], ors)
            : "-";
        });
        finalList.push(finalObj);
      }
      data.push(finalList);
    });
    return data;
  };
  const downloadCostingOptions = (currentCostingProfile) => {
    let allCostingOptions = [];
    currentCostingProfile?.CountrySpecs?.map((cs) => {
      if (cs?.MethodologySpecs) {
        cs?.MethodologySpecs?.map((ms) => {
          ms.CostingOptions?.filter((co) => co.Selected).map((t) => {
            t?.CostingOptionSchema?.map((cso) => {
              if (t.CostingOptionData && t.CostingOptionData[cso.id]) {
                if (
                  !allCostingOptions.filter((asc) => asc.id == cso.id).length
                ) {
                  allCostingOptions.push(cso);
                }
              }
            });
          });
        });
      }
    });

    let data = [];
    currentCostingProfile.CountrySpecs?.map((cs) => {
      let finalList = [];
      let finalObj = {};
      finalObj["Costing Options"] = cs.CountryName;

      cs?.MethodologySpecs?.map((ms) => {
        ms.CostingOptions?.filter((co) => co.Selected)?.map((cso) => {
          finalObj[`${ms.Label}-${cso.Name}`] = "";
        });
      });
      finalList.push(finalObj);

      allCostingOptions?.map((aco) => {
        let finalObj = {};

        finalObj["Costing Options"] = aco.label;
        cs?.MethodologySpecs?.map((ms) => {
          ms.CostingOptions?.filter((co) => co.Selected)?.map((cso) => {
            finalObj[`${ms.Label}-${cso.Name}`] = cso.CostingOptionData
              ? Array.isArray(cso.CostingOptionData[aco.id])
                ? cso.CostingOptionData[aco.id].map((v) => v.label).join()
                : typeof cso.CostingOptionData[aco.id] == "object"
                ? cso.CostingOptionData[aco.id].label
                : cso.CostingOptionData[aco.id]
              : "";
          });
        });
        finalList.push(finalObj);
      });
      data.push(finalList);
    });
    return data;
  };
  const downloadProfiles = () => {
    var opts = [];
    var allTabs = [];
    selectedProfiles?.map((profile) => {
      let finalData = {};
      profile.CountrySpecs?.map((cs) => {
        cs?.MethodologySpecs.filter((ms) => !ms.NotApplicable)?.map((ms) => {
          if (!finalData[cs.CountryCode]) {
            finalData[cs.CountryCode] = {};
          }
          if (!finalData[cs.CountryCode][ms.Code]) {
            finalData[cs.CountryCode][ms.Code] = {};
          }
          finalData[cs.CountryCode][ms.Code] = ms.RFQData;
        });
      });
      let data = dowloadMultipleProfileRFQ(profile, finalData);
      let final = [];
      data?.map((t) => {
        final.push(...t);
      });
      if (final.length) {
        allTabs.push(final);
      }
      opts.push({
        sheetid: `${profile.ProfileNumber}${
          profile.ProfileName ? `_${profile.ProfileName}_RFQData` : ""
        }`,
        header: true,
      });

      let opsFinal = {};
      profile.WaveSpecs?.map((ws) => {
        opsFinal[ws.WaveNumber] = ws.OpsResourcesData;
      });

      let reqData = downloadMultipleOps(profile, opsFinal);
      // })
      let finalOps = [];
      reqData.map((t) => {
        finalOps.push(...t);
      });
      if (finalOps.length) {
        allTabs.push(finalOps);
        opts.push({
          sheetid: `${profile.ProfileNumber}${
            profile.ProfileName ? `_${profile.ProfileName}_OpsData` : ""
          }`,
          header: true,
        });
      }

      if (
        project?.CostingTemplateVersion == "v2.0" ||
        (project?.CostingTemplateVersion != "v2.0" &&
          !_.includes(pacificMarkets, project?.CommissioningCountry))
      ) {
        let costingOptionData = downloadCostingOptions(profile);
        let finalCostings = [];
        costingOptionData?.map((t) => {
          finalCostings.push(...t);
        });
        if (finalCostings.length) {
          allTabs.push(finalCostings);
        }
        opts.push({
          sheetid: `${profile.ProfileNumber}${
            profile.ProfileName
              ? `_${profile.ProfileName}_CostingOptionData`
              : ""
          }`,
          header: true,
        });
      }
    });
    if (allTabs.length) {
      alasql("SELECT INTO XLSX('ProfileSummary.xlsx',?) FROM ?", [
        opts,
        [...allTabs],
      ]);
    } else {
      toastr.error("No RFQ Data to download");
      setSelectedProfiles([]);
      setSelectProfilesDownload(!selectProfilesDownload);
    }
  };

  return (
    <Layout
      hideProfileName={true}
      hideActions={project.Mode == "CostingOptionsDetails" ? false : true}
      projectStatusToDisplay={getLabel(
        "ProjectStatusOptions",
        project?.ProjectStatus
      )}
    >
      <>
        <Container>
          {(!project.Mode || project.Mode == "decommision") && (
            <>
              <Card>
                <CardHeader>
                  <Row className="d-flex justify-content-between">
                    <Col xs="8" className="align-self-center">
                      <Row className="d-flex justify-content-start">
                        <Col className="align-self-center">
                          <CardTitle className="text-uppercase mb-0">
                            Project Details
                            {JSON.stringify(links_sidebar)}
                          </CardTitle>
                        </Col>
                        {/* <Col className="align-self-center">
                                  <Badge title="Project Status">
                                    {getLabel(
                                      "ProjectStatusOptions",
                                      project?.ProjectStatus
                                    )}
                                  </Badge>
                                </Col> */}
                      </Row>
                    </Col>
                    <Col xs="3" className="align-self-center">
                      <Link
                        className=" p-1 medium float-right mr-2"
                        onClick={(e) =>
                          setToggle({
                            ...toggle,
                            overall: !toggle.overall,
                            start: !toggle.overall ? true : false,
                            salesforce: !toggle.overall ? true : false,
                            projectContacts: !toggle.overall ? true : false,
                            costingProfiles: !toggle.overall ? true : false,
                          })
                        }
                      >
                        <Label className="mb-0 mr-1 small">
                          {!toggle.overall ? "Expand All" : "Collapse All"}
                        </Label>
                        <FontAwesomeIcon
                          icon={!toggle.overall ? faChevronDown : faChevronUp}
                          fixedWidth
                          className="mb-0 mr-3 medium"
                        />
                      </Link>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>

              <Card className="ml-2 mr-2 mb-2">
                  <CardHeader
                    onClick={(e) =>
                      setToggle({ ...toggle, salesforce: !toggle.salesforce })
                    }
                  >
                    <Row>
                      <Col>
                        <CardTitle className="mb-0">Project Details</CardTitle>
                      </Col>
                      <Col xs="1">
                        <FontAwesomeIcon
                          className="align-middle mr-2"
                          icon={
                            !toggle.salesforce ? faChevronRight : faChevronDown
                          }
                          fixedWidth
                        />
                      </Col>
                    </Row>
                  </CardHeader>
                  <Collapse isOpen={toggle.salesforce}>
                    <CardBody>
                      {
                        <Salesforce
                          user={user}
                          project={project}
                          updateProject={updateProject}
                          fieldInvalidation={fieldInvalidationProject}
                        />
                      }
                    </CardBody>
                  </Collapse>
                </Card>

              { project?.IsSyndicated ? (project?.ContractDetails.length > 0) && <Card className="ml-2 mr-2 mb-2">
                <CardHeader
                  onClick={(e) =>
                    setToggle({ ...toggle, start: !toggle.start })
                  }
                >
                  <Row>
                    <Col xs="11">
                      <CardTitle className="mb-0">Market Details</CardTitle>
                    </Col>
                    <Col xs="1">
                      <FontAwesomeIcon
                        className="align-middle mr-2"
                        icon={!toggle.start ? faChevronRight : faChevronDown}
                        fixedWidth
                      />
                    </Col>
                  </Row>
                </CardHeader>
                <Collapse isOpen={toggle.start}>
                  <CardBody>
                    <MarketDetails
                      project={project}
                      updateProject={updateProject}
                      fieldInvalidation={fieldInvalidationProject}
                    />
                  </CardBody>
                </Collapse>
              </Card> : <Card className="ml-2 mr-2 mb-2">
                <CardHeader
                  onClick={(e) =>
                    setToggle({ ...toggle, start: !toggle.start })
                  }
                >
                  <Row>
                    <Col xs="11">
                      <CardTitle className="mb-0">Market Details</CardTitle>
                    </Col>
                    <Col xs="1">
                      <FontAwesomeIcon
                        className="align-middle mr-2"
                        icon={!toggle.start ? faChevronRight : faChevronDown}
                        fixedWidth
                      />
                    </Col>
                  </Row>
                </CardHeader>
                <Collapse isOpen={toggle.start}>
                  <CardBody>
                    <MarketDetails
                      project={project}
                      updateProject={updateProject}
                      fieldInvalidation={fieldInvalidationProject}
                    />
                  </CardBody>
                </Collapse>
              </Card>}

              
                
              

              {project?.IsSyndicated ? (project?.ContractDetails.length > 0) && <Card className="ml-2 mr-2 mb-2">
                <CardHeader
                  onClick={(e) =>
                    setToggle({
                      ...toggle,
                      projectContacts: !toggle.projectContacts,
                    })
                  }
                >
                  <Row>
                    <Col>
                      <CardTitle className="mb-0">Project Contacts</CardTitle>
                    </Col>
                    <Col xs="1">
                      <FontAwesomeIcon
                        className="align-middle mr-2"
                        icon={
                          !toggle.projectContacts
                            ? faChevronRight
                            : faChevronDown
                        }
                        fixedWidth
                      />
                    </Col>
                  </Row>
                </CardHeader>

                <Collapse isOpen={toggle.projectContacts}>
                  <CardBody>
                    <ProjectContacts
                      user={user}
                      project={project}
                      updateProject={updateProject}
                      primaryCSContacts={primaryCSContacts}
                      otherInternalContacts={otherInternalContacts}
                      SalesTeamContactError={SalesTeamContactError}
                    />
                  </CardBody>
                </Collapse>
              </Card> : <Card className="ml-2 mr-2 mb-2">
                <CardHeader
                  onClick={(e) =>
                    setToggle({
                      ...toggle,
                      projectContacts: !toggle.projectContacts,
                    })
                  }
                >
                  <Row>
                    <Col>
                      <CardTitle className="mb-0">Project Contacts</CardTitle>
                    </Col>
                    <Col xs="1">
                      <FontAwesomeIcon
                        className="align-middle mr-2"
                        icon={
                          !toggle.projectContacts
                            ? faChevronRight
                            : faChevronDown
                        }
                        fixedWidth
                      />
                    </Col>
                  </Row>
                </CardHeader>

                <Collapse isOpen={toggle.projectContacts}>
                  <CardBody>
                    <ProjectContacts
                      user={user}
                      project={project}
                      updateProject={updateProject}
                      primaryCSContacts={primaryCSContacts}
                      otherInternalContacts={otherInternalContacts}
                      SalesTeamContactError={SalesTeamContactError}
                    />
                  </CardBody>
                </Collapse>
              </Card>}

              {costingProfiles.length > 0 ? (
                <Card className="ml-2 mr-2 mb-2">
                  <CardHeader
                    onClick={(e) =>
                      setToggle({
                        ...toggle,
                        costingProfiles: !toggle.costingProfiles,
                      })
                    }
                  >
                    <Row>
                      <Col xs="8">
                        <CardTitle className="mb-0">Costing Profiles</CardTitle>
                      </Col>
                      <Col xs="3">
                        <Button
                          disabled={project.ProjectStatus == "7"}
                          onClick={(e) => {
                            if (project.IsUploaded) {
                              setUploadProject(true);
                            } else {
                              e.stopPropagation();
                              setSelectProfilesDownload(true);
                              setSelectedProfiles([]);
                            }
                          }}
                          color="secondary"
                        >
                          Download
                        </Button>
                      </Col>
                      {project.IsUploaded && uploadProject && (
                        <UploadedProjectModal
                          uploadProject={uploadProject}
                          setUploadProject={setUploadProject}
                        />
                      )}

                      <Col xs="1">
                        <FontAwesomeIcon
                          className="align-middle mr-2"
                          icon={
                            !toggle.costingProfiles
                              ? faChevronRight
                              : faChevronDown
                          }
                          fixedWidth
                        />
                      </Col>
                    </Row>
                  </CardHeader>
                  <Collapse isOpen={toggle.costingProfiles}>
                    <CardBody>
                      <CostingProfilesTable />
                    </CardBody>
                  </Collapse>
                </Card>
              ) : null}

             {project?.IsSyndicated ? (project?.ContractDetails.length > 0) && <Card className="ml-2 mr-2 mb-2">
                <CardHeader
                  onClick={(e) =>
                    setToggle({
                      ...toggle,
                      methodologyDTL: !toggle.methodologyDTL,
                    })
                  }
                >
                  <Row>
                    <Col>
                      <CardTitle className="mb-0">
                        Methodologies Details
                      </CardTitle>
                      {/* {_.includes(pacificMarkets, project.CommissioningCountry) ? <i className="help">Countries and Methodologies updated here will be applicable only for Master Costing Profile</i> : null} */}
                    </Col>
                    <Col xs="1">
                      <FontAwesomeIcon
                        className="align-middle mr-2"
                        icon={
                          !toggle.methodologyDTL
                            ? faChevronRight
                            : faChevronDown
                        }
                        fixedWidth
                      />
                    </Col>
                  </Row>
                </CardHeader>

                <Collapse isOpen={toggle.methodologyDTL}>
                  <CardBody>
                    <ProjectDetails
                      project={project}
                      updateProject={updateProject}
                      fieldInvalidation={fieldInvalidationCosting}
                      setProfileDetailsChange={setProfileDetailsChange}
                      setCostingMarkets={setCostingMarketTable}
                    />
                  </CardBody>
                </Collapse>
              </Card> : <Card className="ml-2 mr-2 mb-2">
                <CardHeader
                  onClick={(e) =>
                    setToggle({
                      ...toggle,
                      methodologyDTL: !toggle.methodologyDTL,
                    })
                  }
                >
                  <Row>
                    <Col>
                      <CardTitle className="mb-0">
                        Methodologies Details
                      </CardTitle>
                      {/* {_.includes(pacificMarkets, project.CommissioningCountry) ? <i className="help">Countries and Methodologies updated here will be applicable only for Master Costing Profile</i> : null} */}
                    </Col>
                    <Col xs="1">
                      <FontAwesomeIcon
                        className="align-middle mr-2"
                        icon={
                          !toggle.methodologyDTL
                            ? faChevronRight
                            : faChevronDown
                        }
                        fixedWidth
                      />
                    </Col>
                  </Row>
                </CardHeader>

                <Collapse isOpen={toggle.methodologyDTL}>
                  <CardBody>
                    <ProjectDetails
                      project={project}
                      updateProject={updateProject}
                      fieldInvalidation={fieldInvalidationCosting}
                      setProfileDetailsChange={setProfileDetailsChange}
                      setCostingMarkets={setCostingMarketTable}
                    />
                  </CardBody>
                </Collapse>
              </Card>}
             { project?.IsSyndicated ? (project?.ContractDetails.length > 0) && <Card className="ml-2 mr-2 mb-2">
                <CardHeader
                  onClick={(e) =>
                    setToggle({
                      ...toggle,
                      projectOverview: !toggle.projectOverview,
                    })
                  }
                >
                  <Row>
                    <Col>
                      <CardTitle className="mb-0">Project Overview</CardTitle>
                    </Col>
                    <Col xs="1">
                      <FontAwesomeIcon
                        className="align-middle mr-2"
                        icon={
                          !toggle.projectOverview
                            ? faChevronRight
                            : faChevronDown
                        }
                        fixedWidth
                      />
                    </Col>
                  </Row>
                </CardHeader>
                <Collapse isOpen={toggle.projectOverview}>
                  <CardBody>
                    <ProjectOverview
                      project={project}
                      updateProject={updateProject}
                    />
                  </CardBody>
                </Collapse>
              </Card> : <Card className="ml-2 mr-2 mb-2">
                <CardHeader
                  onClick={(e) =>
                    setToggle({
                      ...toggle,
                      projectOverview: !toggle.projectOverview,
                    })
                  }
                >
                  <Row>
                    <Col>
                      <CardTitle className="mb-0">Project Overview</CardTitle>
                    </Col>
                    <Col xs="1">
                      <FontAwesomeIcon
                        className="align-middle mr-2"
                        icon={
                          !toggle.projectOverview
                            ? faChevronRight
                            : faChevronDown
                        }
                        fixedWidth
                      />
                    </Col>
                  </Row>
                </CardHeader>
                <Collapse isOpen={toggle.projectOverview}>
                  <CardBody>
                    <ProjectOverview
                      project={project}
                      updateProject={updateProject}
                    />
                  </CardBody>
                </Collapse>
              </Card>}
            </>
          )}

          {/* {project.Mode == "CostingOptionsDetails" &&
                        <>
                            <Row>
                                <Col md={12}>
                                    <CountryMethodologyTabs
                                        profile={_.last(project.CostingProfiles)}
                                        updateProject={updateProject}
                                    />
                                </Col>
                            </Row>
                        </>
                    } */}
        </Container>

        <Container className="d-flex mt-4 mr-2 justify-content-end">
          {profileCreation ? (
            <Button
              color="secondary"
              className="mr-2"
              onClick={() => setProfileCreation(false)}
            >
              Cancel
            </Button>
          ) : null}

          {project.Mode != "CostingOptionsDetails" && project.id ? (
            <Button
              onClick={saveOnlyProject}
              className="mr-2"
              color="secondary"
              disabled={
                ["1", "6", "30", "3", "9", "11"].indexOf(project?.ProjectStatus) == -1 ||
                (project.ProjectStatus == "8" && project.IsDeleted == true)
              }
            >
              Save Project Details
            </Button>
          ) : null}
          {project?.IsSyndicated ? (project?.ContractDetails.length > 0) && project.Mode != "CostingOptionsDetails" &&
            !(
              (project.IsSyndicated && project.SyndicatedwithOpp !== true) &&
              project.ParentProjectId &&
              !project.IsCopy
            ) && (
              <Button
                onClick={handleProfileUpdate}
                disabled={
                  ["1", "6", "30"].indexOf(project?.ProjectStatus) == -1 ||
                  (project?.CostingProfiles.length &&
                    project?.CostingProfiles?.every(
                      (cp) => cp.id && cp.ProfileStatus > 1
                    )) ||
                  project.PriceChange === 3 ||
                  (project.ProjectStatus == "8" && project.IsDeleted == true)
                }
                color="primary"
              >
                Save and Next
              </Button>
            ) : project.Mode != "CostingOptionsDetails" &&
            !(
              (project.IsSyndicated && project.SyndicatedwithOpp !== true) &&
              project.ParentProjectId &&
              !project.IsCopy
            ) && (
              <Button
                onClick={handleProfileUpdate}
                disabled={
                  ["1", "6", "30"].indexOf(project?.ProjectStatus) == -1 ||
                  (project?.CostingProfiles.length &&
                    project?.CostingProfiles?.every(
                      (cp) => cp.id && cp.ProfileStatus > 1
                    )) ||
                  project.PriceChange === 3 ||
                  (project.ProjectStatus == "8" && project.IsDeleted == true)
                }
                color="primary"
              >
                Save and Next
              </Button>
            )}

          {/* {project.Mode == "CostingOptionsDetails" && !costingStatus.showManualCostEntry && 1 == 2 &&
                        <Button
                            onClick={handleProjectSave}
                            color="primary"
                        >
                            Submit
                        </Button>
                    } */}
        </Container>
        {project?.IsSyndicated ? (project?.ContractDetails.length > 0) &&<Container className="d-flex justify-content-center mt-3">
          <RecordsBadge />
        </Container> : <Container className="d-flex justify-content-center mt-3">
          <RecordsBadge />
        </Container>}
      </>

      <Modal
        isOpen={profileCreationWarning}
        toggle={() => setProfileCreationWarning(!profileCreationWarning)}
      >
        <ModalHeader
          toggle={() => setProfileCreationWarning(!profileCreationWarning)}
        >
          Profile Creation Warning
        </ModalHeader>
        <ModalBody>
          You have made a new selection, do you want to update to{" "}
          {currentCostingProfile.id
            ? currentCostingProfile.ProfileName
            : project.CostingProfiles.length
            ? _.last(project.CostingProfiles).ProfileName
              ? `Latest Profile- ${_.last(project.CostingProfiles).ProfileName}`
              : "Latest Profile"
            : "Latest Profile"}{" "}
          or create a new Profile?
        </ModalBody>
        <ModalFooter className="justify-content-end">
          <Button color="primary" onClick={() => handleProjectSave(false)}>
            Update to{" "}
            {currentCostingProfile.id
              ? currentCostingProfile.ProfileName
              : project.CostingProfiles.length
              ? _.last(project.CostingProfiles).ProfileName
                ? `Latest Profile- ${
                    _.last(project.CostingProfiles).ProfileName
                  }`
                : "Latest Profile"
              : "Latest Profile"}
          </Button>
          <Button color="primary" onClick={() => handleProjectSave(true)}>
            Create New Profile
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={selectProfilesDownload}
        toggle={() => setSelectProfilesDownload(!selectProfilesDownload)}
        centered={true}
      >
        <ModalHeader
          toggle={() => setSelectProfilesDownload(!selectProfilesDownload)}
        >
          {/* <img src={logo} className="modallogo" /> */}
        </ModalHeader>
        <div>
          <span className="modaltitle modaltitleDisplay">
            Select Profiles to be downloaded
          </span>
        </div>
        <ModalBody>
          <Row className="m-0 justify-content-end">
            <a
              className="mr-1 select-link"
              onClick={() =>
                setSelectedProfiles([
                  ...costingProfiles
                    .filter((sp) => !sp.IsImportedProfile)
                    .map((adp) => {
                      return { ...adp };
                    }),
                ])
              }
            >
              Select all
            </a>
            <a
              className="ml-1 select-link"
              onClick={() => setSelectedProfiles([])}
            >
              Deselect all
            </a>
          </Row>
          <ul>
            {costingProfiles.map((cp, index) => (
              <li>
                <span>
                  <input
                    id={`cp_${index}`}
                    disabled={cp.IsImportedProfile}
                    type="checkbox"
                    checked={_.includes(
                      selectedProfiles.map((sp) => sp.id),
                      cp.id
                    )}
                    onChange={(e) => {
                      if (!cp.IsImportedProfile) {
                        if (e.target.checked) {
                          let selectedprofiles = [...selectedProfiles];
                          setSelectedProfiles([...selectedprofiles, cp]);
                        } else {
                          let selectedprofiles = [...selectedProfiles];
                          setSelectedProfiles([
                            ...selectedprofiles.filter((sp) => sp.id != cp.id),
                          ]);
                        }
                      }
                    }}
                  />
                  <label
                    className={`ml-2${
                      cp.IsImportedProfile ? " no-actions" : " pointer"
                    }`}
                    for={`cp_${index}`}
                  >
                    #{cp.ProfileNumber} {cp.ProfileName}
                  </label>{" "}
                  {cp.IsImportedProfile ? (
                    <span>
                      <FontAwesomeIcon
                        className="warning pointer"
                        icon={faExclamationTriangle}
                        title="You cannot download this profile as it is Migrated from v1."
                      />
                    </span>
                  ) : null}
                </span>
              </li>
            ))}
          </ul>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Button
              color="primary"
              className="mr-2"
              disabled={
                !selectedProfiles.length || project?.ProjectStatus == "7"
              }
              onClick={() => {
                downloadProfiles();
                setSelectedProfiles([]);
                setSelectProfilesDownload(!selectProfilesDownload);
              }}
            >
              Download
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                setSelectedProfiles([]);
                setSelectProfilesDownload(!selectProfilesDownload);
              }}
            >
              Cancel
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </Layout>
  );
};

export default Proposal;
