import React, { useEffect, useState } from "react";
import axios from "../../axios-interceptor";
import Wrapper from "../../components/Wrapper";
import Sidebar from "../../components/Sidebar";
import Main from "../../components/Main";
import ProjectNavbar from "../../components/ProjectNavbar";
import TimeCostModal from "../../components/TimeCostModal";
import * as currentCostingActions from "../../redux/actions/currentCostingActions";
import Select from "react-select";
import {
  Row,
  Button,
  Col,
  NavLink,
  NavItem,
  Nav,
  TabContent,
  TabPane,
  Table,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Input,
} from "reactstrap";
import { Container } from "react-bootstrap";
import {
  faPencilAlt,
  faInfoCircle,
  faPlusCircle,
  faCopy,
  faSave
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import _ from "lodash";
import * as sideBarActions from "../../redux/actions/sidebarActions";
import { Prompt } from "react-router-dom";
import TimeCostTable from "./TimeCostTable";
import CostingMarketTable from "./CostingMarketTable";
import {
  setCommercialHours,
  setCommercialCost,
  saveHourspayload,
  saveTotalCost,
  saveTimecost
} from "../../redux/actions/timecostHoursActions";
import InfoModal from "../../components/TimeCostModals/InfoModal";
import { toastr } from "react-redux-toastr";
import CopyOptionsModal from "./CopyOptionsModal";

const CommercialTimecost = () => {
  const dispatch = useDispatch();
  const timecostView = useSelector(({ timeCost }) => timeCost.selectedRole);
  const pageloaded = useSelector(({ app }) => app.pageloaded);
  const profile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  const project = useSelector(
    ({ currentProject }) => currentProject.newProject
  );
  let csCostData = useSelector(({ timecostHours }) => timecostHours.commercial);
  const levelInput=timecostView==="commercial"?profile.CSInput:profile.OpsInput
  let commercialCostData = useSelector(({ timecostHours }) => timecostHours.commercialCost);
  const currencies = useSelector(({ currentCosting }) =>
  currentCosting.currentCostingProfile.ProfileSetting &&
    currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
    ? currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
    : []
  );
  const [infoModal, setInfoModal] = useState(false);
  const [activeTabFC, setActiveTabFC] = useState(0);
  const [timecostViewModal, settimecostViewModal] = useState(false);
  const [activeTabSM, setActiveTabSM] = useState(0);
  const [activeTabCO, setActiveTabCO] = useState(0);
  const [activitiesForCS, setActivitiesForCS] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [band, setBand] = useState([]);
  const [alert, setAlert] = useState(false);
  const [activityMapping, setActivityMapping] = useState([]);
  const [currentActiveTab, setCurrentActiveTab] = useState("1");
  const [localSupportCS, setLocalSupportCS] = useState(true);
  const [localSupportModalCS, setLocalSupportModalCS] = useState(true);
  const [role, setRole] = useState("CS");
  const [CSTeamDataOptions, setCSTeamDataOptions] = useState([]);
  const [CSTeamDataOptionsTemp, setCSTeamDataOptionsTemp] = useState([]);
  const [defaultRates, setDefaultRates] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isClear, setIsClear] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCostOptionID, setSelectedCostOptionID] = useState();
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedCountryForClear, setSelectedCountryForClear] = useState();
  const [selectedCountryData, setSelectedCountryData] = useState({});
  const [modal, setModal] = useState(false);
  const [selectedMethodologyData1, setSelectedMethodologyData1] = useState({});
  const [modalMethodology, setModalMethodology] = useState(false);
  const [options, setoptions] = useState();
  const [makeScroll, setMakeScroll] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [costoptionsDetails,setCostoptionsDetails]=useState({});
  const [deletedTimeCostData,setDeletedTimeCostData]=useState({});
  const [deletedCostData,setDeletedCostData]=useState({})
  const [CSCurrencyUnit,setCSCurrencyUnit]=useState("")
  const [CSCurrencyConversion,setCSCurrencyConversion]=useState(1)
  const [CScurrencyDropdown,setCScurrencyDropdown]=useState({})
  const [CSCurrencyCode,setCSCurrencyCode]=useState("")
  const [showProductValidation, setShowProductValidation] = useState(true);
  const [CsTimecostReason,setcsTimecostReason]=useState({})
  const [csTimecostReasonSaved,setcsTimecostReasonSaved]=useState({})
  const [IsTextBoxdisabled, SetIsTextBoxdisabled] = useState([]);
  const [minCommercialHours, SetMinCommercialHours] = useState([]);

  const teamData = async(countryArray, callBack) => {
    axios.get(`/timecost/country`).then((res) => {
      let csTeamData = [];
      let csTeamCountry = res.data.result;
      let csTempData = [];

      csTeamCountry.map((csTeam) => {
          let optionObj = {
            value: csTeam.Code,
            label: csTeam.Label,
          };
          csTeamData.push(optionObj);
      });
      if (countryArray && countryArray?.length > 0) {
        let cArray = [];
        countryArray?.map((c) => {
          if (profile.CountrySpecs[activeTabFC].MethodologySpecs[
            activeTabSM
          ]?.CostingOptions[activeTabCO]?.id == Object.keys(c)[0]
          ) {
            cArray.push(Object.values(c)[0])
          }
        });
        if (cArray && cArray?.length > 0) {
          csTempData = csTeamData.filter((data) =>
            !cArray.includes(data.label)
          );
        }
      }
      setCSTeamDataOptions(csTeamData);
      setCSTeamDataOptionsTemp(csTempData);
      callBack();
    });
  };
  console.log("***", CSTeamDataOptions);

  const tableHeaders = [
    "Product Line",
    "WBS Number",
    "Methodology",
    "Costing Option",
    "Function",
    "Internal Cost (USD)",
    "Number of hours",
    `Minimum number of hours threshold by ${profile.ThresholdTimecost * 100}%`,
    "Reason for low time cost"
  ];

  const gettimecost = async () => {
    setIsLoading(true);
    const res = await axios.get(`/timecost/defaults/${profile.id}`);
    const rates = res?.data?.rates?.filter((rate) => rate.function_id === 1);
    setDefaultRates(rates);
    const response = await axios.get(`/timecost/${profile.id}`);
    console.log(response, "res");
    const activityDataForCS = res.data.activities.filter(
      (activity) => activity.function_id === 1
    );
    const bandsDataForCS = res.data.bands.filter(
      (activity) => activity.function_id === 1
    );
    const activityDataForOPS = res.data.activities.filter(
      (activity) => activity.function_id === 2
    );
    const bandsDataForOPS = res.data.bands.filter(
      (activity) => activity.function_id === 2
    );

    let temparr = [];
    let csarr = res.data.Mapping.filter(
      (activity) => activity.funtction_id === 1
    );
    temparr.push(csarr);
    let opsarr = res.data.Mapping.filter(
      (activity) => activity.funtction_id === 2
    );
    temparr.push(opsarr);
    setActivityMapping(temparr);
    setBand(bandsDataForCS);
    setActivitiesForCS(activityDataForCS);
    let commercial = {};
    let operations = {};
    let commercialCost = {};
    let countryArray = [];

    response.data.datas.map((co) => {
      console.log(co.OptionId);
      let id = co.OptionId;
      commercial[id] = [];
      operations[id] = [];
      commercialCost[id] = [];

      co.CSSchema && co.CSSchema?.length > 0 && co.CSSchema.map((schema) => { 
        let cstable = [];
        let costTable = [];
        for (
          let activity = 0;
          activity < activityDataForCS.length;
          activity++
        ) {
          let temparr = [];
          let activityName = activityDataForCS[activity].activity_name;
          if (!(activityName in schema.Rateselection)) {
            temparr = Array(bandsDataForCS.length).fill("");
          } else {
            let activityarr = schema.Rateselection[activityName];

            for (let bandCS = 0; bandCS < bandsDataForCS.length; bandCS++) {
              let bandname = bandsDataForCS[bandCS].band_name;
              let flag = true;
              activityarr.forEach(function (obj) {
                if (bandname in obj) {
                  temparr.push(obj[bandname]);
                  flag = false;
                }
              });
              if (flag) {
                temparr.push("");
              }
            }
          }
          cstable.push(temparr);
        }
        let totalCost = 0;
        for (let bandCS = 0; bandCS < bandsDataForCS.length; bandCS++) {  
          let bandname = bandsDataForCS[bandCS].band_name;
          totalCost += Number(schema.Rateselection[bandname]);           
          costTable.push({[bandname]: schema.Rateselection[bandname]});
        }
        if(costTable && costTable?.length > 0){
          costTable.push({["totalCost"]:(Math.round(totalCost * 100) / 100).toFixed(2)});
        }
        let countryName = schema.Rateselection.dropdownCountry.label;
        countryArray.push({[id]:countryName});
        let obj = {};
        obj[countryName] = cstable;
        let costObj = {};
        costObj[countryName] = costTable;
        commercial[id].push(obj);
        commercialCost[id].push(costObj);
      });
    });
    if(countryArray && countryArray?.length > 0){
      await teamData(countryArray,()=>{
        const tableHours = [];
        const tableCosts = [];
    
        for (let i = 0; i < activityDataForCS.length; i++) {
          tableHours.push(Array(bandsDataForCS.length).fill(""));      
        }
    
        for (let i = 0; i < bandsDataForCS.length; i++) {
          tableCosts.push(Array(bandsDataForCS.length).fill(""));
        }
    
        profile.CountrySpecs.map((country, index) => {
          const newCountry = profile.CountrySpecs[index]?.CountryName
          const newRow = {
            [newCountry]: tableHours,
          };
      
          const newCostRow = {
            [newCountry]: tableCosts,
          };
      
          const newTable = [newRow];
          const newCost = [newCostRow];
          country.MethodologySpecs.map((method) => {
            method.CostingOptions.map((co,index) => {
              if(!(co.id in commercial) || (commercial[co.id] && !commercial[co.id].length)) {
                commercial[co.id] = newTable;
              }
              if(!(co.id in commercialCost) || (commercialCost[co.id] && !commercialCost[co.id].length)) {
                commercialCost[co.id] = newCost;
              }
            });
          });
        });
    
        dispatch(setCommercialHours(commercial));
        dispatch(setCommercialCost(commercialCost));
        setIsLoading(false);  
      });
    }else{
      let newArray = [];
      let id=profile.CountrySpecs[activeTabFC].MethodologySpecs[
        activeTabSM
      ]?.CostingOptions[activeTabCO]?.id;
      let name = profile.CountrySpecs[activeTabFC].CountryName;
      newArray.push({ [id]: name });
      await teamData(newArray,()=>{
        const tableHours = [];
        const tableCosts = [];
    
        for (let i = 0; i < activityDataForCS.length; i++) {
          tableHours.push(Array(bandsDataForCS.length).fill(""));      
        }
    
        for (let i = 0; i < bandsDataForCS.length; i++) {
          tableCosts.push(Array(bandsDataForCS.length).fill(""));
        }
    
        profile.CountrySpecs.map((country, index) => {
          const newCountry = profile.CountrySpecs[index]?.CountryName
          const newRow = {
            [newCountry]: tableHours,
          };
      
          const newCostRow = {
            [newCountry]: tableCosts,
          };
      
          const newTable = [newRow];
          const newCost = [newCostRow];
          country.MethodologySpecs.map((method) => {
            method.CostingOptions.map((co,index) => {
              if(!(co.id in commercial) || (commercial[co.id] && !commercial[co.id].length)) {
                commercial[co.id] = newTable;
              }
              if(!(co.id in commercialCost) || (commercialCost[co.id] && !commercialCost[co.id].length)) {
                commercialCost[co.id] = newCost;
              }
            });
          });
        });
    
        dispatch(setCommercialHours(commercial));
        dispatch(setCommercialCost(commercialCost));
        setIsLoading(false);
      });
    }      
 
  };
  
  const setoptionsData = () => {
    let options = {};
    for (let country = 0; country < profile.CountrySpecs.length; country++) {
      let method = [];
      for (
        let methodology = 0;
        methodology < profile.CountrySpecs[country].MethodologySpecs.length;
        methodology++
      ) {
        let cost = [];
        for (
          let costingOption = 0;
          costingOption <
          profile.CountrySpecs[country].MethodologySpecs[methodology]
            .CostingOptions.length;
          costingOption++
        ) {
          let label =
            profile.CountrySpecs[country].MethodologySpecs[methodology]
              .CostingOptions[costingOption].label;
          let ID =
            profile.CountrySpecs[country].MethodologySpecs[methodology]
              .CostingOptions[costingOption].id;
          let productName=profile.CountrySpecs[country].MethodologySpecs[methodology]
          .CostingOptions[costingOption].CostingOptionData.producttype
              if(!(productName.includes("EXPENSE RECHARGE")))
              {
                cost.push({ label: label, value: ID })
                
              }  
        }
        method.push(cost);
      }
      options[country] = method;
    }
    setoptions(options);
  };
  const disabledOptionIds = []
  useEffect(() => {
      let csTimecostReasontemp = {}
      profile.CountrySpecs?.map((country)=>{
        country.MethodologySpecs?.map((method)=>{
          method.CostingOptions?.map((co)=>{
            const {id, CsTimecostReason}=co
            csTimecostReasontemp[id] = CsTimecostReason == null ? {} : {CsTimecostReason} 
          })})})
          setcsTimecostReason(csTimecostReasontemp)
          setcsTimecostReasonSaved(csTimecostReasontemp)
    // }
  }, [])

  useEffect(()=>{  
    console.log(IsTextBoxdisabled)
  },[IsTextBoxdisabled])

  const handleIconClick = (costOptionId) =>{
    //console.log("IsTextBoxdisabled",IsTextBoxdisabled)
  
    disabledOptionIds.map(opt => {
      if (opt.hasOwnProperty(costOptionId)) {
        opt[costOptionId] = true
      }
      return opt
    })
    let temp = []
    temp.push(costOptionId)
    SetIsTextBoxdisabled(temp);
    // forceUpdate();
    // checkIfEnabled(costOptionId) 
  }

  useEffect(() => {
    if (!isLoading && makeScroll) {
      window.scrollTo(0, scrollPosition);
      setMakeScroll(false);
    }
  }, [isLoading, makeScroll]);

  useEffect(() => {
    setoptionsData();
    gettimecost();
    getCurrencyDetails()
  }, []);

  useEffect(()=>{
    let tempObj=costoptionsDetails
    profile.CountrySpecs.map((country)=>{
      country.MethodologySpecs.map((method)=>{
        method.CostingOptions.map((co,index)=>{
         tempObj[co.id]={countryName:country.CountryName,oppID:co.OpportunityLineItemID
         }
        })
      })
    })
    setCostoptionsDetails(tempObj)
    console.log(tempObj,"tempObj")

  },[])
  useEffect(() => {
    axios.get(`/timecost/minimumhour/${profile.id}`).then((res) => {
  console.log("resdata", res.data.CS)
  SetMinCommercialHours(res.data.CS);
  
    });
  }, [])
  const getTotalminHours = (minhours) => {

    console.log("activity, minhours",  minhours);
      let totalminHours = Object.values(minhours).reduce((a,b) => a+b, 0)      
      let threshold = (totalminHours * (profile.ThresholdTimecost))
      totalminHours = totalminHours - threshold 
    return totalminHours;
  };
  const getMinHoursByCostOpt = (costoptid) => {

    let minHours = minCommercialHours.filter(p => Object.keys(p) == costoptid)[0][costoptid] 
    return minHours
  }

  const handleTimecostReasoncs=(e,costOptionId)=>{
    let reasonObj={...CsTimecostReason}
    reasonObj[costOptionId]={CsTimecostReason:e.target.value}
    setcsTimecostReason(reasonObj)
  };
  const getCurrencyDetails=()=>{
    
    if(timecostView==="commercial")
    {
      let countryCode=profile.CSCurrencySelection||project.CommissioningCountry
      let currencyArray=currencies.filter((item)=>item.Code===countryCode)
      setCSCurrencyCode(countryCode)
      setCSCurrencyUnit(currencyArray[0].CurrencyUnit)
      setCSCurrencyConversion(currencyArray[0].ConversionRateToLocal)
      let finalCurrency = {
				value: `${currencyArray[0].Code}-${currencyArray[0].CurrencyUnit}`,
				label: `${currencyArray[0].Label} (1USD = ${currencyArray[0].ConversionRateToLocal}${currencyArray[0].CurrencyUnit})`,
			};
      setCScurrencyDropdown(finalCurrency)
    }
  }

  const toggleSM = (tab) => {
    setActiveTabCO(0);
    const tableData = _.cloneDeep(csCostData);
    let costTableData =
      tableData[
        profile.CountrySpecs[activeTabFC].MethodologySpecs[tab].CostingOptions[0].id
      ];
    let countryMap =  costTableData?.map((m)=>Object.keys(m)[0]);
    
    let dataOption=CSTeamDataOptions;
    let res= dataOption.filter((data)=>
      !countryMap.includes(data.label)
    );
    setCSTeamDataOptionsTemp(res);
    if (activeTabSM !== tab) {
      setActiveTabSM(tab);
    }
    let csSupport =
      profile.CountrySpecs[activeTabFC].MethodologySpecs[tab].CostingOptions[0]
        .CostingOptionData.CommericalLocalsu;
    if (csSupport === "Yes") {
      setLocalSupportCS(true);
    } else {
      setLocalSupportCS(false);
    }
    let modalSupport =
      profile.CountrySpecs[activeTabFC].MethodologySpecs[tab].CostingOptions[0]
        .CostingOptionData?.Commericalsupport;
    if (modalSupport === "Yes") {
      setLocalSupportModalCS(true);
    } else {
      setLocalSupportModalCS(false);
    }
  };

  const toggleTabPane = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  const toggleCO = (tab) => {
    const tableData = _.cloneDeep(csCostData);
    let costTableData =
      tableData[
        profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
        .CostingOptions[tab].id
      ];
    let countryMap =  costTableData?.map((m)=>Object.keys(m)[0]);
    
    let dataOption=CSTeamDataOptions;
    let res= dataOption.filter((data)=>
      !countryMap.includes(data.label)
    );
    setCSTeamDataOptionsTemp(res);
    if (activeTabCO !== tab) {
      setActiveTabCO(tab);
    }
    let csSupport =
      profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
        .CostingOptions[tab].CostingOptionData.CommericalLocalsu;
    if (csSupport === "Yes") {
      setLocalSupportCS(true);
    } else {
      setLocalSupportCS(false);
    }
    let modalSupport =
      profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
        .CostingOptions[tab].CostingOptionData?.Commericalsupport;
    if (modalSupport === "Yes") {
      setLocalSupportModalCS(true);
    } else {
      setLocalSupportModalCS(false);
    }
  };

  const infoModalToggle = () => {
    setInfoModal(false);
  };
  const handleCSCurrencyChange =(e)=>{
    let countryCode=e.value.split("-")[0]
    let currencyArray=currencies.filter((item)=>item.Code===countryCode)
    setCSCurrencyCode(countryCode)
    setCSCurrencyUnit(currencyArray[0].CurrencyUnit)
    setCSCurrencyConversion(currencyArray[0].ConversionRateToLocal)
    let finalCurrency = {
      value: `${currencyArray[0].Code}-${currencyArray[0].CurrencyUnit}`,
      label: `${currencyArray[0].Label} (1USD = ${currencyArray[0].ConversionRateToLocal}${currencyArray[0].CurrencyUnit})`,
    };
    setCScurrencyDropdown(finalCurrency)
  }

  const clearCSTable = (costingOption,index) => {
    setShowModal(true);
    setSelectedCountryForClear({
      costingOption,index
    });
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleOk = () => {
    let csdata = _.cloneDeep(csCostData);
    let costingOptionID=selectedCountryForClear.costingOption
    let index=selectedCountryForClear.index
    const tableHours = [];
    for (var i = 0; i < activitiesForCS.length; i++) {
      tableHours.push(Array(band.length).fill(""));
    }
    let hoursObj=_.cloneDeep(csdata[costingOptionID][index])
    Object.keys(hoursObj).map((key)=>{
      hoursObj[key]=tableHours
    })
    csdata[costingOptionID][index]=hoursObj
    console.log(csdata,"csdata");
    dispatch(setCommercialHours(csdata));
    setShowModal(false);
    setIsClear({
      costingOption:selectedCountryForClear.costingOption,
      index:selectedCountryForClear.index
    })
    toastr.success("Hours cleared successfully")
  };

  const deleteCSTable = (costOptionID, index) => {
    console.log("......", costOptionID, index);
    setSelectedCostOptionID(costOptionID);
    setSelectedIndex(index);
    // let csdata=_.cloneDeep(csCostData)
    // const data =csdata[costOptionID].map((c)=>c)
    //                                 .filter((country,i)=>
    //                                 i!=index
    //                                 )
    // console.log("****",data)
    // csCostData[costOptionID]=data
    // console.log("csCostData",csCostData)
    // setUpdatedCSData(csCostData);
    setShowDeleteModal(true);
  };

  const handleCloseModal = () => {
    setShowDeleteModal(false);
  };

  const handleOKModal = () => {
    let csdata = _.cloneDeep(csCostData);
    let costdata = _.cloneDeep(commercialCostData);
   
    const data = csdata[selectedCostOptionID]
      .map((c) => c)
      .filter((country, i) => i != selectedIndex);

    const cdata = costdata[selectedCostOptionID]
      .map((c) => c)
      .filter((country, i) => i != selectedIndex);

    const deletedData = csdata[selectedCostOptionID]
    .map((c) => c)
    .filter((country, i) => i === selectedIndex);

    const deletedCData = costdata[selectedCostOptionID]
    .map((c) => c)
    .filter((country, i) => i === selectedIndex);
    let teamData = CSTeamDataOptions?.map((m)=>m.label);

    //csdata
    let clonedDeletedData = _.cloneDeep(deletedTimeCostData);
    if(teamData.includes(Object.keys(deletedData[0])[0])){
    if (clonedDeletedData && Object.keys(clonedDeletedData).length) {
      if (clonedDeletedData?.[selectedCostOptionID] === undefined) {
        let newObj = { [selectedCostOptionID]: deletedData };
        Object.assign(clonedDeletedData, newObj);
      }else{
        let newArray = clonedDeletedData[selectedCostOptionID];
        newArray.push(deletedData[0]);
        clonedDeletedData[selectedCostOptionID] = newArray;
      }
    }
    else {
      clonedDeletedData = { [selectedCostOptionID]: deletedData };
    }
  }

    //cost data
    let clonedCostData = _.cloneDeep(deletedCostData);
    if(teamData.includes(Object.keys(deletedCData[0])[0])){
    if (clonedCostData && Object.keys(clonedCostData).length) {
      if (clonedCostData?.[selectedCostOptionID] === undefined) {
        let newObj = { [selectedCostOptionID]: deletedCData };
        Object.assign(clonedCostData, newObj);
      }else{
        let newArray = clonedCostData[selectedCostOptionID];
        newArray.push(deletedCData[0]);
        clonedCostData[selectedCostOptionID] = newArray;
      }
    }
    else {
      clonedCostData = { [selectedCostOptionID]: deletedCData };
    }
  }
    if (clonedDeletedData && Object.keys(clonedDeletedData)?.length) {
      setDeletedTimeCostData(clonedDeletedData);
    }
    if (clonedCostData && Object.keys(clonedCostData)?.length) {
      setDeletedCostData(clonedCostData);
    }  
    csdata[selectedCostOptionID] = data;
    costdata[selectedCostOptionID] = cdata;

    //excluding the already selected country from the list
    let costTableData = csdata[selectedCostOptionID];
    let countryMap =  costTableData?.map((m)=>Object.keys(m)[0]);
    
    let dataOption=CSTeamDataOptions;
    let res= dataOption.filter((data)=>
      !countryMap.includes(data.label)
    );
    setCSTeamDataOptionsTemp(res);
    dispatch(setCommercialHours(csdata));
    dispatch(setCommercialCost(costdata));
    setShowDeleteModal(false);
  };

  function populateSideBarLinks(variables) {
    if (variables) {
      dispatch(
        sideBarActions.addCostingOptionsLink(
          "#costing_options_card_" + activeTabFC + "_" + activeTabSM
        )
      );
      dispatch(
        sideBarActions.addSudOptionsLink(
          "#sud_options_card_" + activeTabFC + "_" + activeTabSM
        )
      );

      if (profile.CountrySpecs.length > 1)
        dispatch(
          sideBarActions.resOptionsLink(
            "#res_options_card_" + activeTabFC + "_" + activeTabSM
          )
        );
      else dispatch(sideBarActions.resOptionsLink(""));

      let links = [];
      variables.forEach((x, index) => {
        if (x.isNewSection)
          links.push({ label: x.sectionTitle, value: "#header_" + index });
      });
      dispatch(sideBarActions.addSidebarLinks(links));
    }
  }

  function toggle(tab) {
    setActiveTabSM(0);
    setActiveTabCO(0);
    const tableData = _.cloneDeep(csCostData);
    let costTableData =
      tableData[
        profile.CountrySpecs[tab].MethodologySpecs[0].CostingOptions[0].id
      ];
    let countryMap =  costTableData?.map((m)=>Object.keys(m)[0]);
    
    let dataOption=CSTeamDataOptions;
    let res= dataOption.filter((data)=>
      !countryMap.includes(data.label)
    );
    setCSTeamDataOptionsTemp(res);    
    if (activeTabFC !== tab) {
      setActiveTabFC(tab);
      let allMeth = profile.CountrySpecs[tab].MethodologySpecs.map(
        (m, mIndex) => ({
          methodology: m,
          index: mIndex,
        })
      );
      let reqMeth = allMeth.find((x) => !x.methodology.NotApplicable);
      if (!reqMeth) reqMeth = _.head(allMeth);
    }

    if (
      !(
        "CostingOptionData" in
        profile.CountrySpecs[tab].MethodologySpecs[0].CostingOptions[0]
      )
    ) {
      populateSideBarLinks(
        profile.CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]
          ?.Variables
      );
    }
    let csSupport =
      profile.CountrySpecs[tab].MethodologySpecs[0].CostingOptions[0]
        .CostingOptionData.CommericalLocalsu;
    if (csSupport === "Yes") {
      setLocalSupportCS(true);
    } else {
      setLocalSupportCS(false);
    }
    let modalSupport =
      profile.CountrySpecs[tab].MethodologySpecs[0].CostingOptions[0]
        .CostingOptionData?.Commericalsupport;
    if (modalSupport === "Yes") {
      setLocalSupportModalCS(true);
    } else {
      setLocalSupportModalCS(false);
    }
  }

  const updateModal = () => {
    settimecostViewModal(!timecostViewModal);
  };

  const getWBSnumber = () => {
    let oppID = profile?.CountrySpecs ? 
      profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
        .CostingOptions[activeTabCO].OpportunityLineItemID : "";
    let ProductTypeData = profile?.ProductTypeData !== undefined ? profile?.ProductTypeData : [];
    let filterarray = Array.isArray(ProductTypeData) ? ProductTypeData?.filter(
      (item) => item.OpportunityLineItemID == oppID
    ) : [];
    if (filterarray) {
      return filterarray[0]?.WBSNumber;
    }
    return null;
  };

  const generateRandomKey = () => {
    const randomKey = Math.random().toString(36).substring(2, 10);
    return randomKey;
  };

  const addNewCostingRow = () => {
    const costingOptionID =
      profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
        ?.CostingOptions[activeTabCO]?.id;
    const tableHours = [];
    for (var i = 0; i < activitiesForCS.length; i++) {
      tableHours.push(Array(band.length).fill(""));
    }
    const newRow = {
      [generateRandomKey()]: tableHours,
    };

    const tableCosts = [];
    for (var i = 0; i < band.length; i++) {
      tableCosts.push(Array(band.length).fill(""));
    }
    const newCostRow = {
      [generateRandomKey()]: tableCosts,
    };
    const tableData = _.cloneDeep(csCostData);
    const tableCostData = _.cloneDeep(commercialCostData);
    //for hours
    if (tableData && Object.keys(tableData).length && tableData[costingOptionID]) {
      tableData[costingOptionID].push(newRow);
    } else {
      const optionIdAssignment = {
        [costingOptionID]: [newRow],
      };
      Object.assign(tableData, optionIdAssignment);
    }
    //for cost
    if (tableCostData && Object.keys(tableCostData).length && tableCostData[costingOptionID]) {
      tableCostData[costingOptionID].push(newCostRow);
    } else {
      const optionIdAssignment = {
        [costingOptionID]: [newCostRow],
      };
      Object.assign(tableCostData, optionIdAssignment);
    }
    dispatch(setCommercialHours(tableData));
    dispatch(setCommercialCost(tableCostData));
    toastr.success("New Costing Market Added");
  };

  const handleSelectChange = (e, index) => {
    const tableData = _.cloneDeep(csCostData);
    const tableCostData = _.cloneDeep(commercialCostData);

    let coData =
      tableData[
        profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
          .CostingOptions[activeTabCO].id
      ];
    let costData = tableCostData[
      profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
        .CostingOptions[activeTabCO].id
    ];
    let obj = {};
    let costObj = {};
    obj[e.label] = Object.values(coData[index])[0];
    coData[index] = obj;
    costObj[e.label] = Object.values(costData[index])[0];
    costData[index] = costObj;

    //excluding the already selected country from the list

    let costTableData =
      tableData[
        profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
          .CostingOptions[activeTabCO].id
      ];
    let countryMap =  costTableData?.map((m)=>Object.keys(m)[0]);
    
    let dataOption=CSTeamDataOptions;
    let res= dataOption.filter((data)=>
      !countryMap.includes(data.label)
    );
    setCSTeamDataOptionsTemp(res);
    dispatch(setCommercialHours(tableData));
    dispatch(setCommercialCost(tableCostData));
    console.log(tableData, "tableData");
  };

  const copyMethodologiesData = () => {
    var convertedMethodologies = [];

    if (profile.CountrySpecs[activeTabFC].MethodologySpecs[0].label) {
      convertedMethodologies = profile.CountrySpecs[
        activeTabFC
      ].MethodologySpecs.map((fc) => ({ label: fc.label, value: fc.label }));
    } else {
      convertedMethodologies = profile.CountrySpecs[
        activeTabFC
      ].MethodologySpecs.map((fc) => ({ label: fc.Label, value: fc.Label }));
    }
    setSelectedMethodologyData1(convertedMethodologies);
    setModalMethodology(true);
  };

  const copyCountriesData = () => {
    var convertedCountries = [];

    if (profile.CountrySpecs[0].label) {
      convertedCountries = profile.CountrySpecs.map((fc) => ({
        label: fc.label,
        value: fc.label,
      }));
    } else {
      convertedCountries = profile.CountrySpecs.map((fc) => ({
        label: fc.CountryName,
        value: fc.CountryName,
      }));
    }

    setSelectedCountryData(convertedCountries);
    copyMethodologiesData();

    setModal(true);
  };
  const getCSOPSDetails=(coID)=>{
    let result=[];
    profile.CountrySpecs.map((country)=>{
      country.MethodologySpecs.map((method)=>{
        method.CostingOptions.map((co,index)=>{
          if(coID==co.id)
          {
            result.push(co.CostingOptionData.CommericalLocalsu,co.CostingOptionData.operationalpm,co.CostingOptionData.Commericalsupport,co.CostingOptionData.operationpm,method.Code,country.CountryCode)
          }
        })
      })
    })
    return result
  };

  const copyHoursWBSlevel=async()=>{
    const response =await axios.get(`/timecost/${profile.id}`)
    let costingOptionDetailsObj=costoptionsDetails
    let hoursPayload={
      records: [],
    };
    let totalCostPayload = {
      records: [],
    };
    for (const costingOptionID in csCostData) {
      const [csSupport,opsSupport,csModal,opsModal]=getCSOPSDetails(costingOptionID)
      
        if(csSupport==="No")
        {
          continue
        }
        if(csModal==="No")
        {
          continue
        }
      
     
      let oppID=costingOptionDetailsObj[costingOptionID].oppID
      let countryName=costingOptionDetailsObj[costingOptionID].countryName
      let costArray=commercialCostData[costingOptionID]
      if(levelInput[oppID]==="wbslevel")
      {
        let tableArray=csCostData[costingOptionID]

        let csSchema=[]
        tableArray.map((country,index)=>{
          let countryName;
          let hours;
          for (const [key, value] of Object.entries(country)) {
            countryName=key
            hours=value
          }
          let csCostArray=costArray[index]
          let bandCostArray=csCostArray[countryName]
          const isCountry = CSTeamDataOptions?.some((obj) => obj.label === countryName);

          if(bandCostArray === undefined && !isCountry) {
            return null;
          }
  
          let csData = {};
          band.map((bandValue, index) => {
            let bandObj=bandCostArray[index]
            csData[bandValue.band_name] = bandObj[bandValue.band_name];
          });
        let csTable=hours
        for(let activity=0;activity<activitiesForCS.length;activity++)
        {
          let temparr=[]
          let activityName=activitiesForCS[activity].activity_name
          
          for(let bandCS=0;bandCS<band.length;bandCS++)
          {
            let bandname=band[bandCS].band_name
            let tempObj={}
            tempObj[bandname]=csTable[activity][bandCS]
            temparr.push(tempObj)
          }
          csData[activityName]=temparr
        }
        csData["dropdownCountry"]={label:countryName}
        csSchema.push({
          Rateselection: csData,
        })
        })
          profile.CountrySpecs.map((country)=>{
          country.MethodologySpecs.map((method)=>{
            method.CostingOptions.map((co,index)=>{
              if(co.OpportunityLineItemID==oppID && country.CountryName==countryName && co.id!=costingOptionID&&co.CostingOptionData.CommericalLocalsu==="Yes")
              {
                let copyCSschema=_.cloneDeep(csSchema)
                let costingOptionPayload={
                  ProfileId: profile.id,
                }
                costingOptionPayload["CountryId"]=country.CountryCode
                costingOptionPayload["MethodologyCode"]=method.Code
                costingOptionPayload["OptionId"]=co.id

                response.data.datas.map((res)=>{
                  if(res.OptionId==co.id)
                  {
                    copyCSschema.map((schemaItem,index)=>{
                      if(res.CSSchema.length>index)
                      {
                        let schema=res.CSSchema[index]
                        if("CostId" in schema)
                        {
                          schemaItem["CostId"]=schema.CostId
                        }
                      }
                    })
                    
                  }
                });
                costingOptionPayload["CSSchema"]=copyCSschema


                response.data.datas.map((res)=>{
                  if(res.OptionId==co.id)
                  {
                    costingOptionPayload["id"]=res.id
                  }
                })
                hoursPayload.records.push(costingOptionPayload)
              }
              })
            })
          })

          const totalCommercialCost = commercialCostData[costingOptionID]?.map((csCost) => {
            return Object.values(csCost)[0];
          });
    
          const finalCsCost = totalCommercialCost.reduce((outerAcc, innerArray) => {
            const innerSum = innerArray.reduce((innerArray, obj) => {
              const totalCostValue = parseFloat(obj.totalCost);
              if(!isNaN(totalCostValue)) {
                innerArray += totalCostValue;
              }
              return innerArray;
            }, 0);
            return outerAcc + innerSum;
          }, 0);
          profile.CountrySpecs.map((country)=>{
            country.MethodologySpecs.map((method)=>{
              method.CostingOptions.map((co,index)=>{
                if(co.OpportunityLineItemID==oppID && country.CountryName==countryName && co.id!=costingOptionID&&co.CostingOptionData.CommericalLocalsu==="Yes")
                {
                  let payload;
                  if(co.CostingOptionData.Booster==="No")
                  {
                    payload = {
                      id: co.id,
                      TotalCSTimeCost: finalCsCost
                    };
                    totalCostPayload.records.push(payload);
                  }
                  else
                  {
                    payload = {
                      id: co.id,
                      TotalCSTimeCost: finalCsCost*0.05
                    };
                    totalCostPayload.records.push(payload);
                  }
                }
              })
            })
          })
    
          
    
    
        
        
                      
      }
    }
    let resultpayload=[hoursPayload,totalCostPayload]
    console.log("copyhoursPayload",hoursPayload);
    return resultpayload

  }
  //*********************************************************** */
  const deleteHoursWBSlevel=async(response)=>{
    let costingOptionDetailsObj=costoptionsDetails
    let hoursPayload=[];
    for (const costingOptionID in deletedTimeCostData) {
      const [csSupport,opsSupport,csModal,opsModal]=getCSOPSDetails(costingOptionID)
      
        if(csSupport==="No")
        {
          continue
        }
        if(csModal==="No")
        {
          continue
        }
      
     
      let oppID=costingOptionDetailsObj[costingOptionID].oppID
      let countryName=costingOptionDetailsObj[costingOptionID].countryName
      let costArray=deletedCostData[costingOptionID]
      if(levelInput[oppID]==="wbslevel")
      {
        let tableArray=deletedTimeCostData[costingOptionID]

        let csSchema=[]
        tableArray.map((country,index)=>{
          let countryName;
          let hours;
          for (const [key, value] of Object.entries(country)) {
            countryName=key
            hours=value
          }
          let csCostArray=costArray[index]
          let bandCostArray=csCostArray[countryName]
          const isCountry = CSTeamDataOptions?.some((obj) => obj.label === countryName);

          if(bandCostArray === undefined && !isCountry) {
            return null;
          }
  
          let csData = {};
          band.map((bandValue, index) => {
            let bandObj=bandCostArray[index]
            csData[bandValue.band_name] = bandObj[bandValue.band_name];
          });
        let csTable=hours
        for(let activity=0;activity<activitiesForCS.length;activity++)
        {
          let temparr=[]
          let activityName=activitiesForCS[activity].activity_name
          
          for(let bandCS=0;bandCS<band.length;bandCS++)
          {
            let bandname=band[bandCS].band_name
            let tempObj={}
            tempObj[bandname]=csTable[activity][bandCS]
            temparr.push(tempObj)
          }
          csData[activityName]=temparr
        }
        csData["dropdownCountry"]={label:countryName}
        csSchema.push({
          Rateselection: csData,
          Isdeleted: true,
        })
        })
          profile.CountrySpecs.map((country)=>{
          country.MethodologySpecs.map((method)=>{
            method.CostingOptions.map((co,index)=>{
              if(co.OpportunityLineItemID==oppID && country.CountryName==countryName && co.id!=costingOptionID&&co.CostingOptionData.CommericalLocalsu==="Yes")
              {
                let copyCSschema=_.cloneDeep(csSchema)
                let costingOptionPayload={
                  ProfileId: profile.id,
                }
                costingOptionPayload["CountryId"]=country.CountryCode
                costingOptionPayload["MethodologyCode"]=method.Code
                costingOptionPayload["OptionId"]=co.id

                response.data.datas.map((res)=>{
                  if(res.OptionId==co.id)
                  {
                    copyCSschema.map((schemaItem,index)=>{
                      if(res.CSSchema.length>index)
                      {
                       res.CSSchema?.map((r)=>{
                        if (r.Rateselection.dropdownCountry.label == schemaItem.Rateselection.dropdownCountry.label) {
                          if ("CostId" in r) {
                            schemaItem["CostId"] = r.CostId
                          }
                        }
                       })
                      }
                    })
                    
                  }
                });
                costingOptionPayload["CSSchema"]=copyCSschema


                response.data.datas.map((res)=>{
                  if(res.OptionId==co.id)
                  {
                    costingOptionPayload["id"]=res.id
                  }
                })
                hoursPayload.push(costingOptionPayload)
              }
              })
            })
          })               
      }
    }
    return hoursPayload;
  }
  //************************************************************ */
  const deleteTimeCostDatas = async (response) => {
    console.log("?????", deletedTimeCostData);
    let records=await deleteHoursWBSlevel(response);
    for(let costingOptionID in deletedTimeCostData)
    {
      const [csSupport,opsSupport,csModal,opsModal,methodCode,countryCode]=getCSOPSDetails(costingOptionID)
      if(csSupport==="No")
      {
        continue;
      }
      if(csModal==="No")
      {
        continue
      }
      let tableArray=deletedTimeCostData[costingOptionID]
      let costArray=deletedCostData[costingOptionID]
      let costingOptionPayload={
        ProfileId: profile.id,
  
        CountryId: countryCode,

        MethodologyCode:methodCode,

        OptionId: costingOptionID,
        CSSchema:[]
      }

      tableArray.map((country,index)=>{
        let countryName;
        let hours;
        for (const [key, value] of Object.entries(country)) {
          countryName=key
          hours=value
        }
        let csCostArray=costArray[index]
        let bandCostArray=csCostArray[countryName]

        let csData = {};
        band.map((bandValue, index) => {
          let bandObj=bandCostArray[index]
          csData[bandValue.band_name] = bandObj[bandValue.band_name];
        });
        let csTable=hours
        for(let activity=0;activity<activitiesForCS.length;activity++)
        {
          let temparr=[]
          let activityName=activitiesForCS[activity].activity_name
          
          for(let bandCS=0;bandCS<band.length;bandCS++)
          {
            let bandname=band[bandCS].band_name
            let tempObj={}
            tempObj[bandname]=csTable[activity][bandCS]
            temparr.push(tempObj)
          }
          csData[activityName]=temparr
        }
        csData["dropdownCountry"]={label:countryName}
        let finalObj={
          Rateselection: csData,
          Isdeleted: true,
        }
        response.data.datas.map((res)=>{
          if(res.OptionId==costingOptionID)
          {
            res.CSSchema.map((r)=>{
              if (r.Rateselection.dropdownCountry.label == countryName) {
                if ("CostId" in r) {
                  finalObj["CostId"] = r.CostId
                }
              } 
            })
          }
        });
        costingOptionPayload.CSSchema.push(finalObj);
    })
      response.data.datas.map((res)=>{
        if(res.OptionId==costingOptionID)
        {
          costingOptionPayload["id"]=res.id
        }
      })

      records.push(costingOptionPayload)
    }
    console.log("+++",records)
    await axios.put(`/timecost/Trash/${profile.id}`, records).then(
      res => {
        setDeletedTimeCostData({});
        setDeletedCostData({});
      }
    ).catch(err => {
      console.log(err.message);
    })
  }
  const getInternalCost = () => {
    let validationData = {}
    profile.CountrySpecs.map((country)=>{
      country.MethodologySpecs.map((method)=>{
        const {Label}=method
        method.CostingOptions.map((co)=>{
          const {id, OpportunityLineItemID, label}=co
          validationData[id] = {costOptionId:id, OpportunityLineItemID, Label, costingoptionlabel:label}
        })})})
    let productTypeArray=_.cloneDeep(profile.ProductTypeData)
 
    productTypeArray=productTypeArray.filter((item)=>!item.isRed)
 
 
    productTypeArray.map((item)=>{
        const {OpportunityLineItemID,Productname,WBSNumber}=item
        Object.entries(validationData).forEach(([costOptionID, value]) => {
          if (value.OpportunityLineItemID == OpportunityLineItemID) {
            validationData[costOptionID]={...value,WBSNumber,Productname,csCost:0,csCost:0,Predefinedcs:0,PredefinedCS:0}
          }})
    })
 
    let productArray=Object.values(validationData)
    profile.CountrySpecs.map((country)=>{
      country.MethodologySpecs.map((method)=>{
        method.CostingOptions.map((co)=>{
        // console.log(productArray.findIndex(obj=>obj.costOptionId === co.id))
          let index=productArray.findIndex(obj=>obj.costOptionId === co.id)
          if(index!=-1)
          {
            productArray[index].PredefinedCS += co.PredefinedHourCS * (1-profile.ThresholdTimecost)
            productArray[index].Predefinedcs += co.PredefinedHourOP * (1-profile.ThresholdTimecost)
          }
         
        })
      })
    })

    
    productArray.map((prod)=>{
      if(((getTotalcsCost(prod.costOptionId) >= prod.Predefinedcs && (getTotalcsCost(prod.costOptionId) !== 0)) || (csTimecostReasonSaved[prod.costOptionId]?.CsTimecostReason)) )
        if(prod.costOptionId){
        var obj = {[prod.costOptionId]: false}
        disabledOptionIds.push(obj)
        }
    })
    
    console.log("disabledOptionIdssss", disabledOptionIds, productArray);
    return productArray;
  }
  const saveTableData = async() => {
    setIsLoading(true);
    setAlert(false);
    let payload=await copyHoursWBSlevel()
    console.log("commercialCostData",commercialCostData);
    const response = await axios.get(`/timecost/${profile.id}`);
    let hoursPayload=payload[0]

    let totalCostPayload = payload[1]

    if(deletedTimeCostData && Object.keys(deletedTimeCostData)?.length && response?.data?.datas?.length >0){
      deleteTimeCostDatas(response);  
    }
    


    for(let costingOptionID in csCostData)
    {
      const [csSupport,opsSupport,csModal,opsModal,methodCode,countryCode]=getCSOPSDetails(costingOptionID)
      if(csSupport==="No")
      {
        continue;
      }
      if(csModal==="No")
      {
        continue
      }
      let tableArray=csCostData[costingOptionID]
      let costArray=commercialCostData[costingOptionID]
      let costingOptionPayload={
        ProfileId: profile.id,
  
        CountryId: countryCode,

        MethodologyCode:methodCode,

        OptionId: costingOptionID,
        CSSchema:[]
      }

      tableArray.map((country,index)=>{
        let countryName;
        let hours;
        for (const [key, value] of Object.entries(country)) {
          countryName=key
          hours=value
        }
        let csCostArray=costArray[index]
        let bandCostArray=csCostArray[countryName]

        const isCountry = CSTeamDataOptions?.some((obj) => obj.label === countryName);

        if(bandCostArray === undefined && !isCountry) {
          for(let country=0;country<profile.CountrySpecs.length;country++){
            for(let methodology=0;methodology<profile.CountrySpecs[country].MethodologySpecs.length;methodology++){
              for(let costingOption=0;costingOption<profile.CountrySpecs[country].MethodologySpecs[methodology].CostingOptions.length;costingOption++){
                if(profile.CountrySpecs[country].MethodologySpecs[methodology].CostingOptions[costingOption].id == costingOptionID) {
                  toastr.warning(`Please select a country for ${profile.CountrySpecs[country].CountryName} > ${profile.CountrySpecs[country].MethodologySpecs[methodology].label} > ${profile.CountrySpecs[country].MethodologySpecs[methodology].CostingOptions[costingOption].Name} > Team ${index+1}`, '', {timeOut: 5000});
                }
              }
            }
          }          
          return null;
        }

        let csData = {};
        band.map((bandValue, index) => {
          let bandObj=bandCostArray[index]
          csData[bandValue.band_name] = bandObj[bandValue.band_name];
        });
        let csTable=hours
        for(let activity=0;activity<activitiesForCS.length;activity++)
        {
          let temparr=[]
          let activityName=activitiesForCS[activity].activity_name
          
          for(let bandCS=0;bandCS<band.length;bandCS++)
          {
            let bandname=band[bandCS].band_name
            let tempObj={}
            tempObj[bandname]=csTable[activity][bandCS]
            temparr.push(tempObj)
          }
          csData[activityName]=temparr
        }
        csData["dropdownCountry"]={label:countryName}
        let finalObj={
          Rateselection: csData,
        }
        response.data.datas.map((res)=>{
          if(res.OptionId==costingOptionID)
          {
            if(res.CSSchema.length>index)
            {
              let schema=res.CSSchema[index]
              if("CostId" in schema)
              {
                finalObj["CostId"]=schema.CostId
              }
            }
          }
        });
        costingOptionPayload.CSSchema.push(finalObj)
      })

      response.data.datas.map((res)=>{
        if(res.OptionId==costingOptionID)
        {
          costingOptionPayload["id"]=res.id
        }
      });

      const totalCommercialCost = commercialCostData[costingOptionID]?.map((csCost) => {
        return Object.values(csCost)[0];
      });

      const finalCsCost = totalCommercialCost.reduce((outerAcc, innerArray) => {
        const innerSum = innerArray.reduce((innerArray, obj) => {
          const totalCostValue = parseFloat(obj.totalCost);
          if(!isNaN(totalCostValue)) {
            innerArray += totalCostValue;
          }
          return innerArray;
        }, 0);
        return outerAcc + innerSum;
      }, 0);


      let payload = {
        id: costingOptionID,
        TotalCSTimeCost: finalCsCost,
        CSCurrencySelection:CSCurrencyCode
      };

      hoursPayload.records.push(costingOptionPayload);
      totalCostPayload.records.push(payload);
    }    
    console.log("hoursPayload", hoursPayload);
    console.log(totalCostPayload, "totalcostpayload");
    if(hoursPayload?.records[0]?.CSSchema.length > 0) {
      // if(totalCostPayload.records.length > 0) {
      //   dispatch(saveTotalCost(totalCostPayload, profile.id));
      // }
      // if(hoursPayload.records.length > 0)
      // {
      //   dispatch(saveHourspayload(hoursPayload, profile.id));
      // }

      let tempProfile=_.cloneDeep(profile)
      tempProfile["CSCurrencySelection"]=CSCurrencyCode
      Object.keys(CsTimecostReason).map(ops => {
        tempProfile.CountrySpecs.map((country)=>{
          country.MethodologySpecs.map((method)=>{
            method.CostingOptions.map((co)=>{
              if (co.id == ops) {
                co.CsTimecostReason = CsTimecostReason[ops].CsTimecostReason
              }
            })})})
      })
      setcsTimecostReasonSaved(CsTimecostReason)
      tempProfile["CSCurrencySelection"]=CSCurrencyCode
      dispatch(currentCostingActions.updateProfile(tempProfile));
      dispatch(saveTimecost(hoursPayload,totalCostPayload,profile.id))
      dispatch(currentCostingActions.saveCostingProfileDraft(tempProfile, () => {
        toastr.success("Timecost Reason saved succesfully")
      },true));
      setIsLoading(false);
    } else {
      toastr.warning("Data not saved");
      setIsLoading(false);
    }
    SetIsTextBoxdisabled([])
  };

  const getTotalBandHours = (bandIndex, data) => {
    let totalHours = 0;
    let hours = Object.values(data)[0];
    for (let i = 0; i < activitiesForCS.length; i++) {
      if (hours[i][bandIndex] !== "") totalHours += hours[i][bandIndex];
    }
    return totalHours;
  };


  const getTotalHours = (id, i, data) => {
    let bandRates = [];    
    let totalHours = getTotalBandHours(i, data);
    if (isNaN(totalHours)) {
      return 0;
    }
    const country = CSTeamDataOptions?.filter((csTeam =>csTeam.label === Object.keys(data)[0] ));
    if(country && country?.length > 0){
      bandRates = defaultRates?.filter((rate) => rate.country_id === country[0]?.value);
    }
    let rate = bandRates?.filter((r)=>r.band_id === id);
    let finalRate = 0;
    if(rate && rate?.length > 0){
    finalRate = Number(
    Math.round(totalHours)
    ).toFixed(2);
    }
    return finalRate; 
  };



  const totalHours = (data) => {    
    let totalCost = 0;
    band.forEach((b,index)=>{
      let tempCsValue = getTotalHours(b.band_id, index, data);
      if (!isNaN(tempCsValue)) {
        totalCost += Number(tempCsValue);
      }
    })
    return (Math.round(totalCost));
  };

  const getTotalcsCost = (costingOptionId) => {
    let totalSum = 0;
    
    const totalcsCost = csCostData[costingOptionId]?.map(data => totalCost(data))
                          ?.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0);
    totalSum += totalcsCost;
    return totalSum;
  };
  const getTotalcshours = (costingOptionId) => {
    let totalSum = 0;
    console.log("csCostData[costingOptionId]", csCostData[costingOptionId])
    console.log("csCostData[costingOptionId]?.map(data => totalCost(data))", csCostData[costingOptionId]?.map(data => totalHours(data)))
    const totalcsCost = csCostData[costingOptionId]?.map(data => totalHours(data))
                          ?.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0);
    console.log("totalcsCost", totalcsCost)                     
    return totalcsCost;
  };

  const getTotalCost = (id, i, data) => {
    let bandRates = [];    
    let totalHours = getTotalBandHours(i, data);
    if (isNaN(totalHours)) {
      return 0;
    }
    const country = CSTeamDataOptions?.filter((csTeam =>csTeam.label === Object.keys(data)[0] ));
    if(country && country?.length > 0){
      bandRates = defaultRates?.filter((rate) => rate.country_id === country[0]?.value);
    }
    let rate = bandRates?.filter((r)=>r.band_id === id);
    let finalRate = 0;
    if(rate && rate?.length > 0){
    finalRate = Number(
    Math.round(totalHours * rate[0].rate * 100) / 100
    ).toFixed(2);
    }
    return finalRate; 
  };

  const totalCost = (data) => {    
    let totalCost = 0;
    band.forEach((b,index)=>{
      let tempCsValue = getTotalCost(b.band_id, index, data);
      if (!isNaN(tempCsValue)) {
        totalCost += Number(tempCsValue);
      }
    })
    return (Math.round(totalCost * 100) / 100).toFixed(2);
  };

  const totalCsCostArray =  profile?.CountrySpecs !== undefined ? csCostData[
    profile?.CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]
      ?.CostingOptions[activeTabCO]?.id
  ]?.map(data => totalCost(data)) : [];
  
  const totalCsCost = totalCsCostArray?.length > 0 ? totalCsCostArray?.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0) : 0;

  const getProductName=()=>{
    return profile?.CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]?.CostingOptions[activeTabCO]?.CostingOptionData.producttype
  }

  return (
    <Wrapper>
      <Sidebar />
      <Main>
        <ProjectNavbar />
        {isLoading || !pageloaded ? (
          <div id="pageCoverSpin"></div>
        ) : (
          <div className={"tab mt-8"}>
            <div
              style={{
                border: "1px solid grey",
                width: "470px",
                fontSize: "12px",
                float: "right",
                marginTop: "5px",
              }}
            >
              <p style={{ marginBottom: "0px" }}>
                Please refer to the Time Cost Planning Dashboard link below as a
                reference on how much hours to be budgeted.
              </p>
              <p>
                <a
                  href="https://bit.ly/NIQ-Time-Cost-Dashboard"
                  style={{ color: "blue" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  https://bit.ly/NIQ-Time-Cost-Dashboard
                </a>
              </p>
              <p style={{ marginBottom: "0px" }}>
                Access to be granted by Capacity Management Team (CMT).Please
                send an email request to
                m2m_capacity_management@nielsenenterprise.onmicrosoft.com. And
                indicate the user's email address (also include other team
                members if any) and market/s that want access to.
              </p>
            </div>
            <Row style={{ position: "sticky", zIndex: "1", top: "70px" }}>
              <Col
                md={12}
                className={"countryTabs tab-vertical"}
                style={{ height: "180px" }}
              >
                <div className={"tab mt-4"}>
                  <Nav tabs style={{ width: "100px", display: "inline-block" }}>
                    {profile.CountrySpecs?.map((fc, indxFC) => (
                      <NavItem key={fc.value}>
                        <NavLink
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          className={classnames({
                            active: activeTabFC === indxFC,
                          })}
                          onClick={() => {
                            toggle(indxFC);
                          }}
                        >
                          <b>{fc.label ? fc.label : fc.CountryName}</b>
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  <TabContent activeTab={activeTabFC}>
                    {profile.CountrySpecs?.map(
                      (fc, indxFC) =>
                        activeTabFC === indxFC && (
                          <>
                            <TabPane tabId={indxFC} key={fc.value}>
                              <Row>
                                <Col md={12} className={"methodologyTabs"}>
                                  <Nav tabs>
                                    {fc.MethodologySpecs?.map((sm, indxSM) => (
                                      <>
                                        <NavItem key={sm.value}>
                                          <NavLink
                                            className={classnames({
                                              active: activeTabSM === indxSM,
                                            })}
                                            onClick={() => {
                                              toggleSM(indxSM);
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                              }}
                                            >
                                              <div className="hoverEffect">
                                                {sm.NotApplicable}
                                                <b>
                                                  {sm.label
                                                    ? sm.label
                                                    : sm.Label}
                                                </b>
                                              </div>
                                            </div>
                                          </NavLink>
                                        </NavItem>
                                      </>
                                    ))}
                                  </Nav>
                                </Col>
                              </Row>
                            </TabPane>
                          </>
                        )
                    )}
                  </TabContent>
                  <TabContent activeTab={activeTabFC}>
                    {profile.CountrySpecs?.map(
                      (fc, indxFC) =>
                        activeTabFC === indxFC && (
                          <>
                            <TabPane tabId={indxFC} key={fc.value}>
                              <Row>
                                <Col md={12} className={"methodologyTabs"}>
                                  <Nav tabs style={{ marginLeft: "95px" }}>
                                    {fc.MethodologySpecs?.[
                                      activeTabSM
                                    ].CostingOptions.map(
                                      (costingOption, indxSM) => {
                                        return (
                                          <NavItem key={indxSM.value}>
                                            <NavLink
                                              className={classnames({
                                                active: activeTabCO === indxSM,
                                              })}
                                              disabled={
                                                timecostView === "commercial"
                                                  ? costingOption
                                                      .CostingOptionData
                                                      .Commericalsupport ===
                                                    "No"
                                                  : costingOption
                                                      .CostingOptionData
                                                      .operationpm === "No"
                                              }
                                              onClick={() => {
                                                toggleCO(indxSM);
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <div className="hoverEffect">
                                                  <b>
                                                    {costingOption.label
                                                      ? costingOption.label
                                                      : costingOption.Name}
                                                  </b>
                                                </div>
                                              </div>
                                            </NavLink>
                                          </NavItem>
                                        );
                                      }
                                    )}
                                  </Nav>
                                </Col>
                              </Row>
                            </TabPane>
                          </>
                        )
                    )}
                  </TabContent>
                </div>
              </Col>
            </Row>
            <Row className="mt-2 mb-2">
              <Col md={6}>
                <h3 style = {{marginLeft: "160px"}}>Product: {profile?.CountrySpecs?profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[activeTabCO]
            .CostingOptionData.producttype:null}</h3>
              <h3 style = {{marginLeft: "160px"}}>WBS Number: {getWBSnumber()}</h3>
              </Col>
              <Col md={4}>
                  <h4>Currency Selection</h4>
                   <Select
													// isDisabled={profile.ProfileStatus > 1}
													placeholder="Select a Currency..."
													options={currencies?.map((c) => {
														return {
															value: `${c.Code}-${c.CurrencyUnit}`,
															label: `${c.Label} (1USD = ${c.ConversionRateToLocal}${c.CurrencyUnit})`,
														};
													})}
                          value={CScurrencyDropdown?CScurrencyDropdown:null}
                          onChange={handleCSCurrencyChange}
										/>
              </Col>
            </Row>
            <Container
              style={{
                width: "50%",
                marginLeft: "160px",
                backgroundColor: "white",
              }}
            >
              <Table responsive hover striped bordered size="sm">
                <tbody>
                  <tr style={{ fontWeight: "bold", fontSize: "15px" }}>
                    <td>Summary</td>
                  </tr>
                  {
                    <tr style={{ fontWeight: "bold", fontSize: "15px" }}>
                      <td>Total Commercial Time Cost ({CSCurrencyUnit})</td>
                      <td>{(totalCsCost*CSCurrencyConversion)?.toFixed(2)}</td>
                    </tr>
                  }
                </tbody>
              </Table>
            </Container>
            <CostingMarketTable
              activities={activitiesForCS}
              band={band}
              role={role}
              activeTabSM={activeTabSM}
              activeTabCO={activeTabCO}
              activeTabFC={activeTabFC}
              tableData={_.cloneDeep(csCostData)}
              teamDataOptions={CSTeamDataOptions}
              defaultRates={defaultRates}
              costingOptionID={ profile.CountrySpecs !== undefined ?
                profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
                  ?.CostingOptions[activeTabCO]?.id : ""
              }
              CurrencyConversion={CSCurrencyConversion}
            />
            {csCostData && profile.CountrySpecs &&
              csCostData[
                profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
                  ?.CostingOptions[activeTabCO]?.id
              ]?.map((csData, index) => (
                <>
                  {/* <hr style={{height:"2px",border:"none",color:"#333",backgroundColor:"#333"}}/> */}
                  <hr />
                  <Container style={{ marginLeft: "140px" }}>
                    <Row>
                      <Col
                        md={4}
                        style={{
                          maxWidth: "287px !important",
                        }}
                      >
                        <h4>Select Commercial Project Team {`${index + 1}`}</h4>
                      </Col>
                      <Col md={5} style={{ marginLeft: "-50px" }}>
                        <Select
                          options={CSTeamDataOptionsTemp}
                          value={CSTeamDataOptions.filter(function (option) {
                            return option.label === Object.keys(csData)[0];
                          })}
                          onChange={(e) => {
                            handleSelectChange(e, index);
                          }}
                          isDisabled={
                            !localSupportModalCS ||
                            !localSupportCS ||
                            project.PriceChange == 3 ||
                            profile.ProfileStatus === "6" ||
                            profile.ProfileStatus === "99" ||
                            project.ProjectStatus == 3||getProductName().includes("EXPENSE RECHARGE") ||
                            !profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
                              ?.CostingOptions[activeTabCO]?.isSelected
                          }
                        />
                      </Col>
                      <Col>
                        {index >= 2 && (
                          <Button
                            style={{
                              width: "80px",
                            }}
                            color="primary"
                            onClick={() => {
                              deleteCSTable(
                                profile.CountrySpecs[activeTabFC]
                                  .MethodologySpecs[activeTabSM]
                                  ?.CostingOptions[activeTabCO]?.id,
                                index
                              );
                            }}
                            //  disabled={project.PriceChange == 3 || profile.ProfileStatus === "6" || profile.ProfileStatus === "99" || project.ProjectStatus == 3}
                          >
                            Delete
                          </Button>
                        )}
                      </Col>
                      <Col md={2}>
                        <Button
                          style={{
                            width: "80px",
                            marginLeft: "-43px",
                          }}
                          color="primary"
                          onClick={() => {
                            clearCSTable(profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
                              ?.CostingOptions[activeTabCO]?.id,index);
                          }}
                          //  disabled={project.PriceChange == 3 || profile.ProfileStatus === "6" || profile.ProfileStatus === "99" || project.ProjectStatus == 3}
                        >
                          Clear
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                  <TimeCostTable
                    reduxData = {_.cloneDeep(csCostData)}
                    costingOptionID={
                      profile.CountrySpecs[activeTabFC].MethodologySpecs[
                        activeTabSM
                      ]?.CostingOptions[activeTabCO]?.id
                    }
                    activities={activitiesForCS}
                    band={band}
                    role="CS"
                    inputCountry={Object.keys(csData)[0]}
                    rowIndex={index}
                    tableData={Object.values(csData)[0]}
                    localSupport={localSupportCS}
                    localModalSupport={localSupportModalCS}
                    defaultRates={defaultRates}
                    countryOptions={CSTeamDataOptions}
                    isClearTable={isClear}
                    setIsClear={setIsClear}
                    costData = {_.cloneDeep(commercialCostData)}
                    setAlert={setAlert}
                    CurrencyUnit={CSCurrencyUnit}
                    CurrencyConversion={CSCurrencyConversion}
                    productName={getProductName()}
                    costingOption={
                      profile.CountrySpecs[activeTabFC].MethodologySpecs[
                        activeTabSM
                      ]?.CostingOptions[activeTabCO]
                    }
                    minhours = {getMinHoursByCostOpt(profile.CountrySpecs[activeTabFC].MethodologySpecs[
                      activeTabSM
                    ]?.CostingOptions[activeTabCO].id)}
                  />
                </>
              ))}
              {( showProductValidation || IsTextBoxdisabled) && <><hr /> <Container 
                style={{width: "100%",
                  marginLeft: "10px"
                }}
              >
                <Row style={{ position: "sticky", zIndex: "1", top: "70px" }}>
                  <Col
                      md={12}
                      className={"countryTabs tab-vertical"}
                      style={{ height: "180px" }}
                    >
                  <div className={"tab mt-2"}>                
                    
                    {/* <Card className="ml-2 mr-2">
                      <CardHeader>                        
                        <CardTitle id="reason_timecost" className="mb-0" style={{ fontSize: '1.5rem', fontWeight: 'bold', margin: '0'}}> */}
                        <span style={{fontSize: "18px", fontWeight: "bold"}}>
                          Internal time cost input costing Option level validation
                        </span>
                        <br/>
                        {/* </CardTitle>
                        <CardSubtitle style={{fontSize: '1.2rem', color: 'black'}}> */}
                        <span style={{fontSize: "16px"}}>
                          For each costing Option, associate the internal time cost or provide a reason if it is zero or less than cost calculated from predefined hours by {profile.ThresholdTimecost * 100}%.
                        </span>
                        {/* </CardSubtitle>                          
                      </CardHeader> */}
                      {/* <CardBody> */}
                        <Table> 
                          <thead>
                            {tableHeaders.map((tv) => (
                              <th>
                                {tv}
                              </th>
                            ))}
                          </thead>
                          <tbody>
                            {
                              getInternalCost()?.map(tv => (
                                <tr>
                                  <td>
                                    {tv?.Productname}
                                  </td>
                                  <td>
                                    {tv.WBSNumber}
                                  </td>
                                  <td>
                                    {tv.Label}
                                  </td>
                                  <td>
                                    {tv.costingoptionlabel}
                                  </td>
                                  <td>
                                    Commercial
                                  </td>
                                  <td>
                                    { getTotalcsCost(tv.costOptionId).toFixed(2)}
                                  </td>
                                  <td>
                                  { getTotalcshours(tv.costOptionId).toFixed(0)} 
                                  </td>
                                  <td>
                                  {getTotalminHours(getMinHoursByCostOpt(tv.costOptionId)).toFixed(0)} 
                                  </td>
                                  <td>
                                    <div style={{ position: "relative", display: "inline-block", width: "100%" }}>
                                      <Input
                                        type="textarea"  style = {{width:"100%",paddingRight:"30px"}}
                                        value={CsTimecostReason[tv.costOptionId]?.CsTimecostReason}
                                        disabled={(IsTextBoxdisabled.length > 0 ? 
                                          (((getTotalcshours(tv.costOptionId) >= getTotalminHours(getMinHoursByCostOpt(tv.costOptionId)).toFixed(0) && (getTotalcshours(tv.costOptionId) !== 0 ))) || ( (csTimecostReasonSaved[tv.costOptionId]?.CsTimecostReason !== null && csTimecostReasonSaved[tv.costOptionId]?.CsTimecostReason !== "" && csTimecostReasonSaved[tv.costOptionId]?.CsTimecostReason !== undefined) ) 
                                        ) &&
                                          !IsTextBoxdisabled.includes(tv.costOptionId) : (((getTotalcshours(tv.costOptionId) >= getTotalminHours(getMinHoursByCostOpt(tv.costOptionId)).toFixed(0) && (getTotalcshours(tv.costOptionId) !== 0 ))) || ( (csTimecostReasonSaved[tv.costOptionId]?.CsTimecostReason !== null && csTimecostReasonSaved[tv.costOptionId]?.CsTimecostReason !== "" && csTimecostReasonSaved[tv.costOptionId]?.CsTimecostReason !== undefined) ) 
                                        ))
                                           }
                                        onChange={(e) => handleTimecostReasoncs(e, tv.costOptionId)}
                                      />
                                      {(getTotalcshours(tv.costOptionId) >= getTotalminHours(getMinHoursByCostOpt(tv.costOptionId)).toFixed(0) && getTotalcshours(tv.costOptionId) !== 0  ) || (csTimecostReasonSaved[tv.costOptionId]?.CsTimecostReason !== null && csTimecostReasonSaved[tv.costOptionId]?.CsTimecostReason !== "" && csTimecostReasonSaved[tv.costOptionId]?.CsTimecostReason !== undefined) && (<FontAwesomeIcon
                                        title="Edit Reason"
                                        icon={faPencilAlt}
                                        fixedWidth
                                        onClick={() =>handleIconClick(tv.costOptionId)}
                                        style={{ cursor: "pointer", position: "absolute", right: "0px", top: "9px", transform: "translateY(-50%)", color: "#666" }}
                                      />
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </Table>
                      {/* </CardBody>
                    </Card> */}
                  </div>
                  </Col>
                </Row>
              </Container></>} 
            <Prompt
              when={alert}
              message='Clicking on "OK" will navigate to next page without saving data '
            />
            <InfoModal
              infoModal={infoModal}
              infoModalToggle={infoModalToggle}
              activityMapping={activityMapping}
              currentActiveTab={currentActiveTab}
              toggleTabPane={toggleTabPane}
            />
            <Row>
              <Col className="justify-content-end">
              <Button style={{top:"85%", zIndex: "2"}}
                  className="validateandsave costing-profile-btn"
                  color="primary"
                  onClick={saveTableData}
                  disabled={project.PriceChange == 3 || profile.ProfileStatus === "6" || profile.ProfileStatus === "99" || project.ProjectStatus == 3}
                >
                  <FontAwesomeIcon
                    className="icon-costingprofile"
                    icon={faSave}
                    fixedWidth
                  />
                  Save
                </Button>
              </Col>
            </Row>

            <Row>
              <Col className="justify-content-end">
                <Button
                  style={{ top: "45%", zIndex: "2" }}
                  className="copy-to-all-market costing-profile-btn"
                  color="primary"
                  onClick={() => {
                    setInfoModal(true);
                  }}
                >
                  <FontAwesomeIcon
                    className="icon-costingprofile"
                    icon={faInfoCircle}
                    fixedWidth
                  />
                  Activities info
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="justify-content-end">
                <Button
                  style={{ top: "75%", zIndex: "2" }}
                  className="copy-to-all-market costing-profile-btn"
                  color="primary"
                  onClick={() => {
                    copyCountriesData();
                  }}
                >
                  <FontAwesomeIcon
                    className="icon-costingprofile"
                    icon={faCopy}
                    fixedWidth
                  />
                  Copy Commercial tables
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="justify-content-end">
                <Button
                  style={{ top: "35%", zIndex: "2" }}
                  className="copy-to-all-market costing-profile-btn"
                  color="primary"
                  onClick={() => {
                    settimecostViewModal(true);
                  }}
                >
                  <FontAwesomeIcon
                    className="icon-costingprofile"
                    icon={faPencilAlt}
                    fixedWidth
                  />
                  Edit Level of Input
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="justify-content-end">
                <Button
                  style={{ top: "65%", zIndex: "2" }}
                  className="copy-to-all-market costing-profile-btn"
                  color="primary"
                  onClick={addNewCostingRow}
                >
                  <FontAwesomeIcon
                    className="icon-costingprofile"
                    icon={faPlusCircle}
                    fixedWidth
                  />
                  Add Costing Market
                </Button>
              </Col>
            </Row>
            <TimeCostModal isOpen={timecostViewModal} isClose={updateModal} />
          </div>
        )}
        <Modal
          isOpen={showModal}
          toggle={handleClose}
          style={{ height: "30px" }}
        >
          <ModalHeader toggle={handleClose}>Clear Table Data</ModalHeader>
          <ModalBody>Are you sure to clear table data?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => handleOk()}>
              Ok
            </Button>
            <Button color="secondary" onClick={() => handleClose()}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={showDeleteModal}
          toggle={handleCloseModal}
          style={{ height: "30px" }}
        >
          <ModalHeader toggle={handleClose}>Delete Table</ModalHeader>
          <ModalBody>Are you sure to Delete table?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => handleOKModal()}>
              Ok
            </Button>
            <Button color="secondary" onClick={() => handleCloseModal()}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <CopyOptionsModal
          mode={"CS"}
          modal={modal}
          activeTabFC={activeTabFC}
          activeTabCO={activeTabCO}
          activeTabSM={activeTabSM}
          setModal={setModal}
          setIsLoading={setIsLoading}
          selectedMethodologyData1={selectedMethodologyData1}
          activities={activitiesForCS}
          band={band}
          options={options}
          selectedCountryData={selectedCountryData}
        />
      </Main>
    </Wrapper>
  );
};

export default CommercialTimecost;