import React, { useEffect } from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    HashRouter,
    Redirect,
    useLocation,
    useHistory,
} from "react-router-dom";
// import {
//   landing as landingRoutes,
//   dashboard as dashboardRoutes,
//   page as pageRoutes
// } from "./index";

import { connect, useSelector } from "react-redux";
import _, { replace } from "lodash";
import * as appActions from "../redux/actions/appActions";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";
import Spinner from "../components/Spinner";
import ScrollToTop from "../components/ScrollToTop";
import SignIn from "../pages/auth/SignIn";
import Register from "../pages/auth/Register";
import RegisterCompleted from "../pages/auth/RegisterCompleted";
// import RegisterRequestSubmitted from "../pages/auth/registerRequestSubmitted"
// import GFKUserRegisterSubmitted from "../pages/auth/GFKUserRegisterSubmitted"
import QuestionsSubmitted from "../pages/auth/GFKUserRegisterSubmitted"


import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/ResetPassword";
// import BigPage from "../pages/BigPage";
import Dashboard from "../pages/dashboard/Projects/Dashboard";
import Proposal from "../pages/proposal/Proposal";
import ProposalNew from "../pages/proposalNew/Proposal";
import Costing from "../pages/proposalNew/Costing";
// import CostingCopy from "../pages/proposalNew/Costing";
import CountryMethodologyTabsCopy from "../pages/proposalNew/CountryMethodologyTabsFinalCopy";
import ManageUsers from "../pages/manageUsers/ManageUsers";
import MarketDefaults from "../pages/marketDefault/MarketDefault";
import DeliveryDashboard from "../pages/deliveryDasboard/DeliveryDashboard";
import DeliveryReport from "../pages/deliveryReport/DeliveryReport";
import SurveyInformation from "../pages/deliveryDasboard/SurveyInformation";
import Summary from "../pages/summary/Summary";
import RequestsBoard from "../pages/requestsBoard/RequestsBoard";
import NewProjectSchedule from "../pages/projectSchedule/ProjectScheduleNew";
import ProjectSchedule from "../pages/projectSchedule/ProjectSchedule";
import Finance from "../pages/finance/Finance";
import OpsReport from "../pages/opsReport/OpsReport";
import OverrideCosts from "../pages/summary/OverrideCosts";
import Page403 from "../pages/auth/Page403";
import ProjectReview from "../pages/ProjectReview/ProjectReviewNew";
import ProjectReviewUpdated from "../pages/ProjectReview/ProjectReviewUpdated";

import DeliveryReportNew from "../pages/deliveryReport/DeliveryReportNew";
import FinanceNew from "../pages/finance/FinanceNew";
import OpsReportNew from "../pages/opsReport/OpsReportNew";
import FinanceLogsNew from "../pages/finance/FinanceLogsNew";
import OopReport from "../pages/oopReport/OopReport";
import wavelevelEdit from "../pages/wavelevel/WaveLevelEdit";
import ManualCostEntry from "../pages/costingProfile/ManualCostEntry";
import NewSpreadsheet from "../pages/proposalNew/NewSpreadsheet";
import CommercialHours from "../pages/costingProfile/CommercialHours";
import ProfileSummary from "../pages/profileSummary/profileSummary";
import CountryLevelOverrideCosts from "../pages/summary/CountryLevelOverrideCosts";
import NotificationsBoard from "../pages/notificationsBoard/NotificationsBoard";
import SudReport from "../pages/sudReport/SudReport";
import DataExport from "../pages/dataExport/DataExport";

// Vendor Components
import Vdashboard from "../pages/vendordashboard/Projects/Vdashboard";
import VendorList from "../pages/vendordashboard/Projects/VendorList";
import VendorForm from "../pages/vendordashboard/Projects/VendorForm";
import VendorFormV2 from "../pages/vendordashboard/Projects/VendorFormV2";

import VendorBiddingForm from "../pages/vendordashboard/Projects/VendorBiddingForm";
//import Vendorbiddingview from "../pages/vendordashboard/Projects/VendorBiddingview";
import Vendorbiddingview from "../pages/vendordashboard/Projects/VendorBiddingviewUpdated";
import VendorFormUpdated from "../pages/vendordashboard/Projects/VendorFormUpdated";
import VendorBiddingFormUpdated from "../pages/vendordashboard/Projects/VendorBiddingFormUpdated";
import VendorbiddingviewUpdated from "../pages/vendordashboard/Projects/VendorBiddingviewUpdated";
import VendorBiddingViewBase from "../pages/vendordashboard/Projects/VendorBiddingViewBase";
import CommercialTimecost from "../pages/newInputCost/CommercialTimecost";
import OperationsTimecost from "../pages/newInputCost/OperationsTimecost";
import VendorFormApproveReject from "../pages/vendordashboard/Projects/VendorFormApproveReject";
import ReactGA from 'react-ga';
import ProposalBiddingSelectVendorsUpdated from "../pages/vendordashboard/Projects/ProposalBiddingSelectVendorsUpdated";
import ScheduleDatesEWN from "../pages/projectSchedule/ScheduleDatesEWN";
import ManageFeeds from "../pages/manageFeeds/ManageFeeds";
import ManageAlerts from "../pages/manageAlerts/ManageAlerts";
const TRACKING_ID = "UA-236012581-1" // OLD: "UA-234416706-2"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID, { 'siteSpeedSampleRate': 100 });



const AccessLookup = {
    deliveries: "DeliveryDashboardAccess",
    requests: "RequestsBoardAccess",
    finance: "FinanceAccess",
    marketsettings: "ManageMarketAccess",
    users: "ManageUsersAccess",
    SUDExport: "SUDExport",
    DataExport: "CanExport",
    // projectReview: "ProjectReviewAccess",
};

//sets home page in case internal dashboard is not accessible for the loggedin user
//if it's TCS user navigates to delivery dashboard, in case if have access for it
//for non-TCS user, it will navigate to next available route as per priority
const setHomePage = (actualPath, userRecord) => {
    let isTCSUser = userRecord.IsTCSUser;

    if (!actualPath && !userRecord.InternalDashBoardAccess) {
        if (isTCSUser && userRecord.DeliveryDashboardAccess) {
            return (
                <Redirect
                    to={{
                        pathname: "/deliveries",
                    }}
                />
            );
        } else {
            let firstRoute = Object.keys(AccessLookup).filter(
                (al) => userRecord[AccessLookup[al]]
            );
            if (firstRoute.length)
                return (
                    <Redirect
                        to={{
                            pathname: `/${_.head(firstRoute)}`,
                        }}
                    />
                );
        }
    }
    return (
        <AuthLayout>
            <Page403 />
        </AuthLayout>
    );
};

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => {

    let location = useLocation();
    let history = useHistory();
    history.listen((location) => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
    }
    );
    const userRecord = useSelector(({ user }) => user.userRecord);
    let actualPath = _.head(location.pathname?.replace("/", "").split("/"));

    return (
        <Route
            {...rest}
            render={(props) => {
                return isAuthenticated === true ? (
                    (!actualPath && userRecord.InternalDashBoardAccess) ||
                        (actualPath &&
                            AccessLookup[actualPath] &&
                            userRecord[AccessLookup[actualPath]]) ||
                        (actualPath && !AccessLookup[actualPath]) ? (
                        <Component {...props} />
                    ) : (
                        setHomePage(actualPath, userRecord)
                    )
                ) : (
                    <Redirect
                        to={{
                            pathname: "/auth/login",
                            state: { from: location },
                        }}
                    />
                );
            }}
        />
    );
};

// const layoutWrapper = (Layout, routes, isAuthenticated) => {
//   return routes.map(({ path, component: Component }, index) => {
//     //console.log("component", Component);
//     return (
//       <PrivateRoute
//         key={index}
//         path={path}
//         isAuthenticated={isAuthenticated}
//         exact
//         component={
//           <Layout>
//             <Component />
//           </Layout>
//         }
//       />
//     );
//   });
// };

const Routes = (props) => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    useEffect(() => {
        props.init();
    }, []);

    return (
        <>
            {props.apploaded ? (
                <HashRouter>
                    <ScrollToTop>
                        <Switch>
                            <PrivateRoute
                                path="/"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={Dashboard}
                            />
                            <PrivateRoute
                                path="/vendordashboard"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={Vdashboard}
                            />
                            <PrivateRoute
                                path="/vendoronboarding"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={VendorList}

                            />
                            <PrivateRoute
                                path="/vendoronboarding/vendor-form"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={VendorForm}
                            />
                            <PrivateRoute
                                path="/vendoronboarding/vendor-form/:Id"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={VendorForm}
                            />
                            <PrivateRoute
                                path="/vendoronboardingV2/vendor-form"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={VendorFormV2}
                            />
                            <PrivateRoute
                                path="/vendoronboardingV2/vendor-form/:Id"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={VendorFormV2}
                            />

                            <PrivateRoute
                                path="/vendordashboard/vendor-bidding/:projectId/:CostingProfileID"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={VendorBiddingFormUpdated}
                            />
                            <PrivateRoute
                                path="/vendordashboard/vendor-bidding-summary/:projectId/:CostingProfileID/:summary"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={VendorBiddingFormUpdated}
                            />
                            <PrivateRoute
                                path="/approve-reject-Vendor/:Id"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={VendorFormApproveReject}
                            />
                            <Route
                                path="/vendordashboard/vendorbiddingview/:projectId/:vendorId"
                                exact
                                component={Vendorbiddingview}
                            />
                            <Route
                                path="/vendordashboard/vendorbiddingview-testing/:projectId/:costingprofileID"
                                exact
                                component={Vendorbiddingview}
                            />
                            <Route
                                path="/vendordashboard/vendorbiddingview"
                                exact
                                component={VendorBiddingViewBase}
                            />

                            <PrivateRoute
                                path="/proposal"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={Proposal}
                            />
                            <PrivateRoute
                                path="/proposal-new"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={ProposalNew}
                            />
                            <PrivateRoute
                                path="/costing"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={Costing}
                            />
                            {/* <PrivateRoute
                                path="/costing"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={CostingCopy}
                            /> */}
                            <PrivateRoute
                                path="/detailed-rfq"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={CountryMethodologyTabsCopy}
                            />
                            <PrivateRoute
                                path="/costing-input/:profileId"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={ManualCostEntry}
                            />
                            <PrivateRoute
                                path="/shopper-costing-input/:profileId"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={NewSpreadsheet}
                            />
                            <PrivateRoute
                                path="/commercial-costing-input/:profileId"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={CommercialHours}
                            />
                            <PrivateRoute
                                path="/users"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={ManageUsers}
                            />
                            <PrivateRoute
                                path="/marketsettings"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={MarketDefaults}
                            />
                            <PrivateRoute
                                path="/deliveries"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={DeliveryDashboard}
                            />
                            <PrivateRoute
                                path="/deliveryReport"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={DeliveryReport}
                            />
                            <PrivateRoute
                                path="/deliveryReportNew"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={DeliveryReportNew}
                            />
                            <PrivateRoute
                                path="/finance"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={Finance}
                            />
                            <PrivateRoute
                                path="/finance-new"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={FinanceNew}
                            />
                            <PrivateRoute
                                path="/finance-logs"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={FinanceLogsNew}
                            />
                            <PrivateRoute
                                path="/oop-report"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={OopReport}
                            />
                            <PrivateRoute
                                path="/internalCostCommercial"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={CommercialTimecost}
                            />
                            <PrivateRoute
                                path="/internalCostOperations"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={OperationsTimecost}
                            />
                            <PrivateRoute
                                path="/rfcreport"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={OopReport}
                            />
                            <PrivateRoute
                                path="/ops-report"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={OpsReport}
                            />
                            <PrivateRoute
                                path="/ops-report-new"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={OpsReportNew}
                            />
                            <PrivateRoute
                                path="/summary/:profileId"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={Summary}
                            />
                            <PrivateRoute
                                path="/Notificationsboard"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={NotificationsBoard}
                            />
                            <PrivateRoute
                                path="/SudReport"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={SudReport}
                            />
                            <PrivateRoute
                                path="/managefeeds"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={ManageFeeds}
                            />
                            <PrivateRoute
                                path="/managealerts"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={ManageAlerts}
                            />
                             <PrivateRoute
                                path="/DataExport"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={DataExport}
                            />
                            <PrivateRoute
                                path="/overridecosts"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={OverrideCosts}
                            />
                            <PrivateRoute
                                path="/schedule/:profileId"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={ProjectSchedule}
                            />
                                <PrivateRoute
                                path="/newschedule/:profileId"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={NewProjectSchedule}
                            />
                                <PrivateRoute
                                path="/newscheduleewn/:profileId"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={ScheduleDatesEWN}
                            />
                            <PrivateRoute
                                path="/waveleveledit"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={wavelevelEdit}
                            />
                            <PrivateRoute
                                path="/profile-Summary"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={ProfileSummary}
                            />
                            <PrivateRoute
                                path="/countryoverridecosts"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={CountryLevelOverrideCosts}
                            />
                            {/* <PrivateRoute
                path="/survey"
                isAuthenticated={props.isAuthenticated}
                exact
                component={SurveyInformation}
              />
            /> */}
                            <PrivateRoute
                                path="/requests/:requestId?"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={RequestsBoard}
                            />
                            <PrivateRoute
                                path="/project-review/:profileId"
                                isAuthenticated={props.isAuthenticated}
                                exact
                                component={ProjectReviewUpdated}
                            />
                            {/* <Route
                path="/requests/:requestId?"
                isAuthenticated={props.isAuthenticated}
                exact
                component={RequestsBoard}
              /> */}

                            <Route path="/auth/login" exact component={SignIn} />
                            <Route path="/auth/register" exact component={Register} />
                            <Route path="/auth/registerCompleted" exact component={RegisterCompleted} />
                            <Route path="/auth/QuestionsSubmitted" exact component={QuestionsSubmitted} />

                            <Route
                                path="/auth/forgot-password"
                                exact
                                component={ForgotPassword}
                            />
                            <Route
                                path="/auth/reset-password/:ResetToken"
                                exact
                                component={ResetPassword}
                            />
                            <Route path="unauthorised" exact component={Page403} />
                            <Route
                                render={() => (
                                    <AuthLayout>
                                        <Page404 />
                                    </AuthLayout>
                                )}
                            />
                        </Switch>
                    </ScrollToTop>
                </HashRouter>

            ) : (
                <div id="pageCoverSpin"></div>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated:
            state.user.authToken !== null &&
            state.user.authToken !== "undefined" &&
            state.user.authToken !== "",
        apploaded: state.app.apploaded,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        init: () => dispatch(appActions.init()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Routes);
