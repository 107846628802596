import React, { useEffect, useState, useReducer } from "react";
import axios from "../../axios-interceptor";
import Wrapper from "../../components/Wrapper";
import Sidebar from "../../components/Sidebar";
import Main from "../../components/Main";
import ProjectNavbar from "../../components/ProjectNavbar";
import TimeCostModal from "../../components/TimeCostModal";
import * as currentCostingActions from "../../redux/actions/currentCostingActions";
import Select from "react-select";
import {
  Row,
  Button,
  Col,
  NavLink,
  NavItem,
  Nav,
  TabContent,
  TabPane,
  Table,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardSubtitle,
  Input,
  FormGroup,
  Label,
  CustomInput,
} from "reactstrap";
import { Container } from "react-bootstrap";
import {
  faPencilAlt,
  faInfoCircle,
  faCopy,
  faPlusCircle,
  faSave
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import _, { uniq } from "lodash";
import * as sideBarActions from "../../redux/actions/sidebarActions";
import { Prompt } from "react-router-dom";
import TimeCostTable from "./TimeCostTable";
import CostingMarketTable from "./CostingMarketTable";
import {
  setCommercialHours,
  setOperationsHours,
  setOperationsCost,
  saveHourspayload,
  saveTotalCost,
  saveTimecost
} from "../../redux/actions/timecostHoursActions";
import InfoModal from "../../components/TimeCostModals/InfoModal";
import CopyOptionsModal from "./CopyOptionsModal";
import { toastr } from "react-redux-toastr";
import TestPageOpResourceNew from "../proposalNew/TestOpResourceNew";
import { setCurrentProject, setFormErrorStatus, setOpportunityValue } from "../../redux/actions/currentProjectActions";
import { valueChangedInRFQ } from "../../redux/actions/appActions";
import { useLocation } from "react-router-dom";
import zIndex from "@material-ui/core/styles/zIndex";
import Axios from "../../axios-interceptor";
import { calcAll } from "../../utils/calculations";
import * as mapperFunctions from "../../utils/rfqMapper";
import { colors } from "@material-ui/core";

const SHOPPERS_METHODOLOGIS = ["SM000024", "SM000026", "SM000029"]

const OperationsTimecost = () => {
  var isvalid = true;
  const dispatch = useDispatch();
  const timecostView = useSelector(({ timeCost }) => timeCost.selectedRole);
  const pageloaded = useSelector(({ app }) => app.pageloaded);
  const profile = useSelector(
    ({ currentCosting }) => currentCosting.currentCostingProfile
  );
  const project = useSelector(
    ({ currentProject }) => currentProject.newProject
  );
  let opsCostData = useSelector(
    ({ timecostHours }) => timecostHours.operations
  );

  console.log("opsopsopsops", opsCostData)
  const rateCards = useSelector(({ rateCards }) => rateCards);
  let operationsCostData = useSelector(({ timecostHours }) => timecostHours.operationCost);
  const levelInput=timecostView==="commercial"?profile.CSInput:profile.OpsInput
  const currencies = useSelector(({ currentCosting }) =>
  currentCosting.currentCostingProfile.ProfileSetting &&
    currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
    ? currentCosting.currentCostingProfile.ProfileSetting.CurrenciesData
    : []
  );
  const [infoModal, setInfoModal] = useState(false);
  const [activeTabFC, setActiveTabFC] = useState(0);
  const [timecostViewModal, settimecostViewModal] = useState(false);
  const [activeTabSM, setActiveTabSM] = useState(0);
  const [activeTabCO, setActiveTabCO] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [OPSband, setOPSBand] = useState([]);
  const [activitiesForOPS, setActivitiesForOPS] = useState([]);
  const [alert, setAlert] = useState(false);
  const [activityMapping, setActivityMapping] = useState([]);
  const [currentActiveTab, setCurrentActiveTab] = useState("1");
  const [localSupportOPS, setLocalSupportOPS] = useState(true);
  const [localSupportModalOPS, setLocalSupportModalOPS] = useState(true);
  const [role, setRole] = useState("OPS");
  const [OPSTeamDataOptions, setOPSTeamDataOptions] = useState([]);
  const [OPSTeamDataOptionsTemp, setOPSTeamDataOptionsTemp] = useState([]);
  const [defaultRates, setDefaultRates] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isClear, setIsClear] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCostOptionID, setSelectedCostOptionID] = useState();
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedCountryForClear, setSelectedCountryForClear] = useState();
  const [selectedCountryData, setSelectedCountryData] = useState({});
  const [selectedMethodologyData1, setSelectedMethodologyData1] = useState({});
  const [modalMethodology, setModalMethodology] = useState(false);
  const [opsModal, setOpsModal] = useState(false);
  const [options, setoptions] = useState();
  const [makeScroll, setMakeScroll] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [costoptionsDetails,setCostoptionsDetails]=useState({});
  const [deletedTimeCostData,setDeletedTimeCostData]=useState({});
  const [deletedCostData,setDeletedCostData]=useState({})
  const [OPSCurrencyUnit,setOPSCurrencyUnit]=useState("")
  const [OPSCurrencyConversion,setOPSCurrencyConversion]=useState(1)
  const [OPScurrencyDropdown,setOPScurrencyDropdown]=useState({})
  const [OPSCurrencyCode,setOPSCurrencyCode]=useState("")
  const [errorObj, setErrorObj] = useState({});
  const [opsTimecostReasonSaved,setopsTimecostReasonSaved]=useState({})
  const codeLabels = useSelector(({ codeLabels }) => codeLabels);
  let pacificData = _.head(codeLabels.PacificCountriesList).Label;
  const [pacificMarkets] = useState(pacificData?.split(","));
  const userRecord = useSelector(({ user }) => user.userRecord);
  const location = useLocation();  
  const [isModalOpen, handleOpen] = useState(false);
  const [validationHeader, setValidationHeader] = useState(
    "Please complete answering all mandatory fields before proceeding."
  );
  const countrySpecsLength =  profile.CountrySpecs?.length;
  const singleCountryMethodologySpecs = countrySpecsLength === 1  && 
  profile.CountrySpecs ?
  profile.CountrySpecs[0]?.MethodologySpecs[activeTabSM]
  .Label : "";
  const [advanceCopying, setAdvanceCopying] = useState({});
  const [modalMethodologiesState, setModalMethodologiesState] = useState(false);
  const [opsTimecostReason, setopsTimecostReason] = useState({});
  const [filteredMethodology,  setfilteredMethodology] = useState([]);
  const [modalConfirmMethodology, setModalConfirmMethodology] = useState(false);
  // const [productRow, setProductRow] = useState([]);
  const [isNewProject, setIsNewProject] = useState(false);
  const [isOpsvalidation, setIsOpsvalidation] = useState([]);
  const [showProductValidation, setShowProductValidation] = useState(true);
  const [IsTextBoxdisabled, SetIsTextBoxdisabled] = useState([]);
  const [minOperationsHours, SetMinOperationsHours] = useState([]);
  // const [, forceUpdate] = useReducer(x => x+1, 0);
  const disabledOptionIds = []

  const modalCategoriesSingleCountry  = [
    // {
    //   value: "CostingOptions",
    //   label: "Costing Options",
    // },
    // {
    //   value: "RFQSchema",
    //   label: "RFQ Form",
    // },
    {
      value: "OpResourcesVariablesTimecost",
      label: "Setup & Delivery",
    }
  ];

  const modalCategories = [
    ...modalCategoriesSingleCountry,
    // {
    //   value: "Responsibilities",
    //   label: "Responsibilities",
    // },
  ];

  const modalPacificCategoriesSingleCountry = [
    // {
    //   value: "RFQSchema",
    //   label: "RFQ Form",
    // },
    {
      value: "OpResourcesVariablesTimecost",
      label: "Setup & Delivery",
    },
    {
      value: "CommercialHoursData",
      label: "Commercial Hours",
    },
  ]

  const modalPacificCategories = [
    ...modalPacificCategoriesSingleCountry,
    // {
    //   value: "Responsibilities",
    //   label: "Responsibilities",
    // },
  ];

  useEffect(()=>{  
    console.log("activeTabSM, activeTabFC", activeTabSM, activeTabFC)
    let isNew = false;
    // if(new Date(profile?.createdAt) > new Date('2024-11-16')) {
    //   isNew = true;
    // }  
    if (profile?.CountrySpecs[activeTabFC]?.MethodologySpecs[
      activeTabSM
    ]?.SetupSchema?.properties) {
      if (!Object.values(profile?.CountrySpecs[activeTabFC]?.MethodologySpecs[
        activeTabSM
      ]?.SetupSchema?.properties)?.some(
        (property) => property?.sectionTitle?.toLowerCase() === "operations resources"
      )){
        isNew = true;
      }
    }
    // if(isNew) {
      setIsNewProject(isNew);
    // }
   
    filteredSingleCountryMethodology(activeTabSM)
  },[activeTabSM, activeTabFC])

  useEffect(()=>{  
    let opsvalidations = []
    profile.CountrySpecs?.map(cs => {
      cs.MethodologySpecs?.map(ms => {
         if (
          ms?.SetupSchema?.properties && !Object.values(ms?.SetupSchema?.properties)?.some(
            (property) => property?.sectionTitle?.toLowerCase() === "operations resources"
          )
        ) {
          opsvalidations.push(ms.id);
        }
      })
    })
    setIsOpsvalidation(opsvalidations);
  },[profile])
  
  useEffect(()=>{  
    console.log(IsTextBoxdisabled)
  },[IsTextBoxdisabled])

  useEffect(() => {
    // if(profile.OpsTimecostReason==null) {
    //   setopsTimecostReason({})
    // }
    // else {
      let opsTimecostReasontemp = {}
      profile.CountrySpecs?.map((country)=>{
        country.MethodologySpecs?.map((method)=>{
          method.CostingOptions?.map((co)=>{
            const {id, CsTimecostReason, OpsTimecostReason}=co
            opsTimecostReasontemp[id] = OpsTimecostReason == null ? {} : {OpsTimecostReason} 
          })})})
          setopsTimecostReason(opsTimecostReasontemp)
          setopsTimecostReasonSaved(opsTimecostReasontemp)
    // }
  }, [])

  useEffect(() => {
    axios.get(`/timecost/minimumhour/${profile.id}`).then((res) => {
  console.log("resdata", res.data.Op)
  SetMinOperationsHours(res.data.Op);
  
    });
  }, [])
  const getTotalminHours = (minhours) => {

    console.log("activity, minhours",  minhours);
      let totalminHours = Object.values(minhours).reduce((a,b) => a+b, 0)
      let threshold = (totalminHours * (profile.ThresholdTimecost))
      totalminHours = totalminHours - threshold 
    return totalminHours;
  };
  const getMinHoursByCostOpt = (costoptid) => {
    let minHours = minOperationsHours?.filter(p => Object.keys(p) == costoptid)[0][costoptid] 
    return minHours
  }
  const teamData = async(countryArray,callBack) => {
    axios.get(`/timecost/country`).then((res) => {
      let opsTeamData = [];
      let opsTeamCountry = res.data.result;
      let opsTempData = [];

      opsTeamCountry.map((opsTeam) => {
          let optionObj = {
            value: opsTeam.Code,
            label: opsTeam.Label,
          };
          opsTeamData.push(optionObj);
      });
      if (countryArray && countryArray?.length > 0) {
        let cArray = [];
        countryArray?.map((c) => {
          if (profile.CountrySpecs[activeTabFC].MethodologySpecs[
            activeTabSM
          ]?.CostingOptions[activeTabCO]?.id == Object.keys(c)[0]
          ) {
            cArray.push(Object.values(c)[0])
          }
        });
        if (cArray && cArray?.length > 0) {
          opsTempData = opsTeamData.filter((data) =>
            !cArray.includes(data.label)
          );
        }
      }
      setOPSTeamDataOptions(opsTeamData);
      setOPSTeamDataOptionsTemp(opsTempData);
      callBack();
    });
  };
  const [opsResourcesVariables] = useState([
    "surveyProgrammingResource",
    "surveyProgrammingResourceVendor",
    "surveyProgrammingResourceVendorOthers",
    "dataProcessingResource",
    "dataProcessingResourceVendor",
    "dataProcessingResourceVendorOthers",
    "dataProcessingComplexity",
    "translationsResource",
    "translationsResourceVendor",
    "translationsResourceVendorOthers",
    "verbatimCodingResource",
    "verbatimCodingResourceVendor",
    "verbatimCodingResourceVendorOthers",
    "chartingResource",
    "chartingResourceVendor",
    "chartingResourceVendorOthers",
    "dashboardingResource",
    "dashboardingResourceVendor",
    "dashboardingResourceVendorOthers",
    "qualityControl",
    "qualityControlComments",
    "dataCollectionPlatform",
    "platformComments",
    "dataProcessingPlatform",
    "overallOperationResourcesComments",
    "dataCollectionPlatformOthers",
    "dataProcessingPlatformOthers"
  ]);

  const tableHeaders = [
    "Product Line",
    "WBS Number",
    "Methodology",
    "Costing Option",
    "Function",
    "Internal Cost (USD)",
    "Number of hours",
    `Minimum number of hours threshold by ${profile.ThresholdTimecost * 100}%`,
    "Reason for low time cost"
  ];

  const commercialFields = {
    "Associate Director": "CostIntCommAssociateDirector",
    "Data Science": "CostIntCommDataScience",
    Director: "CostIntCommDirector",
    "Executive Director": "CostIntCommExecDirector",
    Executive: "CostIntCommExecutive",
    Manager: "CostIntCommManager",
    "Senior Executive": "CostIntCommSeniorExecutive",
    "Senior Manager": "CostIntCommSeniorManager",
  };

  function objectKeys(obj) {
    var keys = [];
    if (!obj) return keys;
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
            keys.push(key);
        }
    }
    return keys;
}

  function formatSchemaByVariables(schemaJSON, variables) {
    if (schemaJSON && schemaJSON.properties != null) {
        var keys = objectKeys(schemaJSON.properties);
        var keysDependencies = schemaJSON.dependencies ? Object.keys(schemaJSON.dependencies) : [];

        for (var key of keys) {
            if (variables && variables.filter(x => x.id == key).length > 0) {
                var variable = variables.filter(x => x.id == key)[0]
                schemaJSON.properties[key].disabled = variable.disabled;
                schemaJSON.properties[key].selected = variable.selected;
                schemaJSON.properties[key].Default = variable.Default;
                // List of keys that can be the starting field for Operations resource in setup and Delivery Part of RFQ Form
                let opResorceHeaders = ["surveyProgrammingResource", "dataProcessingResource", "translationsResource", "verbatimCodingResource", "chartingResource", "dashboardingResource", "qualityControl"]
                // To

                if (_.includes(opResorceHeaders, key)) // To check if a methodology contains any of these fields in setup and delivery section or not
                    if (variable.isNewSection) { // to check if selected key is the first element or not
                        schemaJSON.properties[key].isNewSection = variable.isNewSection //Adding section title and isnew section in schema
                        schemaJSON.properties[key].sectionTitle = variable.sectionTitle
                    }
                    else { // Deleting is new section and section title if key is not the first element of section
                        if (schemaJSON.properties[key].isNewSection) {
                            delete schemaJSON.properties[key].isNewSection
                            delete schemaJSON.properties[key].sectionTitle
                        }
                    }
                // schemaJSON.properties[key].isNewSection = variables.isNewSection;

            }

            if (keysDependencies.indexOf(key) > -1) {
                var dependency = schemaJSON.dependencies[key].oneOf.filter(x => Object.keys(x.properties).length > 1)[0]
                var nestedKeysDependencies = Object.keys(dependency.properties);
                for (let keyNest of nestedKeysDependencies) {
                    if (variables?.filter(x => x.id == keyNest).length > 0) {
                        var variable = variables.filter(x => x.id == keyNest)[0]
                        dependency.properties[keyNest].disabled = variable.disabled;
                        dependency.properties[keyNest].selected = variable.selected;
                        dependency.properties[keyNest].Default = variable.Default;
                    }
                }
            }
        }
    }
    return schemaJSON;
}
const handleIconClick = (costOptionId) =>{
  //console.log("IsTextBoxdisabled",IsTextBoxdisabled)

  disabledOptionIds.map(opt => {
    if (opt.hasOwnProperty(costOptionId)) {
      opt[costOptionId] = true
    }
    return opt
  })
  let temp = []
  temp.push(costOptionId)
  SetIsTextBoxdisabled(temp);
  // forceUpdate();
  // checkIfEnabled(costOptionId) 
}

const fieldsFiltered = (arrData) => {

  const costingOption = (!pacificMarkets.includes(profile.CountrySpecs[0].CountryCode) ||
  (_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0")
  ) ? 
  arrData?.CostingOptions[0]?.Variables?.map((cItem, cIndex) => cItem.label) : []
  const rfqData = arrData?.Variables?.filter((cItem) => !cItem.Default).map((dataItem, cIndex) => dataItem.label)
  const setupAndDelivery = arrData?.OpResourcesVariablesTimecost?.filter((cItem) => !cItem.disabled).map((dataItem, cIndex) => dataItem.id)
  const intersection = _.intersection(setupAndDelivery, opsResourcesVariables);

  return {
    // costingOption : costingOption, 
    // rfqData :rfqData , 
    setupAndDelivery: intersection, 
    name:arrData.Label  
  }
}

const filteredSingleCountryMethodology = (tab) => {
  const  {CountrySpecs} = profile

  if(CountrySpecs?.length) {
    const methodologySpecsLength = CountrySpecs[0]?.MethodologySpecs.length > 1;

    const newDataSetup = []
    if(methodologySpecsLength) {
      CountrySpecs[0].MethodologySpecs.map((mData, mIndex) => {
        if(!mData.NotApplicable) {
          newDataSetup.push(fieldsFiltered(mData))
        }
      })
    }
    setfilteredMethodology(newDataSetup)
  }
}

const copyFormToMethodology = (tab) => {
  if (validateCopyModal()) {

    const primaryMethodology = profile.CountrySpecs[0].MethodologySpecs[activeTabSM]
    const methodologiesLabel = advanceCopying.SelectedMethodologies.map(data => data.label)

    let deletedCOs = []
    
      const copyToAllMethodology = profile.CountrySpecs[0].MethodologySpecs.map((C,index) => {

        if(index !== activeTabSM && (methodologiesLabel).includes(C.Label)) {
          advanceCopying.SelectedCategories.forEach((SC) => {
            switch (SC.value) {
              case "CostingOptions":

                if (advanceCopying.SelectedCopyMethod === COPYING_METHOD_VALUES.APPEND) {
                  // Extract variables from source to tempCO

                  const targetCoHeadVariable = _.head(C.CostingOptions).Variables
                    .map((dataItem, cIndex) => dataItem.label)

                  var sourceLevel = _.cloneDeep(primaryMethodology.CostingOptions);
  
                  const tempCO = sourceLevel.map((MC) => {
                    
                    MC.Variables = MC.Variables.map((V) => {
                      if(targetCoHeadVariable.includes(V.label)) {

                        const  filterData =  _.cloneDeep(
                        _.head(C.CostingOptions)
                       .Variables.filter(item => item.label === V.label))
                      
                          return  {
                            ...filterData[0],
                            value: V?.value ? V?.value  : ""
                          }
                        
                      }
                    }).filter(item => item);

                    MC.CostsData = null
                    MC.TimingsData = null
                    return MC;
                  })

                  const matchingCoLabel = tempCO[0].Variables.map((dataItem, cIndex) => dataItem.label)

                  var remainingTargetCoVariables =  _.head(C.CostingOptions).Variables.filter(itemData => {
                    if(!matchingCoLabel.includes(itemData.label)) {
                      return itemData
                    }
                  })


                  const reOrderTempCO = tempCO.map((MCData) => {

                    const filterData = [...MCData.Variables, ...remainingTargetCoVariables]

                    filterData.map((item, index) => {
                      let indexValue = targetCoHeadVariable.indexOf(item.label)
                      let temp = filterData[indexValue]
                      filterData[indexValue] = item
                      filterData[index] =temp
                   })

                    MCData.Variables = filterData
                    return MCData;
                  })

                  // Append converted costing options to targeted MS costing options
                  
                  C.CostingOptions = C.CostingOptions
                  .concat(reOrderTempCO)
                } 
                else {
                  let costingOptBk = C.CostingOptions
                  C.CostingOptions = primaryMethodology.CostingOptions.map((MC, _ind) => {
                    let COs = primaryMethodology.CostingOptions[_ind];
                    if (COs) {
                      let _MC = _.cloneDeep(COs);

                      let targetCostingOption = C.CostingOptions.length-1 >= _ind ? 
                        C.CostingOptions[_ind] :  C.CostingOptions[0]

                      const coLabelFiltered = targetCostingOption.Variables.map((dataItem, cIndex) => dataItem.label)

                      _MC.Variables = _MC.Variables.map((V) => {
                        if(coLabelFiltered.includes(V.label)) {

                          let  filterData = 
                          targetCostingOption?.Variables.filter(item => item.label === V.label)
                          if(V?.value) {
                            return {
                              ...filterData[0],
                              value: V?.value
                            };
                          } else {
                            return {
                              ...filterData[0]
                            }
                          }
                        }
                      }).filter(item => item);

                    const matchedLabelFiltered = _MC.Variables.map((dataItem, cIndex) => dataItem.label)

                      const remainingTargetVariables =  targetCostingOption.Variables.filter((V) => {
                        if(!matchedLabelFiltered.includes(V.label)) {
                          return V;
                        }
                      })

                      if(remainingTargetVariables.length) {
                        _MC.Variables = _MC.Variables.concat(remainingTargetVariables)
                      }

                      const targetCoHeadVariable = _.head(costingOptBk).Variables
                      .map((dataItem, cIndex) => dataItem.label)
  
                      const filterData = _MC.Variables
  
                      filterData.map((item, index) => {
                        let indexValue = targetCoHeadVariable.indexOf(item.label)
                        let temp = filterData[indexValue]
                        filterData[indexValue] = item
                        filterData[index] =temp
                      })

                      _MC.Variables = filterData

                      _MC.id = C.CostingOptions[_ind]?.id;
                      _MC.MethodologySpecId = C.id;
                      return _MC;
                    } else {
                      return MC;
                    }
                  });
                  let temp = []
                  costingOptBk.forEach(i => {
                    if (C.CostingOptions.findIndex(c => c.id === i.id) === -1) {
                      temp.push({ ...i, toRemove: true })
                    }
                  })
                  deletedCOs = deletedCOs.concat(temp);
                }

                break;
              case  "RFQSchema":

                if(C.Variables.length) {

                  const cloneRfqData = _.cloneDeep(C.Variables)
                  const newData = cloneRfqData.map((rfqVariable, index) => {
                   const matchData = primaryMethodology.Variables.filter(rfqVariabledastd => rfqVariabledastd.label  === rfqVariable.label)
                   if(matchData) {
                      if( matchData[0]?.options ) {
                        let valueData = matchData[0]?.value  ? matchData[0]?.value : "" 
                        let filterValue = valueData ? 
                         rfqVariable?.options.filter(item => 
                          item.value.toString().toLowerCase() ===  valueData.toString().toLowerCase()
                        ) : []

                        return {...rfqVariable, 
                          value: 
                          filterValue.length > 0 ? filterValue[0].value : 
                          valueData ?  valueData : 
                          rfqVariable?.value
                        }
                     } else {
                       return {...rfqVariable, 
                      value: matchData[0]?.value  ? matchData[0]?.value :
                      rfqVariable?.value ?  rfqVariable?.value :
                      "" }

                     }
                   } else {
                       return rfqVariable
                   }
                 })

                  C.Variables = [...newData]
                }
                
              break;
              case "OpResourcesVariablesTimecost":

              if(C.OpResourcesVariablesTimecost.length) {

                const cloneSetupNdDelivery = _.cloneDeep(C.OpResourcesVariablesTimecost)
                const newData = cloneSetupNdDelivery.map((setupAndDVariable, index) => {
                 const matchData = primaryMethodology.OpResourcesVariablesTimecost.filter(setupAndDVariabledastd => setupAndDVariabledastd.label  === setupAndDVariable.label)
                 if(matchData) {
                    if( matchData[0]?.options ) {
                      let valueData = matchData[0]?.value  ? matchData[0]?.value : undefined
                      
                      let filterValue = valueData ? setupAndDVariable?.options.filter(item => 
                        item.value.toString().toLowerCase() ===  valueData.toString().toLowerCase()
                      ) : []
                      
                      return {
                        ...setupAndDVariable, 
                        value: 
                        filterValue.length > 0 ? filterValue[0].value : 
                        valueData ?  valueData : 
                        setupAndDVariable?.value
                      }
                   } else {
                     return {...setupAndDVariable, 
                      value: matchData[0]?.value  ? matchData[0]?.value : 
                      setupAndDVariable?.value ?
                      setupAndDVariable?.value :
                      undefined 
                    }
                   }
                 } else {
                     return setupAndDVariable
                 }
               })

                C.OpResourcesVariablesTimecost = [...newData]
              }
              break;
            }
          })
          return C;
        } else {
          return C;
        }
      })

      // updating the profile and project data
    let profileTemp = _.cloneDeep(profile);
    profileTemp.deletedCostingOptions = profileTemp?.deletedCostingOptions ? 
    profileTemp?.deletedCostingOptions.concat(deletedCOs) : deletedCOs
    
 
    const tempProfiles = project?.CostingProfiles.map((cp) => {
      if (cp?.id == profileTemp?.id) return { ..._.cloneDeep(profileTemp) };
      return { ..._.cloneDeep(cp) };
    });
    dispatch(setCurrentProject({ CostingProfiles: tempProfiles }));
    dispatch(currentCostingActions.updateProfile(profileTemp));

    setModalConfirmMethodology(false);
    toastr.success(
      "Success",
      "Data copied successfully to the selected Methodology"
    );

  }
}

const copyFormToPacificCountries = (tab) => {
  if (validateCopyModal()) {
    const tempProfile = { ...profile };

    let methBreak = {};
    tempProfile.CountrySpecs.map((cs) => {
      cs.MethodologySpecs?.map((ms) => {
        if (!methBreak[ms.id]) {
          methBreak[ms.id] = { Code: ms.Code };
        }
      });
    });

    let selectedCountry = tempProfile.CountrySpecs[tab];
    let selectedCountryMethBreak = {};
    let slectedMethIds = selectedCountry.MethodologySpecs.map((ms) => ms.id);

    tempProfile.WaveSpecs.map((ws) => {
      if (slectedMethIds.indexOf(ws.MethodologySpecId) != -1) {
        if (!selectedCountryMethBreak[methBreak[ws.MethodologySpecId].Code]) {
          selectedCountryMethBreak[methBreak[ws.MethodologySpecId].Code] = {};
        }
        selectedCountryMethBreak[methBreak[ws.MethodologySpecId].Code][
          ws.WaveNumber
        ] = {
          OpsResourcesData: ws.OpsResourcesData,
          CommercialHoursData: ws.CommercialHoursData,
          ResponsibilitySchema: _.cloneDeep(ws.ResponsibilitySchema),
        };
      }
    });
    tempProfile.WaveSpecs = tempProfile.WaveSpecs.map((ws) => {
      if (slectedMethIds.indexOf(ws.MethodologySpecId) == -1) {
        ws.OpsResourcesData =
          selectedCountryMethBreak[methBreak[ws.MethodologySpecId].Code][
            ws.WaveNumber
          ]?.OpsResourcesData;
        ws.CommercialHoursData =
          selectedCountryMethBreak[methBreak[ws.MethodologySpecId].Code][
            ws.WaveNumber
          ]?.CommercialHoursData;
        ws.ResponsibilitySchema =
          selectedCountryMethBreak[methBreak[ws.MethodologySpecId].Code][
            ws.WaveNumber
          ]?.ResponsibilitySchema;
      }
      return { ...ws };
    });

    // selected market data
    const tempCountrySpecs = tempProfile.CountrySpecs[tab];
    // data conversion of the selected items in Modal
    const countryArray = advanceCopying.SelectedCountries.map(
      (SC) => SC.label
    );
    const methodologiesArray = advanceCopying.SelectedMethodologies.map(
      (MS) => MS.value
    );
    // filtering the countries other than selected market
    const filteredCountrySpecsByInd = tempProfile.CountrySpecs.filter(
      (_, ind) => ind !== tab
    );
    let filteredCountrySpecs = [];
    filteredCountrySpecsByInd.forEach((F) => {
      if (countryArray.includes(F.label)) {
        filteredCountrySpecs.push(F);
      }
    });
    // data copying for the selected methodologies and categories at market level
    const convertedCountrySpecs = filteredCountrySpecs.map((C) => {
      C.MethodologySpecs = C.MethodologySpecs.map((M) => {
        if (methodologiesArray.includes(M.Code)) {
          const findMS =  tempCountrySpecs.MethodologySpecs.find(
                (MS) => MS.Code === M.Code
              )
           
          if (findMS) {
            advanceCopying.SelectedCategories.forEach((SC) => {
              switch (SC.value) {
                case "RFQSchema":
                  M.Variables = M.Variables.map((x) => {
                    if(x.id === "citiesCoverage") {
                      return x
                    }
                    if (x.id != "numberOfWaves") {
                      // Not to copy number of Waves
                      return _.cloneDeep(
                        findMS.Variables.find((xy) => xy.id === x.id)
                      );
                    } else {
                      // copy the number of waves variable without value
                      let temp = _.cloneDeep(
                        findMS.Variables.find((xy) => xy.id === x.id)
                      );
                      delete temp.value;
                      return temp;
                    }
                  });
                  break;
                default:
                  break;
              }
            });
            return M;
          }
          return M;
        }
        return M;
      });
      return C;
    });
    // updating the profile and project data
    let profileTemp = _.cloneDeep(profile);
    profileTemp.CountrySpecs = profileTemp.CountrySpecs.map(
      (obj) => convertedCountrySpecs.find((o) => o.id === obj.id) || obj
    );
    profileTemp.WaveSpecs = _.cloneDeep(tempProfile.WaveSpecs);
    const tempProfiles = project.CostingProfiles.map((cp) => {
      if (cp.id == profileTemp.id) return { ..._.cloneDeep(profileTemp) };
      return { ..._.cloneDeep(cp) };
    });
    dispatch(setCurrentProject({ CostingProfiles: tempProfiles }));
    setModalConfirmMethodology(false);
    toastr.success(
      "Success",
      "Data copied successfully to the selected markets"
    );
  }
};

const handlePacificModalData = (tab) => {

  const convertedCountries = profile.CountrySpecs
  .filter(
    (C, ind) => countrySpecsLength === 1 ? C  : 
    ind !== tab
  )
  .map((fc) => ({ label: fc.label, value: fc.id, ColSpan: null }));

  const  methodologiesArray =   profile.CountrySpecs[
    tab
  ].MethodologySpecs

  const intersection =  countrySpecsLength === 1 ?  handleIntersection(filteredMethodology) : []

  const uniqData = countrySpecsLength === 1 ?  handleMCategories(intersection) : []

  const methodologiesArrayData = profile.CountrySpecs?.length === 1 
  ? 
    methodologiesArray.filter(data => data.label !== singleCountryMethodologySpecs && !data.NotApplicable) 
  : 
    methodologiesArray

  const convertedMethodologies = methodologiesArrayData.map((MS) => ({
    label: MS.label,
    value: MS.Code,
    id: MS.id,
    ColSpan: null,
  }));
  const selectConvertedCategories = profile.CountrySpecs?.length === 1 ? modalPacificCategoriesSingleCountry : 
  modalPacificCategories
  const convertedCategories = selectConvertedCategories.filter((MC) => (
    countrySpecsLength === 1 ?
    uniqData.includes(MC.label) &&
    {
      label: MC.label,
      value: MC.value,
      ColSpan: null,
    } 
    : 
    {
      label: MC.label,
      value: MC.value,
      ColSpan: null,
    }
  ));
  const Countries = _.cloneDeep(convertedCountries);
  const MethodologiesSpec = _.cloneDeep(convertedMethodologies);
  const Categories = _.cloneDeep(convertedCategories);
  if (convertedCountries.length > 0) {
    setAdvanceCopying({
      Countries: Countries,
      Methodologies: MethodologiesSpec,
      Categories: Categories,
      Mode: "Selection",
      SelectedMarketInd: tab,
      SelectedCountries: Countries,
      SelectedMethodologies: MethodologiesSpec,
      SelectedCategories: Categories,
      intersectionCopying: intersection ? intersection : [],
    });
  }
  setModalMethodologiesState(true);
};

const copyFormToCountries = (tab) => {
  if (validateCopyModal()) {
    const tempProfile = { ...profile };
    // selected market data
    const tempCountrySpecs = tempProfile.CountrySpecs[tab];
    // data conversion of the selected items in Modal
    const countryArray = advanceCopying.SelectedCountries.map(
      (SC) => SC.label
    );
    const methodologiesArray = advanceCopying.SelectedMethodologies.map(
      (MS) => MS.value
    );
    // filtering the countries other than selected market
    const filteredCountrySpecsByInd = tempProfile.CountrySpecs.filter(
      (_, ind) => ind !== tab
    );
    let filteredCountrySpecs = [];
    filteredCountrySpecsByInd.forEach((F) => {
      if (countryArray.includes(F.label)) {
        filteredCountrySpecs.push(F);
      }
    });
    let _fieldingCountries = profile.FieldingCountries;
    if (_fieldingCountries && Array.isArray(_fieldingCountries))
      _fieldingCountries = _fieldingCountries.map((fc) => fc.value);
    else if (_fieldingCountries)
      _fieldingCountries = _fieldingCountries.split(",");
    delete _fieldingCountries[tempCountrySpecs.value];
    // data copying for the selected methodologies and categories at market level
    let deletedCOs = []
    const convertedCountrySpecs = filteredCountrySpecs.map((C) => {
      C.MethodologySpecs = C.MethodologySpecs.map((M) => {
        if (methodologiesArray.includes(M.Code)) {
          const findMS = tempCountrySpecs.MethodologySpecs.find(
            (MS) => MS.Code === M.Code
          );
          if (findMS) {
            advanceCopying.SelectedCategories.forEach((SC) => {
              switch (SC.value) {
                case "CostingOptions":
                  // if (_.includes(_fieldingCountries, C.CountryCode)) {
                    if (advanceCopying.SelectedCopyMethod === COPYING_METHOD_VALUES.APPEND) {
                      // Extract variables from source to tempCO
                      let tempCO = findMS.CostingOptions.map((MC) => {
                        let _MC = _.cloneDeep(MC);
                        _MC.Variables = _MC.Variables.map((V) => {
                          if(profile.CountrySpecs[0].CountryCode == C.CountryCode){
                            if(V.id == "CommericalLocalsu"){
                              V.value = "Yes"
                            }
                            if(V.id == "operationalpm"){
                              V.value = "Yes"
                            }
                          }
                          let _v = _.cloneDeep(V);
                          _v.disabled = false;
                          if (_v.id === "citiesCoverage") delete _v.value;
                          return _v;
                        });
                        delete _MC.id
                        _MC.MethodologySpecId = M.id;
                        return _MC;
                      })
                      /*
                        Check which (source, target) have more variables in costing option
                        Those who has more variable will be adding to alternate MS variable by replacing its value.
                      */
                      if (_.head(tempCO).Variables.length > _.head(M.CostingOptions).Variables.length) {
                        M.CostingOptions = M.CostingOptions.map(co => {
                          let tempVars = _.cloneDeep(_.head(tempCO).Variables)
                          let indx = 0;
                          co.Variables = tempVars.map((tv, ind) => {
                            if(tv.id === co.Variables[indx]?.id) {
                              let obj = {...tv, value: co.Variables[indx]?.value}
                              indx += 1
                              return obj
                            } else {
                              return {
                                ...tv,
                                value: undefined,
                              }
                            }
                          })
                          return co
                        })
                      } else {
                        tempCO = tempCO.map(co => {
                          let tempVars = _.cloneDeep(_.head(M.CostingOptions).Variables)
                          let indx = 0;
                          co.Variables = tempVars.map((tv, ind) => {
                            if(tv.id === co.Variables[indx]?.id) {
                              let obj = {...tv, value: co.Variables[indx]?.value}
                              indx += 1
                              return obj
                            } else {
                              return {
                                ...tv,
                                value: undefined
                              }
                            }
                          }).filter(i => !_.isEmpty(i))
                          return co
                        })
                      }
                      // Append converted costing options to targeted MS costing options
                      M.CostingOptions = M.CostingOptions.concat(tempCO)
                    } else {
                      // M.CostingOptions = findMS.CostingOptions.map(MC => {
                      // //    delete MC["id"]
                      //     let _MC = _.cloneDeep(MC)
                      //     _MC.Variables = _MC.Variables.map(V => {
                      //         let _v = _.cloneDeep(V)
                      //         if (_v.id === 'citiesCoverage') delete _v.value
                      //         return _v
                      //     });
                      //     return _MC;
                      // });
                      let costingOptBk = M.CostingOptions
                      M.CostingOptions = findMS.CostingOptions.map((MC, _ind) => {
                        let COs = findMS.CostingOptions[_ind];
                        if (COs) {
                          let _MC = _.cloneDeep(COs);
                          _MC.CommercialHours = M.CostingOptions[_ind]?.CommercialHours

                          _MC.Variables = _MC.Variables.map((V) => {
                            if(profile.CountrySpecs[0].CountryCode == C.CountryCode){
                              if(V.id == "CommericalLocalsu"){
                                V.value = "Yes"
                              }
                              if(V.id == "operationalpm"){
                                V.value = "Yes"
                              }
                            }
                            let _v = _.cloneDeep(V);
                            _v.disabled = false;
                            if (_v.id === "citiesCoverage") delete _v.value;
                            return _v;
                          });
                          _MC.id = M.CostingOptions[_ind]?.id;
                          _MC.MethodologySpecId = M.id;
                          return _MC;
                        } else {
                          return MC;
                        }
                      });
                      let temp = []
                      costingOptBk.forEach(i => {
                        if (M.CostingOptions.findIndex(c => c.id === i.id) === -1) {
                          temp.push({ ...i, toRemove: true })
                        }
                      })
                      deletedCOs = deletedCOs.concat(temp);
                    }
                  // }
                  break;
                case "RFQSchema":
                  // let _fieldingCountries = profile.FieldingCountries
                  // if (_fieldingCountries && Array.isArray(_fieldingCountries))
                  //     _fieldingCountries = _fieldingCountries.map(fc => fc.value)
                  // else if (_fieldingCountries)
                  //     _fieldingCountries = _fieldingCountries.split(",")

                  if (_.includes(_fieldingCountries, C.CountryCode))
                    M.Variables = findMS.Variables;
                  break;
                case "OpResourcesVariablesTimecost":
                  let dependency={
                    surveyProgrammingResource:"surveyProgrammingRequired",
                    surveyProgrammingResourceVendor:"surveyProgrammingRequired",
                    surveyProgrammingResourceVendorOthers:"surveyProgrammingResourceVendor",
                    dataProcessingResource:"dataProcessingRequired",
                    dataProcessingResourceVendor:"dataProcessingRequired",
                    dataProcessingResourceVendorOthers:"dataProcessingResourceVendor",
                    dataProcessingComplexity:"dataProcessingRequired",
                    translationsResource:"translations",
                    translationsResourceVendor:"translations",
                    translationsResourceVendorOthers:"translationsResourceVendor",
                    verbatimCodingResource:"verbatimTranslationRequired",
                    verbatimCodingResourceVendor:"verbatimTranslationRequired",
                    verbatimCodingResourceVendorOthers:"verbatimCodingResourceVendor",
                    chartingResource:"chartingRequired",
                    chartingResourceVendor:"chartingRequired",
                    chartingResourceVendorOthers:"chartingResourceVendor",
                    dashboardingResource:"dashboarding",
                    dashboardingResourceVendor:"dashboarding",
                    dashboardingResourceVendorOthers:"dashboardingResourceVendor",
                    qualityControl:null,
                    qualityControlComments:null,
                    dataCollectionPlatform:null,
                    platformComments:null,
                    dataProcessingPlatform:null,
                    overallOperationResourcesComments: null,
                    dataCollectionPlatformOthers:true,
                    dataProcessingPlatformOthers:true
                  }
                  let resourceDependency={
                    surveyProgrammingResourceVendor:"surveyProgrammingResource",
                    surveyProgrammingResourceVendorOthers:"surveyProgrammingResourceVendor",
                    dataProcessingResourceVendor:"dataProcessingResource",
                    dataProcessingResourceVendorOthers:"dataProcessingResourceVendor",
                    translationsResourceVendor:"translationsResource",
                    translationsResourceVendorOthers:"translationsResourceVendor",
                    verbatimCodingResourceVendor:"verbatimCodingResource",
                    verbatimCodingResourceVendorOthers:"verbatimCodingResourceVendor",
                    chartingResourceVendor:"chartingResource",
                    chartingResourceVendorOthers:"chartingResourceVendor",
                    dashboardingResourceVendor:"dashboardingResource",
                    dashboardingResourceVendorOthers:"dashboardingResourceVendor",
                    dataCollectionPlatformOthers:"dataCollectionPlatform",
                    dataProcessingPlatformOthers:"dataProcessingPlatform"
                  }
                  opsResourcesVariables.map((variable)=>{
                    if(dependency[variable]===null)
                    {
                      let index = M.OpResourcesVariablesTimecost.findIndex(x => x.id ===variable);
                      M.OpResourcesVariablesTimecost[index]=findMS.OpResourcesVariablesTimecost[index]
                    }
                    else if(dependency[variable]===true)
                    {
                      let depend=resourceDependency[variable]
                      // let depend_var=dependency[variable]
                      let dependIndex = M.OpResourcesVariablesTimecost.findIndex(x => x.id ===depend);
                      let dependObj=M.OpResourcesVariablesTimecost[dependIndex]
                      // let dependIndex_var = M.OpResourcesVariablesTimecost.findIndex(x => x.id ===depend_var);
                      // let dependObj_var=M.OpResourcesVariablesTimecost[dependIndex_var]
                      let isOtherSpecify = dependObj && dependObj["value"] ? dependObj["value"]?.some(item => item.value === "Others (Specify)") : false;
                      if(isOtherSpecify)
                      {
                        let index = M.OpResourcesVariablesTimecost.findIndex(x => x.id ===variable);
                        let obj=_.cloneDeep(findMS.OpResourcesVariablesTimecost[index])
                        if(obj.disabled===false || obj.disabled === undefined)
                          M.OpResourcesVariablesTimecost[index]=findMS.OpResourcesVariablesTimecost[index]
                      }
                      else
                      {
                        let index = M.OpResourcesVariablesTimecost.findIndex(x => x.id ===variable);
                        let obj=_.cloneDeep(M.OpResourcesVariablesTimecost[index])
                        if(obj !== undefined && !obj.disabled)
                        {
                          obj.disabled=true
                        }
                        M.OpResourcesVariablesTimecost[index]=obj
                      }
                    }
                    else
                    {
                      if(!variable.includes("ResourceVendor"))
                      {
                        let depend=dependency[variable]
                        let dependIndex = M.OpResourcesVariablesTimecost.findIndex(x => x.id ===depend);
                        let dependObj=M.OpResourcesVariablesTimecost[dependIndex]
                        if(dependObj !== undefined && dependObj["value"]==="Yes" )
                        {
                          let index = M.OpResourcesVariablesTimecost.findIndex(x => x.id ===variable);
                          let obj=_.cloneDeep(findMS.OpResourcesVariablesTimecost[index])
                          if(obj.disabled===false || obj.disabled === undefined)
                            M.OpResourcesVariablesTimecost[index]=findMS.OpResourcesVariablesTimecost[index]
                        }
                      }
                      else
                      {
                        if(variable.includes("ResourceVendorOthers"))
                        {
                          let depend=resourceDependency[variable]
                          let depend_var=dependency[variable]
                          let dependIndex = M.OpResourcesVariablesTimecost.findIndex(x => x.id ===depend);
                          let dependObj=M.OpResourcesVariablesTimecost[dependIndex]
                          let dependIndex_var = M.OpResourcesVariablesTimecost.findIndex(x => x.id ===resourceDependency[depend_var]);
                          let dependObj_var=M.OpResourcesVariablesTimecost[dependIndex_var]
                          let isOtherSpecify = dependObj && dependObj["value"] ? dependObj["value"]?.some(item => item.value === "Others (Specify)") && (dependObj_var["value"]==="External" || dependObj_var["value"]==="External vendor") : false;
                          if(isOtherSpecify)
                          {
                            let index = M.OpResourcesVariablesTimecost.findIndex(x => x.id ===variable);
                            let obj=_.cloneDeep(findMS.OpResourcesVariablesTimecost[index])
                            if(obj.disabled===false || obj.disabled === undefined)
                              M.OpResourcesVariablesTimecost[index]=findMS.OpResourcesVariablesTimecost[index]
                          }
                          else
                          {
                            let index = M.OpResourcesVariablesTimecost.findIndex(x => x.id ===variable);
                            let obj=_.cloneDeep(M.OpResourcesVariablesTimecost[index])
                            if(obj !== undefined && !obj.disabled)
                            {
                              obj.disabled=true
                            }
                            M.OpResourcesVariablesTimecost[index]=obj
                          }
                        }
                        else
                        {
                          let depend=resourceDependency[variable]
                          let dependIndex = M.OpResourcesVariablesTimecost.findIndex(x => x.id ===depend);
                          let dependObj=M.OpResourcesVariablesTimecost[dependIndex]
                          if(dependObj !== undefined && (dependObj["value"]==="External" || dependObj["value"]==="External vendor"))
                          {
                            let index = M.OpResourcesVariablesTimecost.findIndex(x => x.id ===variable);
                            let obj=_.cloneDeep(findMS.OpResourcesVariablesTimecost[index])
                            if(obj.disabled===false || obj.disabled === undefined)
                              M.OpResourcesVariablesTimecost[index]=findMS.OpResourcesVariablesTimecost[index]
                          }
                          else
                          {
                            let index = M.OpResourcesVariablesTimecost.findIndex(x => x.id ===variable);
                            let obj=_.cloneDeep(M.OpResourcesVariablesTimecost[index])
                            if(obj !== undefined && !obj.disabled)
                            {
                              obj.disabled=true
                            }
                            M.OpResourcesVariablesTimecost[index]=obj
                          }
                        }
                      }
                    }
                  })
                  break;
                case "Responsibilities":
                  M.Responsibilities = findMS.Responsibilities;
                  break;
                default:
                  break;
              }
            });
            return M;
          }
          return M;
        }
        return M;
      });
      return C;
    });
    // updating the profile and project data
    let profileTemp = _.cloneDeep(profile  );
    profileTemp.deletedCostingOptions = profileTemp.deletedCostingOptions ? profileTemp.deletedCostingOptions.concat(deletedCOs) : deletedCOs
    // commenting chethan's code as it is replacing the countries with the countries to be copied
    // profileTemp.CountrySpecs = _.cloneDeep(convertedCountrySpecs)
    // added by Ravi Punnam
    profileTemp.CountrySpecs = profileTemp.CountrySpecs.map(
      (obj) => convertedCountrySpecs.find((o) => o.id === obj.id) || obj
    );
    const tempProfiles = project.CostingProfiles.map((cp) => {
      if (cp.id == profileTemp.id) return { ..._.cloneDeep(profileTemp) };
      return { ..._.cloneDeep(cp) };
    });
    dispatch(setCurrentProject({ CostingProfiles: tempProfiles }));
    dispatch(currentCostingActions.updateProfile(profileTemp));
    setModalConfirmMethodology(false);
    toastr.success(
      "Success",
      "Data copied successfully to the selected markets"
    );
  }
};

const COPYING_METHOD_VALUES = Object.freeze({
  APPEND: "APPEND",
  OVERWRITE: "OVERWRITE"
})

const selectedMethodologiesFunc = (val) => {

  if(val && advanceCopying.SelectedCategories.length !== val.length) {

    const methodologiesLabel = val.map(data => data.label)
    

    const filteredselectedMethodologies = filteredMethodology.filter(item => methodologiesLabel.includes(item.name))


    const intersection = handleIntersection([filteredMethodology[activeTabSM], ...filteredselectedMethodologies])

    const uniqData = handleMCategories(intersection)

    const selectConvertedCategories = modalCategoriesSingleCountry 
    
    const convertedCategories = selectConvertedCategories.filter((MC) => (
      uniqData.includes(MC.label) &&
      {
        label: MC.label,
        value: MC.value,
        ColSpan: null,
      }
    ));

    const nonRelatedCategories = advanceCopying.SelectedCategories.filter((MC) => (
      uniqData.includes(MC.label) &&
      {
        label: MC.label,
        value: MC.value,
        ColSpan: null,
      }
    ));

    setAdvanceCopying((prevState) => ({
      ...prevState,
      SelectedMethodologies: val ? val : [],
      SelectedCategories: nonRelatedCategories,
      Categories: convertedCategories,
    }));
  } else {

  setAdvanceCopying((prevState) => ({
    ...prevState,
    SelectedMethodologies: val ? val : [],
    SelectedCategories: !val ? [] : advanceCopying.SelectedCategories,
    Categories: !val ? [] : advanceCopying.Categories,
  }));
}

 }

  const validateForms = (_profile) => {
    let errorFieldsObj = {}; // object to store missing required fields - RFQ Form
    let costingProfile = _profile ? _profile : profile;
    let isValid = true;
    let countrySpecs = [...costingProfile.CountrySpecs];
    var allCostingOptionsUsed = [];
    let unavialable = [];
    let methodologyObj = {};
    let methodologyCountryObj = {};
    let isFieldsValid = true;
    let fieldingCountries = [];
    let isTrackingProjectNonPacific = {};

    if (Array.isArray(profile.FieldingCountries))
      fieldingCountries = profile.FieldingCountries.map((fc) => fc.value);
    else fieldingCountries = profile.FieldingCountries.split(",");

    countrySpecs.map((cs) => {
      if (cs.MethodologySpecs) {
        cs.MethodologySpecs?.filter((ms) => !ms.NotApplicable)?.map((ms) => {
          methodologyObj[ms.id] = ms.Label;
          methodologyCountryObj[ms.id] = cs.CountryName;
          ms.CostingOptions?.map((co) => {
            co.CostingOptionSchema?.map((cps) => {
              if (!_.includes(allCostingOptionsUsed, cps.id) && cps.id) {
                allCostingOptionsUsed.push(cps.id);
              }
            });
          });
          if (
            ms?.RFQSchema?.required &&
            _.includes(fieldingCountries, cs.CountryCode)
          ) {
            if (!ms.RFQData) {
              isValid = false;
              isFieldsValid = false;
              unavialable = ms.RFQSchema?.required;
            } else {
              let allRequiredFields = ms.RFQSchema?.required;
              if (
                (_.includes(pacificMarkets, project?.CommissioningCountry) && project.CostingTemplateVersion != "v2.0") &&
                _.includes(SHOPPERS_METHODOLOGIS, ms.Code) &&
                allRequiredFields?.length === 0
              ) {
                allRequiredFields = ms.Variables.filter(i => i.mandatory == true && !i.disabled).map(i => i.id)
              }
              if (
                ms.RFQData["externalSampleProvider"] &&
                ms.RFQData["externalSampleProvider"] != "Applicable"
              )
                allRequiredFields = allRequiredFields.filter(
                  (rf) => rf != "incidenceRate"
                );
              let existingProperties = Object.keys(
                ms.RFQSchema?.properties
              ).filter((f) => !ms.RFQSchema.properties[f].Default);

              let finalFields = allRequiredFields?.filter((arf) =>
                _.includes(existingProperties, arf)
              );
              unavialable = finalFields?.filter(
                (req) => req && (!ms.RFQData[req] || ms.RFQData[req] == "0")
              );

              if (
                (_.includes(pacificMarkets, project?.CommissioningCountry) && project.CostingTemplateVersion != "v2.0") &&
                ms.RFQData["trackingProject"] === "Yes" &&
                (!ms.RFQData["numberOfWaves"] ||
                  ms.RFQData["numberOfWaves"] < 2)
              ) {
                unavialable.push("numberOfWaves");
              } else if (
                ms.CostingOptions.length &&
                ms.CostingOptions.find(
                  (co) => co.CostingOptionData?.trackingProject === "Yes"
                )
              ) {
                isTrackingProjectNonPacific[ms.id] = true;
              }

              let haveInCostingOptions = ms.CostingOptions?.map(
                (co) => co?.CostingOptionData
              )?.filter(
                (co) =>
                  finalFields
                    ?.map((ff) => co && co[ff] && co[ff] != 0)
                    .filter((f) => f).length
              );
              if (unavialable.length && !haveInCostingOptions.length) {
                isValid = false;
                isFieldsValid = false;
              }
            }
          }
          if (!isFieldsValid && _.includes(fieldingCountries, cs.CountryCode)) {
            let fields = unavialable.map((obj) =>
              obj === "numberOfWaves"
                ? ms.SetupSchema?.properties[obj]?.title
                : ms.RFQSchema.properties[obj]?.title
            );
            if (!errorFieldsObj[cs.CountryName])
              errorFieldsObj[cs.CountryName] = {};

            if (!errorFieldsObj[cs.CountryName].RFQ)
              errorFieldsObj[cs.CountryName].RFQ = {};

            if (!errorFieldsObj[cs.CountryName].RFQ[ms.Label])
              errorFieldsObj[cs.CountryName].RFQ[ms.Label] = {};

            errorFieldsObj[cs.CountryName].RFQ[ms.Label] = fields;
          }
          isFieldsValid = true;
        });

        if (!_.includes(pacificMarkets, project?.CommissioningCountry)||
        (_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0")
        ) {
          cs.MethodologySpecs?.filter((ms) => !ms.NotApplicable).forEach(
            (mc) => {
              if (mc) {
                mc.CostingOptions.forEach((me) => {
                  const mandate = me.Variables.filter(
                    (mt) => mt.mandatory == true && !mt.disabled
                  ).map((i) => i.label);
                  const dataValues = me.Variables.filter(
                    (mt) => mt.mandatory == true && !mt.disabled
                  );
                  const labelData = dataValues.filter((dt) => dt.label);

                  if (!errorFieldsObj[cs.CountryName])
                    errorFieldsObj[cs.CountryName] = {};

                  if (!errorFieldsObj[cs.CountryName].CostingOptions)
                    errorFieldsObj[cs.CountryName].CostingOptions = {};

                  if (!errorFieldsObj[cs.CountryName].CostingOptions[mc.Label])
                    errorFieldsObj[cs.CountryName].CostingOptions[mc.Label] =
                      {};

                  if(me.Selected === 'true' || me.Selected){
                    if (
                      Object.keys(me.CostingOptionData).length === 0 &&
                      mandate.length > 0
                    ) {
                      isValid = false;
                      errorFieldsObj[cs.CountryName].CostingOptions[mc.Label] =
                        mandate;
                    } else {
                      const filterData = dataValues.filter((x) => x.value && x?.value?.length !== 0 );
                      const unfilterData = dataValues
                        .filter((xy) => !xy.value || xy?.value?.length === 0)
                        .map((t) => t.label);
  
                      if (filterData.length !== dataValues.length) {
                        isValid = false;
                        errorFieldsObj[cs.CountryName].CostingOptions[mc.Label] =
                          unfilterData;
                      }
                    }
                  }
                });
              }
            }
          );
        }
      }
    });

    if (costingProfile?.WaveSpecs?.length) {
      let validMethIds = [];
      costingProfile.CountrySpecs?.map((cs) => {
        cs.MethodologySpecs?.filter((ms) => !ms.NotApplicable)
          ?.map((ms) => ms.id)
          ?.map((ms) => {
            if (!_.includes(validMethIds, ms)) validMethIds.push(ms);
          });
      });
      let wavespecs = [
        ...costingProfile.WaveSpecs?.filter((ws) =>
          _.includes(validMethIds, ws.MethodologySpecId)
        ),
      ];
      if (wavespecs.length) {
        let OpsTimecostSchema = {};
        costingProfile.CountrySpecs?.map((cs) => {
          cs.MethodologySpecs?.map(ms => {
            wavespecs.map((ws) => {  
              if(ms.id === ws.MethodologySpecId){
                const opsVariable = formatSchemaByVariables(ms.OpsTimecostSchema, ms.OpResourcesVariablesTimecost);
                OpsTimecostSchema[ws.MethodologySpecId] = opsVariable;
              }
            })
          })
        })
        wavespecs.map((ws) => {  
          // ws = _.head(wavespecs)
          let finalPropsToValidate = [];
          ws.OpsTimecostSchema = OpsTimecostSchema[ws.MethodologySpecId];
          ws.OpsTimecostSchema?.required?.map((prop) => {
            let currentProp = ws.OpsTimecostSchema.properties[prop];
            // if(currentProp.title === "Data Processing Complexity") {
            //   return;
            // }

            if (
              currentProp &&
              (!currentProp.dependentOn ||
              !currentProp.disabled) &&
              !currentProp.selected
            ) {
              if (
                prop === "numberofExcelFile" &&
                ws.OpsResourcesData &&
                ws.OpsResourcesData["chartingComplexity"] !==
                  prop
              )
                return;
              if (
                prop === "numberOfModules" &&
                ws.OpsResourcesData &&
                ws.OpsResourcesData["numberOfModules"] >= 0
              )
              return;
              if (
                prop === "numberOfOpenEnded" &&                 
                ws.OpsResourcesData &&
                ws.OpsResourcesData["numberOfSemiOpenEnded"] >= 0
              )
              return;
              if (
                prop === "numberOfSemiOpenEnded" &&               
                ws.OpsResourcesData &&
                ws.OpsResourcesData["numberOfSemiOpenEnded"] >= 0
              )
                return;
                if (

                                  prop === "L2breakouts" &&
                  
                                  ws.OpsResourcesData &&
                  
                                  ws.OpsResourcesData["L2breakouts"] >= 0
                  
                                )
                  
                                  return;
                  
                                if (
                  
                                  prop === "L3breakouts" &&
                  
                                  ws.OpsResourcesData &&
                  
                                  ws.OpsResourcesData["L3breakouts"] >= 0
                  
                                )
                  
                                  return;
                if (
                  prop === "numberOfOpenEndedToCode" &&
                  ws.OpsResourcesData &&
                  ws.OpsResourcesData["codingRequired"] === "No"
                )
                  return;
              if (
                (!_.includes(opsResourcesVariables, prop) &&
                  !_.includes(allCostingOptionsUsed, prop)) ||
                (_.includes(opsResourcesVariables, prop) &&
                  userRecord.CanEditOpsResources)
              )
                if (
                  prop === "surveyProgrammingResource" &&
                  ws.OpsResourcesData &&
                  ws.OpsResourcesData["surveyProgrammingRequired"] !== "Yes"
                )
                  //todo: not sure why it is been done in this way in CountryMethodologyTabsFinal
                  //copied the same, but need to be fixed in a proper way
                  return;
                  
                  if (
                    prop === "surveyProgrammingResource" &&
                    Object.keys(ws.OpsResourcesData).length == 0
                  )
                    //todo: not sure why it is been done in this way in CountryMethodologyTabsFinal
                    //copied the same, but need to be fixed in a proper way
                    return;

              if (
                _.includes(opsResourcesVariables, prop) &&
                !userRecord.CanEditOpsResources
              )
                return;
                if(!finalPropsToValidate.includes(prop)){
                  finalPropsToValidate.push(prop);
                }
              
            } else {
              if (
                !(currentProp?.dependentOn?.fields?.length > 1) &&
                currentProp &&
                !currentProp.disabled &&
                !currentProp.selected
              ) {
                 
                currentProp?.dependentOn?.fields?.map((_dependentField) => {
                  let depProp = _dependentField;
                  if (depProp) {
                    let parentProp = {};
                    Object.keys(ws.OpsTimecostSchema.properties).map((pp) => {
                      if (
                        ws.OpsTimecostSchema.properties[pp].title ==
                        depProp.fieldName
                      ) {
                        parentProp = pp;
                      }
                    });
                    if (
                      prop === "approxPercentageChange" &&
                      wavespecs.length < 2
                    )
                      return;
                    

                    if (
                      parentProp &&
                      ws.OpsResourcesData &&
                      ws.OpsResourcesData[parentProp] &&
                      ws.OpsResourcesData[parentProp] != "No" &&
                      !_.includes(finalPropsToValidate, prop)
                    ) {
                      if (
                        (!_.includes(opsResourcesVariables, prop) &&
                          !_.includes(allCostingOptionsUsed, prop)) ||
                        (_.includes(opsResourcesVariables, prop) &&
                          userRecord.CanEditOpsResources)
                      ) {

                        if(!finalPropsToValidate.includes(prop)){
                          finalPropsToValidate.push(prop);
                        }
                      }
                    }
                  }
                });
              }
            }
          });

          let finalErrorFields = [];
          
          const numberOfWaves = ws.OpsResourcesData["numberOfWaves"]
            ? ws.OpsResourcesData["numberOfWaves"]
            : 0;

          if (
            isTrackingProjectNonPacific[ws.MethodologySpecId] &&
            ws.OpsTimecostSchema?.properties?.["numberOfWaves"] &&
            numberOfWaves < 2
          ) {
            isValid = false;
            isFieldsValid = false;
            if(finalErrorFields.includes(ws.OpsTimecostSchema?.properties?.["numberOfWaves"]?.title)
              && !finalPropsToValidate.includes("numberOfWaves")) {
              finalErrorFields.push(
                ws.OpsTimecostSchema?.properties?.["numberOfWaves"]?.title
              );
            }
            
          }
          const numberOfLicenses = ws.OpsResourcesData["NumberOfLicenses"]
          ?ws.OpsResourcesData["NumberOfLicenses"]:0;

          if(  
            ws.OpsTimecostSchema?.properties?.["NumberOfLicenses"] && 
            numberOfLicenses>1000
            ){
            isValid=false;
            isFieldsValid=false;
            finalErrorFields.push(ws.OpsTimecostSchema?.properties?.["NumberOfLicenses"]?.title)
          
          }
          
          const numberOfModules = ws.OpsResourcesData["numberOfModules"]
          ?ws.OpsResourcesData["numberOfModules"]:0;

          if(  
            ws.OpsTimecostSchema?.properties?.["numberOfModules"]  && numberOfModules>999
            ){
            isValid=false;
            isFieldsValid=false;
            finalErrorFields.push(ws.OpsTimecostSchema?.properties?.["numberOfModules"]?.title) && 
            finalErrorFields.push(" - Value should be between " +ws.OpsTimecostSchema?.properties?.["numberOfModules"].minimum +"-" +ws.OpsTimecostSchema?.properties?.["numberOfModules"].maximum)
          
          }
          const numberOfDashboard = ws.OpsResourcesData["numberOfDashboard"]
          ?ws.OpsResourcesData["numberOfDashboard"]:0;

          if(  
            ws.OpsTimecostSchema?.properties?.["numberOfDashboard"] && 
            numberOfDashboard>120
            ){
            isValid=false;
            isFieldsValid=false;
            finalErrorFields.push(ws.OpsTimecostSchema?.properties?.["numberOfDashboard"]?.title) &&  finalErrorFields.push(ws.OpsTimecostSchema?.properties?.["numberOfDashboard"]?.message)   
          }
          const L2Breakouts = ws.OpsResourcesData["L2breakouts"]

            ? ws.OpsResourcesData["L2breakouts"]

            : 0;




          if (

            ws.OpsTimecostSchema?.properties?.["L2breakouts"] &&

            L2Breakouts > 100

          ) {

            isValid = false;

            isFieldsValid = false;

            finalErrorFields.push(

              ws.OpsTimecostSchema?.properties?.["L2breakouts"]?.title

            );

          }

          const L3Breakouts = ws.OpsResourcesData["L3breakouts"]

            ? ws.OpsResourcesData["L3breakouts"]

            : 0;




          if (

            ws.OpsTimecostSchema?.properties?.["L3breakouts"] &&

            L3Breakouts > 100

          ) {

            isValid = false;

            isFieldsValid = false;

            finalErrorFields.push(

              ws.OpsTimecostSchema?.properties?.["L3breakouts"]?.title

            );

          }
          finalPropsToValidate.map((ftv) => {
            // const combinedObj = mergedObjects(ws.SetupSchema, ws.OpsTimecostSchema);
            if (
              !ws.OpsResourcesData ||
              (ws.OpsResourcesData &&
                (!ws.OpsResourcesData[ftv] || ws.OpsResourcesData[ftv] == "0"))
            ) {
              let excludeError = [];
              
              if (!(excludeError.includes(ftv))) {
                if (isOpsvalidation.includes(ws.MethodologySpecId)) {
                isValid = false;              
                isFieldsValid = false;              
                finalErrorFields.push(ws.OpsTimecostSchema.properties[ftv].title);
                }
              }
            }
          });
          if (!isFieldsValid) {
            if (!errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]])
              errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]] = {};
			  
            if (!errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD)
              errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD =
                {};

            if (
              !errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                methodologyObj[ws.MethodologySpecId]
              ]
            )
              errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                methodologyObj[ws.MethodologySpecId]
              ] = {};

              if (_.includes(pacificMarkets, project.CommissioningCountry) && project.CostingTemplateVersion != "v2.0") {
                if (
                  !errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                    methodologyObj[ws.MethodologySpecId]
                  ].NO
                )
                  errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                    methodologyObj[ws.MethodologySpecId]
                  ].NO = {};
  
                if (
                  !errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                    methodologyObj[ws.MethodologySpecId]
                  ].NO[ws.WaveNumber]
                )
                  errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                    methodologyObj[ws.MethodologySpecId]
                  ].NO[ws.WaveNumber] = {};
  
                errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                  methodologyObj[ws.MethodologySpecId]
                ].NO[ws.WaveNumber] = finalErrorFields;
              } else {
                errorFieldsObj[methodologyCountryObj[ws.MethodologySpecId]].SD[
                  methodologyObj[ws.MethodologySpecId]
                ] = finalErrorFields;
              }
          }
          isFieldsValid = true;
        });
      }
    }
    
    if (!isValid) {
      if (
        document.getElementById("OpsResourcesFinalForm")
      ) {
        // document.getElementById("RFQFinalForm")?.requestSubmit();
        document.getElementById("OpsResourcesFinalForm")?.requestSubmit();
        // document.getElementById("CostiongOptionsForm")?.requestSubmit();
      }
      setErrorObj(errorFieldsObj);
      handleOpen(true);
    }
    return isValid;
  };

  function saveDraft(notAPI) {
    dispatch(setOpportunityValue(""));
    // validateCO();
    // validateRFQ();
    validateSUD();
    if (isvalid) {
      isvalid = true;
      let _profile = { ...profile }; //_.last(project.CostingProfiles) //currentCosting//_.last(project.CostingProfiles)

      // _profile = { ..._profile, ...currentCosting }
      _profile.AdditionalSheetId = profile.AdditionalSheetId
        ? profile.AdditionalSheetId
        : null;
      _profile.CostingType = profile.CostingType
        ? profile.CostingType
        : null;
      _profile.CostingsSheetId = profile.CostingsSheetId
        ? profile.CostingsSheetId
        : null;
        
      let _project = _.cloneDeep(project)
      if(_project.CostingProfiles){
        delete _project.CostingProfiles
      }
          _profile.Project = _project;
        
      if (!_.includes(pacificMarkets, project.CommissioningCountry) ||
       (_.includes(pacificMarkets, project.CommissioningCountry) && project.CostingTemplateVersion == "v2.0")) {
        _profile.CountrySpecs = _profile.CountrySpecs?.map((cs) => {
          let requiredcountry = {
            ...profile.CountrySpecs.find((CS) => CS.id == cs.id),
          };
          if (requiredcountry) {
            cs.MethodologySpecs = cs.MethodologySpecs.map((ms) => {
              let requiredMeth = {
                ...requiredcountry.MethodologySpecs.find(
                  (MS) => MS.id == ms.id
                ),
              };
              // delete requiredMeth.CostingOptions
              // delete requiredMeth.NotApplicable
              // delete requiredMeth.Responsibilities
              // delete requiredMeth.ResponsibilityData
              // delete requiredMeth.ResponsibilitySchema
              // delete requiredMeth.SetupData
              // delete requiredMeth.SetupSchema
              // delete requiredMeth.RFQData
              // delete requiredMeth.RFQSchema
              return { ...ms, ...requiredMeth };
            });
          }
          delete requiredcountry.MethodologySpecs;
          return { ...cs, ...requiredcountry };
        });
      }
      if (!_.includes(pacificMarkets, project.CommissioningCountry)  ||
       (_.includes(pacificMarkets, project.CommissioningCountry) && project.CostingTemplateVersion == "v2.0")) {
        _profile.WaveSpecs = _.cloneDeep(profile.WaveSpecs);
      }
      if (_profile.WaveSpecs)
        _profile.WaveSpecs = calcAll(
          project,
          profile,
          profile.CountrySpecs,
          _profile.WaveSpecs,
          rateCards,
          pacificMarkets
        )?.waveSpecs;

      var WaveSpecsAll = _profile?.WaveSpecs || [];
      // WaveSpecsAll = calcAll(project, currentCosting, currentCosting.CountrySpecs, WaveSpecsAll, rateCards, pacificMarkets)?.waveSpecs

      let temporaryWavesForValidation = [];
      let countIteration=0
      if(typeof profile.ProductTypeData !=="String")
      {
        var ProductTypeData=profile.ProductTypeData
      }
      else
      {
        while(typeof profile.ProductTypeData === "string"){
          profile.ProductTypeData = JSON.parse(profile.ProductTypeData)
        }
        // if(typeof JSON.parse( currentCosting.ProductTypeData) === "string"){
          
        //   var ProductTypeData=JSON.parse(JSON.parse( currentCosting.ProductTypeData))
        // }
        // else{
          var ProductTypeData=JSON.parse( profile.ProductTypeData)
        // }
      }
      _profile?.CountrySpecs?.forEach((cs,index) => {
        cs?.MethodologySpecs?.forEach((ms) => {
          ms.RFQSchema = mapperFunctions.formatSchemaByVariables(
            _.cloneDeep(ms?.RFQSchema),
            _.cloneDeep(ms?.Variables)
          );
          ms.RFQData = _.cloneDeep(ms?.Variables)
            ? mapperFunctions.getDataJSON(_.cloneDeep(ms?.Variables))
            : mapperFunctions.getDataJSON(_.cloneDeep([]));
          //let WaveSpecs=ms.WaveSpecs||[];
          var costingOptionWaves;
          if (ms?.CostingOptions.length)
            costingOptionWaves = ms?.CostingOptions[0]?.Variables.filter(
              (x) => x?.label == "Number of Waves"
            );
          else {
            costingOptionWaves = ms?.Variables
              ? ms.Variables.filter((x) => x?.id == "numberOfWaves")
              : [];
          }
          var WaveSpecs = WaveSpecsAll.filter(
            (x) => x.MethodologySpecId == ms.id
          );
          var waveSize =
            costingOptionWaves.length > 0
              ? costingOptionWaves[0].value
              : (ms?.OpResourcesVariables.filter(
                  (x) => x.label == "Number of Waves"
                ).length > 0
                  ? ms?.OpResourcesVariables.filter(
                      (x) => x.label == "Number of Waves"
                    )[0].value
                  : 1) || 1;
          let waves =
            WaveSpecs.length > 0 && WaveSpecs.length == waveSize
              ? WaveSpecs.length
              : waveSize;
          if (!waves) {
            waves = 1;
          }
          if (!_.includes(pacificMarkets, project.CommissioningCountry)  ||
           (_.includes(pacificMarkets, project.CommissioningCountry) && project.CostingTemplateVersion == "v2.0")) {
            var tempWaveSpecs = [];
            for (let i = 0; i < waves; i++) {
              var id =
                WaveSpecs.length > 0
                  ? WaveSpecs[i]
                    ? WaveSpecs[i].id
                    : null
                  : null;
              tempWaveSpecs.push({
                id: id,
                WaveNumber: i + 1,
                ResponsibilitySchema: _.cloneDeep(ms.Responsibilities),
                OpsResourcesSchema: mapperFunctions.formatSchemaByVariables(
                  _.cloneDeep(ms.SetupSchema),
                  _.cloneDeep(ms.OpResourcesVariables)
                ),
                OpsTimecostSchema: mapperFunctions.formatSchemaByVariables(
                  _.cloneDeep(ms.OpsTimecostSchema),
                  _.cloneDeep(ms.OpResourcesVariablesTimecost)
                ),
                OpsResourcesData: mapperFunctions.getDataJSON(
                  _.cloneDeep(ms.OpResourcesVariablesTimecost)
                ),
                MethodologySpecId: ms.id,
                CostingProfileId: _profile?.id,
              });

              ms["WaveSpecs"] = tempWaveSpecs;
              temporaryWavesForValidation = [
                ...temporaryWavesForValidation,
                ...tempWaveSpecs,
              ];
            }

            ///For Wave Specs Deletion
            if (WaveSpecs.length > waves) {
              for (let i = waves; i < WaveSpecs.length; i++) {
                var id = WaveSpecs[i].id;
                tempWaveSpecs.push({
                  id: id,
                  toRemove: true,
                  WaveNumber: i + 1,
                  ResponsibilitySchema: _.cloneDeep(ms.Responsibilities),
                  OpsResourcesSchema: mapperFunctions.formatSchemaByVariables(
                    _.cloneDeep(ms.SetupSchema),
                    _.cloneDeep(ms.OpResourcesVariables)
                  ),
                  OpsTimecostSchema: mapperFunctions.formatSchemaByVariables(
                    _.cloneDeep(ms.OpsTimecostSchema),
                    _.cloneDeep(ms.OpResourcesVariablesTimecost)
                  ),
                  OpsResourcesData: mapperFunctions.getDataJSON(
                    _.cloneDeep(ms.OpResourcesVariablesTimecost)
                  ),
                  MethodologySpecId: ms.id,
                  CostingProfileId: _profile?.id,
                });
                ms["WaveSpecs"] = tempWaveSpecs;
                temporaryWavesForValidation = [
                  ...temporaryWavesForValidation,
                  ...tempWaveSpecs,
                ];
              }
            }
          } else {
            //todo: wave specs deletion and updation
            //when _.groupBy(profile.WaveSpecs).length>tempWaveSpecs delete respective number of wavespecs
            //for that methodology accordingly, likewise _.groupBy(profile.WaveSpecs).length<tempWaveSpecs
            //create wavespecs, if you fill wavespecs and without IDs it'll create in backend
            var pacificTempWaves = [];
            let waveMethCount = profile.WaveSpecs.filter(
              (x) => x.MethodologySpecId === ms.id
            );
            // to add
            if (waveMethCount.length < waves) {
              for (let i = 0; i < waves; i++) {
                var id =
                  waveMethCount.length > 0
                    ? waveMethCount[i]
                      ? waveMethCount[i].id
                      : null
                    : null;
                pacificTempWaves.push({
                  id: id,
                  WaveNumber: i + 1,
                  ResponsibilitySchema: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].ResponsibilitySchema)
                    : _.cloneDeep(ms.Responsibilities),
                  CommercialHoursData: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].CommercialHoursData)
                    : _.cloneDeep(ms?.CommercialHoursData),
                  OpsResourcesSchema: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].OpsResourcesSchema)
                    : mapperFunctions.formatSchemaByVariables(
                        _.cloneDeep(ms.SetupSchema)
                      ),
                  OpsTimecostSchema: waveMethCount[i]
                  ? _.cloneDeep(waveMethCount[i].OpsTimecostSchema)
                  : mapperFunctions.formatSchemaByVariables(
                      _.cloneDeep(ms.OpsTimecostSchema)
                    ),
                  OpsResourcesData: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].OpsResourcesData)
                    : {},
                  MethodologySpecId: ms.id,
                  CostingProfileId: profile?.id,
                });
                pacificTempWaves = calcAll(
                  project,
                  profile,
                  profile.CountrySpecs,
                  pacificTempWaves,
                  rateCards,
                  pacificMarkets
                )?.waveSpecs;
                ms["WaveSpecs"] = pacificTempWaves;

                temporaryWavesForValidation = [
                  ...temporaryWavesForValidation,
                  ...pacificTempWaves,
                ];
              }
            }
            if (pacificTempWaves.length === 0) {
              profile.WaveSpecs = calcAll(
                project,
                profile,
                profile.CountrySpecs,
                profile.WaveSpecs,
                rateCards,
                pacificMarkets
              )?.waveSpecs;
              let data = profile.WaveSpecs.filter(
                (x) => x.MethodologySpecId === ms.id
              ).map((x) => {
                return { ...x };
              });
              ms["WaveSpecs"] = data;
              temporaryWavesForValidation = [
                ...temporaryWavesForValidation,
                ...data,
              ];
            }

            // to Remove
            if (waveMethCount.length > waves) {
              for (let i = waves; i < waveMethCount.length; i++) {
                var id = waveMethCount[i].id;
                pacificTempWaves.push({
                  id: id,
                  toRemove: true,
                  WaveNumber: i + 1,
                  ResponsibilitySchema: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].ResponsibilitySchema)
                    : _.cloneDeep(ms.Responsibilities),
                  CommercialHoursData: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].CommercialHoursData)
                    : _.cloneDeep(ms?.CommercialHoursData),
                  OpsResourcesSchema: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].OpsResourcesSchema)
                    : mapperFunctions.formatSchemaByVariables(
                        _.cloneDeep(ms.SetupSchema)
                      ),

                  OpsTimecostSchema: waveMethCount[i]
                  ? _.cloneDeep(waveMethCount[i].OpsTimecostSchema)
                  : mapperFunctions.formatSchemaByVariables(
                      _.cloneDeep(ms.OpsTimecostSchema)
                    ),
                  OpsResourcesData: waveMethCount[i]
                    ? _.cloneDeep(waveMethCount[i].OpsResourcesData)
                    : {},
                  MethodologySpecId: ms.id,
                  CostingProfileId: _profile?.id,
                });
                pacificTempWaves = calcAll(
                  project,
                  profile,
                  profile.CountrySpecs,
                  pacificTempWaves,
                  rateCards,
                  pacificMarkets
                )?.waveSpecs;
                ms["WaveSpecs"] = pacificTempWaves;
                temporaryWavesForValidation = [
                  ...temporaryWavesForValidation,
                  ...pacificTempWaves,
                ];
              }
            }
          }
          if (!_.includes(pacificMarkets, project.CommissioningCountry) ||
           (_.includes(pacificMarkets, project.CommissioningCountry) && project.CostingTemplateVersion == "v2.0"))
            ms.CostingOptions.forEach((co, indexCO) => {
              co["id"] = co.id;
              co["Name"] = co.label;
              co["Selected"] = co.isSelected?co.isSelected:false;
              co["CostingOptionSchema"] = _.cloneDeep(co.Variables);
              co["CostingOptionData"] = mapperFunctions.getDataJSON(
                _.cloneDeep(co.Variables)
              );

              if(!co.OpportunityLineItemID)
              {
                let oppID=co?.CostingOptionSchema[0]?.options[0]?.OpportunityLineItemID
                console.log("oppID",oppID)
                co["OpportunityLineItemID"]=oppID
              }
              if(!co?.CostingOptionData?.producttype)
              {
                co.CostingOptionData.producttype=co?.CostingOptionSchema[0]?.options[0]?.Productname
              } 
              if(!co?.CostingOptionData?.Booster)
              {
                co.CostingOptionData.Booster="No"
              } 
              if(!("CommericalLocalsu" in
                co["CostingOptionData"]) && (profile.createdAt >= "2023-08-05T10:43:12.000Z") ){
                  if(index === 0){
                    co["CostingOptionData"]={...co["CostingOptionData"],
                      "CommericalLocalsu" : "Yes",
                      "operationalpm" : "Yes"}
                    }
                    else{
                      co["CostingOptionData"]={
                        ...co["CostingOptionData"],
                        "CommericalLocalsu" : "No",
                        "operationalpm" : "Yes"}
                      }
                }
                  
                  
              co["MethodologySpecId"] = ms.id;
              
                if(!co.OpportunityLineItemID)
                {
                  co["OpportunityLineItemID"] = co.OpportunityLineItemID;
                }
                // ProductTypeData[countIteration].OpportunityLineItemID
                // countIteration++;

            });
        });
        // countIteration=0
      });
      //commercial costs recounting if there any and helps in case if any costing option got deleted
      Object.keys(commercialFields).map((cf) => {
        _profile[commercialFields[cf]] = 0;
      });
      _profile.CountrySpecs = _profile.CountrySpecs.map((cs) => {
        Object.keys(commercialFields).map((cf) => {
          cs[commercialFields[cf]] = 0;
        });
        cs.MethodologySpecs = cs.MethodologySpecs?.map((ms) => {
          ms.CostingOptions?.filter((co) => co.Selected)?.map((co) => {
            Object.keys(commercialFields).map((cf) => {
              cs[commercialFields[cf]] =
                (cs[commercialFields[cf]] ? cs[commercialFields[cf]] : 0) +
                (co[commercialFields[cf]] ? co[commercialFields[cf]] : 0);
            });
          });
          return { ...ms };
        });
        Object.keys(commercialFields).map((cf) => {
          _profile[commercialFields[cf]] =
            (_profile[commercialFields[cf]]
              ? _profile[commercialFields[cf]]
              : 0) + cs[commercialFields[cf]];
        });
        return { ...cs };
      });

      if (_profile.WaveSpecs.length > 0) {
        let WaveSpecs = _profile.WaveSpecs.filter(ws => ws.NotApplicable != true)
        const noWaves = Object.values(
          _.groupBy(WaveSpecs, "MethodologySpecId")
        ).map((O) => O.length);
        _profile.NumberOfWaves = _.max(noWaves);
      }
      if (project.ProjectName !== profile.Project.ProjectName) {
        profile.Project.ProjectName = project.ProjectName;
      }
      dispatch(valueChangedInRFQ(false));
      _profile.isRFQ=true

      if (!notAPI) {
        // dispatch(currentCostingActions.saveCostingProfileDraft(_profile));
        dispatch(currentCostingActions.saveCostingProfileTimeCost(_profile));
        Axios.put(`/projects/${project.ProjectId}/UpdateProjectParams`, {
          ClientChargeableApplicable: project.ClientChargeableApplicable ? 1 : 0,
        })
      } else {
        return { ..._profile, WaveSpecs: temporaryWavesForValidation };
      }
      // setprofileCopy(profile);
      // setShouldBlockNavigation(false);
    } else {
      toastr.error("You have Entered Invalid Values");
      isvalid = true;
    }
  }

  const getInternalCost = () => {
    let validationData = {}
    profile.CountrySpecs.map((country)=>{
      country.MethodologySpecs.map((method)=>{
        const {Label}=method
        method.CostingOptions.map((co)=>{
          const {id, OpportunityLineItemID, label}=co
          validationData[id] = {costOptionId:id, OpportunityLineItemID, Label, costingoptionlabel:label}
        })})})
    let productTypeArray=_.cloneDeep(profile.ProductTypeData)
 
    productTypeArray=productTypeArray.filter((item)=>!item.isRed)
 
 
    productTypeArray.map((item)=>{
        const {OpportunityLineItemID,Productname,WBSNumber}=item
        Object.entries(validationData).forEach(([costOptionID, value]) => {
          if (value.OpportunityLineItemID == OpportunityLineItemID) {
            validationData[costOptionID]={...value,WBSNumber,Productname,csCost:0,opsCost:0,PredefinedOPS:0,PredefinedCS:0}
          }})
    })
 
    let productArray=Object.values(validationData)
    profile.CountrySpecs.map((country)=>{
      country.MethodologySpecs.map((method)=>{
        method.CostingOptions.map((co)=>{
        // console.log(productArray.findIndex(obj=>obj.costOptionId === co.id))
          let index=productArray.findIndex(obj=>obj.costOptionId === co.id)
          if(index!=-1)
          {
            productArray[index].PredefinedCS += co.PredefinedHourCS * (1-profile.ThresholdTimecost)
            productArray[index].PredefinedOPS += co.PredefinedHourOP * (1-profile.ThresholdTimecost)
          }
         
        })
      })
    })

    
    productArray.map((prod)=>{
      if(((getTotalOpsCost(prod.costOptionId) >= prod.PredefinedOPS && (getTotalOpsCost(prod.costOptionId) !== 0)) || (opsTimecostReasonSaved[prod.costOptionId]?.OpsTimecostReason)) )
        if(prod.costOptionId){
        var obj = {[prod.costOptionId]: false}
        disabledOptionIds.push(obj)
        }
    })
    
    console.log("disabledOptionIdssss", disabledOptionIds, productArray);
    return productArray;
  }  

  // const handleReasonSave=()=>{
  //   let tempProfile=_.cloneDeep(profile)
  //   tempProfile["OpsTimecostReason"]=opsTimecostReason
  //   dispatch(currentCostingActions.updateProfile(tempProfile));
  //   dispatch(currentCostingActions.saveCostingProfileDraft(tempProfile,()=>{
  //     toastr.success("Timecost Reason saved succesfully")
  //   },true));
  //   handleOpen(false)
  // }

  const gettimecost = async () => {
    setIsLoading(true);
    const res = await axios.get(`/timecost/defaults/${profile.id}`);
    const response = await axios.get(`/timecost/${profile.id}`);
    const rates = res?.data?.rates?.filter((rate) => rate.function_id === 2);
    setDefaultRates(rates);
    const activityDataForCS = res.data.activities.filter(
      (activity) => activity.function_id === 2
    );
    const bandsDataForCS = res.data.bands.filter(
      (activity) => activity.function_id === 1
    );
    const activityDataForOPS = res.data.activities.filter(
      (activity) => activity.function_id === 2
    );
    const bandsDataForOPS = res.data.bands.filter(
      (activity) => activity.function_id === 2
    );

    let temparr = [];
    let csarr = res.data.Mapping.filter(
      (activity) => activity.funtction_id === 1
    );
    temparr.push(csarr);
    let opsarr = res.data.Mapping.filter(
      (activity) => activity.funtction_id === 2
    );
    temparr.push(opsarr);
    setActivityMapping(temparr);
    setOPSBand(bandsDataForOPS);
    setActivitiesForOPS(activityDataForOPS);
    let commercial = {};
    let operations = {};
    let operationCost = {};
    let countryArray = [];

    response.data.datas.map((co) => {
      console.log(co.OptionId);
      let id = co.OptionId;
      commercial[id] = [];
      operations[id] = [];
      operationCost[id] = [];

      co.OPMSchema && co.OPMSchema?.length > 0 && co.OPMSchema.map((schema) => {
        let pmtable = [];
        let costTable = [];
        for (
          let activitypm = 0;
          activitypm < activityDataForOPS.length;
          activitypm++
        ) {
          let temparr = [];
          let activityName = activityDataForOPS[activitypm].activity_name;
          if (!(activityName in schema.Rateselection)) {
            temparr = Array(bandsDataForOPS.length).fill("");
          } else {
            let activityarr = schema.Rateselection[activityName];

            for (let bandCS = 0; bandCS < bandsDataForOPS.length; bandCS++) {
              let bandname = bandsDataForOPS[bandCS].band_name;
              let flag = true;
              activityarr.forEach(function (obj) {
                if (bandname in obj) {
                  temparr.push(obj[bandname]);
                  flag = false;
                }
              });
              if (flag) {
                temparr.push("");
              }
            }
          }
          pmtable.push(temparr);
        }
        let totalCost = 0;
        for (let bandCS = 0; bandCS < bandsDataForOPS.length; bandCS++) {
          let bandname = bandsDataForOPS[bandCS].band_name;
          totalCost += Number(schema.Rateselection[bandname]);           
          costTable.push({ [bandname]: schema.Rateselection[bandname] });
        }
        if(costTable && costTable?.length > 0){
          costTable.push({"totalCost":(Math.round(totalCost * 100) / 100).toFixed(2)});
        }
        let countryName = schema.Rateselection.dropdownCountry.label;
        countryArray.push({[id]:countryName});
        let obj = {};
        let costObj = {};
        costObj[countryName] = costTable;
        obj[countryName] = pmtable;
        operations[id].push(obj);
        operationCost[id].push(costObj);
      });
    });
    if(countryArray && countryArray?.length > 0){
      await teamData(countryArray,()=>{
        const tableHours = [];
    const tableCosts = [];
    
    for (let i = 0; i < activityDataForOPS.length; i++) {
      tableHours.push(Array(bandsDataForOPS.length).fill(""));
    }

    for (let i = 0; i < bandsDataForOPS.length; i++) {
      tableCosts.push(Array(bandsDataForOPS.length).fill(""));
    }

    profile.CountrySpecs.map((country, index) => {
      const newRow = {
        [profile.CountrySpecs[index]?.CountryName]: tableHours,
      };
  
      const newCostRow = {
        [profile.CountrySpecs[index]?.CountryName]: tableCosts,
      };
  
      const newTable = [newRow];
      const newCost = [newCostRow];

      country.MethodologySpecs.map((method) => {
        method.CostingOptions.map((co,index) => {
          if(!(co.id in operations) || (operations[co.id] && !operations[co.id].length)) {
            operations[co.id] = newTable;
          }
          if(!(co.id in operationCost) || (operationCost[co.id] && !operationCost[co.id].length)) {
            operationCost[co.id] = newCost;
          }
        });
      });
    });

    dispatch(setCommercialHours(commercial));
    dispatch(setOperationsHours(operations));
    dispatch(setOperationsCost(operationCost));
    setIsLoading(false);
      });
    }else{
      let newArray = [];
      let id=profile.CountrySpecs[activeTabFC].MethodologySpecs[
        activeTabSM
      ]?.CostingOptions[activeTabCO]?.id;
      let name = profile.CountrySpecs[activeTabFC].CountryName;
      newArray.push({ [id]: name });      
      await teamData(newArray,()=>{
        const tableHours = [];
    const tableCosts = [];
    
    for (let i = 0; i < activityDataForOPS.length; i++) {
      tableHours.push(Array(bandsDataForOPS.length).fill(""));
    }

    for (let i = 0; i < bandsDataForOPS.length; i++) {
      tableCosts.push(Array(bandsDataForOPS.length).fill(""));
    }

    profile.CountrySpecs.map((country, index) => {
      const newRow = {
        [profile.CountrySpecs[index]?.CountryName]: tableHours,
      };
  
      const newCostRow = {
        [profile.CountrySpecs[index]?.CountryName]: tableCosts,
      };
  
      const newTable = [newRow];
      const newCost = [newCostRow];

      country.MethodologySpecs.map((method) => {
        method.CostingOptions.map((co,index) => {
          if(!(co.id in operations) || (operations[co.id] && !operations[co.id].length)) {
            operations[co.id] = newTable;
          }
          if(!(co.id in operationCost) || (operationCost[co.id] && !operationCost[co.id].length)) {
            operationCost[co.id] = newCost;
          }
        });
      });
    });

    dispatch(setCommercialHours(commercial));
    dispatch(setOperationsHours(operations));
    dispatch(setOperationsCost(operationCost));
    setIsLoading(false);
      });
    }  
  };
  const setoptionsData = () => {
    let options = {};
    for (let country = 0; country < profile.CountrySpecs.length; country++) {
      let method = [];
      for (
        let methodology = 0;
        methodology < profile.CountrySpecs[country].MethodologySpecs.length;
        methodology++
      ) {
        let cost = [];
        for (
          let costingOption = 0;
          costingOption <
          profile.CountrySpecs[country].MethodologySpecs[methodology]
            .CostingOptions.length;
          costingOption++
        ) {
          let label =
            profile.CountrySpecs[country].MethodologySpecs[methodology]
              .CostingOptions[costingOption].label;
          let ID =
            profile.CountrySpecs[country].MethodologySpecs[methodology]
              .CostingOptions[costingOption].id;
          let productName=profile.CountrySpecs[country].MethodologySpecs[methodology]
          .CostingOptions[costingOption].CostingOptionData.producttype
              if(!(productName.includes("EXPENSE RECHARGE")))
              {
                cost.push({ label: label, value: ID })
                
              }  
        }
        method.push(cost);
      }
      options[country] = method;
    }
    setoptions(options);
  };

  useEffect(() => {
    if (!isLoading && makeScroll) {
      window.scrollTo(0, scrollPosition);
      setMakeScroll(false);
    }
  }, [isLoading, makeScroll]);

  useEffect(() => {
    // getActivityDetails();
    setoptionsData();
    gettimecost();
    getCurrencyDetails();
    // getInternalCost();
  }, []);

  useEffect(()=>{
    let tempObj=costoptionsDetails
    profile.CountrySpecs.map((country)=>{
      country.MethodologySpecs.map((method)=>{
        method.CostingOptions.map((co,index)=>{
         tempObj[co.id]={countryName:country.CountryName,oppID:co.OpportunityLineItemID
         }
        })
      })
    })
    setCostoptionsDetails(tempObj)
    console.log(tempObj,"tempObj")

  },[])

  const getCurrencyDetails=()=>{
    if(timecostView==="operations")
    {
      let countryCode=profile.OPSCurrencySelection||project.CommissioningCountry
      let currencyArray=currencies.filter((item)=>item.Code===countryCode)
      setOPSCurrencyCode(countryCode)
      setOPSCurrencyUnit(currencyArray[0].CurrencyUnit)
      setOPSCurrencyConversion(currencyArray[0].ConversionRateToLocal)
      let finalCurrency = {
				value: `${currencyArray[0].Code}-${currencyArray[0].CurrencyUnit}`,
				label: `${currencyArray[0].Label} (1USD = ${currencyArray[0].ConversionRateToLocal}${currencyArray[0].CurrencyUnit})`,
			};
      setOPScurrencyDropdown(finalCurrency)
    }
  }

  const toggleSM = (tab) => {
    setActiveTabCO(0);
    const tableData = _.cloneDeep(opsCostData);
    let costTableData =
      tableData[
        profile.CountrySpecs[activeTabFC].MethodologySpecs[tab].CostingOptions[0].id
      ];
    let countryMap =  costTableData?.map((m)=>Object.keys(m)[0]);
    
    let dataOption=OPSTeamDataOptions;
    let res= dataOption.filter((data)=>
      !countryMap.includes(data.label)
    );
    setOPSTeamDataOptionsTemp(res);
    if (activeTabSM !== tab) {
      setActiveTabSM(tab);
      filteredSingleCountryMethodology(tab)
    }
    let opsSupport =
      profile.CountrySpecs[activeTabFC].MethodologySpecs[tab].CostingOptions[0]
        .CostingOptionData.operationalpm;
    if (opsSupport === "Yes") {
      setLocalSupportOPS(true);
    } else {
      setLocalSupportOPS(false);
    }
    let modalSupport =
      profile.CountrySpecs[activeTabFC].MethodologySpecs[tab].CostingOptions[0]
        .CostingOptionData?.operationpm;
    if (modalSupport === "Yes") {
      setLocalSupportModalOPS(true);
    } else {
      setLocalSupportModalOPS(false);
    }
  };

  const toggleTabPane = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  const toggleCO = (tab) => {
    const tableData = _.cloneDeep(opsCostData);
    let costTableData =
      tableData[
        profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
        .CostingOptions[tab].id
      ];
    let countryMap =  costTableData?.map((m)=>Object.keys(m)[0]);
    
    let dataOption=OPSTeamDataOptions;
    let res= dataOption.filter((data)=>
      !countryMap.includes(data.label)
    );
    setOPSTeamDataOptionsTemp(res);
    if (activeTabCO !== tab) {
      setActiveTabCO(tab);
    }
    let opsSupport =
      profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
        .CostingOptions[tab].CostingOptionData.operationalpm;
    if (opsSupport === "Yes") {
      setLocalSupportOPS(true);
    } else {
      setLocalSupportOPS(false);
    }
    let modalSupport =
      profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
        .CostingOptions[tab].CostingOptionData?.operationpm;
    if (modalSupport === "Yes") {
      setLocalSupportModalOPS(true);
    } else {
      setLocalSupportModalOPS(false);
    }
  };

  const infoModalToggle = () => {
    setInfoModal(false);
  };
  const handleOPSCurrencyChange =(e)=>{
    let countryCode=e.value.split("-")[0]
    let currencyArray=currencies.filter((item)=>item.Code===countryCode)
    setOPSCurrencyCode(countryCode)
    setOPSCurrencyUnit(currencyArray[0].CurrencyUnit)
    setOPSCurrencyConversion(currencyArray[0].ConversionRateToLocal)
    let finalCurrency = {
      value: `${currencyArray[0].Code}-${currencyArray[0].CurrencyUnit}`,
      label: `${currencyArray[0].Label} (1USD = ${currencyArray[0].ConversionRateToLocal}${currencyArray[0].CurrencyUnit})`,
    };
    setOPScurrencyDropdown(finalCurrency)
  }
  function populateSideBarLinks(variables) {
    if (variables) {
      dispatch(
        sideBarActions.addCostingOptionsLink(
          "#costing_options_card_" + activeTabFC + "_" + activeTabSM
        )
      );
      dispatch(
        sideBarActions.addSudOptionsLink(
          "#sud_options_card_" + activeTabFC + "_" + activeTabSM
        )
      );

      if (profile.CountrySpecs.length > 1)
        dispatch(
          sideBarActions.resOptionsLink(
            "#res_options_card_" + activeTabFC + "_" + activeTabSM
          )
        );
      else dispatch(sideBarActions.resOptionsLink(""));

      let links = [];
      variables.forEach((x, index) => {
        if (x.isNewSection)
          links.push({ label: x.sectionTitle, value: "#header_" + index });
      });
      dispatch(sideBarActions.addSidebarLinks(links));
    }
  }

  function toggle(tab) {
    setActiveTabSM(0);
    setActiveTabCO(0);
    const tableData = _.cloneDeep(opsCostData);
    let costTableData =
      tableData[
        profile.CountrySpecs[tab].MethodologySpecs[0].CostingOptions[0].id
      ];
    let countryMap =  costTableData?.map((m)=>Object.keys(m)[0]);
    
    let dataOption=OPSTeamDataOptions;
    let res= dataOption.filter((data)=>
      !countryMap.includes(data.label)
    );
    setOPSTeamDataOptionsTemp(res);
    if (activeTabFC !== tab) {
      setActiveTabFC(tab);
      let allMeth = profile.CountrySpecs[tab].MethodologySpecs.map(
        (m, mIndex) => ({
          methodology: m,
          index: mIndex,
        })
      );
      let reqMeth = allMeth.find((x) => !x.methodology.NotApplicable);
      if (!reqMeth) reqMeth = _.head(allMeth);
    }

    if (
      !(
        "CostingOptionData" in
        profile.CountrySpecs[tab].MethodologySpecs[0].CostingOptions[0]
      )
    ) {
      populateSideBarLinks(
        profile.CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]
          ?.Variables
      );
    }
    let opsSupport =
      profile.CountrySpecs[tab].MethodologySpecs[0].CostingOptions[0]
        .CostingOptionData.operationalpm;
    if (opsSupport === "Yes") {
      setLocalSupportOPS(true);
    } else {
      setLocalSupportOPS(false);
    }
    let modalSupport =
      profile.CountrySpecs[tab].MethodologySpecs[0].CostingOptions[0]
        .CostingOptionData?.operationpm;
    if (modalSupport === "Yes") {
      setLocalSupportModalOPS(true);
    } else {
      setLocalSupportModalOPS(false);
    }
  }

  const updateModal = () => {
    settimecostViewModal(!timecostViewModal);
  };

  const getWBSnumber = () => {
    let oppID = profile?.CountrySpecs ? 
      profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
        .CostingOptions[activeTabCO].OpportunityLineItemID : "";
    let ProductTypeData = profile?.ProductTypeData !== undefined ? profile?.ProductTypeData : [];
    let filterarray = Array.isArray(ProductTypeData) ? ProductTypeData?.filter(
      (item) => item.OpportunityLineItemID == oppID
    ) : [];
    if (filterarray) {
      return filterarray[0]?.WBSNumber;
    }
    return null;
  };

  const clearOPSTable = (costingOption,index) => {
    setShowModal(true);
    setSelectedCountryForClear({
      costingOption,index
    });
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleOk = () => {
    let opsdata = _.cloneDeep(opsCostData);
    let costingOptionID=selectedCountryForClear.costingOption
    let index=selectedCountryForClear.index
    const tableHours = [];
    for (var i = 0; i < activitiesForOPS.length; i++) {
      tableHours.push(Array(OPSband.length).fill(""));
    }
    let hoursObj=_.cloneDeep(opsdata[costingOptionID][index])
    Object.keys(hoursObj).map((key)=>{
      hoursObj[key]=tableHours
    })
    opsdata[costingOptionID][index]=hoursObj
    dispatch(setOperationsCost(opsdata));
    setShowModal(false);
    setIsClear({
      costingOption:selectedCountryForClear.costingOption,
      index:selectedCountryForClear.index
    })
    toastr.success("Hours cleared successfully")
  };

  const deleteOPSTable = (costOptionID, index) => {
    console.log("......", costOptionID, index);
    setSelectedCostOptionID(costOptionID);
    setSelectedIndex(index);
    setShowDeleteModal(true);
  };

  const handleCloseModal = () => {
    setShowDeleteModal(false);
  };

  const handleOKModal = () => {
    let csdata = _.cloneDeep(opsCostData);
    let costdata = _.cloneDeep(operationsCostData);

    const data = csdata[selectedCostOptionID]
      .map((c) => c)
      .filter((country, i) => i != selectedIndex);
    const cdata = costdata[selectedCostOptionID]
      .map((c) => c)
      .filter((country, i) => i != selectedIndex);
    const deletedData = csdata[selectedCostOptionID]
      .map((c) => c)
      .filter((country, i) => i === selectedIndex);
    const deletedCData = costdata[selectedCostOptionID]
      .map((c) => c)
      .filter((country, i) => i === selectedIndex);
      let teamData = OPSTeamDataOptions?.map((m)=>m.label);

    //opsdata  
    let clonedDeletedData = _.cloneDeep(deletedTimeCostData);
    if(teamData.includes(Object.keys(deletedData[0])[0])){
    if (clonedDeletedData && Object.keys(clonedDeletedData).length) {
      if (clonedDeletedData?.[selectedCostOptionID] === undefined) {
        let newObj = { [selectedCostOptionID]: deletedData };
        Object.assign(clonedDeletedData, newObj);
      }else{
        let newArray = clonedDeletedData[selectedCostOptionID];
        newArray.push(deletedData[0]);
        clonedDeletedData[selectedCostOptionID] = newArray;
      }
    }
    else {
      clonedDeletedData = { [selectedCostOptionID]: deletedData };
    }
  }
    //cost data
    let clonedCostData = _.cloneDeep(deletedCostData);
    if(teamData.includes(Object.keys(deletedCData[0])[0])){
    if (clonedCostData && Object.keys(clonedCostData).length) {
      if (clonedCostData?.[selectedCostOptionID] === undefined) {
        let newObj = { [selectedCostOptionID]: deletedCData };
        Object.assign(clonedCostData, newObj);
      }else{
        let newArray = clonedCostData[selectedCostOptionID];
        newArray.push(deletedCData[0]);
        clonedCostData[selectedCostOptionID] = newArray;
      }
    }
    else {
      clonedCostData = { [selectedCostOptionID]: deletedCData };
    }
  }
    if (clonedDeletedData && Object.keys(clonedDeletedData)?.length) {
      setDeletedTimeCostData(clonedDeletedData);
    }
    if (clonedCostData && Object.keys(clonedCostData)?.length) {
      setDeletedCostData(clonedCostData);
    }
    csdata[selectedCostOptionID] = data;
    costdata[selectedCostOptionID] = cdata;
     //excluding the already selected country from the list
     let costTableData = csdata[selectedCostOptionID];
     let countryMap =  costTableData?.map((m)=>Object.keys(m)[0]);
     
     let dataOption=OPSTeamDataOptions;
     let res= dataOption.filter((data)=>
       !countryMap.includes(data.label)
     );
    setOPSTeamDataOptionsTemp(res);
    dispatch(setOperationsHours(csdata));
    dispatch(setOperationsCost(costdata));
    setShowDeleteModal(false);
  };

  const handleSelectChange = (e, index) => {
    const tableData = _.cloneDeep(opsCostData);
    const tableCostData = _.cloneDeep(operationsCostData);

    let coData =
      tableData[
        profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
          .CostingOptions[activeTabCO].id
      ];
      let costData = tableCostData[
        profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
          .CostingOptions[activeTabCO].id
      ];
    let obj = {};
    let costObj = {};
    obj[e.label] = Object.values(coData[index])[0];
    coData[index] = obj;
    costObj[e.label] = Object.values(costData[index])[0];
    costData[index] = costObj;

    //excluding the already selected country from the list
    let costTableData =
    tableData[
      profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
        .CostingOptions[activeTabCO].id
    ];
    let countryMap =  costTableData?.map((m)=>Object.keys(m)[0]);
    let dataOption=OPSTeamDataOptions;
    let res= dataOption.filter((data)=>
    !countryMap.includes(data.label)
    );

    setOPSTeamDataOptionsTemp(res);
    dispatch(setOperationsHours(tableData));
    dispatch(setOperationsCost(tableCostData));
    console.log(tableData, "tableData");
  };

  const copyMethodologiesData = () => {
    var convertedMethodologies = [];

    if (profile.CountrySpecs[activeTabFC].MethodologySpecs[0].label) {
      convertedMethodologies = profile.CountrySpecs[
        activeTabFC
      ].MethodologySpecs.map((fc) => ({ label: fc.label, value: fc.label }));
    } else {
      convertedMethodologies = profile.CountrySpecs[
        activeTabFC
      ].MethodologySpecs.map((fc) => ({ label: fc.Label, value: fc.Label }));
    }
    setSelectedMethodologyData1(convertedMethodologies);
    setModalMethodology(true);
  };

  const generateRandomKey = () => {
    const randomKey = Math.random().toString(36).substring(2, 10);
    return randomKey;
  };

  const addNewCostingRow = () => {
    const costingOptionID =
      profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
        ?.CostingOptions[activeTabCO]?.id;
    const tableHours = [];
    for (var i = 0; i < activitiesForOPS.length; i++) {
      tableHours.push(Array(OPSband.length).fill(""));
    }
    const newRow = {
      [generateRandomKey()]: tableHours,
    };

    const tableCosts = [];
    for (var i = 0; i < OPSband.length; i++) {
      tableCosts.push(Array(OPSband.length).fill(""));
    }
    const newCostRow = {
      [generateRandomKey()]: tableCosts,
    };

    const tableData = _.cloneDeep(opsCostData);
    const tableCostData = _.cloneDeep(operationsCostData);

    //for hours
    if (tableData && Object.keys(tableData).length && tableData[costingOptionID]) {
      tableData[costingOptionID].push(newRow);
    } else {
      const optionIdAssignment = {
        [costingOptionID]: [newRow],
      };
      Object.assign(tableData, optionIdAssignment);
    }

    //for cost
    if (tableCostData && Object.keys(tableCostData).length && tableCostData[costingOptionID]) {
      tableCostData[costingOptionID].push(newCostRow);
    } else {
      const optionIdAssignment = {
        [costingOptionID]: [newCostRow],
      };
      Object.assign(tableCostData, optionIdAssignment);
    }
    dispatch(setOperationsHours(tableData));
    dispatch(setOperationsCost(tableCostData))
    toastr.success("New Costing Market Added");
  };

  const copyCountriesDataOps = () => {
    var convertedCountries = [];

    if (profile.CountrySpecs[0].label) {
      convertedCountries = profile.CountrySpecs.map((fc) => ({
        label: fc.label,
        value: fc.label,
      }));
    } else {
      convertedCountries = profile.CountrySpecs.map((fc) => ({
        label: fc.CountryName,
        value: fc.CountryName,
      }));
    }

    setSelectedCountryData(convertedCountries);
    copyMethodologiesData();

    setOpsModal(true);
  };

  const getCSOPSDetails=(coID)=>{
    let result=[];
    profile.CountrySpecs.map((country)=>{
      country.MethodologySpecs.map((method)=>{
        method.CostingOptions.map((co,index)=>{
          if(coID==co.id)
          {
            result.push(co.CostingOptionData.CommericalLocalsu,co.CostingOptionData.operationalpm,co.CostingOptionData.Commericalsupport,co.CostingOptionData.operationpm,method.Code,country.CountryCode)
          }
        })
      })
    })
    return result
  };

  const copyHoursWBSlevel=async()=>{
    const response =await axios.get(`/timecost/${profile.id}`)
    let costingOptionDetailsObj=costoptionsDetails
    let hoursPayload={
      records: [],
    };
    let totalCostPayload = {
      records: [],
    };
    for (const costingOptionID in opsCostData) {
      const [csSupport,opsSupport,csModal,opsModal]=getCSOPSDetails(costingOptionID)
      
        if(opsSupport==="No")
        {
          continue
        }
        if(opsModal==="No")
        {
          continue
        }
      
     
      let oppID=costingOptionDetailsObj[costingOptionID]?.oppID
      let countryName=costingOptionDetailsObj[costingOptionID]?.countryName
      let costArray=operationsCostData[costingOptionID]
      if(levelInput[oppID]==="wbslevel")
      {
        let tableArray=opsCostData[costingOptionID]
        let OPMSchema=[]
        tableArray.map((country,index)=>{
          let countryName;
          let hours;
          for (const [key, value] of Object.entries(country)) {
            countryName=key
            hours=value
          }
          let opsCostArray=costArray[index]
          let bandCostArray=opsCostArray[countryName]

          const isCountry = OPSTeamDataOptions?.some((obj) => obj.label === countryName);

          if(bandCostArray === undefined && !isCountry) {
            return null;
          }
  
          let pmData = {};
          OPSband.map((bandValue, index) => {
            let bandObj=bandCostArray[index]
            pmData[bandValue.band_name] = bandObj[bandValue.band_name];
          });
        let pmTable=hours
        pmData["dropdownCountry"]={label:countryName}
        for(let activity=0;activity<activitiesForOPS.length;activity++)
        {
          let temparr=[]
          let activityName=activitiesForOPS[activity].activity_name
        
          for(let bandCS=0;bandCS<OPSband.length;bandCS++)
          {
            let bandname=OPSband[bandCS].band_name
            let tempObj={}
            tempObj[bandname]=pmTable[activity][bandCS]
            temparr.push(tempObj)
          }
          pmData[activityName]=temparr
        }
        let finalObj={
          Rateselection: pmData,
        }
        response.data.datas.map((res)=>{
          if(res.OptionId===costingOptionID)
          {
            if(res.OPMSchema.length>index)
            {
              let schema=res.OPMSchema[index]
              if("CostId" in schema)
              {
                finalObj["CostId"]=schema.CostId
              }
            }
          }
        });
  
        OPMSchema.push(finalObj)
        })
          profile.CountrySpecs.map((country)=>{
          country.MethodologySpecs.map((method)=>{
            method.CostingOptions.map((co,index)=>{
              if(co.OpportunityLineItemID==oppID && country.CountryName==countryName && co.id!=costingOptionID&&co.CostingOptionData.CommericalLocalsu==="Yes")
              {
                let copyOPSschema=_.cloneDeep(OPMSchema)
                let costingOptionPayload={
                  ProfileId: profile.id,
                }
                costingOptionPayload["CountryId"]=country.CountryCode
                costingOptionPayload["MethodologyCode"]=method.Code
                costingOptionPayload["OptionId"]=co.id

                response.data.datas.map((res)=>{
                  if(res.OptionId==co.id)
                  {
                    copyOPSschema.map((schemaItem,index)=>{
                      if(res.OPMSchema.length>index)
                      {
                        let schema=res.OPMSchema[index]
                        if("CostId" in schema)
                        {
                          schemaItem["CostId"]=schema.CostId
                        }
                      }
                    })
                    
                  }
                });
                costingOptionPayload["OPMSchema"]=copyOPSschema


                response.data.datas.map((res)=>{
                  if(res.OptionId==co.id)
                  {
                    costingOptionPayload["id"]=res.id
                  }
                })
                hoursPayload.records.push(costingOptionPayload)
              }
              })
            })
          })

          const totalOperationsCost = operationsCostData[costingOptionID]?.map((csCost) => {
            return Object.values(csCost)[0];
          });
    
          const finalOpsCost = totalOperationsCost.reduce((outerAcc, innerArray) => {
            const innerSum = innerArray.reduce((innerArray, obj) => {
              const totalCostValue = parseFloat(obj.totalCost);
              if(!isNaN(totalCostValue)) {
                innerArray += totalCostValue;
              }
              return innerArray;
            }, 0);
            return outerAcc + innerSum;
          }, 0);

          
          profile.CountrySpecs.map((country)=>{
            country.MethodologySpecs.map((method)=>{
              method.CostingOptions.map((co,index)=>{
                if(co.OpportunityLineItemID==oppID && country.CountryName==countryName && co.id!=costingOptionID&&co.CostingOptionData.CommericalLocalsu==="Yes")
                {
                  let payload;
                  if(co.CostingOptionData.Booster==="No")
                  {
                    payload = {
                      id: co.id,
                      TotalOPSTimeCost: finalOpsCost
                    };
                    totalCostPayload.records.push(payload);
                  }
                  else
                  {
                    payload = {
                      id: co.id,
                      TotalOPSTimeCost: finalOpsCost*0.05
                    };
                    totalCostPayload.records.push(payload);
                  }
                }
              })
            })
          })

                      
      }
    }

    let resultpayload=[hoursPayload,totalCostPayload]
    console.log("copyhoursPayload",hoursPayload);
    return resultpayload
  }
    //*********************************************************** */
    const deleteHoursWBSlevel=async(response)=>{
      
    let costingOptionDetailsObj=costoptionsDetails
    let hoursPayload=[];
    for (const costingOptionID in deletedTimeCostData) {
      const [csSupport,opsSupport,csModal,opsModal]=getCSOPSDetails(costingOptionID)
      
        if(opsSupport==="No")
        {
          continue
        }
        if(opsModal==="No")
        {
          continue
        }
      
     
      let oppID=costingOptionDetailsObj[costingOptionID].oppID
      let countryName=costingOptionDetailsObj[costingOptionID].countryName
      let costArray=deletedCostData[costingOptionID]
      if(levelInput[oppID]==="wbslevel")
      {
        let tableArray=deletedTimeCostData[costingOptionID]
        let OPMSchema=[]
        tableArray.map((country,index)=>{
          let countryName;
          let hours;
          for (const [key, value] of Object.entries(country)) {
            countryName=key
            hours=value
          }
          let opsCostArray=costArray[index]
          let bandCostArray=opsCostArray[countryName]

          const isCountry = OPSTeamDataOptions?.some((obj) => obj.label === countryName);

          if(bandCostArray === undefined && !isCountry) {
            return null;
          }
  
          let pmData = {};
          OPSband.map((bandValue, index) => {
            let bandObj=bandCostArray[index]
            pmData[bandValue.band_name] = bandObj[bandValue.band_name];
          });
        let pmTable=hours
        pmData["dropdownCountry"]={label:countryName}
        for(let activity=0;activity<activitiesForOPS.length;activity++)
        {
          let temparr=[]
          let activityName=activitiesForOPS[activity].activity_name
        
          for(let bandCS=0;bandCS<OPSband.length;bandCS++)
          {
            let bandname=OPSband[bandCS].band_name
            let tempObj={}
            tempObj[bandname]=pmTable[activity][bandCS]
            temparr.push(tempObj)
          }
          pmData[activityName]=temparr
        }
        let finalObj={
          Rateselection: pmData,
          Isdeleted: true,
        }
        response.data.datas.map((res)=>{
          if(res.OptionId===costingOptionID)
          {
            if(res.OPMSchema.length>index)
            {
              let schema=res.OPMSchema[index]
              if("CostId" in schema)
              {
                finalObj["CostId"]=schema.CostId
              }
            }
          }
        });
  
        OPMSchema.push(finalObj)
        })
          profile.CountrySpecs.map((country)=>{
          country.MethodologySpecs.map((method)=>{
            method.CostingOptions.map((co,index)=>{
              if(co.OpportunityLineItemID==oppID && country.CountryName==countryName && co.id!=costingOptionID&&co.CostingOptionData.CommericalLocalsu==="Yes")
              {
                let copyOPSschema=_.cloneDeep(OPMSchema)
                let costingOptionPayload={
                  ProfileId: profile.id,
                }
                costingOptionPayload["CountryId"]=country.CountryCode
                costingOptionPayload["MethodologyCode"]=method.Code
                costingOptionPayload["OptionId"]=co.id

                response.data.datas.map((res)=>{
                  if(res.OptionId==co.id)
                  {
                    copyOPSschema.map((schemaItem,index)=>{
                      if(res.OPMSchema.length>index)
                      {
                        res.OPMSchema?.map((r)=>{
                          if (r.Rateselection.dropdownCountry.label == schemaItem.Rateselection.dropdownCountry.label) {
                            if ("CostId" in r) {
                              schemaItem["CostId"] = r.CostId
                            }
                          }
                         })
                      }
                    })
                    
                  }
                });
                costingOptionPayload["OPMSchema"]=copyOPSschema


                response.data.datas.map((res)=>{
                  if(res.OptionId==co.id)
                  {
                    costingOptionPayload["id"]=res.id
                  }
                })
                hoursPayload.push(costingOptionPayload)
              }
              })
            })
          })                    
      }
    }
    return hoursPayload;
    }
    //************************************************************ */
  const deleteTimeCostDatas = async (response) => {
    console.log("?????", deletedTimeCostData);
    let records=await deleteHoursWBSlevel(response);
    for(let costingOptionID in deletedTimeCostData)
    {
      const [csSupport,opsSupport,csModal,opsModal,methodCode,countryCode]=getCSOPSDetails(costingOptionID)
      if(opsSupport==="No")
      {
        continue
      }
      if(opsModal==="No")
      {
        continue
      }
      let tableArray=deletedTimeCostData[costingOptionID]
      let costArray=deletedCostData[costingOptionID]
      let costingOptionPayload={
        ProfileId: profile.id,
  
        CountryId: countryCode,

        MethodologyCode:methodCode,

        OptionId: costingOptionID,
        OPMSchema:[]
      }

      tableArray.map((country,index)=>{
        let countryName;
        let hours;
        for (const [key, value] of Object.entries(country)) {
          countryName=key
          hours=value
        }
        let opsCostArray=costArray[index]
        let bandCostArray=opsCostArray[countryName]

        let pmData = {};
        OPSband.map((bandValue, index) => {
          let bandObj=bandCostArray[index]
          pmData[bandValue.band_name] = bandObj[bandValue.band_name];
        });
      let pmTable=hours
      pmData["dropdownCountry"]={label:countryName}
      for(let activity=0;activity<activitiesForOPS.length;activity++)
      {
        let temparr=[]
        let activityName=activitiesForOPS[activity].activity_name
      
        for(let bandCS=0;bandCS<OPSband.length;bandCS++)
        {
          let bandname=OPSband[bandCS].band_name
          let tempObj={}
          tempObj[bandname]=pmTable[activity][bandCS]
          temparr.push(tempObj)
        }
        pmData[activityName]=temparr
      }
      let finalObj={
        Rateselection: pmData,
        Isdeleted: true,
      }
      response.data.datas.map((res)=>{
        if(res.OptionId==costingOptionID)
        {
          res.OPMSchema.map((r)=>{
            if (r.Rateselection.dropdownCountry.label === countryName) {
              if ("CostId" in r) {
                finalObj["CostId"] = r.CostId
              }
            } 
          })
        }
      });
      costingOptionPayload.OPMSchema.push(finalObj)
      })


      response.data.datas.map((res)=>{
        if(res.OptionId==costingOptionID)
        {
          costingOptionPayload["id"]=res.id
        }
      })

      records.push(costingOptionPayload)
    }
    
    await axios.put(`/timecost/Trash/${profile.id}`, records).then(
      res => {
        setDeletedTimeCostData({});
        setDeletedCostData({});
      }
    ).catch(err => {
      console.log(err.message);
    })
  }

  function validateSUD() {
    console.log(profile, "profileee")
    profile?.CountrySpecs.map((fc) =>
      fc?.MethodologySpecs.map((sm) =>
        sm?.OpResourcesVariablesTimecost.filter((v) => v?.type == "integer").map(
          (cd) => {
            if (parseInt(cd?.value) < 0) {
              isvalid = false;
            }
          }
        )
      )
    );
  }

  const saveTableData = async () => {
    dispatch(setOpportunityValue(""));    
    
    // if (validateForms(_profile)) {
    setIsLoading(true);
    setAlert(false);      
    
    // dispatch(currentCostingActions.updateProfile(tempProfile));
    // dispatch(currentCostingActions.saveCostingProfileDraft(tempProfile,()=>{
    //   toastr.success("Timecost Reason saved succesfully")
    // },true));
    handleOpen(false)
    let payload=await copyHoursWBSlevel()
    console.log("?????",deletedTimeCostData);
    const response = await axios.get(`/timecost/${profile.id}`);
    let hoursPayload=payload[0]

    let totalCostPayload = payload[1]


    if(deletedTimeCostData && Object.keys(deletedTimeCostData)?.length && response?.data?.datas?.length >0){
      deleteTimeCostDatas(response);  
    }
    
    for(let costingOptionID in opsCostData)
    {
      const [csSupport,opsSupport,csModal,opsModal,methodCode,countryCode]=getCSOPSDetails(costingOptionID)
      if(opsSupport==="No")
      {
        continue
      }
      if(opsModal==="No")
      {
        continue
      }
      let tableArray=opsCostData[costingOptionID]
      let costArray=operationsCostData[costingOptionID]
      let costingOptionPayload={
        ProfileId: profile.id,
  
        CountryId: countryCode,

        MethodologyCode:methodCode,

        OptionId: costingOptionID,
        OPMSchema:[]
      }

      tableArray.map((country,index)=>{
        let countryName;
        let hours;
        for (const [key, value] of Object.entries(country)) {
          countryName=key
          hours=value
        }
        let opsCostArray=costArray[index]
        let bandCostArray=opsCostArray[countryName]

        const isCountry = OPSTeamDataOptions?.some((obj) => obj.label === countryName);

        if(bandCostArray === undefined && !isCountry) {
          for(let country=0;country<profile.CountrySpecs.length;country++){
            for(let methodology=0;methodology<profile.CountrySpecs[country].MethodologySpecs.length;methodology++){
              for(let costingOption=0;costingOption<profile.CountrySpecs[country].MethodologySpecs[methodology].CostingOptions.length;costingOption++){
                if(profile.CountrySpecs[country].MethodologySpecs[methodology].CostingOptions[costingOption].id == costingOptionID) {
                  toastr.warning(`Please select a country for ${profile.CountrySpecs[country].CountryName} > ${profile.CountrySpecs[country].MethodologySpecs[methodology].label} > ${profile.CountrySpecs[country].MethodologySpecs[methodology].CostingOptions[costingOption].Name} > Team ${index+1}`, '', {timeOut: 5000});
                }
              }
            }
          }
          return null;
        }

        let pmData = {};
        OPSband.map((bandValue, index) => {
          let bandObj=bandCostArray[index]
          pmData[bandValue.band_name] = bandObj[bandValue.band_name];
        });
      let pmTable=hours
      pmData["dropdownCountry"]={label:countryName}
      for(let activity=0;activity<activitiesForOPS.length;activity++)
      {
        let temparr=[]
        let activityName=activitiesForOPS[activity].activity_name
      
        for(let bandCS=0;bandCS<OPSband.length;bandCS++)
        {
          let bandname=OPSband[bandCS].band_name
          let tempObj={}
          tempObj[bandname]=pmTable[activity][bandCS]
          temparr.push(tempObj)
        }
        pmData[activityName]=temparr
      }
      let finalObj={
        Rateselection: pmData,
      }
      response.data.datas.map((res)=>{
        if(res.OptionId==costingOptionID)
        {
          if(res.OPMSchema.length>index)
          {
            let schema=res.OPMSchema[index]
            if("CostId" in schema)
            {
              finalObj["CostId"]=schema.CostId
            }
          }
        }
      });

      costingOptionPayload.OPMSchema.push(finalObj)
      })

      

      response.data.datas.map((res) => {
        if(res.OptionId==costingOptionID)
        {
          costingOptionPayload["id"]=res.id
        }
      });

      const totalOperationsCost = operationsCostData[costingOptionID]?.map((csCost) => {
        return Object.values(csCost)[0];
      });

      const finalOpsCost = totalOperationsCost.reduce((outerAcc, innerArray) => {
        const innerSum = innerArray.reduce((innerArray, obj) => {
          const totalCostValue = parseFloat(obj.totalCost);
          if(!isNaN(totalCostValue)) {
            innerArray += totalCostValue;
          }
          return innerArray;
        }, 0);
        return outerAcc + innerSum;
      }, 0);


      let payload = {
        id: costingOptionID,
        TotalOPSTimeCost: finalOpsCost,
        OPSCurrencySelection:OPSCurrencyCode
      };

      hoursPayload.records.push(costingOptionPayload);
      totalCostPayload.records.push(payload);
    }
    console.log("hoursPayload",hoursPayload);
    console.log(totalCostPayload,"totalcostpayload");

    if(hoursPayload?.records[0]?.OPMSchema.length > 0) {
      // if(totalCostPayload.records.length > 0) {
      //   dispatch(saveTotalCost(totalCostPayload, profile.id));
      // }
      // if(hoursPayload.records.length > 0)
      // {
      //   dispatch(saveHourspayload(hoursPayload, profile.id));
      // }
      // let tempProfile=_.cloneDeep(profile)
      let tempProfile = _.cloneDeep(profile);
      let _profile = saveDraft(true);
      if(isOpsvalidation.length > 0) {
        saveDraft();      
      // handleReasonSave();
      }
      // tempProfile["OpsTimecostReason"] = opsTimecostReason
      tempProfile["OPSCurrencySelection"]=OPSCurrencyCode
      Object.keys(opsTimecostReason).map(ops => {
        tempProfile.CountrySpecs.map((country)=>{
          country.MethodologySpecs.map((method)=>{
            method.CostingOptions.map((co)=>{
              if (co.id == ops) {
                co.OpsTimecostReason = opsTimecostReason[ops].OpsTimecostReason
              }
            })})})
      })
      setopsTimecostReasonSaved(opsTimecostReason)
      dispatch(currentCostingActions.updateProfile(tempProfile));
      dispatch(saveTimecost(hoursPayload,totalCostPayload,profile.id));
      dispatch(currentCostingActions.saveCostingProfileDraft(tempProfile, () => {
          toastr.success("Timecost Reason saved succesfully")
        },true));
      if(isOpsvalidation.length > 0) {
        validateForms(_profile);          
      }
      setIsLoading(false);
    } else {
      toastr.warning("Data not saved");
      setIsLoading(false);
    }
    // }
    SetIsTextBoxdisabled([])
  };

  const getTotalBandHours = (bandIndex, data) => {
    let totalHours = 0;
    let hours = Object.values(data)[0];
    for (let i = 0; i < activitiesForOPS.length; i++) {
      if (hours[i][bandIndex] !== "") totalHours += hours[i][bandIndex];
    }
    return totalHours;
  };

  const getTotalCost = (id, i, data) => {
    let bandRates = [];    
    let totalHours = getTotalBandHours(i, data);
    if (isNaN(totalHours)) {
      return 0;
    }
    const country = OPSTeamDataOptions?.filter((opsTeam =>opsTeam.label === Object.keys(data)[0] ));
    if(country && country?.length > 0){
      bandRates = defaultRates?.filter((rate) => rate.country_id === country[0]?.value);
    }
    let rate = bandRates?.filter((r)=>r.band_id === id);
    let finalRate = 0;
    if(rate && rate?.length > 0){
    finalRate = Number(
    Math.round(totalHours * rate[0].rate * 100) / 100
    ).toFixed(2);
    }
    return finalRate; 
  };

  const getTotalHours = (id, i, data) => {
    let bandRates = [];    
    let totalHours = getTotalBandHours(i, data);
    if (isNaN(totalHours)) {
      return 0;
    }
    const country = OPSTeamDataOptions?.filter((opsTeam =>opsTeam.label === Object.keys(data)[0] ));
    if(country && country?.length > 0){
      bandRates = defaultRates?.filter((rate) => rate.country_id === country[0]?.value);
    }
    let rate = bandRates?.filter((r)=>r.band_id === id);
    let finalRate = 0;
    if(rate && rate?.length > 0){
    finalRate = Number(
    Math.round(totalHours)
    ).toFixed(2);
    }
    return finalRate; 
  };

  const totalCost = (data) => {    
    let totalCost = 0;
    OPSband.forEach((b,index)=>{
      let tempCsValue = getTotalCost(b.band_id, index, data);
      if (!isNaN(tempCsValue)) {
        totalCost += Number(tempCsValue);
      }
    })
    return (Math.round(totalCost * 100) / 100).toFixed(2);
  };

  const totalHours = (data) => {    
    let totalCost = 0;
    OPSband.forEach((b,index)=>{
      let tempCsValue = getTotalHours(b.band_id, index, data);
      if (!isNaN(tempCsValue)) {
        totalCost += Number(tempCsValue);
      }
    })
    return (Math.round(totalCost));
  };

  const getTotalOpsCost = (costingOptionId) => {
    let totalSum = 0;
    
    const totalOpsCost = opsCostData[costingOptionId]?.map(data => totalCost(data))
                          ?.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0);
    totalSum += totalOpsCost;
    return totalSum;
  };
  const getTotalOpshours = (costingOptionId) => {
    let totalSum = 0;
    console.log("opsCostData[costingOptionId]", opsCostData[costingOptionId])
    console.log("opsCostData[costingOptionId]?.map(data => totalCost(data))", opsCostData[costingOptionId]?.map(data => totalHours(data)))
    const totalOpsCost = opsCostData[costingOptionId]?.map(data => totalHours(data))
                          ?.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0);
    console.log("totalOpsCost", totalOpsCost)                     
    return totalOpsCost;
  };

  const checkIfEnabled = (costOptionId) => {
    const item = disabledOptionIds.find(opt => opt.hasOwnProperty(costOptionId));
    return item ? item[costOptionId] : false;
  };

  
  const totalOpsCostArray = profile?.CountrySpecs !== undefined ? opsCostData[
    profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
      ?.CostingOptions[activeTabCO]?.id
    ]?.map(data => totalCost(data)) : [];
    
    const totalOpsCost = totalOpsCostArray?.length > 0 ? totalOpsCostArray?.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0) : 0;
    
    const getProductName=()=>{
      return profile?.CountrySpecs[activeTabFC]?.MethodologySpecs[activeTabSM]?.CostingOptions[activeTabCO]?.CostingOptionData.producttype
    }
    
    const showErrorMessages = (eve, errors, key) => {
      eve.preventDefault();
      dispatch(setFormErrorStatus({ [key]: errors }));
    };

  const ValueChangeCheckInChild = (val) => {
    dispatch(valueChangedInRFQ(val));
    // eve.preventDefault();
    // dispatch(setFormErrorStatus({ [key]: errors }))
  };

  const handleTimecostReasonOPS=(e,costOptionId)=>{
    let reasonObj={...opsTimecostReason}
    reasonObj[costOptionId]={OpsTimecostReason:e.target.value}
    setopsTimecostReason(reasonObj)
  };

  const confirmModalCopying = () => {
    const { 
      SelectedCountries, 
      SelectedMethodologies, 
      SelectedCategories, 
      SelectedCopyMethod
    } = advanceCopying

    // const isCopyMethodEmpty = () => SelectedCategories.some(i => i.value === "CostingOptions") && _.isEmpty(SelectedCopyMethod) 
    // console.log(isCopyMethodEmpty(), "ddkdk")

    if(!SelectedCountries.length || !SelectedMethodologies.length || !SelectedCategories?.length) {
      toastr.error("Error", "All fields are mandatory")
    } else {
      if (validateCopyModal()) {
        setModalMethodologiesState(false);
        setModalConfirmMethodology(true);
      }
    }
    
  };


  const validateCopyModal = () => {
    if (
      advanceCopying.SelectedCountries &&
      advanceCopying.SelectedMethodologies &&
      advanceCopying.SelectedCategories
    ) {
      return true;
    }
    toastr.error("Error", "All fields are mandatory");
    return false;
  };

  const handleIntersection = (filteredMethodologyArr) => {

    const costingOptionArray = []
    const rfqArray = []
    const setupAndDeliveryArray = []

    const intersection = filteredMethodologyArr.map((element) => {
      const filteredCostingOp = 
      !pacificMarkets.includes(profile.CountrySpecs[0].CountryCode)||
      (_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0") ? 
      element?.costingOption.filter(data => filteredMethodology[activeTabSM]?.costingOption.includes(data)) : [];
      const filteredSetupAndDelivery = element?.setupAndDelivery.filter(data => filteredMethodology[activeTabSM]?.setupAndDelivery.includes(data))
      const filteredRfq = element?.rfqData.filter(data => filteredMethodology[activeTabSM]?.rfqData.includes(data))
      

      const filteredArrayData = []
      // console.log(filteredCostingOp, "addsms")
      // if(filteredCostingOp.length) { 
      //   filteredArrayData.push("Costing Options"); 
      //   costingOptionArray.push(element.name)
      // }
      if(filteredSetupAndDelivery.length) {
        filteredArrayData.push("Setup & Delivery"); 
        setupAndDeliveryArray.push(element.name) 
      }
      // if(filteredRfq.length){ 
      //   filteredArrayData.push("RFQ Form"); 
      //   rfqArray.push(element.name)
      // }
      
      return { ...element, filteredArrayData }
    });

    return intersection
  }

  const handleMCategories = (intersection) => {

    const filteredDataMethodology = intersection.filter((item, index) => index !== activeTabSM)

    const catData = []
    filteredDataMethodology.map((item, data) => 
      catData.push(...item.filteredArrayData)
    )

    const uniqueData =  uniq(catData)

    return uniqueData

  }

  const handleModalData = (tab) => {
    let currentProfile = _.cloneDeep(profile);
    const convertedCountries = profile.CountrySpecs
    .filter(
      (C, ind) => countrySpecsLength === 1 ? C : 
      ind!== tab
    )
    .map((fc) => ({ label: fc.label, value: fc.id, ColSpan: null }));

    
    const  methodologiesArray =   currentProfile.CountrySpecs[
      tab
    ].MethodologySpecs

   
    const intersection = countrySpecsLength === 1 ? handleIntersection(filteredMethodology) : []

    const uniqData = countrySpecsLength === 1 ?  handleMCategories(intersection) : []


    const methodologiesArrayData = currentProfile.CountrySpecs?.length === 1 
    ? 
      methodologiesArray.filter(data => data.label !== singleCountryMethodologySpecs && !data.NotApplicable) 
    : 
      methodologiesArray

    const convertedMethodologies = methodologiesArrayData.map((MS) => ({
      label: MS.label,
      value: MS.Code,
      ColSpan: null,
    }));
    const selectConvertedCategories = currentProfile.CountrySpecs?.length === 1 ? modalCategoriesSingleCountry : 
    modalCategories
    const convertedCategories = selectConvertedCategories.filter((MC) => (
      countrySpecsLength === 1 ? 
      uniqData.includes(MC.label) &&
      {
        label: MC.label,
        value: MC.value,
        ColSpan: null,
      }
      : 
      {
        label: MC.label,
        value: MC.value,
        ColSpan: null,
      }
    ));

    const Countries = _.cloneDeep(convertedCountries);
    const MethodologiesSpec = _.cloneDeep(convertedMethodologies);
    const Categories = _.cloneDeep(convertedCategories);
    if (convertedCountries.length > 0) {
      setAdvanceCopying({
        Countries: Countries,
        Methodologies: MethodologiesSpec,
        Categories: Categories,
        Mode: "Selection",
        SelectedMarketInd: tab,
        SelectedCountries: Countries,
        SelectedMethodologies: MethodologiesSpec,
        SelectedCategories: Categories,
        intersectionCopying: intersection ? intersection : [],
        // SelectedCopyMethod: COPYING_METHOD_VALUES.APPEND

      });
    }
    setModalMethodologiesState(true);
  };

  return (
    <Wrapper>
      <Sidebar />
      <Main>
        <ProjectNavbar />
        {isLoading || !pageloaded? (
          <div id="pageCoverSpin"></div>
        ) : (
          <div className={"tab mt-12"}>
            <div
              style={{
                border: "2px solid grey",
                // width: "470px",
                fontSize: "14px",
                // float: "right",
                marginTop: "5px",
              }}
            >
              <p style={{ marginBottom: "0px" }}>
                Please refer to the Time Cost Planning Dashboard link below as a
                reference on how much hours to be budgeted.
              </p>
              <p>
                <a
                  href="https://bit.ly/NIQ-Time-Cost-Dashboard"
                  style={{ color: "blue" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  https://bit.ly/NIQ-Time-Cost-Dashboard
                </a>
              </p>
              <p style={{ marginBottom: "0px" }}>
                Access to be granted by Capacity Management Team (CMT).Please
                send an email request to
                m2m_capacity_management@nielsenenterprise.onmicrosoft.com. And
                indicate the user's email address (also include other team
                members if any) and market/s that want access to.
              </p>
            </div>
            <Row style={{ position: "sticky", zIndex: "1", top: "70px" }}>
              <Col
                md={12}
                className={"countryTabs tab-vertical"}
                style={{ height: "90px" }}
              >
                <div className={"tab mt-2"}>
                  <Nav tabs style={{ width: "100px", display: "inline-block" }}>
                    {profile.CountrySpecs?.map((fc, indxFC) => (
                      <NavItem key={fc.value}>
                        <NavLink
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          className={classnames({
                            active: activeTabFC === indxFC,
                          })}
                          onClick={() => {
                            toggle(indxFC);
                          }}
                        >
                          <b>{fc.label ? fc.label : fc.CountryName}</b>
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  <TabContent activeTab={activeTabFC}>
                    {profile.CountrySpecs?.map(
                      (fc, indxFC) =>
                        activeTabFC === indxFC && (
                          <>
                            <TabPane tabId={indxFC} key={fc.value}>
                              <Row>
                                <Col md={12} className={"methodologyTabs"}>
                                  <Nav tabs>
                                    {fc.MethodologySpecs?.map((sm, indxSM) => (
                                      <>
                                        <NavItem key={sm.value}>
                                          <NavLink
                                            className={classnames({
                                              active: activeTabSM === indxSM,
                                            })}
                                            onClick={() => {
                                              toggleSM(indxSM);
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                              }}
                                            >
                                              <div className="hoverEffect">
                                                {sm.NotApplicable}
                                                <b>
                                                  {sm.label
                                                    ? sm.label
                                                    : sm.Label}
                                                </b>
                                              </div>
                                            </div>
                                          </NavLink>
                                        </NavItem> 
                                                     
                                      </>
                                    ))}
                                  </Nav>
                                </Col>
                              </Row>
                            </TabPane>
                          </>
                        )
                    )}
                  </TabContent>  
                  </div>
                  </Col> 
              </Row>   
                  {isNewProject && profile.CountrySpecs?.map(
                      (fc, indxFC) =>
                        activeTabFC === indxFC && (
                          <Row>
                            <Col md={11}>
                            {fc.MethodologySpecs?.map((sm, indxSM) => (
                              activeTabSM === indxSM &&
                              <TestPageOpResourceNew
                                profile={profile}
                                indxFC={indxFC}
                                methodology={sm}
                                indxSM={indxSM}
                                updateProject={
                                  setCurrentProject
                                }
                                showErrorMessages={
                                  showErrorMessages
                                }
                                errorFieldsObj={errorObj}
                                isEditable={
                                  ValueChangeCheckInChild
                                }
                                disableFields={
                                  location.state
                                }
                                callFrom= "timecost"
                              />                          
                            ))}
                            </Col>
                          </Row>
                          
                          ))} 
            <Row style={{ position: "sticky", zIndex: "1", top: "70px" }}>
            <Col
                md={12}
                className={"countryTabs tab-vertical"}
                style={{ height: "90px" }}
              >
                <div className={"tab mt-2"}>
                  <Container style={{width:"100%", marginLeft: "80px"}}>
                    <TabContent activeTab={activeTabFC}>
                      {profile.CountrySpecs?.map(
                        (fc, indxFC) =>
                          activeTabFC === indxFC && (
                            <>
                              <TabPane tabId={indxFC} key={fc.value}>
                                <Row>
                                  <Col md={12} className={"methodologyTabs"}>
                                    <Nav tabs>
                                      {fc.MethodologySpecs?.[
                                        activeTabSM
                                      ].CostingOptions.map(
                                        (costingOption, indxSM) => {
                                          return (
                                            <NavItem key={indxSM.value}>
                                              <NavLink
                                                className={classnames({
                                                  active: activeTabCO === indxSM,
                                                })}
                                                disabled={
                                                  timecostView === "commercial"
                                                    ? costingOption
                                                        .CostingOptionData
                                                        .Commericalsupport ===
                                                      "No"
                                                    : costingOption
                                                        .CostingOptionData
                                                        .operationpm === "No"
                                                }
                                                onClick={() => {
                                                  toggleCO(indxSM);
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <div className="hoverEffect">
                                                    <b>
                                                      {costingOption.label
                                                        ? costingOption.label
                                                        : costingOption.Name}
                                                    </b>
                                                  </div>
                                                </div>
                                              </NavLink>
                                            </NavItem>
                                          );
                                        }
                                      )}
                                    </Nav>
                                  </Col>
                                </Row>
                              </TabPane>
                            </>
                          )
                      )}
                    </TabContent>
                  </Container>
                </div>
              </Col>
            </Row>                              
            <Row className="mt-2 mb-2">
              <Col md={6}>
                <h3 style = {{marginLeft: "160px"}}>Product: {profile?.CountrySpecs?profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM].CostingOptions[activeTabCO]
            .CostingOptionData.producttype:null}</h3>
              <h3 style = {{marginLeft: "160px"}}>WBS Number: {getWBSnumber()}</h3>
              </Col>
              <Col md={4}>
                  <h4>Currency Selection</h4>
                    <Select
													// isDisabled={profile.ProfileStatus > 1}
													placeholder="Select a Currency..."
													options={currencies?.map((c) => {
														return {
															value: `${c.Code}-${c.CurrencyUnit}`,
															label: `${c.Label} (1USD = ${c.ConversionRateToLocal}${c.CurrencyUnit})`,
														};
													})}
                          value={OPScurrencyDropdown?OPScurrencyDropdown:null}
                          onChange={handleOPSCurrencyChange}
										/>
              </Col>
            </Row>
            <Container
              style={{
                width: "50%",
                marginLeft: "160px",
                backgroundColor: "white",
              }}
            >
              <Table responsive hover striped bordered size="sm">
                <tbody>
                  <tr style={{ fontWeight: "bold", fontSize: "15px" }}>
                    <td>Summary</td>
                  </tr>
                  {
                    <tr style={{ fontWeight: "bold", fontSize: "15px" }}>
                      <td>Total Operations Time Cost ({OPSCurrencyUnit})</td>
                      <td>{(totalOpsCost*OPSCurrencyConversion)?.toFixed(2)}</td>
                    </tr>
                  }
                </tbody>
              </Table>
            </Container>
            <CostingMarketTable
              activities={activitiesForOPS}
              band={OPSband}
              role={role}
              activeTabSM={activeTabSM}
              activeTabCO={activeTabCO}
              activeTabFC={activeTabFC}
              teamDataOptions={OPSTeamDataOptions}
              tableData={_.cloneDeep(opsCostData)}
              defaultRates={defaultRates}
              costingOptionID={ profile.CountrySpecs !== undefined ?
                profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
                  ?.CostingOptions[activeTabCO]?.id : ""                
              }
              CurrencyConversion={OPSCurrencyConversion}
            />
            {opsCostData && profile.CountrySpecs &&
              opsCostData[
                profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
                  ?.CostingOptions[activeTabCO]?.id
              ]?.map((opsData, index) => (
                <>
                  <hr />
                  <Container style={{ marginLeft: "140px" }}>
                    <Row>
                      <Col
                        md={4}
                        style={{
                          marginLeft: "20px",
                          maxWidth: "287px !important",
                        }}
                      >
                        <h4>Select Operation Project Team {`${index + 1}`}</h4>
                      </Col>
                      <Col md={5} style={{ marginLeft: "-50px" }}>
                        <Select
                          options={OPSTeamDataOptionsTemp}
                          value={OPSTeamDataOptions.filter(function (option) {
                            return option.label === Object.keys(opsData)[0];
                          })}
                          onChange={(e) => {
                            handleSelectChange(e, index);
                          }}
                          isDisabled={
                            !localSupportModalOPS ||
                            !localSupportOPS ||
                            project.PriceChange == 3 ||
                            profile.ProfileStatus === "6" ||
                            profile.ProfileStatus === "99" ||
                            project.ProjectStatus == 3||
                            getProductName().includes("EXPENSE RECHARGE") ||
                            !profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
                              ?.CostingOptions[activeTabCO]?.isSelected
                          }
                        />
                      </Col>
                      <Col>
                        {index >= 2 && (
                          <Button
                            style={{
                              width: "80px",
                            }}
                            color="primary"
                            onClick={() => {
                              deleteOPSTable(
                                profile.CountrySpecs[activeTabFC]
                                  .MethodologySpecs[activeTabSM]
                                  ?.CostingOptions[activeTabCO]?.id,
                                index
                              );
                            }}
                            //  disabled={project.PriceChange == 3 || profile.ProfileStatus === "6" || profile.ProfileStatus === "99" || project.ProjectStatus == 3}
                          >
                            Delete
                          </Button>
                        )}
                      </Col>
                      <Col md={2}>
                        <Button
                          style={{
                            width: "80px",
                            marginLeft: "-43px",
                          }}
                          //  onClick={clearOPSTable}
                          color="primary"
                          onClick={() => {
                            clearOPSTable(profile.CountrySpecs[activeTabFC].MethodologySpecs[activeTabSM]
                              ?.CostingOptions[activeTabCO]?.id,index);
                          }}
                          //  disabled={project.PriceChange == 3 || profile.ProfileStatus === "6" || profile.ProfileStatus === "99" || project.ProjectStatus == 3}
                        >
                          Clear
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                  <TimeCostTable
                    reduxData = {_.cloneDeep(opsCostData)}
                    costingOptionID={
                      profile.CountrySpecs[activeTabFC].MethodologySpecs[
                        activeTabSM
                      ]?.CostingOptions[activeTabCO]?.id
                    }
                    activities={activitiesForOPS}
                    band={OPSband}
                    role="OPS"
                    inputCountry={Object.keys(opsData)[0]}
                    rowIndex={index}
                    tableData={Object.values(opsData)[0]}
                    localSupport={localSupportOPS}
                    localModalSupport={localSupportModalOPS}
                    defaultRates={defaultRates}
                    countryOptions={OPSTeamDataOptions}
                    isClearTable={isClear}
                    setIsClear={setIsClear}
                    costData = {_.cloneDeep(operationsCostData)}
                    setAlert={setAlert}
                    CurrencyUnit={OPSCurrencyUnit}
                    CurrencyConversion={OPSCurrencyConversion}
                    productName={getProductName()}
                    costingOption={
                      profile.CountrySpecs[activeTabFC].MethodologySpecs[
                        activeTabSM
                      ]?.CostingOptions[activeTabCO]
                    }
                    minhours = {getMinHoursByCostOpt(profile.CountrySpecs[activeTabFC].MethodologySpecs[
                      activeTabSM
                    ]?.CostingOptions[activeTabCO]?.id)}
                  />
                </>
              ))}    

              {(isNewProject || showProductValidation || IsTextBoxdisabled) && <><hr /> <Container 
                style={{width: "100%",
                  marginLeft: "10px"
                }}
              >
                <Row style={{ position: "sticky", zIndex: "1", top: "70px" }}>
                  <Col
                      md={12}
                      className={"countryTabs tab-vertical"}
                      style={{ height: "180px" }}
                    >
                  <div className={"tab mt-2"}>                
                    
                    {/* <Card className="ml-2 mr-2">
                      <CardHeader>                        
                        <CardTitle id="reason_timecost" className="mb-0" style={{ fontSize: '1.5rem', fontWeight: 'bold', margin: '0'}}> */}
                        <span style={{fontSize: "18px", fontWeight: "bold"}}>
                          Internal time cost input costing Option level validation
                        </span>
                        <br/>
                        {/* </CardTitle>
                        <CardSubtitle style={{fontSize: '1.2rem', color: 'black'}}> */}
                        <span style={{fontSize: "16px"}}>
                          For each costing Option, associate the internal time cost or provide a reason if it is zero or less than cost calculated from predefined hours by {profile.ThresholdTimecost * 100}%.
                        </span>
                        {/* </CardSubtitle>                          
                      </CardHeader> */}
                      {/* <CardBody> */}
                        <Table> 
                          <thead>
                            {tableHeaders.map((tv) => (
                              <th>
                                {tv}
                              </th>
                            ))}
                          </thead>
                          <tbody>
                            {
                              getInternalCost()?.map(tv => (
                                <tr>
                                  <td>
                                    {tv?.Productname}
                                  </td>
                                  <td>
                                    {tv.WBSNumber}
                                  </td>
                                  <td>
                                    {tv.Label}
                                  </td>
                                  <td>
                                    {tv.costingoptionlabel}
                                  </td>
                                  <td>
                                    Operations
                                  </td>
                                  <td>
                                    { getTotalOpsCost(tv.costOptionId).toFixed(2)}
                                  </td>
                                  <td>
                                  { getTotalOpshours(tv.costOptionId).toFixed(0)} 
                                  </td>
                                  <td>
                                  {getTotalminHours(getMinHoursByCostOpt(tv.costOptionId)).toFixed(0)} 
                                  </td>
                                  <td>
                                    <div style={{ position: "relative", display: "inline-block", width: "100%" }}>
                                      <Input
                                        type="textarea"  style = {{width:"100%",paddingRight:"30px"}}
                                        value={opsTimecostReason[tv.costOptionId]?.OpsTimecostReason}
                                        disabled={(IsTextBoxdisabled.length > 0 ? 
                                          (((getTotalOpshours(tv.costOptionId) >= getTotalminHours(getMinHoursByCostOpt(tv.costOptionId)).toFixed(0) && (getTotalOpshours(tv.costOptionId) !== 0 ))) || ( (opsTimecostReasonSaved[tv.costOptionId]?.OpsTimecostReason !== null && opsTimecostReasonSaved[tv.costOptionId]?.OpsTimecostReason !== "" && opsTimecostReasonSaved[tv.costOptionId]?.OpsTimecostReason !== undefined) ) 
                                        ) &&
                                          !IsTextBoxdisabled.includes(tv.costOptionId) : (((getTotalOpshours(tv.costOptionId) >= getTotalminHours(getMinHoursByCostOpt(tv.costOptionId)).toFixed(0) && (getTotalOpshours(tv.costOptionId) !== 0 ))) || ( (opsTimecostReasonSaved[tv.costOptionId]?.OpsTimecostReason !== null && opsTimecostReasonSaved[tv.costOptionId]?.OpsTimecostReason !== "" && opsTimecostReasonSaved[tv.costOptionId]?.OpsTimecostReason !== undefined) ) 
                                        ))
                                           }
                                        onChange={(e) => handleTimecostReasonOPS(e, tv.costOptionId)}
                                      />
                                      {(getTotalOpshours(tv.costOptionId) >= getTotalminHours(getMinHoursByCostOpt(tv.costOptionId)).toFixed(0) && getTotalOpshours(tv.costOptionId) !== 0  ) || (opsTimecostReasonSaved[tv.costOptionId]?.OpsTimecostReason !== null && opsTimecostReasonSaved[tv.costOptionId]?.OpsTimecostReason !== "" && opsTimecostReasonSaved[tv.costOptionId]?.OpsTimecostReason !== undefined) && (<FontAwesomeIcon
                                        title="Edit Reason"
                                        icon={faPencilAlt}
                                        fixedWidth
                                        onClick={() =>handleIconClick(tv.costOptionId)}
                                        style={{ cursor: "pointer", position: "absolute", right: "0px", top: "9px", transform: "translateY(-50%)", color: "#666" }}
                                      />
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </Table>
                      {/* </CardBody>
                    </Card> */}
                  </div>
                  </Col>
                </Row>
              </Container></>}         
            <Prompt
              when={alert}
              message='Clicking on "OK" will navigate to next page without saving data '
            />
            <InfoModal
              infoModal={infoModal}
              infoModalToggle={infoModalToggle}
              activityMapping={activityMapping}
              currentActiveTab={currentActiveTab}
              toggleTabPane={toggleTabPane}
            />
            <Row>
              <Col className="justify-content-end">
              <Button style={{top:"85%", zIndex: "2"}}
                  className="validateandsave costing-profile-btn"
                  color="primary"
                  onClick={saveTableData}
                  disabled={project.PriceChange == 3 || profile.ProfileStatus === "6" || profile.ProfileStatus === "99" || project.ProjectStatus == 3}
                >
                  <FontAwesomeIcon
                    className="icon-costingprofile"
                    icon={faSave}
                    fixedWidth
                  />
                  {isOpsvalidation.length > 0 ? `Validate & Save` : `Save`}
                </Button>
              </Col>
            </Row>

            <Row>
              <Col className="justify-content-end">
                <Button
                  style={{ top: "45%", zIndex: "2" }}
                  className="copy-to-all-market costing-profile-btn"
                  color="primary"
                  onClick={() => {
                    setInfoModal(true);
                  }}
                >
                  <FontAwesomeIcon
                    className="icon-costingprofile"
                    icon={faInfoCircle}
                    fixedWidth
                  />
                  Activities info
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="justify-content-end">
                <Button
                  style={{ top: "75%", zIndex: "2" }}
                  className="savedraft costing-profile-btn"
                  color="primary"
                  onClick={() => {
                    copyCountriesDataOps();
                  }}
                >
                  <FontAwesomeIcon
                    className="icon-costingprofile"
                    icon={faCopy}
                    fixedWidth
                  />
                  Copy Operations tables
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="justify-content-end">
                <Button
                  style={{ top: "35%", zIndex: "2" }}
                  className="copy-to-all-market costing-profile-btn"
                  color="primary"
                  onClick={() => {
                    settimecostViewModal(true);
                  }}
                >
                  <FontAwesomeIcon
                    className="icon-costingprofile"
                    icon={faPencilAlt}
                    fixedWidth
                  />
                  Edit Level of Input
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="justify-content-end">
                <Button
                  style={{ top: "65%", zIndex: "2" }}
                  className="copy-to-all-market costing-profile-btn"
                  color="primary"
                  onClick={addNewCostingRow}
                >
                  <FontAwesomeIcon
                    className="icon-costingprofile"
                    icon={faPlusCircle}
                    fixedWidth
                  />
                  Add Costing Market
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="justify-content-end">
                <Button
                  style={{ top: "55%", zIndex: "2" }}
                  className="savedraft costing-profile-btn"
                  color="primary"
                  onClick={() => {
                    if (
                      (!_.includes(
                        pacificMarkets,
                        project.CommissioningCountry
                      )|| (_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0"))
                    ) {
                      handleModalData(activeTabFC);
                    } else {
                      handlePacificModalData(activeTabFC);
                    }
                  }}
                >
                  <FontAwesomeIcon
                    className="icon-costingprofile"
                    icon={faCopy}
                    fixedWidth
                  />
                  Copy Operations Section 
                </Button>
              </Col>
            </Row>
            <TimeCostModal isOpen={timecostViewModal} isClose={updateModal} />
          </div>
        )}
        <Modal
          isOpen={showModal}
          toggle={handleClose}
          style={{ height: "30px" }}
        >
          <ModalHeader toggle={handleClose}>Clear Table Data</ModalHeader>
          <ModalBody>Are you sure to clear table data?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => handleOk()}>
              Ok
            </Button>
            <Button color="secondary" onClick={() => handleClose()}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={showDeleteModal}
          toggle={handleCloseModal}
          style={{ height: "30px" }}
        >
          <ModalHeader toggle={handleClose}>Delete Table</ModalHeader>
          <ModalBody>Are you sure to Delete table?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => handleOKModal()}>
              Ok
            </Button>
            <Button color="secondary" onClick={() => handleCloseModal()}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal className="modal-with-header" isOpen={isModalOpen} toggle={() => handleOpen()} size="lg">
        <ModalHeader
          // className="modal-header-error"
          toggle={() => handleOpen(!isModalOpen)}
        >
          {/* <img src={logo} style={imgStyle} className="modallogo" /> */}

        </ModalHeader>

        <ModalBody id="scrollModalBody" className="modal-body-error-header">
          <div>
            <span className="modaltitle modalvalidationheader " style = {{color:"red"}}>
              {validationHeader}
            </span>
          </div>
          {/* <p>
            <b>
              Key Parameters information is missing at cost option level for
              following Country and Methodologies
            </b>
          </p> */}
          <br></br>
          <br></br>
          {Object.keys(errorObj)?.length ? (
            <>
            <div className="col-md-12">
              <Table id="errorTable">
              <thead>
                              <tr>
                                <th>Country</th>
                                <th>Methodology</th>
                                <th>Mandatory fields requiring input</th>
                              </tr>
                            </thead>

              {Object.keys(errorObj).map(function (key) {
                return (
                  <>

                      {((errorObj[key]?.CostingOptions &&
                      Object.keys(errorObj[key]?.CostingOptions).length) || 
                      (errorObj[key]?.RFQ &&
                        Object.keys(errorObj[key]?.RFQ).length) || 
                        (errorObj[key]?.SD &&
                          Object.keys(errorObj[key]?.SD).length)) ? (
                        <>
                              <tbody>
                              {Object.keys(errorObj[key]).includes("CostingOptions") ? Object.keys(errorObj[key]?.CostingOptions).map(
                                  function (key1) {
                                    return (
                                      <tr>
                                        {((Object.keys(errorObj[key]).includes("CostingOptions") && Object.entries(
                                          errorObj[key]?.CostingOptions[key1]
                                        ).length > 0) || (Object.keys(errorObj[key]).includes("RFQ") && 
                                        (Object.entries(errorObj[key]?.RFQ).some((  [key,value]   ) => key == key1  ))
                                         && Object.entries(
                                          errorObj[key]?.RFQ[key1]
                                        ).length > 0) || (Object.keys(errorObj[key]).includes("SD") && (Object.entries(errorObj[key]?.SD).some((  [key,value]   ) => key == key1  )) &&Object.entries(
                                          errorObj[key]?.SD[key1]
                                        ).length > 0)) && (
                                          <>
                                            <td>{key}</td>
                                            <td>{key1} </td>
                                            <td style = {{color:"red"}}>
                                              {errorObj[key]?.CostingOptions &&
                                              Object.keys(errorObj[key]?.CostingOptions[key1]).length ?
                                               errorObj[key]?.CostingOptions[
                                                key1
                                              ].map((item,index)=>(
                                                <div key1 ={index}>{item}</div>
                                )) : null}

                                              {errorObj[key]?.RFQ &&
                                              (Object.entries(errorObj[key]?.RFQ).some((  [key,value]   ) => key == key1  )) &&
                                                Object.keys(errorObj[key].RFQ[key1]).length ? 
                                                errorObj[key].RFQ[key1].map((item,index)=>(
                                                  <div key1 ={index}>{item}</div>
                                  )) : null}

                                              {errorObj[key]?.SD &&
                                              (Object.entries(errorObj[key]?.SD).some((  [key,value]   ) => key == key1  )) &&
                                                Object.keys(errorObj[key].SD[key1]).length ?
                                                errorObj[key].SD[key1]
                                                .map((item,index)=>(
                                                  <div key1 ={index}>{item}</div>
                                  )): null}
                                            </td>
                                          </>
                                        )}
                                      </tr>
                                    );
                                  }
                                ) : Object.keys(errorObj[key]).includes("RFQ") ? 
                                Object.keys(errorObj[key]?.RFQ).map(
                                  function (key1) {
                                    return (
                                      <tr>
                                        {((Object.keys(errorObj[key]).includes("CostingOptions") && Object.entries(
                                          errorObj[key]?.CostingOptions[key1]
                                        ).length > 0) || (Object.keys(errorObj[key]).includes("RFQ") && 
                                        (Object.entries(errorObj[key]?.RFQ).some((  [key,value]   ) => key == key1  )) && Object.entries(
                                          errorObj[key]?.RFQ[key1]
                                        ).length > 0) || (Object.keys(errorObj[key]).includes("SD") &&
                                        (Object.entries(errorObj[key]?.SD).some((  [key,value]   ) => key == key1  )) && Object.entries(
                                          errorObj[key]?.SD[key1]
                                        ).length > 0)) && (
                                          <>
                                            <td>{key}</td>
                                            <td>{key1} </td>
                                            <td style = {{color:"red"}}>
                                              {errorObj[key]?.CostingOptions &&
                                              Object.keys(errorObj[key]?.CostingOptions[key1]).length ?
                                               errorObj[key]?.CostingOptions[
                                                key1
                                              ].map((item,index)=>(
                                                <div key1 ={index}>{item}</div>
                                )) : null}

                                              {errorObj[key]?.RFQ &&
                                              (Object.entries(errorObj[key]?.RFQ).some((  [key,value]   ) => key == key1  )) &&
                                                Object.keys(errorObj[key].RFQ[key1]).length ? 
                                                errorObj[key].RFQ[key1].map((item,index)=>(
                                                  <div key1 ={index}>{item}</div>
                                  )) : null}

                                              {errorObj[key]?.SD &&
                                              (Object.entries(errorObj[key]?.SD).some((  [key,value]   ) => key == key1  )) &&
                                                Object.keys(errorObj[key].SD[key1]).length ?
                                                errorObj[key].SD[key1]
                                                .map((item,index)=>(
                                                  <div key1 ={index}>{item}</div>
                                  )) : null}
                                            </td>
                                          </>
                                        )}
                                      </tr>
                                    );
                                  }
                                ): Object.keys(errorObj[key]).includes("SD") ? 
                                Object.keys(errorObj[key]?.SD).map(
                                  function (key1) {
                                    return (
                                      <tr>
                                        {((Object.keys(errorObj[key]).includes("CostingOptions") && Object.entries(
                                          errorObj[key]?.CostingOptions[key1]
                                        ).length > 0) || (Object.keys(errorObj[key]).includes("RFQ") && (Object.entries(errorObj[key]?.RFQ).some((  [key,value]   ) => key == key1  )) && Object.entries(
                                          errorObj[key]?.RFQ[key1]
                                        ).length > 0) || (Object.keys(errorObj[key]).includes("SD") &&
                                        (Object.entries(errorObj[key]?.SD).some((  [key,value]   ) => key == key1  )) && Object.entries(
                                          errorObj[key]?.SD[key1]
                                        ).length > 0)) && (
                                          <>
                                            <td>{key}</td>
                                            <td>{key1} </td>
                                            <td style = {{color:"red"}}>
                                              {errorObj[key]?.CostingOptions &&
                                              Object.keys(errorObj[key]?.CostingOptions[key1]).length ?
                                               errorObj[key]?.CostingOptions[
                                                key1
                                              ].map((item,index)=>(
                                                <div key1 ={index}>{item}</div>
                                )) : null}

                                              {errorObj[key]?.RFQ &&
                                              (Object.entries(errorObj[key]?.RFQ).some((  [key,value]   ) => key == key1  )) &&
                                                Object.keys(errorObj[key].RFQ[key1]).length ? 
                                                errorObj[key].RFQ[key1].map((item,index)=>(
                                                  <div key1 ={index}>{item}</div>
                                  )) : null}

                                              {errorObj[key]?.SD &&
                                              (Object.entries(errorObj[key]?.SD).some((  [key,value]   ) => key == key1  )) &&
                                                Object.keys(errorObj[key].SD[key1]).length ?
                                                errorObj[key].SD[key1]
                                                .map((item,index)=>(
                                                  <div key1 ={index}>{item}</div>
                                  )) : null}
                                            </td>
                                          </>
                                        )}
                                      </tr>
                                    );
                                  }
                                ) : null
                              }



                              </tbody>

                          {/* </Table> */}
                        </>
                      ) : null}

                    {/* </div>  */}
                  </>
                );
               })}  
               </Table>
               </div>
            </>
          ) : null}
          {/* {Object.keys(errorObj)?.length ? (
            <>
              {Object.keys(errorObj).map(function (key) {
                return (
                  <>
                    <div className="displayCountry col-md-12">
                      <h4>
                        <b>{key} :</b>
                      </h4>
                    </div>
                    <div className="col-md-12">
                      {errorObj[key]?.CostingOptions &&
                      Object.keys(errorObj[key]?.CostingOptions).length ? (
                        <>
                          <p>
                            <b>Costing Options Section:</b>
                          </p>
                          <Table id="errorTable">
                            <thead>
                              <tr>
                                <th>Methodologies</th>
                                <th>Fields</th>
                              </tr>
                            </thead>
                            {
                              <tbody>
                                {Object.keys(errorObj[key]?.CostingOptions).map(
                                  function (key1) {
                                    return (
                                      <tr>
                                        {Object.entries(
                                          errorObj[key]?.CostingOptions[key1]
                                        ).length > 0 && (
                                          <>
                                            <td>{key1} </td>
                                            <td>
                                              {errorObj[key]?.CostingOptions[
                                                key1
                                              ].join(", ")}
                                            </td>
                                          </>
                                        )}
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            }
                          </Table>
                        </>
                      ) : null}
                    </div>

                    <div className="col-md-12">
                      {errorObj[key]?.RFQ &&
                      Object.keys(errorObj[key].RFQ).length ? (
                        <>
                          <p>
                            <b>RFQ Form Section:</b>
                          </p>
                          <Table id="errorTable">
                            <thead>
                              <tr>
                                <th>Methodologies</th>
                                <th>Fields</th>
                              </tr>
                            </thead>
                            {
                              <tbody>
                                {Object.keys(errorObj[key].RFQ).map(function (
                                  key1
                                ) {
                                  return (
                                    <tr>
                                      <td>{key1} </td>
                                      <td>
                                        {errorObj[key].RFQ[key1].join(", ")}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            }
                          </Table>
                        </>
                      ) : null}
                    </div>
                    {(_.includes(
                      pacificMarkets,
                      project.CommissioningCountry
                    )  && project.CostingTemplateVersion != "v2.0")? (
                      <div className="col-md-12">
                        {errorObj[key]?.SD &&
                        Object.keys(errorObj[key].SD).length ? (
                          <>
                            <p>
                              <b>Setup and Delivery Section:</b>
                            </p>
                            <Table id="errorTable">
                              <thead>
                                <tr>
                                  <th>Methodologies</th>
                                  <th>Wave Number</th>
                                  <th>Fields</th>
                                </tr>
                              </thead>
                              {
                                <tbody>
                                  {Object.keys(errorObj[key].SD).map(function (
                                    key1
                                  ) {
                                    return Object.keys(
                                      errorObj[key].SD[key1].NO
                                    ).map(function (number) {
                                      return (
                                        <tr>
                                          <td>{key1}</td>
                                          <td>{number} </td>
                                          <td>
                                            {errorObj[key].SD[key1].NO[
                                              number
                                            ].join(", ")}
                                          </td>
                                        </tr>
                                      );
                                    });
                                  })}
                                </tbody>
                              }
                            </Table>
                          </>
                        ) : null}
                      </div>
                    ) : (
                      <div className="col-md-12">
                        {errorObj[key]?.SD &&
                        Object.keys(errorObj[key].SD).length ? (
                          <>
                            <p>
                              <b>Setup and Delivery Section:</b>
                            </p>
                            <Table id="errorTable">
                              <thead>
                                <tr>
                                  <th>Methodologies</th>
                                  <th>Fields</th>
                                </tr>
                              </thead>
                              {
                                <tbody>
                                  {Object.keys(errorObj[key].SD).map(function (
                                    key1
                                  ) {
                                    return (
                                      <tr>
                                        <td>{key1} </td>
                                        <td>
                                          {errorObj[key].SD[key1].join(", ")}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              }
                            </Table>
                          </>
                        ) : null}
                      </div>
                    )}
                  </>
                );
              })}
            </>
          ) : null} */}
        </ModalBody>
        <ModalFooter>
        {/* <Button  color="primary" style = {{marginLeft:"300px"}}  onClick = {()=>handleOpen(!isModalOpen)}>
            Ok
          </Button> */}
          <Button color="secondary" className="ml-auto"  onClick={() => handleOpen(!isModalOpen)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={modalMethodologiesState}
        toggle={() => setModalMethodologiesState(!modalMethodologiesState)}
        centered
        size={"xm"}
      >
        <ModalHeader
          toggle={() => setModalMethodologiesState(!modalMethodologiesState)}
        >
          {/* <img src={logo}  className="modallogo" /> */}
        </ModalHeader>
        <div>
          <span className="modaltitle modaltitleDisplay">
          { `Copying ${
              profile.CountrySpecs && profile.CountrySpecs[activeTabFC]?.label
            } ${
              singleCountryMethodologySpecs
            } 
            Methodologies data to other applicable 
            ${countrySpecsLength === 1 
              ? 
                "methodologies" 
              : 
              "markets"
            }`
          }
          </span>
        </div>
        <ModalBody style={{ minHeight: "60px" }}>
          {countrySpecsLength > 1 
            ?
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Select Countries(*)</Label>
                    <Select
                      name={"selectCountries"}
                      value={advanceCopying.SelectedCountries}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      menuPortalTarget={document.querySelector("modal-body")}
                      menuPosition={"fixed"}
                      options={advanceCopying.Countries}
                      isMulti
                      onChange={(val) => {
                        setAdvanceCopying((prevState) => ({
                          ...prevState,
                          SelectedCountries: val ? val : [],
                        }));
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
             : 
              "" 
          } 
          <Row>
            <Col>
              <FormGroup>
              
                <Label>Select Methodologies(*)</Label>
                <Select
                  name={"selectMethodologies"}
                  value={advanceCopying.SelectedMethodologies}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  menuPortalTarget={document.querySelector("modal-body")}
                  menuPosition={"fixed"}
                  options={advanceCopying.Methodologies}
                  isMulti
                  onChange={(val) => {
                    countrySpecsLength > 1 ?
                      setAdvanceCopying((prevState) => ({
                        ...prevState,
                        SelectedMethodologies: val ? val : [],
                      }))
                    :
                      selectedMethodologiesFunc(val)
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                {/* <Label>Select copy behaviour for costing options(*)</Label>
                  <Col className="ml-2">
                    <CustomInput
                      id={COPYING_METHOD_VALUES.APPEND + '-radio'}
                      label="Append"
                      type="radio"
                      name={"selectCopyMethod"}
                      disabled={!advanceCopying?.SelectedCategories?.some(i => i.value === "CostingOptions")}
                      checked={advanceCopying.SelectedCopyMethod === COPYING_METHOD_VALUES.APPEND}
                      onChange={(val) => {
                        setAdvanceCopying((prevState) => ({
                          ...prevState,
                          SelectedCopyMethod: COPYING_METHOD_VALUES.APPEND,
                        }));
                      }}
                    />
                  </Col>
                  <Col className="ml-2">
                    <CustomInput
                      id={COPYING_METHOD_VALUES.OVERWRITE + '-radio'}
                      label="Overwrite"
                      type="radio"
                      name={"selectCopyMethod"}
                      checked={advanceCopying.SelectedCopyMethod === COPYING_METHOD_VALUES.OVERWRITE}
                      disabled={!advanceCopying?.SelectedCategories?.some(i => i.value === "CostingOptions")}
                      onChange={(val) => {
                        setAdvanceCopying((prevState) => ({
                          ...prevState,
                          SelectedCopyMethod: COPYING_METHOD_VALUES.OVERWRITE,
                        }));
                      }}
                    />
                  </Col> */}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Select Categories(*)</Label>
                <Select
                  name={"selectCategories"}
                  value={advanceCopying.SelectedCategories?.filter(sct => sct.label === "Setup & Delivery")}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  menuPortalTarget={document.querySelector("modal-body")}
                  menuPosition={"fixed"}
                  options={advanceCopying.Categories?.filter(ct => ct.label === "Setup & Delivery")}
                  isMulti
                  onChange={(val) => {
                      setAdvanceCopying((prevState) => ({
                        ...prevState,
                        SelectedCategories: val ? val : [],
                      }));
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label>
                {" "}
                <strong>Note:</strong> Copying the information may change the
                data in selected{" "}
                {countrySpecsLength === 1 ?  "methodologies" : "markets"}.
              </Label>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              confirmModalCopying();
            }}
          >
            Yes
          </Button>
          <Button
            color="secondary"
            onClick={() => setModalMethodologiesState(false)}
          >
            No
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalConfirmMethodology} size={"sm"} centered={true}>
        <ModalHeader
          toggle={() => setModalConfirmMethodology(!modalConfirmMethodology)}
        >
          {/* <img src={logo}  className="modallogo" /> */}
        </ModalHeader>
        <div>
          <span className="modaltitle modaltitleDisplay">
            {`Confirm Copying Data`}
          </span>
        </div>
        <ModalBody style={{ minHeight: "60px" }}>
          <Label>{`You are about to copy the ${
            profile.CountrySpecs && profile.CountrySpecs[activeTabFC]?.label
          }'s data to other selected ${countrySpecsLength === 1 ?  "methodology" : "markets"  }. This action is irreversible. Are you sure want to proceed with copying data ?`}</Label>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              if (!_.includes(pacificMarkets, project.CommissioningCountry)
              ||(_.includes(pacificMarkets, project.CommissioningCountry)  && project.CostingTemplateVersion == "v2.0")
              ) {
                countrySpecsLength === 1 
                  ? 
                    copyFormToMethodology(activeTabFC)
                  :
                    copyFormToCountries(activeTabFC)

              } else {
                countrySpecsLength === 1 
                ? 
                  copyFormToMethodology(activeTabFC)
                :
                  copyFormToPacificCountries(activeTabFC)
              }
            }}
          >
            Yes
          </Button>
          <Button
            color="secondary"
            onClick={() => setModalConfirmMethodology(false)}
          >
            No
          </Button>
        </ModalFooter>
      </Modal>
        <CopyOptionsModal
          mode={"OPS"}
          modal={opsModal}
          activeTabFC={activeTabFC}
          activeTabCO={activeTabCO}
          activeTabSM={activeTabSM}
          setModal={setOpsModal}
          setIsLoading={setIsLoading}
          selectedMethodologyData1={selectedMethodologyData1}
          activities={activitiesForOPS}
          band={OPSband}
          options={options}
          selectedCountryData={selectedCountryData}
        />
      </Main>
    </Wrapper>
  );
};

export default OperationsTimecost;