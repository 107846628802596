import * as codeLabelActions from "./codeLabelActions";
import * as userActions from "./userActions";
import * as reportStatusActions from "./reportStatusActions";
import { getNewNotification } from "./notificationActions";

export const SET_APP = "SET_APP";
export const SET_PAGELOAD = "SET_PAGELOAD";
export const VENDOR_SET_PAGELOAD = "VENDOR_SET_PAGELOAD";
export const SET_RECORDLOAD = "SET_RECORDLOAD";
export const SET_LOCAL_LOAD = "SET_LOCAL_LOAD";
export const SET_RECORDENDED = "SET_RECORDENDED";
export const SET_COSTING = "SET_COSTING";
export const SET_VALUE_CHANGED = "SET_VALUE_CHANGED";
export const SET_SAVE_BUTTON_DISABLED = "SET_SAVE_BUTTON_DISABLED";

export const init = () => {
  return (dispatch) => {
    // dispatch(userActions.authCheckState());
    //dispatch(codeLabelActions.getCodeLabels());

    const authToken = localStorage.getItem("auth-token");
    const userRecord = localStorage.getItem("userRecord");

    if (authToken) {
      if (!userRecord) {
        dispatch(userActions.authSuccess(authToken, "Vendor Bidding View'"));
      }
      else {
        codeLabelActions.resetErrorFlag();
        dispatch(codeLabelActions.getDashBoardResponse());
        dispatch(codeLabelActions.getCodeLabelResponse());
        dispatch(codeLabelActions.getCountriesResponse());
        dispatch(codeLabelActions.getRateCardsResponse());
        dispatch(reportStatusActions.getReportStatuses());
        dispatch(getNewNotification());
      }
    }
    else {
      dispatch(userActions.authFail());
    }
  };
};

export const pageLoadStart = () => {
  return (dispatch) => {
    dispatch(getNewNotification());
    dispatch({ type: SET_PAGELOAD, pageloaded: false });
  };
};
export const pageLoadEnd = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_PAGELOAD, pageloaded: true, optionalData : data });
  };
};

export const recordLoadStart = () => {
  return (dispatch) => {
    dispatch({ type: SET_RECORDLOAD, recordloading: true });
  };
};
export const recordLoadEnd = () => {
  return (dispatch) => {
    dispatch({ type: SET_RECORDLOAD, recordloading: false });
  };
};

export const localPageLoadStart = () => {
  return (dispatch) => {
    dispatch({ type: SET_LOCAL_LOAD, localPageload: true });
  };
};
export const localPageLoadEnd = () => {
  return (dispatch) => {
    dispatch({ type: SET_LOCAL_LOAD, localPageload: false });
  };
};

export const setCostingStatus = (costing, callback) => {
  return (dispatch) => {
    dispatch({ type: SET_COSTING, costing: { ...costing } })
    if (callback)
      callback()
    // if (history) {
    //   history.push("/proposal-new")
    //   // history.push("/costing")
    // }
  }
}
export const valueChangedInRFQ = (val) => {
  return (dispatch) => {
    dispatch({ type: SET_VALUE_CHANGED, valueChanged: val });
  };
};

export const saveButtonEnableInputCost = (val) => {
  return (dispatch) => {
    dispatch({ type: SET_SAVE_BUTTON_DISABLED, updateSaveButton: val });
  };
};