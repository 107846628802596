import React from "react";
import filterFactory, {
    textFilter, dateFilter, Comparator
} from "react-bootstrap-table2-filter";
import _ from "lodash";

export const reportColumns = [
"ID",
"PID",
"Profile Id",
"Wave Spec Id",
"Cost Option ID",
"Selected Cost Option",
"Project Id",
"Opportunity Number",
"WBS Number",
"Project Name",
"Proposal Owner Email",
"Other CS Contacts",
"Commissioning Country",
"Fielding Countries",
"Business Unit",
"Verticals",
"Methodology",
"Syndicated",
"Project Status",
"Commission Date",
"MultiCountry",
"Tracker",
"Number Of Waves",
"Tracking Frequency",
"Costing Option Title",
"Number Of Questionnaires",
"Number Of Modules",
"Number Of Questions",
"Questionarrie Complexity",
"Number Of Open Ended Questions",
"Number Of Semi Open Ended Questions",
"Survey Programming Required",
"Survey Programming Resource",
"Survey Programming Resource Vendor",
"Data Processing Required",
"Data Processing Complexity",
"Data Processing Resource",
"Data Processing Resource Vendor",
"Charting Required",
"Charting Complexity",
"Charting Resource",
"Charting Resource Vendor",
"Charting Slides Per Country",
"Charting Number Per Country",
"Coding Required",
"Number Of Open Ended To Code",
"Number Of Semi Open Ended To Code",
"Operations Time Cost (USD)",
"Commercial Time Cost (USD)",
"Programming Costs (USD)",
"Cost Completed Sample (USD)",
"Data Entry/Punching/Typing Costs (USD)",
"Data Processing Costs (USD)",
"Modeling Cluster Solutions Costs (USD)",
"Coding Costs (USD)",
"Dashboard And Analytics Costs (USD)",
"Charting Costs (USD)",
]

const getDates = () => {
    const fields = {
        DateWaveCommissioned: "Date Wave Commissioned",
        DateFinalQuestionnaire: "Date Final Questionnaire",
        DateFinalQuestionnaireActual:
            "TCSActualDateFinalQuestionnaireToProgramming",
        DateProgrammingStartActual: "TCSActualDateProgrammingStart",
        DateTranslations: "Date Translations",
        DateFieldStart: "Planned FW Start Date",
        DateFieldStartActual: "TCSActualStartFieldDate",
        DateFieldEnd: "Planned FW End Date",
        DateFieldEndActual: "TCSActualFieldEndDate",
        DateDeliveryToGOActual: "TCSActualDeliveryToGODate",
        DateDataProcessing: "Date Data Processing",
        DateCharts: "Date Charts",
        DateDashboards: "Date Dashboards",
        DateFinalReport: "Date Final Report",
        DateFirstTestLinkActual: "TCSDateFirstTestLinkSent",
        DateLiveLinkActual: "TCSDateLiveLinkProvided",
    };

    return Object.keys(fields).map((key) => {
        return {
            dataField: key,
            text: fields[key],
            sort: true,
            // filter: textFilter(),
            formatter: (cell, row) => {
                return <span>{row[key]?.split("T")[0]}</span>;
            },
            csvFormatter: (cell, row) => {
                return row[key]?.split("T")[0] || "not available";
            },
            filter: dateFilter({
                delay: 400,
                placeholder: 'custom placeholder',
                withoutEmptyComparatorOption: false,
                comparators: [Comparator.EQ, Comparator.GT, Comparator.LT]
            })
        };
    });
};

const getOpsResources = () => {
    const fields = {
        NumberOfQuestionnaires: "Number Of Questionnaires",
        NumberOfModules: "Number Of Modules",
        NumberOfQuestions:
            "Number Of Questions",
        QuestionarrieComplexity:
            "Questionarrie Complexity",
        NumberOfOpenEndedQuestions:
            "Number Of Open Ended Questions",
        SurveyProgrammingRequired: "Survey Programming Required",
        SurveyProgrammingResource: "Survey Programming Resource",
        SurveyProgrammingResourceVendor: "Survey Programming Resource Vendor",
        DataProcessingRequired: "Data Processing Required",
        DataProcessingComplexity: "Data Processing Complexity",
        dataProcessingResource: "Data Processing Resource",
        DataProcessingResourceVendor: "Data Processing Resource Vendor",
        ChartingRequired: "Charting Required",
        ChartingComplexity: "Charting Complexity",
        ChartingResource: "Charting Resource",
        chartingResourceVendor: "Charting Resource Vendor",
        ChartingSlidesPerCountry:
            "Charting Slides Per Country",
            ChartingNumberPerCountry: "Charting Number Per Country",
            CodingRequired: "Coding Required",
            NumberOfOpenEndedToCode:"Number Of Open Ended To Code",
            NumberOfSemiOpenEndedToCode: "Number Of Semi Open Ended To Code",
            OPSTimeCost: "Operations Time Cost (USD)",
            CSTimeCost: "Commercial Time Cost (USD)",
            ProgrammingCostsExternal: "Programming Costs (USD)",
            DataEntry:"Data Entry/Punching/Typing Costs (USD)",
            DataProcessingCosts:"Data Processing Costs (USD)",
            ModelingClusterSolutionsCosts:"Modeling Cluster Solutions Costs (USD)",
            CodingCosts:"Coding Costs (USD)",
            DashboardAndAnalytics:"Dashboard And Analytics Costs (USD)",
            ChartingCosts:"Charting Costs (USD)",
            CostCompletedSample:"Cost Completed Sample (USD)"
    };
    const sKeys = [
        "SurveyProgrammingRequired",
        "Dashboarding",
        "Translations",
        "VerbatimCoding",
        "TextAnalytics",
        "OtherDataPreparationAssistance",
        "AdditionalOperationsSupport",
    ];
    return Object.keys(fields).map((key) => {
        return {
            dataField: key,
            text: fields[key],
            sort: true,
            formatter: (cell, row) => {
                if (sKeys.indexOf(key) !== -1) {
                    return (
                        <span>
                            {row[key] ? row[key] : ""}
                        </span>
                    );
                }
                if (key === "TranslationsLanguages") {
                    return (
                        <span>
                            {row[key]
                                ? row[key].split(',').join(', ') || ""
                                : ""}
                        </span>
                    );
                }
                return (
                    <span>
                        {row[key]
                            ? row[key] || ""
                            : ""}
                    </span>
                );
            },
            csvFormatter: (cell, row) => {
                if (sKeys.indexOf(key) !== -1) {
                    return row[key]
                        ? row[key]
                            ? "Yes"
                            : "No"
                        : ""
                }
                if (key === "TranslationsLanguages") {
                    return row[key]
                        ? row[key].split(',').join('|') || ""
                        : ""
                }
                return row[key]
                    ? row[key] || ""
                    : ""
            },
        };
    });
};

const getTCS = () => {
    const fields = {
        ProgrammerAssigned: "TCSProgrammerAssigned",
        GroupLeadEmail: "TCSGroupLeadName",
        //InitialSetupCTDays: "Initial Setup CT Days",
        //ComplexityLevel: "Complexity Level",
        ChangesBeforeInitialLinkDelivery: "TCSChangesBeforeInitialLinkDelivery",
        NumberOfQuestions: "TCSNumberOfQuestions",
        ChangesToUniqueQuestions: "TCSChangesToUniqueQuestions",
        NumberOfPids: "TCSNumberOfPids",
        ChangesAfterInitialLinkDelivery: "TCSChangesAfterInitialLinkDelivery",
        NumberOfIterations: "TCSNumberOfIterations",
        ErrorsInInitialLinkDelivery: "TCSErrorsInInitialLinkDelivery",
        ErrorsAfterInitialLinkDelivery: "TCSErrorsAfterInitialLinkDelivery",
        //JobCount: "Job Count",
        PlatformProjectId: "TCSPlatformProjectId",
        ChangesAfterLiveLinkCreated: "TCSChangesAfterLiveLinkCreated",
        ErrorsAfterLiveLinkCreated: "TCSErrorsAfterLiveLinkCreated",
        TotalNumberOfIterations: "TCSTotalNumberOfIterations",
        ActualLOIMins: "TCSActualLOI",
        TotalCompletes: "TCSTotalCompletes",
        CancelledDate: "TCSCancelledDate",
        ReasonForCancellation: "TCSReasonForCancellation",
        //Platform: "Platform",
        //DataCollectionMethod: "Data Collection Method",
        //CostCentre: "Cost Centre",
        //DeliveryStatus: "Delivery Status",
        DataCollectionMethodOther: "TCSDataCollectionOther",
        ChangesToUniqueQuestionsNA: "TCSChangesToUniqueQuestionsNA",
        LiveSurveyProjectId: "TCSLiveSurveyProjectId",
        TestSurveyProjectId: "TCSTestSurveyProjectId",
        IsInternalDelivery: "TCSIsInternalDelivery",
        IsDecommissionedFixed: "TCSIsDecommissionedFixed",
    };
    const sKeys = {
        DeliveryStatus: {
            title: "TCSWaveStatus",
            codeLabel: "DeliveryStatusOptions",
        },
        DataCollectionMethod: {
            title: "TCSDataCollection",
            codeLabel: "DeliveryDataCollectionTypeOptions",
        },
        InitialSetupCTDays: {
            title: "TCSInitialSetupCTDays",
            codeLabel: "DeliveryInitialSetupCTDaysOptions",
        },
        CostCentre: {
            title: "TCSCostCentre",
            codeLabel: "DeliveryCostCentreOptions",
        },
        ComplexityLevel: {
            title: "TCSComplexityLevel",
            codeLabel: "DeliveryComplexityLevelSPOptions",
        },
        JobCount: { title: "TCSJobCount", codeLabel: "DeliveryJobCountSP" },
        Platform: {
            title: "TCSPlatform",
            codeLabel: "DeliveryPlatformSPOptions",
        },
    };
    let columns = [];
    columns = columns.concat(
        Object.keys(fields).map((key) => {
            return {
                dataField: key,
                text: fields[key],
                sort: true,
                //filter: textFilter(),
                formatter: (cell, row) => {
                    return <span>{row[key] || ""}</span>;
                },
                csvFormatter: (cell, row) => {
                    return row[key] || "";
                },
            };
        })
    );
    columns = columns.concat(
        Object.keys(sKeys).map((key) => {
            return {
                dataField: key,
                text: sKeys[key]["title"],
                sort: true,
                //filter: textFilter(),
                formatter: (cell, row) => {
                    return (
                        <span>{row[key] && row[key].split(',').join(', ') || ""}</span>
                    );
                },
                csvFormatter: (cell, row) => {
                    return row[key] && row[key].split(',').join('|') || "";
                },
            };
        })
    );
    return columns;
};

export const finalColumns = [
    {
        dataField: "PID",
        text: "PID",
        sort: true,
        formatter: (cell, row) => {
            return <span>{row.PID}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.PID || "";
        },
        //filter: textFilter(),
    },
    {
        dataField: "ProfileId",
        text: "Profile Id",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return <span>{row.ProfileId}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.ProfileId || "";
        },
    },
    {
        dataField: "WaveSpecId",
        text: "Wave Spec Id",
        sort: true,
        formatter: (cell, row) => {
            return <span>{row.WaveSpecId || ""}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.WaveSpecId || "";
        },
        // filter: textFilter()
    },
    {
        dataField: "COID",
        text: "Cost Option ID",
        sort: true,
        formatter: (cell, row) => {
            return <span>{row.COID || ""}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.COID || "";
        },
        // filter: textFilter()
    },
    {
        dataField: "Module Nmae",
        text: "Module Name",
        sort: true,
        formatter: (cell, row) => {
            return <span>{row.ModuleUserInputName || ""}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.ModuleUserInputName || "";
        },
    },
    {
        dataField: "ProjectId",
        text: "Project Id",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return <span>{row.ProjectId}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.ProjectId || "";
        },
    },
    {
        dataField: "WBSNumber",
        text: "WBS Number",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>
                    {row.WBSNumber && row.WBSNumber.split(',').join(', ') || ""}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return (
                row.WBSNumber && row.WBSNumber.split(',').join('|') || ""
            );
        },
    },
    {
        dataField: "OpportunityNumber",
        text: "Opportunity Number",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>{row.OpportunityNumber ? row.OpportunityNumber.split(',').join(', ') : ""}</span>
            );
        },
        csvFormatter: (cell, row) => { return row.OpportunityNumber ? row.OpportunityNumber.split(',').join('|') : "" },
        // filter: textFilter()
    },
    {
        dataField: "ProjectName",
        text: "Project Name",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>{row.ProjectName}</span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.ProjectName || "";
        },
        // filter: textFilter()
    },
    {
        dataField: "ProposalOwnerEmail",
        text: "Proposal Owner Email",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>
                    {row.ProposalOwnerEmail}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return (
                row.ProposalOwnerEmail || ""
            );
        },
        // filter: textFilter()
    },
    {
        dataField: "OtherCSContacts",
        text: "Other CS Contacts",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>
                    {row.OtherCSContacts && row.OtherCSContacts.split(',').join(', ') || ""}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return (
                row.OtherCSContacts && row.OtherCSContacts.split(',').join('|') || ""
            );
        },
    },
    {
        dataField: "CommissioningCountry",
        text: "Commissioning Country",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>
                    {row.CommissioningCountry}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return (
                row.CommissioningCountry || ""
            );
        },
        // filter: textFilter()
    },
    {
        dataField: "BusinessUnit",
        text: "Business Unit",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>
                    {row.BusinessUnit}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return (
                row.BusinessUnit || ""
            );
        },
        // filter: textFilter()
    },
    {
        dataField: "Verticals",
        text: "Verticals",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>
                    {row.Verticals}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return (
                row.Verticals || ""
            );
        },
        // filter: textFilter()
    },
    {
        dataField: "FieldingCountries",
        text: "Fielding Countries",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>
                    {row.FieldingCountries && row.FieldingCountries.split(',').join(', ') || ""}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return (
                row.FieldingCountries && row.FieldingCountries.split(',').join('|') || ""
            );
        },
    },
    {
        dataField: "Submethodology",
        text: "Methodology",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>
                    {row.SubMethodology && row.SubMethodology.split(',').join(', ') || ""}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return (
                row.SubMethodology && row.SubMethodology.split(',').join('|') || ""
            );
        },
        // filter: textFilter()
    },
    {
        dataField: "Syndicated",
        text: "Syndicated",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>
                    {row.IsSyndicated}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.IsSyndicated || "";
        },
    },
    {
        dataField: "ProjectStatus",
        text: "Status",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>
                    {row.ProjectStatus}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return (
                row.ProjectStatus || ""
            );
        },
        filter: textFilter()
    },
    {
        dataField: "CommissionedDate",
        text: "Commissioned Date",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>
                    {row.CommissionedDate}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return (
                row.CommissionedDate || ""
            );
        },
    },
    {
        dataField: "Tracker",
        text: "Tracker",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>{row.IsTracker}</span>
            );
        },
        csvFormatter: (cell, row) => {
            return row.IsTracker || "";
        },
    },
    {
        dataField: "TrackingFrequency",
        text: "Tracking Frequency",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return (
                <span>
                    {row.TrackingFrequency}
                </span>
            );
        },
        csvFormatter: (cell, row) => {
            return (
                row.TrackingFrequency || ""
            );
        },
    },
    {
        dataField: "NumberOfWaves",
        text: "Number Of Waves",
        sort: true,
        //filter: textFilter(),
        formatter: (cell, row) => {
            return <span>{row.NumberOfWaves}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.NumberOfWaves || "";
        },
    },
    {
        dataField: "CostingOption",
        text: "Costing Option Title",
        sort: true,
        formatter: (cell, row) => {
            return <span>{row.CostingOption}</span>;
        },
        csvFormatter: (cell, row) => {
            return row.CostingOption || "";
        }
    },
    // {
    //     dataField: "WaveCountryName",
    //     text: "Wave Country",
    //     sort: true,
    //     formatter: (cell, row) => {
    //         return <span>{row.WaveCountryName}</span>;
    //     },
    //     csvFormatter: (cell, row) => {
    //         return row.WaveCountryName || "";
    //     },
    //     // filter: textFilter()
    // },
    // ...getDates(),
    ...getOpsResources(),
    // ...getTCS(),
    // ...getOnline(),
];



export const csvHeaders = finalColumns.map(col => ({ label: col.text, key: col.dataField }));