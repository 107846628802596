import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Modal,
    ModalFooter,
    ModalHeader,
    ModalBody,
    CardTitle,
} from "reactstrap";
import { Button } from "react-bootstrap";
import Select from "react-select";
import logo from "../../assets/img/avatars/modal-logo.png";
import { useSelector, useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { Prompt } from "react-router-dom";
import _ from "lodash";
import { setCommercialHours, setOperationsHours, setCommercialCost, setOperationsCost } from "../../redux/actions/timecostHoursActions";

const CopyOptionsModal = ({ mode, modal, activeTabFC, activeTabCO, activeTabSM, setModal, setIsLoading, selectedMethodologyData1, activities, band, options, selectedCountryData }) => {
    const dispatch = useDispatch();
    const [selectedCostingOptionData, setSelectedCostingOptionData] = useState({});
    const [copyData, setCopyData] = useState({});
    const [isCountrySelected, setIsCountrySelected] = useState(false);
    const [isMethodologySelected, setIsMethodologySelected] = useState(false);
    const [toCopycostingsoptions, setToCopycostingsoptions] = useState([]);
    const [selectData,setSelectData]=useState({
        costingOption:null,
        Methodology:null
    })
    console.log(selectData);
    console.log(copyData)
    const profile = useSelector(
        ({ currentCosting }) => currentCosting.currentCostingProfile
    );
    const project = useSelector(
        ({ currentProject }) => currentProject.newProject
    );

    let csCostData = useSelector(({ timecostHours }) => timecostHours.commercial);
    let opsCostData = useSelector(({ timecostHours }) => timecostHours.operations);
    let commercialCostData = useSelector(({ timecostHours }) => timecostHours.commercialCost);
    let operationsCostData = useSelector(({ timecostHours }) => timecostHours.operationCost);


    const handleModal = () => {
        setModal(false);
        setIsMethodologySelected(false);
        setIsCountrySelected(false);
    };
    const copyCountry = (data) => {
        let copyDataObj = copyData
        copyDataObj["Country"] = data.value
        copyDataObj["Methodology"] = null
        copyDataObj["costingOption"] = null
        let selectDataObj={...selectData}
        selectDataObj["Methodology"] =""
        selectDataObj["costingOption"] = ""
        setCopyData(copyDataObj)
        setIsCountrySelected(true);
        setSelectData(selectDataObj)
    }
    const getCostingOptions = (method) => {
        let costOptionArr = []
        for (let country = 0; country < profile.CountrySpecs.length; country++) {
            if (profile.CountrySpecs[country].CountryName === copyData["Country"]) {
                for (let methodology = 0; methodology < profile.CountrySpecs[country].MethodologySpecs.length; methodology++) {
                    if (profile.CountrySpecs[country].MethodologySpecs[methodology].Label === method) {
                        for (let costingOption = 0; costingOption < profile.CountrySpecs[country].MethodologySpecs[methodology].CostingOptions.length; costingOption++) {
                            let label = profile.CountrySpecs[country].MethodologySpecs[methodology]
                                .CostingOptions[costingOption].label;
                            let ID = profile.CountrySpecs[country].MethodologySpecs[methodology]
                                .CostingOptions[costingOption].id;
                            
                            if (profile.CountrySpecs[country].MethodologySpecs[methodology]

                                .CostingOptions[costingOption].label) {

                                costOptionArr.push({ label: label, value: ID })

                            }

                            else {

                                let name = profile.CountrySpecs[country].MethodologySpecs[methodology]

                                    .CostingOptions[costingOption].Name;

                                costOptionArr.push({ label: name, value: ID })

                            }
                        }
                    }
                }
            }
        }
        setSelectedCostingOptionData(costOptionArr)
        if(costOptionArr.length == 1){
            setSelectData(selectData.costingOption =  costOptionArr[0])
            let copyDataObj = copyData
            copyDataObj["costingOption"] = costOptionArr[0].value
            setCopyData(copyDataObj)
        }
    }
    const copyMethodology = (data) => {
        let copyDataObj = copyData
        copyDataObj["Methodology"] = data.value
        setCopyData(copyDataObj)
        selectData["costingOption"] = ""
        getCostingOptions(data.value)
        setIsMethodologySelected(true);
        setSelectData({...selectData,Methodology:data})
    }
    const copyCostingOptionsData = (data) => {
        let copyDataObj = copyData
        copyDataObj["costingOption"] = data.value
        setCopyData(copyDataObj)
        setSelectData({...selectData,costingOption:data})
    }
    const costingoptionsToCopy = (data) => {
        let temparr = toCopycostingsoptions;
        data?.map((value) => temparr.push(value.value))
        setToCopycostingsoptions(temparr)
    }
    const copyCSTable = () => {
        if(!isCountrySelected ){
            toastr.error("Please select Country from 'Copy from' to proceed.")
        }
        else if(selectData.Methodology == ""){
            toastr.error("Please select Methodology from 'Copy from' to proceed.")
        }
        else if(selectData.costingOption == ""){
            toastr.error("Please select Costing Option from 'Copy from' to proceed.")
        }
        else{
        setIsLoading(true);
        let csData = _.cloneDeep(csCostData);
        let costData=_.cloneDeep(commercialCostData);

        for (let country = 0; country < profile.CountrySpecs.length; country++) {
            for (let methodology = 0; methodology < profile.CountrySpecs[country].MethodologySpecs.length; methodology++) {
                for (let costingOption = 0; costingOption < profile.CountrySpecs[country].MethodologySpecs[methodology].CostingOptions.length; costingOption++) {
                    if (toCopycostingsoptions.indexOf(profile.CountrySpecs[country].MethodologySpecs[methodology]
                        .CostingOptions[costingOption].id) !== -1) {
                        let tempcostingOptionId =
                            profile.CountrySpecs[country].MethodologySpecs[methodology]
                                .CostingOptions[costingOption].id;
                        let commercialValue;
                        if (!("CostingOptionData" in profile.CountrySpecs[country].MethodologySpecs[methodology]
                            .CostingOptions[costingOption])) {
                            if (profile.CountrySpecs[country].CountryCode === project.CommissioningCountry) {
                                commercialValue = "Yes"
                            }
                            else {
                                commercialValue = "No"
                            }

                        }
                        else if (Object.keys(profile.CountrySpecs[country].MethodologySpecs[methodology]
                            .CostingOptions[costingOption].CostingOptionData).length === 0) {
                            if (profile.CountrySpecs[country].CountryCode === project.CommissioningCountry) {
                                commercialValue = "Yes"
                            }
                            else {
                                commercialValue = "No"
                            }
                        }
                        else {
                            commercialValue = profile.CountrySpecs[country].MethodologySpecs[methodology]
                                .CostingOptions[costingOption].CostingOptionData.CommericalLocalsu
                        }
                        if (commercialValue === "Yes") {
                            let copyFromOption = csData[copyData.costingOption];
                            let copyCostFromOption = costData[copyData.costingOption];
                            //copying hours
                            if (csData?.[tempcostingOptionId] === undefined) {
                                let newObj = { [tempcostingOptionId]: copyFromOption };
                                Object.assign(csData, newObj);
                            } else {
                                csData[tempcostingOptionId] = copyFromOption;
                            }
                            //copying cost
                            if (costData?.[tempcostingOptionId] === undefined) {
                                let newObj = { [tempcostingOptionId]: copyCostFromOption };
                                Object.assign(costData, newObj);
                            } else {
                                costData[tempcostingOptionId] = copyCostFromOption;
                            }
                        }
                    }
                }
            }
        }
        dispatch(setCommercialHours(csData));
        dispatch(setCommercialCost(costData));
        toastr.success("Copied Successfully");
        setIsLoading(false);
        setModal(false)
        setCopyData({})
        setToCopycostingsoptions([])
        setIsMethodologySelected(false);
        setIsCountrySelected(false);
    }
    }
    const copyOPSTable = () => {
        if(!isCountrySelected ){
            toastr.error("Please select Country from 'Copy from' to proceed.")
        }
        else if(selectData.Methodology == ""){
            toastr.error("Please select Methodology from 'Copy from' to proceed.")
        }
        else if(selectData.costingOption == ""){
            toastr.error("Please select Costing Option from 'Copy from' to proceed.")
        }
        else{
        setIsLoading(true);
        let opData = _.cloneDeep(opsCostData);
        let costData=_.cloneDeep(operationsCostData);

        for (let country = 0; country < profile.CountrySpecs.length; country++) {
            for (let methodology = 0; methodology < profile.CountrySpecs[country].MethodologySpecs.length; methodology++) {
                for (let costingOption = 0; costingOption < profile.CountrySpecs[country].MethodologySpecs[methodology].CostingOptions.length; costingOption++) {
                    if (toCopycostingsoptions.indexOf(profile.CountrySpecs[country].MethodologySpecs[methodology]
                        .CostingOptions[costingOption].id) !== -1) {
                        let tempcostingOptionId =
                            profile.CountrySpecs[country].MethodologySpecs[methodology]
                                .CostingOptions[costingOption].id;

                        let operationsValue;
                        if (!("CostingOptionData" in profile.CountrySpecs[country].MethodologySpecs[methodology]
                            .CostingOptions[costingOption])) {
                            if (profile.CountrySpecs[country].CountryCode === project.CommissioningCountry) {

                                operationsValue = "Yes"
                            }
                            else {

                                operationsValue = "Yes"
                            }

                        }
                        else if (Object.keys(profile.CountrySpecs[country].MethodologySpecs[methodology]
                            .CostingOptions[costingOption].CostingOptionData).length === 0) {
                            if (profile.CountrySpecs[country].CountryCode === project.CommissioningCountry) {

                                operationsValue = "Yes"
                            }
                            else {

                                operationsValue = "Yes"
                            }
                        }
                        else {

                            operationsValue = profile.CountrySpecs[country].MethodologySpecs[methodology]
                                .CostingOptions[costingOption].CostingOptionData.operationalpm
                        }
                        if (operationsValue === "Yes") {
                            let copyToOption = opData[copyData.costingOption];
                            let copyCostFromOption = costData[copyData.costingOption];
                            //copying hours
                            if (opData?.[tempcostingOptionId] === undefined) {
                                let newObj = { [tempcostingOptionId]: copyToOption };
                                Object.assign(opData, newObj);
                            } else {
                                opData[tempcostingOptionId] = copyToOption;
                            }
                            //copying cost
                            if (costData?.[tempcostingOptionId] === undefined) {
                                let newObj = { [tempcostingOptionId]: copyCostFromOption };
                                Object.assign(costData, newObj);
                            } else {
                                costData[tempcostingOptionId] = copyCostFromOption;
                            }
                        }
                    }
                }
            }
        }
        dispatch(setOperationsHours(opData));
        dispatch(setOperationsCost(costData));
        toastr.success("Copied Successfully");
        setIsLoading(false);
        setModal(false);
        setCopyData({});
        setToCopycostingsoptions([]);
        setIsMethodologySelected(false);
        setIsCountrySelected(false);
    }
    }

    return (
        <>
            <Modal
                isOpen={modal}
                toggle={handleModal}
                size="xl"
                scrollable={true}
            >
                <ModalHeader toggle={handleModal}>
                    {/* <img src={logo} className="modallogo" /> */}
                    <CardTitle>{`${mode === "CS" ? "Copy Commercial tables:" : "Copy Operations tables:"}`}</CardTitle>
                </ModalHeader>
                <ModalBody >
                    <Row style={{ margin: "15px" }}>
                        <h4>Copy from</h4>
                        <Col>
                            <h5>
                                Select Country
                            </h5>
                            <Select
                                options={selectedCountryData}

                                onChange={(e) => {
                                    copyCountry(e)
                                }}
                            >

                            </Select>
                        </Col>

                        {isCountrySelected && <Col><h5>
                            Select Methodology
                        </h5>
                            <Select
                                options={selectedMethodologyData1}
                                value={selectData["Methodology"]}
                                onChange={(e) => {
                                    copyMethodology(e)
                                }}
                            >
                            </Select>
                        </Col>}
                        {(isMethodologySelected && isCountrySelected) && <Col><h5>
                            Select Costing Option
                        </h5>
                            <Select
                                options={selectedCostingOptionData}
                                value={selectedCostingOptionData.length == 1 ? selectedCostingOptionData[0] : selectData["costingOption"]}
                                onChange={(e) => {
                                    copyCostingOptionsData(e)
                                }}
                            >
                            </Select>
                        </Col>}
                    </Row>
                    <div style={{ margin: "19px" }}>
                        <h4>Copy To</h4>
                    </div>
                    {profile?.CountrySpecs && profile.CountrySpecs.map((country, countryindex) => {
                        return <Row style={{ margin: "20px", border: "1px solid grey", padding: "10px" }}>
                            <Col md={3}>
                                {country.label ? country.label : country.CountryName}
                            </Col>
                            <Col md={9}>
                                {country.MethodologySpecs.map((method, methodindex) => {
                                    return <Row >
                                        <Col>
                                            {method.label ? method.label : method.Label}

                                        </Col>
                                        <Col>
                                            <Select
                                                options={options?.[countryindex][methodindex]}
                                                isMulti
                                                onChange={(e) => {
                                                    costingoptionsToCopy(e)
                                                }}
                                                isClearable
                                            ></Select>
                                            <br />
                                        </Col>
                                    </Row>
                                })}
                            </Col>
                        </Row>
                    })}
                    <br />
                </ModalBody>
                <ModalFooter>
                    <Row>
                        <Col>
                            <span style={{ color: "red", fontWeight: "600px", fontSize: "14px" }}>
                                {" "}
                                <strong>Note:</strong> Destination costing options may have zero rates
                                for any column so those hours will not be calculated for total cost.
                            </span>
                        </Col>
                    </Row>


                    <Row>
                        <Col style={{ marginLeft: "110px" }}>
                            <Button color="primary" onClick={() => { mode === "CS" ? copyCSTable() : copyOPSTable() }}>
                                Ok
                            </Button>&nbsp;&nbsp;
                            <Button color="secondary" onClick={() => { setModal(false); setIsMethodologySelected(false); setIsCountrySelected(false); }}>
                                Cancel
                            </Button>
                        </Col>
                    </Row>

                </ModalFooter>
            </Modal>
            {/* <Prompt
                when={alert}
                message='Clicking on "OK" will navigate to next page without saving data '
            /> */}
        </>
    )
};
export default CopyOptionsModal;