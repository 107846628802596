// registerRequestSubmitted
import React from "react";
import {
	Card,
	CardText,
	CardImg,
	CardBody,
	CardTitle,
} from "reactstrap";



import banner from "../../assets/img/avatars/nielsen-logo-header-3x1.png";
import image from "../../assets/img/avatars/niq.png"

import AuthLayout from "../../layouts/Auth";

const QuestionsSubmitted = (props) => {


	return (
		<AuthLayout>
			<Card>
				<CardImg
					variant="top"
					src={image}
					className="mx-auto img-responsive"
				/>
				<CardBody>
					<CardTitle className="text-center mt-4">
						<h1 className="h3">CINTRAL Access Request </h1>
					</CardTitle>
					<div className="m-sm-4 text-center">
						<CardText>
                        Questions Submitted to Product Team.
                        <br />
                        <br />
                        The CINTRAL Team
						</CardText>
                       
					</div>
				</CardBody>
			</Card>
		</AuthLayout>
	);
};



export default (QuestionsSubmitted);
